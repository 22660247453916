import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { getCookie } from "../../Util/util";

import TotalNightPrice from "./TotalNightPrice";
import Button from "../FormComponent/Button";
import { ReactComponent as Lock } from "../../Assets/img/icons/lock-s.svg";
import CancelPolicyModal from "../../Views/HotelDetail/Rooms/CancelPolicyModal";
import AdditionalPolicyModal from "../../Views/HotelDetail/Rooms/AdditionalPolicyModal";
import { hotelActions } from "../../Stores/Actions/hotelActions"
import { useDispatch, useSelector } from "react-redux";
import {ErrorModel} from "../../Assets/scss/components/ErrorModal/ErrorModal"
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';


const currencyAndLanguage =
  (window.localStorage.getItem("currencyAndLanguage") &&
    JSON.parse(window.localStorage.getItem("currencyAndLanguage"))) ||
  {};

const currency =
  currencyAndLanguage?.currencyLanguageReducer?.data?.currency || {};

const language =
  currencyAndLanguage?.currencyLanguageReducer?.data?.language || {};


function RoomPriceInfo({

  roomId,
  hotel_id,
  roomNo,
  price,

  currencySymbol,
  currencyCode,

  setCurrentSavings,
  board_cancellation_policy,
  additional_info,
  setShowUpgradeModal,

  free_cancellation_date,
  hideSaveMember,
  disableLink,
  scrollToView,
  firstPackageIndex
}) {

  const [selectedTab, setselectedTab] = useState(1);
  const [toggalSeeMemberPrice,setToggalSeeMemberPrice] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [isPremium, setIsPremium] = useState(null);
  const [cancelPolicyModal, setCancelPolicyModal] = useState(false);
  const [additionalPolicyModal, setAdditionalPolicyModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const mandatoryFee =
    price?.mandatory_fee?.length &&
    price?.mandatory_fee?.map((ele) => ele.amount)
      .reduce((a, b) => parseInt(a) + parseInt(b));

  // const tax_Nightly = (price?.per_night - price?.per_night_without_tax).toFixed(
  //   2
  // );


  const [prices, setPrices] = useState({});

  const {
    savings_percentage,
    public_price,
    final_price,
    no_of_rooms,
    no_of_nights,
    savings_amount,
    net_rate,
    room_tax,
    mandatory_fee,
    per_night_public_price,
    per_night_without_tax,
    per_night_savings_amount,
    per_night,
    voucher_credit,
    save_more_percentage,
    save_more,
    save_more_final_price,
    client_commission,
    per_night_save_more_final_price,
    per_night_save_more_savings,
    per_night_voucher_credit,
    tax
  } = price; // price from parent componant

  const tax_Nightly = (tax/no_of_rooms).toFixed(2);
  const premiumPrices = {
    savings: savings_percentage,
    publicPrice: public_price,
    // from: final_price,
    from: save_more_final_price,
    roomCount: no_of_rooms,
    nightCount: no_of_nights,
    mandatory_fee: mandatoryFee,
    hasMandatoryFee: !isEmpty(mandatory_fee),
    free_cancellation_date: free_cancellation_date || null,
    currency: price?.mandatory_fee[0]?.currency,
    // price details
    perNight: net_rate,
    // tax: room_tax,
    tax : tax,
    totalPriceToPay: save_more_final_price,
    savingPrice: save_more,

    // Night prices
    night_savings: savings_percentage,
    night_publicPrice: per_night_public_price,
    night_from: per_night_save_more_final_price,
    night_roomCount: no_of_rooms,
    night_nightCount: no_of_nights,
    night_mandatory_fee: mandatoryFee,
    night_hasMandatoryFee: !isEmpty(price?.mandatory_fee),
    night_free_cancellation_date: free_cancellation_date || null,
    night_currency: price?.mandatory_fee[0]?.currency,
    // price details
    night_perNight: per_night_without_tax,
    night_tax: tax_Nightly,
    night_totalPriceToPay: per_night,
    night_savingPrice: per_night_save_more_savings,
  };
  const fremiumPrices = {
    savings: savings_percentage,
    publicPrice: public_price,
    from: final_price,
    roomCount: no_of_rooms,
    nightCount: no_of_nights,
    mandatory_fee: mandatoryFee,
    hasMandatoryFee: !isEmpty(mandatory_fee),
    free_cancellation_date: free_cancellation_date || null,
    currency: price?.mandatory_fee[0]?.currency,
    // price details
    perNight: net_rate,
    tax: tax,
    totalPriceToPay: final_price,
    savingPrice: voucher_credit,

    // Night prices
    night_savings: savings_percentage,
    night_publicPrice: per_night_public_price,
    night_from: per_night,
    night_roomCount: no_of_rooms,
    night_nightCount: no_of_nights,
    night_mandatory_fee: mandatoryFee,
    night_hasMandatoryFee: !isEmpty(price?.mandatory_fee),
    night_free_cancellation_date: free_cancellation_date || null,
    night_currency: price?.mandatory_fee[0]?.currency,
    // price details
    night_perNight: per_night_without_tax,
    night_tax: tax_Nightly,
    night_totalPriceToPay: per_night,
    night_savingPrice: per_night_voucher_credit,
  };
  const premiumForFremium = {
    savings: save_more_percentage,
    publicPrice: public_price,
    from: save_more_final_price,     //////
    roomCount: no_of_rooms,
    nightCount: no_of_nights,
    mandatory_fee: mandatoryFee,
    hasMandatoryFee: !isEmpty(mandatory_fee),
    free_cancellation_date: free_cancellation_date || null,
    currency: price?.mandatory_fee[0]?.currency,
    // price details
    perNight: net_rate,
    tax: tax,
    totalPriceToPay: save_more_final_price,      //////
    savingPrice: save_more,

    // Night prices
    night_savings: save_more_percentage,
    night_publicPrice: per_night_public_price,
    night_from: per_night_save_more_final_price,
    night_roomCount: no_of_rooms,
    night_nightCount: no_of_nights,
    night_mandatory_fee: mandatoryFee,
    night_hasMandatoryFee: !isEmpty(price?.mandatory_fee),
    night_free_cancellation_date: free_cancellation_date || null,
    night_currency: price?.mandatory_fee[0]?.currency,
    // price details
    night_perNight: per_night_without_tax,
    night_tax: tax_Nightly,
    night_totalPriceToPay: per_night,
    night_savingPrice: per_night_save_more_savings,
  };
  useEffect(() => {
    setIsPremium(getCookie("is_premium") === "true");
  }, []);

  useEffect(() => {
    if (isPremium == true) {
      setPrices(premiumPrices);
    } else if (isPremium == false) {
      setPrices(fremiumPrices);
    }
    if (showUpdate) setPrices(premiumForFremium);

    // ---setting savings for use outside RoomPriceInfo

    if (!setCurrentSavings) return
    setCurrentSavings(premiumPrices?.savings)
    if (showUpdate) {
      setCurrentSavings(premiumForFremium?.savings)
    }
  }, [showUpdate, isPremium, price]);

  const closeCancelPolicyModal = () => {
    setCancelPolicyModal(false);
  };

  const  closeAdditionalPolicyModal = () => {
    setAdditionalPolicyModal(false);
  };

  const [isSelectedHotelSoldOut, setIsSeletectedHotelSoldOut] = useState(null);
  const [validatingLoader, setValidatingLoader] = useState(false)
  const [errorModal, setErrorModal] = useState({
    open : false,
    message : "", 
  })
  const antIcon = (<LoadingOutlined style={{fontSize: 24,color:"white"}} spin />);
  const validatePrice = (hotel_id, roomId, roomNo) => {
    setValidatingLoader(true)
    const checkHotelAvabilityPayload = {
      room_ids: [roomId],
      currency: currency?.isoCode || "USD",
      locale: language?.langCode || "en-US",
    };
    dispatch(hotelActions.checkHotelAvability( 
      checkHotelAvabilityPayload,
      hotel_id
    )).then((res) => {
      setValidatingLoader(false);

      if (res?.status === 200) {
        localStorage.setItem("hotelDataForCheckout", JSON.stringify(res?.data));
        navigate(`/hotel-checkout/${hotel_id}/${roomId}/${roomNo}`);

      } else if (res?.status === 400 && res?.data?.sold_out) {
        setIsSeletectedHotelSoldOut(res.data?.message) // render sold out UI
      } else {    // open modal with error message (eg. session expired error)
        setErrorModal({
          open:true,
          message : res.data?.message,
        })
      }
    });
  }

  // ----------- check if hotel is sold out then return following Sold out UI -----------
  if (isSelectedHotelSoldOut) {
    return (
      <div className="col price-box-col sold-out-wrap">
        <div className="infoBox box-info">
          <h2> Sold out </h2>
          <h4>You just missed it!</h4>
          <p>{isSelectedHotelSoldOut}</p>
        </div>
      </div>
    )
  }

  // ----------- if hotel is not sold out or we have'nt validate hotel then return following price Card UI -----------
  return (
    <>
      <ErrorModel
        openErrorModal={errorModal.open}
        handleErrorModal={()=>setErrorModal({open:false,message:''})}
        message={errorModal.message}
        sessionExpireError={true}
        type="hotel"
      />

      <div className="col price-box-col">
        <div className="infoBox box-info">
          <div className="title d-flex">
            <Button
              className={`nightlyPriceTab ${selectedTab === 0 ? "active" : ""}`}
              onClick={() => setselectedTab(0)}
            >
              Nightly Price
            </Button>
            <Button
              className={`totalPriceTab ${selectedTab === 1 ? "active" : ""}`}
              onClick={() => setselectedTab(1)}
            >
              Total Price
            </Button>
          </div>
          <div className="row no-gutters ">
            <div className="col info">
              <div className="infoBoxWrapper">
                <div
                  id="total-price-toggle"
                  className={`price-container ${selectedTab === 0 ? "activeTab" : ""
                    }`}
                >
                  {selectedTab === 0 && (  // night
                    <>
                      <TotalNightPrice
                        savings={prices?.night_savings}
                        publicPrice={prices.night_publicPrice}
                        from={prices?.night_from}
                        roomCount={prices?.roomCount}
                        nightCount={1}
                        savingPrice={prices?.night_savingPrice}
                        perNight={prices?.night_perNight}
                        totalPriceToPay={prices?.night_totalPriceToPay}
                        no_of_nights={1}
                        nightTax={prices?.night_tax}
                        tax={prices?.night_tax}
                        mandatory_fee={prices.night_mandatory_fee}
                        hasMandatoryFee={!isEmpty(prices?.night_hasMandatoryFee)}
                        free_cancellation_date={
                          prices.night_free_cancellation_date || null
                        }
                        currency={prices.night_currency}
                        currencyCode={currencyCode}
                        nightPrice={true}
                        toggalSeeMemberPrice={toggalSeeMemberPrice}
                      />
                    </>
                  )}
                </div>
                <div
                  id="nightly-price-toggle"
                  className={`price-container ${selectedTab === 1 ? "activeTab" : ""
                    }`}
                >
                  {selectedTab === 1 && (   // total price
                    <TotalNightPrice
                      savings={prices?.savings}
                      publicPrice={prices?.publicPrice}
                      from={prices?.from}
                      roomCount={prices?.roomCount}
                      nightCount={prices?.nightCount}
                      savingPrice={prices?.savingPrice}
                      no_of_nights={no_of_nights}
                      perNight={prices?.perNight}
                      totalPriceToPay={prices?.totalPriceToPay}
                      tax={prices?.tax}
                      mandatory_fee={prices.mandatory_fee}
                      hasMandatoryFee={!isEmpty(prices?.hasMandatoryFee)}
                      free_cancellation_date={
                        prices?.free_cancellation_date || null
                      }
                      currency={prices.currency}
                      currencyCode={currencyCode}
                      toggalSeeMemberPrice={toggalSeeMemberPrice}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col buttonsGroup btn-right">
              {!showUpdate && (
                <div className="centerAlign">
                  {!disableLink ? (
                    <>
                      {((!isPremium && prices?.savingPrice > 0) || isPremium) &&
                        <button
                          disabled={validatingLoader}
                          type="button"
                          className="btn"
                          onClick={() => validatePrice(hotel_id, roomId, roomNo)}
                        >
                        Book Room
                        </button>
                      }
                      {validatingLoader && <Spin className="custom-loader" indicator={antIcon} /> } 
                      {!isPremium && prices?.savingPrice <= 0 && <button
                        type="button"
                        onClick={() => setShowUpgradeModal(true)}
                        className="btn"
                      >
                        Book Room <Lock style={{ marginBottom: "-2px" }} />
                      </button>}
                    </>
                  ) : (
                    <p onClick={() => scrollToView(`roomIndex_${firstPackageIndex}`)} className="btn">
                      Select Room
                    </p>
                  )}
                </div>
              )}

              {showUpdate ? (
                <div className="centerAlign buttonSpace upgradeBtn">
                  <Link to="/membership-upgrade" className="btn">
                    <i className="icon-border-plain"></i>
                    Upgrade
                  </Link>
                  <button className="btn-cancel" onClick={() => {
                    setShowUpdate(!showUpdate);
                    setToggalSeeMemberPrice(false);
                    }}>
                    Cancel
                  </button>
                </div>
              ) : (
                !isPremium &&
                !disableLink &&
                prices?.savingPrice > 0 && (
                  <div className="centerAlign">
                    <Link
                      to="#"
                      className="btnBorder2 btn-see-member-price"
                      onClick={() => {
                        setShowUpdate(!showUpdate);
                        setToggalSeeMemberPrice(true);

                      }}
                    >
                      <i className="icon-border-plain"></i>
                      See Member Price
                    </Link>
                  </div>
                )
              )}

              {!hideSaveMember &&
                board_cancellation_policy?.cancellation?.length !== 0 && (
                  <div className="centerAlign cancellation-policy">
                    <button onClick={() => setCancelPolicyModal(true)}>
                      Cancellation Policy
                    </button>
                  </div>
                )}

              {!hideSaveMember &&
                additional_info?.length !== 0 && (
                  <div className="centerAlign cancellation-policy">
                    <button onClick={() => setAdditionalPolicyModal(true)}>
                      Additional Info
                    </button>
                  </div>
                )}

              {cancelPolicyModal && (
                <CancelPolicyModal
                  isOpen={cancelPolicyModal}
                  closeModal={closeCancelPolicyModal}
                  policies={board_cancellation_policy?.cancellation}
                />
              )}
              {additionalPolicyModal && (
                <AdditionalPolicyModal
                  isOpen={additionalPolicyModal}
                  closeModal={closeAdditionalPolicyModal}
                  policies={additional_info}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default RoomPriceInfo;