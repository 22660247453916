import React, { useEffect, useState, useRef } from 'react';
import { Formik } from 'formik';
import SearchAutocomplete from '../../../Component/Autocomplete/SearchAutocomplete';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import DateRangePickerComponent from '../../../Component/DatePicker/DateRangePickerComponent';
import { childAge } from '../../../Stores/Constants/siteConstants/dropdownConstants';
import AddRoomForm from '../../../Component/Forms/AddRoomForm';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../../Component/FormComponent/Button';
import { commonActions } from '../../../Stores/Actions';

function HotelWidget({ isFromDetailPage, setShowMap, handleSearchHotel }) {

  const form = useRef(null);
  const dispatch = useDispatch();
  const nagivate = useNavigate();
  const [showAutocomplete, setshowAutocomplete] = useState(false);
  const hotelSearchAutoCompleteRes = useSelector(
    (state) => state.commonReducer.autoCompleteData
  );
  const searchLoading = useSelector(
    (state) => state.commonReducer.searchLoading
  );

  const currencyAndLanguage =
    window.localStorage.getItem('currencyAndLanguage') &&
    JSON.parse(window.localStorage.getItem('currencyAndLanguage'));

  const currency =
    currencyAndLanguage?.currencyLanguageReducer?.data?.currency || {};

  const [isFilter, setIsFilter] = useState(false);
  const [isResponsive, setResponsive] = useState(false);
  const [isMobile, setMobile] = useState(false);
  const [focused2, setFocused2] = useState(null);
  const [selectedValue, setselectedValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDateResponsive, setDateResponsive] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [open, setOpen] = useState(false);
  const [hideFilter, setHideFilter] = useState(false);
  const [address, setAddress] = useState('')

  let initialValues = {
    check_in: '',
    check_out: '',
    rooms: [],
    goingTo: [],
  }


  // Get the payload data from localStorage and hit the api for hotel search on mounting
  const payloadData = JSON.parse(window.localStorage.getItem('hotelPayload'))

  const roomObject = { adults: 2, children: [] };

  if (payloadData) {
    var roomsData = [...payloadData.rooms];
    var childData = []

    roomsData?.map((ele, roomIndex) => {
      childData[roomIndex] = []
      return ele.children.map(children => {
        var childObject = childAge.filter(child => child.value === children);
        if (childObject[0]) {
          return childData[roomIndex].push(childObject[0]);
        }
      });
    });

  }

  roomsData?.map((ele, index) => {
    if (childData[index]) {
      ele.children = childData[index]
    }
  });


  if (payloadData) {
    initialValues = {
      check_in: payloadData ? moment(payloadData?.check_in_date) : '',
      check_out: payloadData ? moment(payloadData?.check_out_date) : '',
      rooms: payloadData ? [...roomsData] : [{ ...roomObject }],
      goingTo: payloadData ? [
        {
          'name': payloadData?.city_name,
          'id': payloadData?.region_id,
          'secondary_label': payloadData?.city_name,
        }
      ] : [],
    };

  }

  useEffect(() => {
    if (window.innerWidth < 768) {
      setMobile(true);
    }
    if (payloadData) {
      setselectedValue(payloadData?.city_name);
      setSelectedId(payloadData?.region_id);
      if (payloadData?.address) {
        setAddress(payloadData?.address)
      }
    }
  }, []);

  const handleOnSearch = (query) => {
    dispatch(commonActions.hotelSearchAutoComplete(query));
  };


  const handleOnSelect = (value, field, formikProps) => {
    formikProps.setFieldValue(field, value);
    setselectedValue(value[0]?.secondary_label);
    setSelectedId(value[0]?.id);
    setAddress(value[0]?.address)
    setIsLoading(false);
    if (value[0]) {
      window.localStorage.setItem('autoCompleteData', JSON.stringify(value[0]));
      handleAutocompleteClick(false);
    } else {
      setselectedValue(JSON.parse(window.localStorage.getItem('autoCompleteData'))?.secondary_label);
    }
  };

  const handleDateChange = (value, formikProps, field) => {
    formikProps.setFieldValue(field, value);
    if (field === 'check_out' && value) setDateResponsive(false);
  };

  const setMobileDropdown = () => {
    setIsFilter(true);
  };

  const handleAutocompleteClick = () => {
    setshowAutocomplete(!showAutocomplete);
  };

  const handleDateClick = () => {
    setDateResponsive(!isDateResponsive);
  };

  const showResponsive = () => {
    setResponsive(true);
    handleAutocompleteClick(false);
  };

  const showDateResponsive = () => {
    setDateResponsive(true);
  };


  const handleSubmit = (data) => {
    window.localStorage.removeItem('hotel_search_key');

    const payload = {
      region_id: selectedId,
      city_name: selectedValue,
      address: address,
      search_type: 'hotel',
      check_in_date: moment(data.check_in).format('YYYY-MM-DD'),
      check_out_date: moment(data.check_out).format('YYYY-MM-DD'),
      rooms: data?.rooms?.map((ele) => {
        return {
          adults: ele.adults,
          children: ele.children.map((ele) => ele.value),
        };
      }),
      currency: currency.isoCode,
      locale: 'en-US',
      nationality: 'US',
      type_name: data?.goingTo[0].type_name,
      secondary_display_name: data?.goingTo[0].secondary_display_name,
      paging: {
        page_number: 1,
        per_page: 15
      },
      sort: { field: 'savings_percentage', order: 'desc' }
    }

    window.localStorage.removeItem('hotelParentLocation');
    window.localStorage.removeItem('hotelFilter');
    window.localStorage.removeItem('neighbourhoodsSelection');
    window.localStorage.setItem('mapPayload', JSON.stringify(payload));
    window.localStorage.setItem('hotelPayload', JSON.stringify(payload));

    if (isFromDetailPage) {
      nagivate('/hotel-search-result');
    } else if (handleSearchHotel) {
      handleSearchHotel(null, payload)
      // dispatch(newSearchHotelAction(payload));
    }
  };

  const renderForm = (formikProps) => {
    const { values, handleSubmit, handleBlur } =
      formikProps;
    let adultNumber = 0, childNumber = 0, adultChildrenCount = 0, childCount = 0;

    adultNumber = payloadData?.rooms
      .map((data) => data.adults)
      .reduce((a, b) => a + b);

    childNumber = payloadData?.rooms
      .map((ele) => ele.children?.length)
      .reduce((a, b) => a + b);

    if (values?.rooms?.length > 0) {

      adultChildrenCount = values?.rooms
        .map((ele) => ele.adults)
        .reduce((a, b) => a + b);

      childCount = values?.rooms
        .map((ele) => ele.children?.length)
        .reduce((a, b) => a + b);
    }

    return (
      <div className={hideFilter ? ' hide-content' : 'filterCnt '}>
        <form onSubmit={handleSubmit}>
          <div className={isFilter ? 'filterCnt2 openFilter' : 'filterCnt2'}>
            {!isFilter && (
              <div className="mobileShow">
                <div className="mobileSearchBlock" onClick={setMobileDropdown}>
                  <span className="fa fa-search"></span>
                  <div className="selectSearchCnt">
                    <h4>{payloadData?.city_name}</h4>
                    <p>
                      {payloadData?.check_in_date} —{' '}
                      {payloadData?.check_out_date}{' '}
                      <i className="fa fa-circle"></i>
                      {payloadData?.rooms.length}
                      {payloadData?.rooms.length > 1 ? ' Rooms' : ' Room'}
                      <i className="fa fa-circle"></i>{' '}
                      {adultNumber + childNumber}
                      {childNumber ? ' Guest' : ' Adult'}
                      {adultNumber > 1 ? 's' : ''}
                    </p>
                  </div>
                  <i className="fa fa-angle-down"></i>
                </div>
              </div>
            )}
            <div className="container dflex mobileHide">
              <div className="locationStartEndWrap dflex">
                {!isMobile && (
                  <div
                    className={
                      !showAutocomplete
                        ? 'inputStyle showDropdown'
                        : 'inputStyle showDropdown'
                    }
                  >
                    <div className="dropdown-content">
                      <span />
                      <SearchAutocomplete
                        {...formikProps}
                        label=""
                        options={hotelSearchAutoCompleteRes}
                        name="goingTo"
                        onSearch={(query) => handleOnSearch(query)}
                        onChange={(selected) => {
                          handleOnSelect(selected, 'goingTo', formikProps);
                        }}
                        defaultSelected={[JSON.parse(window.localStorage.getItem('autoCompleteData'))]}
                        value={values.goingTo}
                        onBlur={handleBlur}
                        isLoading={searchLoading}
                        placeholder="Going To"
                        icon="icon-location-on"
                        drop={true}
                      />
                    </div>
                  </div>
                )}
                {/* Search for Mobile */}

                {isMobile && (
                  <div className="responsive-input">
                    <button
                      className="inputStyle grow1"
                      onClick={showResponsive}
                      type="button"
                    >
                      {' '}
                      <i className="icon-location-on" />{' '}
                      {selectedValue || 'Going to'}
                    </button>
                  </div>
                )}
                {isResponsive && showAutocomplete && (
                  <div className="inputStyle showDropdown">
                    <div className="mobilePopup">
                      <Link to="#" className="closePopup">
                        <span
                          className="icon-close"
                          onClick={() => handleAutocompleteClick()}
                        />
                      </Link>
                      <h4 className="inputPopTitle">
                        Where are you leaving from?
                      </h4>
                      <div className="dropdown-content">
                        <span />
                        <SearchAutocomplete
                          {...formikProps}
                          label=""
                          options={hotelSearchAutoCompleteRes}
                          name="goingTo"
                          onSearch={(query) => handleOnSearch(query)}
                          onChange={(selected) => {
                            handleOnSelect(selected, 'goingTo', formikProps);
                          }}
                          autoFocus={true}
                          onBlur={handleBlur}
                          isLoading={isLoading}
                          defaultSelected={[JSON.parse(window.localStorage.getItem('autoCompleteData'))]}
                          value={values.goingTo}
                          // onClick={handleAutocompleteClick}
                          placeholder="Search by city or airport"
                          icon="icon-location-on"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* date & room and search */}
              <div className="dateAndBtnWrap">
                {!isMobile && (
                  <div className="Date-picker-range">
                    <DateRangePickerComponent
                      name="dates"
                      startDate={values.check_in}
                      endDate={values.check_out}
                      startDateId="check_in"
                      endDateId="check_out"
                      focused2={focused2}
                      setFocused2={setFocused2}
                      isRequired
                      icon="icon-invitation"
                      orientation="horizontal"
                      openDirection="down"
                      startDatePlaceholderText="Check-in"
                      endDatePlaceholderText="Check-out"
                      onDatesChange={({ startDate, endDate }) => {
                        handleDateChange(startDate, formikProps, 'check_in');
                        if (moment(endDate).diff(moment(startDate), 'days') > 20) {
                          handleDateChange(null, formikProps, 'check_out');
                        } else {
                          handleDateChange(endDate, formikProps, 'check_out');
                        }
                      }}

                      isOutsideRange={(day) => {
                        if (focused2 === 'startDate') return day.isBefore(moment().subtract(0, 'days'));
                        if (focused2 === 'endDate') {
                          return (
                            day.isBefore(moment(values?.check_in).subtract(0, 'days')) || day.isAfter(moment(values?.check_in).add(20, 'days'))
                          );
                        }
                      }}
                    />
                  </div>
                )}
                {isMobile && (
                  <div className="date-picker-type">
                    <div className="check-in-date date-picker-style">
                      <i className="icon-invitation" />
                      <Button
                        className="inputStyle grow1"
                        onClick={() => {
                          showDateResponsive()
                          setFocused2('startDate')
                        }}
                        type="button"
                        text={
                          values?.check_in
                            ? moment(values?.check_in).format('DD/MM/YYYY')
                            : 'Check-in'
                        }
                      />
                    </div>

                    <div className="check-out-date date-picker-style">
                      <i className="icon-invitation" />
                      <Button
                        className="inputStyle grow1"
                        onClick={() => {
                          showDateResponsive()
                          if (focused2 === null) {
                            setFocused2('startDate')
                          } else {
                            setFocused2('endDate')
                          }
                        }}
                        type="button"
                        text={
                          values?.check_out
                            ? moment(values?.check_out).format('DD/MM/YYYY')
                            : 'Check-Out'
                        }
                      />
                    </div>
                  </div>
                )}
                {isMobile && isDateResponsive && (
                  <div className="inputStyle showDropdown">
                    <div className="date-picker-popup ">
                      <Link to="#" className="closePopup">
                        <span
                          className="icon-close"
                          onClick={() => handleDateClick()}
                        />
                      </Link>
                      <h4 className="inputPopTitle">Going to</h4>
                      <div className="Date-picker-range">
                        <DateRangePickerComponent
                          isDateResponsive={'startDate'}
                          name="dates"
                          startDate={values.check_in}
                          endDate={values.check_out}
                          startDateId="check_in"
                          endDateId="check_out"
                          focused2={focused2}
                          setFocused2={setFocused2}
                          numberOfMonths={12}
                          openDirection="down"
                          isRequired
                          icon="icon-invitation"
                          orientation="verticalScrollable"
                          startDatePlaceholderText="Check-in"
                          endDatePlaceholderText="Check-out"
                          onDatesChange={({ startDate, endDate }) => {
                            handleDateChange(startDate, formikProps, 'check_in');
                            if (moment(endDate).diff(moment(startDate), 'days') > 20) {
                              handleDateChange(null, formikProps, 'check_out');
                            } else {
                              handleDateChange(endDate, formikProps, 'check_out');
                            }
                          }}
                          isOutsideRange={(day) => {
                            if (focused2 === 'startDate') return day.isBefore(moment().subtract(0, 'days'));
                            if (focused2 === 'endDate') {
                              return (
                                day.isBefore(moment(values?.check_in).subtract(0, 'days')) || day.isAfter(moment(values?.check_in).add(20, 'days'))
                              );
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div
                  className={
                    !open
                      ? 'dropdownWrap dropDownMobilePopup toggleClass'
                      : 'dropdownWrap dropDownMobilePopup toggleClass openDropdown'
                  }
                >
                  <Button
                    className="btnSml width180 toggleBtn"
                    type="button"
                    onClick={() => setOpen(!open)}
                    text={`${values?.rooms.length} Room${values.rooms.length > 1 ? 's' : ''
                      }, ${adultChildrenCount + childCount}  ${childCount ? 'Guest' : 'Adult'
                      }${adultChildrenCount > 1 ? 's' : ''}`}
                    icon="fa fa-angle-down"
                  />
                  <div className="dropdownInWrap cabin-class-wrapper">
                    <div className="dropDownSubWrap">
                      <div className="cabinClassWrapper">
                        <Link className="popupCloseBtn toggleBtn" to="#">
                          <span
                            className="icon-close"
                          // onClick={() => setOpen(!open)}
                          />
                        </Link>
                      </div>
                      {open && (
                        <AddRoomForm
                          isOpen={open}
                          setOpen={setOpen}
                          inputFields={values?.rooms}
                          roomObject={roomObject}
                          formikProps={formikProps}
                          isListing={true}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="mobileBtnWrap">
                  <a
                    href="#"
                    className="mobileShow closeFilter"
                    onClick={() => setIsFilter(false)}
                  >
                    Close <i className="fa fa-angle-up"></i>
                  </a>
                  <button
                    onClick={() => {
                      setOpen(false)
                      if (!isFromDetailPage && setShowMap) {
                        setShowMap(false)
                      }
                    }}
                    className="btnBorder"
                    type="submit">
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  };
  return (
    <>
      {initialValues && (
        <Formik initialValues={initialValues} onSubmit={handleSubmit} innerRef={form}>
          {renderForm}
        </Formik>
      )}
    </>
  );
}

export default HotelWidget;
