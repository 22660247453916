/* eslint-disable react/no-unescaped-entities */
import Modal from 'react-modal';
import CloseIcon from '../../../Assets/img/close-blue-icon.png';

const CancelPolicyModal = ({ isOpen, closeModal, policies }) => {
  return (
    <div className="detailPageWrap">
      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        className="popup cancellation-policy-popup popup-open "
      >
        <div className="popup-content-wrap  ">
          <div className="popup-content">
            <div className="popup-title">
              <h3>Cancellation Policy</h3>
              <button
                className="btn close room-details-popup-close-btn"
                onClick={() => closeModal(false)}
              >
                <img src={CloseIcon} alt="icon" />
              </button>
            </div>
            <p>
              Refundable cancellations (as laid out in our{' '}
              <a target="_blank" href="/terms-conditions">Terms and Conditions </a>) will be subject to a 3.2% merchant processing fee
            </p>
            <p>
              Booking charges to your Debit/Credit Card will appear in the name
              of "Tripello" and/or "Stripe"
            </p>

            <div>
              {policies?.map((policy, index) => (
                <h5 key={index}>{policy.policy}</h5>
              ))}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CancelPolicyModal;
