import React, {useState } from "react";
import IcomoonReact from "icomoon-react";
import iconSet from "../../Assets/fonts/selection.json";

import whatsAppImg from "../../Assets/img/whatsapp-bg.png";
import telegramImg from "../../Assets/img/telegram-bg.png";
import tiktokImg from "../../Assets/img/tiktok.png";
import instagramImg from "../../Assets/img/instagram-bg2.png";
import pinterestImg from "../../Assets/img/pinterest.png";
import facebookImg from "../../Assets/img/facebook-bg2.png";
import linkedinImg from "../../Assets/img/linkedin-bg.png";
import twitterImg from "../../Assets/img/twitter-bg2.png";
import exampleImage from "../../Assets/img/air.png";


// Sharing social media link
function Link({
  heading2,
  para1,
  para2,
  contentCopyImg,
  socialMediaList,
  urlLinkText,
}) {
 
  const socialLinkList = [
    {
      href: "https://web.whatsapp.com",
      image: (
        // <WhatsappShareButton
        //   url={urlLinkText}
        //   title={title}
        //   separator=":: "
        //   className="Demo__some-network__share-button"
        // >
          <img src={whatsAppImg} alt="social icon" />
        //   </WhatsappShareButton>  
      ),
    },
    {
      href: "https://web.telegram.org",
      image: (
        // <TelegramShareButton title={title} url={urlLinkText}>
          <img src={telegramImg} alt="social icon" />
        // </TelegramShareButton>
      ),
    },
    {
      href: "https://www.tiktok.com",
      image: <img src={tiktokImg} alt="social icon" />,
    },
    {
      href: "https://www.instagram.com/",
      image: <img src={instagramImg} alt="social icon" />,
    },
    {
      href: "https://in.pinterest.com/",
      image: (
        // <PinterestShareButton
        //   url={String(window.location)}
        //   media={`${String(window.location)}/${exampleImage}`}
        //   className="Demo__some-network__share-button"
        // >
          <img src={pinterestImg} alt="social icon" />
        // {/* </PinterestShareButton> */}
      ),
    },
    {
      href: "https://www.facebook.com/",
      image: (
        // <FacebookShareButton
        //   url={urlLinkText}
        //   quote={title}
        //   className="Demo__some-network__share-button"
        // >
          <img src={facebookImg} alt="social icon" />
        // </FacebookShareButton>
      ),
    },
    {
      href: "https://in.linkedin.com/",
      image: (
        // <LinkedinShareButton
        //   url={urlLinkText}
        //   className="Demo__some-network__share-button"
        // >
          <img src={linkedinImg} alt="social icon" />
        // {/* </LinkedinShareButton> */}
      ),
    },
    {
      href: "https://twitter.com/",
      image: (
        // <TwitterShareButton
        //   url={urlLinkText}
        //   title={title}
        //   className="Demo__some-network__share-button"
        // >
          <img src={twitterImg} alt="social icon" />
        // </TwitterShareButton>
      ),
    },
  ];

  const [msgShow, setMsgShow] = useState(false);

  const MsgShowFun = () => {
    setMsgShow((prev) => !prev);
    setTimeout(() => {
      setMsgShow((prevVal) => !prevVal);
    }, 2000);
  };

  const copied = "Copied!";

  return (
    <>
      <p>{para1}</p>
      <div
        className="copyLinkBox"
        onClick={async() => {
          var $temp = document.createElement("input");
          // eslint-disable-next-line no-undef
          $("body").append($temp);
          $temp.value =urlLinkText;
          $temp.select();
          document.execCommand("copy");
          MsgShowFun();
          $temp.remove();
        }}
        style={{ cursor: "pointer" }}
      >
        <img src={contentCopyImg} alt="" style={{ cursor: "pointer" }} />
        <h4>{msgShow ? copied : heading2}</h4>
      </div>
      <p>{para2}</p>
      {socialLinkList ? (
        <div className="sharingIconsList">
          <ul className="d-flex flex-wrap list-unstyled">
            {socialLinkList.map((item, index) => (
              <li key={`${index}mediaIcon`}><a href={item.href} target="_blank" rel="noreferrer"> {item.image} </a></li>
            ))}
          </ul>
        </div>
      ) : (
        <div className="sharingIconsList">
          <ul className="d-flex flex-wrap list-unstyled">
            {socialMediaList?.map((item, index) => (
              <li key={`${index}mediaIcon1`}>{item.image}</li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}

export default Link;