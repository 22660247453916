import React, { useEffect, useState } from "react";
import Button from "../../../Component/FormComponent/Button";
import ReviewBarCol from "../../../Component/Hotel/ReviewBarCol";
import ReviewCommentCard from "../../../Component/Hotel/ReviewCommentCard";
import TravelersCircularBar from "../../../Component/Hotel/TravelersCircularBar";
import ReviewTab from "./ReviewTab";
import { isEmpty } from "lodash";

function Reviews({ reviews }) {
  const [showMoreReviews, setshowMoreReviews] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    setshowMoreReviews(reviews?.tabs[selectedTab]?.subtitle_text?.length > 4);
  }, [selectedTab]);
  return (
    <div id="reviews" className="rewievsWrapper">
      <div className="sectionTitle">
        <h2>Reviews</h2>
      </div>
      {reviews !== null ? (
      <div className="rewievsInfo">
          <div className="row no-gutters">
          <div className="col leftContent">
            <div className="contentWrap">
              <div className="ratingComment">
                <h3 className="commentCount">{reviews?.rating}</h3>
                <div className="verifiedReviews">
                  <h4>{reviews?.title}</h4>
                  <p>{reviews?.review_count} verified reviews</p>
                </div>
              </div>
              <ReviewBarCol
                reviewTypeData={
                  !isEmpty(reviews) && reviews?.tabs
                    ? reviews?.tabs[selectedTab].subtitle_text
                    : []
                }
              />
            </div>
            <div className="typesofTravelers">
              <div className="text-center heading">
                <h3>Types of Travelers</h3>
              </div>
              <TravelersCircularBar
                typesOfTravlers={
                  !isEmpty(reviews) && reviews?.tabs ? reviews?.tabs : []
                }
              />
            </div>
          </div>

          <div className="col rightContent">
            <ReviewTab
              setSelectedTab={setSelectedTab}
              selectedTab={selectedTab}
              tabs={!isEmpty(reviews?.tabs) ? reviews?.tabs : []}
            />

            {reviews?.tabs[selectedTab]?.subtitle_text?.map((item, index) => {
              if (index > 4 && showMoreReviews) return;
              return <ReviewCommentCard text={item?.text} key={index} />;
            })}

            {reviews?.tabs[selectedTab]?.subtitle_text?.length > 5 && (
              <div className="showMoreLink text-center">
                <Button
                  className="show-more-reviews-popup"
                  onClick={() => setshowMoreReviews(!showMoreReviews)}
                >
                  {showMoreReviews
                    ? "Show More Reviews  "
                    : "Show Less Reviews "}
                  <i
                    className={`fa fa-angle-${
                      !showMoreReviews ? "up" : "down"
                    }`}
                  ></i>
                </Button>
              </div>
            )}
          </div>
        </div>
        </div>
        ) : (
          <div className="review-empty-box">
            <h4>No Reviews Found</h4>
          </div>
        )}
    </div>
  );
}

export default Reviews;
