/* eslint-disable no-irregular-whitespace */
import React from "react";
import HeaderNavbar from "../../Component/HeaderNavbar";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import Scrollbars from "react-custom-scrollbars";
import TermsTab from "../Tabs/TermsTab";

function PrivacyPolicy() {
  return (
    <div className="siteBg FaqPageWrap Terms-wrapper Privacy-wrapper">
      <div className="pageWrap faq-scroll">
        <div className="fixedHeader">
          <HeaderNavbar noHeader={!localStorage.getItem("accessToken") ? true : false}/>
        </div>
      </div>
      <section className="mainContent">
        <Scrollbars
          autoHeight={true}
          autoHeightMin={120}
          autoHeightMax={`calc(100%)`}
          className="pageInternalScroll"
        >
          <div className="container pageInternalScrollTopPadding">
            <div className="row no-gutters rightContent-bottom">
              <TermsTab />
              <div className="col rightContent content-wrapper cookies-content">
                <div className="faqWrapper card2">
                  <h1> Privacy Policy</h1>
                  <span>October 10, 2022</span>

                  <h2>Introduction</h2>
                  <p>Tripello, Inc. (“Company” or “Tripello,” or “we,” “our,” “us”) is dedicated to protecting your privacy
                    and safeguarding your personally identifiable information. We are committed to adhering to the
                    principles of applicable data protection and privacy laws. Tripello, Inc. respects your privacy and is
                    committed to protecting it through compliance with this notice. This notice describes the types of
                    information we may collect from you or that you may provide when you visit the websites: <a  target="_blank" href="https://tripello.com" rel="noreferrer">www.tripello.com</a>, <a href="https://tripellonetwork.com"> www.tripellonetwork.com</a>, <a href="https://tripello.com"> www.tripellonetwork.com</a>,
                    <a target="_blank" href="https://tripello.com/blog/" rel="noreferrer"> www.tripellohub.com</a>, (our “Websites”) or when you
                    opt into receiving text or email messages from us (Electronic Messaging) and our practices for
                    collecting, using, maintaining, protecting, and disclosing that information. The Websites and
                    Electronic Messaging are collectively referred to in this notice as the Services. This notice applies to
                    information we collect (i) on the Websites; (ii) in email, text, and other electronic messages between
                    you and us; and (iii) when you interact with our advertising on other websites or third-party websites
                    and services. Please read this notice carefully to understand our policies and practices regarding
                    your information and how we will treat it. If you do not agree with our policy and practices, your
                    choice is not to use the Services. By using the Services, you agree to this privacy notice. This notice
                    may change from time to time. Your continued use of the Services after we make changes is deemed
                    to be acceptance of those changes, so please check the notice periodically for updates.</p>


                  <h2>
                    Types of Information We Collect
                  </h2>
                  <p>
                    We collect several types of information from and about users of our Services, including information:
                    (i) by which you may be personally identified, such as name, address, email address, and telephone
                    number; (ii) that is about you but individually does not identify you, such as your age or gender; (iii)
                    about your internet connection, the equipment you use to access the Services, and usage details. We
                    collect this information: (i) directly from you when you provide it to us; and (ii) automatically as you
                    navigate through the Services. Information collected automatically may include usage details, IP
                    addresses, and information collected through cookies and other tracking technologies. The
                    information we collect on or through our Website may include: (i) information that you provide by
                    filling in forms on our Website; (ii) records and copies of your correspondence; and (iii) details of
                    transactions you carry out with us or the fulfillment of your orders. As you navigate through and
                    interact with our Services, we may use automatic data collection technologies, such as Google
                    Analytics, to collect certain information about your equipment, browsing actions, and patterns,
                    including: (i) details of your visits to our Website, including traffic data, location data, logs, and other
                    communication data and the resources that you access and use on the Website; and (ii) information
                    about your computer and internet connection, including your IP address, operating system, and
                    browser type. We also may use these technologies to collect information about your online activities
                    over time and across third-party websites or other online services (behavioral tracking).
                  </p>

                  <h2>
                    Online Tracking
                  </h2>
                  <p>If you choose not to provide requested information, you may not be able to use some of the
                    features of the Services. Tripello, Inc. does not respond to web browser “do not track” signals or
                    other mechanisms that provide consumers the ability to exercise choice about behavioral tracking.
                  </p>

                  <h2>
                    Purpose of Collection &amp; Use
                  </h2>

                  <p>
                    We may use and share the Personal Information and other information collected (unless otherwise
                    restricted by applicable law):
                  </p>
                  <ul>
                    <li>To operate, provide, and enhance the Services and to provide the products, information and
                      services you request or sign up for through the Services or that may be of interest to you.
                      For example, we will use your Personal Information to contact you with respect to
                      information you requested or with respect to offering or providing the Services, for any
                      administrative, maintenance and customer service purposes, to facilitate a third party’s
                      provision of services you request, such as third-part custodian services, and to address
                      issues such as intellectual property infringement, rights of privacy, or defamation claims;
                    </li>
                    <li>
                      to track and analyze your usage trends and preferences in order to improve the Website and
                      Services;
                    </li>
                    <li>
                      to personalize our Services and to monitor the operation and effectiveness of the Services;
                    </li>
                    <li>
                      to analyze aggregate Website usage metrics;
                    </li>
                    <li>
                      to provide you with customized third-party content, and information;
                    </li>
                    <li>
                      to send you messages, including to notify you about changes to our Services or any products
                      or services we offer or provide through the Services and promotional messages related to
                      the Services and of third parties we work with. You will have the ability to opt-out of
                      receiving any such communications, through links provided in the messages;
                    </li>
                    <li>
                      to engage in transactions with you and to carry out our obligations and enforce our rights
                      arising from any contracts entered into between you and us;
                    </li>
                    <li>
                      to respond to your requests;
                    </li>
                    <li>
                      to share your Personal Information with other selected third parties, affiliates and
                      subsidiaries that offer complementary products or services. In such an event, please
                      review that company’s privacy policy regarding any further interactions with them.
                    </li>
                    <li>
                      to obtain third party services from those who provide services to us, such as analytics, web
                      site management, payment processing, information technology and related
                      infrastructure, customer service, e-mail delivery, auditing, and other similar services. When
                      we share Personal Information with third party service providers, we require that they use

                      your Personal Information only for the purpose of providing services to us and subject to
                      terms consistent with this notice.
                    </li>
                    <li>
                      as we believe to be necessary or appropriate to protect the health and safety of our
                      employees and operations or the employees or operations of any of our third-party
                      service providers.
                    </li>
                    <li>
                      as we believe to be necessary or appropriate: (a) under applicable law, including laws outside
                      your country of residence; (b) to comply with legal process; (c) to respond to requests
                      from public and government authorities, including public and government authorities
                      outside your country of residence, for national security and/or law enforcement purposes;
                      (d) to enforce our terms and conditions; and (e) to allow us to pursue available remedies
                      or limit the damages that we may sustain. Additionally, in the event of a reorganization,
                      merger, sale, joint venture, assignment, transfer or other disposition of all or any portion
                      of our business, assets or stock (including in connection with any bankruptcy or similar
                      proceedings), we may transfer the Personal Information we have collected to the relevant
                      third party.
                    </li>
                  </ul>

                  <p>
                    We may use, transfer, and disclose other information we collect for any purpose, except where
                    applicable law requires otherwise. If we are required to treat other information as Personal
                    Information under applicable law, then we will only use it in the same way that we are permitted to
                    use and disclose Personal Information.
                  </p>

                  <h2>
                    Collection of Information
                  </h2>

                  <p>
                    <strong>Direct Interactions:</strong> From your use of and interaction with us through the Services and other
                    activities such as submission of forms.
                  </p>
                  <p>
                    <strong>Electronic Communications Protocols: </strong> We may automatically receive information from you as part of
                    the communication connection itself, which often consists of network routing information (where
                    you came from), equipment information (browser type or device type), your IP address (which may
                    identify your general geographic location or company), and date and time.
                    We may also automatically receive and record information about your interaction with the Services,
                    such as how often you access the Services, performance data, aggregate usage, or general geo-
                    location data.
                  </p>
                  <p>
                    <strong>Cookies:</strong> We may use cookies. A cookie is a small piece of information sent by a web server to store
                    on a web browser so it can later be read back from that browser. Cookies may collect information,
                    including a unique identifier, user preferences, profile information, membership information, and
                    general usage and volume statistical information. Cookies may also be used to collect individualized
                    web site use data, provide Website personalization, or conduct and measure the effectiveness of
                    advertising in accordance with this notice. Some cookies may remain on users’ computers after they
                    leave the Website. While many are set to expire within 1-24 months of your last visit to the website
                    that set the cookie, others may not expire because of their nature, like cookies that remember opt-
                    out preferences.
                  </p>
                  <p>Your browser may provide you with information and control over cookies. You can set your browser
                    to alert you when a cookie is being used, and accept or reject the cookie. You can also set your
                    browser to refuse all cookies or accept only cookies returned to the originating servers. Users can
                    generally disable the cookie feature on their browser. Cookies, however, are important to the
                    proper functioning of a site, and disabling them may degrade your experience and interfere with
                    Website features and customizations.
                    We may engage one or more third party service providers to track and analyze both individualized
                    usage and volume statistical information from interactions with the Website. The service provider(s)
                    may set cookies on our behalf. We may also use third party cookies to provide advertising and
                    personalization services in accordance with this notice, and to track the performance of our
                    advertisements and emails.
                  </p>

                  <p>
                    <stong>Managing Cookie Preferences:</stong> Your browser should allow you to access or change your cookie
                    preferences at any time.
                  </p>

                  <p>
                    <storng>Flash Cookies: </storng> We may use Flash cookies, also known as “local shared objects,” on our Website that
                    employ Flash technology. Flash cookies are used to remember the Website’s settings to personalize
                    the look and feel of the Website. Like normal cookies, Flash cookies are represented as small files on
                    your computer. You may be able to prevent Flash cookies from being placed by adjusting your
                    browser’s settings.
                  </p>

                  <p>
                    <stong> Embedded URLs:</stong> We may use a tracking technique that employs embedded URLs to allow use of the
                    Website without cookies. Embedded URLs allow limited information to follow you as you navigate
                    the Website, but are not associated with Personal Information and are not used beyond the session.
                  </p>
                  <p>
                    <strong>Embedded Pixels and Similar Technologies:</strong>   We may use embedded pixel technologies for the
                    purposes of identifying unique user visits (as opposed to aggregate hits), and for advertising
                    purposes. In addition, embedded pixels or other technologies may be used in e-mails and our online
                    display advertising to provide information on when the e-mail or ad was opened to track marketing
                    campaign responsiveness; information collected using these technologies may be associated with
                    your e-mail address.
                  </p>
                  <p>
                    <strong> Physical Location:</strong> We may collect and share the physical location of your device for purposes
                    consistent with this notice, such as to provide you with personalized location-based services or
                    content. In some instances, you may be permitted to allow or deny such use of your device’s
                    location, but if you choose to deny such use, we may not be able to provide you with the applicable
                    personalized services or content.
                  </p>

                  <h2>
                    Preferences and Opt-Out
                  </h2>
                  <p>
                    You may opt out of e-mail marketing by using our general unsubscribe automated link that is
                    included in our marketing e-mails. If you have any problems using any of these opt-out mechanisms,
                    please contact us.
                  </p>
                  <p>
                    Some non-marketing communications are not subject to general opt-out, such as communications
                    related to customer services and disclosures to comply with legal requirements.
                  </p>
                  <p>
                    If you do not want us to use your Personal Information to notify you of products or services of
                    interest to you or if you do not want us to share your Personal Information with selected third
                    parties, affiliates and subsidiaries, please opt out by contacting us via email at
                  </p>

                  <p><a href="">compliance@Tripello.com </a> or by mail at:</p>
                  <p>Tripello, Inc.</p>
                  <p>Attn: Privacy Notice Manager</p>
                  <p>PO Box 27740</p>
                  <p>Las Vegas, NV 89126-7740</p>
                  <p>
                    United States
                  </p>
                  <p>If you have consented to receiving text messages or auto-dialed telephone calls from us, you may
                    revoke that consent by writing, emailing, or calling us or by any other reasonable means.
                  </p>

                  <p><strong>Cookies and Do Not Track Preferences.</strong> You may opt out of cookies by using your browser settings.
                    We do not process or recognize any Do Not Track signals.</p>
                  <p>
                    <stong>Information-Sharing Choices.</stong> We may only share Personal Information with selected companies
                    after providing you the ability to make choices about the sharing, or as otherwise described in this
                    notice. There are limitations to opting out of such sharing in the case of: (1) where you have
                    previously requested the sharing of your Personal Information with these companies; (2) where third
                    parties are required to perform a service in connection with our provision of Services to you; and (3)
                    where required by law.
                  </p>

                  <h2>
                    Access, Corrections, and Updates
                  </h2>
                  <p>
                    You may request to review, correct, update, suppress, remove, or otherwise modify any of your
                    Personal Information that you have previously provided to us through the Website. You have the
                    ability to update your Personal Information by contacting us at <a href="https://membersupport@tripello.com"> membersupport@tripello.com</a>, or by
                    mail at:
                  </p>

                  <p>
                    Tripello, Inc.
                  </p>
                  <p>Attn: Privacy Notice Manager</p>
                  <p> PO Box 27740</p>
                  <p>Las Vegas, NV 89126-7740</p>
                  <p>United States </p>

                  <p>You have the right to data portability for the information you provide to us. You can request to
                    obtain a copy of your Personal Information in a commonly used electronic format so that you can
                    manage and move it.</p>
                  <p>In certain circumstances, you have the right:</p>

                  <ul>
                    <li>To access and receive a copy of the Personal Information we hold about you;</li>
                    <li>To rectify any Personal Information held about you that is inaccurate;</li>
                    <li>To request the deletion of Personal Information held about you.</li>
                  </ul>

                  <p>Please note that you may be asked to verify your identity before receiving a response to such
                    requests. Please be aware that if you delete your Personal Information, you may not be able to
                    continue to use our Services. Please note that if you request that we delete your Personal
                    Information, we may in our sole discretion retain certain information, including your Personal
                    Information, to satisfy our legal, audit and/or dispute resolution requirements. We may also retain
                    your non-Personal Information indefinitely. If you have privacy concerns regarding access to or the
                    correction of your Personal Information, please contact us at <a href="https://membersupport@tripello.com"> membersupport@tripello.com </a>or by</p>

                  <p>
                    mail at:
                  </p>
                  <p>Tripello, Inc.</p>
                  <p>Attn: Privacy Notice Manager</p>
                  <p>PO Box 27740</p>
                  <p>Las Vegas, NV 89126-7740</p>
                  <p>
                    United States
                  </p>

                  <p>In your request, please make clear what Personal Information you would like to have changed,
                    whether you would like to have your Personal Information that you have provided to us suppressed
                    from our database or otherwise let us know what limitations you would like to put on our use of
                    your Personal Information that you have provided to us.</p>
                  <p>While the majority of questions and issues related to access can be handled quickly, complex
                    requests may take more research and time. In such cases, issues will be addressed, or you will be
                    contacted regarding the nature of the problem and appropriate next steps, within thirty days.</p>

                  <h2>Retention and Deletion</h2>
                  <p>We will retain your Personal Information for as long as your account is active; as needed to provide
                    you the Services; as needed for the purposes outlined in this notice or at the time of collection; as
                    necessary to comply with our legal obligations (e.g., to honor opt-outs), resolve disputes, and
                    enforce our agreements; or to the extent permitted by law.</p>
                  <p>At the end of the retention period, we will delete your Personal Information in a manner designed
                    to ensure that it cannot be reconstructed or read.</p>


                  <h2>Advertising and Third-Party Websites</h2>
                  <p>The Services may contain links to websites and services provided by third parties. Any personally
                    identifiable information you provide on third party sites or services is provided directly to that third
                    party and is subject to that third party’s policies, if any, governing privacy and security. We are not
                    responsible for the content or privacy and security practices and policies of third-party sites or
                    services. You should read the third parties’ privacy policies before providing them with your
                    personally identifiable information.</p>

                  <h2>Security</h2>

                  <p>The security of your Personal Information is very important to us. We use commercially reasonable
                    physical, electronic, and administrative safeguards that are designed to protect your Personal
                    Information from loss, misuse and unauthorized access, disclosure, alteration and destruction. We
                    cannot, however, ensure or warrant the security of any information you transmit to us, and your
                    transfer of your Personal Information to us is at your own risk.</p>

                  <p>In the event that your Personal Information is acquired, or is reasonably believed to have been
                    acquired, by an unauthorized person and applicable law requires notification, we will notify you by e-
                    mail, fax, or U.S. mail. We will give you notice promptly, consistent with the reasonable needs of law
                    enforcement and our company to determine the scope of the breach and to investigate and restore
                    the integrity of the data system.</p>

                  <h2>Cross-borders Transfers and International Uses</h2>

                  <p>This notice is intended to cover only customers and other users residing in the United States. If you
                    are outside of the United States, by visiting our Website and by providing us with your Personal
                    Information or data, you acknowledge and agree that we may use the Personal Information or data
                    collected in the course of our relationship for the purposes identified in this Privacy notice or in our
                    other communications with you, including the transmission of information outside your resident
                    jurisdiction. In addition, please understand that such Personal Information and data may be stored
                    on servers located in the United States. By providing us with your Personal Information or data, you
                    consent to the transfer of your Personal Information and other data to our servers, which may be
                    located anywhere in the world. Personal Information may be transferred, accessed and stored
                    globally as necessary in accordance with this notice.</p>

                  <h2>
                    Information Sent Abroad
                  </h2>

                  <p>Tripello, Inc. provides global services. In certain circumstances, sharing data cross-border is essential
                    to the services you receive from us.</p>

                  <p> <strong>I. Special Requirements under EU Data Protection Laws (GDPR):</strong></p>
                  <p>In order to complete your booking, complete analysis, or for other legitimate purposes we transfer,
                    process and store information about you outside of the EEA on servers located in the United States
                    by Tripello, Inc. and its affiliates</p>

                  <p>Therefore, your information may be transferred to, stored, or processed in the United States. We
                    will ensure that in terms of Data Security Standards, our systems in the US will have the same level
                    of data protection that is required of our EU affiliate. If we transfer your Personal Information to
                    agencies or third parties outside the EEA, we require similar, binding EU Standard Contractual
                    Clauses, and we monitor compliance with such. Our affiliated or related entities, associates and
                    partners have also agreed to adopt equivalent measures. Copies of these measures can be obtained
                    by contacting us as detailed under Contact Information below. You may lodge a complaint with the
                    UK Information Commissioner’s Office if you consider that our processing of your Personal
                    Information infringes applicable law.</p>

                  <p><strong>II. Special Requirements under Mexican Data Protection Laws</strong></p>
                  <p>In accordance with the requirements of the Mexican Federal Law on the Protection of Personal
                    Information Held by Private Parties (Ley Federal de Protección de Datos Personales en Posesión de
                    los Particulares) (the “Mexican DP Law”), We inform our users and subscribers located in Mexico of
                    Our compliance with the Mexican DP Law. Under Mexican DP Law, We do not need to obtain your
                    consent for the processing of your Personal Information for the purposes that give rise and are
                    necessary for the fulfillment of our relationship (e.g. personal information required in order for you
                    to subscribe to our vacation programs). In case you do not agree with the processing of your
                    personal information for such purposes and as described in this notice, please abstain from
                    acquiring or using our services.</p>

                  <p>Our Website users and subscribers located in Mexico should note that:</p>

                  <ul>
                    <li>The Personal Information provided by you to us through email, fax, websites, online services,
                      enrollment or subscription forms and agreements, telephone calls or any other means, is
                      processed by us for the purposes set forth in this notice; in the understanding that, in
                      order to comply with the Mexican DP Law, below we have set forth those purposes that
                      are not required to fulfill the relationship between us, commonly known as “additional or
                      secondary purposes”:</li>
                    <li>
                      To provide you with updates about discounted availability, special offers, new services and
                      products which may be of interest to you and other noteworthy news items.
                    </li>
                    <li>
                      Send advertisements, promotions and brochures.
                    </li>
                    <li>
                      To contact you about our and third parties’ goods and services that may be of interest to you.
                    </li>
                    <li>
                      To ask for ratings and reviews of services or products. Should you not wish that your
                      personal information be used for the purposes listed immediately above, please send an
                      email to <a href="https://membersupport@tripello.com"> membersupport@tripello.com </a> with subject line “Request for Restriction on the
                      use of My Personal Information for Secondary Purposes / Mexico”.
                    </li>
                    <li>
                      That this privacy notice applies to all your personal information that we may have access to,
                      including: name, nationality, birthdate and birthplace, age, address, telephone number,
                      cell phone number, email, tax identification number, hand written signature, as well as
                      personal information that may be considered sensitive and related to recorded video or
                      photographs, health condition, economic and financial data, including your bank account
                      information and information of your credit or debit cards, as well as any other Personal
                      Information listed in this notice.
                    </li>
                    <li>
                      As described above, we also collect information using various technologies, such as cookies
                      and web beacons.
                    </li>
                    <li>
                      That your personal information will be solely treated in accordance with the terms and
                      conditions of this privacy notice.
                    </li>
                    <li>
                      In order for you to: (i) exercise your rights to Access, Rectify, Cancel, and Object to the use of
                      your personal information (known as “ARCO Rights”), and (ii) to limit the use and
                      disclosure or to revoke your consent to the use of your personal information for the
                      secondary purposes described above, please send an email to
                      <a href="https://membersupport@Tripello.com "> membersupport@Tripello.com </a>with subject line “Exercise of ARCO Rights / Mexico”. The
                      request should include, as minimum:
                      <br />
                      <br />
<ul className="disck-li">
                      <li>
                        Copy of your official ID and/or of your legal representative. Such documents
                        should be scanned and attached to the corresponding email communication. For
                        legal representative, please also attach a copy of his/her power-of-attorney.
                      </li>
                      <li>Clear and precise description of the personal information about which the ARCO
                        Rights are to be exercised, as well as the right or rights you wish to exercise. This
                        description could be included in the email cover letter or in a document attached
                        thereto, scanned and initialized in each of its pages.</li>
                      <li>Expressly state your agreement to receive our response through an email
                        communication, specifying the corresponding email address.</li>
                      <li>Any other data that allows us to locate your Personal Information.</li>
                      </ul>
                      </li>
                  </ul>
                  <p>We will issue a response within the following 20 business days after we receive your request. Once
                    you receive our response, you will have a 15 business day period to respond to our communication.
                    In case you do not reply to our response within the mentioned period, we will understand in good
                    faith that you agree with our response. We may refuse the exercise of your ARCO Rights in the cases

                    permitted by applicable law, and shall inform you about such decision. The refusal may be partial, in
                    which case We will carry out the access, rectification, cancellation or objection in the corresponding
                    part. As mentioned below, you can also review and change your personal information by logging
                    into the Website and visiting your account profile page.</p>

                  <p>See the section Purpose of Collection and Use for information regarding the manner in which we
                    share your personal information with third parties and data-processors. Please note that we require
                    your consent to transfer your Personal Information to third parties to market their own products or
                    services to you. Should you not wish that your Personal Information be transferred to third parties
                    for their own marketing purposes please send an email to <a href="https://membersupport@tripello.com"> membersupport@tripello.com</a> with
                    subject line “Request for Restriction on the Transfer of Personal Information to Third Parties for
                    their Own Marketing Purposes / Mexico”.</p>
                  <p>
                    This privacy notice may be modified as described in the section “Changes to the Privacy Notice” set
                    forth below.
                  </p>

                  <h2>
                    Minors
                  </h2>
                  <p>Our Services are not directed to minors, we do not market to minors, and we do not knowingly
                    collect Personal Information from anyone under the age of 18. If you believe that we have
                    mistakenly or unintentionally collected Personal Information of a minor without appropriate
                    consent, please notify us so that we may immediately delete the information from our servers and
                    make any other necessary corrections.</p>

                  <h2>
                    Dispute Resolution
                  </h2>

                  <p>You have the right to lodge complaints about the data processing activities carried out by us before
                    the competent data protection authorities. If you have any complaints regarding our compliance
                    with this Notice, you should first contact us at <a href="https://compliance@tripello.com"> compliance@tripello.com</a> or by mail at:</p>
                  <p>Tripello, Inc.</p>
                  <p>Attn: Privacy Notice Manager</p>
                  <p>PO Box 27740</p>
                  <p>Las Vegas, NV 89126-7740</p>
                  <p>United States</p>
                  <p>We will investigate and attempt to resolve complaints and disputes regarding use and disclosure of
                    Personal Information in accordance with this Notice.</p>

                  <h2>California Privacy Rights</h2>
                  <p> California Civil Code Section § 1798.83 permits users of our Services that are California residents to
                    request certain information regarding our disclosure of personal information to third parties for
                    their direct marketing purposes. To make such a request, please send an e-mail
                    to <a href="https://membersupport@tripello.com"> membersupport@tripello.com</a> or by mail at:</p>

                  <p>Tripello, Inc.</p>
                  <p>Attn: Privacy Notice Manager</p>
                  <p>PO Box 27740</p>
                  <p>Las Vegas, NV 89126-7740</p>
                  <p>United States</p>

                  <p>Customers in the state of California may request a list of categorized third parties and in some cases
                    affiliates to whom we may have disclosed their personal information specifically for those third
                    parties’ and/or affiliates’ own marketing purposes, as well as the type of personal information
                    disclosed to those parties. Tripello, Inc. does not disclose your personal information to third parties
                    or affiliates for those third parties’ or affiliates’ own marketing purposes. If you are a California
                    resident and would like to more information about our compliance with this law, please email us at:
                    <a href="https://compliance@tripello.com"> compliance@tripello.com</a> or by mail at:</p>

                  <p> Tripello, Inc.</p>
                  <p>Attn: Privacy Notice Manager</p>
                  <p>PO Box 27740</p>
                  <p>Las Vegas, NV 89126-7740</p>
                  <p>United States</p>
                  <p>You must include your name, street address, city, state, and ZIP code. Please note that we are not
                    required to respond to requests made by means other than through the provided address listed.</p>


                  <h2>
                    Changes to our Privacy Notice
                  </h2>

                  <p>It is our policy to post any changes we make to our privacy notice on this page with a notification
                    that the privacy notice has been updated on the Website home page. If we make material changes
                    to how we treat our users’ personal information, we will notify you by e-mail to the primary e-mail
                    address specified in your account and/or through a notice on the Website home page. The date the
                    privacy notice was last revised is identified at the top of the page. You are responsible for ensuring
                    we have an up-to-date active and deliverable e-mail address for you, and for periodically visiting our
                    Website and this privacy notice to check for any changes.</p>


                  <h2>Contacting Us and Updating Your Communication Preferences</h2>
                  <p>If you would like to update your information, modify your communication preferences, or if you do
                    not want to receive marketing communications from Tripello, Inc. in the future, you can contact us
                    at <a href="https://membersupport@tripello.com"> membersupport@tripello.com</a> or by mail at:</p>

                  <p>
                    Tripello, Inc.
                  </p>
                  <p>Attn: Privacy Notice Manager</p>
                  <p>PO Box 27740</p>
                  <p>Las Vegas, NV 89126-7740</p>
                  <p>United States</p>
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>
      </section>
      <FooterWithMediaIcon className="white-icon" iconClassName="icon-white" />
    </div>
  );
}

export default PrivacyPolicy;
