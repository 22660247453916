import React, { useState } from "react";
import Button from "../FormComponent/Button";
import FlightDetailsModal from "../Modals/FlightDetailsModal";
import FareRule from "../Modals/FareRule";
import SingleFlightDetails from "./SingleFlightDetails";

function FlightInfoBox({ legs, flights }) {
  const [sampleModal, setSampleModal] = useState(false);
  const [fareRule, setFareRule] = useState(false);
  return (
    <div className="flightInfoBox card2">
      {flights?.flightSegments?.map((item, index) => {
        return (
          <SingleFlightDetails
            {...item}
            flightLegs={item}
            key={index}
            showFlightModal={sampleModal}
            setshowFlightModal={setSampleModal}
            flightSegments={item.flightLegs}
            segmentIndex={index}
          />
        );
      })}

      <div className="flightDetailsbtn">
        <div className="flightDetailsbtn-wrap">
          <Button
            type="button"
            className="detailsLink"
            onClick={() => setSampleModal(true)}
          >
            Flight Details <span className="fa fa-angle-down"></span>
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            type="button"
            className="detailsLink"
            onClick={() => setFareRule(true)}
          >
            Fare Rule <span className="fa fa-angle-down"></span>
          </Button>
        </div>
        {flights?.freeCancellation && (
          <h4>
            <span className="icon-timer"></span>{" "}
            <span className="green-text">12hr</span>Free Cancellation
          </h4>
        )}
      </div>
      {sampleModal && (
        <FlightDetailsModal
          isOpen={sampleModal}
          closeModal={setSampleModal}
          flights={flights}
        />
      )}
      {fareRule && (
        <FareRule
          isOpen={fareRule}
          closeModal={setFareRule}
          flights={flights}
        />
      )}
    </div>
  );
}

export default FlightInfoBox;
