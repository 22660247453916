import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import FlightSubDetails from "../FlightListing/FlightSubDetails";
import SingleFlightDetails from "../FlightListing/SingleFlightDetails";
import SingleFlightModal from "../FlightListing/SingleFlightModal";
import Button from "../FormComponent/Button";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { flightActions } from "../../Stores/Actions/flightActions";

Modal.setAppElement("#root");
function FlightDetailsModal({ isOpen, closeModal, flights }) {
  const dispatch = useDispatch();

  const [flightSegments, setFlightSegments] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isOpen) {
      document
        .getElementsByTagName("body")[0]
        .classList.add("siteBg", "listingPage", "listingFlight", "popupOpned");
    } else {
      document
        .getElementsByTagName("body")[0]
        .classList.remove(
          "siteBg",
          "listingPage",
          "listingFlight",
          "popupOpned"
        );
    }
  }, [isOpen]);
  useEffect(() => {
    return () => {
      document
        .getElementsByTagName("body")[0]
        .classList.remove(
          "siteBg",
          "listingPage",
          "listingFlight",
          "popupOpned"
        );
    };
  }, []);

  useEffect(() => {
    var payload = {
      sequenceId: flights?.sequenceId,
    };
    dispatch(flightActions.searchFlightDetailsAction(payload)).then((res) => {
      if (res.status === 200) {
        setFlightSegments(res?.data?.data?.flightSegments);
        setError(null);
      } else {
        setError(res?.data?.message);
      }
    });
  }, []);

  return (
    <>
      <Modal className="flightDetailsModal" isOpen={isOpen} ariaHideApp={false}>
        <div id="flightDetailPopup" className={isOpen ? "popupStyle" : ""}>
          <div className="popUpBox">
            <Button onClick={() => closeModal(false)} className="popupCloseBtn">
              <span className="icon-close"></span>
            </Button>
            <h3 className="popupTitle">Flight Details</h3>
            <div className="popupScrollBox">
              <div className="popupFlightBox">
                {flightSegments?.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="mobileSectionTitle">
                        {index == 0 &&
                          `Departure — ${moment(
                            item.flightLegs[0].departureDateTime
                          ).format("ddd,D MMM")}`}
                        {index == 1 &&
                          `Returning — ${moment(
                            item.flightLegs[item.flightLegs?.length - 1]
                              .arrivalDateTime
                          ).format("ddd,D MMM")}`}
                      </div>
                      <SingleFlightModal key={index} flightLegs={item} />
                    </React.Fragment>
                  );
                })}
                {error && <h1>{error}</h1>}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default FlightDetailsModal;
