import React from "react";

const MultiFlightIcon = ({
    width = 35,
    height = 24, // or any default size of your choice
    color = "white", // or any color of your choice
    onClick = () => { },
    className = ""
}) => {
    return (
        <svg width={width} height={height} className={className} onClick={onClick} viewBox="0 0 35 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_9685_43194)">
                <path d="M19.0965 0H24.9317L19.7594 19.5611H0C0 19.5611 13.5461 7.44036 19.0965 0Z" fill="#B3D4FF" />
                <path d="M28.1754 4.4375H34.0107L28.8383 23.9986H9.07812C9.07812 23.9986 22.6242 11.8779 28.1746 4.4375H28.1754Z" fill="#505F79" />
            </g>
            <defs>
                <clipPath id="clip0_9685_43194">
                    <rect width="34.0069" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
export default MultiFlightIcon;