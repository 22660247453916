import React from "react";
import Modal from "react-modal";
import closeIcon from '../../Assets/img/close-blue-icon.png';
import lockImage from '../../Assets/img/lock.svg';


function ReviewAndVerifyModal({ openModal, closeModal, icon, title, content, handelPayNow }) {
    return (
        <Modal
            isOpen={openModal}
            closeModal={closeModal}
            ariaHideApp={false}
            className="popup popup-open popup-review-and-verify"
        >
            <div className="popup-content">
                <div className="popup-title">
                    <h3>{title}</h3>
                </div>
                <div className="content-box">
                    <p dangerouslySetInnerHTML={{ __html: content }} />
                </div>
                <div className="button-wrap">
                    <button
                        type="button"
                        className="btn blue-btn"
                        onClick={() => {
                            handelPayNow()
                        }}
                    >
                        <img src={lockImage} /> Pay Now
                    </button>
                    <button
                        type="button"
                        className="btn blue-btn"
                        onClick={() => {
                            closeModal(true);
                        }}
                    >
                        Review
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default ReviewAndVerifyModal;
