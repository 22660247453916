import React, { useEffect, useState } from "react";
import StarImg from "../../../Assets/img/star.png";
import YellowStarImg from "../../../Assets/img/yellow-star.png";


function StarRating({ isMobileView, filters, setFilters, handleSearchHotel }) {
    const [showFilter, setshowFilter] = useState(true);

    function handleApply() {
        let res = { ...filters, starRating: { ...filters?.starRating, value: filters?.starRating?.onChangeValue } }
        handleSearchHotel(res, null);
    }

    const handleChange = (val) => {
        if (filters?.starRating?.onChangeValue?.includes(val)) {
            const result = filters?.starRating?.onChangeValue.filter((item) => val !== item);
            setFilters({ ...filters, starRating: { ...filters?.starRating, onChangeValue: result, value: null } });
        } else {
            setFilters({ ...filters, starRating: { ...filters?.starRating, onChangeValue: [...(filters?.starRating?.onChangeValue || []), val], value: null } });
        }
    }

    return isMobileView ? (
        <>
            <div className="flterOption" onClick={() => setshowFilter(!showFilter)}>
                <span> Star Ratings </span>
            </div>
            <div
                className={`cardBody  star-rating ${!showFilter ? "d-none" : ""}`}
                style={{ display: "flex" }}
            >
                <div
                    className="rating"
                    data-id="1"
                    onClick={() => handleChange(1)}
                >
                    <img
                        src={filters?.starRating?.onChangeValue?.includes(1) ? YellowStarImg : StarImg}
                        alt="star"
                        width="38px"
                    />
                </div>
                <div
                    className="rating"
                    data-id="2"
                    onClick={() => handleChange(2)}
                >
                    <img
                        src={filters?.starRating?.onChangeValue?.includes(2) ? YellowStarImg : StarImg}
                        alt="star"
                        width="38px"
                    />
                </div>
                <div
                    className="rating"
                    data-id="3"
                    onClick={() => handleChange(3)}
                >
                    <img
                        src={filters?.starRating?.onChangeValue?.includes(3) ? YellowStarImg : StarImg}
                        alt="star"
                        width="38px"
                    />
                </div>
                <div
                    className="rating"
                    data-id="4"
                    onClick={() => handleChange(4)}
                >
                    <img
                        src={filters?.starRating?.onChangeValue?.includes(4) ? YellowStarImg : StarImg}
                        alt="star"
                        width="38px"
                    />
                </div>
                <div
                    className="rating"
                    data-id="5"
                    onClick={() => handleChange(5)}
                >
                    <img
                        src={filters?.starRating?.onChangeValue?.includes(5) ? YellowStarImg : StarImg}
                        alt="star"
                        width="38px"
                    />
                </div>
            </div>
        </>
    ) : (
        <>
            <div
                className="cardHead dflex"
                onClick={() => setshowFilter(!showFilter)}
            >
                <h6>Star Ratings</h6>
                <span className={`fa fa-angle-${showFilter ? "up" : "down"}`}></span>
            </div>
            <div className={`cardBody ${!showFilter ? "d-none" : ""}`}>
                <div className="rate" style={{ display: "flex" }}>
                    <div
                        className="ratings"
                        data-id="1"
                        onClick={() => handleChange(1)}
                    >
                        1{" "}
                        <img src={filters?.starRating?.onChangeValue?.includes(1) ? YellowStarImg : StarImg} alt="star" />
                    </div>
                    <div
                        className="ratings"
                        data-id="2"
                        onClick={() => handleChange(2)}
                    >
                        2{" "}
                        <img src={filters?.starRating?.onChangeValue?.includes(2) ? YellowStarImg : StarImg} alt="star" />
                    </div>
                    <div
                        className="ratings"
                        data-id="3"
                        onClick={() => handleChange(3)}

                    >
                        3{" "}
                        <img src={filters?.starRating?.onChangeValue?.includes(3) ? YellowStarImg : StarImg} alt="star" />
                    </div>
                    <div
                        className="ratings"
                        data-id="4"
                        onClick={() => handleChange(4)}
                    >
                        4{" "}
                        <img src={filters?.starRating?.onChangeValue?.includes(4) ? YellowStarImg : StarImg} alt="star" />
                    </div>
                    <div
                        className="ratings"
                        data-id="5"
                        onClick={() => handleChange(5)}
                    >
                        5{" "}
                        <img src={filters?.starRating?.onChangeValue?.includes(5) ? YellowStarImg : StarImg} alt="star" />
                    </div>
                </div>
                <button
                    className={`btnBorder ${false ? "disabled" : ""}`}
                    type="button"
                    onClick={handleApply}
                // disabled={filters?.rating ? false : true}
                >
                    Apply
                </button>
            </div>
        </>
    );
}
export default StarRating;
