/* eslint-disable react/no-unescaped-entities */
import React, { Fragment, useEffect, useState } from "react";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import HeaderNavbar from "../../Component/HeaderNavbar";
import printerImg from "../../Assets/img/printer-white-bg.png";
import emailImg from "../../Assets/img/email-white-bg.png";
import informationImg from "../../Assets/img/information.svg";
import rightShiftImg from "../../Assets/img/right-shift-blue.png";
import starImg from "../../Assets/img/yellow-star.png";
import location from "../../Assets/img/location-gray.png";
import datePicker from "../../Assets/img/date-picker.png";
import SingleHotelMapView from "../HotelSearchResult/SingleHotelCard/SingleHotelMapView";
import { useDispatch, useSelector } from "react-redux";
import { hotelActions } from "../../Stores/Actions";
import { isEmpty, has } from "lodash";
import GreyStarImg from "../../Assets/img/star.png";
import { useParams } from "react-router-dom";
import moment from "moment";
import EmailModal from "./EmailModal";
import CancellationModal from "./CancellationModal";
import { notification } from "antd";
// import "antd/dist/antd.css";
import importantImage from "../../Assets/img/ic-error-outline.png";
import errorImg from "../../Assets/img/ic-error-outline-copy-8.svg";
import Scrollbars from "react-custom-scrollbars";
import { getLocationParams } from "../../Util/util";
import infoGray from "../../Assets/img/info-gray.png";
import CancellationPolicy from "./CancellationPolicy";

const type = {
  non_refundable: "No Refund",
  fully_refundable: "Refund",
  partial_refund: "Partial Refund",
};
function HotelConfirmation() {
  const loading = useSelector((state) => state.commonReducer.loader);
  const [isConfirm, setIsConfirm] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    let id = params.bookingID;
    let urlParams = getLocationParams();
    if (urlParams?.isConfirm) {
      setIsConfirm(true);
    }
    if (id) {
      dispatch(hotelActions.getBookingDetails(id));
    }
  }, []);

  const hotelConfirmation = useSelector(
    (state) => state.hotelReducer.userBookingDetails
  );

  const emailSent = useSelector((state) => state.hotelReducer.emailSent);

  const hotelsDetail = !isEmpty(hotelConfirmation)
    ? hotelConfirmation.data?.data
    : [];

  const hotel = !isEmpty(hotelsDetail) ? hotelsDetail : [];
  const currentGeoLocation =
    window.localStorage.getItem("currentGeoLocation") &&
    JSON.parse(window.localStorage.getItem("currentGeoLocation"));

  const dateDisplayFormat =
    currentGeoLocation === "US" ? "MM/DD/YYYY" : "DD/MM/YYYY";

  const currency = hotelsDetail?.currenc?.isoCode ?? "USD";

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  });

  function starprint() {
    let star = [];
    let darkStar = 5 - hotel?.hotel_Details?.star_rating;
    for (let i = 0; i < hotel?.hotel_Details?.star_rating; i++) {
      star.push(<img src={starImg} alt="yellow-star" key={`${i}_rating1`} />);
    }
    for (let i = 0; i < darkStar; i++) {
      star.push(
        <img
          src={GreyStarImg}
          alt="yellow-star"
          key={`${i}_rating2`}
          width="24"
        />
      );
    }
    return star;
  }

  const downloadInvoice = () => {
    let id = params.bookingID;
    dispatch(hotelActions.downloadPdf(id));
  };

  // Email Receipt
  const [isOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  //Cancel Itinerary
  const [isCancel, setIsCancel] = useState(false);

  function toggleCancelModal() {
    setIsCancel(!isCancel);
  }

  //**************Error handle for cancel booking*******************//
  const cancelBookingError = useSelector(
    (state) => state.hotelReducer.errorCancelBooking
  );

  let openNotifyCancelBookingError = (type) => {
    notification[type]({
      // message: "Notification Title",
      description: cancelBookingError?.message,
    });
    setIsCancel((prev) => !prev);
  };

  // ***********Succes Msg handle for cancel booking**********//
  // const cancelBookingData = useSelector(
  //   (state) => state.hotelReducer.cancelledBooking
  // );

  // let openNotifyCancelBookingSuccess = (type) => {
  //   notification[type]({
  //     // message: "Notification Title",
  //     // description: cancelBookingError?.message,
  //     description: "Cancelled Successfully",
  //   });
  //   setIsCancel((prev) => !prev);
  // };
  // useEffect(() => {
  //   let id = params.bookingID;
  //   if (cancelBookingError !== null) {
  //     dispatch(hotelActions.getBookingDetails(id));
  //     openNotifyCancelBookingError("error");
  //     dispatch(hotelActions.resetCancelledBooking());
  //   }
  //   if (cancelBookingData !== null) {
  //     dispatch(hotelActions.getBookingDetails(id));
  //     openNotifyCancelBookingSuccess("success");
  //   }
  // }, [cancelBookingError, cancelBookingData]);

  // ***********Error handle for Email****************//
  // let errorMsg = useSelector((state) => state.hotelReducer.error);

  // let openNotifyEmailError = (type) => {
  //   notification[type]({
  //     // message: "Notification Title",
  //     description: errorMsg?.message,
  //   });
  //   setIsOpen((prev) => !prev);
  // };

  // ***************Succsess msg handle for Email**************//
  // const emailReservationData = useSelector(
  //   (state) => state.hotelReducer.cancelledBooking
  // );

  // let openNotifyEmailSuccess = (type) => {
  //   notification[type]({
  //     // message: "Notification Title",
  //     description: "Sent Successfully",
  //   });
  //   setIsCancel((prev) => !prev);
  // };

  // useEffect(() => {
  //   let id = params.bookingID;
  //   if (errorMsg !== null) {
  //     // dispatch(hotelActions.getBookingDetails(id));
  //     openNotifyEmailError("error");
  //   }
  // }, [errorMsg]);

  // useEffect(() => {
  //   let id = params.bookingID;
  //   if (emailReservationData !== null) {
  //     // dispatch(hotelActions.getBookingDetails(id));
  //     openNotifyEmailSuccess("success");
  //   }
  // }, [emailReservationData]);

  const getStatus = (status) => {
    if (status === "PAYMENT_COMPLETED") {
      return "Pending";
    } else if (status === "COMPLETED") {
      return "Confirmed";
    } else if (status === "CANCELLED") {
      return "Cancelled";
    } else if (status === "CANCELLED_REFUNDED") {
      return "Refunded";
    } else if (status === "PENDING") {
      return "PENDING";
    } else if (status === "FAILED") {
      return "Failed";
    }
  };

  // const bookingStatus = (setStatus) => {
  //   if (hotelsDetail?.booking_status === "CANCELLED") {
  //     if (setStatus) setShowCancelBtn(false);
  //     return "Your Cancelled Booking.";
  //   } else if (hotelsDetail?.booking_status == "CANCELLED_REFUNDED") {
  //     if (setStatus) setShowCancelBtn(false);
  //     return "Your Cancelled Booking.";
  //   } else if (hotelsDetail?.booking_status == "COMPLETED" && isConfirm) {
  //     if (setStatus) setShowCancelBtn(true);
  //     return "Your Booking is complete.";
  //   } else if (
  //     hotelsDetail?.booking_status == "COMPLETED" &&
  //     !isConfirm &&
  //     moment(
  //       hotelsDetail?.booking_package_details_json?.check_out_date_format4,
  //       "DD-MM-YYYY"
  //     ) > moment()
  //   ) {
  //     if (setStatus) setShowCancelBtn(true);
  //     return "Your Upcoming Booking.";
  //   } else if (hotelsDetail?.booking_status == "COMPLETED" && !isConfirm) {
  //     if (setStatus) setShowCancelBtn(false);
  //     return "Your Completed Booking.";
  //   } else if (hotelsDetail?.booking_status == "FAILED" && !isConfirm) {
  //     if (setStatus) setShowCancelBtn(false);
  //     return "Your Failed Booking.";
  //   } else if (hotelsDetail?.booking_status == "PENDING" && !isConfirm) {
  //     if (setStatus) setShowCancelBtn(false);
  //     return "Your Booking is Pending.";
  //   }
  // };

  // useEffect(() => {
  //   if (hotelConfirmation) {
  //     bookingStatus(true);
  //   }
  // }, [hotelConfirmation]);
  const bookingStatus = ["Failed", "Cancelled", "Completed", "Pending"];
  return (
    !loading && (
      <div className="siteBg listingPage confirmationPageWrap">
        <div className="pageWrap pageWrapInner">
          <div className="fixedHeader">
            <HeaderNavbar />
          </div>
          <section className="mainContent">
            <Scrollbars
              autoHide
              autoHeight
              autoHeightMin={120}
              autoHeightMax={`calc(100%)`}
              className="pageInternalScroll"
            >
              <div className="container pageInternalScrollTopPadding">
                <div className="confirmationWrapper">
                  <div className="row">
                    <div className="col leftContent">
                      <div className="card2 ">
                        <div className="messageWrapper">
                          <div className="container">
                            <h1>
                              {/* {hotelsDetail?.booking_status !== "FAILED" && (
                                <span>Thank You! </span>
                              )} */}
                              {/* {bookingStatus()} */}
                              Thank you for choosing Tripello.
                              <br /> Your booking is{" "}
                              {hotelsDetail?.booking_status}!
                            </h1>

                            <p>
                              Booking Reference Number :{" "}
                              {hotelsDetail?.booking_id}
                            </p>
                            <p>
                              Confirmation Number:{" "}
                              {hotel?.hc_number?.length > 0
                                ? hotel?.hc_number.join(", ")
                                : "---"}
                            </p>
                            {/* {(hotelsDetail?.booking_status === "CANCELLED" ||
                              hotelsDetail?.booking_status ===
                                "CANCELLED_REFUNDED") && (
                              <p>Status : {getStatus(hotelsDetail?.status)}</p>
                            )} */}
                            <p>
                              {/* {hotelsDetail?.booking_status === "CANCELLED"
                                ? "Cancellation "
                                : "Booking "} */}
                              Itinerary Emailed To:{" "}
                              {hotelsDetail?.primaryContactPerson?.email}
                            </p>
                          </div>
                          <div className="buttonWrap">
                            {/* <button className="btn" onClick={downloadInvoice}>
                              <img src={printerImg} alt="icon" />
                              Print Receipt
                            </button> */}
                            <button className="btn" onClick={toggleModal}>
                              <img src={emailImg} alt="icon" />
                              Email Receipt
                            </button>
                            {!bookingStatus?.includes(
                              hotelsDetail?.booking_status
                            ) && (
                              <button
                                className="btnBorder"
                                onClick={toggleCancelModal}
                              >
                                Cancel Itinerary
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="imgContentWrapper">
                          <div className="row no-gutters">
                            <div className="col contentWrap">
                              <div className="content">
                                <h3 className="cartTitle">
                                  {hotel?.hotel_Details?.hotel_name}
                                </h3>
                                <div className="topSection dflex justifyStart">
                                  <div className="rating">{starprint()}</div>
                                </div>
                                <div className="contactInfo">
                                  <div className="location">
                                    <img src={location} alt="icon" />
                                    {hotel?.hotel_Details?.address_line_one}
                                  </div>
                                  <div className="phone">
                                    <span className="icon-phone"></span>
                                    {hotel?.hotel_Details?.hotel_phone}
                                  </div>
                                </div>
                                <div className="timeWrapper">
                                  <div className="row no-gutters">
                                    <div className="col">
                                      <h5>
                                        <img src={datePicker} alt="icon" />
                                        Check-In:
                                      </h5>
                                    </div>
                                    <div className="col">
                                      <h5>
                                        <img src={datePicker} alt="icon" />
                                        Check-Out:
                                      </h5>
                                    </div>
                                  </div>
                                  <div className="row no-gutters dateWrapper">
                                    <div className="col">
                                      <h4>
                                        {!isEmpty(hotel) &&
                                          moment(
                                            hotel?.hotel_Details?.check_in_date,
                                            "YYYY-MM-DD"
                                          ).format(dateDisplayFormat)}
                                      </h4>
                                    </div>
                                    <div className="col">
                                      <h4>
                                        {!isEmpty(hotel) &&
                                          moment(
                                            hotel?.hotel_Details
                                              ?.check_out_date,
                                            "YYYY-MM-DD"
                                          ).format(dateDisplayFormat)}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="infoDetail">
                                <div className="row no-gutters info-content">
                                  <div className="col left-content">
                                    <h4>Room Type</h4>
                                    <h5>{hotel?.hotel_Details?.room_title}</h5>
                                    <h4>Meal Plan</h4>
                                    <h5>{hotel?.hotel_Details?.board_type}</h5>
                                    <h4>Number of Rooms:</h4>
                                    <h5>
                                      {hotel?.hotel_Details?.num_rooms}{" "}
                                      {`${
                                        hotel?.hotel_Details?.num_rooms > 1
                                          ? "Rooms"
                                          : "Room"
                                      }`}
                                    </h5>
                                    <h4>Number of Nights:</h4>
                                    <h5>
                                      {hotel?.hotel_Details?.num_nights}{" "}
                                      {`${
                                        hotel?.hotel_Details?.num_nights > 1
                                          ? "Nights"
                                          : "Night"
                                      }`}
                                    </h5>
                                  </div>
                                  <div className="col right-content">
                                    <h4>Bed Type</h4>
                                    <h5>{hotel?.booking_Details?.beds_type}</h5>
                                    <h4>Smoking</h4>
                                    <h5>
                                      {hotel?.booking_Details?.smoking_room
                                        ? "Smoking"
                                        : "Non Smoking"}{" "}
                                    </h5>
                                    <h4>Number of Guests:</h4>
                                    <h5>
                                      {hotel?.booking_Details?.num_adults +
                                        hotel?.booking_Details
                                          ?.num_children}{" "}
                                      Guest(s)
                                    </h5>
                                    <button
                                      className={
                                        hotel?.hotel_Details?.refundable
                                          ? "btn-refundable"
                                          : "btn-non-refundable"
                                      }
                                    >
                                      {hotel?.hotel_Details?.refundable
                                        ? "Refundable"
                                        : "Non Refundable"}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col imgWrapmain">
                              <div className="imgWrap">
                                <img
                                  src={hotel?.hotel_Details?.thumbnail_image}
                                  alt="confirmationImg"
                                />
                              </div>
                              {/* Disable for not lat and lon value */}
                              {/* {!loading && (
                                <SingleHotelMapView
                                  className="mapWrap"
                                  latitude={hotel?.hotel_Details?.latitude}
                                  longitude={hotel?.hotel_Details?.longitude}
                                />
                              )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card2">
                        <h4 className="d-flex align-items-center">
                          Guest Information
                        </h4>
                        <div className="guestTableWrap">
                          <table className="guestTable">
                            <thead>
                              <tr>
                                <td>
                                  <span>Guest Type</span>
                                </td>
                                <td>
                                  <span className="table-heading">
                                    Salutation
                                  </span>
                                </td>
                                <td>
                                  <span className="table-heading">
                                    First Name
                                  </span>
                                </td>
                                <td>
                                  <span className="table-heading">
                                    Last Name
                                  </span>
                                </td>
                                <td>
                                  <span className="table-heading">
                                    Nationality
                                  </span>
                                </td>
                                <td>
                                  <span className="table-heading">Age</span>
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              {hotelsDetail &&
                                hotelsDetail?.guest_data?.map(
                                  (guest, roomIndex) => {
                                    return (
                                      <Fragment key={`${roomIndex}`}>
                                        {guest?.adults?.map(
                                          (adult, adultIndex) => {
                                            return (
                                              <tr key={`${adultIndex}Adult`}>
                                                <td>
                                                  <span className="table-value">
                                                    {/* Room {roomIndex + 1} */}
                                                    Lead Guest
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="table-value">
                                                    {adult.salutation}
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="table-value">
                                                    {adult.first_name}
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="table-value">
                                                    {adult.last_name}
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="table-value">
                                                    -
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="table-value">
                                                    {adult.age}
                                                  </span>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                        {guest?.child?.map(
                                          (child, childIndex) => {
                                            return (
                                              <tr key={`${childIndex}Child`}>
                                                <td>
                                                  <span className="table-value">
                                                    {/* Room {roomIndex + 1} */}
                                                    Child
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="table-value">
                                                    {child.salutation}
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="table-value">
                                                    {child.first_name}
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="table-value">
                                                    {child.last_name}
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="table-value">
                                                    -
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="table-value">
                                                    {child.age}
                                                  </span>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </Fragment>
                                    );
                                  }
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      {/* {hotelsDetail?.booking_package_details_json?.room_details
                        .is_refundable && ( */}
                      <div className="hotel-card cancellation-policy card2">
                        <h4 className="d-flex align-items-center">
                          <img src={importantImage} className="mr-2" />
                          Cancellation Policy
                        </h4>
                        <div className="flight-penalty-info">
                          <div className="a table-wrap">
                            <table className="tableStyle font14 w-100">
                              <thead>
                                <tr>
                                  <th className="">Date</th>
                                  <th className="">Type</th>
                                  <th className="">Refund Amount</th>
                                  <th className="">Cancellation Fee</th>
                                </tr>
                              </thead>
                              <tbody>
                                {hotelsDetail?.cancellation_policy_v2?.map(
                                  (item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>
                                          <span>
                                            {`From ${moment(
                                              item?.from_date,
                                              "DD-MM-YYYY"
                                            ).format(
                                              dateDisplayFormat
                                            )} to ${moment(
                                              item?.to_date,
                                              "DD-MM-YYYY"
                                            ).format(dateDisplayFormat)}`}
                                          </span>
                                        </td>
                                        <td>
                                          <span>
                                            {
                                              type[
                                                item?.display_refund_type_text
                                              ]
                                            }
                                          </span>
                                        </td>
                                        <td>
                                          <span>
                                            {item?.currency}{" "}
                                            {item?.refund_amount || 0}
                                          </span>
                                        </td>
                                        <td>
                                          <span>
                                            {item?.currency}{" "}
                                            {item.total_cancellation_amount}
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {/* <CancellationPolicy bookingDetailsData={hotelsDetail} /> */}
                        {/* <ul>
                          <li>
                            Refundable cancellations (as laid out in our{" "}
                            <a target="_blank" href="/terms-conditions">
                              Terms and Conditions{" "}
                            </a>
                            ) will be subject to a 3.2% merchant processing fee.
                          </li>
                          <li>
                            Booking charges to your Debit/Credit Card will
                            appear in the name of "Tripello" and/or "Stripe".
                          </li>
                          {hotelsDetail?.booking_package_details_json
                            ?.cancellation_policy.cancellation &&
                            hotelsDetail?.booking_package_details_json?.cancellation_policy?.cancellation.map(
                              (policy) => {
                                let fromDate = policy.from_date.slice(0, 10);
                                let toDate = policy.to_date.slice(0, 10);
                                return (
                                  <li key={`${policy.from_date}Date`}>
                                    From Date <strong>{fromDate}</strong>{" "}
                                    {policy.to_date ? (
                                      <>
                                        to{" "}
                                        <strong>
                                          {policy.to_date.slice(0, 10)}
                                        </strong>{" "}
                                      </>
                                    ) : null}
                                    Penalty will be{" "}
                                    <strong>${policy.charges}</strong>
                                  </li>
                                );
                              }
                            )}
                        </ul> */}
                      </div>
                      {/* )} */}
                      <div className="card2">
                        <h3 className="sectionTitle">Policies</h3>
                        <div className="row no-gutters paraText">
                          {hotelsDetail?.Policies?.map((ele, index) => {
                            return (
                              ele?.values?.length > 0 && (
                                <div
                                  key={index}
                                  className={`col col-6  m-fullwidth`}
                                >
                                  <h5 className="font600 font14 mb-20">
                                    {ele?.group_name}
                                  </h5>
                                  {ele?.values?.map((ele, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="bulletsList bulletsList2"
                                        dangerouslySetInnerHTML={{
                                          __html: ele,
                                        }}
                                      ></div>
                                    );
                                  })}
                                </div>
                              )
                            );
                          })}
                        </div>
                      </div>

                      <div className="card2">
                        <h3 className="sectionTitle">Special Instructions</h3>
                        <h4 className="sectionTitle">Check-in Instructions</h4>

                        <div
                          className=""
                          dangerouslySetInnerHTML={{
                            __html:
                              hotelsDetail?.Special_CheckIn_Instructions
                                ?.check_in_instructions,
                          }}
                        ></div>
                        <h4 className="sectionTitle">
                          special check-in instructions
                        </h4>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              hotelsDetail?.Special_CheckIn_Instructions
                                ?.check_in_special_instructions,
                          }}
                        ></div>
                      </div>

                      <div className="hotel-card card2 cancellation-policy">
                        <h4 className="d-flex align-items-center">
                          <img src={importantImage} className="mr-2" />
                          Important Information
                        </h4>
                        {hotelsDetail?.importantInfo?.map((ele, index) => {
                          if (ele?.description?.length > 0) {
                            return (
                              <div key={index} className="mb-20">
                                <h3 className="font14 font700 mb-10">
                                  {ele?.title}
                                </h3>
                                <div
                                  className="bulletsDanegrouslySetHtml mb-30"
                                  dangerouslySetInnerHTML={{
                                    __html: ele?.description,
                                  }}
                                ></div>
                              </div>
                            );
                          }
                        })}
                        {/* <p className="mb-0">
                          The Rates listed above were made available to you as a
                          Member of Tripello, a Closed User Group (CUG),
                          accessing Non-Public Rates through our secure login
                          portal and website. These Rates are not available to
                          the General Public.
                        </p> */}
                      </div>
                    </div>
                    <div className="col rightContent">
                      <div className="card2 cardSummary">
                        <div className="sectionTitle">
                          <h2>Summary of Charges</h2>
                          <h3>
                            Booking Date:
                            <strong>
                              {moment(hotel?.booking_date_time).format(
                                dateDisplayFormat
                              )}
                            </strong>
                          </h3>
                        </div>
                        <div className="customShadow contentInfo">
                          <div className="d-flex justify-content-between">
                            <h4>Lowest Retail Price:</h4>
                            <h5>
                              {hotelsDetail?.currency?.symbol_native}
                              {hotelsDetail?.price_Breakup?.retail_price}
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between">
                            <h4>Hotel Base Price:</h4>
                            <h5>
                              <span className="font500">
                                {hotelsDetail?.currency?.symbol_native}
                                {hotelsDetail?.price_Breakup?.base_rate}
                              </span>
                            </h5>
                          </div>
                          <div className="d-flex font14 justify-content-between">
                            <h4>
                              {`${hotelsDetail?.hotel_Details?.num_rooms} ${
                                hotelsDetail?.hotel_Details?.num_rooms > 1
                                  ? `Rooms`
                                  : "Room"
                              } X ${hotelsDetail?.hotel_Details?.num_nights}  ${
                                hotelsDetail?.hotel_Details?.num_nights > 1
                                  ? `Night(s)`
                                  : "Night"
                              }`}
                              <br />
                              {hotelsDetail?.currency?.symbol_native}
                              {
                                hotelsDetail?.price_Breakup?.per_night_base_rate
                              }{" "}
                              {hotelsDetail?.hotel_Details?.num_rooms > 1
                                ? "per room per night"
                                : "per night"}
                            </h4>
                          </div>
                          {parseFloat(
                            hotelsDetail?.price_Breakup?.extra_person_fee
                          ) > 0 && (
                            <div className="d-flex justify-content-between">
                              <h4>Extra Person Fees</h4>
                              <h5>
                                {hotelsDetail?.currency?.symbol_native}
                                {hotelsDetail?.price_Breakup?.extra_person_fee}
                              </h5>
                            </div>
                          )}
                          {(parseFloat(hotelsDetail?.price_Breakup?.tax) > 0 ||
                            parseFloat(hotelsDetail?.price_Breakup?.sales_tax) >
                              0) && (
                            <div className="d-flex justify-content-between">
                              <h4>
                                {parseFloat(
                                  hotelsDetail?.price_Breakup?.sales_tax
                                ) > 0
                                  ? "Sales Tax"
                                  : "Hotel Taxes and Fees"}
                              </h4>
                              <h5>
                                {hotelsDetail?.currency?.symbol_native}
                                {parseFloat(
                                  hotelsDetail?.price_Breakup?.sales_tax
                                ) > 0
                                  ? hotelsDetail?.price_Breakup?.sales_tax
                                  : hotelsDetail?.price_Breakup?.tax}
                              </h5>
                            </div>
                          )}
                          {/* {parseFloat(
                            hotelsDetail?.price_Breakup?.convenience_fee
                          ) > 0 && (
                            <div className="d-flex justify-content-between">
                              <h4>Convenience Fees</h4>
                              <h5>
                                {hotelsDetail?.currency?.symbol_native}
                                {hotelsDetail?.price_Breakup?.convenience_fee}
                              </h5>
                            </div>
                          )} */}

                          <div className="paidInfo">
                            {parseFloat(hotel?.price_Breakup?.savings) > 0 && (
                              <div className="d-flex justify-content-between payWrapper">
                                <h4 className="paid-title">You Saved :</h4>
                                <h5 className="red-text">
                                  {hotel?.currency?.symbol}
                                  {hotel?.price_Breakup?.savings}
                                  {`(${hotel?.price_Breakup?.savings_percentage})`}
                                </h5>
                              </div>
                            )}
                            <div className="d-flex justify-content-between payWrapper">
                              <h4 className="paid-title">
                                You paid:
                                {/* <div className="tooltip-wrap">
                                  <img
                                    src={infoGray}
                                    alt="icon"
                                    className="infoIcon"
                                  />
                                  <div className="pricedetail-dropdown">
                                    <div className="dropdown-box">
                                      <div className="dropdown-content">
                                        <div className="dropdown-info">
                                          <h3>Price Details</h3>
                                          {/* <h4>1 Room x {!isEmpty(hotelsDetail) &&
                                            hotelsDetail.booking_package_details_json.rates
                                              .no_of_nights} Nights<span>{formatter.format(hotelsDetail?.display_price?.net_rate || 0)}</span></h4>
                                          {hotelsDetail?.display_price?.tax !==0 || hotelsDetail?.display_price?.tax !=="0.00" && <h4>Taxes and Fees<span>{hotelsDetail?.display_price?.tax ? formatter.format(hotelsDetail?.display_price?.tax) : formatter.format(0)}</span></h4>}
                                          <div className="total-amount">
                                            <h4><strong className="total-text">Total:</strong><span>{formatter.format((hotelsDetail?.display_price?.final_price)?.replace(",", ""))}</span></h4>
                                          </div> */}
                                {/* </div> */}
                                {/* </div> */}
                                {/* </div> */}
                                {/* </div> */}
                                {/* </div>  */}
                              </h4>
                              <h5 className="green-text">
                                {hotel?.currency?.symbol}
                                {hotel?.price_Breakup?.total_paid}
                              </h5>
                            </div>
                            {hotelsDetail?.price_Breakup?.mandatory_fees
                              ?.length > 0 && (
                              <div className="d-flex justify-content-between payWrapper">
                                <h4 className="paid-title">
                                  Pay at property:
                                  <div className="tooltip-wrap">
                                    <img
                                      src={infoGray}
                                      alt="icon"
                                      className="infoIcon"
                                    />
                                    <div className="pricedetail-dropdown">
                                      <div className="dropdown-box">
                                        <div className="dropdown-content">
                                          <div className="dropdown-info">
                                            {
                                              hotelsDetail?.price_Breakup
                                                ?.mandatory_fees[0]?.currency
                                            }
                                            &nbsp;
                                            {
                                              hotelsDetail?.price_Breakup
                                                ?.mandatory_fees[0]?.amount
                                            }{" "}
                                            total room for total stay payable at
                                            property. <br /> (NOT included in
                                            the price, due at the Hotel)
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </h4>
                                <h5>
                                  {
                                    hotelsDetail?.price_Breakup
                                      ?.mandatory_fees[0]?.currency
                                  }
                                  &nbsp;
                                  {
                                    hotelsDetail?.price_Breakup
                                      ?.mandatory_fees[0]?.amount
                                  }
                                </h5>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="card2 cardHelp">
                        <div className="sectionTitle">
                          <h2>Need Help?</h2>
                        </div>
                        <div className="links">
                          <a href="/hotel-faq">
                            <img src={informationImg} alt="icon" />
                            <span>See all FAQ’s</span>
                            <img
                              className="arrow-img"
                              src={rightShiftImg}
                              alt="icon"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Scrollbars>
          </section>
          <FooterWithMediaIcon
            className="white-icon"
            iconClassName="icon-white"
          />
        </div>
        {isOpen && (
          <EmailModal
            openModal={isOpen}
            closeModal={toggleModal}
            setIsOpen={setIsOpen}
          />
        )}
        {isCancel && (
          <CancellationModal
            openModal={isCancel}
            closeModal={toggleCancelModal}
            setIsCancel={setIsCancel}
            policy={
              hotelsDetail?.booking_package_details_json?.cancellation_policy
            }
          />
        )}
      </div>
    )
  );
}

export default HotelConfirmation;
