import React, { useState, useEffect, useRef } from "react";
import SelectFieldOption from "../FormComponent/SelectFieldOption.jsx";
import FileUpload from "../../Assets/img/support/paper-clip.png";
import { Formik } from "formik";
import {
  supportHotelTabValidation,
  supportHotelTabValidationMembership,
} from "../Validation/supportHotelTabValidation.js";
import { useDispatch, useSelector } from "react-redux";
import { supportPageAction } from "../../Stores/Actions/supportPageActions";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import BasicModal from "../Modals/BasicModal.jsx";
import checkIcon from "../../Assets/img/icons/check.png";
import cancelIcon from "../../Assets/img/icons/cancel.png";
import moment from "moment";

export default function SupportForm(props) {
  const dispatch = useDispatch();
  const [modalData, setModalData] = useState({
    open: false,
    message: "",
    id: "",
    success: false,
  });
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const formikRef = useRef();

  const initValues = {
    confirmation_id: "",
    support_need: "",
    description: "",
    attachment: null,
    vertical: props.type,
  };

  const [modalState, setModalState] = useState({
    open: false,
    isSuccessView: false,
    message: "success",
  });

  const issuesListData = useSelector(
    (state) => state.supportReducer?.issuesListData?.data
  );

  useEffect(() => {
    const payload = {
      type: props.type,
    };
    props?.tabKey == props?.tab &&
      dispatch(supportPageAction.getIssueListByCategory(payload));
  }, [props?.tab]);
  const inputRef = useRef(null);

  const handleSubmit = async (formValues) => {
    const payload = new FormData();

    payload.append("support_need", formValues.support_need);

    if (formValues?.attachment) {
      payload.append("attachment", formValues?.attachment);
    }
    payload.append("description", formValues.description);

    if (props.type !== "Membership") {
      payload.append("confirmation_id", formValues.confirmation_id);
      payload.append(
        "vertical",
        props.type === "Hotel"
          ? "Hotels"
          : props.type === "Flight"
            ? "Flights"
            : props.type === "Experiences"
              ? "Experiences"
              : props.type === "Membership"
                ? "Membership"
                : ""
      );
    }

    if (props.type !== "Membership") {
      dispatch(supportPageAction.sendIssueToSupportTeam(payload))?.then(
        (res) => {
          if (res?.status === 200 && res?.data?.success) {
            // alert(res?.data?.message);
            setModalData({
              open: true,
              message: res?.data?.message,
              id: res?.data?.id,
              success: true,
            });
            formikRef.current.setValues({
              // booking_id: '',
              // issue: '',
              // description: '',
              // attachments: null,

              confirmation_id: "",
              support_need: "",
              description: "",
              attachment: null,
            });

            formikRef.current.setTouched({
              // booking_id: false,
              // issue: false,
              // description: false,
              // attachments: false,
              confirmation_id: false,
              support_need: false,
              description: false,
              attachment: null,
            });
            inputRef.current._handleClear();
          } else {
            setModalData({
              open: true,
              message: res.response?.data?.message,
              id: res?.data?.id,
              success: false,
            });
          }
        }
      );
    } else if (props.type === "Membership") {
      dispatch(supportPageAction.sendIssueToAccountsettings(payload))?.then(
        (res) => {
          if (res?.status === 200 && res?.data?.success) {
            // alert(res?.data?.message);
            setModalData({
              open: true,
              message: res?.data?.message,
              id: res?.data?.id,
              success: true,
            });
            formikRef.current.setValues({
              // booking_id: '',
              // issue: '',
              // description: '',
              // attachments: null,

              confirmation_id: "",
              support_need: "",
              description: "",
              attachment: null,
            });

            formikRef.current.setTouched({
              // booking_id: false,
              // issue: false,
              // description: false,
              // attachments: false,
              confirmation_id: false,
              support_need: false,
              description: false,
              attachment: null,
            });
            inputRef.current._handleClear();
          } else {
            setModalData({
              open: true,
              message: res.response?.data?.message,
              id: res?.data?.id,
              success: false,
            });
          }
        }
      );
    }
  };

  const handleOnClick = (query) => {
    // const payload = {
    //   type: props.type,
    //   term: query,
    // };
    // dispatch(supportPageAction.getAutoCompletedData(payload)).then((res) => {
    //   if (res.status === 200) {
    //     setAutoCompleteOptions(res?.data?.data);
    //   }
    // });
    setAutoCompleteOptions(issuesListData?.confirmation_ids);
  };

  const [found, setFound] = useState(true);
  const handleOnSearch = (query) => {
    let filteredBookings;
    if (props.type === "Hotel") {
      if (query?.length > 0) {
        filteredBookings = issuesListData?.confirmation_ids?.filter(booking =>
          booking.booking_id.toLowerCase().includes(query.toLowerCase()) ||
          booking.hotel_data.hotel_name.toLowerCase().includes(query.toLowerCase())
        );
        setFound(filteredBookings?.length > 0 ? true : false)
        setAutoCompleteOptions(filteredBookings);
      } else {
        setAutoCompleteOptions(issuesListData?.confirmation_ids);
      }
    } else if (props.type === "Flight") {
      if (query?.length > 0) {
        filteredBookings = issuesListData?.confirmation_ids?.filter(booking =>
          booking.booking_id.toLowerCase().includes(query.toLowerCase())
        );
        setFound(filteredBookings?.length > 0 ? true : false)
        setAutoCompleteOptions(filteredBookings);
      } else {
        setAutoCompleteOptions(issuesListData?.confirmation_ids);
      }
    }

  };

  return (
    <>
      <BasicModal
        openModal={modalData.open}
        closeModal={() => {
          setModalData({
            open: false,
            message: null,
            id: "",
            success: false,
          });
        }}
        text={
          <>
            <h3>{modalData.message}</h3>
            {modalData?.id && (
              <p style={{ marginTop: "15px" }}>
                Your ticket number is #{modalData?.id}
              </p>
            )}
          </>
        }
        icon={modalData.success ? checkIcon : cancelIcon}
      />
      <Formik
        initialValues={initValues}
        onSubmit={handleSubmit}
        validationSchema={
          props.type !== "Membership"
            ? supportHotelTabValidation()
            : supportHotelTabValidationMembership()
        }
        innerRef={formikRef}
      >
        {(formikProps) => {
          const {
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          } = formikProps;

          return (
            <div className="customer-portal-tab-ui">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <SelectFieldOption
                    placeholder={"Select your issue"}
                    options={issuesListData?.support_queries}
                    name="support_need"
                    formikProps={formikProps}
                    error={errors.support_need}
                    onBlur={() => {
                      formikProps.setTouched({
                        ...formikProps.touched,
                        support_need: true,
                      });
                    }}
                    onChange={(formikProps, name, value) => {
                      formikProps.setFieldValue("support_need", value);
                    }}
                    touched={touched.support_need}
                    value={values.support_need}
                    isSearchable={true}
                    id="eee"
                  />
                </div>
                {props.type !== "Membership" && (
                  <div className="form-group">
                    <AsyncTypeahead
                      ref={inputRef}
                      // autoFocus={autoFocus}
                      // inputProps={{ className: "inputDropdown widget-dropdown" }}
                      // onInputChange={onInputChange}
                      labelKey={(option) => {
                        if (props.type === "Hotel") {
                          return option?.hotel_data?.hotel_name;
                        } else if (props.type === "Flight") {
                          return ` ${option?.departure_airport_code} - ${option?.arrival_airport_code} (${option?.booking_id}) `;
                        }
                      }}
                      onBlur={() => {
                        formikProps.setTouched({
                          ...formikProps.touched,
                          confirmation_id: true,
                        });
                      }}
                      // isLoading={isLoading}
                      filterBy={() => true}
                      minLength={0}
                      onSearch={(query) => handleOnSearch(query)}
                      // onClick={(query) => handleOnClick(query)}
                      options={autoCompleteOptions?.length === 0 ? !found ? autoCompleteOptions : issuesListData?.confirmation_ids : autoCompleteOptions}
                      placeholder={
                        "Enter your Itinerary / Booking / Reference ID (TRIP-...)"
                      }
                      onKeyDown={(query) => handleOnClick(query)}
                      // cache={false}
                      onChange={(selected) => {
                        formikProps.setFieldValue(
                          "confirmation_id",
                          selected[0]?.booking_id
                        );
                        setAutoCompleteOptions(issuesListData?.confirmation_ids);
                      }}
                      clearButton
                      searchText="Searching..."
                      name="confirmation_id"
                      id={"confirmation_id"}
                      // dropup={!isMobile}
                      // disabled={true}
                      emptyLabel={!found ? "No record found" : "Type to search..."}
                      // className="inputDropdown"
                      renderMenuItemChildren={(option) => (
                        <div className="dorpdown-content">
                          <div className="dorpdown-item">
                            {/* options UI for hotel tab */}
                            {(props.type === "Hotel" ||
                              props.type === "home") && (
                                <>
                                  <h3 className="dropdown-title">
                                    {option?.hotel_data?.hotel_name}
                                  </h3>
                                  <h3 className="dropdown-title">
                                    {option?.hotel_data?.city_name}
                                  </h3>
                                  <h4 className="booking-id-content">
                                    {option?.booking_id}
                                    <span className="dot"></span>
                                    {option?.booking_data?.booking_status}{" "}
                                    <span className="dot"></span>
                                    {option.iternary_details?.check_in_date}
                                  </h4>
                                </>
                              )}

                            {/* options UI for flight tab */}
                            {props.type === "Flight" && (
                              <>
                                <h3 className="dropdown-title">
                                  {option.departure_city_name}-
                                  {option.arrival_city_name}
                                </h3>
                                <h4 className="booking-id-content">
                                  {option.booking_id}
                                  <span className="dot"></span>
                                  {option.booking_status}{" "}
                                  <span className="dot"></span>
                                  Departure{" "}
                                  {moment(option.departure_date_time).format(
                                    "MM-DD-YYYY"
                                  )}
                                </h4>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    />
                    {/* && touched.booking_id */}
                    {errors.confirmation_id && touched.confirmation_id && (
                      <p className="error-messages">{errors.confirmation_id}</p>
                    )}
                  </div>
                )}

                <div className="form-group">
                  <textarea
                    className="form-control"
                    placeholder="Enter details or descriptions here"
                    onBlur={handleBlur}
                    name="description"
                    onChange={(e) => {
                      setFieldValue("description", e.target.value);
                    }}
                    value={values.description}
                  ></textarea>
                  {errors.description && touched.description && (
                    <p className="error-messages">{errors.description}</p>
                  )}
                </div>
                <div className="form-footer">
                  {!values?.attachment && (
                    <div className="file-upload">
                      <img src={FileUpload} alt="" />
                      <label htmlFor="upload-photo">
                        Add attachment <small>(png, jpg, pdf)</small>
                      </label>
                      <input
                        // className={`${values?.attachments} ? "disabled":""`}
                        //  disabled={values?.attachments}
                        type="file"
                        onChange={(e) => {
                          formikProps.setFieldValue(
                            "attachment",
                            e.target.files.item(0)
                          );
                        }}
                        name="photo"
                        id="upload-photo"
                        accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                      />
                    </div>
                  )}
                  {values?.attachment && (
                    <span className="fileNameDisplay">
                      {values?.attachment.name || ""}
                      <button
                        type="button"
                        onClick={() => {
                          formikProps.setFieldValue("attachment", null);
                        }}
                      // className='btn btn-submit'
                      >
                        x
                      </button>
                    </span>
                  )}
                  <button
                    disabled={errors?.length > 0}
                    type="submit"
                    className="btn-submit btn"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          );
        }}
      </Formik>
      {/* <SupportPageModal
        open={modalState.open}
        closeModal={() => {
          setModalState({ ...modalState, open: false });
        }}
        isSuccessView={modalState.isSuccessView}
        message={modalState.message}
      /> */}
    </>
  );
}
