

import React from 'react'

function Card({ component, image, heading }) {
	return (
		<div className="col">
			<div className="card">
				<div className="linkIcon">
					<img src={image} alt="icon" />
					<h3>{heading}</h3>
				</div>
				{component}
			</div>
		</div>
	)
}

export default Card