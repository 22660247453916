export const formatCarAutoCompleteData = ({ airport_data, city_data }) => {
  let data = [];

  for (let airport in airport_data) {
    data.push({
      label: `${airport_data[airport]?.airport_name} (${airport_data[airport]?.airport_code})`,
      // eslint-disable-next-line no-unsafe-optional-chaining
      secondary_label: `${(airport_data[airport]?.airport_name).replace(/&#40;/g,'(').replace(/&#41;/g,')').replace(/&#39;/g,"'")} (${airport_data[airport]?.airport_code})`,
      expedia_id: airport_data[airport]?.airport_code,
    });
  }

  for (var i = 0; i < Object.keys(city_data).length; i++) {
    var keyID = "city_" + i;
    data.push({
      label: `${city_data[keyID]?.city} (${city_data[keyID]?.country_code})`,
      // eslint-disable-next-line no-unsafe-optional-chaining
      secondary_label: `${(city_data[keyID]?.city).replace(/&#40;/g,'(').replace(/&#41;/g,')').replace(/&#39;/g,"'")} (${city_data[keyID]?.country_code})`,
      expedia_id: city_data[keyID]?.ppn_car_cityid,
    });
    if (city_data[keyID].airport_data) {
      for (
        var j = 0;
        j < Object.keys(city_data[keyID].airport_data).length;
        j++
      ) {
        var airID = "airport_" + j;
        data.push({
          label: `${city_data[keyID].airport_data[airID]?.airport_name} (${city_data[keyID].airport_data[airID]?.airport_code})`,
          // eslint-disable-next-line no-unsafe-optional-chaining
          secondary_label: `${(city_data[keyID].airport_data[airID]?.airport_name).replace(/&#40;/g,'(').replace(/&#41;/g,')').replace(/&#39;/g,"'")} (${city_data[keyID].airport_data[airID]?.airport_code})`,
          expedia_id: city_data[keyID].airport_data[airID]?.airport_code,
        });
      }
    }
  }

  return {
    data,
  };
};
