import React, { useState } from 'react';
import HeaderNavbar from '../../Component/HeaderNavbar';
import TabList from './TabList';
import SelectFieldOption from '../../Component/FormComponent/SelectFieldOption.jsx';
import { FlightFaqsList } from '../../main/CustomConstants/FlightFaqsList';
import FooterWithMediaIcon from '../../Component/FooterWithMediaIcon';
import FlightFaqWrapper from './FlightFaqWrapper';
import Scrollbars from 'react-custom-scrollbars';
import Button from './Button';

function FlightFaqs() {
  // State for expand faqs list
  const [isExpandAccordian, setIsExpandAccoridan] = useState(false);
  const [formData, setFormData] = useState({
    contactInfo: null,
    checkIn: null,
    changeSeat: null,
  });

  // State for opening faqs list
  const [openList, setOpenList] = useState(0);
  // Intial state of search
  const [search, setSearch] = useState([]);

  // Handling state on click
  const handleOpenCloseList = (id) => {
    setOpenList(openList !== id ? id : null);
  };

  // Handle change function
  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const contactInfoOptions = carrierDirectory.map((ele, index) => {
    return {
      value: ele.airlineLink,
      label: `${ele.label} ${ele.carrierContactNo}`,
    };
  });

  const checkInOptions = carrierDirectory.map((ele, index) => {
    return { value: ele.airlineCheckin, label: ele.label };
  });

  const changeSeatOptions = carrierDirectory.map((ele, index) => {
    return { value: ele.airlineSeating, label: ele.label };
  });

  // Array list of different select input field
  const selectInputList = [
    {
      heading: 'Contact Information',
      options: contactInfoOptions,
      placeholder: 'Air Canada 1-888-247-2262',
      name: 'contactInfo',
      link: formData.contactInfo,
    },
    {
      heading: 'Check In',
      options: checkInOptions,
      placeholder: 'Choose Airline',
      name: 'checkIn',
      link: formData.checkIn,
    },
    {
      heading: 'Change Seat',
      options: changeSeatOptions,
      placeholder: 'Choose Airline',
      name: 'changeSeat',
      link: formData.changeSeat,
    },
  ];

  //faq list getting pushed into an array
  const flightFaq = (getFlightFaq) => {
    const accordianList = [];

    getFlightFaq.forEach((faq, index) => {
      if (isExpandAccordian) {
        accordianList.push(
          <FlightFaqWrapper
            openList={openList}
            handleOpenCloseList={handleOpenCloseList}
            faq={faq}
            index={index}
          />
        );
      } else if (index < 6) {
        accordianList.push(
          <FlightFaqWrapper
            openList={openList}
            handleOpenCloseList={handleOpenCloseList}
            faq={faq}
            index={index}
          />
        );
      }
    });

    return accordianList;
  };

  const handleCostomChange = (no_use, name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="siteBg FaqPageWrap">
      <div className="pageWrap faq-scroll">
        <div className="fixedHeader">
          <HeaderNavbar useBlueIcon={true}/>
        </div>
        <section className="mainContent">
          <Scrollbars
            autoHeight={true}
            autoHeightMin={120}
            autoHeightMax={`calc(100%)`}
            className="pageInternalScroll"
          >
            <div className="container pageInternalScrollTopPadding">
              <div className="row no-gutters rightContent-bottom">
                <TabList />
                <div className="col rightContent">
                  <div className="carrierInfoWrap">
                    <div className="d-flex justify-content-between">
                      <h2>Carrier Contact Directory</h2>
                    </div>
                  </div>
                  <div className="flight-faq-dropdown-section card2">
                    {selectInputList.map((item, index) => (
                      <div className="row no-gutters" key={item.name}>
                        <div className="section-title col">
                          <h3 key={index}>{item.heading}</h3>
                        </div>
                        <SelectFieldOption
                          name={item.name}
                          value={item.link}
                          onChange={handleCostomChange}
                          options={item.options}
                          formikProps={'no_use'}
                          placeholder={item.placeholder}
                          onBlur={() => {}}
                        />
                        <div className="col button-wrap">
                          <a href={item.link} disabled={!item.link} target="_blank" className="btn" rel="noreferrer">
                            Go To Site
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="faqWrapper card2">
                    <div className="toggle-more-accordions ">
                      {flightFaq(FlightFaqsList)}
                    </div>
                  </div>
                  {/* button more and less */}
                  {FlightFaqsList.length > 5 && (
                    <Button
                      isExpandAccordian={isExpandAccordian}
                      setIsExpandAccoridan={setIsExpandAccoridan}
                    />
                  )}
                  {/* button more and less end here */}
                </div>
              </div>
            </div>
          </Scrollbars>
        </section>
        <FooterWithMediaIcon
          className="white-icon"
          iconClassName="icon-white"
        />
      </div>
    </div>
  );
}

export default FlightFaqs;
const carrierImg3 = null;

const carrierDirectory = [
  {
    carrierImg: carrierImg3,
    label: 'Aeromexico',
    id: 'Aeromexico',
    carrierContactNo: '1-800-237-6639',
    airlineLink: 'https://www.aeromexico.com/en-us',
    airlineCheckin: 'https://www.aeromexico.com/en-us/check-in',
    airlineBaggagePolicy:
      'https://www.aeromexico.com/en-us/travel-information/baggage',
    airlineSeating: 'https://www.aeromexico.com/en-us/check-in',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Canada',
    id: 'Air Canada',
    carrierContactNo: '1-888-247-2262',
    airlineLink: 'https://www.aircanada.com/us/en/aco/home.html',
    airlineCheckin: 'https://www.aircanada.com/us/en/aco/home.html',
    airlineBaggagePolicy:
      'https://www.aircanada.com/us/en/aco/home/plan/baggage.html',
    airlineSeating: 'https://www.aircanada.com/us/en/aco/home.html',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air France',
    id: 'Air France',
    carrierContactNo: '1-800-237-2747',
    airlineLink: 'https://www.airfrance.us/',
    airlineCheckin:
      'https://www.airfrance.us/US/en/local/core/engine/echeckin/IciFormAction.do',
    airlineBaggagePolicy:
      'https://www.airfrance.us/US/en/common/voyage-en-avion/preparation-voyage/bagages-avion.htm',
    airlineSeating:
      'https://www.airfrance.us/US/en/local/core/engine/echeckin/IciFormAction.do',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air India',
    id: 'Air India',
    carrierContactNo: '1-800-223-7776',
    airlineLink: 'https://www.airindia.in/',
    airlineCheckin: 'https://icheck.sita.aero/iCheckWebAI/Home',
    airlineBaggagePolicy: 'https://airindia.com/baggage.htm',
    airlineSeating: 'https://icheck.sita.aero/iCheckWebAI/Home',
  },
  {
    carrierImg: carrierImg3,
    label: 'Alaska Airlines',
    id: 'Alaska Airlines',
    carrierContactNo: '1-800-252-7522',
    airlineLink: 'https://www.alaskaair.com/',
    airlineCheckin: 'https://www.alaskaair.com/',
    airlineBaggagePolicy:
      'https://www.alaskaair.com/content/travel-info/baggage/overview',
    airlineSeating: 'https://www.alaskaair.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'American Airlines',
    id: 'American Airlines',
    carrierContactNo: '1-800-433-7300',
    airlineLink: 'https://www.aa.com/',
    airlineCheckin: 'https://www.aa.com/homePage.do',
    airlineBaggagePolicy:
      'https://www.aa.com/i18n/travelInformation/baggage/baggageAllowance.jsp',
    airlineSeating: 'https://www.aa.com/homePage.do',
  },
  {
    carrierImg: carrierImg3,
    label: 'Avianca',
    id: 'Avianca',
    carrierContactNo: '1-800-284-2622',
    airlineLink: 'https://www.avianca.com/',
    airlineCheckin: 'https://www.avianca.com/us/en/your-booking/check-in/',
    airlineBaggagePolicy:
      'https://www.avianca.com/us/en/before-your-trip/prepare-your-baggage/checked/',
    airlineSeating: 'https://www.avianca.com/us/en/your-booking/check-in/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Cathay Pacific',
    id: 'Cathay Pacific',
    carrierContactNo: '1-800-233-2742',
    airlineLink: 'https://www.cathaypacific.com/cx/en_US.html',
    airlineCheckin: 'https://www.cathaypacific.com/olci/#/en_US/login',
    airlineBaggagePolicy:
      'https://www.cathaypacific.com/cx/en_US/travel-information/baggage.html',
    airlineSeating: 'https://www.cathaypacific.com/olci/#/en_US/login',
  },
  {
    carrierImg: carrierImg3,
    label: 'Copa',
    id: 'Copa',
    carrierContactNo: '1-800-359-2672',
    airlineLink: 'https://www.copaair.com/en',
    airlineCheckin:
      'https://www.copaair.com/sites/cc/en/informacion-de-viaje/pages/Condiciones-Generales-de-Equipaje.aspx',
    airlineBaggagePolicy:
      'https://www.copaair.com/sites/cc/en/informacion-de-viaje/pages/Condiciones-Generales-de-Equipaje.aspx',
    airlineSeating:
      'https://www.copaair.com/sites/cc/en/informacion-de-viaje/pages/Condiciones-Generales-de-Equipaje.aspx',
  },
  {
    carrierImg: carrierImg3,
    label: 'Delta Air Lines',
    id: 'Delta Air Lines',
    carrierContactNo: '1-800-221-1212',
    airlineLink: 'https://www.delta.com/',
    airlineCheckin:
      'https://www.delta.com/en_US/traveling-with-us/baggage/before-your-trip/checked',
    airlineBaggagePolicy:
      'https://www.delta.com/en_US/traveling-with-us/baggage/before-your-trip/checked',
    airlineSeating:
      'https://www.delta.com/en_US/traveling-with-us/baggage/before-your-trip/checked',
  },
  {
    carrierImg: carrierImg3,
    label: 'Frontier Airlines',
    id: 'Frontier Airlines',
    carrierContactNo: '801-401-9000',
    airlineLink: 'https://www.flyfrontier.com/',
    airlineCheckin: 'https://www.flyfrontier.com/travel/my-trips/check-in/',
    airlineBaggagePolicy:
      'https://www.flyfrontier.com/travel-information/baggage/',
    airlineSeating: 'https://www.flyfrontier.com/travel/my-trips/check-in/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Hawaiian Airlines',
    id: 'Hawaiian Airlines',
    carrierContactNo: '1-800-367-5320',
    airlineLink: 'https://www.hawaiianairlines.com/',
    airlineCheckin: 'https://www.hawaiianairlines.com/manage/check-in',
    airlineBaggagePolicy: '',
    airlineSeating: 'https://www.hawaiianairlines.com/manage/check-in',
  },
  {
    carrierImg: carrierImg3,
    label: 'Icelandair',
    id: 'Icelandair',
    carrierContactNo: '1-800-223-5500',
    airlineLink: 'https://www.icelandair.com/',
    airlineCheckin: 'https://www.icelandair.com/support/baggage/',
    airlineBaggagePolicy: 'https://www.icelandair.com/support/baggage/',
    airlineSeating: 'https://www.icelandair.com/support/baggage/',
  },
  {
    carrierImg: carrierImg3,
    label: 'JetBlue Airways',
    id: 'JetBlue Airways',
    carrierContactNo: '1-800-538-2583',
    airlineLink: 'https://www.jetblue.com/',
    airlineCheckin: 'https://book.jetblue.com/B6.myb/#/landing',
    airlineBaggagePolicy: 'https://www.jetblue.com/travel/baggage/',
    airlineSeating: 'https://book.jetblue.com/B6.myb/#/landing',
  },
  {
    carrierImg: carrierImg3,
    label: 'Lufthansa',
    id: 'Lufthansa',
    carrierContactNo: '1-800-645-3880',
    airlineLink: 'https://www.lufthansa.com/us/en/homepage',
    airlineCheckin: 'https://www.lufthansa.com/us/en/online-check-in',
    airlineBaggagePolicy: 'https://www.lufthansa.com/us/en/baggage-overview',
    airlineSeating: 'https://www.lufthansa.com/us/en/online-check-in',
  },
  {
    carrierImg: carrierImg3,
    label: 'Spirit Airlines',
    id: 'Spirit Airlines',
    carrierContactNo: '1-801-401-2222',
    airlineLink: 'https://www.spirit.com/',
    airlineCheckin: 'https://www.spirit.com/',
    airlineBaggagePolicy:
      'https://www.spirit.com/OptionalFees.aspx#baggage-fees',
    airlineSeating: 'https://www.spirit.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Sun Country Airlines',
    id: 'Sun Country Airlines',
    carrierContactNo: '1-800-359-6786',
    airlineLink: 'https://www.suncountry.com/',
    airlineCheckin: 'https://www.suncountry.com/bags-optional-services',
    airlineBaggagePolicy: 'https://www.suncountry.com/bags-optional-services',
    airlineSeating: 'https://www.suncountry.com/bags-optional-services',
  },
  {
    carrierImg: carrierImg3,
    label: 'TAP Portugal',
    id: 'TAP Portugal',
    carrierContactNo: '1-800-221-7370',
    airlineLink: 'https://www.flytap.com/en-us/',
    airlineCheckin: 'https://www.flytap.com/en-us/check-in/online-check-in',
    airlineBaggagePolicy: '',
    airlineSeating: 'https://www.flytap.com/en-us/check-in/online-check-in',
  },
  {
    carrierImg: carrierImg3,
    label: 'United Airlines',
    id: 'United Airlines',
    carrierContactNo: '1-800-864-8331',
    airlineLink: 'https://www.united.com/en/us/',
    airlineCheckin: 'https://www.united.com/en/us/',
    airlineBaggagePolicy:
      'https://www.united.com/ual/en/us/fly/travel/baggage.html',
    airlineSeating: 'https://www.united.com/en/us/',
  },
  {
    carrierImg: carrierImg3,
    label: 'WestJet',
    id: 'WestJet',
    carrierContactNo: '1-888-937-853',
    airlineLink: 'https://www.westjet.com/en-us/index',
    airlineCheckin: 'https://checkin.westjet.com/WSOCI/oci',
    airlineBaggagePolicy:
      'https://www.westjet.com/en-us/travel-info/baggage/index',
    airlineSeating: 'https://checkin.westjet.com/WSOCI/oci',
  },
  {
    carrierImg: carrierImg3,
    label: '9 Air',
    id: '9 Air',
    carrierContactNo: '',
    airlineLink: 'http://www.9air.com/',
    airlineCheckin: 'http://www.9air.com/',
    airlineBaggagePolicy: 'http://www.9air.com/',
    airlineSeating: 'http://www.9air.com/',
  },
  {
    carrierImg: carrierImg3,
    label: '9G Rail Limited',
    id: '9G Rail Limited',
    carrierContactNo: '',
    airlineLink: 'https://www.aviaperm.ru/',
    airlineCheckin: 'https://www.aviaperm.ru/',
    airlineBaggagePolicy: 'https://www.aviaperm.ru/',
    airlineSeating: 'https://www.aviaperm.ru/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Adria Airways',
    id: 'Adria Airways',
    carrierContactNo: '011-386-1-369-1010',
    airlineLink: 'https://www.adria.si/en/',
    airlineCheckin: 'https://www.adria.si/en/check-in/',
    airlineBaggagePolicy: 'https://www.adria.si/en/information/baggage/',
    airlineSeating: 'https://www.adria.si/en/check-in/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Advanced Air',
    id: 'Advanced Air',
    carrierContactNo: '1-800-393-7035',
    airlineLink: 'https://www.advancedairlines.com/',
    airlineCheckin: 'https://www.advancedairlines.com/shuttles/',
    airlineBaggagePolicy: '',
    airlineSeating: 'https://www.advancedairlines.com/shuttles/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Aegean Airlines',
    id: 'Aegean Airlines',
    carrierContactNo: '1-833-732-8158',
    airlineLink: 'https://en.aegeanair.com/',
    airlineCheckin:
      'https://en.aegeanair.com/travel-information/baggage/baggage-allowance/',
    airlineBaggagePolicy: 'https://en.aegeanair.com/plan/check-in/',
    airlineSeating:
      'https://en.aegeanair.com/travel-information/baggage/baggage-allowance/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Aer Lingus',
    id: 'Aer Lingus',
    carrierContactNo: '1-800-474-7424',
    airlineLink: 'https://www.aerlingus.com/html/en-US/home.html',
    airlineCheckin:
      'https://webcheckin.aerlingus.com/html/checkIn/checkin.html',
    airlineBaggagePolicy:
      'https://www.aerlingus.com/help/help/baggageinformation/',
    airlineSeating:
      'https://webcheckin.aerlingus.com/html/checkIn/checkin.html',
  },
  {
    carrierImg: carrierImg3,
    label: 'Aero',
    id: 'Aero',
    carrierContactNo: '',
    airlineLink: 'https://www.alnaserwings.com/core/en/',
    airlineCheckin: 'https://www.alnaserwings.com/core/en/',
    airlineBaggagePolicy: 'https://www.alnaserwings.com/core/en/',
    airlineSeating: 'https://www.alnaserwings.com/core/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Aerodynamics, Inc',
    id: 'Aerodynamics, Inc',
    carrierContactNo: '',
    airlineLink: 'https://www.mycpair.com/',
    airlineCheckin: 'https://www.mycpair.com/',
    airlineBaggagePolicy: 'https://www.mycpair.com/',
    airlineSeating: 'https://www.mycpair.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Aeroflot Russian Airlines',
    id: 'Aeroflot Russian Airlines',
    carrierContactNo: '1-866-879-7647',
    airlineLink: 'https://www.aeroflot.ru/ru-en',
    airlineCheckin:
      'https://www.aeroflot.ru/us-en/information/checkin/web_checkin',
    airlineBaggagePolicy:
      'https://www.aeroflot.ru/cms/en/before_and_after_fly/luggage',
    airlineSeating:
      'https://www.aeroflot.ru/us-en/information/checkin/web_checkin',
  },
  {
    carrierImg: carrierImg3,
    label: 'AeroGal',
    id: 'AeroGal',
    carrierContactNo: '1-800-284-2622',
    airlineLink: 'https://www.aerogal.com.ec/',
    airlineCheckin: 'https://www.aerogal.com.ec/',
    airlineBaggagePolicy: 'https://www.aerogal.com.ec/',
    airlineSeating: 'https://www.aerogal.com.ec/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Aerolineas Argentinas',
    id: 'Aerolineas Argentinas',
    carrierContactNo: '1-800-333-0276',
    airlineLink:
      'https://www.aerolineas.com.ar/arg/main.asp?idSitio=AU&idPagina=52&idIdioma=en',
    airlineCheckin:
      'https://www.aerolineas.com.ar/en-us/booking_services/flight_status',
    airlineBaggagePolicy:
      'https://www.aerolineas.com.ar/arg/main.asp?idSitio=AU&idPagina=52&idIdioma=en',
    airlineSeating:
      'https://www.aerolineas.com.ar/en-us/booking_services/flight_status',
  },
  {
    carrierImg: carrierImg3,
    label: 'Aerolineas Sosa',
    id: 'Aerolineas Sosa',
    carrierContactNo: '',
    airlineLink: 'https://als.aerocrs.net/',
    airlineCheckin: 'https://als.aerocrs.net/',
    airlineBaggagePolicy: 'https://als.aerocrs.net/',
    airlineSeating: 'https://als.aerocrs.net/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Aeromar Airlines',
    id: 'Aeromar Airlines',
    carrierContactNo: '1-800-237-6627',
    airlineLink: 'https://www.aeromar.mx/en/home/',
    airlineCheckin: 'https://www.aeromar.mx/en/webcheck-in/',
    airlineBaggagePolicy: 'https://www.aeromar.mx/en/information-of-passenger/',
    airlineSeating: 'https://www.aeromar.mx/en/webcheck-in/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Aeropostal',
    id: 'Aeropostal',
    carrierContactNo: '1-888-912-8466',
    airlineLink: 'https://www.aeropostal.com/2014/home/',
    airlineCheckin: 'https://www.aeropostal.com/2014/home/',
    airlineBaggagePolicy: 'https://www.aeropostal.com/2014/home/',
    airlineSeating: 'https://www.aeropostal.com/2014/home/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Aigle Azur',
    id: 'Aigle Azur',
    carrierContactNo: '',
    airlineLink: 'https://www.aigle-azur.com/en',
    airlineCheckin:
      'https://checkin.si.amadeus.net/static/PRD/ZI/#/identification',
    airlineBaggagePolicy:
      'https://checkin.si.amadeus.net/static/PRD/ZI/#/identification',
    airlineSeating:
      'https://checkin.si.amadeus.net/static/PRD/ZI/#/identification',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Algerie',
    id: 'Air Algerie',
    carrierContactNo: '1-514-905-0147',
    airlineLink: 'https://airalgerie.dz/en/',
    airlineCheckin:
      'https://checkin.si.amadeus.net/static/PRD/AH/#/identification?ln=en',
    airlineBaggagePolicy:
      'https://checkin.si.amadeus.net/static/PRD/AH/#/identification?ln=en',
    airlineSeating:
      'https://checkin.si.amadeus.net/static/PRD/AH/#/identification?ln=en',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Arabia',
    id: 'Air Arabia',
    carrierContactNo: '+91 2271004777',
    airlineLink: 'https://www.airarabia.com/en',
    airlineCheckin:
      'https://webcheckin.airarabia.com/accelaero/en/index.html#/',
    airlineBaggagePolicy:
      'https://webcheckin.airarabia.com/accelaero/en/index.html#/',
    airlineSeating:
      'https://webcheckin.airarabia.com/accelaero/en/index.html#/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Arabia Egypt',
    id: 'Air Arabia Egypt',
    carrierContactNo: '',
    airlineLink: 'https://www.airarabia.com/en/air-arabia-egypt',
    airlineCheckin:
      'https://webcheckin.airarabia.com/accelaero/en/index.html#/',
    airlineBaggagePolicy:
      'https://webcheckin.airarabia.com/accelaero/en/index.html#/',
    airlineSeating:
      'https://webcheckin.airarabia.com/accelaero/en/index.html#/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Arabia Maroc',
    id: 'Air Arabia Maroc',
    carrierContactNo: '',
    airlineLink: 'https://www.airarabia.com/en/air-arabia-maroc',
    airlineCheckin:
      'https://webcheckin.airarabia.com/accelaero/en/index.html#/',
    airlineBaggagePolicy:
      'https://webcheckin.airarabia.com/accelaero/en/index.html#/',
    airlineSeating:
      'https://webcheckin.airarabia.com/accelaero/en/index.html#/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Asia Zest',
    id: 'Air Asia Zest',
    carrierContactNo: '1-844-727-4588',
    airlineLink: 'https://www.airasia.com/',
    airlineCheckin: 'https://www.airasia.com/check-in/en/gb',
    airlineBaggagePolicy: 'https://www.airasia.com/check-in/en/gb',
    airlineSeating: 'https://www.airasia.com/check-in/en/gb',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Astana',
    id: 'Air Astana',
    carrierContactNo: '011-771-7258-4477',
    airlineLink: 'https://airastana.com/global/en-US/',
    airlineCheckin:
      'https://airastana.com/global/en-us/My-Booking/Online-Check-In',
    airlineBaggagePolicy:
      'https://airastana.com/global/en-us/My-Booking/Online-Check-In',
    airlineSeating:
      'https://airastana.com/global/en-us/My-Booking/Online-Check-In',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Austral',
    id: 'Air Austral',
    carrierContactNo: '',
    airlineLink: 'https://www.air-austral.com/',
    airlineCheckin:
      'https://www.air-austral.com/reservation/ma-reservation-en-cours.html',
    airlineBaggagePolicy:
      'https://www.air-austral.com/reservation/ma-reservation-en-cours.html',
    airlineSeating:
      'https://www.air-austral.com/reservation/ma-reservation-en-cours.html',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Bagan',
    id: 'Air Bagan',
    carrierContactNo: '',
    airlineLink: 'https://www.airbagan.com/',
    airlineCheckin: 'https://www.airbagan.com/',
    airlineBaggagePolicy: 'https://www.airbagan.com/',
    airlineSeating: 'https://www.airbagan.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Belgium',
    id: 'Air Belgium',
    carrierContactNo: '',
    airlineLink: 'https://www.airbelgium.com/en',
    airlineCheckin: 'https://www.airbelgium.com/en',
    airlineBaggagePolicy: 'https://www.airbelgium.com/en',
    airlineSeating: 'https://www.airbelgium.com/en',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Berlin',
    id: 'Air Berlin',
    carrierContactNo: '1-866-266-5588',
    airlineLink: 'https://www.airberlin.com/',
    airlineCheckin: 'https://www.airberlin.com/',
    airlineBaggagePolicy: 'https://www.airberlin.com/',
    airlineSeating: 'https://www.airberlin.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Botswana',
    id: 'Air Botswana',
    carrierContactNo: '1-800-518-7781',
    airlineLink: 'https://www.airbotswana.co.bw/',
    airlineCheckin: 'https://icheck.sita.aero/iCheckWebBP/',
    airlineBaggagePolicy: 'https://icheck.sita.aero/iCheckWebBP/',
    airlineSeating: 'https://icheck.sita.aero/iCheckWebBP/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Burkina',
    id: 'Air Burkina',
    carrierContactNo: '',
    airlineLink: 'https://www.air-burkina.com/en/',
    airlineCheckin: 'https://www.air-burkina.com/en/',
    airlineBaggagePolicy: 'https://www.air-burkina.com/en/',
    airlineSeating: 'https://www.air-burkina.com/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Busan',
    id: 'Air Busan',
    carrierContactNo: '011 82 70-7997-3060',
    airlineLink: 'https://www.en.airbusan.com/content/individual/?',
    airlineCheckin:
      'https://en.airbusan.com/content/common/service/airport/autoIssue',
    airlineBaggagePolicy:
      'https://en.airbusan.com/content/common/service/airport/autoIssue',
    airlineSeating:
      'https://en.airbusan.com/content/common/service/airport/autoIssue',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Caraibes',
    id: 'Air Caraibes',
    carrierContactNo: '1-877-772-1005',
    airlineLink: 'https://www.aircaraibes.com/',
    airlineCheckin: 'https://www.aircaraibes.com/',
    airlineBaggagePolicy: 'https://www.aircaraibes.com/',
    airlineSeating: 'https://www.aircaraibes.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Changan',
    id: 'Air Changan',
    carrierContactNo: '',
    airlineLink: 'https://www.airchangan.com/portal',
    airlineCheckin: 'https://www.airchangan.com/portal/dynamic/checkinonline',
    airlineBaggagePolicy:
      'https://www.airchangan.com/portal/dynamic/checkinonline',
    airlineSeating: 'https://www.airchangan.com/portal/dynamic/checkinonline',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Chathams',
    id: 'Air Chathams',
    carrierContactNo: '',
    airlineLink: 'https://www.airchathams.co.nz/',
    airlineCheckin:
      'https://fo-apac.ttinteractive.com/Zenith/FrontOffice/(S(0ai1aam04o4cuycopqhrg21d))/AirChathams/en-GB/Customer/Login',
    airlineBaggagePolicy:
      'https://fo-apac.ttinteractive.com/Zenith/FrontOffice/(S(0ai1aam04o4cuycopqhrg21d))/AirChathams/en-GB/Customer/Login',
    airlineSeating:
      'https://fo-apac.ttinteractive.com/Zenith/FrontOffice/(S(0ai1aam04o4cuycopqhrg21d))/AirChathams/en-GB/Customer/Login',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air China',
    id: 'Air China',
    carrierContactNo: '1-800-882-8122',
    airlineLink: 'https://www.airchina.us/US/GB/Home',
    airlineCheckin: 'https://www.airchina.us/US/GB/booking/online-check-in/',
    airlineBaggagePolicy: 'https://www.airchina.us/US/GB/info/checked-baggage/',
    airlineSeating: 'https://www.airchina.us/US/GB/booking/online-check-in/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Choice One',
    id: 'Air Choice One',
    carrierContactNo: '1-866-435-9847',
    airlineLink: 'https://www.airchoiceone.com/',
    airlineCheckin: 'https://www.airchoiceone.com/',
    airlineBaggagePolicy:
      'https://www.airchoiceone.com/customer-service/baggage-policies/',
    airlineSeating: 'https://www.airchoiceone.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Company Khors',
    id: 'Air Company Khors',
    carrierContactNo: '38 044 246 2888',
    airlineLink: 'https://www.khors.com.ua/',
    airlineCheckin: 'https://www.khors.com.ua/',
    airlineBaggagePolicy:
      'https://www.khors.com.ua/customer-service/baggage-policies/',
    airlineSeating: 'https://www.khors.com.ua/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Corsica',
    id: 'Air Corsica',
    carrierContactNo: '',
    airlineLink: 'https://www.aircorsica.com/flights/',
    airlineCheckin:
      'https://www.aircorsica.com/flights/airline-tickets/registration.html',
    airlineBaggagePolicy:
      'https://www.aircorsica.com/flights/airline-tickets/registration.html',
    airlineSeating:
      'https://www.aircorsica.com/flights/airline-tickets/registration.html',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Cote d\'Ivoire',
    id: 'Air Cote d\'Ivoire',
    carrierContactNo: '',
    airlineLink: 'https://www.aircotedivoire.com/',
    airlineCheckin: 'https://www.aircotedivoire.com/',
    airlineBaggagePolicy: 'https://www.aircotedivoire.com/',
    airlineSeating: 'https://www.aircotedivoire.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Creebec',
    id: 'Air Creebec',
    carrierContactNo: '',
    airlineLink: 'https://www.aircreebec.ca/',
    airlineCheckin: 'https://www.aircreebec.ca/',
    airlineBaggagePolicy: 'https://www.aircreebec.ca/',
    airlineSeating: 'https://www.aircreebec.ca/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Do (Hokkaido International Airlines)',
    id: 'Air Do (Hokkaido International Airlines)',
    carrierContactNo: '',
    airlineLink: 'https://www.airdo.jp/en/',
    airlineCheckin: 'https://www.airdo.jp/en/',
    airlineBaggagePolicy: 'https://www.airdo.jp/en/',
    airlineSeating: 'https://www.airdo.jp/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Dolomiti',
    id: 'Air Dolomiti',
    carrierContactNo: '1-800-428-4322',
    airlineLink: 'https://www.airdolomiti.eu/',
    airlineCheckin: 'https://www.airdolomiti.eu/',
    airlineBaggagePolicy: 'https://www.airdolomiti.eu/',
    airlineSeating: 'https://www.airdolomiti.eu/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Europa',
    id: 'Air Europa',
    carrierContactNo: '1-844-415-3955',
    airlineLink: 'https://www.aireuropa.com/en/flights',
    airlineCheckin: 'https://www.aireuropa.com/en/fligths/my-booking',
    airlineBaggagePolicy: 'https://www.aireuropa.com/en/flights/baggage',
    airlineSeating: 'https://www.aireuropa.com/en/fligths/my-booking',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Finland',
    id: 'Air Finland',
    carrierContactNo: '',
    airlineLink: 'https://www.overlandairways.com/',
    airlineCheckin: 'https://www.overlandairways.com/',
    airlineBaggagePolicy: 'https://www.overlandairways.com/',
    airlineSeating: 'https://www.overlandairways.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Greenland',
    id: 'Air Greenland',
    carrierContactNo: '',
    airlineLink: 'https://www.airgreenland.com/',
    airlineCheckin: 'https://www.airgreenland.com/',
    airlineBaggagePolicy: 'https://www.airgreenland.com/',
    airlineSeating: 'https://www.airgreenland.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Guilin',
    id: 'Air Guilin',
    carrierContactNo: '',
    airlineLink: 'https://www.airguilin.com/',
    airlineCheckin: 'https://www.airguilin.com/',
    airlineBaggagePolicy: 'https://www.airguilin.com/',
    airlineSeating: 'https://www.airguilin.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air India Express',
    id: 'Air India Express',
    carrierContactNo: '',
    airlineLink: 'https://www.airindiaexpress.in/en',
    airlineCheckin: 'https://www.airindiaexpress.in/en/manage/manage-booking',
    airlineBaggagePolicy:
      'https://www.airindiaexpress.in/en/manage/manage-booking',
    airlineSeating: 'https://www.airindiaexpress.in/en/manage/manage-booking',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Italy',
    id: 'Air Italy',
    carrierContactNo: '1-866-387-6359',
    airlineLink: 'https://www.airitaly.com/it-IT',
    airlineCheckin: 'https://www.airitaly.com/en/your-flights/web-check-in',
    airlineBaggagePolicy:
      'https://www.airitaly.com/us/beforetheflight/conditions.aspx#bagagliostiva',
    airlineSeating: 'https://www.airitaly.com/en/your-flights/web-check-in',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Juan',
    id: 'Air Juan',
    carrierContactNo: '1-888-387-8989',
    airlineLink: 'https://www.islandairx.com/',
    airlineCheckin: 'https://www.islandairx.com/',
    airlineBaggagePolicy: 'https://www.islandairx.com/',
    airlineSeating: 'https://www.islandairx.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'AIR KBZ',
    id: 'AIR KBZ',
    carrierContactNo: '',
    airlineLink: 'https://www.airkbz.com/',
    airlineCheckin: 'https://www.airkbz.com/',
    airlineBaggagePolicy: 'https://www.airkbz.com/',
    airlineSeating: 'https://www.airkbz.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Lituanica',
    id: 'Air Lituanica',
    carrierContactNo: '',
    airlineLink: 'https://www.airlituanica.com/en',
    airlineCheckin: 'https://www.airlituanica.com/en',
    airlineBaggagePolicy: 'https://www.airlituanica.com/en',
    airlineSeating: 'https://www.airlituanica.com/en',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Macau',
    id: 'Air Macau',
    carrierContactNo: '011-853-8396-5555',
    airlineLink: 'https://www.airmacau.com.mo/eng/',
    airlineCheckin: 'https://www.airmacau.com.mo/eng/ibe/web_check-in.php',
    airlineBaggagePolicy:
      'https://www.airmacau.com.mo/eng/ibe/web_check-in.php',
    airlineSeating: 'https://www.airmacau.com.mo/eng/ibe/web_check-in.php',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Madagascar',
    id: 'Air Madagascar',
    carrierContactNo: '011-261-20-225-1000',
    airlineLink: 'https://www.airmadagascar.com/fr',
    airlineCheckin: 'https://www.airmadagascar.com/fr/mes-reservations',
    airlineBaggagePolicy: 'https://www.airmadagascar.com/fr/mes-reservations',
    airlineSeating: 'https://www.airmadagascar.com/fr/mes-reservations',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Malta',
    id: 'Air Malta',
    carrierContactNo: '011-356-2299-9884',
    airlineLink: 'https://www.airmalta.com/',
    airlineCheckin: 'https://www.airmalta.com/',
    airlineBaggagePolicy: 'https://www.airmalta.com/baggage',
    airlineSeating: 'https://www.airmalta.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Manas',
    id: 'Air Manas',
    carrierContactNo: '',
    airlineLink: 'https://airmanas.com/en/index.html',
    airlineCheckin: 'https://airmanas.com/en/index.html',
    airlineBaggagePolicy: 'https://airmanas.com/en/index.html',
    airlineSeating: 'https://airmanas.com/en/index.html',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Mauritius',
    id: 'Air Mauritius',
    carrierContactNo: '1-800-380-6541',
    airlineLink: 'https://www.airmauritius.com/index_oc.htm',
    airlineCheckin: 'https://www.airmauritius.com/index_oc.htm',
    airlineBaggagePolicy: 'https://www.airmauritius.com/index_oc.htm',
    airlineSeating: 'https://www.airmauritius.com/index_oc.htm',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Mediterranee',
    id: 'Air Mediterranee',
    carrierContactNo: '011-33-581-312-999',
    airlineLink: 'https://www.air-mediterranee.fr/',
    airlineCheckin: 'https://www.air-mediterranee.fr/',
    airlineBaggagePolicy: 'https://www.air-mediterranee.fr/',
    airlineSeating: 'https://www.air-mediterranee.fr/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Moldova',
    id: 'Air Moldova',
    carrierContactNo: '011-373-22-830-830',
    airlineLink: 'https://www.airmoldova.md/home-en/',
    airlineCheckin: 'https://www.airmoldova.md/home-en/',
    airlineBaggagePolicy: 'https://www.airmoldova.md/luggage-en/',
    airlineSeating: 'https://www.airmoldova.md/home-en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Namibia',
    id: 'Air Namibia',
    carrierContactNo: '1-877-496-9893',
    airlineLink: 'https://www.airnamibia.com/',
    airlineCheckin: 'https://book-airnamibia.crane.aero/web/ICIPNRSearch.xhtml',
    airlineBaggagePolicy:
      'https://book-airnamibia.crane.aero/web/ICIPNRSearch.xhtml',
    airlineSeating: 'https://book-airnamibia.crane.aero/web/ICIPNRSearch.xhtml',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air New Zealand',
    id: 'Air New Zealand',
    carrierContactNo: '1-800-262-1234',
    airlineLink: 'https://www.airnewzealand.com/?noredir=1',
    airlineCheckin: 'https://www.airnewzealand.com/?noredir=1',
    airlineBaggagePolicy: 'https://www.airnewzealand.com/baggage',
    airlineSeating: 'https://www.airnewzealand.com/?noredir=1',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Niugini',
    id: 'Air Niugini',
    carrierContactNo: '',
    airlineLink: 'https://www.airniugini.com.pg/',
    airlineCheckin: 'https://www.airniugini.com.pg/',
    airlineBaggagePolicy: 'https://www.airniugini.com.pg/',
    airlineSeating: 'https://www.airniugini.com.pg/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air North Airlines',
    id: 'Air North Airlines',
    carrierContactNo: '1-800-661-0407',
    airlineLink: 'https://www.flyairnorth.com/default.aspx',
    airlineCheckin:
      'https://booking.flyairnorth.com/servlet/OnlineCheckinPassengerForm',
    airlineBaggagePolicy:
      'https://booking.flyairnorth.com/servlet/OnlineCheckinPassengerForm',
    airlineSeating:
      'https://booking.flyairnorth.com/servlet/OnlineCheckinPassengerForm',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air One',
    id: 'Air One',
    carrierContactNo: '1-888-935-9247',
    airlineLink: 'https://www.alitalia.com/AP_EN/home/index.aspx',
    airlineCheckin: 'https://www.alitalia.com/AP_EN/home/index.aspx',
    airlineBaggagePolicy: 'https://www.alitalia.com/AP_EN/home/index.aspx',
    airlineSeating: 'https://www.alitalia.com/AP_EN/home/index.aspx',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Peace',
    id: 'Air Peace',
    carrierContactNo: '',
    airlineLink: 'https://www.flyairpeace.com/',
    airlineCheckin: 'https://www.flyairpeace.com/',
    airlineBaggagePolicy: 'https://www.flyairpeace.com/',
    airlineSeating: 'https://www.flyairpeace.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Seoul',
    id: 'Air Seoul',
    carrierContactNo: '',
    airlineLink: 'https://www.flyairseoul.com/CW/EN/main.do',
    airlineCheckin: 'https://www.flyairseoul.com/CW/EN/main.do',
    airlineBaggagePolicy: 'https://www.flyairseoul.com/CW/EN/main.do',
    airlineSeating: 'https://www.flyairseoul.com/CW/EN/main.do',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Serbia',
    id: 'Air Serbia',
    carrierContactNo: '011-381-11-311-2123',
    airlineLink: 'https://www.airserbia.com/en/welcome',
    airlineCheckin: 'https://www.airserbia.com/en-US/',
    airlineBaggagePolicy: 'https://www.airserbia.com/en/baggage-information',
    airlineSeating: 'https://www.airserbia.com/en-US/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Seychelles',
    id: 'Air Seychelles',
    carrierContactNo: '',
    airlineLink: 'https://www.airseychelles.com/',
    airlineCheckin:
      'https://www.airseychelles.com/en/before-you-fly/web-checkin',
    airlineBaggagePolicy:
      'https://www.airseychelles.com/en/before-you-fly/web-checkin',
    airlineSeating:
      'https://www.airseychelles.com/en/before-you-fly/web-checkin',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Tahiti',
    id: 'Air Tahiti',
    carrierContactNo: '011-689-86-42-42',
    airlineLink: 'https://www.airtahiti.com/',
    airlineCheckin: 'https://www.airtahiti.com/checkin',
    airlineBaggagePolicy: 'https://www.airtahiti.com/checkin',
    airlineSeating: 'https://www.airtahiti.com/checkin',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Tahiti Nui',
    id: 'Air Tahiti Nui',
    carrierContactNo: '1-877-824-4846',
    airlineLink: 'https://www.airtahitinui.com/us-en',
    airlineCheckin: 'https://www.airtahitinui.com/us-en',
    airlineBaggagePolicy: 'https://www.airtahitinui.com/us-en',
    airlineSeating: 'https://www.airtahitinui.com/us-en',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Tindi',
    id: 'Air Tindi',
    carrierContactNo: '1-888-545-6794',
    airlineLink: 'https://www.airtindi.com/',
    airlineCheckin: 'https://www.airtindi.com/',
    airlineBaggagePolicy: 'https://www.airtindi.com/',
    airlineSeating: 'https://www.airtindi.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Transat',
    id: 'Air Transat',
    carrierContactNo: '1-514-636-3630',
    airlineLink: 'https://www.airtransat.com/en-CA/home',
    airlineCheckin:
      'https://www.airtransat.com/en-CA/online-check-in?ici=homepage&icn=moteur_online-checkin',
    airlineBaggagePolicy:
      'https://www.airtransat.com/en-CA/online-check-in?ici=homepage&icn=moteur_online-checkin',
    airlineSeating:
      'https://www.airtransat.com/en-CA/online-check-in?ici=homepage&icn=moteur_online-checkin',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Transport International',
    id: 'Air Transport International',
    carrierContactNo: '1-800-866-2844',
    airlineLink: 'https://www.airtransport.cc/',
    airlineCheckin: 'https://www.airtransport.cc/',
    airlineBaggagePolicy: 'https://www.airtransport.cc/',
    airlineSeating: 'https://www.airtransport.cc/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Vanuatu',
    id: 'Air Vanuatu',
    carrierContactNo: '',
    airlineLink: 'https://www.airvanuatu.com/',
    airlineCheckin: 'https://www.airvanuatu.com/home/plan-book/webcheck',
    airlineBaggagePolicy: 'https://www.airvanuatu.com/home/plan-book/webcheck',
    airlineSeating: 'https://www.airvanuatu.com/home/plan-book/webcheck',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Wisconsin Airlines',
    id: 'Air Wisconsin Airlines',
    carrierContactNo: '920-739-5123',
    airlineLink: 'https://www.airwis.com/',
    airlineCheckin: 'https://www.airwis.com/',
    airlineBaggagePolicy: 'https://www.airwis.com/',
    airlineSeating: 'https://www.airwis.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Air Zimbabwe',
    id: 'Air Zimbabwe',
    carrierContactNo: '',
    airlineLink: 'https://www.airzimbabwe.aero/',
    airlineCheckin: 'https://www.airzimbabwe.aero/prepare-travel/check',
    airlineBaggagePolicy: 'https://www.airzimbabwe.aero/prepare-travel/check',
    airlineSeating: 'https://www.airzimbabwe.aero/prepare-travel/check',
  },
  {
    carrierImg: carrierImg3,
    label: 'AirAsia',
    id: 'AirAsia',
    carrierContactNo: '1-844-727-4588',
    airlineLink: 'https://www.airasia.com/',
    airlineCheckin: 'https://www.airasia.com/check-in/en/gb',
    airlineBaggagePolicy: 'https://www.airasia.com/check-in/en/gb',
    airlineSeating: 'https://www.airasia.com/check-in/en/gb',
  },
  {
    carrierImg: carrierImg3,
    label: 'AirAsia India',
    id: 'AirAsia India',
    carrierContactNo: '',
    airlineLink: 'https://www.airasia.com/flights/india',
    airlineCheckin: 'https://www.airasia.com/flights/india',
    airlineBaggagePolicy: 'https://www.airasia.com/flights/india',
    airlineSeating: 'https://www.airasia.com/flights/india',
  },
  {
    carrierImg: carrierImg3,
    label: 'AirAsia Japan',
    id: 'AirAsia Japan',
    carrierContactNo: '',
    airlineLink: 'https://www.airasia.com/flights/japan',
    airlineCheckin: 'https://www.airasia.com/flights/japan',
    airlineBaggagePolicy: 'https://www.airasia.com/flights/japan',
    airlineSeating: 'https://www.airasia.com/flights/japan',
  },
  {
    carrierImg: carrierImg3,
    label: 'AirAsia X',
    id: 'AirAsia X',
    carrierContactNo: '1-844-727-4588',
    airlineLink: 'https://www.airasia.com/',
    airlineCheckin: 'https://www.airasia.com/check-in/en/gb',
    airlineBaggagePolicy: 'https://www.airasia.com/check-in/en/gb',
    airlineSeating: 'https://www.airasia.com/check-in/en/gb',
  },
  {
    carrierImg: carrierImg3,
    label: 'airBaltic',
    id: 'airBaltic',
    carrierContactNo: '011-371-6700-6006',
    airlineLink: 'https://www.airbaltic.com/en-US/index',
    airlineCheckin: 'https://www.airbaltic.com/en-US/index',
    airlineBaggagePolicy: 'https://www.airbaltic.com/en/baggage',
    airlineSeating: 'https://www.airbaltic.com/en-US/index',
  },
  {
    carrierImg: carrierImg3,
    label: 'AirBlue',
    id: 'AirBlue',
    carrierContactNo: '',
    airlineLink: 'https://us.aircalin.com/en',
    airlineCheckin:
      'https://us.aircalin.com/en/flights-bookings/online-check-in',
    airlineBaggagePolicy:
      'https://us.aircalin.com/en/flights-bookings/online-check-in',
    airlineSeating:
      'https://us.aircalin.com/en/flights-bookings/online-check-in',
  },
  {
    carrierImg: carrierImg3,
    label: 'Aircalin',
    id: 'Aircalin',
    carrierContactNo: '',
    airlineLink: 'https://www.airblue.com/',
    airlineCheckin: 'https://www.airblue.com/bookings/flight_selection.aspx',
    airlineBaggagePolicy:
      'https://www.airblue.com/bookings/flight_selection.aspx',
    airlineSeating: 'https://www.airblue.com/bookings/flight_selection.aspx',
  },
  {
    carrierImg: carrierImg3,
    label: 'Aircompany Armenia',
    id: 'Aircompany Armenia',
    carrierContactNo: '',
    airlineLink: 'https://www.armeniafly.com/en',
    airlineCheckin: 'https://www.armeniafly.com/manage-reservation',
    airlineBaggagePolicy: 'https://www.armeniafly.com/manage-reservation',
    airlineSeating: 'https://www.armeniafly.com/manage-reservation',
  },
  {
    carrierImg: carrierImg3,
    label: 'Airline Container Leasing',
    id: 'Airline Container Leasing',
    carrierContactNo: '',
    airlineLink: 'https://www.aclairshop.com/',
    airlineCheckin: 'https://www.aclairshop.com/',
    airlineBaggagePolicy: 'https://www.aclairshop.com/',
    airlineSeating: 'https://www.aclairshop.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Airlines of Papua New Guinea',
    id: 'Airlines of Papua New Guinea',
    carrierContactNo: '',
    airlineLink: 'https://www.pngair.com.pg/en/',
    airlineCheckin: 'https://www.pngair.com.pg/en/',
    airlineBaggagePolicy: 'https://www.pngair.com.pg/en/',
    airlineSeating: 'https://www.pngair.com.pg/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Airnorth',
    id: 'Airnorth',
    carrierContactNo: '',
    airlineLink: 'https://www.airnorth.com.au/',
    airlineCheckin: 'https://www.airnorth.com.au/',
    airlineBaggagePolicy: 'https://www.airnorth.com.au/',
    airlineSeating: 'https://www.airnorth.com.au/',
  },
  {
    carrierImg: carrierImg3,
    label: 'AirSwift',
    id: 'AirSwift',
    carrierContactNo: '',
    airlineLink: 'https://air-swift.com/',
    airlineCheckin: 'https://air-swift.com/',
    airlineBaggagePolicy: 'https://air-swift.com/',
    airlineSeating: 'https://air-swift.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'AirTran Airways',
    id: 'AirTran Airways',
    carrierContactNo: '1-800-247-8726',
    airlineLink: 'https://www.airtran.com/',
    airlineCheckin: 'https://www.airtran.com/',
    airlineBaggagePolicy: 'https://www.airtran.com/',
    airlineSeating: 'https://www.airtran.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'AIS Airlines',
    id: 'AIS Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.aisairlines.com/',
    airlineCheckin: 'https://www.aisairlines.com/',
    airlineBaggagePolicy: 'https://www.aisairlines.com/',
    airlineSeating: 'https://www.aisairlines.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Alajnihah Airways',
    id: 'Alajnihah Airways',
    carrierContactNo: '',
    airlineLink: 'https://www.alajnihah.com/home/',
    airlineCheckin: 'https://www.alajnihah.com/home/',
    airlineBaggagePolicy: 'https://www.alajnihah.com/home/',
    airlineSeating: 'https://www.alajnihah.com/home/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Alaska Seaplane Service',
    id: 'Alaska Seaplane Service',
    carrierContactNo: '907-789-3331',
    airlineLink: 'https://www.flyalaskaseaplanes.com/',
    airlineCheckin: 'https://www.flyalaskaseaplanes.com/',
    airlineBaggagePolicy: 'https://www.flyalaskaseaplanes.com/',
    airlineSeating: 'https://www.flyalaskaseaplanes.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Alitalia',
    id: 'Alitalia',
    carrierContactNo: '1-800-223-5730',
    airlineLink: 'https://www.alitalia.com/en_us/',
    airlineCheckin:
      'https://www.alitalia.com/en_us/fly-alitalia/check-in/online-check-in.html',
    airlineBaggagePolicy:
      'https://www.alitalia.com/en_us/fly-alitalia/baggage.html',
    airlineSeating:
      'https://www.alitalia.com/en_us/fly-alitalia/check-in/online-check-in.html',
  },
  {
    carrierImg: carrierImg3,
    label: 'Allegiant Air',
    id: 'Allegiant Air',
    carrierContactNo: '1-702-505-8888',
    airlineLink: 'https://www.allegiantair.com/',
    airlineCheckin: 'https://www.allegiantair.com/online-checkin',
    airlineBaggagePolicy: 'https://www.allegiantair.com/online-checkin',
    airlineSeating: 'https://www.allegiantair.com/online-checkin',
  },
  {
    carrierImg: carrierImg3,
    label: 'AlMasria Universal Airlines',
    id: 'AlMasria Universal Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.almasriaairlines.com/',
    airlineCheckin: 'https://www.almasriaairlines.com/',
    airlineBaggagePolicy: 'https://www.almasriaairlines.com/',
    airlineSeating: 'https://www.almasriaairlines.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Alrosa Mirny Air',
    id: 'Alrosa Mirny Air',
    carrierContactNo: '',
    airlineLink: 'https://www.alrosa.aero/',
    airlineCheckin: 'https://www.alrosa.aero/',
    airlineBaggagePolicy: 'https://www.alrosa.aero/',
    airlineSeating: 'https://www.alrosa.aero/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Alsa Grupo SLU',
    id: 'Alsa Grupo SLU',
    carrierContactNo: '',
    airlineLink: 'https://www.alsa.com/en',
    airlineCheckin: 'https://www.alsa.com/en',
    airlineBaggagePolicy: 'https://www.alsa.com/en',
    airlineSeating: 'https://www.alsa.com/en',
  },
  {
    carrierImg: carrierImg3,
    label: 'Amapola Flyg AB',
    id: 'Amapola Flyg AB',
    carrierContactNo: '',
    airlineLink: 'https://www.amapola.nu/en/',
    airlineCheckin: 'https://www.amapola.nu/en/',
    airlineBaggagePolicy: 'https://www.amapola.nu/en/',
    airlineSeating: 'https://www.amapola.nu/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Amaszonas',
    id: 'Amaszonas',
    carrierContactNo: '',
    airlineLink: 'https://www.amaszonas.com/en-us/',
    airlineCheckin: 'https://www.amaszonas.com/en-us/',
    airlineBaggagePolicy: 'https://www.amaszonas.com/en-us/',
    airlineSeating: 'https://www.amaszonas.com/en-us/',
  },
  {
    carrierImg: carrierImg3,
    label: 'ANA All Nippon Airways',
    id: 'ANA All Nippon Airways',
    carrierContactNo: '1-800-235-9262',
    airlineLink: 'https://www.ana.co.jp/en/us/',
    airlineCheckin: 'https://www.ana.co.jp/en/us/',
    airlineBaggagePolicy:
      'https://www.ana.co.jp/en/us/international/prepare/baggage/',
    airlineSeating: 'https://www.ana.co.jp/en/us/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Andes Líneas Aéreas',
    id: 'Andes Líneas Aéreas',
    carrierContactNo: '',
    airlineLink: 'https://www.andesonline.com/',
    airlineCheckin: 'https://www.andesonline.com/',
    airlineBaggagePolicy: 'https://www.andesonline.com/',
    airlineSeating: 'https://www.andesonline.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Angara Airlines',
    id: 'Angara Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.angara.aero/en/',
    airlineCheckin: 'https://www.angara.aero/en/',
    airlineBaggagePolicy: 'https://www.angara.aero/en/',
    airlineSeating: 'https://www.angara.aero/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'APG Airilines',
    id: 'APG Airilines',
    carrierContactNo: '1-800-380-6541',
    airlineLink: 'https://www.apg-airlines.com/search?lang=en_US',
    airlineCheckin: 'https://www.apg-airlines.com/search?lang=en_US',
    airlineBaggagePolicy: 'https://www.apg-airlines.com/search?lang=en_US',
    airlineSeating: 'https://www.apg-airlines.com/search?lang=en_US',
  },
  {
    carrierImg: carrierImg3,
    label: 'Arik Air',
    id: 'Arik Air',
    carrierContactNo: '1-877-900-2745',
    airlineLink: 'https://www.arikair.com/',
    airlineCheckin: 'https://www.arikair.com/',
    airlineBaggagePolicy: 'https://www.arikair.com/',
    airlineSeating: 'https://www.arikair.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Arkia-Israeli Airlines',
    id: 'Arkia-Israeli Airlines',
    carrierContactNo: '011-972-3-6902210',
    airlineLink: 'https://www.arkia.com/',
    airlineCheckin: 'https://www.arkia.com/',
    airlineBaggagePolicy: 'https://www.arkia.com/',
    airlineSeating: 'https://www.arkia.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Aruba Airlines',
    id: 'Aruba Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.arubaairlines.com/',
    airlineCheckin: 'https://www.arubaairlines.com/',
    airlineBaggagePolicy: 'https://www.arubaairlines.com/',
    airlineSeating: 'https://www.arubaairlines.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Asia Atlantic Airlines',
    id: 'Asia Atlantic Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.asiaatlanticairlines.com/',
    airlineCheckin: 'https://www.asiaatlanticairlines.com/',
    airlineBaggagePolicy: 'https://www.asiaatlanticairlines.com/',
    airlineSeating: 'https://www.asiaatlanticairlines.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Asian Wings Airways',
    id: 'Asian Wings Airways',
    carrierContactNo: '',
    airlineLink: 'https://www.asianwingsair.com/',
    airlineCheckin: 'https://www.asianwingsair.com/',
    airlineBaggagePolicy: 'https://www.asianwingsair.com/',
    airlineSeating: 'https://www.asianwingsair.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Asiana Airlines',
    id: 'Asiana Airlines',
    carrierContactNo: '1-800-227-4262',
    airlineLink: 'https://flyasiana.com/C/US/EN/index',
    airlineCheckin: 'https://flyasiana.com/C/US/EN/contents/user-guide',
    airlineBaggagePolicy: 'https://flyasiana.com/C/US/EN/contents/user-guide',
    airlineSeating: 'https://flyasiana.com/C/US/EN/contents/user-guide',
  },
  {
    carrierImg: carrierImg3,
    label: 'ASKY Airlines',
    id: 'ASKY Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.flyasky.com/',
    airlineCheckin: 'https://www.flyasky.com/',
    airlineBaggagePolicy: 'https://www.flyasky.com/',
    airlineSeating: 'https://www.flyasky.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'ASL Airlines France',
    id: 'ASL Airlines France',
    carrierContactNo: '',
    airlineLink: 'https://www.aslairlines.fr/en/',
    airlineCheckin:
      'https://fo-emea.ttinteractive.com/Zenith/FrontOffice/(S(ze2ges1axgr3g4vhm2fejpuv))/Europeairpost/en-GB/Home/FindBooking?findbookingmode=WebCheckin',
    airlineBaggagePolicy:
      'https://www.aslairlines.fr/en/travellers-information/prepare-your-trip/your-baggage/',
    airlineSeating:
      'https://fo-emea.ttinteractive.com/Zenith/FrontOffice/(S(ze2ges1axgr3g4vhm2fejpuv))/Europeairpost/en-GB/Home/FindBooking?findbookingmode=WebCheckin',
  },
  {
    carrierImg: carrierImg3,
    label: 'AtlasGlobal',
    id: 'AtlasGlobal',
    carrierContactNo: '011-90-850-222-0000',
    airlineLink: 'https://www.atlasglb.com/en',
    airlineCheckin: 'https://www.atlasglb.com/en',
    airlineBaggagePolicy: 'https://www.atlasglb.com/en/baggage',
    airlineSeating: 'https://www.atlasglb.com/en',
  },
  {
    carrierImg: carrierImg3,
    label: 'Auric Air',
    id: 'Auric Air',
    carrierContactNo: '',
    airlineLink: 'https://www.auricair.com/en',
    airlineCheckin: 'https://www.auricair.com/en',
    airlineBaggagePolicy: 'https://www.auricair.com/en',
    airlineSeating: 'https://www.auricair.com/en',
  },
  {
    carrierImg: carrierImg3,
    label: 'Aurigny Air Services',
    id: 'Aurigny Air Services',
    carrierContactNo: '',
    airlineLink: 'https://www.aurignynew.com/',
    airlineCheckin: 'https://www.aurigny.com/check-in',
    airlineBaggagePolicy: 'https://www.aurigny.com/check-in',
    airlineSeating: 'https://www.aurigny.com/check-in',
  },
  {
    carrierImg: carrierImg3,
    label: 'Aurora Airline',
    id: 'Aurora Airline',
    carrierContactNo: '',
    airlineLink: 'https://www.flyaurora.ru/en/',
    airlineCheckin: 'https://www.flyaurora.ru/en/',
    airlineBaggagePolicy: 'https://www.flyaurora.ru/en/',
    airlineSeating: 'https://www.flyaurora.ru/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Austrian Airlines',
    id: 'Austrian Airlines',
    carrierContactNo: '1-800-843-0002',
    airlineLink: 'https://www.austrian.com/',
    airlineCheckin: 'https://wci.austrian.com/app/ck.fly?l=en',
    airlineBaggagePolicy:
      'https://www.austrian.com/Info/Flying/Baggage.aspx?sc_lang=en&cc=US',
    airlineSeating: 'https://wci.austrian.com/app/ck.fly?l=en',
  },
  {
    carrierImg: carrierImg3,
    label: 'Avianca Brazil',
    id: 'Avianca Brazil',
    carrierContactNo: '1-844-259-1397',
    airlineLink: 'https://www.avianca.com.br/',
    airlineCheckin: 'https://www.avianca.com.br/en/check-in',
    airlineBaggagePolicy: 'https://www.avianca.com.br/en/check-in',
    airlineSeating: 'https://www.avianca.com.br/en/check-in',
  },
  {
    carrierImg: carrierImg3,
    label: 'Avianca Costa Rica',
    id: 'Avianca Costa Rica',
    carrierContactNo: '1-800-284-2622',
    airlineLink: 'https://www.avianca.com/cr/en/',
    airlineCheckin: 'https://www.avianca.com/cr/en/',
    airlineBaggagePolicy:
      'https://www.avianca.com/co/en/before-your-trip/prepare-your-baggage/',
    airlineSeating: 'https://www.avianca.com/cr/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Avior Airlines',
    id: 'Avior Airlines',
    carrierContactNo: '1-305-470-2203',
    airlineLink: 'https://www.aviorair.com/',
    airlineCheckin: 'https://www.aviorair.com/',
    airlineBaggagePolicy: 'https://www.aviorair.com/',
    airlineSeating: 'https://www.aviorair.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Azerbaijan',
    id: 'Azerbaijan',
    carrierContactNo: '1-844-598-8880',
    airlineLink: 'https://www.azal.az/en/',
    airlineCheckin: 'https://icheck.azal.az/registration/#!/?lang=en',
    airlineBaggagePolicy: 'https://icheck.azal.az/registration/#!/?lang=en',
    airlineSeating: 'https://icheck.azal.az/registration/#!/?lang=en',
  },
  {
    carrierImg: carrierImg3,
    label: 'Azores Airlines',
    id: 'Azores Airlines',
    carrierContactNo: '011-351-296-209-720',
    airlineLink: 'https://www.azoresairlines.pt/en',
    airlineCheckin: 'https://www.azoresairlines.pt/en',
    airlineBaggagePolicy:
      'https://www.azoresairlines.pt/en/information/baggage/checked-baggage',
    airlineSeating: 'https://www.azoresairlines.pt/en',
  },
  {
    carrierImg: carrierImg3,
    label: 'Azul',
    id: 'Azul',
    carrierContactNo: '1-888-587-2985',
    airlineLink: 'https://www.voeazul.com.br/en/home',
    airlineCheckin: 'https://www.voeazul.com.br/en/home',
    airlineBaggagePolicy: 'https://www.voeazul.com.br/en/home',
    airlineSeating: 'https://www.voeazul.com.br/en/home',
  },
  {
    carrierImg: carrierImg3,
    label: 'Bahamasair',
    id: 'Bahamasair',
    carrierContactNo: '1-800-222-4262',
    airlineLink: 'https://www.bahamasair.com/',
    airlineCheckin:
      'https://checkin.bahamasair.com/dx/UPCI/#/check-in/start?locale=en-US',
    airlineBaggagePolicy: 'https://bahamasair.com/optionalfees/',
    airlineSeating:
      'https://checkin.bahamasair.com/dx/UPCI/#/check-in/start?locale=en-US',
  },
  {
    carrierImg: carrierImg3,
    label: 'Bamboo Airways',
    id: 'Bamboo Airways',
    carrierContactNo: '011 84 1900 1166',
    airlineLink: 'https://www.bambooairways.com/en/',
    airlineCheckin: 'https://www.bambooairways.com/en/',
    airlineBaggagePolicy: 'https://www.bambooairways.com/en/',
    airlineSeating: 'https://www.bambooairways.com/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Bangkok Airways',
    id: 'Bangkok Airways',
    carrierContactNo: '1-866-226-4565',
    airlineLink: 'https://www.bangkokair.com/',
    airlineCheckin: 'https://www.bangkokair.com/travel-information',
    airlineBaggagePolicy:
      'https://www.bangkokair.com/travel-information/baggage',
    airlineSeating: 'https://www.bangkokair.com/travel-information',
  },
  {
    carrierImg: carrierImg3,
    label: 'Batik Air',
    id: 'Batik Air',
    carrierContactNo: '',
    airlineLink: 'https://www.batikair.com/en/',
    airlineCheckin: 'https://www.batikair.com/en/',
    airlineBaggagePolicy: 'https://www.batikair.com/en/',
    airlineSeating: 'https://www.batikair.com/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Bearskin Airlines',
    id: 'Bearskin Airlines',
    carrierContactNo: '1-800-465-2323',
    airlineLink: 'https://www.bearskinairlines.com/',
    airlineCheckin: 'https://www.bearskinairlines.com/',
    airlineBaggagePolicy: 'https://www.bearskinairlines.com/baggage-policy',
    airlineSeating: 'https://www.bearskinairlines.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Beijing Capital Airlines',
    id: 'Beijing Capital Airlines',
    carrierContactNo: '',
    airlineLink: 'https://intl.jdair.net/',
    airlineCheckin: 'https://intl.jdair.net/',
    airlineBaggagePolicy: 'https://intl.jdair.net/',
    airlineSeating: 'https://intl.jdair.net/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Belavia',
    id: 'Belavia',
    carrierContactNo: '011-37-517-220-2555',
    airlineLink: 'https://en.belavia.by/',
    airlineCheckin: 'https://belavia.by/#wci',
    airlineBaggagePolicy: 'https://en.belavia.by/baggage/',
    airlineSeating: 'https://belavia.by/#wci',
  },
  {
    carrierImg: carrierImg3,
    label: 'Berjaya Air',
    id: 'Berjaya Air',
    carrierContactNo: '',
    airlineLink: 'https://www.berjaya-air.com/',
    airlineCheckin: 'https://www.berjaya-air.com/',
    airlineBaggagePolicy: 'https://www.berjaya-air.com/',
    airlineSeating: 'https://www.berjaya-air.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Bhutan Airlines',
    id: 'Bhutan Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.bhutanairlines.bt/?p=859',
    airlineCheckin: 'https://b3.prod.sita.aero/retrieve-booking/manage',
    airlineBaggagePolicy: 'https://b3.prod.sita.aero/retrieve-booking/manage',
    airlineSeating: 'https://b3.prod.sita.aero/retrieve-booking/manage',
  },
  {
    carrierImg: carrierImg3,
    label: 'Biman Bangladesh',
    id: 'Biman Bangladesh',
    carrierContactNo: '',
    airlineLink: 'https://www.biman-airlines.com/',
    airlineCheckin: 'https://www.biman-airlines.com/reservations',
    airlineBaggagePolicy: 'https://www.biman-airlines.com/reservations',
    airlineSeating: 'https://www.biman-airlines.com/reservations',
  },
  {
    carrierImg: carrierImg3,
    label: 'Binter Canarias',
    id: 'Binter Canarias',
    carrierContactNo: '011-34-902-391-392',
    airlineLink: 'https://www.bintercanarias.com/eng',
    airlineCheckin:
      'https://www.bintercanarias.com/onlineServices/checkInOnline/',
    airlineBaggagePolicy:
      'https://www.bintercanarias.com/onlineServices/checkInOnline/',
    airlineSeating:
      'https://www.bintercanarias.com/onlineServices/checkInOnline/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Blue Air',
    id: 'Blue Air',
    carrierContactNo: '',
    airlineLink: 'https://www.blueairweb.com/en/gb/',
    airlineCheckin: 'https://www.blueairweb.com/en/gb/checkin-engine/',
    airlineBaggagePolicy: 'https://www.blueairweb.com/en/gb/checkin-engine/',
    airlineSeating: 'https://www.blueairweb.com/en/gb/checkin-engine/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Blue Panorama Airlines',
    id: 'Blue Panorama Airlines',
    carrierContactNo: '011-39-06-9895-6666',
    airlineLink: 'https://www.blue-panorama.com/main',
    airlineCheckin: 'https://www.blue-panorama.com/main',
    airlineBaggagePolicy: 'https://www.blue-panorama.com/luggage',
    airlineSeating: 'https://www.blue-panorama.com/main',
  },
  {
    carrierImg: carrierImg3,
    label: 'Bluebird Aviation',
    id: 'Bluebird Aviation',
    carrierContactNo: '',
    airlineLink: 'https://www.bluebirdair.com/',
    airlineCheckin: 'https://www.bluebirdair.com/',
    airlineBaggagePolicy: 'https://www.bluebirdair.com/',
    airlineSeating: 'https://www.bluebirdair.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'bmi regional',
    id: 'bmi regional',
    carrierContactNo: '',
    airlineLink: 'https://www.flybmi.com/',
    airlineCheckin: 'https://www.flybmi.com/',
    airlineBaggagePolicy: 'https://www.flybmi.com/',
    airlineSeating: 'https://www.flybmi.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Boutique Air',
    id: 'Boutique Air',
    carrierContactNo: '1-855-268-8478',
    airlineLink: 'https://www.boutiqueair.com/',
    airlineCheckin: 'https://www.boutiqueair.com/reservation_authorization/new',
    airlineBaggagePolicy:
      'https://www.boutiqueair.com/reservation_authorization/new',
    airlineSeating: 'https://www.boutiqueair.com/reservation_authorization/new',
  },
  {
    carrierImg: carrierImg3,
    label: 'BoV Boliviana de Aviacion',
    id: 'BoV Boliviana de Aviacion',
    carrierContactNo: '1-800-584-4099',
    airlineLink: 'https://www.boa.bo/BoAWebsite',
    airlineCheckin: 'https://www.boa.bo/BoAWebsite',
    airlineBaggagePolicy: 'https://www.boa.bo/BoAWebsite',
    airlineSeating: 'https://www.boa.bo/BoAWebsite',
  },
  {
    carrierImg: carrierImg3,
    label: 'BQB Líneas Aéreas',
    id: 'BQB Líneas Aéreas',
    carrierContactNo: '(54-11) 4316-6500',
    airlineLink: 'https://www.flybqb.com/',
    airlineCheckin: 'https://www.flybqb.com/',
    airlineBaggagePolicy: 'https://www.flybqb.com/',
    airlineSeating: 'https://www.flybqb.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Braathens Regional',
    id: 'Braathens Regional',
    carrierContactNo: '',
    airlineLink: 'https://www.eraa.org/braathens-regional',
    airlineCheckin: 'https://www.eraa.org/braathens-regional',
    airlineBaggagePolicy: 'https://www.eraa.org/braathens-regional',
    airlineSeating: 'https://www.eraa.org/braathens-regional',
  },
  {
    carrierImg: carrierImg3,
    label: 'Braathens Regional Aviation',
    id: 'Braathens Regional Aviation',
    carrierContactNo: '011-46 771-44 00 10',
    airlineLink: 'https://www.flygbra.se/en/',
    airlineCheckin: 'https://www.flygbra.se/en/check-in/',
    airlineBaggagePolicy: 'https://www.flygbra.se/en/check-in/',
    airlineSeating: 'https://www.flygbra.se/en/check-in/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Branson Air Express',
    id: 'Branson Air Express',
    carrierContactNo: '1-888-359-2541',
    airlineLink: 'https://www.bransonairexpress.com/',
    airlineCheckin: 'https://www.bransonairexpress.com/',
    airlineBaggagePolicy: 'https://www.bransonairexpress.com/',
    airlineSeating: 'https://www.bransonairexpress.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'British Airways',
    id: 'British Airways',
    carrierContactNo: '1-800-247-9297',
    airlineLink: 'https://www.britishairways.com/en-us/home#/',
    airlineCheckin:
      'https://www.britishairways.com/travel/managebooking/public/en_us',
    airlineBaggagePolicy:
      'https://www.britishairways.com/travel/bagchk/public/en_us',
    airlineSeating:
      'https://www.britishairways.com/travel/managebooking/public/en_us',
  },
  {
    carrierImg: carrierImg3,
    label: 'Brussels Airlines',
    id: 'Brussels Airlines',
    carrierContactNo: '1-866-308-2230',
    airlineLink: 'https://www.brusselsairlines.com/en-be/',
    airlineCheckin:
      'https://checkin.brusselsairlines.com/CI/WebForms/PaxByQuery.aspx',
    airlineBaggagePolicy:
      'https://www.brusselsairlines.com/en-be/practical-information/travel-info/before-the-flight/luggage-info/default.aspx',
    airlineSeating:
      'https://checkin.brusselsairlines.com/CI/WebForms/PaxByQuery.aspx',
  },
  {
    carrierImg: carrierImg3,
    label: 'Buddha Air',
    id: 'Buddha Air',
    carrierContactNo: '',
    airlineLink: 'https://www.buddhaair.com/',
    airlineCheckin: 'https://www.buddhaair.com/',
    airlineBaggagePolicy: 'https://www.buddhaair.com/',
    airlineSeating: 'https://www.buddhaair.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Bulgaria Air',
    id: 'Bulgaria Air',
    carrierContactNo: '011-359-2-4020-400',
    airlineLink: 'https://www.air.bg/en/',
    airlineCheckin:
      'https://www.air.bg/en/customer-support/online-check-in-upgrade',
    airlineBaggagePolicy: 'https://www.air.bg/en/customer-support/your-baggage',
    airlineSeating:
      'https://www.air.bg/en/customer-support/online-check-in-upgrade',
  },
  {
    carrierImg: carrierImg3,
    label: 'Caicos Express Airways',
    id: 'Caicos Express Airways',
    carrierContactNo: '1-649-941-5730',
    airlineLink: 'https://www.caicosexpress.com/',
    airlineCheckin: 'https://booknow.caicosexpressairways.com/checkin',
    airlineBaggagePolicy: 'https://booknow.caicosexpressairways.com/checkin',
    airlineSeating: 'https://booknow.caicosexpressairways.com/checkin',
  },
  {
    carrierImg: carrierImg3,
    label: 'Calafia Airlines',
    id: 'Calafia Airlines',
    carrierContactNo: '1-619-354-5138',
    airlineLink: 'https://www.calafiaairlines.com/',
    airlineCheckin: 'https://www.calafiaairlines.com/#/webcheckin',
    airlineBaggagePolicy: 'https://www.calafiaairlines.com/#/webcheckin',
    airlineSeating: 'https://www.calafiaairlines.com/#/webcheckin',
  },
  {
    carrierImg: carrierImg3,
    label: 'Calm Air International',
    id: 'Calm Air International',
    carrierContactNo: '1-800-839-2256',
    airlineLink: 'https://www.calmair.com/',
    airlineCheckin: 'https://www.calmair.com/',
    airlineBaggagePolicy: 'https://www.calmair.com/',
    airlineSeating: 'https://www.calmair.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Camair-Co',
    id: 'Camair-Co',
    carrierContactNo: '',
    airlineLink: 'https://www.camair-co.cm/',
    airlineCheckin: 'https://www.camair-co.cm/',
    airlineBaggagePolicy: 'https://www.camair-co.cm/',
    airlineSeating: 'https://www.camair-co.cm/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Cambodia Airways',
    id: 'Cambodia Airways',
    carrierContactNo: '',
    airlineLink: 'https://www.cambodia-airways.com/en',
    airlineCheckin: 'https://www.cambodia-airways.com/en/check-in-guide',
    airlineBaggagePolicy: 'https://www.cambodia-airways.com/en/check-in-guide',
    airlineSeating: 'https://www.cambodia-airways.com/en/check-in-guide',
  },
  {
    carrierImg: carrierImg3,
    label: 'Cambodia Angkor Air',
    id: 'Cambodia Angkor Air',
    carrierContactNo: '',
    airlineLink: 'https://www.cambodiaangkorair.com/',
    airlineCheckin: 'https://www.cambodiaangkorair.com/',
    airlineBaggagePolicy: 'https://www.cambodiaangkorair.com/',
    airlineSeating: 'https://www.cambodiaangkorair.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Cambodia Bayon Airlines',
    id: 'Cambodia Bayon Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.bayonairlines.com/',
    airlineCheckin: 'https://www.bayonairlines.com/',
    airlineBaggagePolicy: 'https://www.bayonairlines.com/',
    airlineSeating: 'https://www.bayonairlines.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Canadian North Airlines',
    id: 'Canadian North Airlines',
    carrierContactNo: '1-800-661-1505',
    airlineLink: 'https://www.canadiannorth.com/',
    airlineCheckin:
      'https://checkin.canadiannorth.com/SSW2010/5TC0/checkin.html?execution=e1s1',
    airlineBaggagePolicy: 'https://www.canadiannorth.com/info/checked-luggage',
    airlineSeating:
      'https://checkin.canadiannorth.com/SSW2010/5TC0/checkin.html?execution=e1s1',
  },
  {
    carrierImg: carrierImg3,
    label: 'Cape Air',
    id: 'Cape Air',
    carrierContactNo: '1-800-227-3247',
    airlineLink: 'https://www.capeair.com/',
    airlineCheckin: 'https://www.capeair.com/check-in/index.html',
    airlineBaggagePolicy: 'https://www.capeair.com/check-in/index.html',
    airlineSeating: 'https://www.capeair.com/check-in/index.html',
  },
  {
    carrierImg: carrierImg3,
    label: 'Caribbean Airlines',
    id: 'Caribbean Airlines',
    carrierContactNo: '1-800-920-4225',
    airlineLink: 'https://www.caribbean-airlines.com/#/main',
    airlineCheckin:
      'https://www.caribbean-airlines.com/#/plan-your-trip/check-in',
    airlineBaggagePolicy: 'https://www.caribbean-airlines.com/#/baggage',
    airlineSeating:
      'https://www.caribbean-airlines.com/#/plan-your-trip/check-in',
  },
  {
    carrierImg: carrierImg3,
    label: 'Carpatair',
    id: 'Carpatair',
    carrierContactNo: '011-40-256-300-900',
    airlineLink: 'https://www.carpatair.com/',
    airlineCheckin: 'https://www.carpatair.com/',
    airlineBaggagePolicy: 'https://www.carpatair.com/',
    airlineSeating: 'https://www.carpatair.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Cathay Dragon',
    id: 'Cathay Dragon',
    carrierContactNo: '1-800-233-2742',
    airlineLink:
      'https://www.cathaypacific.com/cx/en_US/cathaydragon/introduction.html',
    airlineCheckin: 'https://www.cathaypacific.com/olci/#/en_US/login',
    airlineBaggagePolicy:
      'https://www.cathaypacific.com/cx/en_US/travel-information/baggage.html',
    airlineSeating: 'https://www.cathaypacific.com/olci/#/en_US/login',
  },
  {
    carrierImg: carrierImg3,
    label: 'Cayman Airways',
    id: 'Cayman Airways',
    carrierContactNo: '1-800-422-9626',
    airlineLink: 'https://www.caymanairways.com/',
    airlineCheckin:
      'https://dx.checkin.caymanairways.com/dx/KXCI/#/check-in/start?locale=en-US',
    airlineBaggagePolicy: 'https://www.caymanairways.com/baggagepolicy',
    airlineSeating:
      'https://dx.checkin.caymanairways.com/dx/KXCI/#/check-in/start?locale=en-US',
  },
  {
    carrierImg: carrierImg3,
    label: 'Cebgo',
    id: 'Cebgo',
    carrierContactNo: '1-855-523-2722',
    airlineLink: 'https://www.cebupacificair.com/en-us',
    airlineCheckin: 'https://book.cebupacificair.com/CheckIn/Retrieve',
    airlineBaggagePolicy: 'https://book.cebupacificair.com/CheckIn/Retrieve',
    airlineSeating: 'https://book.cebupacificair.com/CheckIn/Retrieve',
  },
  {
    carrierImg: carrierImg3,
    label: 'Cebu Pacific',
    id: 'Cebu Pacific',
    carrierContactNo: '1-855-523-2722',
    airlineLink: 'https://www.cebupacificair.com/en-us',
    airlineCheckin: 'https://book.cebupacificair.com/CheckIn/Retrieve',
    airlineBaggagePolicy: 'https://book.cebupacificair.com/CheckIn/Retrieve',
    airlineSeating: 'https://book.cebupacificair.com/CheckIn/Retrieve',
  },
  {
    carrierImg: carrierImg3,
    label: 'CEIBA Intercontinental',
    id: 'CEIBA Intercontinental',
    carrierContactNo: '',
    airlineLink: 'https://ceiba.irc-conseil.com/',
    airlineCheckin: 'https://ceiba.irc-conseil.com/',
    airlineBaggagePolicy: 'https://ceiba.irc-conseil.com/',
    airlineSeating: 'https://ceiba.irc-conseil.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'CemAir',
    id: 'CemAir',
    carrierContactNo: '',
    airlineLink: 'https://www.flycemair.co.za/',
    airlineCheckin: 'https://www.flycemair.co.za/',
    airlineBaggagePolicy: 'https://www.flycemair.co.za/',
    airlineSeating: 'https://www.flycemair.co.za/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Central Mountain Air',
    id: 'Central Mountain Air',
    carrierContactNo: '1-888-865-8585',
    airlineLink: 'https://flycma.com/',
    airlineCheckin: 'https://flycmaweb.intelisys.ca/SearchResCheckin.aspx',
    airlineBaggagePolicy:
      'https://flycmaweb.intelisys.ca/SearchResCheckin.aspx',
    airlineSeating: 'https://flycmaweb.intelisys.ca/SearchResCheckin.aspx',
  },
  {
    carrierImg: carrierImg3,
    label: 'Chalair',
    id: 'Chalair',
    carrierContactNo: '',
    airlineLink: 'https://www.chalair.eu/index.php/en/',
    airlineCheckin: 'https://www.chalair.eu/index.php/en/',
    airlineBaggagePolicy: 'https://www.chalair.eu/index.php/en/',
    airlineSeating: 'https://www.chalair.eu/index.php/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Cham Wings Airlines',
    id: 'Cham Wings Airlines',
    carrierContactNo: '',
    airlineLink: 'https://chamwings.com/',
    airlineCheckin: 'https://chamwings.com/',
    airlineBaggagePolicy: 'https://chamwings.com/',
    airlineSeating: 'https://chamwings.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Charlie Airlines DBA Cyprus Airways',
    id: 'Charlie Airlines DBA Cyprus Airways',
    carrierContactNo: '',
    airlineLink: 'https://www.cyprusairways.com/',
    airlineCheckin: 'https://webcheckin.cyprusairways.com/login.action',
    airlineBaggagePolicy: 'https://webcheckin.cyprusairways.com/login.action',
    airlineSeating: 'https://webcheckin.cyprusairways.com/login.action',
  },
  {
    carrierImg: carrierImg3,
    label: 'CHC Helikopter Service AS',
    id: 'CHC Helikopter Service AS',
    carrierContactNo: '',
    airlineLink: 'https://www.atlasatlantiqueairlines.com/',
    airlineCheckin: 'https://www.atlasatlantiqueairlines.com/',
    airlineBaggagePolicy: 'https://www.atlasatlantiqueairlines.com/',
    airlineSeating: 'https://www.atlasatlantiqueairlines.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Chengdu Airlines',
    id: 'Chengdu Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.cdal.com.cn/',
    airlineCheckin: 'https://www.cdal.com.cn/',
    airlineBaggagePolicy: 'https://www.cdal.com.cn/',
    airlineSeating: 'https://www.cdal.com.cn/',
  },
  {
    carrierImg: carrierImg3,
    label: 'China Airlines',
    id: 'China Airlines',
    carrierContactNo: '1-800-227-5118',
    airlineLink: 'https://www.china-airlines.com/us/en',
    airlineCheckin:
      'https://calec.china-airlines.com/echeckin_tn/eCheckin_home.aspx?country=uk&locale=en-',
    airlineBaggagePolicy:
      'https://www.china-airlines.com/us/en/fly/prepare-for-the-fly/baggage/baggage-rules',
    airlineSeating:
      'https://calec.china-airlines.com/echeckin_tn/eCheckin_home.aspx?country=uk&locale=en-',
  },
  {
    carrierImg: carrierImg3,
    label: 'China Eastern Airlines',
    id: 'China Eastern Airlines',
    carrierContactNo: '1-626-583-1500',
    airlineLink: 'https://us.ceair.com/en/',
    airlineCheckin: 'https://us.ceair.com/en/',
    airlineBaggagePolicy:
      'https://us.ceair.com/newCMS/us/en/content/en_Header/headerBottom/service/baggage/baggageDetail/',
    airlineSeating: 'https://us.ceair.com/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'China Express Airlines',
    id: 'China Express Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.chinaexpressair.com/',
    airlineCheckin: 'https://www.chinaexpressair.com/',
    airlineBaggagePolicy: 'https://www.chinaexpressair.com/',
    airlineSeating: 'https://www.chinaexpressair.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'China Southern Airlines',
    id: 'China Southern Airlines',
    carrierContactNo: '1-888-338-8988',
    airlineLink: 'https://global.csair.com/',
    airlineCheckin: 'https://global.csair.com/US/GB/online-check-in',
    airlineBaggagePolicy: 'https://global.csair.com/US/GB/JTXX/INFO/XLFW/CKBA',
    airlineSeating: 'https://global.csair.com/US/GB/online-check-in',
  },
  {
    carrierImg: carrierImg3,
    label: 'China United Airlines',
    id: 'China United Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.flycua.com/',
    airlineCheckin: 'https://www.flycua.com/',
    airlineBaggagePolicy: 'https://www.flycua.com/',
    airlineSeating: 'https://www.flycua.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'China West Air',
    id: 'China West Air',
    carrierContactNo: '',
    airlineLink: 'https://www.westair.cn/portal/',
    airlineCheckin: 'https://www.westair.cn/portal/',
    airlineBaggagePolicy: 'https://www.westair.cn/portal/',
    airlineSeating: 'https://www.westair.cn/portal/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Chongqing Airlines',
    id: 'Chongqing Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.chongqingairlines.cn/',
    airlineCheckin: 'https://www.chongqingairlines.cn/',
    airlineBaggagePolicy: 'https://www.chongqingairlines.cn/',
    airlineSeating: 'https://www.chongqingairlines.cn/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Chu Kong Passenger Transport',
    id: 'Chu Kong Passenger Transport',
    carrierContactNo: '',
    airlineLink: 'https://www.cksp.com.hk/en',
    airlineCheckin: 'https://www.cksp.com.hk/en',
    airlineBaggagePolicy: 'https://www.cksp.com.hk/en',
    airlineSeating: 'https://www.cksp.com.hk/en',
  },
  {
    carrierImg: carrierImg3,
    label: 'Cimber Sterling',
    id: 'Cimber Sterling',
    carrierContactNo: '011-457-010-1218',
    airlineLink: 'https://www.cimber.com/',
    airlineCheckin: 'https://www.cimber.com/',
    airlineBaggagePolicy: 'https://www.cimber.com/',
    airlineSeating: 'https://www.cimber.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Citilink',
    id: 'Citilink',
    carrierContactNo: '+62 21 659 9049',
    airlineLink: 'https://www.citilink.co.id/en',
    airlineCheckin: 'https://www.citilink.co.id/en',
    airlineBaggagePolicy: 'https://www.citilink.co.id/en',
    airlineSeating: 'https://www.citilink.co.id/en',
  },
  {
    carrierImg: carrierImg3,
    label: 'City Jet',
    id: 'City Jet',
    carrierContactNo: '',
    airlineLink: 'https://www.cityjet.com/',
    airlineCheckin: 'https://www.cityjet.com/',
    airlineBaggagePolicy: 'https://www.cityjet.com/',
    airlineSeating: 'https://www.cityjet.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'CM Airlines',
    id: 'CM Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.cmairlines.com/',
    airlineCheckin: 'https://www.cmairlines.com/',
    airlineBaggagePolicy: 'https://www.cmairlines.com/',
    airlineSeating: 'https://www.cmairlines.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Comair Limited',
    id: 'Comair Limited',
    carrierContactNo: '',
    airlineLink: 'https://www.kulula.com/',
    airlineCheckin: 'https://www.kulula.com/',
    airlineBaggagePolicy: 'https://www.kulula.com/',
    airlineSeating: 'https://www.kulula.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Condor Flugdienst',
    id: 'Condor Flugdienst',
    carrierContactNo: '1-866-960-7915',
    airlineLink: 'https://www.condor.com/us',
    airlineCheckin: 'https://www.condor.com/tcibe/us/mybooking/login',
    airlineBaggagePolicy:
      'https://www.condor.com/us/flight-preparation/baggage-and-animals/baggage.jsp',
    airlineSeating: 'https://www.condor.com/tcibe/us/mybooking/login',
  },
  {
    carrierImg: carrierImg3,
    label: 'Contour Airlines (Corporate Flight Management)',
    id: 'Contour Airlines (Corporate Flight Management)',
    carrierContactNo: '1-888-332-6686',
    airlineLink: 'https://contourairlines.com/en',
    airlineCheckin: 'https://checkin.contourairlines.com/',
    airlineBaggagePolicy: 'https://checkin.contourairlines.com/',
    airlineSeating: 'https://checkin.contourairlines.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Conviasa',
    id: 'Conviasa',
    carrierContactNo: '',
    airlineLink: 'https://www.conviasa.aero/en/',
    airlineCheckin: 'https://www.conviasa.aero/en/',
    airlineBaggagePolicy: 'https://www.conviasa.aero/en/',
    airlineSeating: 'https://www.conviasa.aero/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Corendon Airlines',
    id: 'Corendon Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.corendonairlines.com/en',
    airlineCheckin: 'https://www.corendonairlines.com/en',
    airlineBaggagePolicy: 'https://www.corendonairlines.com/en',
    airlineSeating: 'https://www.corendonairlines.com/en',
  },
  {
    carrierImg: carrierImg3,
    label: 'Corendon Dutch Airlines',
    id: 'Corendon Dutch Airlines',
    carrierContactNo: '',
    airlineLink: 'https://fly.corendon.com/',
    airlineCheckin: 'https://fly.corendon.com/',
    airlineBaggagePolicy: 'https://fly.corendon.com/',
    airlineSeating: 'https://fly.corendon.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Corsair',
    id: 'Corsair',
    carrierContactNo: '1-877-554-3389',
    airlineLink: 'https://www.corsair.ca/',
    airlineCheckin:
      'https://checkin.si.amadeus.net/static/PRD/SS/#/checkin?ln=en&appRedirected=true',
    airlineBaggagePolicy:
      'https://www.corsair.ca/vol/services/Services-Corsair/Bagages/Bagage-en-soute',
    airlineSeating:
      'https://checkin.si.amadeus.net/static/PRD/SS/#/checkin?ln=en&appRedirected=true',
  },
  {
    carrierImg: carrierImg3,
    label: 'Croatia Airlines',
    id: 'Croatia Airlines',
    carrierContactNo: '011 385-16676555',
    airlineLink: 'https://www.croatiaairlines.com/',
    airlineCheckin: 'https://wci.croatiaairlines.hr/ck.fly#ck_retrieve',
    airlineBaggagePolicy:
      'https://www.croatiaairlines.com/Travel-info/Baggage/Checked-baggage',
    airlineSeating: 'https://wci.croatiaairlines.hr/ck.fly#ck_retrieve',
  },
  {
    carrierImg: carrierImg3,
    label: 'Cronos Airlines',
    id: 'Cronos Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.cronosair.com/',
    airlineCheckin: 'https://www.cronosair.com/',
    airlineBaggagePolicy: 'https://www.cronosair.com/',
    airlineSeating: 'https://www.cronosair.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'CSA Czech Airlines',
    id: 'CSA Czech Airlines',
    carrierContactNo: '1-855-359-2932',
    airlineLink: 'https://www.csa.cz/cz-en/',
    airlineCheckin: 'https://www.csa.cz/cz-en/check-in/',
    airlineBaggagePolicy:
      'https://www.csa.cz/en/portal/info-and-services/travel-information/baggage2.htm',
    airlineSeating: 'https://www.csa.cz/cz-en/check-in/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Daallo',
    id: 'Daallo',
    carrierContactNo: '',
    airlineLink: 'https://www.daallo.com/',
    airlineCheckin: 'https://www.daallo.com/',
    airlineBaggagePolicy: 'https://www.daallo.com/',
    airlineSeating: 'https://www.daallo.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Dana Airlines',
    id: 'Dana Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.flydanaair.com/',
    airlineCheckin: 'https://www.flydanaair.com/',
    airlineBaggagePolicy: 'https://www.flydanaair.com/',
    airlineSeating: 'https://www.flydanaair.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Danish Air Transport',
    id: 'Danish Air Transport',
    carrierContactNo: '',
    airlineLink: 'https://dat.dk/',
    airlineCheckin: 'https://dat.dk/',
    airlineBaggagePolicy: 'https://dat.dk/',
    airlineSeating: 'https://dat.dk/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Denver Air Connection',
    id: 'Denver Air Connection',
    carrierContactNo: '1-866-373-8513',
    airlineLink: 'https://www.denverairconnection.com/',
    airlineCheckin: 'https://www.denverairconnection.com/',
    airlineBaggagePolicy: 'https://www.denverairconnection.com/',
    airlineSeating: 'https://www.denverairconnection.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Dniproavia Aviation',
    id: 'Dniproavia Aviation',
    carrierContactNo: '',
    airlineLink: 'https://www.dniproavia.com/en',
    airlineCheckin: 'https://www.dniproavia.com/en',
    airlineBaggagePolicy: 'https://www.dniproavia.com/en',
    airlineSeating: 'https://www.dniproavia.com/en',
  },
  {
    carrierImg: carrierImg3,
    label: 'Donghai Airlines',
    id: 'Donghai Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.donghaiair.com/',
    airlineCheckin: 'https://www.donghaiair.com/',
    airlineBaggagePolicy: 'https://www.donghaiair.com/',
    airlineSeating: 'https://www.donghaiair.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Dynamic Airways',
    id: 'Dynamic Airways',
    carrierContactNo: '1-844-867-1916',
    airlineLink: 'https://airdynamic.com/',
    airlineCheckin: 'https://airdynamic.com/',
    airlineBaggagePolicy: 'https://airdynamic.com/',
    airlineSeating: 'https://airdynamic.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Eastar Jet',
    id: 'Eastar Jet',
    carrierContactNo: '',
    airlineLink: 'https://www.eastarjet.com/newstar/',
    airlineCheckin: 'https://www.eastarjet.com/newstar/',
    airlineBaggagePolicy: 'https://www.eastarjet.com/newstar/',
    airlineSeating: 'https://www.eastarjet.com/newstar/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Eastern Airways',
    id: 'Eastern Airways',
    carrierContactNo: '',
    airlineLink: 'https://www.easternairways.com/',
    airlineCheckin: 'https://www.easternairways.com/',
    airlineBaggagePolicy: 'https://www.easternairways.com/',
    airlineSeating: 'https://www.easternairways.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'EasyJet',
    id: 'EasyJet',
    carrierContactNo: '',
    airlineLink: 'https://www.easyjet.com/en',
    airlineCheckin: 'https://www.easyjet.com/en',
    airlineBaggagePolicy: 'https://www.easyjet.com/en',
    airlineSeating: 'https://www.easyjet.com/en',
  },
  {
    carrierImg: carrierImg3,
    label: 'Edelwiess Air',
    id: 'Edelwiess Air',
    carrierContactNo: '',
    airlineLink: 'https://www.flyedelweiss.com/DE/Pages/home.aspx',
    airlineCheckin:
      'https://www.flyedelweiss.com/DE/fly/at-the-airport/check-in/Pages/online-check-in.aspx',
    airlineBaggagePolicy:
      'https://www.flyedelweiss.com/DE/fly/at-the-airport/check-in/Pages/online-check-in.aspx',
    airlineSeating:
      'https://www.flyedelweiss.com/DE/fly/at-the-airport/check-in/Pages/online-check-in.aspx',
  },
  {
    carrierImg: carrierImg3,
    label: 'Egyptair',
    id: 'Egyptair',
    carrierContactNo: '1-800-334-6787',
    airlineLink: 'https://agents.egyptair.com/Pages/default.aspx',
    airlineCheckin:
      'https://www.egyptair.com/en/fly/check-in/Pages/web-check-in.aspx',
    airlineBaggagePolicy:
      'https://www.egyptair.com/en/fly/baggage/Pages/default.aspx',
    airlineSeating:
      'https://www.egyptair.com/en/fly/check-in/Pages/web-check-in.aspx',
  },
  {
    carrierImg: carrierImg3,
    label: 'El Al',
    id: 'El Al',
    carrierContactNo: '1-877-995-0677 / 1-800-223-6700',
    airlineLink: 'https://www.elal.com/en/USA/Pages/default.aspx',
    airlineCheckin: 'https://www.elal.com/en/USA/Pages/default.aspx',
    airlineBaggagePolicy:
      'https://www.elal.co.il/ELAL/English/AllAboutYourFlight/BeforeYourFlight/LuggageAndBaggage/',
    airlineSeating: 'https://www.elal.com/en/USA/Pages/default.aspx',
  },
  {
    carrierImg: carrierImg3,
    label: 'Ellinair',
    id: 'Ellinair',
    carrierContactNo: '',
    airlineLink: 'https://en.ellinair.com/article/check_in',
    airlineCheckin: 'https://en.ellinair.com/article/check_in',
    airlineBaggagePolicy: 'https://en.ellinair.com/article/check_in',
    airlineSeating: 'https://en.ellinair.com/article/check_in',
  },
  {
    carrierImg: carrierImg3,
    label: 'Emirates Airlines',
    id: 'Emirates Airlines',
    carrierContactNo: '1-800-777-3999',
    airlineLink: 'https://www.emirates.com/au/english/',
    airlineCheckin:
      'https://www.emirates.com/us/english/manage-booking/online-check-in.aspx',
    airlineBaggagePolicy:
      'https://www.emirates.com/us/english/before-you-fly/baggage/',
    airlineSeating:
      'https://www.emirates.com/us/english/manage-booking/online-check-in.aspx',
  },
  {
    carrierImg: carrierImg3,
    label: 'Endeavor Air',
    id: 'Endeavor Air',
    carrierContactNo: '1-800-221-1212',
    airlineLink:
      'https://www.endeavorair.com/content/endeavor-air/en_us/home.html',
    airlineCheckin:
      'https://www.endeavorair.com/content/endeavor-air/en_us/home.html',
    airlineBaggagePolicy:
      'https://www.endeavorair.com/content/endeavor-air/en_us/home.html',
    airlineSeating:
      'https://www.endeavorair.com/content/endeavor-air/en_us/home.html',
  },
  {
    carrierImg: carrierImg3,
    label: 'Equaflight Service',
    id: 'Equaflight Service',
    carrierContactNo: '',
    airlineLink: 'https://www.equaflight.com/en/?lang=en',
    airlineCheckin: 'https://www.equaflight.com/en/?lang=en',
    airlineBaggagePolicy: 'https://www.equaflight.com/en/?lang=en',
    airlineSeating: 'https://www.equaflight.com/en/?lang=en',
  },
  {
    carrierImg: carrierImg3,
    label: 'Ernest Airlines',
    id: 'Ernest Airlines',
    carrierContactNo: '',
    airlineLink: 'https://manage.flyernest.com/?lang=en',
    airlineCheckin: 'https://manage.flyernest.com/search?lang=en',
    airlineBaggagePolicy: 'https://manage.flyernest.com/search?lang=en',
    airlineSeating: 'https://manage.flyernest.com/search?lang=en',
  },
  {
    carrierImg: carrierImg3,
    label: 'Ethiopian',
    id: 'Ethiopian',
    carrierContactNo: '1-800-445-2733',
    airlineLink: 'https://www.ethiopianairlines.com/AA/EN',
    airlineCheckin:
      'https://checkin.ethiopianairlines.com/SSW2010/ETM1/checkin/#checkin/e1s1?lang=en_GB',
    airlineBaggagePolicy:
      'https://www.ethiopianairlines.com/AA/EN/information/baggage',
    airlineSeating:
      'https://checkin.ethiopianairlines.com/SSW2010/ETM1/checkin/#checkin/e1s1?lang=en_GB',
  },
  {
    carrierImg: carrierImg3,
    label: 'Etihad Airways',
    id: 'Etihad Airways',
    carrierContactNo: '1-877-690-0767',
    airlineLink: 'https://www.etihad.com/en/',
    airlineCheckin: 'https://www.etihad.com/en/before-you-fly/check-in-online/',
    airlineBaggagePolicy:
      'https://www.etihad.com/en/before-you-fly/baggage-information/',
    airlineSeating: 'https://www.etihad.com/en/before-you-fly/check-in-online/',
  },
  {
    carrierImg: carrierImg3,
    label: 'EuroAtlantic Airways',
    id: 'EuroAtlantic Airways',
    carrierContactNo: '',
    airlineLink: 'https://www.euroatlantic.pt/',
    airlineCheckin: 'https://www.euroatlantic.pt/',
    airlineBaggagePolicy: 'https://www.euroatlantic.pt/',
    airlineSeating: 'https://www.euroatlantic.pt/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Eurolot',
    id: 'Eurolot',
    carrierContactNo: '011-48-22-275-87-40',
    airlineLink: 'https://www.eurolot.com/',
    airlineCheckin: 'https://www.eurolot.com/',
    airlineBaggagePolicy: 'https://www.eurolot.com/',
    airlineSeating: 'https://www.eurolot.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Eurostar',
    id: 'Eurostar',
    carrierContactNo: '',
    airlineLink: 'https://www.eurostar.com/rw-en',
    airlineCheckin: 'https://www.eurostar.com/rw-en',
    airlineBaggagePolicy: 'https://www.eurostar.com/rw-en',
    airlineSeating: 'https://www.eurostar.com/rw-en',
  },
  {
    carrierImg: carrierImg3,
    label: 'Eurowings',
    id: 'Eurowings',
    carrierContactNo: '',
    airlineLink: 'https://www.eurowings.com/us.html',
    airlineCheckin: 'https://www.eurowings.com/us.html',
    airlineBaggagePolicy: 'https://www.eurowings.com/us.html',
    airlineSeating: 'https://www.eurowings.com/us.html',
  },
  {
    carrierImg: carrierImg3,
    label: 'EVA Airways',
    id: 'EVA Airways',
    carrierContactNo: '1-800-695-1188',
    airlineLink: 'https://www.evaair.com/en-global/index.html',
    airlineCheckin: 'https://www.evaair.com/en-global/index.html',
    airlineBaggagePolicy:
      'https://www.evaair.com/en-global/managing-your-trip/baggage-information/general-information/',
    airlineSeating: 'https://www.evaair.com/en-global/index.html',
  },
  {
    carrierImg: carrierImg3,
    label: 'Evelop Airlines',
    id: 'Evelop Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.en.evelop.com/cheap-flights',
    airlineCheckin: 'https://www.en.evelop.com/cheap-flights',
    airlineBaggagePolicy: 'https://www.en.evelop.com/cheap-flights',
    airlineSeating: 'https://www.en.evelop.com/cheap-flights',
  },
  {
    carrierImg: carrierImg3,
    label: 'EWA Air',
    id: 'EWA Air',
    carrierContactNo: '',
    airlineLink: 'https://www.ewa-air.com/en/',
    airlineCheckin: 'https://www.ewa-air.com/en/info-and-service/check-in.html',
    airlineBaggagePolicy:
      'https://www.ewa-air.com/en/info-and-service/check-in.html',
    airlineSeating: 'https://www.ewa-air.com/en/info-and-service/check-in.html',
  },
  {
    carrierImg: carrierImg3,
    label: 'ExecAir',
    id: 'ExecAir',
    carrierContactNo: '1-866-669-2359',
    airlineLink: 'https://execairline.com/',
    airlineCheckin: 'https://execairline.com/',
    airlineBaggagePolicy: 'https://execairline.com/',
    airlineSeating: 'https://execairline.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'ExpressJet Airlines',
    id: 'ExpressJet Airlines',
    carrierContactNo: '1-800-864-8331',
    airlineLink: 'https://www.expressjet.com/',
    airlineCheckin: 'https://www.united.com/en/us',
    airlineBaggagePolicy: 'https://www.united.com/en/us',
    airlineSeating: 'https://www.united.com/en/us',
  },
  {
    carrierImg: carrierImg3,
    label: 'Far Eastern Air Transport',
    id: 'Far Eastern Air Transport',
    carrierContactNo: '',
    airlineLink: 'https://www.fat.com.tw/web/en/home',
    airlineCheckin: 'https://www.fat.com.tw/web/en/home',
    airlineBaggagePolicy: 'https://www.fat.com.tw/web/en/home',
    airlineSeating: 'https://www.fat.com.tw/web/en/home',
  },
  {
    carrierImg: carrierImg3,
    label: 'Fastjet',
    id: 'Fastjet',
    carrierContactNo: '',
    airlineLink: 'https://www.fastjet.com/en',
    airlineCheckin:
      'https://www.fastjet.com/en/information-central/at-the-airport/checking-in',
    airlineBaggagePolicy: 'https://www.fastjet.com/en',
    airlineSeating:
      'https://www.fastjet.com/en/information-central/at-the-airport/checking-in',
  },
  {
    carrierImg: carrierImg3,
    label: 'Fiji Airways',
    id: 'Fiji Airways',
    carrierContactNo: '1-800-227-4446',
    airlineLink: 'https://www.fijiairways.com/en-us/',
    airlineCheckin:
      'https://checkin.si.amadeus.net/static/PRD/FJ/#/identification',
    airlineBaggagePolicy:
      'https://www.fijiairways.com/flight-information/baggage-allowances/',
    airlineSeating:
      'https://checkin.si.amadeus.net/static/PRD/FJ/#/identification',
  },
  {
    carrierImg: carrierImg3,
    label: 'Finnair',
    id: 'Finnair',
    carrierContactNo: '1-877-757-7143',
    airlineLink: 'https://www.finnair.com/int/gb/',
    airlineCheckin: 'https://www.finnair.com/us-en/check-in',
    airlineBaggagePolicy:
      'https://www.finnair.com/en/baggage-on-finnair-flights',
    airlineSeating: 'https://www.finnair.com/us-en/check-in',
  },
  {
    carrierImg: carrierImg3,
    label: 'Firefly',
    id: 'Firefly',
    carrierContactNo: '',
    airlineLink: 'https://www.fireflyz.com.my/',
    airlineCheckin: 'https://booking.fireflyz.com.my/WebCheckIn.aspx',
    airlineBaggagePolicy: 'https://booking.fireflyz.com.my',
    airlineSeating: 'https://booking.fireflyz.com.my/WebCheckIn.aspx',
  },
  {
    carrierImg: carrierImg3,
    label: 'Flair Air',
    id: 'Flair Air',
    carrierContactNo: '1-204-285-0844',
    airlineLink: 'https://flairair.ca/',
    airlineCheckin: 'https://book.flairair.ca/SearchResCheckin.aspx',
    airlineBaggagePolicy: 'https://book.flairair.ca/',
    airlineSeating: 'https://book.flairair.ca/SearchResCheckin.aspx',
  },
  {
    carrierImg: carrierImg3,
    label: 'Flexflight',
    id: 'Flexflight',
    carrierContactNo: '011-45-39-56-39-55',
    airlineLink: 'https://flexflight.dk/',
    airlineCheckin: 'https://flexflight.dk/online-check-in/',
    airlineBaggagePolicy: 'https://flexflight.dk/',
    airlineSeating: 'https://flexflight.dk/online-check-in/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Fly Corporate',
    id: 'Fly Corporate',
    carrierContactNo: '',
    airlineLink: 'https://flycorporate.com.au/',
    airlineCheckin: 'https://flycorporate.com.au/manage-flights/web-check-in/',
    airlineBaggagePolicy: 'https://flycorporate.com.au/',
    airlineSeating: 'https://flycorporate.com.au/manage-flights/web-check-in/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Fly540',
    id: 'Fly540',
    carrierContactNo: '',
    airlineLink: 'https://www.fly540.com/',
    airlineCheckin:
      'https://booking.fly540.com/vars/public/CustomerPanels/MMBLogin.aspx',
    airlineBaggagePolicy: 'https://www.fly540.com/',
    airlineSeating:
      'https://booking.fly540.com/vars/public/CustomerPanels/MMBLogin.aspx',
  },
  {
    carrierImg: carrierImg3,
    label: 'Flyadeal',
    id: 'Flyadeal',
    carrierContactNo: '',
    airlineLink: 'https://www.flyadeal.com/en/',
    airlineCheckin: 'https://book.flyadeal.com/#/checkin/search',
    airlineBaggagePolicy: 'https://www.flyadeal.com/en/',
    airlineSeating: 'https://book.flyadeal.com/#/checkin/search',
  },
  {
    carrierImg: carrierImg3,
    label: 'Flybe',
    id: 'Flybe',
    carrierContactNo: '011-44-13-922-685-000',
    airlineLink: 'https://www.flybe.com/',
    airlineCheckin: 'https://www.flybe.com/check-in',
    airlineBaggagePolicy: 'https://www.flybe.com/baggage',
    airlineSeating: 'https://www.flybe.com/check-in',
  },
  {
    carrierImg: carrierImg3,
    label: 'Flydubai',
    id: 'Flydubai',
    carrierContactNo: '011-971-4231-1000',
    airlineLink: 'https://www.flydubai.com/en/',
    airlineCheckin: 'https://www.flydubai.com/en/flying-with-us/check-in/',
    airlineBaggagePolicy: 'https://www.flydubai.com/en/',
    airlineSeating: 'https://www.flydubai.com/en/flying-with-us/check-in/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Flyme (Villa Air)',
    id: 'Flyme (Villa Air)',
    carrierContactNo: '',
    airlineLink: 'https://www.flyme.mv/',
    airlineCheckin: 'https://www.flyme.mv/',
    airlineBaggagePolicy: 'https://www.flyme.mv/',
    airlineSeating: 'https://www.flyme.mv/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Flynas',
    id: 'Flynas',
    carrierContactNo: '',
    airlineLink: 'https://www.flynas.com/en',
    airlineCheckin: 'https://www.flynas.com/en',
    airlineBaggagePolicy: 'https://www.flynas.com/en',
    airlineSeating: 'https://www.flynas.com/en',
  },
  {
    carrierImg: carrierImg3,
    label: 'FlyOne',
    id: 'FlyOne',
    carrierContactNo: '',
    airlineLink: 'https://flyone.md/en/',
    airlineCheckin:
      'https://booking.flyone.md/websky/?lang=en&currency=EUR#/search-order',
    airlineBaggagePolicy: 'https://flyone.md/en/',
    airlineSeating:
      'https://booking.flyone.md/websky/?lang=en&currency=EUR#/search-order',
  },
  {
    carrierImg: carrierImg3,
    label: 'French Bee',
    id: 'French Bee',
    carrierContactNo: '1-833-376-7158',
    airlineLink: 'https://us.frenchbee.com/en',
    airlineCheckin: 'https://us.frenchbee.com/en/check-in-online',
    airlineBaggagePolicy: 'https://us.frenchbee.com/en',
    airlineSeating: 'https://us.frenchbee.com/en/check-in-online',
  },
  {
    carrierImg: carrierImg3,
    label: 'Fuji Dream Airlines',
    id: 'Fuji Dream Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.fujidream.co.jp/en/',
    airlineCheckin: 'https://www.fujidream.co.jp/en/',
    airlineBaggagePolicy: 'https://www.fujidream.co.jp/en/',
    airlineSeating: 'https://www.fujidream.co.jp/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Fuzhou Airlines',
    id: 'Fuzhou Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.fuzhou-air.cn/',
    airlineCheckin: 'https://www.fuzhou-air.cn/frontend/checkin/checkIndex.jsp',
    airlineBaggagePolicy: 'https://www.fuzhou-air.cn/',
    airlineSeating: 'https://www.fuzhou-air.cn/frontend/checkin/checkIndex.jsp',
  },
  {
    carrierImg: carrierImg3,
    label: 'Gambia Bird',
    id: 'Gambia Bird',
    carrierContactNo: '011-220-439-27-33',
    airlineLink: 'https://www.gambiabird.com/',
    airlineCheckin: 'https://www.gambiabird.com/',
    airlineBaggagePolicy: 'https://www.gambiabird.com/',
    airlineSeating: 'https://www.gambiabird.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Garuda Airlines',
    id: 'Garuda Airlines',
    carrierContactNo: '1-800-342-7832',
    airlineLink:
      'https://www.garuda-indonesia.com/other-countries/en/index.page',
    airlineCheckin:
      'https://checkin.si.amadeus.net/1ASIHSSCWEBGA/sscwga/checkin?ln=en',
    airlineBaggagePolicy:
      'https://checkin.si.amadeus.net/1ASIHSSCWEBGA/sscwga/checkin?ln=en',
    airlineSeating:
      'https://checkin.si.amadeus.net/1ASIHSSCWEBGA/sscwga/checkin?ln=en',
  },
  {
    carrierImg: carrierImg3,
    label: 'Georgian Airways',
    id: 'Georgian Airways',
    carrierContactNo: '011-995-322-999-130',
    airlineLink: 'https://www.georgian-airways.com/',
    airlineCheckin:
      'https://booking.georgian-airways.com/websky/#/search-order',
    airlineBaggagePolicy: 'https://booking.georgian-airways.com/',
    airlineSeating:
      'https://booking.georgian-airways.com/websky/#/search-order',
  },
  {
    carrierImg: carrierImg3,
    label: 'Germania',
    id: 'Germania',
    carrierContactNo: '',
    airlineLink: 'https://www.flygermania.com/en/',
    airlineCheckin: 'https://www.flygermania.com/en/',
    airlineBaggagePolicy: 'https://www.flygermania.com/en/',
    airlineSeating: 'https://www.flygermania.com/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Germania Flug AG',
    id: 'Germania Flug AG',
    carrierContactNo: '',
    airlineLink: 'https://www.chair.ch/',
    airlineCheckin: 'https://www.chair.ch/',
    airlineBaggagePolicy: 'https://www.chair.ch/',
    airlineSeating: 'https://www.chair.ch/',
  },
  {
    carrierImg: carrierImg3,
    label: 'germanwings',
    id: 'germanwings',
    carrierContactNo: '1-800-864-8331',
    airlineLink: 'https://www.eurowings.com/us.html',
    airlineCheckin:
      'https://www.eurowings.com/skysales/CheckInInfo.aspx?culture=en-GB',
    airlineBaggagePolicy: 'hhttps://www.eurowings.com/us.html',
    airlineSeating:
      'https://www.eurowings.com/skysales/CheckInInfo.aspx?culture=en-GB',
  },
  {
    carrierImg: carrierImg3,
    label: 'GoAir',
    id: 'GoAir',
    carrierContactNo: '',
    airlineLink: 'https://www.goair.in/',
    airlineCheckin: 'https://www.goair.in/',
    airlineBaggagePolicy: 'hhttps://www.goair.in/',
    airlineSeating: 'https://www.goair.in/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Gol Varig/VRG Linhas Aereas S.A',
    id: 'Gol Varig/VRG Linhas Aereas S.A',
    carrierContactNo: '1-855-862-9190',
    airlineLink: 'https://www.voegol.com.br/en',
    airlineCheckin: 'https://checkininternet.voegol.com.br/home?culture=en-us',
    airlineBaggagePolicy:
      'https://www.voegol.com.br/en/information/travel-worry-free/checked-and-carry-on-baggage',
    airlineSeating: 'https://checkininternet.voegol.com.br/home?culture=en-us',
  },
  {
    carrierImg: carrierImg3,
    label: 'Golden Myanmar Airlines',
    id: 'Golden Myanmar Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.gmairlines.com/',
    airlineCheckin: 'https://www.gmairlines.com/',
    airlineBaggagePolicy: 'https://www.gmairlines.com/',
    airlineSeating: 'https://www.gmairlines.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Grant Aviation',
    id: 'Grant Aviation',
    carrierContactNo: '1-888-359-4726',
    airlineLink: 'https://www.flygrant.com/',
    airlineCheckin: 'https://www.flygrant.com/',
    airlineBaggagePolicy: 'https://www.flygrant.com/',
    airlineSeating: 'https://www.flygrant.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Great Lakes',
    id: 'Great Lakes',
    carrierContactNo: '1-800-554-5111',
    airlineLink: 'https://www.greatlakesav.com/',
    airlineCheckin: 'https://www.greatlakesav.com/',
    airlineBaggagePolicy: 'https://www.greatlakesav.com/',
    airlineSeating: 'https://www.greatlakesav.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Gulf Air Company',
    id: 'Gulf Air Company',
    carrierContactNo: '1-888-359-4853',
    airlineLink: 'https://www.gulfair.com/',
    airlineCheckin: 'https://www.gulfair.com/manage-my-booking/web-check-in',
    airlineBaggagePolicy: 'https://www.gulfair.com/baggage/allowance',
    airlineSeating: 'https://www.gulfair.com/manage-my-booking/web-check-in',
  },
  {
    carrierImg: carrierImg3,
    label: 'GX Airlines',
    id: 'GX Airlines',
    carrierContactNo: '',
    airlineLink: 'https://http/www.gxairlines.com/',
    airlineCheckin: 'https://http/www.gxairlines.com/',
    airlineBaggagePolicy: 'https://http/www.gxairlines.com/',
    airlineSeating: 'https://http/www.gxairlines.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Hahn Air Systems',
    id: 'Hahn Air Systems',
    carrierContactNo: '1-855-424-6247',
    airlineLink: 'https://www.hahnair.com/en',
    airlineCheckin: 'https://www.hahnair.com/en',
    airlineBaggagePolicy: 'https://www.hahnair.com/en',
    airlineSeating: 'https://www.hahnair.com/en',
  },
  {
    carrierImg: carrierImg3,
    label: 'Hainan Airlines',
    id: 'Hainan Airlines',
    carrierContactNo: '1-888-688-8813',
    airlineLink: 'https://www.hainanairlines.com/',
    airlineCheckin: 'https://www.hainanairlines.com/US/US/Check-in',
    airlineBaggagePolicy:
      'https://www.hainanairlines.com/HUPortal/dyn/portal/DisplayPage?COUNTRY_SITE=US&SITE=CBHZCBHZ&LANGUAGE=US&PAGE=TYXL',
    airlineSeating: 'https://www.hainanairlines.com/US/US/Check-in',
  },
  {
    carrierImg: carrierImg3,
    label: 'Hebei Airlines',
    id: 'Hebei Airlines',
    carrierContactNo: '1-888-688-8813',
    airlineLink: 'https://http/www.hbhk.com.cn/',
    airlineCheckin: 'https://http/www.hbhk.com.cn/',
    airlineBaggagePolicy: 'https://http/www.hbhk.com.cn/',
    airlineSeating: 'https://http/www.hbhk.com.cn/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Helvetic Airways',
    id: 'Helvetic Airways',
    carrierContactNo: '011-972-3-5109589',
    airlineLink: 'https://www.israirairlines.com/',
    airlineCheckin: 'https://www.israirairlines.com/',
    airlineBaggagePolicy: 'https://www.israirairlines.com/',
    airlineSeating: 'https://www.israirairlines.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Hinterland Aviation',
    id: 'Hinterland Aviation',
    carrierContactNo: '',
    airlineLink: 'https://www.hinterlandaviation.com.au/',
    airlineCheckin: 'https://www.hinterlandaviation.com.au/',
    airlineBaggagePolicy: 'https://www.hinterlandaviation.com.au/',
    airlineSeating: 'https://www.hinterlandaviation.com.au/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Hong Kong Airlines',
    id: 'Hong Kong Airlines',
    carrierContactNo: '1-855-393-3880',
    airlineLink: 'https://www.hongkongairlines.com/en_HK/homepage',
    airlineCheckin: 'https://www.hongkongairlines.com/checkinservice/en_HK',
    airlineBaggagePolicy:
      'https://www.hongkongairlines.com/en_HK/valueaddbooking/pre-paidbaggage',
    airlineSeating: 'https://www.hongkongairlines.com/checkinservice/en_HK',
  },
  {
    carrierImg: carrierImg3,
    label: 'Hong Kong Express',
    id: 'Hong Kong Express',
    carrierContactNo: '1-855-298-4316',
    airlineLink: 'https://www.hkexpress.com/en-us/',
    airlineCheckin: 'https://booking.hkexpress.com/en-US/check-in/login',
    airlineBaggagePolicy: 'https://www.hkexpress.com/en-us/',
    airlineSeating: 'https://booking.hkexpress.com/en-US/check-in/login',
  },
  {
    carrierImg: carrierImg3,
    label: 'HOP!',
    id: 'HOP!',
    carrierContactNo: '',
    airlineLink: 'https://www.hop.com/en/',
    airlineCheckin: 'https://www.hop.com/en/',
    airlineBaggagePolicy: 'https://www.hop.com/en/',
    airlineSeating: 'https://www.hop.com/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Horizon Air',
    id: 'Horizon Air',
    carrierContactNo: '1-800-252-7522',
    airlineLink: 'https://www.alaskaair.com/',
    airlineCheckin: 'https://www.alaskaair.com/',
    airlineBaggagePolicy: 'https://www.alaskaair.com/',
    airlineSeating: 'https://www.alaskaair.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'IBC Airways',
    id: 'IBC Airways',
    carrierContactNo: '1-866-422-6000',
    airlineLink: 'https://ibcairways.com/',
    airlineCheckin: 'https://ibcairways.com/',
    airlineBaggagePolicy: 'https://ibcairways.com/',
    airlineSeating: 'https://ibcairways.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Iberia',
    id: 'Iberia',
    carrierContactNo: '1-800-772-4642',
    airlineLink: 'https://www.iberia.com/',
    airlineCheckin:
      'https://www.iberia.com/us/online-checkin/?language=en&market=us#/ibcose',
    airlineBaggagePolicy: 'https://www.iberia.com/us/baggage/',
    airlineSeating:
      'https://www.iberia.com/us/online-checkin/?language=en&market=us#/ibcose',
  },
  {
    carrierImg: carrierImg3,
    label: 'IBEX Airlines',
    id: 'IBEX Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.ibexair.co.jp/en/',
    airlineCheckin: 'https://www.ibexair.co.jp/en/',
    airlineBaggagePolicy: 'https://www.ibexair.co.jp/en/',
    airlineSeating: 'https://www.ibexair.co.jp/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'IndiGo',
    id: 'IndiGo',
    carrierContactNo: '',
    airlineLink: 'https://www.goindigo.in/',
    airlineCheckin: 'https://www.goindigo.in/',
    airlineBaggagePolicy: 'https://www.goindigo.in/',
    airlineSeating: 'https://www.goindigo.in/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Indonesia AirAsia',
    id: 'Indonesia AirAsia',
    carrierContactNo: '1-844-727-4588',
    airlineLink: 'https://www.airasia.com/',
    airlineCheckin: 'https://www.airasia.com/check-in/en/gb',
    airlineBaggagePolicy: 'https://www.airasia.com/',
    airlineSeating: 'https://www.airasia.com/check-in/en/gb',
  },
  {
    carrierImg: carrierImg3,
    label: 'Indonesia AirAsia X',
    id: 'Indonesia AirAsia X',
    carrierContactNo: '+622129270999 or +628041333333',
    airlineLink: 'https://www.airasia.com/',
    airlineCheckin: 'https://www.airasia.com/check-in/en/gb',
    airlineBaggagePolicy: 'https://www.airasia.com/',
    airlineSeating: 'https://www.airasia.com/check-in/en/gb',
  },
  {
    carrierImg: carrierImg3,
    label: 'Indonesian',
    id: 'Indonesian',
    carrierContactNo: '',
    airlineLink:
      'https://www.garuda-indonesia.com/other-countries/en/index.page',
    airlineCheckin:
      'https://www.garuda-indonesia.com/other-countries/en/index.page',
    airlineBaggagePolicy:
      'https://www.garuda-indonesia.com/other-countries/en/index.page',
    airlineSeating:
      'https://www.garuda-indonesia.com/other-countries/en/index.page',
  },
  {
    carrierImg: carrierImg3,
    label: 'Insel Air',
    id: 'Insel Air',
    carrierContactNo: '1-855-493-6004',
    airlineLink: 'https://www.fly-inselair.com/en/',
    airlineCheckin: 'https://www.fly-inselair.com/en/',
    airlineBaggagePolicy: 'https://www.fly-inselair.com/en/',
    airlineSeating: 'https://www.fly-inselair.com/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Insel Air Aruba',
    id: 'Insel Air Aruba',
    carrierContactNo: '',
    airlineLink: 'https://www.fly-inselair.com/en/',
    airlineCheckin: 'https://www.fly-inselair.com/en/',
    airlineBaggagePolicy: 'https://www.fly-inselair.com/en/',
    airlineSeating: 'https://www.fly-inselair.com/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'interCaribbean',
    id: 'interCaribbean',
    carrierContactNo: '1-888-957-3223',
    airlineLink: 'https://www.intercaribbean.com/',
    airlineCheckin: 'https://www.intercaribbean.com/',
    airlineBaggagePolicy: 'https://www.intercaribbean.com/',
    airlineSeating: 'https://www.intercaribbean.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Interjet',
    id: 'Interjet',
    carrierContactNo: '1-866-285-9525',
    airlineLink: 'https://www.interjet.com/en-us',
    airlineCheckin: 'https://www.interjet.com/en-us',
    airlineBaggagePolicy: 'https://www.interjet.com/en-us',
    airlineSeating: 'https://www.interjet.com/en-us',
  },
  {
    carrierImg: carrierImg3,
    label: 'Island Air',
    id: 'Island Air',
    carrierContactNo: '1-800-652-6541',
    airlineLink: 'https://www.islandair.com/',
    airlineCheckin: 'https://www.islandair.com/',
    airlineBaggagePolicy: 'https://www.islandair.com/',
    airlineSeating: 'https://www.islandair.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Israir',
    id: 'Israir',
    carrierContactNo: '011-973-510-9589',
    airlineLink: 'https://www.israir.co.il/',
    airlineCheckin: 'https://www.israir.co.il/',
    airlineBaggagePolicy: 'https://www.israir.co.il/',
    airlineSeating: 'https://www.israir.co.il/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Izhavia',
    id: 'Izhavia',
    carrierContactNo: '',
    airlineLink: 'https://www.izhavia.su/',
    airlineCheckin: 'https://www.izhavia.su/',
    airlineBaggagePolicy: 'https://www.izhavia.su/',
    airlineSeating: 'https://www.izhavia.su/',
  },
  {
    carrierImg: carrierImg3,
    label: 'JAL Express',
    id: 'JAL Express',
    carrierContactNo: '',
    airlineLink: 'https://www.jal.com/index.html',
    airlineCheckin: 'https://www.jal.co.jp/en/inter/boarding/quic/',
    airlineBaggagePolicy: 'https://www.jal.com/index.html',
    airlineSeating: 'https://www.jal.co.jp/en/inter/boarding/quic/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Jambojet',
    id: 'Jambojet',
    carrierContactNo: '',
    airlineLink: 'https://www.jambojet.com/',
    airlineCheckin: 'https://www.jambojet.com/',
    airlineBaggagePolicy: 'https://www.jambojet.com/',
    airlineSeating: 'https://www.jambojet.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Japan Airlines',
    id: 'Japan Airlines',
    carrierContactNo: '1-800-525-3663',
    airlineLink: 'https://www.jal.co.jp/en/',
    airlineCheckin:
      'https://checkin.si.amadeus.net/static/PRD/JL/#/checkin?ghAirline=JL&ln=en',
    airlineBaggagePolicy: 'https://www.jal.co.jp/en/inter/service/bag/',
    airlineSeating:
      'https://checkin.si.amadeus.net/static/PRD/JL/#/checkin?ghAirline=JL&ln=en',
  },
  {
    carrierImg: carrierImg3,
    label: 'Japan Trans Ocean',
    id: 'Japan Trans Ocean',
    carrierContactNo: '',
    airlineLink: 'https://jta-okinawa.com/',
    airlineCheckin: 'https://jta-okinawa.com/',
    airlineBaggagePolicy: 'https://jta-okinawa.com/',
    airlineSeating: 'https://jta-okinawa.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Jazeera Airways',
    id: 'Jazeera Airways',
    carrierContactNo: '',
    airlineLink: 'https://www.jazeeraairways.com/',
    airlineCheckin: 'https://www.jazeeraairways.com/webcheckin/?recloc=&ln=',
    airlineBaggagePolicy: 'https://www.jazeeraairways.com/',
    airlineSeating: 'https://www.jazeeraairways.com/webcheckin/?recloc=&ln=',
  },
  {
    carrierImg: carrierImg3,
    label: 'JC International Airlines',
    id: 'JC International Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.jcairlines.com/',
    airlineCheckin: 'https://www.jcairlines.com/#/selfCenter/ticketVerify',
    airlineBaggagePolicy: 'https://www.jcairlines.com/',
    airlineSeating: 'https://www.jcairlines.com/#/selfCenter/ticketVerify',
  },
  {
    carrierImg: carrierImg3,
    label: 'Jeju Air',
    id: 'Jeju Air',
    carrierContactNo: '',
    airlineLink: 'https://www.jejuair.net/jejuair/en/main.do',
    airlineCheckin: 'https://www.jejuair.net/jejuair/en/main.do',
    airlineBaggagePolicy: 'https://www.jejuair.net/jejuair/en/main.do',
    airlineSeating: 'https://www.jejuair.net/jejuair/en/main.do',
  },
  {
    carrierImg: carrierImg3,
    label: 'Jet Airways',
    id: 'Jet Airways',
    carrierContactNo: '1-877-835-9538',
    airlineLink: 'https://www.jetairways.com/en/ph/Home.aspx',
    airlineCheckin: 'https://www.jetairways.com/en/ph/Home.aspx',
    airlineBaggagePolicy:
      'https://www.jetairways.com/EN/PH/Baggage/FreeBaggageAllowances.aspx',
    airlineSeating: 'https://www.jetairways.com/en/ph/Home.aspx',
  },
  {
    carrierImg: carrierImg3,
    label: 'Jet2',
    id: 'Jet2',
    carrierContactNo: '',
    airlineLink: 'https://www.jet2.com/en',
    airlineCheckin: 'https://www.jet2.com/en',
    airlineBaggagePolicy: 'https://www.jet2.com/en',
    airlineSeating: 'https://www.jet2.com/en',
  },
  {
    carrierImg: carrierImg3,
    label: 'JetLite',
    id: 'JetLite',
    carrierContactNo: '011-91-22-303-02020',
    airlineLink: 'https://www.jetairways.com/en/IN/Home.aspx',
    airlineCheckin: 'https://www.jetairways.com/en/IN/Home.aspx',
    airlineBaggagePolicy:
      'https://www.jetairways.com/EN/PH/Baggage/FreeBaggageAllowances.aspx',
    airlineSeating: 'https://www.jetairways.com/en/IN/Home.aspx',
  },
  {
    carrierImg: carrierImg3,
    label: 'JetSmart',
    id: 'JetSmart',
    carrierContactNo: '',
    airlineLink: 'https://jetsmart.com/cl/es/',
    airlineCheckin: 'https://jetsmart.com/cl/es/',
    airlineBaggagePolicy: 'https://jetsmart.com/cl/es/',
    airlineSeating: 'https://jetsmart.com/cl/es/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Jetstar Airways',
    id: 'Jetstar Airways',
    carrierContactNo: '1-866-397-8170',
    airlineLink: 'https://www.jetstar.com/au/en/home',
    airlineCheckin: 'https://checkin.jetstar.com/au/en/checkin',
    airlineBaggagePolicy: 'https://www.jetstar.com/au/en/home',
    airlineSeating: 'https://checkin.jetstar.com/au/en/checkin',
  },
  {
    carrierImg: carrierImg3,
    label: 'Jetstar Asia',
    id: 'Jetstar Asia',
    carrierContactNo: '1-866-397-8170',
    airlineLink: 'https://www.jetstar.com/au/en/home',
    airlineCheckin: 'https://checkin.jetstar.com/sg/en/checkin',
    airlineBaggagePolicy: 'https://www.jetstar.com/au/en/home',
    airlineSeating: 'https://checkin.jetstar.com/sg/en/checkin',
  },
  {
    carrierImg: carrierImg3,
    label: 'Jetstar Japan',
    id: 'Jetstar Japan',
    carrierContactNo: '1-866-397-8170',
    airlineLink: 'https://www.jetstar.com/au/en/home',
    airlineCheckin: 'https://checkin.jetstar.com/sg/en/checkin',
    airlineBaggagePolicy: 'https://www.jetstar.com/au/en/home',
    airlineSeating: 'https://checkin.jetstar.com/sg/en/checkin',
  },
  {
    carrierImg: carrierImg3,
    label: 'Jetstar Pacific',
    id: 'Jetstar Pacific',
    carrierContactNo: '1-866-397-8170',
    airlineLink: 'https://www.jetstar.com/au/en/home',
    airlineCheckin: 'https://checkin.jetstar.com/sg/en/checkin',
    airlineBaggagePolicy: 'https://www.jetstar.com/au/en/home',
    airlineSeating: 'https://checkin.jetstar.com/sg/en/checkin',
  },
  {
    carrierImg: carrierImg3,
    label: 'JetSuiteX',
    id: 'JetSuiteX',
    carrierContactNo: '1-800-435-9579',
    airlineLink: 'https://www.jetsuitex.com/',
    airlineCheckin: 'https://www.jetsuitex.com/',
    airlineBaggagePolicy: 'https://www.jetsuitex.com/',
    airlineSeating: 'https://www.jetsuitex.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Jiangxi Air',
    id: 'Jiangxi Air',
    carrierContactNo: '1-800-435-9579',
    airlineLink: 'https://www.airjiangxi.com/',
    airlineCheckin: 'https://webckipe.travelsky.com/cki/login.do?orgId=RYAIR',
    airlineBaggagePolicy: 'https://www.airjiangxi.com/',
    airlineSeating: 'https://webckipe.travelsky.com/cki/login.do?orgId=RYAIR',
  },
  {
    carrierImg: carrierImg3,
    label: 'Jin Air',
    id: 'Jin Air',
    carrierContactNo: '',
    airlineLink: 'https://www.jinair.com/',
    airlineCheckin: 'https://www.jinair.com/checkin/index',
    airlineBaggagePolicy: 'https://www.jinair.com/',
    airlineSeating: 'https://www.jinair.com/checkin/index',
  },
  {
    carrierImg: carrierImg3,
    label: 'Jordan Aviation',
    id: 'Jordan Aviation',
    carrierContactNo: '',
    airlineLink: 'https://www.jordanaviation.jo/',
    airlineCheckin: 'https://www.jordanaviation.jo/',
    airlineBaggagePolicy: 'https://www.jordanaviation.jo/',
    airlineSeating: 'https://www.jordanaviation.jo/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Joy Air',
    id: 'Joy Air',
    carrierContactNo: '',
    airlineLink: 'https://www.joy-air.com/',
    airlineCheckin: 'https://www.joy-air.com/',
    airlineBaggagePolicy: 'https://www.joy-air.com/',
    airlineSeating: 'https://www.joy-air.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Jubba Airways',
    id: 'Jubba Airways',
    carrierContactNo: '',
    airlineLink: 'https://www.jubbaairways.com/',
    airlineCheckin: 'https://www.jubbaairways.com/',
    airlineBaggagePolicy: 'https://www.jubbaairways.com/',
    airlineSeating: 'https://www.jubbaairways.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Juneyao Airlines',
    id: 'Juneyao Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.global.juneyaoair.com/',
    airlineCheckin: 'https://www.global.juneyaoair.com/',
    airlineBaggagePolicy: 'https://www.global.juneyaoair.com/',
    airlineSeating: 'https://www.global.juneyaoair.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Kam Air',
    id: 'Kam Air',
    carrierContactNo: '',
    airlineLink: 'https://www.kamair.com/',
    airlineCheckin: 'https://www.kamair.com/',
    airlineBaggagePolicy: 'https://www.kamair.com/',
    airlineSeating: 'https://www.kamair.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Karinou Airlines',
    id: 'Karinou Airlines',
    carrierContactNo: '1-877-872-3000',
    airlineLink: 'https://www.usa3000airlines.com/',
    airlineCheckin: 'https://www.usa3000airlines.com/',
    airlineBaggagePolicy: 'https://www.usa3000airlines.com/',
    airlineSeating: 'https://www.usa3000airlines.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Karun Airlines',
    id: 'Karun Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.karunair.ir/en',
    airlineCheckin: 'https://www.karunair.ir/en',
    airlineBaggagePolicy: 'https://www.karunair.ir/en',
    airlineSeating: 'https://www.karunair.ir/en',
  },
  {
    carrierImg: carrierImg3,
    label: 'Kenmore Air',
    id: 'Kenmore Air',
    carrierContactNo: '1-866-435-9524',
    airlineLink: 'https://www.karunair.ir/en',
    airlineCheckin: 'https://www.kenmoreair.com/',
    airlineBaggagePolicy: 'https://www.kenmoreair.com/Before-you-fly/faq-s/',
    airlineSeating: 'https://www.kenmoreair.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Kenya Airways',
    id: 'Kenya Airways',
    carrierContactNo: '1-866-536-9224',
    airlineLink: 'https://www.kenya-airways.com/us/en/',
    airlineCheckin: 'https://www.kenya-airways.com/us/en/',
    airlineBaggagePolicy:
      'https://www.kenya-airways.com/prepare-for-travel/baggage-information/en/',
    airlineSeating: 'https://www.kenya-airways.com/us/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'KLM-Royal Dutch Airlines',
    id: 'KLM-Royal Dutch Airlines',
    carrierContactNo: '1-800-618-0104',
    airlineLink: 'https://www.klm.com/home/us/en',
    airlineCheckin: 'https://www.klm.com/ams/checkin/web/kl/us/en',
    airlineBaggagePolicy:
      'https://www.klm.com/travel/us_en/prepare_for_travel/baggage/baggage_allowance/index.htm',
    airlineSeating: 'https://www.klm.com/ams/checkin/web/kl/us/en',
  },
  {
    carrierImg: carrierImg3,
    label: 'Korean Air',
    id: 'Korean Air',
    carrierContactNo: '1-800-438-5000',
    airlineLink: 'https://www.koreanair.com/global/en.html',
    airlineCheckin:
      'https://www.koreanair.com/content/koreanair/global/en/traveling/airport-check-in.html#web-check-in',
    airlineBaggagePolicy:
      'https://www.koreanair.com/global/en/traveling/baggage-services.html',
    airlineSeating:
      'https://www.koreanair.com/content/koreanair/global/en/traveling/airport-check-in.html#web-check-in',
  },
  {
    carrierImg: carrierImg3,
    label: 'Kunming Airlines',
    id: 'Kunming Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.airkunming.com/',
    airlineCheckin: 'https://www.airkunming.com/',
    airlineBaggagePolicy: 'https://www.airkunming.com/',
    airlineSeating: 'https://www.airkunming.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Kuwait Airways',
    id: 'Kuwait Airways',
    carrierContactNo: '1-201-582-9200',
    airlineLink: 'https://www.kuwaitairways.com/',
    airlineCheckin: 'https://www.kuwaitairways.com/en?tab=panel3',
    airlineBaggagePolicy:
      'https://www.kuwaitairways.com/en/is/baggaage-service',
    airlineSeating: 'https://www.kuwaitairways.com/en?tab=panel3',
  },
  {
    carrierImg: carrierImg3,
    label: 'La Compagnie',
    id: 'La Compagnie',
    carrierContactNo: '1-800-218-8187',
    airlineLink: 'https://www.lacompagnie.com/en',
    airlineCheckin: 'https://lacompagnie.inkcloud.io/?set_language_key=1',
    airlineBaggagePolicy: 'https://www.lacompagnie.com/en',
    airlineSeating: 'https://lacompagnie.inkcloud.io/?set_language_key=1',
  },
  {
    carrierImg: carrierImg3,
    label: 'Laker Airways',
    id: 'Laker Airways',
    carrierContactNo: '1-800-368-5387',
    airlineLink: 'https://www.ameristarjet.com/',
    airlineCheckin: 'https://www.ameristarjet.com/',
    airlineBaggagePolicy: 'https://www.ameristarjet.com/',
    airlineSeating: 'https://www.ameristarjet.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'LAM Mozambique Airlines',
    id: 'LAM Mozambique Airlines',
    carrierContactNo: '011-258-21-468800',
    airlineLink: 'https://www.lam.co.mz/en',
    airlineCheckin: 'https://www.lam.co.mz/en',
    airlineBaggagePolicy: 'https://www.lam.co.mz/en',
    airlineSeating: 'https://www.lam.co.mz/en',
  },
  {
    carrierImg: carrierImg3,
    label: 'Lanmei Airlines',
    id: 'Lanmei Airlines',
    carrierContactNo: '011-258-21-468800',
    airlineLink: 'https://www.lanmeiairlines.com/',
    airlineCheckin:
      'https://lanmeiairlines.com/lanmeiairlines2.0/default/menus/EN/experience/LMCheckOnline.html',
    airlineBaggagePolicy: 'https://www.lanmeiairlines.com/',
    airlineSeating:
      'https://lanmeiairlines.com/lanmeiairlines2.0/default/menus/EN/experience/LMCheckOnline.html',
  },
  {
    carrierImg: carrierImg3,
    label: 'Lao Airlines',
    id: 'Lao Airlines',
    carrierContactNo: '1-877 989 5677',
    airlineLink: 'https://www.laoairlines.com/',
    airlineCheckin: 'https://www.laoairlines.com/',
    airlineBaggagePolicy: 'https://www.laoairlines.com/',
    airlineSeating: 'https://www.laoairlines.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Lao Skyway',
    id: 'Lao Skyway',
    carrierContactNo: '',
    airlineLink: 'https://www.laoskyway.com/',
    airlineCheckin: 'https://www.laoskyway.com/',
    airlineBaggagePolicy: 'https://www.laoskyway.com/',
    airlineSeating: 'https://www.laoskyway.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'LATAM Airlines',
    id: 'LATAM Airlines',
    carrierContactNo: '1-866-435-9526',
    airlineLink: 'https://www.latam.com/en_us/',
    airlineCheckin: 'https://www.latam.com/en_us/',
    airlineBaggagePolicy: 'https://www.latam.com/en_us/travel-information/',
    airlineSeating: 'https://www.latam.com/en_us/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Latin American Wings',
    id: 'Latin American Wings',
    carrierContactNo: '1-888-575-4546',
    airlineLink: 'https://www.pacificwings.com/',
    airlineCheckin: 'https://www.pacificwings.com/',
    airlineBaggagePolicy: 'https://www.pacificwings.com/',
    airlineSeating: 'https://www.pacificwings.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Laudamotion',
    id: 'Laudamotion',
    carrierContactNo: '',
    airlineLink: 'https://www.laudamotion.com/la/en/',
    airlineCheckin: 'https://www.laudamotion.com/la/en/',
    airlineBaggagePolicy: 'https://www.laudamotion.com/la/en/',
    airlineSeating: 'https://www.laudamotion.com/la/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'LC Peru',
    id: 'LC Peru',
    carrierContactNo: '',
    airlineLink: 'https://www.lcperu.pe/en',
    airlineCheckin: 'https://www.lcperu.pe/en',
    airlineBaggagePolicy: 'https://www.lcperu.pe/en',
    airlineSeating: 'https://www.lcperu.pe/en',
  },
  {
    carrierImg: carrierImg3,
    label: 'Level',
    id: 'Level',
    carrierContactNo: '',
    airlineLink: 'https://www.flylevel.com/',
    airlineCheckin: 'https://www.flylevel.com/',
    airlineBaggagePolicy: 'https://www.flylevel.com/',
    airlineSeating: 'https://www.flylevel.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'LIAT',
    id: 'LIAT',
    carrierContactNo: '1-268-480-5601',
    airlineLink: 'https://www.liat.com/',
    airlineCheckin: 'https://book.liat.com/SearchWebCheckin.aspx',
    airlineBaggagePolicy: 'https://www.liat.com/navSource.html?page_id=3',
    airlineSeating: 'https://book.liat.com/SearchWebCheckin.aspx',
  },
  {
    carrierImg: carrierImg3,
    label: 'Lion Air',
    id: 'Lion Air',
    carrierContactNo: '(+6280) 4177 8899',
    airlineLink: 'https://lionair.co.id/',
    airlineCheckin: 'https://lionair.co.id/booking-and-services/web-check-in',
    airlineBaggagePolicy:
      'https://lionair.co.id/booking-and-services/web-check-in',
    airlineSeating: 'https://lionair.co.id/booking-and-services/web-check-in',
  },
  {
    carrierImg: carrierImg3,
    label: 'Loganair',
    id: 'Loganair',
    carrierContactNo: '',
    airlineLink: 'https://www.loganair.co.uk/',
    airlineCheckin: 'https://www.loganair.co.uk/',
    airlineBaggagePolicy: 'https://www.loganair.co.uk/',
    airlineSeating: 'https://www.loganair.co.uk/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Loong Airlines',
    id: 'Loong Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.loongair.cn/',
    airlineCheckin: 'https://www.loongair.cn/',
    airlineBaggagePolicy: 'https://www.loongair.cn/',
    airlineSeating: 'https://www.loongair.cn/',
  },
  {
    carrierImg: carrierImg3,
    label: 'LOT Polish Airlines',
    id: 'LOT Polish Airlines',
    carrierContactNo: '1-212-789-0970',
    airlineLink: 'https://www.lot.com/us/en/',
    airlineCheckin: 'https://www.lot.com/us/en/check-in',
    airlineBaggagePolicy: 'https://www.lot.com/us/en/baggage',
    airlineSeating: 'https://www.lot.com/us/en/check-in',
  },
  {
    carrierImg: carrierImg3,
    label: 'Lucky Airlines',
    id: 'Lucky Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.luckyair.net/',
    airlineCheckin: 'https://www.luckyair.net/',
    airlineBaggagePolicy: 'https://www.luckyair.net/',
    airlineSeating: 'https://www.luckyair.net/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Luxair',
    id: 'Luxair',
    carrierContactNo: '1-352-2456-4242',
    airlineLink: 'https://www.luxair.lu/en',
    airlineCheckin: 'https://www.luxair.lu/en/flight/checkin',
    airlineBaggagePolicy: 'https://www.luxair.lu/en',
    airlineSeating: 'https://www.luxair.lu/en/flight/checkin',
  },
  {
    carrierImg: carrierImg3,
    label: 'Malaysian',
    id: 'Malaysian',
    carrierContactNo: '1-800-552-9264',
    airlineLink: 'https://www.malaysiaairlines.com/hq/en.html',
    airlineCheckin:
      'https://checkin.si.amadeus.net/static/PRD/MH/#/identification',
    airlineBaggagePolicy:
      'https://www.malaysiaairlines.com/hq/en/plan-your-trip.html',
    airlineSeating:
      'https://checkin.si.amadeus.net/static/PRD/MH/#/identification',
  },
  {
    carrierImg: carrierImg3,
    label: 'Malev Hungarian',
    id: 'Malev Hungarian',
    carrierContactNo: '011-361-802-1111',
    airlineLink: 'https://www.malev.hu/',
    airlineCheckin: 'https://www.malev.hu/',
    airlineBaggagePolicy: 'https://www.malev.hu/',
    airlineSeating: 'https://www.malev.hu/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Malindo Airways',
    id: 'Malindo Airways',
    carrierContactNo: '+603 7841 5388',
    airlineLink: 'https://www.malindoair.com/',
    airlineCheckin:
      'https://dx.checkin.malindoair.com/dx/ODCI/#/check-in/start?locale=en-US',
    airlineBaggagePolicy: 'https://www.malindoair.com/',
    airlineSeating:
      'https://dx.checkin.malindoair.com/dx/ODCI/#/check-in/start?locale=en-US',
  },
  {
    carrierImg: carrierImg3,
    label: 'Mandarin Airlines',
    id: 'Mandarin Airlines',
    carrierContactNo: '(02) 2514-2050',
    airlineLink: 'https://www.mandarin-airlines.com/',
    airlineCheckin:
      'https://www.mandarin-airlines.com/Content/Check%20in-2017020014-111',
    airlineBaggagePolicy: 'https://www.mandarin-airlines.com/',
    airlineSeating:
      'https://www.mandarin-airlines.com/Content/Check%20in-2017020014-111',
  },
  {
    carrierImg: carrierImg3,
    label: 'Mango',
    id: 'Mango',
    carrierContactNo: '',
    airlineLink: 'https://www.flymango.com/',
    airlineCheckin:
      'https://checkin.si.amadeus.net/1ASIHSSCWCIJE/sscwje/checkin',
    airlineBaggagePolicy: 'https://www.flymango.com/',
    airlineSeating:
      'https://checkin.si.amadeus.net/1ASIHSSCWCIJE/sscwje/checkin',
  },
  {
    carrierImg: carrierImg3,
    label: 'Mann Yadanarpon Airlines',
    id: 'Mann Yadanarpon Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.airmyp.com/en',
    airlineCheckin: 'https://www.airmyp.com/en',
    airlineBaggagePolicy: 'https://www.airmyp.com/en',
    airlineSeating: 'https://www.airmyp.com/en',
  },
  {
    carrierImg: carrierImg3,
    label: 'Martinair Holland',
    id: 'Martinair Holland',
    carrierContactNo: '1-800-627-8462',
    airlineLink: 'https://www.martinair.com/',
    airlineCheckin: 'https://www.martinair.com/',
    airlineBaggagePolicy: 'https://www.martinair.com/',
    airlineSeating: 'https://www.martinair.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Maya Island Air',
    id: 'Maya Island Air',
    carrierContactNo: '011-501-223-1140',
    airlineLink: 'https://www.mayaislandair.com/',
    airlineCheckin: 'https://www.mayaislandair.com/',
    airlineBaggagePolicy: 'https://www.mayaislandair.com/',
    airlineSeating: 'https://www.mayaislandair.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'MAYAir',
    id: 'MAYAir',
    carrierContactNo: '1-844-622-0800',
    airlineLink: 'https://www.mayair.com.mx/',
    airlineCheckin: 'https://www.mayair.com.mx/',
    airlineBaggagePolicy: 'https://www.mayair.com.mx/',
    airlineSeating: 'https://www.mayair.com.mx/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Med-View Airline',
    id: 'Med-View Airline',
    carrierContactNo: '',
    airlineLink: 'https://www.medviewairline.com/',
    airlineCheckin: 'https://www.medviewairline.com/',
    airlineBaggagePolicy: 'https://www.medviewairline.com/',
    airlineSeating: 'https://www.medviewairline.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Mesa Air',
    id: 'Mesa Air',
    carrierContactNo: '1-888-435-9462',
    airlineLink: 'https://www.mesa-air.com/',
    airlineCheckin: 'https://www.mesa-air.com/',
    airlineBaggagePolicy: 'https://www.mesa-air.com/',
    airlineSeating: 'https://www.mesa-air.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Mexicana',
    id: 'Mexicana',
    carrierContactNo: '1-800-531-7921',
    airlineLink: 'https://www.mexicana.com/',
    airlineCheckin: 'https://www.mexicana.com/',
    airlineBaggagePolicy: 'https://www.mexicana.com/',
    airlineSeating: 'https://www.mexicana.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Miami Air International',
    id: 'Miami Air International',
    carrierContactNo: '1-877-535-9624',
    airlineLink: 'https://www.miamiair.com/',
    airlineCheckin: 'https://www.miamiair.com/',
    airlineBaggagePolicy: 'https://www.miamiair.com/',
    airlineSeating: 'https://www.miamiair.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'MIAT Mongolian Airlines',
    id: 'MIAT Mongolian Airlines',
    carrierContactNo: '011-976-11-328268',
    airlineLink: 'https://www.miat.com/index.php?lang=en',
    airlineCheckin: 'https://checkin.miat.com/static/PRD/OM/#/identification',
    airlineBaggagePolicy:
      'https://www.miat.com/pagecontent.php?pageId=49&lang=en',
    airlineSeating: 'https://checkin.miat.com/static/PRD/OM/#/identification',
  },
  {
    carrierImg: carrierImg3,
    label: 'Middle East Airlines',
    id: 'Middle East Airlines',
    carrierContactNo: '1-961-162-9999',
    airlineLink: 'https://www.mea.com.lb/english/home',
    airlineCheckin:
      'https://checkin.si.amadeus.net/static/PRD/ME/#/identification',
    airlineBaggagePolicy:
      'https://www.mea.com.lb/english/traveler-info/baggage-calculator/baggage-calculator',
    airlineSeating:
      'https://checkin.si.amadeus.net/static/PRD/ME/#/identification',
  },
  {
    carrierImg: carrierImg3,
    label: 'Mokulele Airlines',
    id: 'Mokulele Airlines',
    carrierContactNo: '1-866-260-7070',
    airlineLink: 'https://www.mokuleleairlines.com/',
    airlineCheckin: 'https://www.mokuleleairlines.com/',
    airlineBaggagePolicy:
      'https://www.mokuleleairlines.com/travel-info/policies/#tab3',
    airlineSeating: 'https://www.mokuleleairlines.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Montenegro',
    id: 'Montenegro',
    carrierContactNo: '011-382-201-9804',
    airlineLink: 'https://montenegroairlines.com/en/',
    airlineCheckin: 'https://montenegroairlines.com/en/',
    airlineBaggagePolicy: 'https://montenegroairlines.com/en',
    airlineSeating: 'https://montenegroairlines.com/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Myanmar Airways International',
    id: 'Myanmar Airways International',
    carrierContactNo: '',
    airlineLink: 'https://http/maiair.com/',
    airlineCheckin: 'https://http/maiair.com/',
    airlineBaggagePolicy: 'https://maiair.com/en',
    airlineSeating: 'https://http/maiair.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Nesma Airlines',
    id: 'Nesma Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.nesmaairlines.com/',
    airlineCheckin: 'https://www.nesmaairlines.com/',
    airlineBaggagePolicy: 'https://www.nesmaairlines.com/',
    airlineSeating: 'https://www.nesmaairlines.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Niki - NL Luftfahrt',
    id: 'Niki - NL Luftfahrt',
    carrierContactNo: '1-866-266-5588',
    airlineLink: 'https://www.flyniki.com/start.php?LANG=eng',
    airlineCheckin: 'https://www.flyniki.com/start.php?LANG=eng',
    airlineBaggagePolicy: 'https://www.flyniki.com/start.php?LANG=eng',
    airlineSeating: 'https://www.flyniki.com/start.php?LANG=eng',
  },
  {
    carrierImg: carrierImg3,
    label: 'Nile Air ',
    id: 'Nile Air ',
    carrierContactNo: '',
    airlineLink: 'https://www.nileair.com/',
    airlineCheckin: 'https://www.nileair.com/',
    airlineBaggagePolicy: 'https://www.nileair.com/',
    airlineSeating: 'https://www.nileair.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Nok Air',
    id: 'Nok Air',
    carrierContactNo: '',
    airlineLink: 'https://www.nokair.com/',
    airlineCheckin: 'https://www.nokair.com/ManageBooking/Login',
    airlineBaggagePolicy: 'https://www.nokair.com/ManageBooking/Login',
    airlineSeating: 'https://www.nokair.com/ManageBooking/Login',
  },
  {
    carrierImg: carrierImg3,
    label: 'NokScoot Airlines',
    id: 'NokScoot Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.nokscoot.com/en/',
    airlineCheckin: 'https://www.nokscoot.com/en/',
    airlineBaggagePolicy: 'https://www.nokscoot.com/en/',
    airlineSeating: 'https://www.nokscoot.com/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Nordavia Regional Airlines',
    id: 'Nordavia Regional Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.nordavia.ru/en/',
    airlineCheckin: 'https://www.nordavia.ru/en/',
    airlineBaggagePolicy: 'https://www.nordavia.ru/en/',
    airlineSeating: 'https://www.nordavia.ru/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Nordwind Airlines',
    id: 'Nordwind Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.nordwindairlines.ru/en/',
    airlineCheckin: 'https://www.nordwindairlines.ru/en/',
    airlineBaggagePolicy: 'https://www.nordwindairlines.ru/en/',
    airlineSeating: 'https://www.nordwindairlines.ru/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Norwegian',
    id: 'Norwegian',
    carrierContactNo: '1-800-357-4159',
    airlineLink: 'https://www.norwegian.com/us/',
    airlineCheckin:
      'https://www.norwegian.com/us/ipr/CheckIn#/auth/lookup?returnState=checkin.gotoresdetails',
    airlineBaggagePolicy: 'https://www.norwegian.com/us/',
    airlineSeating:
      'https://www.norwegian.com/us/ipr/CheckIn#/auth/lookup?returnState=checkin.gotoresdetails',
  },
  {
    carrierImg: carrierImg3,
    label: 'Norwegian Air Argentina',
    id: 'Norwegian Air Argentina',
    carrierContactNo: '',
    airlineLink: 'https://www.norwegian.com/us',
    airlineCheckin: 'https://www.norwegian.com/us',
    airlineBaggagePolicy: 'https://www.norwegian.com/us',
    airlineSeating: 'https://www.norwegian.com/us',
  },
  {
    carrierImg: carrierImg3,
    label: 'Norwegian Air UK',
    id: 'Norwegian Air UK',
    carrierContactNo: '',
    airlineLink: 'https://www.norwegian.com/us/',
    airlineCheckin:
      'https://www.norwegian.com/us/ipr/CheckIn#/auth/lookup?returnState=checkin.gotoresdetails',
    airlineBaggagePolicy: 'https://www.norwegian.com/us/',
    airlineSeating:
      'https://www.norwegian.com/us/ipr/CheckIn#/auth/lookup?returnState=checkin.gotoresdetails',
  },
  {
    carrierImg: carrierImg3,
    label: 'Novoair Limited',
    id: 'Novoair Limited',
    carrierContactNo: '',
    airlineLink: 'https://www.flynovoair.com/',
    airlineCheckin: 'https://www.flynovoair.com/services/web_checkin',
    airlineBaggagePolicy: 'https://www.flynovoair.com/',
    airlineSeating: 'https://www.flynovoair.com/services/web_checkin',
  },
  {
    carrierImg: carrierImg3,
    label: 'Okay Airways',
    id: 'Okay Airways',
    carrierContactNo: '',
    airlineLink: 'https://www.okair.net/#/',
    airlineCheckin: 'https://www.okair.net/#/',
    airlineBaggagePolicy: 'https://www.okair.net/#/',
    airlineSeating: 'https://www.okair.net/#/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Olympic Air',
    id: 'Olympic Air',
    carrierContactNo: '1-833-732-8158',
    airlineLink: 'https://www.olympicair.com/en',
    airlineCheckin: 'https://www.olympicair.com/en/Info/Checkin/WebCheck-In',
    airlineBaggagePolicy: 'https://www.olympicair.com/en/Info/Baggage',
    airlineSeating: 'https://www.olympicair.com/en/Info/Checkin/WebCheck-In',
  },
  {
    carrierImg: carrierImg3,
    label: 'Oman Air',
    id: 'Oman Air',
    carrierContactNo: '011-968-2453-1111',
    airlineLink: 'https://www.omanair.com/',
    airlineCheckin: 'https://www.omanair.com/en/manage-bookings/online-check',
    airlineBaggagePolicy: 'https://www.omanair.com/',
    airlineSeating: 'https://www.omanair.com/en/manage-bookings/online-check',
  },
  {
    carrierImg: carrierImg3,
    label: 'Onur Air',
    id: 'Onur Air',
    carrierContactNo: '011-90-212-468-66-87',
    airlineLink: 'https://www.onurair.com/en/',
    airlineCheckin: 'https://www.onurair.com/en/',
    airlineBaggagePolicy: 'https://www.onurair.com/en/',
    airlineSeating: 'https://www.onurair.com/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'OpenSkies',
    id: 'OpenSkies',
    carrierContactNo: '1-866-581-3596',
    airlineLink: 'https://www.flyopenskies.com/en/home.html',
    airlineCheckin: 'https://www.flyopenskies.com/en/home.html',
    airlineBaggagePolicy: 'https://www.flyopenskies.com/en/home.html',
    airlineSeating: 'https://www.flyopenskies.com/en/home.html',
  },
  {
    carrierImg: carrierImg3,
    label: 'Orbest',
    id: 'Orbest',
    carrierContactNo: '',
    airlineLink: 'https://en.orbest.com/',
    airlineCheckin: 'https://en.orbest.com/exclusive-services',
    airlineBaggagePolicy: 'https://en.orbest.com/exclusive-services',
    airlineSeating: 'https://en.orbest.com/exclusive-services',
  },
  {
    carrierImg: carrierImg3,
    label: 'Orient Thai Airlines',
    id: 'Orient Thai Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.flyorientthai.com/',
    airlineCheckin: 'https://www.flyorientthai.com/',
    airlineBaggagePolicy: 'https://www.flyorientthai.com/',
    airlineSeating: 'https://www.flyorientthai.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Oriental Air Bridge',
    id: 'Oriental Air Bridge',
    carrierContactNo: '',
    airlineLink: 'https://www.orc-air.co.jp/',
    airlineCheckin: 'https://www.orc-air.co.jp/',
    airlineBaggagePolicy: 'https://www.orc-air.co.jp/',
    airlineSeating: 'https://www.orc-air.co.jp/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Pacific Coastal',
    id: 'Pacific Coastal',
    carrierContactNo: '1-800-663-2872',
    airlineLink: 'https://www.pacificcoastal.com/',
    airlineCheckin: 'https://www.pacificcoastal.com/',
    airlineBaggagePolicy: 'https://www.pacificcoastal.com/',
    airlineSeating: 'https://www.pacificcoastal.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Pakistan International Airlines',
    id: 'Pakistan International Airlines',
    carrierContactNo: '1-800-578-6786',
    airlineLink: 'https://www.piac.com.pk/',
    airlineCheckin: 'https://www.piac.com.pk/#web-checkin',
    airlineBaggagePolicy: 'https://www.piac.com.pk/',
    airlineSeating: 'https://www.piac.com.pk/#web-checkin',
  },
  {
    carrierImg: carrierImg3,
    label: 'PAL Express',
    id: 'PAL Express',
    carrierContactNo: '011-63-2-855-8888',
    airlineLink: 'https://www.flypalexpress.com/',
    airlineCheckin: 'https://www.flypalexpress.com/#web-checkin',
    airlineBaggagePolicy: 'https://www.flypalexpress.com/',
    airlineSeating: 'https://www.flypalexpress.com/#web-checkin',
  },
  {
    carrierImg: carrierImg3,
    label: 'PAL Provincial Airlines',
    id: 'PAL Provincial Airlines',
    carrierContactNo: '1-800-225-2525',
    airlineLink: 'https://www.palairlines.ca/en',
    airlineCheckin: 'https://www.palairlines.ca/en',
    airlineBaggagePolicy: 'https://www.palairlines.ca/en',
    airlineSeating: 'https://www.palairlines.ca/en',
  },
  {
    carrierImg: carrierImg3,
    label: 'Panair',
    id: 'Panair',
    carrierContactNo: '1-800-225-2525',
    airlineLink: 'https://www.airarabia.com/en/flights-to-jordan',
    airlineCheckin:
      'https://webcheckin.airarabia.com/accelaero/en/index.html#/',
    airlineBaggagePolicy: 'https://www.airarabia.com/en/flights-to-jordan',
    airlineSeating:
      'https://webcheckin.airarabia.com/accelaero/en/index.html#/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Pascan Aviation',
    id: 'Pascan Aviation',
    carrierContactNo: '1-888-313-8777',
    airlineLink: 'https://www.pascan.com/en/',
    airlineCheckin: 'https://www.pascan.com/en/',
    airlineBaggagePolicy: 'https://www.pascan.com/en/',
    airlineSeating: 'https://www.pascan.com/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Peach Aviation',
    id: 'Peach Aviation',
    carrierContactNo: '+81 3-6302-8991',
    airlineLink: 'https://www.flypeach.com/pc/en',
    airlineCheckin:
      'https://www.flypeach.com/pc/en/lm/ai/airports/how_to_checkin',
    airlineBaggagePolicy: 'https://www.flypeach.com/pc/en',
    airlineSeating:
      'https://www.flypeach.com/pc/en/lm/ai/airports/how_to_checkin',
  },
  {
    carrierImg: carrierImg3,
    label: 'Pegasus Airlines',
    id: 'Pegasus Airlines',
    carrierContactNo: '1-855-229-8999',
    airlineLink: 'https://www.flypgs.com/en',
    airlineCheckin:
      'https://www.flypgs.com/en/useful-info/info-about-flights/check-in',
    airlineBaggagePolicy: 'https://www.flypgs.com/en',
    airlineSeating:
      'https://www.flypgs.com/en/useful-info/info-about-flights/check-in',
  },
  {
    carrierImg: carrierImg3,
    label: 'PenAir (Peninsula Airways)',
    id: 'PenAir (Peninsula Airways)',
    carrierContactNo: '1-800-448-4226',
    airlineLink: 'https://www.penair.com/',
    airlineCheckin:
      'https://wci-prod.sabresonicweb.com/SSW2010/KSC0/checkin.html?execution=e1s1',
    airlineBaggagePolicy: 'https://www.penair.com/fly-with-us/baggage',
    airlineSeating:
      'https://wci-prod.sabresonicweb.com/SSW2010/KSC0/checkin.html?execution=e1s1',
  },
  {
    carrierImg: carrierImg3,
    label: 'People\'s Viennaline',
    id: 'People\'s Viennaline',
    carrierContactNo: '',
    airlineLink: 'https://www.peoples.ch/peoples-airline',
    airlineCheckin: 'https://www.peoples.ch/peoples-airline',
    airlineBaggagePolicy: 'https://www.peoples.ch/peoples-airline',
    airlineSeating: 'https://www.peoples.ch/peoples-airline',
  },
  {
    carrierImg: carrierImg3,
    label: 'Perimeter Aviation',
    id: 'Perimeter Aviation',
    carrierContactNo: '1-800-665-8986',
    airlineLink: 'https://perimeter.ca/',
    airlineCheckin: 'https://perimeter.ca/travel-information/check-in/',
    airlineBaggagePolicy: 'https://perimeter.ca/',
    airlineSeating: 'https://perimeter.ca/travel-information/check-in/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Peruvian Airlines',
    id: 'Peruvian Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.peruvian.pe/pe/en',
    airlineCheckin:
      'https://iport.travelport.com/ici_4_130_0_51750/(S(igwe412onoglksocbvkdjcku))/slm/en',
    airlineBaggagePolicy: 'https://www.peruvian.pe/pe/en',
    airlineSeating:
      'https://iport.travelport.com/ici_4_130_0_51750/(S(igwe412onoglksocbvkdjcku))/slm/en',
  },
  {
    carrierImg: carrierImg3,
    label: 'Philippine Airlines',
    id: 'Philippine Airlines',
    carrierContactNo: '1-800-435-9725',
    airlineLink: 'https://www.philippineairlines.com/en',
    airlineCheckin: 'https://www.philippineairlines.com/en',
    airlineBaggagePolicy: 'https://www.philippineairlines.com/en',
    airlineSeating: 'https://www.philippineairlines.com/en',
  },
  {
    carrierImg: carrierImg3,
    label: 'Pobeda Airlines',
    id: 'Pobeda Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.pobeda.aero/en/',
    airlineCheckin: 'https://www.pobeda.aero/en/',
    airlineBaggagePolicy: 'https://www.pobeda.aero/en/',
    airlineSeating: 'https://www.pobeda.aero/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Polynesian Airlines',
    id: 'Polynesian Airlines',
    carrierContactNo: '011-685-21-261',
    airlineLink: 'https://www.polynesianairlines.com/',
    airlineCheckin: 'https://www.polynesianairlines.com/',
    airlineBaggagePolicy: 'https://www.polynesianairlines.com/',
    airlineSeating: 'https://www.polynesianairlines.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Porter Airlines',
    id: 'Porter Airlines',
    carrierContactNo: '1-888-619-8622',
    airlineLink: 'https://www.flyporter.com/en-us/',
    airlineCheckin: 'https://www.flyporter.com/en-us/#check-in',
    airlineBaggagePolicy:
      'https://www.flyporter.com/en-us/travel-information/baggage',
    airlineSeating: 'https://www.flyporter.com/en-us/#check-in',
  },
  {
    carrierImg: carrierImg3,
    label: 'Precision Air',
    id: 'Precision Air',
    carrierContactNo: '1-255-787-888-408',
    airlineLink: 'https://www.precisionairtz.com/',
    airlineCheckin: 'https://book-precision.crane.aero/web/ICIPNRSearch.xhtml',
    airlineBaggagePolicy:
      'https://www.precisionairtz.com/travel/excess-baggage',
    airlineSeating: 'https://book-precision.crane.aero/web/ICIPNRSearch.xhtml',
  },
  {
    carrierImg: carrierImg3,
    label: 'PSA Airlines',
    id: 'PSA Airlines',
    carrierContactNo: '1-800-235-0986',
    airlineLink: 'https://www.psaairlines.com/',
    airlineCheckin: 'https://www.psaairlines.com/',
    airlineBaggagePolicy: 'https://www.psaairlines.com/',
    airlineSeating: 'https://www.psaairlines.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'PT Trigana Air Service',
    id: 'PT Trigana Air Service',
    carrierContactNo: '',
    airlineLink: 'https://www.trigana-air.com/',
    airlineCheckin: 'https://www.trigana-air.com/',
    airlineBaggagePolicy: 'https://www.trigana-air.com/',
    airlineSeating: 'https://www.trigana-air.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Public Charters',
    id: 'Public Charters',
    carrierContactNo: '1-877-359-7595',
    airlineLink: 'https://www.publiccharters.com/',
    airlineCheckin: 'https://www.publiccharters.com/',
    airlineBaggagePolicy: 'https://www.publiccharters.com/',
    airlineSeating: 'https://www.publiccharters.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Public Charters, Inc. doing business as Regional',
    id: 'Public Charters, Inc. doing business as Regional',
    carrierContactNo: '1-877-359-7595',
    airlineLink: 'https://www.publiccharters.com/',
    airlineCheckin: 'https://www.publiccharters.com/',
    airlineBaggagePolicy: 'https://www.publiccharters.com/fly-with-us/',
    airlineSeating: 'https://www.publiccharters.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Qantas Airways',
    id: 'Qantas Airways',
    carrierContactNo: '1-800-227-4500',
    airlineLink: 'https://www.qantas.com/us/en.html',
    airlineCheckin: 'https://www.qantas.com/us/en.html?tabId=check-in',
    airlineBaggagePolicy:
      'https://www.qantas.com.au/travel/airlines/checked-baggage/global/en',
    airlineSeating: 'https://www.qantas.com/us/en.html?tabId=check-in',
  },
  {
    carrierImg: carrierImg3,
    label: 'Qatar Airways',
    id: 'Qatar Airways',
    carrierContactNo: '1-877-777-2827',
    airlineLink: 'https://www.qatarairways.com/en-us/homepage.html',
    airlineCheckin: 'https://cki.qatarairways.com/cki/dashboard',
    airlineBaggagePolicy: 'https://www.qatarairways.com/us/en/baggage.page',
    airlineSeating: 'https://cki.qatarairways.com/cki/dashboard',
  },
  {
    carrierImg: carrierImg3,
    label: 'Qazaq Air',
    id: 'Qazaq Air',
    carrierContactNo: '',
    airlineLink: 'https://www.flyqazaq.com/en/',
    airlineCheckin: 'https://www.flyqazaq.com/en/checkin',
    airlineBaggagePolicy: 'https://www.flyqazaq.com/en/checkin',
    airlineSeating: 'https://www.flyqazaq.com/en/checkin',
  },
  {
    carrierImg: carrierImg3,
    label: 'Ravn Alaska',
    id: 'Ravn Alaska',
    carrierContactNo: '1-800-866-8394',
    airlineLink: 'https://www.flyravn.com/',
    airlineCheckin:
      'https://checkin.flyravn.com/dx/7HCI/#/check-in/start?locale=en-US',
    airlineBaggagePolicy:
      'https://www.flyravn.com/flying-with-ravn/baggage-information/',
    airlineSeating:
      'https://checkin.flyravn.com/dx/7HCI/#/check-in/start?locale=en-US',
  },
  {
    carrierImg: carrierImg3,
    label: 'Red Wings Airlines',
    id: 'Red Wings Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.flyredwings.com/',
    airlineCheckin: 'https://www.flyredwings.com/',
    airlineBaggagePolicy: 'https://www.flyredwings.com/',
    airlineSeating: 'https://www.flyredwings.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Regent Airways',
    id: 'Regent Airways',
    carrierContactNo: '1-880-96-12669911',
    airlineLink: 'https://www.flyregent.com/',
    airlineCheckin: 'https://www.flyregent.com/',
    airlineBaggagePolicy: 'https://www.flyregent.com/baggage',
    airlineSeating: 'https://www.flyregent.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Regional Express',
    id: 'Regional Express',
    carrierContactNo: '',
    airlineLink: 'https://www.rex.com.au/',
    airlineCheckin: 'https://www.rex.com.au/',
    airlineBaggagePolicy: 'https://www.rex.com.au/baggage',
    airlineSeating: 'https://www.rex.com.au/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Rossiya Russian Airlines',
    id: 'Rossiya Russian Airlines',
    carrierContactNo: '1-8-495-139-7777',
    airlineLink: 'https://www.rossiya-airlines.com/en/',
    airlineCheckin: 'https://wci.rossiya-airlines.com/?lang=en#/search',
    airlineBaggagePolicy:
      'https://www.rossiya-airlines.com/en/flight-with-us/on-board/your_baggage/',
    airlineSeating: 'https://wci.rossiya-airlines.com/?lang=en#/search',
  },
  {
    carrierImg: carrierImg3,
    label: 'Rotana Jet',
    id: 'Rotana Jet',
    carrierContactNo: '',
    airlineLink: 'https://rotanajet.com/',
    airlineCheckin: 'https://rotanajet.com/',
    airlineBaggagePolicy: 'https://rotanajet.com/',
    airlineSeating: 'https://rotanajet.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Royal Air Maroc',
    id: 'Royal Air Maroc',
    carrierContactNo: '1-800-344-6726',
    airlineLink: 'https://www.royalairmaroc.com/',
    airlineCheckin:
      'https://www.royalairmaroc.com/int-en/E-Services/Online-check-in',
    airlineBaggagePolicy:
      'https://www.royalairmarocflights.com/baggage-information.html',
    airlineSeating:
      'https://www.royalairmaroc.com/int-en/E-Services/Online-check-in',
  },
  {
    carrierImg: carrierImg3,
    label: 'Royal Brunei Airlines',
    id: 'Royal Brunei Airlines',
    carrierContactNo: '1-44-2075-84-6660',
    airlineLink: 'https://www.flyroyalbrunei.com/united-kingdom/en/',
    airlineCheckin:
      'https://www.flyroyalbrunei.com/united-kingdom/en/information/seat-selection/',
    airlineBaggagePolicy: 'https://www.flyroyalbrunei.com/united-kingdom/en/',
    airlineSeating:
      'https://www.flyroyalbrunei.com/united-kingdom/en/information/seat-selection/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Royal Jordanian',
    id: 'Royal Jordanian',
    carrierContactNo: '1-212-949-0050',
    airlineLink: 'https://www.rj.com/',
    airlineCheckin: 'https://www.rj.com/',
    airlineBaggagePolicy:
      'https://www.rj.com/en/info-and-tips/baggage-information/checked-luggage',
    airlineSeating: 'https://www.rj.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Ruili Airlines',
    id: 'Ruili Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.rlair.net/#/?_k=yes49z',
    airlineCheckin: 'https://www.rlair.net/#/?_k=yes49z',
    airlineBaggagePolicy: 'https://www.rlair.net/#/?_k=yes49z',
    airlineSeating: 'https://www.rlair.net/#/?_k=yes49z',
  },
  {
    carrierImg: carrierImg3,
    label: 'Rwandair Express',
    id: 'Rwandair Express',
    carrierContactNo: '011-250-788-177-000',
    airlineLink: 'https://www.rwandair.com/',
    airlineCheckin: 'https://www.rwandair.com/',
    airlineBaggagePolicy: 'https://www.rwandair.com/',
    airlineSeating: 'https://www.rwandair.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Ryanair',
    id: 'Ryanair',
    carrierContactNo: '',
    airlineLink: 'https://www.ryanair.com/us/en/',
    airlineCheckin: 'https://www.ryanair.com/us/en/check-in',
    airlineBaggagePolicy: 'https://www.ryanair.com/us/en/',
    airlineSeating: 'https://www.ryanair.com/us/en/check-in',
  },
  {
    carrierImg: carrierImg3,
    label: 'S7 Airlines',
    id: 'S7 Airlines',
    carrierContactNo: '1-7-495-777-9999',
    airlineLink: 'https://www.s7.ru/',
    airlineCheckin: 'https://webcheckin.s7.ru/login.action?request_locale=en',
    airlineBaggagePolicy: 'https://www.s7.ru/',
    airlineSeating: 'https://webcheckin.s7.ru/login.action?request_locale=en',
  },
  {
    carrierImg: carrierImg3,
    label: 'Safi Airways',
    id: 'Safi Airways',
    carrierContactNo: '011 971 4 295 1040',
    airlineLink: 'https://www.safiairways.aero/',
    airlineCheckin: 'https://www.safiairways.aero/',
    airlineBaggagePolicy: 'https://www.safiairways.aero/',
    airlineSeating: 'https://www.safiairways.aero/',
  },
  {
    carrierImg: carrierImg3,
    label: 'SalamAir',
    id: 'SalamAir',
    carrierContactNo: '011-372-640-1163',
    airlineLink: 'https://www.salamair.com/',
    airlineCheckin: 'https://www.salamair.com/',
    airlineBaggagePolicy: 'https://www.salamair.com/',
    airlineSeating: 'https://www.salamair.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Samoa Airways',
    id: 'Samoa Airways',
    carrierContactNo: '1-685-23-097',
    airlineLink: 'https://samoaairways.com/',
    airlineCheckin: 'https://samoaairways.com/',
    airlineBaggagePolicy: 'https://samoaairways.com/',
    airlineSeating: 'https://samoaairways.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'SATA Air Açores',
    id: 'SATA Air Açores',
    carrierContactNo: '1-707-22-7282',
    airlineLink: 'https://www.sata.pt/en',
    airlineCheckin: 'https://www.sata.pt/en',
    airlineBaggagePolicy:
      'https://www.azoresairlines.pt/en/information/baggage',
    airlineSeating: 'https://www.sata.pt/en',
  },
  {
    carrierImg: carrierImg3,
    label: 'SATENA',
    id: 'SATENA',
    carrierContactNo: '',
    airlineLink: 'https://www.satena.com/',
    airlineCheckin: 'https://www.satena.com/',
    airlineBaggagePolicy: 'https://www.satena.com/',
    airlineSeating: 'https://www.satena.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Saudia',
    id: 'Saudia',
    carrierContactNo: '1-800-472-8342',
    airlineLink: 'https://www.saudia.com/',
    airlineCheckin:
      'https://www.saudia.com/travel-with-saudia/manage/web-check-in',
    airlineBaggagePolicy:
      'https://www.saudia.com/TRAVEL-INFORMATION/Baggage/Baggage-allowances',
    airlineSeating:
      'https://www.saudia.com/travel-with-saudia/manage/web-check-in',
  },
  {
    carrierImg: carrierImg3,
    label: 'SaudiGulf Airlines',
    id: 'SaudiGulf Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.saudigulfairlines.com/en/home',
    airlineCheckin: 'https://www.saudigulfairlines.com/en/home',
    airlineBaggagePolicy: 'https://www.saudigulfairlines.com/en/home',
    airlineSeating: 'https://www.saudigulfairlines.com/en/home',
  },
  {
    carrierImg: carrierImg3,
    label: 'SBA Airlines',
    id: 'SBA Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.sbairlines.com/',
    airlineCheckin: 'https://www.sbairlines.com/',
    airlineBaggagePolicy: 'https://www.sbairlines.com/',
    airlineSeating: 'https://www.sbairlines.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Scandinavian Airlines',
    id: 'Scandinavian Airlines',
    carrierContactNo: '1-800-221-2350',
    airlineLink: 'https://www.flysas.com/us-en/',
    airlineCheckin: 'https://www.flysas.com/us-en/checkin',
    airlineBaggagePolicy: 'https://www.flysas.com/us-en/travel-info/baggage/',
    airlineSeating: 'https://www.flysas.com/us-en/checkin',
  },
  {
    carrierImg: carrierImg3,
    label: 'SCAT Airlines',
    id: 'SCAT Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.scat.kz/en/',
    airlineCheckin: 'https://www.scat.kz/en/',
    airlineBaggagePolicy: 'https://www.scat.kz/en/',
    airlineSeating: 'https://www.scat.kz/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Scoot',
    id: 'Scoot',
    carrierContactNo: '1-808-206-7487',
    airlineLink: 'https://www.flyscoot.com/en',
    airlineCheckin: 'https://makeabooking.flyscoot.com/checkin',
    airlineBaggagePolicy: 'https://makeabooking.flyscoot.com/checkin',
    airlineSeating: 'https://makeabooking.flyscoot.com/checkin',
  },
  {
    carrierImg: carrierImg3,
    label: 'Seaborne Airlines',
    id: 'Seaborne Airlines',
    carrierContactNo: '1-866-359-8784',
    airlineLink: 'https://www.seaborneairlines.com',
    airlineCheckin:
      'https://www.seaborneairlines.com/#1495816310455-36fbbb0d-9535',
    airlineBaggagePolicy:
      'https://www.seaborneairlines.com/#1495816310455-36fbbb0d-9535',
    airlineSeating:
      'https://www.seaborneairlines.com/#1495816310455-36fbbb0d-9535',
  },
  {
    carrierImg: carrierImg3,
    label: 'Serene Air',
    id: 'Serene Air',
    carrierContactNo: '',
    airlineLink: 'https://www.sereneair.com/',
    airlineCheckin: 'https://www.sereneair.com/',
    airlineBaggagePolicy: 'https://www.sereneair.com/',
    airlineSeating: 'https://www.sereneair.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Shandong Air',
    id: 'Shandong Air',
    carrierContactNo: '1-86-400-60-96-777',
    airlineLink: 'https://www.sda.cn/index.html',
    airlineCheckin: 'https://www.sda.cn/index.html',
    airlineBaggagePolicy: 'https://www.sda.cn/index.html',
    airlineSeating: 'https://www.sda.cn/index.html',
  },
  {
    carrierImg: carrierImg3,
    label: 'Shanghai Airlines',
    id: 'Shanghai Airlines',
    carrierContactNo: '1-800-200-5118',
    airlineLink: 'https://www.flychinaeastern.com/',
    airlineCheckin: 'https://www.flychinaeastern.com/',
    airlineBaggagePolicy: 'https://www.flychinaeastern.com/',
    airlineSeating: 'https://www.flychinaeastern.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Sharp Airlines',
    id: 'Sharp Airlines',
    carrierContactNo: '',
    airlineLink: 'https://sharpairlines.com/',
    airlineCheckin: 'https://sharpairlines.com/',
    airlineBaggagePolicy: 'https://sharpairlines.com/',
    airlineSeating: 'https://sharpairlines.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Shenzhen Airlines',
    id: 'Shenzhen Airlines',
    carrierContactNo: '1-86-755-8881-4023',
    airlineLink: 'https://shenzhenair.com/',
    airlineCheckin:
      'https://global.shenzhenair.com/zhair/ibe/bookingManagement/toCheckIn.do',
    airlineBaggagePolicy:
      'https://global.shenzhenair.com/zhair/ibe/bookingManagement/toCheckIn.do',
    airlineSeating:
      'https://global.shenzhenair.com/zhair/ibe/bookingManagement/toCheckIn.do',
  },
  {
    carrierImg: carrierImg3,
    label: 'Sichuan Airlines',
    id: 'Sichuan Airlines',
    carrierContactNo: '011-86-8888-8888',
    airlineLink: 'https://global.sichuanair.com/HK-EN/',
    airlineCheckin: 'https://global.sichuanair.com/HK-EN/',
    airlineBaggagePolicy: 'https://global.sichuanair.com/HK-EN/',
    airlineSeating: 'https://global.sichuanair.com/HK-EN/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Silk Air',
    id: 'Silk Air',
    carrierContactNo: '1-800-742-3333',
    airlineLink: 'https://www.silkair.com/en_UK/sg/home',
    airlineCheckin:
      'https://www.singaporeair.com/en_UK/plan-and-book/your-booking/checkin/',
    airlineBaggagePolicy: 'https://www.silkair.com/en_UK/sg/home',
    airlineSeating:
      'https://www.singaporeair.com/en_UK/plan-and-book/your-booking/checkin/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Silver Airways',
    id: 'Silver Airways',
    carrierContactNo: '1-800-499-7450',
    airlineLink: 'https://www.silverairways.com/',
    airlineCheckin: 'https://www.silverairways.com/',
    airlineBaggagePolicy: 'https://www.silverairways.com/',
    airlineSeating: 'https://www.silverairways.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Silverstone Air Services',
    id: 'Silverstone Air Services',
    carrierContactNo: '',
    airlineLink: 'https://www.alternativeairlines.com/silverstone-air',
    airlineCheckin: 'https://www.alternativeairlines.com/silverstone-air',
    airlineBaggagePolicy: 'https://www.alternativeairlines.com/silverstone-air',
    airlineSeating: 'https://www.alternativeairlines.com/silverstone-air',
  },
  {
    carrierImg: carrierImg3,
    label: 'Singapore Airlines',
    id: 'Singapore Airlines',
    carrierContactNo: '1-800-742-3333',
    airlineLink: 'https://www.singaporeair.com/',
    airlineCheckin: 'https://www.singaporeair.com/',
    airlineBaggagePolicy:
      'https://www.singaporeair.com/en_UK/travel-information/baggage-allowances/',
    airlineSeating: 'https://www.singaporeair.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Skippers Australia',
    id: 'Skippers Australia',
    carrierContactNo: '',
    airlineLink: 'https://www.skippers.com.au/',
    airlineCheckin: 'https://www.skippers.com.au/',
    airlineBaggagePolicy: 'https://www.skippers.com.au/',
    airlineSeating: 'https://www.skippers.com.au/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Sky Airline',
    id: 'Sky Airline',
    carrierContactNo: '1-562-2352-5600',
    airlineLink: 'https://www.skyairline.com/english',
    airlineCheckin: 'https://www.skyairline.com/english/services/faq',
    airlineBaggagePolicy: 'https://www.skyairline.com/english/services/faq',
    airlineSeating: 'https://www.skyairline.com/english/services/faq',
  },
  {
    carrierImg: carrierImg3,
    label: 'Sky Express',
    id: 'Sky Express',
    carrierContactNo: '',
    airlineLink: 'https://www.skyexpress.gr/en-us/home.aspx',
    airlineCheckin: 'https://www.skyexpress.gr/en-us/home.aspx',
    airlineBaggagePolicy: 'https://www.skyexpress.gr/en-us/home.aspx',
    airlineSeating: 'https://www.skyexpress.gr/en-us/home.aspx',
  },
  {
    carrierImg: carrierImg3,
    label: 'Skymark Airlines',
    id: 'Skymark Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.skymark.co.jp/en/',
    airlineCheckin: 'https://www.skymark.co.jp/en/',
    airlineBaggagePolicy: 'https://www.skymark.co.jp/en/',
    airlineSeating: 'https://www.skymark.co.jp/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Skytrans',
    id: 'Skytrans',
    carrierContactNo: '',
    airlineLink: 'https://http/www.skytrans.com.au/',
    airlineCheckin: 'https://http/www.skytrans.com.au/',
    airlineBaggagePolicy: 'https://http/www.skytrans.com.au/',
    airlineSeating: 'https://http/www.skytrans.com.au/',
  },
  {
    carrierImg: carrierImg3,
    label: 'SKYWARD EXPRESS',
    id: 'SKYWARD EXPRESS',
    carrierContactNo: '',
    airlineLink: 'https://www.skywardexpress.co.ke/',
    airlineCheckin: 'https://www.skywardexpress.co.ke/',
    airlineBaggagePolicy: 'https://www.skywardexpress.co.ke/',
    airlineSeating: 'https://www.skywardexpress.co.ke/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Skywings Asia Airlines/Sky Angkor Airlines',
    id: 'Skywings Asia Airlines/Sky Angkor Airlines',
    carrierContactNo: '82-2-752-2633',
    airlineLink: 'https://skyangkorair.com/landpage/',
    airlineCheckin: 'https://skyangkorair.com/landpage/',
    airlineBaggagePolicy: 'https://skyangkorair.com/landpage/',
    airlineSeating: 'https://skyangkorair.com/landpage/',
  },
  {
    carrierImg: carrierImg3,
    label: 'SmartWings',
    id: 'SmartWings',
    carrierContactNo: '420-220-116-686',
    airlineLink: 'https://www.smartwings.com/en/',
    airlineCheckin: 'https://www.smartwings.com/en/',
    airlineBaggagePolicy: 'https://www.smartwings.com/en/',
    airlineSeating: 'https://www.smartwings.com/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Solaseed Air',
    id: 'Solaseed Air',
    carrierContactNo: '',
    airlineLink: 'https://www.solaseedair.jp/en/service/airport/',
    airlineCheckin: 'https://www.solaseedair.jp/en/service/airport/',
    airlineBaggagePolicy: 'https://www.solaseedair.jp/en/service/airport/',
    airlineSeating: 'https://www.solaseedair.jp/en/service/airport/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Solomon Airlines',
    id: 'Solomon Airlines',
    carrierContactNo: '1-800-677-4277',
    airlineLink: 'https://www.flysolomons.com/',
    airlineCheckin: 'https://www.flysolomons.com/',
    airlineBaggagePolicy: 'https://www.flysolomons.com/',
    airlineSeating: 'https://www.flysolomons.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Somon Air',
    id: 'Somon Air',
    carrierContactNo: '011-992-44-640-4040',
    airlineLink: 'https://www.somonair.com/',
    airlineCheckin: 'https://www.somonair.com/',
    airlineBaggagePolicy: 'https://www.somonair.com/',
    airlineSeating: 'https://www.somonair.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Sounds Air',
    id: 'Sounds Air',
    carrierContactNo: '',
    airlineLink: 'https://www.soundsair.com/',
    airlineCheckin: 'https://www.soundsair.com/',
    airlineBaggagePolicy: 'https://www.soundsair.com/',
    airlineSeating: 'https://www.soundsair.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'South African Airways',
    id: 'South African Airways',
    carrierContactNo: '1-800-722-9675',
    airlineLink: 'https://www.flysaa.com/',
    airlineCheckin: 'https://www.flysaa.com/manage-fly/manage/check-in',
    airlineBaggagePolicy:
      'https://www.flysaa.com/sn/en/flyingSAA/baggage/excessBaggage.html',
    airlineSeating: 'https://www.flysaa.com/manage-fly/manage/check-in',
  },
  {
    carrierImg: carrierImg3,
    label: 'Southern Air Charter',
    id: 'Southern Air Charter',
    carrierContactNo: '1-242-323-7217',
    airlineLink: 'https://www.southernaircharter.com/',
    airlineCheckin: 'https://www.southernaircharter.com/',
    airlineBaggagePolicy: 'https://www.southernaircharter.com/',
    airlineSeating: 'https://www.southernaircharter.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Southern/Mokulele',
    id: 'Southern/Mokulele',
    carrierContactNo: '1-800-329-0485',
    airlineLink: 'https://iflysouthern.com/',
    airlineCheckin: 'https://iflysouthern.com/',
    airlineBaggagePolicy: 'https://iflysouthern.com/',
    airlineSeating: 'https://iflysouthern.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Spanair',
    id: 'Spanair',
    carrierContactNo: '1-888-545-5757',
    airlineLink: 'https://www.spanair.com/',
    airlineCheckin: 'https://www.spanair.com/',
    airlineBaggagePolicy: 'https://www.spanair.com/',
    airlineSeating: 'https://www.spanair.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'SpiceJet',
    id: 'SpiceJet',
    carrierContactNo: '1-888-545-5757',
    airlineLink: 'https://www.spicejet.com/',
    airlineCheckin: 'https://book.spicejet.com/Search.aspx?op=4',
    airlineBaggagePolicy: 'https://www.spicejet.com/',
    airlineSeating: 'https://book.spicejet.com/Search.aspx?op=4',
  },
  {
    carrierImg: carrierImg3,
    label: 'Spring Airlines',
    id: 'Spring Airlines',
    carrierContactNo: '011-86-21-95524',
    airlineLink: 'https://en.ch.com/',
    airlineCheckin: 'https://en.ch.com/',
    airlineBaggagePolicy: 'https://en.ch.com/',
    airlineSeating: 'https://en.ch.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Srilankan Airlines',
    id: 'Srilankan Airlines',
    carrierContactNo: '1-94117-77-1979',
    airlineLink: 'https://www.srilankan.com/',
    airlineCheckin: 'https://www.srilankan.com/en_uk/us/?redirect=1#olci',
    airlineBaggagePolicy:
      'https://www.srilankan.com/en_uk/plan-and-book/luggage',
    airlineSeating: 'https://www.srilankan.com/en_uk/us/?redirect=1#olci',
  },
  {
    carrierImg: carrierImg3,
    label: 'Sriwijaya Air',
    id: 'Sriwijaya Air',
    carrierContactNo: '1-94117-77-1979',
    airlineLink: 'https://www.sriwijayaair.co.id/',
    airlineCheckin: 'https://webcheckin.sriwijayaair.co.id/webcheckin/',
    airlineBaggagePolicy: 'https://www.sriwijayaair.co.id/',
    airlineSeating: 'https://webcheckin.sriwijayaair.co.id/webcheckin/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Star Flyer',
    id: 'Star Flyer',
    carrierContactNo: '',
    airlineLink: 'https://www.starflyer.jp/en/',
    airlineCheckin: 'https://www.starflyer.jp/en/',
    airlineBaggagePolicy: 'https://www.starflyer.jp/en/',
    airlineSeating: 'https://www.starflyer.jp/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Star Perúr',
    id: 'Star Perúr',
    carrierContactNo: '1-786-242-7224',
    airlineLink: 'https://www.starperu.com/en/',
    airlineCheckin: 'https://www.starperu.com/en/',
    airlineBaggagePolicy: 'https://www.starperu.com/en/',
    airlineSeating: 'https://www.starperu.com/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Stobart Air',
    id: 'Stobart Air',
    carrierContactNo: '011-35-31-844-7700',
    airlineLink: 'https://www.stobartair.com/',
    airlineCheckin: 'https://www.stobartair.com/',
    airlineBaggagePolicy: 'https://www.stobartair.com/',
    airlineSeating: 'https://www.stobartair.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'SunExpress',
    id: 'SunExpress',
    carrierContactNo: '011-35-31-844-7700',
    airlineLink: 'https://www.sunexpress.com/en/',
    airlineCheckin: 'https://www.sunexpress.com/en/online-check-in/',
    airlineBaggagePolicy: 'https://www.sunexpress.com/en/online-check-in/',
    airlineSeating: 'https://www.sunexpress.com/en/online-check-in/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Sunrise Airways',
    id: 'Sunrise Airways',
    carrierContactNo: '',
    airlineLink: 'https://www.sunriseairways.net/en/',
    airlineCheckin: 'https://www.sunriseairways.net/en/online-check-in/',
    airlineBaggagePolicy: 'https://www.sunriseairways.net/en/online-check-in/',
    airlineSeating: 'https://www.sunriseairways.net/en/online-check-in/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Sunwing Airlines',
    id: 'Sunwing Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.sunwing.ca/',
    airlineCheckin: 'https://www.sunwing.ca/',
    airlineBaggagePolicy: 'https://www.sunwing.ca/',
    airlineSeating: 'https://www.sunwing.ca/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Suparna Airlines',
    id: 'Suparna Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.yzr.com.cn/',
    airlineCheckin:
      'https://www.yzr.com.cn/frontend/onlinecheckin/checkin.action',
    airlineBaggagePolicy: 'https://www.yzr.com.cn',
    airlineSeating:
      'https://www.yzr.com.cn/frontend/onlinecheckin/checkin.action',
  },
  {
    carrierImg: carrierImg3,
    label: 'Surinam Air',
    id: 'Surinam Air',
    carrierContactNo: '1-305-599-1196',
    airlineLink: 'https://www.flyslm.com/en/',
    airlineCheckin:
      'https://iport.travelport.com/ici_4_126_0_51009/(S(ljnicdkvmys0f0xxuwxs2rd3))/slm/en',
    airlineBaggagePolicy: 'https://www.flyslm.com/en/checked-baggage/',
    airlineSeating:
      'https://iport.travelport.com/ici_4_126_0_51009/(S(ljnicdkvmys0f0xxuwxs2rd3))/slm/en',
  },
  {
    carrierImg: carrierImg3,
    label: 'SWISS',
    id: 'SWISS',
    carrierContactNo: '1-877-359-7947',
    airlineLink: 'https://www.swiss.com/us/EN',
    airlineCheckin: 'https://www.swiss.com/us/en/Login/PnrLogin',
    airlineBaggagePolicy: 'https://www.swiss.com/us/EN/prepare/baggage',
    airlineSeating: 'https://www.swiss.com/us/en/Login/PnrLogin',
  },
  {
    carrierImg: carrierImg3,
    label: 'Swoop',
    id: 'Swoop',
    carrierContactNo: '1-587-441-1001',
    airlineLink: 'https://www.flyswoop.com/',
    airlineCheckin: 'https://www.flyswoop.com/check-in/',
    airlineBaggagePolicy: 'https://www.flyswoop.com/',
    airlineSeating: 'https://www.flyswoop.com/check-in/',
  },
  {
    carrierImg: carrierImg3,
    label: '  TUI Airlines Netherlands',
    id: '  TUI Airlines Netherlands',
    carrierContactNo: '1-855-808-4015 ',
    airlineLink: 'https://www.tui.nl/vliegtickets/',
    airlineCheckin: 'https://www.tui.nl/vliegtickets/services/inchecken/',
    airlineBaggagePolicy: ' ',
    airlineSeating: 'https://www.tui.nl/vliegtickets/services/inchecken/ ',
  },
  {
    carrierImg: carrierImg3,
    label: '  Tailwind Airlines',
    id: '  Tailwind Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.tailwind.com.tr/anasayfa',
    airlineCheckin: 'https://www.tailwind.com.tr/travel-information',
    airlineBaggagePolicy: ' ',
    airlineSeating: 'https://www.tailwind.com.tr/travel-information',
  },
  {
    carrierImg: carrierImg3,
    label: '  Tarom',
    id: '  Tarom',
    carrierContactNo: '1-877-359-8276',
    airlineLink: 'https://www.tarom.ro/en',
    airlineCheckin:
      'https://www.tarom.ro/en/flights-and-reservations/online-check-in',
    airlineBaggagePolicy:
      ' https://www.tarom.ro/en/passenger-information/baggage',
    airlineSeating:
      'https://www.tarom.ro/en/flights-and-reservations/online-check-in',
  },
  {
    carrierImg: carrierImg3,
    label: '  Thai Vietjet Air',
    id: '  Thai Vietjet Air',
    carrierContactNo: '',
    airlineLink: 'https://www.vietjetair.com/Sites/Web/en-US/Home',
    airlineCheckin: '',
    airlineBaggagePolicy: ' ',
    airlineSeating: '',
  },
  {
    carrierImg: carrierImg3,
    label: '  Transaero',
    id: '  Transaero',
    carrierContactNo: '1-877-747-1191 ',
    airlineLink: 'https://www.transaero.com/',
    airlineCheckin: '',
    airlineBaggagePolicy: ' ',
    airlineSeating: '',
  },
  {
    carrierImg: carrierImg3,
    label: '  Twin Jet',
    id: '  Twin Jet',
    carrierContactNo: ' ',
    airlineLink: 'https://www.twinjet.fr/en',
    airlineCheckin: ' ',
    airlineBaggagePolicy: ' ',
    airlineSeating: ' ',
  },
  {
    carrierImg: carrierImg3,
    label: '  Ural Airlines',
    id: '  Ural Airlines',
    carrierContactNo: '011-7-343-345-36-45',
    airlineLink: 'https://www.uralairlines.com/en/',
    airlineCheckin: 'https://www.uralairlines.com/en/ ',
    airlineBaggagePolicy: ' ',
    airlineSeating: 'https://www.uralairlines.com/en/',
  },
  {
    carrierImg: carrierImg3,
    label: '  Uzbekistan Airways',
    id: '  Uzbekistan Airways',
    carrierContactNo: '(998 71) 140-02-00',
    airlineLink: 'https://www.uzairways.com/en',
    airlineCheckin: 'https://www.uzairways.com/en',
    airlineBaggagePolicy: ' ',
    airlineSeating: 'https://www.uzairways.com/en',
  },
  {
    carrierImg: carrierImg3,
    label: '  VIVA aerobus',
    id: '  VIVA aerobus',
    carrierContactNo: '1-888-935-9848 ',
    airlineLink: 'https://www.vivaaerobus.com/en',
    airlineCheckin: 'https://www.vivaaerobus.com/en ',
    airlineBaggagePolicy: ' ',
    airlineSeating: 'https://www.vivaaerobus.com/en ',
  },
  {
    carrierImg: carrierImg3,
    label: '  Volaris',
    id: '  Volaris',
    carrierContactNo: '1-855-865-2747',
    airlineLink: 'https://www.volaris.com/',
    airlineCheckin: 'https://www.volaris.com/ ',
    airlineBaggagePolicy: ' ',
    airlineSeating: 'https://www.volaris.com/ ',
  },
  {
    carrierImg: carrierImg3,
    label: '  Wideroes Flyveselskap',
    id: '  Wideroes Flyveselskap',
    carrierContactNo: '011-47-75-11-1111',
    airlineLink: 'https://www.wideroe.no/en',
    airlineCheckin:
      'https://checkin.si.amadeus.net/1ASIHSSCWEBWF/sscwwf/checkin?lnen\'type\'=W&Redirected=true',
    '    airlineBaggagePolicy': ' ',
    airlineSeating:
      'https://checkin.si.amadeus.net/1ASIHSSCWEBWF/sscwwf/checkin?ln=en&type=W&Redirected=true',
  },
  {
    carrierImg: carrierImg3,
    label: '  Wings Air',
    id: '  Wings Air',
    carrierContactNo: ' ',
    airlineLink: 'https://lionair.co.id/',
    airlineCheckin:
      'https://checkin.batikair.com/dx/IWCI/#/check-in/start?locale=en-US',
    airlineBaggagePolicy: ' ',
    airlineSeating:
      'https://checkin.batikair.com/dx/IWCI/#/check-in/start?locale=en-US',
  },
  {
    carrierImg: carrierImg3,
    label: ' T\'way Air',
    id: ' T\'way Air',
    carrierContactNo: '+82 1688 8686',
    airlineLink: 'https://www.twayair.com/main.do?_langCode=EN',
    airlineCheckin:
      'https://www.twayair.com/app/reservation/searchCheckinItinerary',
    airlineBaggagePolicy: ' ',
    airlineSeating:
      'https://www.twayair.com/app/reservation/searchCheckinItinerary',
  },
  {
    carrierImg: carrierImg3,
    label: ' TUI fly',
    id: ' TUI fly',
    carrierContactNo: '+3270220000 ',
    airlineLink: 'https://www.tuifly.be/',
    airlineCheckin: 'https://www.tuifly.be/en/online-check-in',
    airlineBaggagePolicy: ' ',
    airlineSeating: 'https://www.tuifly.be/en/online-check-in ',
  },
  {
    carrierImg: carrierImg3,
    label: ' Tajmyr Airline',
    id: ' Tajmyr Airline',
    carrierContactNo: '',
    airlineLink: 'https://www.nordstar.ru/',
    airlineCheckin: '',
    airlineBaggagePolicy: ' ',
    airlineSeating: '',
  },
  {
    carrierImg: carrierImg3,
    label: ' Tassili Airlines',
    id: ' Tassili Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.tassiliairlines.dz/index.php?lang=fr',
    airlineCheckin: '',
    airlineBaggagePolicy: ' ',
    airlineSeating: '',
  },
  {
    carrierImg: carrierImg3,
    label: ' Thai Airways Intl',
    id: ' Thai Airways Intl',
    carrierContactNo: '1-800-767-3598',
    airlineLink: 'https://www.thaiairways.com/en_US/index.page',
    airlineCheckin: 'https://www.thaiairways.com/en_US/manage/i_checkin.page?',
    airlineBaggagePolicy:
      ' https://www.thaiairways.com/en_US/plan_my_trip/travel_information/Baggage.page?',
    airlineSeating: 'https://www.thaiairways.com/en_US/manage/i_checkin.page?',
  },
  {
    carrierImg: carrierImg3,
    label: ' Thomas Cook Airlines',
    id: ' Thomas Cook Airlines',
    carrierContactNo: '1-855-759-3665',
    airlineLink: 'https://www.thomascookairlines.com/',
    airlineCheckin:
      'https://www.thomascookairlines.com/tcibe/tcauk/en/mybooking/login',
    airlineBaggagePolicy: ' ',
    airlineSeating:
      'https://www.thomascookairlines.com/tcibe/tcauk/en/mybooking/login',
  },
  {
    carrierImg: carrierImg3,
    label: ' Thomson Airways',
    id: ' Thomson Airways',
    carrierContactNo: '',
    airlineLink: 'https://www.tui.co.uk/flight/',
    airlineCheckin:
      'https://www.tui.co.uk/destinations/your-account/managemybooking/login',
    airlineBaggagePolicy: ' ',
    airlineSeating:
      'hhttps://www.tui.co.uk/destinations/your-account/managemybooking/login',
  },
  {
    carrierImg: carrierImg3,
    label: ' Tigerair',
    id: ' Tigerair',
    carrierContactNo: '',
    airlineLink: 'https://tigerair.com.au/',
    airlineCheckin: 'https://webcheck.tigerair.com.au/',
    airlineBaggagePolicy: ' ',
    airlineSeating: 'https://webcheck.tigerair.com.au/',
  },
  {
    carrierImg: carrierImg3,
    label: ' Tigerair Taiwan',
    id: ' Tigerair Taiwan',
    carrierContactNo: '+886 2 5599 2555 ',
    airlineLink: 'https://www.tigerairtw.com/en/',
    airlineCheckin: 'https://ec.tigerairtw.com/en/flight/check-in/login',
    airlineBaggagePolicy:
      'https://www.tigerairtw.com/en/welcome-on-board/baggage ',
    airlineSeating: 'https://ec.tigerairtw.com/en/flight/check-in/login',
  },
  {
    carrierImg: carrierImg3,
    label: ' Tradewind Aviation',
    id: ' Tradewind Aviation',
    carrierContactNo: '1-800-376-7922 ',
    airlineLink: 'https://www.flytradewind.com/',
    airlineCheckin: 'https://www.flytradewind.com/',
    airlineBaggagePolicy: ' ',
    airlineSeating: 'https://www.flytradewind.com/',
  },
  {
    carrierImg: carrierImg3,
    label: ' Trans American Airlines/TACA Peru (Avianca Peru)',
    id: ' Trans American Airlines/TACA Peru (Avianca Peru)',
    carrierContactNo: ' ',
    airlineLink: 'https://www.avianca.com/ca/en/',
    airlineCheckin: '',
    airlineBaggagePolicy: ' ',
    airlineSeating: '',
  },
  {
    carrierImg: carrierImg3,
    label: ' Transavia Airlines',
    id: ' Transavia Airlines',
    carrierContactNo: ' ',
    airlineLink: 'https://www.transavia.com/',
    airlineCheckin:
      'https://www.transavia.com/en-EU/my-transavia/account/logon/',
    airlineBaggagePolicy: ' ',
    airlineSeating:
      'https://www.transavia.com/en-EU/my-transavia/account/logon/',
  },
  {
    carrierImg: carrierImg3,
    label: ' Turpial Airlines',
    id: ' Turpial Airlines',
    carrierContactNo: ' ',
    airlineLink: 'https://turpialairlines.com/',
    airlineCheckin: 'https://turpialairlines.com/ ',
    airlineBaggagePolicy: ' ',
    airlineSeating: 'https://turpialairlines.com/ ',
  },
  {
    carrierImg: carrierImg3,
    label: ' UTAir Ukraine',
    id: ' UTAir Ukraine',
    carrierContactNo: '',
    airlineLink: 'https://www.utair.ru/en/',
    airlineCheckin: '',
    airlineBaggagePolicy: ' ',
    airlineSeating: '',
  },
  {
    carrierImg: carrierImg3,
    label: ' Ukraine International Airlines',
    id: ' Ukraine International Airlines',
    carrierContactNo: '1-800-876-0114',
    airlineLink: 'https://www.uniair.com.tw/uniweb/index.aspx',
    airlineCheckin: ' https://ocki.flyuia.com/checkin/',
    airlineBaggagePolicy: 'https://www.flyuia.com/ua/en/information/baggage ',
    airlineSeating: 'https://ocki.flyuia.com/checkin/ ',
  },
  {
    carrierImg: carrierImg3,
    label: ' Vanilla Air',
    id: ' Vanilla Air',
    carrierContactNo: '',
    airlineLink: 'https://www.vanilla-air.com/en/',
    airlineCheckin: 'https://www.vanilla-air.com/en/guide/checkin',
    airlineBaggagePolicy: ' ',
    airlineSeating: 'https://www.vanilla-air.com/en/guide/checkin',
  },
  {
    carrierImg: carrierImg3,
    label: ' VietJet Air',
    id: ' VietJet Air',
    carrierContactNo: '84-1900-1886',
    airlineLink: 'https://www.vietjetair.com/Sites/Web/en-US/Home',
    airlineCheckin:
      'https://booking.vietjetair.com/SearchResCheckin.aspx?lang=en',
    airlineBaggagePolicy: ' ',
    airlineSeating:
      'https://booking.vietjetair.com/SearchResCheckin.aspx?lang=en',
  },
  {
    carrierImg: carrierImg3,
    label: ' Vistara',
    id: ' Vistara',
    carrierContactNo: ' ',
    airlineLink: 'https://www.airvistara.com/trip/',
    airlineCheckin:
      'https://www.airvistara.com/trip/vistara-experience/on-ground/check-in',
    airlineBaggagePolicy: ' ',
    airlineSeating:
      'https://www.airvistara.com/trip/vistara-experience/on-ground/check-in ',
  },
  {
    carrierImg: carrierImg3,
    label: ' Viva Air Perú',
    id: ' Viva Air Perú',
    carrierContactNo: ' ',
    airlineLink: 'https://www.vivaair.com/pe',
    airlineCheckin: 'https://www.vivaair.com/pe/es ',
    airlineBaggagePolicy: ' ',
    airlineSeating: 'https://www.vivaair.com/pe/es ',
  },
  {
    carrierImg: carrierImg3,
    label: ' Volotea',
    id: ' Volotea',
    carrierContactNo: ' ',
    airlineLink: 'https://www.volotea.com/en',
    airlineCheckin: ' ',
    airlineBaggagePolicy: ' ',
    airlineSeating: ' ',
  },
  {
    carrierImg: carrierImg3,
    label: ' Vueling Airlines',
    id: ' Vueling Airlines',
    carrierContactNo: '1-34931-518-158 ',
    airlineLink: 'https://www.vueling.com/en',
    airlineCheckin: 'https://www.vueling.com/en ',
    airlineBaggagePolicy:
      'https://www.vueling.com/en/vueling-services/prepare-your-trip/luggage ',
    airlineSeating: 'https://www.vueling.com/en ',
  },
  {
    carrierImg: carrierImg3,
    label: ' Windward Island Airways Intl',
    id: ' Windward Island Airways Intl',
    carrierContactNo: '1-721-545-4237',
    airlineLink: 'https://www.fly-winair.sx/',
    airlineCheckin: 'https://www.fly-winair.sx/checkin.html',
    airlineBaggagePolicy: 'https://www.fly-winair.sx/baggage.html ',
    airlineSeating: 'https://www.fly-winair.sx/checkin.html',
  },
  {
    carrierImg: carrierImg3,
    label: ' Wizz Air',
    id: ' Wizz Air',
    carrierContactNo: ' ',
    airlineLink: 'https://wizzair.com/',
    airlineCheckin:
      'https://wizzair.com/en-gb/information-and-services/booking-information/check-inand\'boarding\'#/',
    airlineBaggagePolicy: ' ',
    airlineSeating:
      'https://wizzair.com/en-gb/information-and-services/booking-information/check-in-and-boarding#/',
  },
  {
    carrierImg: carrierImg3,
    label: ' Yamal Airlines',
    id: ' Yamal Airlines',
    carrierContactNo: ' ',
    airlineLink: 'https://www.yamal.aero/',
    airlineCheckin: ' ',
    airlineBaggagePolicy: ' ',
    airlineSeating: ' ',
  },
  {
    carrierImg: carrierImg3,
    label: 'TACA International',
    id: 'TACA International',
    carrierContactNo: '1-800-284-2622',
    airlineLink: 'https://www.avianca.com/us/en/',
    airlineCheckin: 'https://www.avianca.com/us/en/your-booking/check-in/',
    airlineBaggagePolicy:
      'https://www.avianca.com/us/en/before-your-trip/prepare-your-baggage/ ',
    airlineSeating: 'https://www.avianca.com/us/en/your-booking/check-in/',
  },
  {
    carrierImg: carrierImg3,
    label: 'TACV Cabo Verde Airlines',
    id: 'TACV Cabo Verde Airlines',
    carrierContactNo: '011-351-213-230-527',
    airlineLink: 'https://www.tailwind.com.tr/anasayfa',
    airlineCheckin: 'https://caboverdeairlines.com/check-in/',
    airlineBaggagePolicy: ' ',
    airlineSeating: 'https://caboverdeairlines.com/check-in/',
  },
  {
    carrierImg: carrierImg3,
    label: 'TAME',
    id: 'TAME',
    carrierContactNo: '1-800-430-2471',
    airlineLink: 'https://tame.com.ec/index.php/es/',
    airlineCheckin: 'https://icheck.sita.aero/iCheckWebEQ/Home',
    airlineBaggagePolicy:
      ' https://www.tarom.ro/en/passenger-information/baggage',
    airlineSeating: 'https://icheck.sita.aero/iCheckWebEQ/Home',
  },
  {
    carrierImg: carrierImg3,
    label: 'THAI Smile',
    id: 'THAI Smile',
    carrierContactNo: '',
    airlineLink: 'https://www.thaismileair.com/',
    airlineCheckin: '',
    airlineBaggagePolicy: ' ',
    airlineSeating: '',
  },
  {
    carrierImg: carrierImg3,
    label: 'TRIP Linhas Aereas',
    id: 'TRIP Linhas Aereas',
    carrierContactNo: ' ',
    airlineLink: 'https://www.voetrip.com.br/default_new.aspx',
    airlineCheckin: '',
    airlineBaggagePolicy: ' ',
    airlineSeating: ' ',
  },
  {
    carrierImg: carrierImg3,
    label: 'TUIfly',
    id: 'TUIfly',
    carrierContactNo: ' ',
    airlineLink: 'https://en.tuifly.com/',
    airlineCheckin: 'https://www.tuifly.com/en/webcheckin/login',
    airlineBaggagePolicy: ' ',
    airlineSeating: 'https://www.tuifly.com/en/webcheckin/login ',
  },
  {
    carrierImg: carrierImg3,
    label: 'Taag Angola Airlines',
    id: 'Taag Angola Airlines',
    carrierContactNo: '011-244-22-263-2811',
    airlineLink: 'https://www.taag.com/en/',
    airlineCheckin: 'https://icheck.sita.aero/iCheckWebDT/',
    airlineBaggagePolicy: ' ',
    airlineSeating: 'https://icheck.sita.aero/iCheckWebDT/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Thai AirAsia',
    id: 'Thai AirAsia',
    carrierContactNo: '1-844-727-4588',
    airlineLink: 'https://www.airasia.com/',
    airlineCheckin: 'https://www.airasia.com/check-in/en/gb',
    airlineBaggagePolicy: ' ',
    airlineSeating: 'https://www.airasia.com/check-in/en/gb',
  },
  {
    carrierImg: carrierImg3,
    label: 'Tianjin Airlines',
    id: 'Tianjin Airlines',
    carrierContactNo: '',
    airlineLink: 'https://global.tianjin-air.com/GB/GB/Home',
    airlineCheckin: 'https://global.tianjin-air.com/GB/GB/COSN',
    airlineBaggagePolicy: ' ',
    airlineSeating: 'https://global.tianjin-air.com/GB/GB/COSN',
  },
  {
    carrierImg: carrierImg3,
    label: 'Tibet Airlines',
    id: 'Tibet Airlines',
    carrierContactNo: '',
    airlineLink: 'https://www.tibetairlines.com.cn/',
    airlineCheckin: 'https://www.tibetairlines.com.cn/stdair/webckipe/webckipe',
    airlineBaggagePolicy: ' ',
    airlineSeating: 'https://www.tibetairlines.com.cn/stdair/webckipe/webckipe',
  },
  {
    carrierImg: carrierImg3,
    label: 'Transavia France',
    id: 'Transavia France',
    carrierContactNo: ' ',
    airlineLink: 'https://www.transavia.com/en-EU/home/',
    airlineCheckin:
      'https://www.transavia.com/en-EU/my-transavia/check-in/login/',
    airlineBaggagePolicy: ' ',
    airlineSeating:
      'https://www.transavia.com/en-EU/my-transavia/check-in/login/ ',
  },
  {
    carrierImg: carrierImg3,
    label: 'Transnusa',
    id: 'Transnusa',
    carrierContactNo: ' ',
    airlineLink: 'https://www.transnusa.co.id/transnusa-2u/en/home/index.html',
    airlineCheckin: 'https://www.transnusa.co.id/Pages',
    airlineBaggagePolicy: ' ',
    airlineSeating: 'https://www.transnusa.co.id/Pages ',
  },
  {
    carrierImg: carrierImg3,
    label: 'Tropic Air',
    id: 'Tropic Air',
    carrierContactNo: ' ',
    airlineLink: 'https://www.tropicair.com/',
    airlineCheckin: 'https://www.tropicair.com/',
    airlineBaggagePolicy: ' ',
    airlineSeating: 'https://www.tropicair.com/ ',
  },
  {
    carrierImg: carrierImg3,
    label: 'Tunis Air',
    id: 'Tunis Air',
    carrierContactNo: '1-216-70-10-37-00 ',
    airlineLink:
      'https://www.tunisair.com/site/publish/content/default.asp?lang=en',
    airlineCheckin:
      'https://www.tunisair.com.tn/site/template/EN/SSCI_ATC/webmobilecheckin_EN.html ',
    airlineBaggagePolicy: '',
    airlineSeating:
      'https://www.tunisair.com.tn/site/template/EN/SSCI_ATC/webmobilecheckin_EN.html ',
  },
  {
    carrierImg: carrierImg3,
    label: 'Turkish Airlines',
    id: 'Turkish Airlines',
    carrierContactNo: '1-800-874-8875 ',
    airlineLink: 'https://turpialairlines.com/',
    airlineCheckin:
      'https://www.turkishairlines.com/en-int/flights/manage-booking/ ',
    airlineBaggagePolicy:
      'https://www.turkishairlines.com/en-int/any-questions/baggage-information/#tcm508-100618 ',
    airlineSeating:
      'https://www.turkishairlines.com/en-int/flights/manage-booking/ ',
  },
  {
    carrierImg: carrierImg3,
    label: 'Tus Airways',
    id: 'Tus Airways',
    carrierContactNo: ' ',
    airlineLink: 'https://www.tusairways.com/',
    airlineCheckin: ' ',
    airlineBaggagePolicy: ' ',
    airlineSeating: ' ',
  },
  {
    carrierImg: carrierImg3,
    label: 'UNI Airways',
    id: 'UNI Airways',
    carrierContactNo: '011-886-2-2513-5555',
    airlineLink: 'https://www.uniair.com.tw/uniweb/index.aspx',
    airlineCheckin: ' https://www.uniair.com.tw/uniweb/index.aspx',
    airlineBaggagePolicy: ' ',
    airlineSeating: 'https://www.uniair.com.tw/uniweb/index.aspx ',
  },
  {
    carrierImg: carrierImg3,
    label: 'US Airways',
    id: 'US Airways',
    carrierContactNo: '1-800-428-4322',
    airlineLink: 'https://www.usair.com/',
    airlineCheckin: ' ',
    airlineBaggagePolicy: ' ',
    airlineSeating: '',
  },
  {
    carrierImg: carrierImg3,
    label: 'UTair Aviation',
    id: 'UTair Aviation',
    carrierContactNo: '011-7-3452-298-888',
    airlineLink: 'https://www.utair.ru/en/',
    airlineCheckin: 'https://www.utair.ru/en/check-in',
    airlineBaggagePolicy: ' ',
    airlineSeating: '',
  },
  {
    carrierImg: carrierImg3,
    label: 'United Airways',
    id: 'United Airways',
    carrierContactNo: '011-880-2-890-1329',
    airlineLink: 'https://www.uabdl.com/',
    airlineCheckin: ' ',
    airlineBaggagePolicy: ' ',
    airlineSeating: ' ',
  },
  {
    carrierImg: carrierImg3,
    label: 'VI Air Ltd',
    id: 'VI Air Ltd',
    carrierContactNo: '',
    airlineLink: 'https://viairlink.com/',
    airlineCheckin: 'https://viairlink.com/',
    airlineBaggagePolicy: ' ',
    airlineSeating: 'https://viairlink.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'VIM Airlines',
    id: 'VIM Airlines',
    carrierContactNo: '011-7-499-271-0333',
    airlineLink: 'https://www.vim-avia.com/',
    airlineCheckin: '',
    airlineBaggagePolicy: '',
    airlineSeating: '',
  },
  {
    carrierImg: carrierImg3,
    label: 'VLM Airlines Slovenia',
    id: 'VLM Airlines Slovenia',
    carrierContactNo: ' ',
    airlineLink: 'https://www.flyvlm.com/en',
    airlineCheckin: ' ',
    airlineBaggagePolicy: ' ',
    airlineSeating: ' ',
  },
  {
    carrierImg: carrierImg3,
    label: 'ViaAir',
    id: 'ViaAir',
    carrierContactNo: '1-407-499-4992',
    airlineLink: 'https://www.flyviaair.com/',
    airlineCheckin: 'https://www.flyviaair.com/',
    airlineBaggagePolicy: ' ',
    airlineSeating: 'https://www.flyviaair.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Vietnam Airlines',
    id: 'Vietnam Airlines',
    carrierContactNo: '1-866-677-8909',
    airlineLink: 'https://www.vim-avia.com/',
    airlineCheckin: 'https://www.vietnamairlines.com/us/en/home',
    airlineBaggagePolicy:
      'https://www.vietnamairlines.com/us/en/travel-information/baggage/baggage-allowance',
    airlineSeating: 'https://www.vietnamairlines.com/us/en/home',
  },
  {
    carrierImg: carrierImg3,
    label: 'Virgin America',
    id: 'Virgin America',
    carrierContactNo: '1-877-359-8474',
    airlineLink: 'https://www.virginamerica.com/',
    airlineCheckin: '',
    airlineBaggagePolicy: '',
    airlineSeating: '',
  },
  {
    carrierImg: carrierImg3,
    label: 'Virgin Atlantic',
    id: 'Virgin Atlantic',
    carrierContactNo: '1-800-862-8621 ',
    airlineLink: 'https://www.virginatlantic.com/us/en',
    airlineCheckin: 'https://www.virginatlantic.com/us/en',
    airlineBaggagePolicy:
      'https://flywith.virginatlantic.com/gb/en/prepare-to-fly/baggage.html ',
    airlineSeating: 'https://www.virginatlantic.com/us/en',
  },
  {
    carrierImg: carrierImg3,
    label: 'Virgin Australia International',
    id: 'Virgin Australia International',
    carrierContactNo: '1-855-253-8021 ',
    airlineLink: 'https://www.virginaustralia.com/us/en/',
    airlineCheckin: 'https://www.virginaustralia.com/us/en/',
    airlineBaggagePolicy:
      'https://www.virginaustralia.com/us/en/plan/baggage/ ',
    airlineSeating: 'https://www.virginaustralia.com/us/en/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Vision Airlines',
    id: 'Vision Airlines',
    carrierContactNo: '1-877-359-2538',
    airlineLink: 'https://www.visionairlines.com/',
    airlineCheckin: ' ',
    airlineBaggagePolicy: ' ',
    airlineSeating: ' ',
  },
  {
    carrierImg: carrierImg3,
    label: 'WOW Air',
    id: 'WOW Air',
    carrierContactNo: '1-866-512-8364',
    airlineLink: 'https://wowair.us/',
    airlineCheckin: 'https://wowair.us/',
    airlineBaggagePolicy: ' ',
    airlineSeating: 'https://wowair.us/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Wamos Air',
    id: 'Wamos Air',
    carrierContactNo: ' ',
    airlineLink: 'https://www.wamosair.com/en/',
    airlineCheckin: ' ',
    airlineBaggagePolicy: ' ',
    airlineSeating: ' ',
  },
  {
    carrierImg: carrierImg3,
    label: 'Wingo',
    id: 'Wingo',
    carrierContactNo: '1-321-445-5799',
    airlineLink: 'https://www.wingo.com/en',
    airlineCheckin: 'https://webcheckin.wingo.com/webcheckin/en',
    airlineBaggagePolicy: ' ',
    airlineSeating: 'https://webcheckin.wingo.com/webcheckin/en',
  },
  {
    carrierImg: carrierImg3,
    label: 'XL Airways',
    id: 'XL Airways',
    carrierContactNo: ' 1-877-496-9889 ',
    airlineLink: 'https://www.xl.com/us',
    airlineCheckin: 'https://www.xl.com/us/check-online ',
    airlineBaggagePolicy: ' ',
    airlineSeating: 'https://www.xl.com/us/check-online ',
  },
  {
    carrierImg: carrierImg3,
    label: 'Xiamen Airlines',
    id: 'Xiamen Airlines',
    carrierContactNo: ' 1-86-592-222-6666 ',
    airlineLink: 'https://www.xiamenair.com/en-us/',
    airlineCheckin: 'https://www.xiamenair.com/en-us/',
    airlineBaggagePolicy: ' ',
    airlineSeating: 'https://www.xiamenair.com/en-us/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Yakutia Airlines',
    id: 'Yakutia Airlines',
    carrierContactNo: ' ',
    airlineLink: 'https://www.yakutia.aero/en',
    airlineCheckin: ' ',
    airlineBaggagePolicy: ' ',
    airlineSeating: ' ',
  },
  {
    carrierImg: carrierImg3,
    label: 'Yanair',
    id: 'Yanair',
    carrierContactNo: ' ',
    airlineLink: 'https://www.yanair.ua/',
    airlineCheckin: ' ',
    airlineBaggagePolicy: ' ',
    airlineSeating: ' ',
  },
  {
    carrierImg: carrierImg3,
    label: 'Yemenia Yemen Airways',
    id: 'Yemenia Yemen Airways',
    carrierContactNo: '1-718-254-5867',
    airlineLink: 'https://yemenia.sita.aero/itd/itd',
    airlineCheckin: 'https://yemenia.sita.aero/itd/itd',
    airlineBaggagePolicy: ' ',
    airlineSeating: 'https://yemenia.sita.aero/itd/itd',
  },
  {
    carrierImg: carrierImg3,
    label: 'Yeti Airlines',
    id: 'Yeti Airlines',
    carrierContactNo: ' ',
    airlineLink: 'https://www.yetiairlines.com/',
    airlineCheckin: 'https://www.yetiairlines.com/',
    airlineBaggagePolicy: ' ',
    airlineSeating: 'https://www.yetiairlines.com/',
  },
  {
    carrierImg: carrierImg3,
    label: 'Zexus Air',
    id: 'Zexus Air',
    carrierContactNo: ' ',
    airlineLink: 'https://www.zoomair.in/',
    airlineCheckin: 'https://www.zoomair.co.in/WebCheckin/SearchPax.aspx',
    airlineBaggagePolicy: ' ',
    airlineSeating: 'https://www.zoomair.co.in/WebCheckin/SearchPax.aspx',
  },
];
