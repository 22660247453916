import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getCurrencyAndLanguage } from '../../../Util/util';
import moment from 'moment'
import Button from '../../../Component/FormComponent/Button';
import CancelPolicyModal from './CancelPolicyModal.jsx';
import AdditionalPolicyModal from './AdditionalPolicyModal.jsx';
import { checkHotelAvability } from '../../../Stores/Actions/hotelActions'
import { useDispatch } from 'react-redux';
import { ErrorModel } from '../../../Assets/scss/components/ErrorModal/ErrorModal'
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import ResortFeeImg from '../../../Assets/img/profile/warning.svg';
import { CARD_TYPES } from '../../../Util/constants.js'
import { ReactComponent as Lock } from "../../../Assets/img/icons/lock-s.svg";

function RoomPriceInfo(props) {

  const {
    selectedBoardData,
    roomdata,
    setShowUpgradeModal
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showNightlyPrice, setShowNightlyPrice] = useState(false);
  const [showMemberPrice, setShowMemberPrice] = useState(false);
  const [cancelPolicyModal, setCancelPolicyModal] = useState(false);
  const [additionalPolicyModal, setAdditionalPolicyModal] = useState(false);
  const { hotel_id } = useParams();
  const { currency } = getCurrencyAndLanguage();

  // const isFreemiumUser = selectedBoardData?.price_detail?.price?.card_type === CARD_TYPES.FREEMIUM;
  const isFreemiumUser = selectedBoardData?.price_detail?.member_price ? true : false;

  const { number_of_rooms, number_of_nights } = roomdata;

  const {
    additional_info,
    cancellation_policy,
    cancellation_policy_v2,
    board_price_currency,
    id, // room_id
  } = selectedBoardData || {};

  const {
    base_rate,
    tax,
    total_with_tax,
    savings_percentage,
    savings,
    retail_price,
    mandatory_fees,
    per_night_savings,
    per_night_retail_price,
    per_night_base_rate,
    per_night_tax,
    per_night_total_with_tax,
    free_cancellation_date,
  } = showMemberPrice ? (selectedBoardData?.price_detail?.member_price || {}) : (selectedBoardData?.price_detail?.price || {}); // price from parent componant

  const prices = {
    savings_percentage: savings_percentage,
    free_cancellation_date: free_cancellation_date,
    currency: board_price_currency,
    mandatory_fees: mandatory_fees,
    savings_ammount: showNightlyPrice ? per_night_savings : savings,
    publicPrice: showNightlyPrice ? per_night_retail_price : retail_price,
    fromPrice: showNightlyPrice ? per_night_total_with_tax : total_with_tax,
    totalPriceToPay: showNightlyPrice ? per_night_total_with_tax : total_with_tax,
    tax: showNightlyPrice ? per_night_tax : tax,
    roomMultiNights: showNightlyPrice ? per_night_base_rate : base_rate
  };

  const closeCancelPolicyModal = () => {
    setCancelPolicyModal(false);
  };

  const closeAdditionalPolicyModal = () => {
    setAdditionalPolicyModal(false);
  };

  const [isSelectedHotelSoldOut, setIsSeletectedHotelSoldOut] = useState(null);

  const [validatingLoader, setValidatingLoader] = useState(false)

  const [errorModal, setErrorModal] = useState({
    open: false,
    message: '',
  })

  const antIcon = (<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />);

  const validatePrice = () => {
    setValidatingLoader(true)
    const checkHotelAvabilityPayload = {
      room_ids: [id],
      currency: currency,
      // locale: language?.code || 'en-US',
    };
    dispatch(checkHotelAvability(
      checkHotelAvabilityPayload,
      hotel_id
    )).then((res) => {
      setValidatingLoader(false);
      if (res?.status === 200) {
        localStorage.setItem('hotelDataForCheckout', JSON.stringify(res?.data));
        navigate(`/hotel-checkout/${hotel_id}/${id}`);
      } else if (res?.status === 400 && res?.data?.sold_out) {
        setIsSeletectedHotelSoldOut(res.data?.message) // render sold out UI
      } else {    // open modal with error message (eg. session expired error)
        setErrorModal({
          open: true,
          message: res?.data?.message,
        })
      }
    });
  }

  // ----------- check if hotel is sold out then return following Sold out UI -----------
  if (isSelectedHotelSoldOut) {
    return (
      <div className="col price-box-col sold-out-wrap">
        <div className="infoBox box-info">
          <h2> Sold out </h2>
          <h4>You just missed it!</h4>
          <p>{isSelectedHotelSoldOut}</p>
        </div>
      </div>
    )
  }

  // ----------- if hotel is not sold out or we have'nt validate hotel then return following price Card UI -----------
  const isFreemium =
    process.env.REACT_APP_FREMIUM_USER_TYPE_ID ==
    JSON.parse(localStorage?.user)?.type_id;
  return (
    <>
      <ErrorModel
        openErrorModal={errorModal.open}
        handleErrorModal={() => setErrorModal({ open: false, message: '' })}
        message={errorModal.message}
        sessionExpireError={true}
        type="hotel"
      />

      <div className="col price-box-col">
        <div className="infoBox box-info">
          <div className="title d-flex">
            <Button
              className={`nightlyPriceTab ${showNightlyPrice ? 'active' : ''}`}
              onClick={() => setShowNightlyPrice(true)}
            >
              Nightly Price
            </Button>
            <Button
              className={`totalPriceTab ${!showNightlyPrice ? 'active' : ''}`}
              onClick={() => setShowNightlyPrice(false)}
            >
              Total Price
            </Button>
          </div>
          <div className="row no-gutters ">
            <div className="col info">
              <div className="infoBoxWrapper">
                <div
                  className={`price-container activeTab`}
                >
                  {parseFloat(savings_percentage) > 0 && <div className="dflex publicPrice">
                    <span className="lightText">Public Price:</span>
                    <span className="font14">{prices?.currency}{prices?.publicPrice}</span>
                  </div>}
                  <div>
                    <div
                      className={`dflex btmSpace fromPrice ${Number(prices?.savings_ammount || 0) > 0 ? '' : 'singlePrice'}`}
                    >
                      <div className="fromPriceWrap">
                        <span className={`font17 lightText ${showMemberPrice && 'from-text-label'}`}>You Pay:</span>
                        <p className="btmSpace lightText">
                          ({number_of_nights} {number_of_nights > 1 ? 'nights' : 'night'}  incl. taxes &amp; fees)
                        </p>
                      </div>

                      <div className="from-details-price">

                        <span className={`font17 ${showMemberPrice && 'from-text-label'}`}>
                          {prices?.currency}{prices?.fromPrice}
                        </span>

                        {/* Price Details MobileView */}
                        <div className="desktopViewDisplayNone">
                          <div className="priceDetailsWrapper">
                            <div className="priceDetails d-flex align-items-center">
                              <h4>Price Details</h4>
                              <i className="fa fa-angle-down"></i>
                              <div className="pricedetail-dropdown">
                                <div className="dropdown-box">
                                  <div
                                    className="dropdown-content"
                                    style={{ borderBottom: Number(prices?.savings_ammount || 0) > 0 ? '' : 'none' }}
                                  >
                                    {prices?.free_cancellation_date && (
                                      <div className="note">
                                        <p>
                                          Free cancellation before{' '}
                                          {moment(prices?.free_cancellation_date).format(
                                            'ddd, MMMM DD'
                                          )}
                                        </p>
                                      </div>
                                    )}
                                    <div className="dropdown-info">
                                      <h3>Price Details</h3>
                                      <h4>
                                        {`${number_of_rooms} Room${number_of_rooms > 1 ? 's' : ''} X ${number_of_nights === 1
                                          ? '1 Night'
                                          : `${number_of_nights} Night${number_of_nights > 1 ? 's' : ''
                                          }`
                                          }`}
                                        <span>
                                          {prices?.currency}{prices?.roomMultiNights}
                                        </span>
                                      </h4>
                                      {parseFloat(prices?.tax) !== 0 && <h4>
                                        Taxes and Fees<span>{prices?.currency}{prices?.tax}</span>
                                      </h4>}
                                      <div className="total-amount">
                                        <h4>
                                          <strong className="total-text">Total:</strong>
                                          <span>{prices?.currency}{prices?.totalPriceToPay}</span>
                                        </h4>
                                      </div>
                                      {prices?.savings_percentage ?
                                        <div className="saving-box">
                                          {
                                            <h4>
                                              Savings ({prices?.savings_percentage}):
                                              <span>{prices?.currency}{prices?.savings_ammount}</span>
                                            </h4>
                                          }
                                        </div>
                                        : ''
                                      }
                                    </div>
                                    {prices?.mandatory_fees?.length && (
                                      <div className="resortFeessection">
                                        <h3>
                                          <span className="circle">
                                            <img src={ResortFeeImg} alt="icon" />
                                          </span>
                                          <strong>
                                            <strong>{`Resort Fees per Room: ${prices?.mandatory_fees[0]?.currency} ${prices?.mandatory_fees[0]?.amount}`}</strong>
                                          </strong>
                                        </h3>
                                        <p>
                                          (<strong>NOT</strong> &nbsp;included in the price, due at the Hotel)
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Price Details Desktop View */}
                  <div
                    className={`mobileViewDisplayNone ${Number(prices?.savings_ammount || 0) > 0 ? '' : 'singlePrice'}`}
                  >
                    <div className="priceDetailsWrapper">
                      <div className="priceDetails d-flex align-items-center">
                        <h4>Price Details</h4>
                        <i className="fa fa-angle-down"></i>
                        <div className="pricedetail-dropdown">
                          <div className="dropdown-box">
                            <div
                              className="dropdown-content"
                              style={{ borderBottom: Number(prices?.savings_ammount || 0) > 0 ? '' : 'none' }}
                            >
                              {prices?.free_cancellation_date && (
                                <div className="note">
                                  <p>
                                    Free cancellation before{' '}
                                    {moment(prices?.free_cancellation_date).format('ddd, MMMM DD')}
                                  </p>
                                </div>
                              )}
                              <div className="dropdown-info">
                                <h3>Price Details</h3>
                                <h4>
                                  {`${number_of_rooms} Room${number_of_rooms > 1 ? 's' : ''} X ${number_of_nights === 1
                                    ? '1 Night'
                                    : `${number_of_nights} Night${number_of_nights > 1 ? 's' : ''}`
                                    }`}
                                  <span>
                                    {prices?.currency}{prices?.roomMultiNights}
                                  </span>
                                </h4>
                                {parseFloat(prices?.tax) !== 0 && <h4> Taxes and Fees<span>{prices?.currency}{prices?.tax}</span>
                                </h4>}
                                <div className="total-amount">
                                  <h4>
                                    <strong className="total-text">Total:</strong>
                                    <span>{prices?.currency}{prices?.totalPriceToPay}</span>
                                  </h4>
                                </div>
                                {prices?.savings_percentage ?
                                  <div className="saving-box">
                                    <h4>
                                      Savings ({prices?.savings_percentage}):
                                      <span>{prices?.currency}{prices?.savings_ammount}</span>
                                    </h4>
                                  </div>
                                  : ''
                                }

                              </div>
                              {prices?.mandatory_fees?.length > 0 ?
                                <div className="resortFeessection">
                                  <h3>
                                    <span className="circle">
                                      <img src={ResortFeeImg} alt="icon" />
                                    </span>

                                    <strong>{`Resort Fees per Room: ${prices?.mandatory_fees[0]?.currency} ${prices?.mandatory_fees[0]?.amount}`}</strong>
                                  </h3>
                                  <p>
                                    (<strong>NOT</strong>&nbsp;included in the price, due at the Hotel)
                                  </p>
                                </div>
                                : ''}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {!prices?.savings_percentage ? (
                    <div className="dflex blueText btmSpace2 savings savings-empty">
                    </div>
                  ) : (
                    <div className="dflex blueText btmSpace2 savings">
                      <span>Savings ({prices?.savings_percentage}):</span>
                      <span className="font14">{prices?.currency}{prices?.savings_ammount}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col buttonsGroup btn-right">

              {!showMemberPrice && (
                <div className="centerAlign">
                  {isFreemium && parseFloat(prices?.savings_percentage) > 0 && <button
                    disabled={validatingLoader}
                    type="button"
                    className="btn"
                    onClick={() => validatePrice()}
                  >
                    Book Room
                  </button>}
                  {!isFreemium && parseFloat(prices?.savings_percentage) > 0 && <button
                    disabled={validatingLoader}
                    type="button"
                    className="btn"
                    onClick={() => validatePrice()}
                  >
                    Book Room
                  </button>}
                  {!isFreemium && parseFloat(prices?.savings_percentage) <= 0 && <button
                    disabled={validatingLoader}
                    type="button"
                    className="btn"
                    onClick={() => validatePrice()}
                  >
                    Book Room
                  </button>}
                  {validatingLoader && <Spin className="custom-loader" indicator={antIcon} />}
                  {isFreemium && parseFloat(prices?.savings_percentage) <= 0 && <button
                    type="button"
                    onClick={() => setShowUpgradeModal(true)}
                    className="btn"
                  >
                    Book Room <Lock style={{ marginBottom: "-2px" }} />
                  </button>}
                </div>
              )}

              {showMemberPrice &&
                <div className="centerAlign buttonSpace upgradeBtn">
                  <Link to="/membership-upgrade" className="btn">
                    <i className="icon-border-plain"></i>
                    Upgrade
                  </Link>
                  <button
                    className="btn-cancel"
                    onClick={() => {
                      setShowMemberPrice(false);
                    }}>
                    Cancel
                  </button>
                </div>
              }

              {isFreemiumUser && !showMemberPrice &&
                <div className="centerAlign">
                  <Link
                    to="#"
                    className="btnBorder2 btn-see-member-price"
                    onClick={() => {
                      setShowMemberPrice(true);
                    }}
                  >
                    <i className="icon-border-plain"></i>
                    See Member Price
                  </Link>
                </div>
              }

              {
                // cancellation_policy?.cancellation?.length > 0 && (
                <div className="centerAlign cancellation-policy">
                  <button onClick={() => setCancelPolicyModal(true)}>
                    Cancellation Policy
                  </button>
                </div>
                // )
              }

              {
                additional_info && (
                  <div className="centerAlign cancellation-policy">
                    <button onClick={() => setAdditionalPolicyModal(true)}>
                      Additional Info
                    </button>
                  </div>
                )
              }

              <CancelPolicyModal
                isOpen={cancelPolicyModal}
                closeModal={closeCancelPolicyModal}
                policies={cancellation_policy?.cancellation}
                policiesNew={cancellation_policy_v2}
                isRefundable={selectedBoardData?.is_refundable}
              />

              <AdditionalPolicyModal
                isOpen={additionalPolicyModal}
                closeModal={closeAdditionalPolicyModal}
                policies={additional_info}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default RoomPriceInfo;



