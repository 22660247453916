import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import SelectFieldOption from "./FormComponent/SelectFieldOption.jsx";
import InputField from "./FormComponent/InputField";
import Checkbox from "./FormComponent/Checkbox";
import Icomoon from "./FormComponent/Icomoon";
import { commonActions } from "../Stores/Actions";
import { useDispatch, useSelector } from "react-redux";
import { get_age } from "../Util/util";

// eslint-disable-next-line react/display-name
const GuestInformation = forwardRef((props, ref) => {
  const {
    title2Label,
    title2Options,
    guestFirstNamePlaceholder,
    guestFirstNameLabel,
    guestLastNamePlaceholder,
    guestLastNameLabel,
    ageOptions,
    ageLabel,
    adultSelectError,
    setAdultSelectError,
    room_no,
  } = props;

  const dispatch = useDispatch();

  let roomPayload = {};

  if (window.location.href.includes("home-checkout")) {
    roomPayload = window.localStorage.getItem("homePayload") || [];
  } else {
    roomPayload = window.localStorage.getItem("hotelPayload") || [];
  }

  const totalRooms = JSON.parse(roomPayload)?.rooms || [{}];
  const [rooms, setRooms] = useState([]);
  const getGuestData = useSelector((state) => state.commonReducer.getGuestData);

  const isFreemium =
    process.env.REACT_APP_FREMIUM_USER_TYPE_ID ==
    JSON.parse(localStorage?.user)?.type_id;
  const localUser = JSON.parse(localStorage?.user);

  const guestAgeOptions = ageOptions.filter((curr) => {
    if (curr.value > 14) {
      return curr;
    }
  });

  const childAgeOptions = ageOptions.filter((ele) => {
    if (ele.value < 15) {
      return ele;
    }
  });

  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    // if (isFreemium) return;
    dispatch(
      commonActions.getGuestListAction(
        JSON.parse(window.localStorage.getItem("user")).user_id
      )
    );
  }, []);
  // curr.email == JSON.parse(localStorage?.user)?.email ? true :
  useEffect(() => {
    if (!getGuestData) return;
    const res = getGuestData
      ?.map((curr, i) => {
        return {
          first_name: curr.fname,
          last_name: curr.lname,
          salutation: curr?.title,
          age: curr.dob ? get_age(curr.dob)?.toFixed(0) : null,
          is_lead_guest: isFreemium ? true : false,
          email: curr?.email,
          touched: {
            salutation: false,
            first_name: false,
            last_name: false,
            age: false,
          },
        };
      })
      .filter((curr) => {
        if (get_age(curr.dob)?.toFixed(0) > 17 || curr.dob == null) {
          return curr;
        }
      });
    setApiData(res);
  }, [getGuestData]);

  const [lead, setLead] = useState([]);

  useImperativeHandle(ref, () => ({
    getRoomsGuests() {
      const touchAllFields = JSON.parse(JSON.stringify(rooms)).map(
        (curr, i) => {
          curr.adults.map((val) => {
            if (val.touched) {
              val.touched.salutation = true;
              val.touched.first_name = true;
              val.touched.last_name = true;
              val.touched.age = true;
            }
            return val;
          });
          curr.child.map((val) => {
            if (val.touched) {
              val.touched.salutation = true;
              val.touched.first_name = true;
              val.touched.last_name = true;
              val.touched.age = true;
            }
            return val;
          });
          return curr;
        }
      );
      setRooms(touchAllFields);
      return JSON.parse(JSON.stringify(rooms));
    },
  }));

  useEffect(() => {
    let result = [];
    totalRooms?.map((curr, roominx) => {
      const childs = [];
      for (let i = 0; i < curr.children?.length; i++) {
        childs.push({
          first_name: "",
          last_name: "",
          salutation: "",
          age: String(totalRooms[roominx].children[i]) || 0,
          touched: {
            salutation: false,
            first_name: false,
            last_name: false,
            age: false,
          },
          errors: {
            salutation: true,
            first_name: true,
            last_name: true,
            age: Number(totalRooms[roominx]?.children[i]) < 0 ? true : false,
          },
        });
      }
      result.push({
        adults:
          apiData && !isFreemium
            ? [...JSON.parse(JSON.stringify(apiData))]
            : roominx === 0 && apiData
            ? [...JSON.parse(JSON.stringify(apiData))]
            : [],
        child: childs,
        children: [],
        room_id: room_no,
      });

      let final = [{}];
      return final;
    });

    if (!isFreemium && apiData) {
      result.map((currRoom, roomid) => {
        if (roomid == 0) {
          // login user should default lead guest 'true'
          currRoom.adults.map((ele, adultid) => {
            if (ele.email == localUser.email) {
              ele.is_lead_guest = true;
            }
            return ele;
          });
        }
        return currRoom;
      });
    }

    setRooms(result);

    let leadResults = [];
    apiData?.map((ele, i) => {
      if (ele.email == localUser.email && !isFreemium) {
        leadResults.push({ roomLeadOf: 0 });
      } else {
        leadResults.push({ roomLeadOf: null });
      }
    });
    setLead(leadResults);
  }, [apiData]);

  const TouchedHandler = (type, roomId, adultId, name) => {
    // value change handler
    const final = rooms?.map((curr, i) => {
      if (roomId == i && adultId >= apiData?.length) {
        curr[type][adultId].touched[name] = true;
      }
      if (roomId == i && type == "child") {
        curr[type][adultId].touched[name] = true;
      }
      return curr;
    });
    setRooms(final);
  };

  const ChangeHandler = (type, roomId, adultId, name, value) => {
    // value change handler
    const final = rooms.map((curr, i) => {
      if (roomId == i) {
        //change field value
        curr[type][adultId][name] = value;

        //handle error
        if (type == "child" && value.length > 0) {
          curr[type][adultId].errors[name] = false; // remove error
        } else {
          if (type == "child") {
            curr[type][adultId].errors[name] = true; // if value is empty then error is true
          }
        }
      }
      return curr;
    });
    setRooms(final);
  };

  const addLeadGuestHandler = (roomIndex) => {
    // Add Lead Guest

    if (adultSelectError?.index == roomIndex) {
      setAdultSelectError(null);
    }

    const result = rooms.map((curr, i) => {
      if (roomIndex == i) {
        curr.adults.map((ele) => {
          ele.is_lead_guest = false;
          return ele;
        });

        setLead([...lead, { roomLeadOf: null }]);

        curr.adults = [
          ...curr.adults,
          {
            first_name: "",
            last_name: "",
            salutation: "",
            age: "",
            is_lead_guest: true,
            touched: {
              salutation: false,
              first_name: false,
              last_name: false,
              age: false,
            },
          },
        ];
      }
      return curr;
    });
    setRooms(result);
  };

  const removeGuest = (roomId, adultId) => {
    // remove guest from room
    const result = rooms.map((curr, i) => {
      if (roomId == i) {
        curr.adults.splice(adultId, 1);
      }
      return curr;
    });
    setRooms(result);
  };

  const CheckboxHandler = (roomId, adultId, name, value) => {
    if (adultSelectError?.index == roomId) {
      setAdultSelectError(null);
    }

    if (roomId == 0 && !value && !isFreemium) return; // disable uncheck for first room if not freemium

    const result = rooms.map((curr, i) => {
      if (roomId == i) {
        curr.adults.map((ele) => {
          ele.is_lead_guest = false;
          return ele;
        });

        curr.adults[adultId].is_lead_guest = value;

        const leadResult = lead.map((val, id) => {
          if (val.roomLeadOf == roomId) val.roomLeadOf = null;

          if (value && !isFreemium && id == adultId && id < apiData?.length) {
            val.roomLeadOf = roomId;
          } else if (id == adultId) {
            val.roomLeadOf = null;
          }

          return val;
        });
        setLead(leadResult);
      }
      return curr;
    });
    setRooms(result);
  };

  // const titleForChild = [
  //   { value: "Master.", label: "Master." },
  //   { value: "Miss.", label: "Miss." },
  // ];

  return (
    <>
      {rooms?.map((curr, roomIndex) => {
        return (
          <div key={roomIndex} className="card2 roomsInfo">
            <div className="sectionTitle justify-space-between">
              <h2>Room {roomIndex + 1} – Lead Guest Information </h2>
              <p>
                {
                  curr?.adults?.filter(
                    (ele) =>
                      ele?.is_lead_guest &&
                      ele.first_name != "" &&
                      ele.last_name != "" &&
                      ele.salutation != "" &&
                      ele.salutation != null &&
                      ele.age != "" &&
                      ele.age != null
                  ).length
                }{" "}
                / 1 added
              </p>
            </div>
            {/* ///// Adult Start ///// */}
            <div className="section-lead-main-row">
              <div className="d-flex promocodeWrap row">
                {rooms?.length > 0 &&
                  curr?.adults?.map((adult, index) => (
                    <React.Fragment key={index}>
                      {(lead[index].roomLeadOf == null ||
                        lead[index].roomLeadOf == roomIndex) &&
                        !isFreemium && (
                          <div className="d-flex" key={index}>
                            {/* {!isFreemium && */}
                            <Checkbox
                              name="is_lead_guest"
                              value={adult?.is_lead_guest}
                              onChange={() => {
                                CheckboxHandler(
                                  roomIndex,
                                  index,
                                  "is_lead_guest",
                                  !adult.is_lead_guest,
                                  // true,
                                  adult.roomLeadOf
                                );
                              }}
                            />
                            {/* } */}
                            <div className="checkBoxContent">
                              <label
                                className="checkbox-label"
                                htmlFor="checkbox-id"
                                onClick={() => {
                                  CheckboxHandler(
                                    roomIndex,
                                    index,
                                    "is_lead_guest",
                                    !adult?.is_lead_guest,
                                    // true,
                                    adult?.roomLeadOf
                                  );
                                }}
                              >
                                {!isFreemium &&
                                  apiData &&
                                  (index < apiData?.length
                                    ? `${adult.first_name} ${adult.last_name}`
                                    : `Guest ${index - apiData?.length + 1}`)}
                                {(isFreemium || !apiData) &&
                                  `Guest ${index + 1}`}
                              </label>
                            </div>
                          </div>
                        )}
                    </React.Fragment>
                  ))}
              </div>
              {rooms?.length > 0 &&
                curr?.adults?.map((adult, index) => (
                  <React.Fragment key={index}>
                    {(lead[index].roomLeadOf == null ||
                      lead[index].roomLeadOf == roomIndex) && (
                      <div key={index}>
                        {adult.is_lead_guest && (
                          <>
                            {((rooms[roomIndex].adults.length > 0 &&
                              index > apiData?.length - 1) ||
                              !isFreemium ||
                              !apiData) && (
                              <p
                                className="add-traveller-button "
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                }}
                              >
                                <Icomoon className="icon-minus add-traveller-icon" />
                                <span
                                  onClick={() => removeGuest(roomIndex, index)}
                                >
                                  <i className="icon-close"></i>
                                </span>
                              </p>
                            )}

                            <div className="row">
                              <SelectFieldOption
                                name="salutation"
                                label={title2Label}
                                options={title2Options}
                                value={adult.salutation}
                                isSearchable={false}
                                error={
                                  (adult?.salutation?.length == 0 ||
                                    adult?.salutation == null) &&
                                  "Required"
                                }
                                touched={
                                  index < apiData?.length
                                    ? true
                                    : adult?.touched?.salutation
                                }
                                onBlur={(e) => {
                                  TouchedHandler(
                                    "adults",
                                    roomIndex,
                                    index,
                                    e.target.name
                                  );
                                }}
                                onChange={(no_use, name, value) => {
                                  ChangeHandler(
                                    "adults",
                                    roomIndex,
                                    index,
                                    name,
                                    value
                                  );
                                }}
                                disabled={
                                  index < apiData?.length &&
                                  !isFreemium &&
                                  adult?.salutation
                                    ? true
                                    : false
                                }
                              />

                              <InputField
                                name="first_name"
                                label={guestFirstNameLabel}
                                placeholder={guestFirstNamePlaceholder}
                                value={adult?.first_name}
                                error={
                                  adult?.first_name?.length == 0 && "Required"
                                }
                                touched={
                                  index < apiData?.length
                                    ? true
                                    : adult?.touched?.first_name
                                }
                                onBlur={(e) => {
                                  TouchedHandler(
                                    "adults",
                                    roomIndex,
                                    index,
                                    e.target.name
                                  );
                                }}
                                onChange={(e) => {
                                  ChangeHandler(
                                    "adults",
                                    roomIndex,
                                    index,
                                    e.target.name,
                                    e.target.value
                                  );
                                }}
                                disabled={
                                  adult?.first_name?.length > 0 ? true : false
                                }
                              />

                              <InputField
                                name="last_name"
                                label={guestLastNameLabel}
                                placeholder={guestLastNamePlaceholder}
                                value={adult?.last_name}
                                error={
                                  adult?.last_name?.length == 0 && "Required"
                                }
                                touched={
                                  index < apiData?.length
                                    ? true
                                    : adult?.touched?.last_name
                                }
                                onBlur={(e) => {
                                  TouchedHandler(
                                    "adults",
                                    roomIndex,
                                    index,
                                    e.target.name
                                  );
                                }}
                                onChange={(e) => {
                                  ChangeHandler(
                                    "adults",
                                    roomIndex,
                                    index,
                                    e.target.name,
                                    e.target.value
                                  );
                                }}
                                disabled={
                                  adult?.last_name?.length > 0 ? true : false
                                }
                              />

                              <SelectFieldOption
                                name="age"
                                label={ageLabel}
                                options={guestAgeOptions}
                                value={adult?.age}
                                isSearchable={false}
                                error={
                                  (adult?.age?.length == 0 ||
                                    adult?.age == null) &&
                                  "Required"
                                }
                                touched={adult?.touched?.age}
                                onBlur={(e) => {
                                  TouchedHandler(
                                    "adults",
                                    roomIndex,
                                    index,
                                    "age"
                                  );
                                }}
                                onChange={(no_use, name, value) => {
                                  ChangeHandler(
                                    "adults",
                                    roomIndex,
                                    index,
                                    name,
                                    value
                                  );
                                }}
                                disabled={
                                  index < apiData?.length &&
                                  !isFreemium &&
                                  adult.email != localUser.email
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </React.Fragment>
                ))}
            </div>

            {/* //// Adult End ///// */}

            <p className="color-red">
              {" "}
              {adultSelectError?.index == roomIndex && adultSelectError.msg}
            </p>
            {(isFreemium || !apiData || apiData?.length < 1
              ? curr?.adults?.length < 1
              : curr?.adults?.length <= apiData?.length) &&
              curr?.adults?.filter((ele) => ele?.is_lead_guest).length < 1 &&
              (isFreemium ||
                roomIndex != 0 ||
                !apiData ||
                apiData?.length < 1) && (
                // ((curr?.adults.length <= apiData?.length || isFreemium)  && roomIndex != 0) &&
                <div>
                  <p
                    className="add-traveller-button add-guest"
                    onClick={() => addLeadGuestHandler(roomIndex)}
                  >
                    <Icomoon className="icon-add add-traveller-icon" />
                    <span>ADD NEW LEAD GUEST</span>
                  </p>
                </div>
              )}

            {curr?.child?.length > 0 && (
              <div className="sectionTitle justify-space-between child-group-wrap">
                <h2>Room {roomIndex + 1} – Child</h2>{" "}
                {
                  curr?.child?.filter(
                    (ele) =>
                      ele.first_name != "" &&
                      ele.last_name != "" &&
                      ele.salutation != "" &&
                      ele.salutation != null &&
                      ele.age != "" &&
                      ele.age != null
                  ).length
                }{" "}
                / {curr?.child?.length} added
              </div>
            )}
            {/* ////////// Child Start ////////// */}
            {rooms?.length > 0 &&
              curr?.child?.map((child, index) => (
                <div key={index}>
                  <div className="sectionTitle justify-space-between">
                    <h2>Child {index + 1}</h2>
                  </div>

                  <div className="row">
                    <SelectFieldOption
                      name="salutation"
                      label={title2Label}
                      options={title2Options}
                      value={child?.salutation}
                      isSearchable={false}
                      error={child.errors.salutation && "Required"}
                      touched={child?.touched?.salutation}
                      onBlur={(e) => {
                        TouchedHandler(
                          "child",
                          roomIndex,
                          index,
                          e.target.name
                        );
                      }}
                      onChange={(no_use, name, value) => {
                        ChangeHandler("child", roomIndex, index, name, value);
                      }}
                    />
                    <InputField
                      name="first_name"
                      label={guestFirstNameLabel}
                      placeholder={guestFirstNamePlaceholder}
                      value={child?.first_name}
                      error={child.errors.first_name && "Required.."}
                      touched={child?.touched?.first_name}
                      onBlur={(e) => {
                        TouchedHandler(
                          "child",
                          roomIndex,
                          index,
                          e.target.name
                        );
                      }}
                      onChange={(e) => {
                        ChangeHandler(
                          "child",
                          roomIndex,
                          index,
                          e.target.name,
                          e.target.value
                        );
                      }}
                    />

                    <InputField
                      name="last_name"
                      label={guestLastNameLabel}
                      placeholder={guestLastNamePlaceholder}
                      value={child?.last_name}
                      error={child.errors.last_name && "Required"}
                      touched={child?.touched?.last_name}
                      onBlur={(e) => {
                        TouchedHandler(
                          "child",
                          roomIndex,
                          index,
                          e.target.name
                        );
                      }}
                      onChange={(e) => {
                        ChangeHandler(
                          "child",
                          roomIndex,
                          index,
                          e.target.name,
                          e.target.value
                        );
                      }}
                    />
                    <SelectFieldOption
                      name="age"
                      label={ageLabel}
                      options={childAgeOptions}
                      value={child?.age}
                      isSearchable={false}
                      error={child.errors.age && "Required"}
                      touched={child?.touched?.age}
                      onBlur={(e) => {
                        TouchedHandler(
                          "child",
                          roomIndex,
                          index,
                          e.target.name
                        );
                      }}
                      onChange={(no_use, name, value) => {
                        ChangeHandler("child", roomIndex, index, name, value);
                      }}
                    />
                  </div>
                </div>
              ))}
            {/* ///////// child End /////////// */}
          </div>
        );
      })}
    </>
  );
});

export default GuestInformation;
