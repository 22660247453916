import React, { useState } from "react";
import Button from "../../../Component/FormComponent/Button";

function ReviewTab({ tabs, setSelectedTab, selectedTab }) {
  return (
    <ul className="reviewTabs list-unstyled d-flex">
      {tabs?.length &&
        tabs.map((ele, index) => (
          <li className={selectedTab === index ? "active" : ""} key={`${index}_${ele.tab_name}`}>
            <Button onClick={() => setSelectedTab(index)}>
              {ele.tab_name}
            </Button>
          </li>
        ))}
    </ul>
  );
}

export default ReviewTab;
