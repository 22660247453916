import React, { useState, useEffect, useRef } from "react";
import { Formik } from "formik";
import Header from "../../Component/Header";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import creaditCardImage from "../../Assets/img/credit-card.png";
import lockImage from "../../Assets/img/lock.svg";
import image from "../../Assets/img/membership-premium-plan.png";
import LogoWhite from "../../Component/FormComponent/LogoWhite";
import logo from "../../Assets/img/logo.png";
import paypal from "../../Assets/img/checkout/paypal.png";
import applePay from "../../Assets/img/checkout/apple-pay.png";
import googlePay from "../../Assets/img/checkout/google-pay.png";
import wechatPay from "../../Assets/img/checkout/wechat-pay.png";
import bitcoin from "../../Assets/img/checkout/bitcoin.png";
import Icon from "../../Component/FormComponent/Icon";
import { NewUserPremiumCheckoutValidation } from "../../Component/Validation/NewUserPremiumCheckoutValidation.js";
import BillingDetails from "../../Component/BillingDetails";
import TermsCheckbox from "../../Component/TermsCheckbox";
// let checkoutCountries = require("../../Util/static-service")
import plusImg from "../../Assets/img/plus-img.png";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../Stores/Actions/authActions";
import { useNavigate } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars";
import { premiumCheckoutInitialValues } from "../../Data/InitialValues";
import PrimaryContactInformation from "../../Component/PrimaryContactInformation";

import moment from "moment";

import { BrowserRouter as Router, useParams } from "react-router-dom";

function UpgradeMembership() {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { user_type_id } = useParams();
  const [membershipType, setMembershipType] = useState(
    user_type_id == process.env.REACT_APP_PREMIUM_USER_TYPE_ID
      ? "premium"
      : user_type_id == process.env.REACT_APP_PREMIUM_PLUS_USER_TYPE_ID
      ? "premium-plus"
      : null
  );
  const [finalCheckoutPrice, setFinalCheckoutPrice] = useState(
    user_type_id == process.env.REACT_APP_PREMIUM_USER_TYPE_ID
      ? "195.00"
      : "299.00"
  );

  const upgradeMembershipData = useSelector(
    (state) => state.authReducer.upgradeMembershipData
  );

  const [featureDropdown, setFeatureDropdown] = useState(false); // features open and hide in planInfo
  const [isPromoAppliedMsg, setPromoAppliedMsg] = useState(null);
  const [isCouponAppliedMsg, setCouponAppliedMsg] = useState(null);
  const handleDropdown = () => {
    // function for handling dropdown feature
    setFeatureDropdown(!featureDropdown);
  };

  const [isHeaderSticky, setHeaderSticky] = useState(false);
  const LocalUser = JSON.parse(window.localStorage.getItem("user"));

  const initialValues = {
    ...premiumCheckoutInitialValues,
    title:
      LocalUser?.title[LocalUser?.title?.length - 1] == "."
        ? LocalUser?.title
        : LocalUser?.title + ".",
    firstName: LocalUser?.first_name,
    lastName: LocalUser?.last_name,
    email: LocalUser?.email,
    phoneNumber: LocalUser?.phone,
    couponArray: ["PROMO-ME03", "PROMO-ME12"], // coupon code array
  };

  const onScrollFrame = (e) => {
    if (e.scrollTop > 10) {
      setHeaderSticky(true);
    } else {
      setHeaderSticky(false);
    }
  };

  useEffect(() => {
    if (upgradeMembershipData == 200) {
      if (upgradeMembershipData == 200) {
        if (membershipType == "premium") navigate("/thankyou/premium-upgrade");
        if (membershipType == "premium-plus")
          navigate("/thankyou/premium-plus-upgrade");
      }
    }
  }, [upgradeMembershipData]);

  const handleSubmit = (values, formikProps) => {
    let id = JSON.parse(window.localStorage.getItem("user"))?.user_id || null;
    const payload = {
      user_type_id: user_type_id,
      sponsor_id:
        JSON.parse(window.localStorage.getItem("user"))?.sponsor_id || null,

      accessible_modules: "hotels, flights, cars",
      coupon_code: values.promoCode,
      phone: values.phoneNumber,

      //card
      billing_address: `${values.addressOne}, ${values.addressTwo}`,
      billing_city: values.city,
      billing_country: values.nationality,
      billing_state: values.state,
      billing_zip: values.zipCode,
    };

    if (isPromoAppliedMsg && values.hasPromo) {
      payload.promotional_code = values.promoCode;
    } else {
      payload.security_code = values.security_code;
      payload.credit_number = values.credit_number || "";
      payload.exp_month = values.mmyy.split("/")[0];
      payload.exp_year =
        moment().format("YYYY").slice(0, 2) + values.mmyy.split("/")[1];
    }
    if (values.hasCoupon) {
      payload.coupon_code = values.couponCode;
    }
    dispatch(authActions.upgradeMembershipRequest(payload, id)).then((res) => {
      if (res?.status !== 200) {
        formikRef.current.setErrors({
          credit_number: res?.data?.response?.response_message,
        });
      }
    }); // email is unique, call signup api
  };

  const ApplyPromo = (promoCode) => {
    const payload = {
      code: promoCode.trim(),
      user_type_id: user_type_id,
    };

    dispatch(authActions.validatePromocodeRequest(payload)).then((res) => {
      if (res?.data?.success) {
        setFinalCheckoutPrice("0.00");
        setPromoAppliedMsg(res?.data?.message);
      } else {
        setFinalCheckoutPrice(
          user_type_id == process.env.REACT_APP_PREMIUM_USER_TYPE_ID
            ? "195.00"
            : "299.00"
        );
        setPromoAppliedMsg(null);
        formikRef.current.setErrors({
          promoCode: promoCode
            ? "Invalid Promo Code"
            : "Promo code can not be empty",
        });
        formikRef.current.setTouched({ promoCode: true });
      }
    });
  };

  // Array list of planInfo
  const premiumArrayList = [
    "Credit/Debit Card Required*",
    "Unlimited Platform Access",
    "Savings of up to 65% and more",
    "Access to 1.5 million Hotels and Resorts",
    "Access to 1.5 million Vacation Homes",
    "Access to Rental Cars and upcoming Chauffeur Service.",
    "Access to Flight Inventory of all Global Carriers and 140 Low Cost Carriers",
  ];

  const premiumPlusArrayList = [
    "Credit/Debit Card Required*",
    "Unlimited Platform Access",
    "Savings of up to 65% and more",
    "Access to 1.5 million Hotels and Resorts",
    "Access to 1.5 million Vacation Homes",
    "Access to Rental Cars and upcoming Chauffeur Service.",
    "Access to Flight Inventory of all Global Carriers and 140 Low Cost Carriers",
    <>
      Access to Member Referral Rewards Program
      <br /> • $25 for each Premium Member
      <br /> • $50 for each Premium Plus Member
    </>,
  ];

  const backgroundImage = {
    contentImage: `url${image}`, // background image of planInfo
  };

  // handleCustomChange to restrict aplphabet enter
  const numberCustomChange = (formikProps, field, value) => {
    if (
      field === "phoneNumber" ||
      field === "credit_number" ||
      field === "security_code"
    ) {
      const temp = Number(value);
      if (temp || value === "") {
        formikProps.setFieldValue(field, value);
      }
    }
  };

  const handleCustomChange = (formikProps, field, value) => {
    // handleCustomChange function for selectfieldoption input
    formikProps.setFieldValue(field, value);
  };

  const phoneCountryCodeCustomChange = (formikProps, field, value) => {
    formikProps.setFieldValue(field, value?.countryCode);
  };

  const renderForm = (formikProps) => {
    const childRef = useRef();
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
    } = formikProps;
    const [activeTab, setActiveTab] = useState(1); // billing details active tab
    const ApplyCoupon = (CouponCode) => {
      const payload = {
        coupon_code: CouponCode.trim(),
        user_type_id:
          membershipType === "premium-plus"
            ? process.env.REACT_APP_PREMIUM_PLUS_USER_TYPE_ID
            : process.env.REACT_APP_PREMIUM_USER_TYPE_ID,
        tenant_id: process.env.REACT_APP_TENANT_ID,
      };

      dispatch(authActions.validateCouponCodeRequest(payload)).then((res) => {
        if (res?.data?.success) {
          setCouponAppliedMsg(res?.data?.message);
          setFinalCheckoutPrice(parseFloat(res?.data?.package_price));
          formikRef.current.setErrors({
            ...formikRef.current.errors,
            couponCode: null,
          });
          formikRef.current.setTouched({ couponCode: true });
        } else {
          setFinalCheckoutPrice(
            user_type_id == process.env.REACT_APP_PREMIUM_USER_TYPE_ID
              ? "195.00"
              : "299.00"
          );
          setCouponAppliedMsg(null);
          formikRef.current.setErrors({
            ...formikRef.current.errors,
            couponCode: CouponCode
              ? "Invalid Coupon Code"
              : "Coupon code can not be empty",
          });
          formikRef.current.setTouched({ couponCode: true });
        }
      });
    };
    const handleCheckboxChange = (formikProps, field, value) => {
      if (field === "hasPromo") {
        formikProps.setFieldValue("hasCoupon", false);
        formikProps.setFieldValue(field, value);
      } else {
        formikProps.setFieldValue("hasPromo", false);
        formikProps.setFieldValue(field, value);
      }
      setFinalCheckoutPrice(
        user_type_id == process.env.REACT_APP_PREMIUM_USER_TYPE_ID
          ? "195.00"
          : "299.00"
      );
      formikProps.setFieldValue("couponCode", "");
      formikProps.setFieldValue("promoCode", "");
      setCouponAppliedMsg(null);
      setPromoAppliedMsg(null);
      formikProps.setErrors({
        ...formikProps.errors,
        couponCode: null,
        promoCode: null,
      });
      formikProps.setTouched({ couponCode: false });
      formikProps.setTouched({ promoCode: false });
    };
    return (
      <form onSubmit={handleSubmit}>
        {/* Primary Contact Information */}
        <PrimaryContactInformation
          // onchange and onblur for common
          formikProps={formikProps}
          handleCustomChange={handleCustomChange}
          numberCustomChange={numberCustomChange}
          // Title
          title="title"
          titleLabel="Title"
          titlePlaceholder="Title"
          disableTitle={true}
          // First Name
          firstName="firstName"
          firstNameLabel="First Name"
          firstNamePlaceholder="Walter"
          disableFirstName={true}
          // Last Name
          lastName="lastName"
          lastNameLabel="Last Name"
          lastNamePlaceholder="White"
          disableLastName={true}
          // Email
          email="email"
          emailLabel="Email"
          emailPlaceholder="Email"
          disableEmail={true}
          // Phone Number
          disablePhone={false}
          // Nationality
          nationality="nationality"
          nationalityLabel="Nationality"
          nationalityPlaceholder="Select Country"
          countryRegion="countryRegion"
          countryRegionLable="Country Region"
          countryRegionPlaceholder="Select Country Region"
          disabledCountryRegion={false}
          // Addressone
          addressOne="addressOne"
          addressOneLabel="Address Line 1"
          addressOnePlaceholder="Address Line 1"
          // AddressTwo
          addressTwo="addressTwo"
          addressTwoLabel="Address Line 2"
          addressTwoPlaceholder="Address Line 2"
          // city
          city="city"
          cityLabel="City"
          cityPlaceholder="City"
          // state
          state="state"
          stateLabel="State/Province/Region"
          statePlaceholder="Select State"
          // zipcode
          zipCode="zipCode"
          zipCodeLabel="ZIP/Postal Code"
          zipCodePlaceholder="ZIP/Postal"
          // checkbox
          hasPromo={values.hasPromo}
          onChange={handleCheckboxChange}
          showPromo={true}
          // promoCode
          promoCode="promoCode"
          promoCodeLabel="Promo / Giftcard Code"
          promoCodePlaceholder="Enter Code"
          //password

          terms="terms"
          ApplyPromo={ApplyPromo}
          isPromoAppliedMsg={isPromoAppliedMsg}
          // couponCode
          hasCoupon={values.hasCoupon}
          couponCode="couponCode"
          couponCodeLabel="Coupon Code"
          couponCodePlaceholder="Enter Code"
          ApplyCoupon={ApplyCoupon}
          onCouponChange={handleCheckboxChange}
          isCouponAppliedMsg={isCouponAppliedMsg}
          showCoupon={true}
          phoneCountryCodeCustomChange={phoneCountryCodeCustomChange}
        />

        {/* Billing Details */}

        <div className="card2 billingInfo GlobalPaymentIntegrationWrap">
          {!values.hasPromo && (
            <div className="billingDetailsToggle">
              <ul>
                <li onClick={() => setActiveTab(1)}>
                  <a className={activeTab == 1 && "active"}>
                    Pay Using Debit / Credit Cards
                  </a>
                </li>
                <li onClick={() => setActiveTab(2)}>
                  <a className={activeTab == 2 && "active"}>
                    Pay Using Other Options
                  </a>
                </li>
              </ul>
            </div>
          )}

          <div
            id="cardPaymentOption"
            className={`cardPaymentOption tabContent ${
              activeTab === 1 ? "activeTab" : ""
            }`}
          >
            {!values.hasPromo && (
              <div className="wallet-options-same-height">
                <BillingDetails
                  formikProps={formikProps}
                  numberCustomChange={numberCustomChange}
                  billingDetails={
                    <div className="sectionTitle">
                      <h2>Billing Details</h2>
                    </div>
                  }
                  // Card Holder Name
                  cardHolderName="cardHolderName"
                  cardHolderNameLabel="Card Holder Name"
                  cardHolderNamePlaceholder="Card Holder Name"
                  // Card Number
                  cardHeadingLabel="Enter Card Details"
                  credit_number="credit_number"
                  cardImage={creaditCardImage}
                  cardNumberPlaceholder="Card"
                  // mmyy
                  mmyy="mmyy"
                  mmyyPlaceholder="MM/YY"
                  // cvv
                  security_code="security_code"
                  cvvPlaceholder="CVV"
                  isMemberUpgradePage={true}
                />
              </div>
            )}
            <div className="d-flex checkBoxWrap">
              <TermsCheckbox
                name="terms"
                id="terms"
                value={values.terms}
                onChange={(e) => {
                  handleCustomChange(formikProps, "terms", e.target.checked);
                }}
                error={errors.terms}
                touched={touched.terms}
                onBlur={handleBlur}
              />

              <div className="buttonWrap">
                <div className="desktopViewDisplayNone">
                  <h4>
                    Amount Due Today:{" "}
                    <strong>&nbsp; ${finalCheckoutPrice} </strong>
                  </h4>
                </div>

                <button type="submit" className="walletPayNow btn d-block">
                  <img src={lockImage} />
                  Pay Now
                </button>
              </div>
            </div>
          </div>

          <div
            id="walletPaymentOption "
            className={`tabContent WalletPaymentOption  ${
              activeTab === 2 ? "activeTab" : ""
            }`}
          >
            <div className="walletOptionWrap">
              {!values.hasPromo && (
                <div className="walletWrapper wallet-options-same-height">
                  <div>
                    <ul>
                      {/* <li>
                      <div className="checkBoxContent">
                        <label
                          for="paypal-id"
                          className="customCheckbox checkbox-label"
                        >
                          <input
                            type="radio"
                            id="paypal-id"
                            name="WalletPayment"
                          />
                          <span></span>
                          <div className="wallet-icon-wrap">
                            <div className="wallet-icon">
                              <img src={paypal} alt="wallet icon" />
                            </div>
                            <h6>PayPal</h6>
                          </div>
                        </label>
                      </div>
                    </li> */}
                      <li>
                        <div className="checkBoxContent">
                          <label
                            htmlFor="apple-id"
                            className="customCheckbox checkbox-label"
                          >
                            <input
                              type="radio"
                              id="apple-id"
                              name="WalletPayment"
                            />
                            <span></span>
                            <div className="wallet-icon-wrap">
                              <div className="wallet-icon">
                                <img src={applePay} alt="wallet icon" />
                              </div>
                              <h6>Apple Pay</h6>
                            </div>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="checkBoxContent">
                          <label
                            htmlFor="Google-id"
                            className="customCheckbox checkbox-label"
                          >
                            <input
                              type="radio"
                              id="Google-id"
                              name="WalletPayment"
                            />
                            <span></span>
                            <div className="wallet-icon-wrap">
                              <div className="wallet-icon">
                                <img src={googlePay} alt="wallet icon" />
                              </div>
                              <h6>Google Pay</h6>
                            </div>
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
              <div className="dflex checkBoxWrap">
                <TermsCheckbox
                  name="terms"
                  id="terms"
                  value={values.terms}
                  onChange={(e) => {
                    handleCustomChange(formikProps, "terms", e.target.checked);
                  }}
                  onBlur={handleBlur}
                />

                <div className="buttonWrap">
                  <div className="desktopViewDisplayNone">
                    <h4>
                      Amount Due Today:{" "}
                      <strong>&nbsp;${finalCheckoutPrice}</strong>
                    </h4>
                  </div>

                  <button type="submit" className="walletPayNow btn d-block">
                    <img src={lockImage} />
                    Pay Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  };

  return (
    <>
      <div
        className={
          isHeaderSticky
            ? "siteBg checkoutPageWrap MembershipCheckoutPageWrap header-sticky "
            : "siteBg checkoutPageWrap MembershipCheckoutPageWrap"
        }
      >
        <div className="pageWrap pageWrapInner ">
          <div className="" style={{ minHeight: "auto" }}>
            <Header headerName={true} />
          </div>

          <section className="mainContent">
            <Scrollbars
              onScrollFrame={onScrollFrame}
              autoHeight={true}
              autoHeightMin={120}
              autoHeightMax={`calc(100%)`}
              className="pageInternalScroll changeHeightPageInternalScroll"
            >
              <div className="container  pageInternalScrollTopBottomPadding ">
                <div className="checkoutWrapper">
                  <div className="row">
                    <div className="col leftContent">
                      <Formik
                        initialValues={initialValues}
                        innerRef={formikRef}
                        onSubmit={handleSubmit}
                        handleSubmit={handleSubmit}
                        validationSchema={NewUserPremiumCheckoutValidation}
                        validateOnBlur={false}
                      >
                        {renderForm}
                      </Formik>
                    </div>
                    <div className="col rightContent">
                      <div className="infoBox">
                        {membershipType === "premium" && (
                          <div
                            className="membership-content"
                            style={backgroundImage}
                          >
                            <div className="logo-image">
                              <LogoWhite src={logo} />
                            </div>
                            <div className="planName">Premium Membership</div>
                            <p>(6 Month)</p>
                          </div>
                        )}

                        {membershipType === "premium-plus" && (
                          <div
                            className="membership-content"
                            style={backgroundImage}
                          >
                            <div className="logo-image">
                              <LogoWhite src={logo} />
                            </div>
                            <div className="planName">
                              Premium Plus Membership
                            </div>
                            <p>(12 Month)</p>
                            <div className="plus-img">
                              <img
                                src={plusImg}
                                alt="image"
                                className="mCS_img_loaded"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          !featureDropdown ? "features" : "features open"
                        }
                      >
                        <ul>
                          {membershipType == "premium" &&
                            premiumArrayList.map((list) => {
                              return <li key={list}>{list}</li>;
                            })}

                          {membershipType == "premium-plus" &&
                            premiumPlusArrayList.map((list) => {
                              return <li key={list}>{list}</li>;
                            })}
                        </ul>

                        <p className="mobileViewDisplayNone">
                          * Required for Sign Up and Bookings
                        </p>
                        <div className="priceInfo">
                          <h6>
                            Amount Due Today:{" "}
                            <strong> ${finalCheckoutPrice} </strong>
                          </h6>
                        </div>
                      </div>
                      <div
                        className={
                          !featureDropdown
                            ? "desktopViewDisplayNone toggleButtons"
                            : "desktopViewDisplayNone toggleButtons open"
                        }
                        onClick={handleDropdown}
                      >
                        <h6>
                          <span className="show-features">Show Features</span>
                          <span className="hide-features">Hide Features</span>
                          <Icon iconName="fa fa-angle-down" />
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Scrollbars>
          </section>
          <FooterWithMediaIcon
            className="white-icon"
            iconClassName="icon-white"
          />
        </div>
      </div>
    </>
  );
}

export default UpgradeMembership;
