

const PenaltyInformation = ({ penalties }) => {
    return <>
        <div className="card2 flight-penalty-info">
            <div className="primaryInfoTitle">
                <h4 className="boxTitle">Penalty Information </h4>
            </div>
            {penalties?.refundPenalties?.length > 0 && < div className="table-wrap">
                <h3>REFUND PENALTIES</h3>
                <div className="table-content">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <span>Passenger type</span>
                                </th>
                                <th>
                                    <span>Hours before departure</span>
                                </th>
                                <th>
                                    <span>Fee before departure</span>
                                </th>
                                <th>
                                    <span>Fee after departure</span>
                                </th>
                                <th>
                                    <span>Admin charges</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {penalties?.refundPenalties?.map((item, index) => {
                                return <tr key={index}>
                                    <td>
                                        <span>{item?.passengerTypeLabel}</span>
                                    </td>
                                    <td>
                                        <span>{item?.isRefundable
                                            ? `${item?.timeBeforeDeparture} ${item?.unit}`
                                            : "--"}</span>
                                    </td>
                                    <td>
                                        <span>{item?.isRefundable
                                            ? item?.amountBeforeDeparture === 0 ||
                                                item?.amountBeforeDeparture === null
                                                ? "Non-Refundable"
                                                : `${item?.currency_code} ${item?.amountBeforeDeparture}`
                                            : "Non-Refundable"}</span>
                                    </td>
                                    <td>
                                        <span className={`${(item?.amountAfterDeparture === 0 ||
                                            item?.amountAfterDeparture === null) && "errorMessage text-center"
                                            }`}>{item?.noShowAllowed
                                                ? item?.amountAfterDeparture === 0 ||
                                                    item?.amountAfterDeparture === null
                                                    ? "Non-Refundable"
                                                    : `${item?.currency_code} ${item?.amountAfterDeparture}`
                                                : "Non-Refundable"}</span>
                                    </td>
                                    <td>
                                        <span className={`${(item?.adminCharges === 0 ||
                                            item?.adminCharges === null) && "errorMessage text-center"
                                            }`}>{item?.adminCharges === 0 ||
                                                item?.adminCharges === null
                                                ? "Non-Refundable"
                                                : `${item?.currency_code}
                          ${item?.adminCharges}`}</span>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            }
            {penalties?.reissuePenalties?.length > 0 && <div className="table-wrap">
                <h3>EXCHANGE PENALTIES</h3>
                <div className="table-content">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <span>Passenger type</span>
                                </th>
                                <th>
                                    <span>Hours before departure</span>
                                </th>
                                <th>
                                    <span>Fee before departure</span>
                                </th>
                                <th>
                                    <span>Fee after departure</span>
                                </th>
                                <th>
                                    <span>Admin charges</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {penalties?.reissuePenalties?.map((item, index) => {
                                return <tr key={index}>
                                    <td>
                                        <span>{item?.passengerTypeLabel}</span>
                                    </td>
                                    <td>
                                        <span>{item?.isReissueAllowed
                                            ? `${item?.timeBeforeDeparture} ${item?.unit}`
                                            : "--"}</span>
                                    </td>
                                    <td>
                                        <span className={`${(item?.amountBeforeDeparture === 0 ||
                                            item?.amountBeforeDeparture === null) && "errorMessage text-center"
                                            }`}>{item?.isReissueAllowed
                                                ? item?.amountBeforeDeparture === 0 ||
                                                    item?.amountBeforeDeparture === null
                                                    ? "Non-Refundable"
                                                    : `${item?.currency_code} ${item?.amountBeforeDeparture}`
                                                : "--"}</span>
                                    </td>
                                    <td>
                                        <span className={`${(item?.amountAfterDeparture === 0 ||
                                            item?.amountAfterDeparture === null) && "errorMessage text-center"
                                            }`}>{item?.noShowAllowed
                                                ? item?.amountAfterDeparture === 0 ||
                                                    item?.amountAfterDeparture === null
                                                    ? "Non-Refundable"
                                                    : `${item?.currency_code} ${item?.amountAfterDeparture}`
                                                : "--"}</span>
                                    </td>
                                    <td>
                                        <span className={`${(item?.adminCharges === 0 ||
                                            item?.adminCharges === null) && "errorMessage text-center"
                                            }`}>{item?.adminCharges === 0 ||
                                                item?.adminCharges === null
                                                ? "Non-Refundable"
                                                : `${item?.currency_code} ${item?.adminCharges}`}</span>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>}
        </div >
    </>
}

export default PenaltyInformation;