import React from "react";
import Modal from "react-modal";
import warningImg from "../../Assets/img/warning.svg";
import { isEmpty, has } from "lodash";
import moment from "moment";

function HotelCheckoutModal(props) {
  const {
    modalOpen,
    modalClose,
    image,
    locationGray,
    datePicker,
    rightArrowImg,
    timerImg,
    infoGray,
    hotels,
    rates,
    input,
    usedWalletAmmout,
    currencyCode,
    isRefundable,
    hotelDetails,
    currencySymbol,
  } = props;

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode,
  });

  const currentGeoLocation =
    window.localStorage.getItem("currentGeoLocation") &&
    JSON.parse(window.localStorage.getItem("currentGeoLocation"));

  const dateDisplayFormat =
    currentGeoLocation === "US" ? "MM/DD/YYYY" : "DD/MM/YYYY";

  let numberOfAdults = !isEmpty(input)
    ? input.rooms.map((ele, i) => ele.adults).reduce((a, b) => a + b)
    : [];

  let numberOfChild = !isEmpty(input)
    ? input.rooms.map((ele, i) => ele.child).reduce((a, b) => a + b)
    : [];

  // <Modal isOpen={openModal}
  // className="popup detail-info-popup popup-open"
  // ariaHideApp={false}>
  // 	<div className="popup-content">
  // 		<div className="popup-title">
  // 			<h3>
  // 				<img src={infoGreyImg} alt="icon" />Dashboard Details
  // 			</h3>
  // 			<button className="btn close" onClick={closeModal}>
  // 				<img src={closeIcon} alt="icon" />
  // 			</button>
  // 		</div>
  // 		<div className="box-content">
  // 			{AffiliateInvitesCommissionList.map(list => (
  // 				<>
  // 					{list.title && <h4>{list.title}</h4>}
  // 					{list.description && <p>{list.description}</p>}
  // 					{list.title2 && <h3>{list.title2}</h3>}
  // 				</>
  // 			))}
  // 		</div>
  // 	</div>
  // </Modal>
  console.log("hotel", hotels);
  console.log("rates", rates);
  return (
    <Modal
      isOpen={modalOpen}
      ariaHideApp={false}
      id="flightDetailPopup"
      className="popup hotelDetailPopup hotelDetailCheckoutPopup popup-open"
    >
      <div className="popup-content">
        <button className="btn close" onClick={modalClose}>
          <span className="icon-close"></span>
        </button>
        <h3 className="popup-title">Booking Details</h3>
        <div className="popupScrollBox">
          <div className="infoBox">
            <div className="customShadow">
              <div
                className="imgWrap bgImg"
                style={{
                  backgroundImage: `url('${hotels.thumbnail_image}')`,
                }}
              ></div>
            </div>
            <div className="box-content">
              <div className="contentGroup">
                <h2 className="cartTitle">{hotels.hotel_name}</h2>
                <div className="contactInfo">
                  <div className="location">
                    <img src={locationGray} alt="icon" /> {hotels.address}
                  </div>
                  <div className="phone">
                    <span className="icon-phone"></span>{" "}
                    <span>{hotels?.phone?.replaceAll("|", " | ")}</span>
                  </div>
                </div>
              </div>
              <div className="contentGroup timeWrapper">
                <div className="d-flex">
                  <img src={datePicker} alt="icon" />
                  <span>
                    {!isEmpty(hotels) &&
                      moment(hotels.check_in_date, "YYYY-MM-DD").format(
                        dateDisplayFormat
                      )}
                  </span>
                  <img src={rightArrowImg} alt="icon" />
                  <span>
                    {!isEmpty(hotels) &&
                      moment(hotels.check_out_date, "YYYY-MM-DD").format(
                        dateDisplayFormat
                      )}
                  </span>
                </div>
                <div className="d-flex checkin-checkout-detail">
                  <div className=" tooltip">
                    <img src={timerImg} className="clock-icon  " alt="icon" />
                    <span className="tooltiptext">
                      Check-in / Check-out Time
                    </span>
                  </div>
                  <span>{hotels.check_in_time}</span>
                  <img src={rightArrowImg} alt="icon" />
                  <span>{hotels.check_out_time}</span>
                </div>
              </div>
              <div className="contentGroup roomInfo">
                <h3>
                  <span>{!isEmpty(rates) && rates?.package?.room_type}</span>
                  <button
                    className={
                      rates?.refundable
                        ? "btn-refundable"
                        : "btn-non-refundable"
                    }
                  >
                    {rates?.refundable ? "Refundable" : "Non Refundable"}
                  </button>
                </h3>
                <div className="d-flex justify-content-between">
                  <h5>Number of Nights:</h5>
                  <h5>
                    <strong>
                      {!isEmpty(rates) && rates?.number_of_nights} Nights
                    </strong>
                  </h5>
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Number of Guests:</h5>
                  <h5>
                    <strong>{rates?.total_guest_count} Guests</strong>
                  </h5>
                </div>
              </div>
              <div className="amountCalculation">
                <div className="amountInfo">
                  <p>
                    {rates?.number_of_nights} night
                    {rates?.number_of_nights > 1 ? "s" : ""} incl. taxes & fees
                  </p>
                  <div className="d-flex justify-content-between publicPrice">
                    <h5>Public Price:</h5>
                    <h4>
                      {currencySymbol}
                      {rates?.boards?.price_detail?.price?.retail_price}
                    </h4>
                  </div>
                  <div className="d-flex justify-content-between savings">
                    {!isEmpty(rates) &&
                      parseFloat(
                        rates?.boards?.price_detail?.price?.savings
                      ) !== 0 && (
                        <>
                          <h5>Savings:</h5>
                          <h4>
                            {currencySymbol}
                            {!isEmpty(rates) &&
                              rates?.boards?.price_detail?.price?.savings}
                          </h4>
                        </>
                      )}
                  </div>
                </div>

                <div className="payWrapper">
                  <div className="d-flex justify-content-between savings">
                    <div className="priceDetailsWrapper">
                      <h5>
                        You Pay:
                        <div className="tooltip-wrap">
                          <img src={infoGray} alt="icon" className="infoIcon" />
                          <div className="pricedetail-dropdown">
                            <div className="dropdown-box">
                              <div
                                className="dropdown-content"
                                style={{
                                  borderBottom:
                                    parseFloat(
                                      rates?.boards?.price_detail?.price
                                        ?.savings
                                    ) > 0
                                      ? ""
                                      : "none",
                                }}
                              >
                                {/* {true && (
                                  <div className="note">
                                    <p>Free cancellation</p>
                                  </div>
                                )} */}
                                <div className="dropdown-info">
                                  <h3>Price Details</h3>
                                  {!isEmpty(rates) && (
                                    <h4>
                                      {rates?.number_of_rooms} Room
                                      {rates?.number_of_rooms > 1
                                        ? "s"
                                        : ""} x {rates?.number_of_nights} Night
                                      {rates?.number_of_nights > 1 ? "s" : ""}
                                      <span>
                                        {currencySymbol}
                                        {
                                          rates?.boards?.price_detail?.price
                                            ?.base_rate
                                        }
                                      </span>
                                    </h4>
                                  )}
                                  {parseFloat(
                                    rates?.boards?.price_detail?.price?.tax
                                  ) > 0 && (
                                    <h4>
                                      Taxes and Fees
                                      {!isEmpty(rates) && (
                                        <span>
                                          {currencySymbol}
                                          {
                                            rates?.boards?.price_detail?.price
                                              ?.tax
                                          }
                                        </span>
                                      )}
                                    </h4>
                                  )}
                                  <div className="total-amount">
                                    <h4>
                                      <strong className="total-text">
                                        Total:
                                      </strong>
                                      {!isEmpty(rates) && (
                                        <span>
                                          {currencySymbol}
                                          {
                                            rates?.boards?.price_detail?.price
                                              ?.total_with_tax
                                          }
                                        </span>
                                      )}
                                    </h4>
                                  </div>
                                  <div className="saving-box">
                                    {!isEmpty(rates) &&
                                      parseFloat(
                                        rates?.boards?.price_detail?.price
                                          ?.savings
                                      ) > 0 &&
                                      (parseFloat(
                                        rates?.boards?.price_detail?.price
                                          ?.savings
                                      ) > 0 ? (
                                        <h4>
                                          Savings (
                                          {parseFloat(
                                            rates?.boards?.price_detail?.price
                                              ?.savings_percentage
                                          )}
                                          %):
                                          <span>
                                            {currencySymbol}
                                            {
                                              rates?.boards?.price_detail?.price
                                                ?.savings
                                            }
                                          </span>
                                        </h4>
                                      ) : (
                                        ""
                                      ))}
                                  </div>
                                </div>
                                {/* {!isEmpty(rates) &&
                                  rates.price.mandatory_fee.length > 0 ? (
                                  <div className="resortFeessection">
                                    <h3>
                                      {" "}
                                      <span className="circle">
                                        <img
                                          src={warningImg}
                                          alt="icon"
                                          className="mCS_img_loaded"
                                        />
                                      </span>
                                      <strong>
                                        Resort Fees per Room:


                                        {formatter.format(
                                          rates.price.mandatory_fee[0].amount
                                        )}

                                      </strong>
                                    </h3>
                                    <p>
                                      (<strong>NOT</strong> included in the price, due at
                                      the Hotel)
                                    </p>
                                  </div>
                                ) : (
                                  ""
                                )} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </h5>
                    </div>
                    <h4>
                      {currencySymbol}
                      {!isEmpty(rates) &&
                        rates?.boards?.price_detail?.price?.total_with_tax}
                      <span>
                        {" "}
                        ({currencySymbol}
                        {!isEmpty(rates) &&
                          rates?.boards?.price_detail?.price
                            ?.per_night_total_with_tax}
                        /night)
                      </span>
                    </h4>
                  </div>

                  <div className="pay-deduction">
                    {usedWalletAmmout > 0 && (
                      <div className="d-flex justify-content-between">
                        <h5>Your Wallet Deduction:</h5>
                        <h4>{Number(usedWalletAmmout)} </h4>
                      </div>
                    )}
                    <div className="d-flex justify-content-between amount-due">
                      <h5>Amount Due Today:</h5>
                      <h4>
                        {" "}
                        {currencySymbol}
                        {!isEmpty(rates) &&
                          rates?.boards?.price_detail?.price?.total_with_tax}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>

              {!isEmpty(rates) &&
              !isEmpty(rates?.boards?.price_detail?.price?.mandatory_fees) ? (
                <div className="resortFeessection">
                  <h3>
                    <span className="circle">
                      <img src={warningImg} alt="icon" />
                    </span>
                    <strong>
                      Resort Fees per Room:&nbsp;&nbsp;{" "}
                      {
                        rates?.boards?.price_detail?.price?.mandatory_fees[0]
                          ?.currency
                      }
                      {
                        rates?.boards?.price_detail?.price?.mandatory_fees[0]
                          ?.amount
                      }
                    </strong>
                  </h3>
                  <p>
                    (<strong>NOT </strong> included in the price, due at the
                    Hotel)
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default HotelCheckoutModal;
