/* eslint-disable no-mixed-spaces-and-tabs */
// Array list of car faqs
export const CarFaqsList = [
	{
		id: 0,
		title: 'Can I add a Driver to my reservation?',
		description1: 'You can add a driver to your rental car reservation at the rental counter.',
		description2: 'There may be a charge for each additional driver which will be disclosed at the rental counter, and payable to the rental car company.',
		description3: ' Along with the additional driver, the primary driver on the reservation must arrive at the counter to pick up the car and add the additional driver to the rental agreement.',
		description4: 'We are unable to add a driver to your rental reservation or change the name of the primary driver originally entered on our web site.',
	},
	{
		id: 1,
		title: 'What kind of cars can I rent?',
		description1: 'Tripello offers a variety of car types including, but not limited to:',
		list:[
			'Economy',
			'Compact',
			'Mid-Size',
			'Full-Size',
			'Luxury',
			'Mini Van',
			'Cargo Van (available for select locations)',
			'Standard Premium',
			'Convertible SUV (Standard, Mid-Size, and Full-Size)'
		],
		heading1: 'Can I upgrade my selection?',
		description2: 'If upgrades are available for your rental car, they will be offered on our website in the section where you view your itinerary. This section can be accessed by checking out the Check Your Request Status section on our website. Please note that upgrade charges vary by car type and by rental car company. If upgrade options are not available on our website for your reservation, you can request an upgrade from the rental company directly when picking up at the rental counter.',
		description3: <><strong>Upgrading at the Rental Car Counter:</strong></>,
		description4: 'Upgrade charges will be determined by the rental partner and will be payable directly to the rental company.',
		description5: ' If you had purchased Collision Damage Insurance with your original reservation, when you accept an upgrade the insurance is automatically cancelled and the charge for the insurance is refunded. If you would like to add Collision Damage Insurance to your new upgraded reservation, it will have to be purchased again.',

	},
	{
		id: 2,
		title: 'What is Collision Damage Coverage and how do I get it?',
		description1: 'Collision Damage Coverage (CDC) is an optional coverage plan which can be purchased on our website either during the reservation process or in some cases after the reservation is confirmed. CDC covers costs up to the covered amount, if a car you\'re renting is stolen or is damaged in a covered accident or while it\'s left unattended.',
		description2: 'Please remember, while payment may not be required for your rental car reservation until you pick up the vehicle, CDC is charged immediately to your credit card at the time of booking your reservation, or when you add it to your existing reservation.',
	},
	{
		id: 3,
		title: 'Will the rental car company perform a credit check?',
		description1: 'The rental car company may perform a credit check to determine credit worthiness at the time of rental. Be sure to read the location specific rental policies and rules during the request process.',
		description2: 'Please note that for all reservations, the rental company may also verify that you have enough funds to cover the security deposit by placing a hold or an authorization for the total amount of the deposit on the card you used to purchase your reservation. If they do authorize your card for the security deposit, they will release the hold or authorization when you drop off your car. Please note that any funds used for the security deposit cannot be accessed until after the car is returned.',

	},
	{
		id: 4,
		title: 'My flight is Delayed or worse, Cancelled?',
		heading: 'Delayed or Cancelled and Rescheduled Flights:',
		description1: 'If you are going to arrive late due to a flight delay, cancellation or reschedule, contact your rental car company.',
		heading1: 'Cancelled/Not Rescheduled/Re-routed Flights:',
		description2: 'If your flight is cancelled but not rescheduled, or re-routed to another airport, please call our Customer Service Department at the toll-free number listed on your Priceline Rental Car itinerary. Be sure to have your airline name and flight number ready when you call.',
	},
	{
		id: 5,
		title: 'Are there restrictions for Local Renters?',
		description1: 'Certain restrictions may apply for local renters. Review the rental partner’s policy and rules for more detail.',
		heading1: 'Debit Card Usage',
		description2: 'If you are a local renter and you will be using a debit card at the counter, you may be asked to provide some additional forms of identification, such as a recent utility bill or payment stub. For more specific information, please review your location\'s specific rental policy. Debit cards are not accepted for some Pay Now reservations. Refer to your checkout page to determine acceptable forms of payment.',

	},
	{
		id: 6,
		title: 'Can I book a one-way rental through Tripello?',
		description1: 'Yes. You can book a rental car reservation with a different pick-up and drop-off location (also known as a \'one-way\' rental).',
		description2: 'Please note, there may be a "drop off" fee charged by the rental car company.',
	},
	{
		id: 7,
		title: 'What Optional Items can I purchase at the Counter?',
		description1: 'If you choose to add an optional item to your Rental Car reservation at the counter, the charge will not be included in your Tripello rental. Charges vary by rental car company and additional taxes, fees, and surcharges will also apply.',
		description2: 'Some optional items that may be available to add at the counter include:',
		list2:[
			'Gasoline',
			'Additional Driver',
			'Infant/Child Seat',
			'Navigational Equipment',
			'Prepaid Toll Passes',
			'Ski Racks'
		],
		description3: 'Fees, applicable taxes & surcharges charged for optional items vary and are payable directly to the rental company and not included in your Tripello rental. Charges will be clearly spelled out on your rental agreement and you are often required to initial by them when you pick up your car.',
	},
	{
		id: 8,
		title: 'What forms of payment do you accept?',
		description1: 'For all other reservations on Tripello, you will only be required to pay at the counter.',
		description2: 'Whether you use a credit card or debit card, you must have enough available funds on the card to cover the refundable security deposit on the vehicle. The amount for the security deposit varies for each rental car company and depends upon car type, rental period and optional items accepted. Please Note - some rental partners do not accept prepaid cards.',
		heading2: 'Can I pay with a debit card or a check card?',
		description3: 'For all reservations, the card presented at the counter must be in the primary driver\'s name and an acceptable form of payment for the rental car company. Debit cards are not accepted for some reservations.',
		description4: 'Debit and credit cards are subject to acceptance by the rental car company. Some rental companies may allow a debit card to be used for the deposit at the rental counter, but may apply additional restrictions. Some of those restrictions could include, but are not limited to:',
		list3:[
			'Minimum debit card hold requirements for security deposit',
			'Proof of round trip travel',
			'Local renter restrictions',
			'Residency restrictions',
			'Car class restrictions',
			'Mileage restrictions',
			'Geography/Boundary restrictions',
			'Identity verification (utility bills (not past due) and most recent pay stub matching address on driver’s license)',
			'Proof of insurance',
			'Additional driver restrictions'
		],
		description5: 'During the request process, be sure to indicate what type of card you plan to use for the refundable security deposit required at the rental counter. If you\'re not sure whether your card is a credit or debit card, your bank can verify that for you. The rental car company may check to see that you have sufficient available funds to cover the refundable security deposit.',
		description6: 'Please note that pre-paid or stored value cards will not be accepted for the security deposit, including those showing a Visa or MasterCard logo.',
		description7: <><strong>For Retail reservations: </strong></>,
		description8: 'Debit and credit cards are subject to acceptance by the car rental company. Most locations accept debit cards;',
		description9: 'however rental partners have different requirements for customers who will only have a debit card at the time of pickup. Some rental companies may allow a debit card to be used for the deposit at the rental counter, but may apply additional restrictions.',
		description10:
        <>
        	<strong>
            Some of those restrictions could include, but are not limited to:
        	</strong>
        </>,
		list4:[
			'Minimum debit card hold requirements for security deposit',
			'Proof of round trip travel',
			'Local renter restrictions',
			'Residency restrictions',
			'Car class restrictions',
			'Mileage restrictions',
			'Geography/Boundary restrictions',
			'Identity verification (utility bills (not past due) and most recent pay stub matching address on driver’s license)',
			'Proof of insurance',
			'Additional driver restrictions'
		], 
        
		description11: 'Please view the Acceptable Forms of Payment section of your location\'s rental policy, which is located on the Important Information section on your Confirmation page.',
	},
	{
		id: 9,
		title: 'Rental Car Age Restrictions',
		description1: 'Tripello offers car rentals to drivers between the ages of 21 and 24 years old in select locations. Rentals for drivers between the ages of 18 and 20 are available in New York only. Enter the details of your trip and we\'ll tell you if we can fulfill your request.',
		description2: 'Please keep in mind that rental companies charge renters under the age of 25 a Young Renter Fee. These fees typically start at $20 USD per day and can be as much as $50 USD per day for high cost locations or more desirable car types. So a rental car that normally costs  $30 USD per day could cost an underage driver at least $50 USD per day.',
		list5:[
			'All drivers will be required to present a valid driver\'s license in order to pick up a vehicle and each driver\'s age will be verified.',
			'If the driver\'s age was misrepresented during the Tripello offer process, the driver will be subject to underage fees at the rental counter or denied rental. Tripello is not responsible for rental car cancellations due to age misrepresentation.'
		]
	},
	{
		id: 10,
		title: 'What is a Rental Car "Day"?',
		description1: 'Rental days are calculated on a 24-hour clock. In order to avoid being charged an additional day, you will need to enter the same pick up and drop off time.',
		description2: 'For example, if you request a rental car for pick up on Friday at 9:00 am and drop off on Saturday at 9:00 am, which is considered a one-day rental. However, if you request a rental car for pick-up on Friday at 9:00 am and drop-off on Saturday at 12:00 pm noon, it is considered a TWO-DAY rental, because rental days are calculated in 24-hour time blocks.',
		description3: 'If you return your car later than the scheduled drop-off date and time you may be charged for additional rental hours or days at the discretion and prevailing rate of your rental car company.',
	},
	{
		id: 11,
		title: 'How long can I rent a car?',
		description1: 'You can rent a car for a maximum of 10 months.',
	},
	{
		id: 12,
		title: 'Can I make a reservation for someone else?',
		description1: 'You can submit a request for a rental car that someone else will be driving as long as you enter the driver\'s full name and age in the "Driver" field during booking. The driver must also meet the following requirements:',
		list6:[
			'Has a valid driver\'s license issued in his or her full name. For international reservations, a valid international driver\'s license and/or a passport may be required.',
			'Has a valid credit card with available funds for the  refundable security deposit. Most locations also accept debit cards. The amount for the security deposit varies for each rental car company and depends upon car type, rental period, and optional items accepted',
			'Meets the minimum age requirements. The driver must be at least 21 years of age (or 24 in some locations).Rentals for drivers aged 18 and older are available in New York and Michigan only.'
		],
		description2: 'When you initial the request on our Web site you are indicating that the DRIVER accepts all of Tripello\'s Rental Cars Terms and Conditions and that the driver will be denied rental if any of the above criteria are not met.',
	},
	{
		id: 13,
		title: 'Can I request special equipment?',
		description1: 'Yes. You can add the following optional items when making a reservation:',
		list7:[
			'Car Sets',
			'Infant Car Seat: (less than one year and under 20 lbs)',
			'Toddler Car Seat: (forward facing for children weighing 20-40 pounds up to 40 inches in height)',
			'Booster Seat: (children weighing 40 to 80 lbs)',
			'Left-Hand Handicap Control',
			'Right-Hand Handicap Control',
			'Ski Rack',
			'Navigation System'
		],
		heading1: 'Please note:',
		list8:[
			'These are only requests and are not guaranteed.',
			'Not all options are available for all locations.',
			'Additional fees may apply. Your rental car company will charge your card at the rental counter for optional items added.',
			'Contact your car rental company to confirm availability and fees.'
		]
	},
];
