/* eslint-disable quotes */
import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Slider from "react-slick";
import NetworkHeader from "../../Component/NetworkHeader";
import Card from "../../Component/Card";
import Button from "../../Component/FormComponent/Button";
import AffiliateMobileSlider from "../../Component/AffiliateMobileSlider";
import ModalAffiliateHome from "../../Component/ModalAffiliateHome";
import PlanInfo from "../../Component/PlanInfo";
import bgImage from "../../Assets/img/Tripello-Network-Homepage-Top-Image-New.png";
import { ReactComponent as PerfectOpportunity } from "../../Assets/img/network/perfect-opportunity.svg";
import { ReactComponent as Structure } from "../../Assets/img/network/structure.svg";
import { ReactComponent as OnboardingProcess } from "../../Assets/img/network/onboarding-process.svg";
import { ReactComponent as NounSafe } from "../../Assets/img/network/noun-safe.svg";
import downArrow from "../../Assets/img/network/down-arrrow.svg";
import tripelloDiffImage from "../../Assets/img/network/tripello-diffrenece.png";
import { ReactComponent as DownArrowSvg } from "../../Assets/img/down-arrow-svg.svg";
import flightImg from "../../Assets/img/flightImg.png";
import hotelImage from "../../Assets/img/cardImg2.png";
import carImage from "../../Assets/img/cardImg3.png";
import homeImgae from "../../Assets/img/cardImg4.png";
import personalMgmtImg from "../../Assets/img/network/personal-mgmt-bg.png";
import mobileSliderOne from "../../Assets/img/network/mobile-slider1.png";
import mobileSliderTwo from "../../Assets/img/network/mobile-slider2.png";
import mobileSliderThree from "../../Assets/img/network/mobile-slider3.png";
import mobileSliderFour from "../../Assets/img/network/mobile-slider4.png";
import playButton from "../../Assets/img/network/play-button.png";
import mobilePlayButton from "../../Assets/img/network/mobile-play-button.png";
import YoutubeEmbed from "../../Component/YoutubeEmbed";
import logo from "../../Assets/img/logo.png";
import { accordianList } from "../../main/CustomConstants/AffiliateHome";
import AccordianTwo from "../../Component/AccordianTwo";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import tripelloNetworkPdfIcon from "../../Assets/img/network/Tripello-Network-Program-Pdf-Icon.jpg";
import WalletSavings from "../../Assets/img/icons/wallet-savings.png";
import Percent0 from "../../Assets/img/icons/percent-0.png";
import DynamicPricing from "../../Assets/img/icons/dynamic-pricing.png";
import VideoModal from "../../Component/VideoModal";
import videoSrc from "../../Assets/video/affiliate-home/affiliate-homepage-video-explainer.mp4";
import { getLocationParams } from "../../Util/util";
import { authActions, commonActions } from "../../Stores/Actions";

function AffiliateLandingPage() {
  const locationSearch = getLocationParams();
  const dispatch = useDispatch();
  const history = useNavigate();

  //state for youtube embed
  const [play, setPlay] = useState(false);
  const scrollPage = useRef(null);
  // state for modal open and close
  const [modalOpen, setModalOpen] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);

  const handleOpenCloseVideoModal = () => {
    setOpenVideoModal(!openVideoModal);
  };

  // state for expanding accordian
  const [isExpandAccordian, setIsExpandAccoridan] = useState(false);

  // state for open and close accordian list
  const [openedAccordian, setOpenedAccordian] = useState(0);

  //open play and close youtube embed
  const youtubeEmbedOpen = () => {
    setPlay(true);
  };

  const youtubeEmbedClose = () => {
    setPlay(false);
  };

  //open and close modal for pdf and video
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  //getting scroll button while scrolling down
  useEffect(() => {
    if (localStorage.getItem("token") == null && locationSearch?.token) {
      console.warn(locationSearch?.token);
      console.warn(locationSearch?.path);
      localStorage.setItem("accessToken", locationSearch?.token);
      localStorage.setItem("lt", new Date());
      dispatch(authActions.getUserDetail(history, locationSearch?.path));
    }

    // scrollPage.current.addEventListener("scroll", handleScroll);
  }, []);

  //setting length after which sticky header and scrollToTop button show
  // const handleScroll = () => {
  //   if (
  //     (scrollPage.current.scrollTop >= 4 && !showHeader) ||
  //     scrollPage.current.scrollTop >= 150
  //   ) {
  //     setShowHeader(true);
  //     setShowScrollTopBtn(true);
  //   }
  //   if (
  //     scrollPage.current.scrollTop < 4 ||
  //     scrollPage.current.scrollTop < 150
  //   ) {
  //     setShowHeader(false);
  //     setShowScrollTopBtn(false);
  //   }
  // };

  //jumping to top of the page on button click
  const scrollElementToTop = () => {
    scrollPage.current.scrollTop = 0;
  };
  //open and close accoridan list
  const handleOpenClose = (id) => {
    if (id === openedAccordian) {
      setOpenedAccordian(null);
    } else {
      setOpenedAccordian(id);
    }
  };

  //image for network banner
  const sectionStyleOne = {
    backgroundImage: `url(${bgImage})`,
  };

  //image for network difference
  const sectionStyleTwo = {
    backgroundImage: `url(${tripelloDiffImage})`,
  };

  //scroll down to scroll section
  const scrollView = (e) => {
    let scrollSection = document.getElementById("scroll-section");
    e.preventDefault();
    scrollSection &&
      window.scrollTo({
        top: (scrollSection.offsetTop - 60),
        behavior: 'smooth',
      });
  };
  const settings = {
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    dots: true,
    centerMode: true,
    centerPadding: "35px",
    speed: 1000,
    variableWidth: false,
    adaptiveHeight: false,
  };
  //Accordion List
  const getFaqs = (faqList) => {
    let accordinNodes = [];

    faqList.forEach((faq, index) => {
      //render the whole list if true
      if (isExpandAccordian) {
        accordinNodes.push(
          <AccordianTwo
            handleOpenClose={handleOpenClose}
            openedAccordian={openedAccordian}
            handleOpenModal={handleOpenModal}
            id={index}
            key={faq.title + index}
            title={faq.title}
            content={faq.description}
            button={faq.button}
            className={`card-content ${index === 0 ? `` : ``}`}
          />
        );
      } else {
        // render only limited list
        if (index < 5) {
          accordinNodes.push(
            <AccordianTwo
              handleOpenClose={handleOpenClose}
              openedAccordian={openedAccordian}
              handleOpenModal={handleOpenModal}
              id={index}
              key={faq.title + index}
              title={faq.title}
              content={faq.description}
              button={faq.button}
              className={`card-content ${index === 0 ? ` ` : ``}`}
            />
          );
        }
      }
    });
    return accordinNodes;
  };

  //Tripello Difference List
  const tripelloDifferenceList = [
    <>
      At <strong>Tripello</strong> we do Travel in a new and different way.
    </>,
    <>
      With our <strong>New Platform</strong> we enable our{" "}
      <strong>Members</strong> to book from thousands of{" "}
      <strong>Wholsale Travel Providers</strong> on one platform. There are{" "}
      <strong>No Mark-Ups, No Commissions</strong> and{" "}
      <strong>No Booking Fees</strong> - Ever.
    </>,
    <>
      Tripello is the
      <strong> first subscription based Travel Service </strong> offering{" "}
      <strong>Dynamic, True Wholesale / Net Rates</strong> directly to its
      Members, in Real Time. This allows for <strong>Savings</strong> of{" "}
      <strong>up to 65% </strong> and sometimes even <strong>more.</strong>
    </>,
  ];

  //Tripello Network List
  const tripelloNetworkList = [
    {
      icon: <PerfectOpportunity />,
      title: "Perfect Opportunity",
      text: <>If you <strong>love Travel</strong> and sharing <br /><strong>incredible Savings</strong> then the Tripello Network is for You.</>
    },
    {
      icon: <OnboardingProcess />,
      title: "Streamlined Onboarding Process",
      text: <><strong>Full Control</strong> and <strong>Tracking</strong> through individual <strong>Links/QR Codes</strong> and <br />Personal Dashboard.</>
    },
    {
      icon: <Structure />,
      title: "Exclusive Compensation Structure",
      text: <>First in the Travel Industry with <strong><br />50% + Commissions</strong> and Annual<br /> <strong>Bonus Pool.</strong></>
    }
  ]
  const affiliateCompensationList = [
    {
      icon: <NounSafe />,
      title: "Freemium Membership Commission",
      text: <><strong>50%</strong> of whatever a Freemium <br/>Member saves on their Hotel Booking.</>
    },
    {
      icon: <NounSafe />,
      title: "Premium Membership Commission",
      text: <><strong>50%</strong> on all Premium and Premium Plus Memberships and on Member to<br/> Member referrals.</>
    },
    {
      icon: <NounSafe />,
      title: "Affiliate Membership Commission",
      text: <><strong>50%</strong> on all Affiliate Memberships.</>
    }
  ]
  //Tripello Network List
  // const tripelloNetworkList = [
  //   <>
  //     We have developed a <strong>Unique Affiliate Program</strong> around our{" "}
  //     <strong>New Platform</strong> that can offer{" "}
  //     <strong>substantial earnings</strong> for its Affiliate Members.
  //   </>,
  //   <>
  //     If you <strong>love Travel</strong> as much as we do and you love{" "}
  //     <strong>sharing incredible savings,</strong> then the Tripello Network is
  //     the <strong>right opportunity</strong> For you.
  //   </>,
  //   <>
  //     The <strong>Unique Compensation Structure</strong> and the available tools
  //     make the Tripello Network the <strong>first of its kind</strong> in the{" "}
  //     <strong>Travel Industry.</strong>
  //   </>,
  //   <>
  //     Tripello Network Affilates make <strong>50%+ Commissions</strong> by
  //     selling Tripello Memberships.{" "}
  //     <strong>
  //       More details &nbsp;
  //       <Button
  //         className="tripello-magic-link"
  //         text="here."
  //         onClick={handleOpenModal}
  //       />
  //     </strong>
  //   </>,
  // ];
  // Tripello QR Code List
  const qrCodeList = [
    <>
      Our Network Affiliates have the opportunity to promote our program using their
      individual <strong>Invitation Link or QR Code</strong> via Social Media,
      in person or through any chosen marketing campaign.
    </>,
    <>
      <strong>Your invitation</strong> will direct your prospect to your own{" "}
      <strong>Registration Pages.</strong> All Sign Ups and Conversions to
      Premium Members are <strong>automatically tracked and accredited</strong>{" "}
      to your affiliate account.
    </>,
    "You have the option to invite Freemium, Premium and Network Affiliate Members.",
  ];

  const boxContentList = [
    <>
      Affiliates receive <strong>earnings on a percentage</strong> of what
      Freemium Members{" "}
      <strong>
        {" "}
        save on their Travel.
        <br /> Flat Fees{" "}
      </strong>{" "}
      on every Premium and Affiliate <strong>Membership sold</strong> as well as
      Member to Member <strong>referrals.</strong>
    </>,
    <>
      Overall the Tripello Network <strong>pays a minimum of 50%</strong>{" "}
      commissions to its Affiliates.
    </>,
    <>
      You can <strong>find out more</strong> about <strong>Tripello</strong> and
      the <strong>Tripello Network Affiliate Program</strong> by clicking{" "}
      <Button
        className="tripello-magic-link"
        text="here.."
        onClick={handleOpenModal}
      />
    </>,
  ];

  return (
    <div ref={scrollPage} className="networkPageWrap">
      <NetworkHeader />
      <section className="network-banner" style={sectionStyleOne}>
        <div className="container">
          <div className="banner-content">
            <h1>The Tripello Network</h1>
            <h2>
              {" "}
              A New And Unique Network Marketing <br />
              Program For The Travel Industry
            </h2>
          </div>
          <div className="scrollBtm">
            <Link
              to="#scroll-section"
              onClick={scrollView}
              className="scroll-down"
            >
              <div className="mousey-wrap">
                <div className="mousey">
                  <div className="scroller"></div>
                </div>
                <DownArrowSvg />
              </div>
            </Link>
          </div>
        </div>
      </section>
      {/* banner end */}

      <section
        id="scroll-section"
        className="tripello-difference-wrapper"
        style={sectionStyleTwo}
      >
        <div className="container">
          <h3 className="sctionTitle">The Tripello Difference</h3>
          <p>Tripello does Travel Booking in a <strong>New and Different Way.</strong></p>
          <div className="content-wrapper">
            <div className="col">
              <div className="heading">
                <i><img src={DynamicPricing} /></i> Dynamic pricing
              </div>
              <div className="content">
                <strong>Dynamic, Wholesale / Net Rates</strong> in Real Time, directly to you.
              </div>
            </div>
            <div className="col">
              <div className="heading">
                <i><img src={Percent0} /></i> No Fees or Mark Up’s
              </div>
              <div className="content">
              With our powerful Technology, book directly from thousands of Wholesale Travel Providers, <strong>YOU pay what WE pay!</strong>
              </div>
            </div>
            <div className="col">
              <div className="heading">
                <i><img src={WalletSavings} /></i> Savings up to 65%
              </div>
              <div className="content">
                More Savings, More Freedom, More Choices, <strong>Better Experiences.</strong>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* the tripello difference end */}

      <div className="tripello-inventory-wrapper">
        <div className="container">
          <div className="section-title">
            <h2>The Tripello Inventory</h2>
          </div>
          <div className="row col-layout">
            <Card
              colClassName="col grid-item"
              styleClassName="cardStyle"
              imgClassName="cardImg"
              image={flightImg}
              textClassName="cardText"
              backgroundImage={true}
              title={
                <h5>
                  <Link to="/home">Global Flights</Link>
                </h5>
              }
              description=" Big savings on Flights, especially in premium cabins, from all Global Carries and over 140 low-cost carriers from around the world."
            />
            <Card
              colClassName="col grid-item"
              styleClassName="cardStyle"
              imgClassName="cardImg"
              image={hotelImage}
              textClassName="cardText"
              backgroundImage={true}
              title={
                <h5>
                  <Link to="/home">Hotels & Resorts</Link>
                </h5>
              }
              description="Huge savings on over 1.5 million Hotels and Resorts worldwide, Including the most popular destinations."
            />
            <Card
              colClassName="col grid-item"
              styleClassName="cardStyle"
              imgClassName="cardImg"
              image={carImage}
              textClassName="cardText"
              backgroundImage={true}
              title={
                <h5>
                  <Link to="/home">Rental Cars & Services</Link>
                </h5>
              }
              description="Great Deals on Rental Cars by the best and most trusted brands from around the world."
            />
            <Card
              colClassName="col grid-item"
              styleClassName="cardStyle"
              imgClassName="cardImg"
              image={homeImgae}
              textClassName="cardText"
              backgroundImage={true}
              title={
                <h5>
                  <Link to="/home">Vacation Homes</Link>
                </h5>
              }
              description="More than 1.5 Million bookable Vacation rentals incl. Condos,Cabins, Villas, Bungalows, Beach Houses and more."
            />
          </div>
        </div>
      </div>
      <div className="tripello-way-wrapper">
        <div className="container">
          <div className="section-title">
            <h2>The Tripello Network</h2>
            <h3>A <strong>Unique Network Marketing Program</strong> with <strong>substantial</strong> earnings for its Affiliates.</h3>
          </div>
          <div className="cardStyle">
            <div className="card-box-wrap row">
              {tripelloNetworkList.map((list, index) => {
                return <>
                  <div className="col card-col">
                    <div className="cardBox">
                      <div className="img-wrap">{list.icon}</div>
                      <h4>{list.title}</h4>
                      <p>{list.text}</p>
                    </div>
                  </div>
                </>;
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="qr-code-section">
        <div className="qr-code-section-wrap">
          <div className="container">
            <div className="left-content">
              <div className="left-content-wrapper">
                <h2>Your Personal Links and QR Codes</h2>
                {qrCodeList.map((list, index) => {
                  return <p key={index}>{list}</p>;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* QR Codes section end */}

      <div className="personal-mgmt-dashboard">
        <div className="container">
          <div className="section-title">
            <h2>Your Personal Management Dashboard</h2>
          </div>
          <div className="content">
            <p>
              As an Affiliate you will have your own{" "}
              <strong>Personal Management Dashboard</strong> giving you access
              to your <strong>Earning Details</strong>, Invitation Links For
              Members and Affiliates, <strong>Marketing Materials</strong> and
              your own <strong>Wallet</strong>. Everything you need,{" "}
              <strong>all conveniently in one location.</strong>
            </p>
          </div>
          <div className="img-section">
            <img src={personalMgmtImg} alt="image" />
          </div>
          <Slider className="moboile-slider" {...settings}>
            <AffiliateMobileSlider heading="Dashboard" src={mobileSliderOne} />
            <AffiliateMobileSlider
              heading="Member Invites"
              src={mobileSliderTwo}
            />
            <AffiliateMobileSlider
              heading="Marketing Materials"
              src={mobileSliderThree}
            />
            <AffiliateMobileSlider heading="Wallet" src={mobileSliderFour} />
          </Slider>
        </div>
      </div>
      {/* Personal mgmt dashboard section end */}

      <div className="affiliate-compensation-section">
        <div className="container">
          <div className="box-layout-wrap">
            <div className="section-title">
              <h2>Network Affiliate Compensation</h2>
            </div>
            <div className="box-layout">
              {/* {boxContentList.map((list, index) => {
                return <p key={index}>{list}</p>;
              })} */}
              <div className="card-box-wrap row">
                {affiliateCompensationList.map((list, index) => {
                  return <>
                    <div className="col card-col">
                      <div className="cardBox">
                        <div className="img-wrap">{list.icon}</div>
                        <h4>{list.title}</h4>
                        <p>{list.text}</p>
                      </div>
                    </div>
                  </>;
                })}
              </div>
              <div className="box-layout-footer">
                <p> You can find out more about Tripello and
                  the Tripello Network Affiliate Program by clicking{" "}
                  <Button
                    className="tripello-magic-link"
                    text="here."
                    onClick={handleOpenModal}
                  />
                  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Box Content End here */}

      <div className="full-video-wrapper">
        <div className="container">
          <div className="section-title">
            <h2>A Quick Overview</h2>
          </div>
          <div className="video-box-main-wrap">
            <div className="video-box">
              <div className="video-box-wrap">
                <div className="desktop-video">
                  <Button
                    type="button"
                    className="video-play-btn"
                    imageSrc={playButton}
                    onClick={handleOpenCloseVideoModal}
                  />
                </div>
                <div className="mobile-video">
                  <div className="mobile-video-wrap">
                    <Button
                      type="button"
                      className="video-play-btn"
                      imageSrc={playButton}
                      onClick={handleOpenCloseVideoModal}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="video-section-content">
            <div className="video-section-content-wrap">
              <h6>
                For a detailed <strong>Tripello Company </strong>and{" "}
                <strong>Tripello Network Affilate Program Overview,</strong>{" "}
                including <strong>Compensation Plan</strong>&nbsp;please&nbsp;click&nbsp;
                <Button
                  className="tripello-magic-link"
                  text="here."
                  onClick={handleOpenModal}
                />
              </h6>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="full-video-wrapper">
        <div className="container">
          <div className="section-title">
            <h2>A Quick Overview</h2>
          </div> 
           <div className="video-box">
            <div className="desktop-video video-box-wrap">
              <button
                type="button"
                className="video-play-btn"
                data-target="#home-desktop-video-player"
                onClick={youtubeEmbedOpen}
              >
                <img src={playButton} alt="play-button" />
              </button>
            </div>
            <div className="mobile-video video-box-wrap">
              <div className="mobile-video-wrap">
                <button
                  type="button"
                  className="video-play-btn"
                  data-target="#home-desktop-video-player"
                  onClick={youtubeEmbedOpen}
                >
                  <img src={mobilePlayButton} alt="play-button" />
                </button>
              </div>
            </div>
          </div>  
          <div className="video-section-content">
            <div className="video-section-content-wrap">
              <h6>
                For a detailed <strong>Tripello Company </strong>and{" "}
                <strong>Tripello Network Affilate Program Overview,</strong>{" "}
                including <strong>Compensation Plan</strong> please
                <Button
                  className="tripello-magic-link"
                  text="CLICK HERE."
                  onClick={handleOpenModal}
                />
              </h6>
            </div>
          </div>
        </div>
      </div> */}

      {/* full video section end */}

      <div className="plan-wrapper">
        <div className="container">
          <div className="section-title">
            <h2>Sign Up or Upgrade Today</h2>
          </div>
          <div className="choose-plan">
            <div className="row">
              <div className="col plan-item plan-item1">
                <PlanInfo
                  logo={logo}
                  planName="Network Affiliate Membership"
                  months="(12 Month)"
                  heading1={
                    <h6>
                      Choose this option if <strong>YOU DO NOT</strong> have a
                      Premium Plus Tripello Membership
                    </h6>
                  }
                  arrayList={[
                    "Full Annual Tripello Premium Plus Membership",
                    "Access to Tripello Network Affilate Program with Unique and Comprehensive Compensation Plan",
                    "Access to Personal Management Dashboard",
                    "Access to Personal Invitation Links and QR Codes for Member and Affiliate Invites",
                    "Access to Marketing Center with Social Media optimized Collateral and Tips and Tricks for Campaigns",
                    "Access to Personal Wallet for tracking of Earnings and Payouts",
                  ]}
                  heading2={
                    <>
                      $499.00<span>/ annually</span>
                    </>
                  }
                  heading3="(Annual Auto Renewal)"
                  text="Sign Me Up"
                  link={`affiliate-premium-membership`}
                />
              </div>
              <div className="col plan-item plan-item2">
                <PlanInfo
                  logo={logo}
                  planName="Upgrade To Network Affiliate Membership"
                  months="(12 Month)"
                  link={"affiliate-upgrade-membership"}
                  heading1={
                    <h6>
                      Choose this option if <strong>YOU ALREADY</strong> have a
                      Premium Plus Tripello Membership
                    </h6>
                  }
                  arrayList={[
                    "Access to Tripello Network Program with Unique and Comprehensive Compensation Plan",
                    "Access to Personal Management Dashboard",
                    "Access to Personal Invitation Links and QR Codes for Member and Affiliate Invites",
                    "Access to Marketing Center with Social Media optimized Collateral and Tips and Tricks for Campaigns",
                    "Access to Personal Wallet for tracking of Earnings and Payouts",
                  ]}
                  heading2={
                    <>
                      $199.00<span>/ First Year</span>
                    </>
                  }
                  heading3="(Renews As Network Affiliate Membership)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="homefaq" className="faq-wrapper">
        <div className="container">
          <div className="section-title">
            <h2>Here are Some Answers to Common Questions</h2>
          </div>
          <div className="accordion">
            <div className="toggle-more-accordions">
              {getFaqs(accordianList)}
            </div>
          </div>
          {accordianList.length > 5 && (
            <div className="read-more-btn-wrap">
              {!isExpandAccordian ? (
                <button
                  onClick={() => {
                    setIsExpandAccoridan(true);
                  }}
                  className="read-more-btn"
                  type="button"
                >
                  more...
                </button>
              ) : (
                <button
                  onClick={() => {
                    setIsExpandAccoridan(false);
                  }}
                  className="read-more-btn"
                  type="button"
                >
                  less...
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      <FooterWithMediaIcon />

      {openVideoModal && (
        <VideoModal
          openModal={openVideoModal}
          closeModal={handleOpenCloseVideoModal}
          videoSrc={videoSrc}
        />
      )}

      {play && <YoutubeEmbed openModal={play} closeModal={youtubeEmbedClose} />}
      {modalOpen && (
        <ModalAffiliateHome
          openModal={modalOpen}
          closeModal={handleCloseModal}
          tripelloNetworkPdfIcon={tripelloNetworkPdfIcon}
        />
      )}
    </div>
  );
}

export default AffiliateLandingPage;
