import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';

function SortBy({ isMobileView, payload, handleSearchHotel }) {

  const { newHotelSearchData } = useSelector((state) => state.hotelReducer);

  const [showOption, setshowOption] = useState(true);

  function handleSelect(sort) {
    handleSearchHotel(null, { ...payload, sort: sort })
  }
  const hotelSortLabelChange = (type, currency) => {
    switch (type?.name) {
      case "cashback_amount":
        return (
          <span>
            {type?.label}&nbsp;(
            {currency})
          </span>
        );
      case "savings_amount":
        return (
          <span>
            {type?.label}&nbsp;(
            {currency})
          </span>
        );
      case "final_price":
        return (
          <span>
            {type?.label}&nbsp;(
            {currency})
          </span>
        );
      default:
        return `${type?.label}`;
    }
  };

  const hotelSortLabelValue = (value, currency) => {
    switch (value[0]?.name) {
      case "cashback_amount":
        return value.map(option => ({
          ...option,
          label: `${value[0]?.label} (${currency})`
        }));
      case "savings_amount":
        return value.map(option => ({
          ...option,
          label: `${value[0]?.label} (${currency})`
        }));
      case "final_price":
        return value.map(option => ({
          ...option,
          label: `${value[0]?.label} (${currency})`
        }));
      default:
        return value;
    }
  };

  const options = newHotelSearchData?.sort
  // console.log("options", options)
  let value = options && options?.filter((item) => item.name === payload.sort.field && item.order === payload.sort.order);
  // value = value?.length > 0 ? value[0] : null;
  // console.log("value", value)

  let newValue = value?.length > 0 && hotelSortLabelValue(value, '$')
  // console.log("newValue", newValue)

  return isMobileView ? (
    <div className="content col">
      <div className="flterOption">
        <p className="whiteLabel" onClick={() => setshowOption(!showOption)}>
          Sort by
        </p>

        <div className={`${showOption ? '' : 'd-none'}`}>
          <Select
            className="sort-by-dropdown 123"
            options={newHotelSearchData?.sort?.map(
              (ele) => {
                return {
                  label: hotelSortLabelChange(
                    ele, '$'
                  ),
                  value: ele,
                };
              }
            )
            }
            placeholder="Select..."
            value={newValue}
            isSearchable={false}
            // onChange={handleSelect}
            onChange={(sortBy) => {
              const sort = {
                field: sortBy?.value.name,
                order: sortBy?.value.order,
              };
              handleSelect(sort)
            }}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="content col">
      <p className="whiteText">Sort by</p>
      <div
        onClick={() => setshowOption(!showOption)}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Select
          className="sort-by-dropdown 456"
          options={newHotelSearchData?.sort?.map(
            (ele) => {
              return {
                label: hotelSortLabelChange(
                  ele, '$'
                ),
                value: ele,
              };
            }
          )
          }
          placeholder="Select..."
          value={newValue}
          isSearchable={false}
          onChange={(sortBy) => {
            const sort = {
              field: sortBy?.value.name,
              order: sortBy?.value.order,
            };
            handleSelect(sort)
          }}
        />
        <span className={`fa fa-angle-${showOption ? 'down' : 'up'}`}></span>
      </div>
    </div>
  );
}
export default SortBy;
