import React from "react";
import { isEmptyValue } from "../../../Util/util";
import { useSelector } from "react-redux";

function FilterDisplay({
  filters,
  handleSearchHotel, 
}) {

  const { newHotelSearchData } = useSelector((state) => state.hotelReducer);

  const handleRemoveFilter = (payloadKey) => {

    let updatedFilters = {};

    Object.entries(filters).forEach(([key, item]) => {
      let singleFilter = item;
      if (payloadKey === item.payloadKey) {
        if (item.valueType === "number") {
          singleFilter.onChangeValue = null;
          singleFilter.value = null;
        } else if (item.valueType === "string") {
          singleFilter.onChangeValue = "";
          singleFilter.value = "";
        } else if (item.valueType === "array") {
          singleFilter.onChangeValue = [];
          singleFilter.value = [];
        } else if (key === "priceRange") {
          const minFromAPI = newHotelSearchData?.filters[1].options[0].value;
          const maxFromAPI = newHotelSearchData?.filters[1].options[1].value;

          singleFilter.onChangeValue = { min: minFromAPI, max: maxFromAPI };
          singleFilter.value = { min: 0, max: 0 };
        }
      }

      updatedFilters = { ...updatedFilters, [key]: singleFilter }
    })

    handleSearchHotel(updatedFilters);
  };

  return (
    <div className="filter-display">
      <div className="filter-btn">

        {Object.entries(filters).map(([key, item]) => {
          if (!isEmptyValue(item?.value, item.valueType) && (key !== "priceRange" || (item?.value?.min || item?.value?.max))) {
            return (
              <div key={key} className="filter-name">
                {item?.label}
                <button
                  className="icon-close"
                  onClick={() => handleRemoveFilter(item?.payloadKey)}
                ></button>
              </div>
            )
          }
        })}

      </div>
    </div>
  );
}

export default FilterDisplay;
