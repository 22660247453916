import Modal from 'react-modal';
import CloseIcon from '../../../Assets/img/close-blue-icon.png';

const AdditionalPolicyModal = ({ isOpen, closeModal, policies }) => {
  return (
    <div className="detailPageWrap">
      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        className="popup cancellation-policy-popup popup-open popup-additional-policy">
        <div className="popup-content-wrap  ">
          <div className="popup-content">
            <div className="popup-title">
              <h3>Additional Policy</h3>
              <button
                className="btn close room-details-popup-close-btn"
                onClick={() => closeModal(false)}
              >
                <img src={CloseIcon} alt="icon" />
              </button>
            </div>
            <div className='content' dangerouslySetInnerHTML={{__html: policies}} >
              </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AdditionalPolicyModal;
