import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import TabLayout from "../Component/TabLayout";
import { commonActions } from "../Stores/Actions";
import { authActions } from "../Stores/Actions";
import moment from "moment";

function LandingPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    window.localStorage.removeItem("home_search_key");
    window.localStorage.removeItem("hotel_search_key");

    const lt = window.localStorage.getItem("lt");
    const timeDiff = moment(lt).diff(moment(), "hours");
    dispatch(commonActions.hideLoader());
    const loginData = JSON.parse(window.localStorage.getItem("loginData"));
    const payload = {
      client_id: loginData?.primary_user_data?.tenant_id,
      user_id: loginData?.primary_user_data?.type_id,
    };
    dispatch(authActions.getTenantCongfig(payload)).then((res) => {
      if (res?.status === 200) {
        const enabledVertical = [];
        const disabledVertical = [];
        // let data = res?.data?.data?.travel_verticals;
        // Check status for each vertical and categorize them accordingly
        Object.keys(res?.data?.data?.travel_verticals).forEach((vertical) => {
          if (res?.data?.data?.travel_verticals[vertical].status) {
            enabledVertical.push(
              vertical.charAt(0).toUpperCase() + vertical.slice(1)
            );
          } else {
            disabledVertical.push(
              vertical.charAt(0).toUpperCase() + vertical.slice(1)
            );
          }
        });
        localStorage.setItem("enabledVertical",JSON.stringify(enabledVertical));
      }
    });
    if (timeDiff > 8) {
      dispatch(authActions.getUserDetail()); // token authentication
    }
  }, []);
  return <TabLayout />;
}

export default LandingPage;
