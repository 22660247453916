/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "../../Component/FormComponent/Button";
import { homeActions } from "../../Stores/Actions";

function CancellationModal({ openModal, closeModal,policy }) {
  const dispatch = useDispatch();
  const params = useParams();

  function cancelItinerary() {
    let id = params.bookingID;
    let idd = "ns5556456-mkks445-kkk44444-4545"; // dumpy id for testing purpose only
    dispatch(homeActions.cancelledBooking(id));
    closeModal();
  }

  return (
    <Modal
      ariaHideApp={false}
      isOpen={openModal}
      onRequestClose={closeModal}
      contentLabel="My dialog"
      className="mymodal"
      overlayClassName="myoverlay"
      closeTimeoutMS={500}
    >
      <div className="popup popup-open hotel-confirmation-cancel-popup hotel-confirmation-popup">
        <div className="popup-content">
          <div className="popup-title">
            <h3>Are you sure you want to cancel this booking?</h3>
          </div>
          <div className="popup-content-title">
          <h4>Cancellation Policy</h4>
                <ul>
                  <li>
                    Refundable cancellations (as laid out in our{" "}
                    <a target="_blank" href="/terms-conditions">
                      Terms and Conditions{" "}
                    </a>
                    ) will be subject to a 3.2% merchant processing fee.
                  </li>
                  <li>
                    Booking charges to your Debit/Credit Card will appear in the
                    name of "Tripello" and/or "Stripe".
                  </li>
                  <li>{policy?.default}</li>
                  {policy?.cancellation?.map((ele, index) => {
              return (<li key={index}>{ele?.policy}</li>)})}
                </ul>
                
          </div>
          <div className="popup-footer">
            <div className="button-wrap">
              <button className="btn blue-btn close-btn" onClick={closeModal}>
                Keep it
              </button>
              <Button
                className="btn orange-btn cancel-btn"
                onClick={cancelItinerary}
              >
                Cancel it
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CancellationModal;
