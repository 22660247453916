import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../Component/FormComponent/Button';

function PropertyFilter({
  isMobileView,
  title,
  type,
  filters,
  setFilters,
  handleSearchHotel
}) {
  const [showFilter, setshowFilter] = useState(false);
  const { newHotelSearchData } = useSelector((state) => state.hotelReducer);
  const [showMore, setShowMore] = useState(false);

  function handleApply() {
    const updatedFilters = { ...filters, [type]: { ...filters[type], value: filters[type]?.onChangeValue } };
    handleSearchHotel(updatedFilters);
  }

  const handleChange = (e) => {
    if (filters[type]?.onChangeValue?.includes(e)) {
      const result = filters[type]?.onChangeValue?.filter((item) => e !== item);
      const updatedFilters = { ...filters, [type]: { ...filters[type], onChangeValue: result } };
      setFilters(updatedFilters);
    } else {
      const updatedFilters = { ...filters, [type]: { ...filters[type], onChangeValue: [...(filters[type]?.onChangeValue || {}), e] } };
      setFilters(updatedFilters);
    }
  }


  const listLimit = 50;
  return (
    <>
      <div className='amenities-filter-wrap'>
        <div
          className={isMobileView ? 'flterOption' : 'cardHead dflex'}
          onClick={() => setshowFilter(!showFilter)}
        >
          {isMobileView ? <span>{title}</span> : <h6>{title}</h6>}
          <span className={`fa fa-angle-${(showFilter) ? 'up' : 'down'}`}></span>
        </div>
        <div className={`cardBody checkbox-card ${(!showFilter) ? 'd-none' : ''}`}>
          <div className='amenities-filter-data'>
            {newHotelSearchData?.filters?.length > 2 && newHotelSearchData?.filters[2]?.options?.map((item, index) => {
              if (index < listLimit || showMore) {
                return (
                  <label className="customCheckbox" key={`${index}_category`}>
                    <input
                      type="checkbox"
                      name="categories"
                      checked={filters[type]?.onChangeValue?.includes(item?.value)}
                      value={item.value}
                      onChange={() => {
                        handleChange(item.value)
                      }}
                    />
                    <span>
                      {item.label} {item.count && `(${item.count})`}
                    </span>
                  </label>
                );
              }
            })}
          </div>
          {(newHotelSearchData?.filters[2]?.options.length > listLimit || showMore) &&
            <>
              <div className='hotel-show-more-btn-wrap'>
                <button className='btnBorder' onClick={() => setShowMore(!showMore)}>{showMore ? 'Less' : 'More'}</button>
              </div>
            </>
          }

          {isMobileView ? (
            ' '
          ) : (
            <Button
              className={`btnBorder btnTopMargin ${false && 'disabled'}`
              }
              type="button"
              onClick={handleApply}
              disabled={false}
            >
              Apply
            </Button>
          )}

        </div>
      </div>
    </>
  );
}

export default PropertyFilter;
