import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { hotelActions } from "../../Stores/Actions";
import DateNTime from "./DateNTime";
import InfoBox from "./InfoBox";
import PriceDetails from "./PriceDetails";

function HotelDetails({
  bookingData,
  // hotel_name,
  // bed_type,
  // check_in_date_format4,
  // check_out_date_format4,
  // check_in_time,
  // check_out_time,
  // address,
  // phone,
  // booking_reference_id,
  // guest_count,
  // room_count,
  // night_count,
  // price,
  // id,
  // status,
  // currency,
  // refundable_status
}) {
  const dispatch = useDispatch();

  const downloadInvoice = () => {
    // dispatch(hotelActions.downloadPdf(id));
  };

  return (
    <div className="card2">
      <div className="dflex alignFlexStart">
        <div className="iconWrapMain">
          <div className="iconWrap">
            <span className="icon-apartments">
              <span className="path1"></span>
              <span className="path2"></span>
            </span>
          </div>
          <span className="booking-status">{bookingData?.booking_status}</span>
        </div>
        <div className="cardContent">
          <h3 className="cartTitle">{bookingData?.hotel_data?.hotel_name}</h3>
          <div className="dflex stretch">
            <div className="innerCnt">
              <div className="topSection dflex justifyStart copyText hotelCopy">
                {/* {bed_type} */}Bed type
                {bookingData?.hotel_data?.refundable ? (
                  <span className="refundable">Refundable</span>
                ) : (
                  <span className="non-refundable"> Non Refundable</span>
                )}
              </div>
              <div className="cardCol no-gutters">
                <DateNTime
                  iconName={"icon-invitation"}
                  label="Check-in:"
                  date={bookingData?.iternary_details?.check_in_date}
                  time={bookingData?.iternary_details?.check_in_time}
                />
                <DateNTime
                  iconName={"icon-invitation"}
                  label="Check-out:"
                  date={bookingData?.iternary_details?.check_out_date}
                  time={bookingData?.iternary_details?.check_out_time}
                />
              </div>
              <div className="smlText">
                <i className="icon-location"></i>
                {bookingData?.hotel_data?.address_details?.address_line_one}
              </div>
              <div className="smlText">
                <i className="icon-phone"></i>
                {bookingData?.hotel_data?.hotel_phone}
              </div>
              <div className="cardLinkStyle">
                {/* <button onClick={downloadInvoice}> */}
                Hotel Confirmation Number : {' '}
                {bookingData?.hc_number?.length > 0 ? (
                  <span>{bookingData?.hc_number.join(",")}</span>
                ):'-'}
                {/* </button> */}
              </div>
            </div>
            <div className="infoBox">
              <div className="dflex mb-10 bookingIdWrap">
                <span className="font14 lightText">Booking ID:</span>
                <span className="font14 darkText">
                  {bookingData?.internalRefId || "-"}
                </span>
              </div>
              <div className="keyValueWrap">
                <div>
                  <InfoBox
                    divClassName={"mb-5"}
                    iconName="icon-group font12"
                    detail1={"Guests:"}
                    detail2={`${bookingData?.guest_details?.length} ${
                      bookingData?.guest_details?.length > 1
                        ? "Adults"
                        : "Adult"
                    }`}
                  />
                  <InfoBox
                    divClassName={"mb-5"}
                    iconName="icon-room"
                    detail1={"Rooms:"}
                    detail2={`${bookingData?.iternary_details?.num_rooms} Room`}
                  />
                  <InfoBox
                    iconName={"icon-invitation font17"}
                    detail1="Nights:"
                    detail2={`${bookingData?.iternary_details?.num_nights} ${
                      bookingData?.iternary_details?.num_nights > 1
                        ? "Nights"
                        : "Night"
                    }`}
                  />
                </div>
                <PriceDetails
                  details={"Total:"}
                  // price={price}
                  // currency={currency}
                  text="View & Manage"
                  nextPage={`/hotel-confirmation/${bookingData?.internalRefId}`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HotelDetails;
