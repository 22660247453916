// eslint-disable-next-line quotes

export const accordianList = [
  {
    title: 'Who can become a Tripello Network Affiliate?',
    description:
      'Basically anyone. To be successful you should be internet and social media savvy and have a love for Travel. Oh, and of course the motivation to create success. That’s it.',
  },
  {
    title:
      'Isn’t the Tripello Network the same as other Travel Affiliate Programs?',
    description:
      ' No, through the Tripello Network you will be promoting a Travel Product, a Subscription based Travel Membership. We do not work with Cookies, Ads, PPC and the usual tools used by these programs.',
  },
  {
    title: 'Is this a Multi-Level Marketing (MLM) Program?',
    description:
      'NO. This is an Affiliate Program, there are no levels and you won’t be under another Affiliate sharing your earnings. Your commissions only go to YOU.',
  },
  {
    title: 'So how does it work then?',
    description:
      'Each Affiliate has their own individual Invitation QR Code and link to promote Tripello. These can be accessed through your own Dashboard. You can share your link and QR Code directly in person from your device, via email, copy and paste it into your favorite social media channels or any other conventional advertisement media. You can also drive traffic to your own, personal landing page which is automatically generated through your link and QR Code. So when people click on your link or scan your QR Code they will be directed to this specific Sign Up Page. Once they register, the New Member will be automatically tracked under your account. Get all the details',
    button: true,
  },
  {
    title: 'What is the Commission Structure?',
    description:
      'The Tripello Network pays commissions on Freemium Member Savings, Premium and Affiliate Membership Sign Ups. Please review all materials (Tripello Company Overview PDF, Overview Video and Tripello Network Program including Compensation Plan). You can access all info',
    button: true,
  },
  {
    title:
      'Do I get revenue for encouraging more Affiliates to sign up for the program?',
    description:
      'Yes, absolutely. This is part of the Compensation Plan that you can review',
    button: true,
  },
  {
    title: 'Do you have any tools to help market Tripello?',
    description:
      'Yes, we have compiled a large amount of materials to support your marketing efforts. We have compiled Social Media optimized imagery and videos that you can utilize in combination with your personalized invitation links. In addition, we offer an Overview into Tripello, Tips and Best Practices for your Marketing Campaigns.',
  },
  {
    title: 'How can I keep track of my earnings?',
    description:
      'Everything is presented in a transparent way. You can review and track your commissions in your own personal Dashboard.',
  },
  {
    title: 'I still have questions; how do I contact you?',
    description: 'You can reach us at info@tripellonetwork.com',
  },
  {
    title: 'How often are Payouts made?',
    description: 'The Tripello Network pays out once a month.',
  },
  {
    title: 'How are Payouts made?',
    description:
      'At this time, the payouts are made through PAYPAL. Additional payout options will be added in the future.',
  },
  {
    title: 'Do US Citizens need to provide a TAX ID Number?',
    description:
      'Yes. For US Citizens we need to collect a valid TAX ID Number/Social Security Number to be in compliance with US TAX Law. US Affiliates will receive a 1099 Form to report their earnings. WARNING!! Invalid TAX ID /Social Security Numbers will result in immediate account suspension and forfeiture of all outstanding commissions.',
  },
  {
    title: 'As an Affiliate can I book on Tripello for myself?',
    description:
      'Yes, absolutely. Your Affiliate Membership includes a Tripello Premium Plus Membership.',
  },
  {
    title: 'Can I cancel my Affiliate Membership?',
    description:
      'Of course, you can. You will have the option to do so in your User Profile Section. Please be advised that if you do cancel, you will not receive any further commissions after the ﻿annual renewal date. This includes any possible pending commissions, residual commissions on ALL ﻿Membership Renewals, Member to Member Referrals as well as Freemium Member Savings. Your ﻿account will be downgraded to a Tripello Freemium Membership. ',
  },
];
