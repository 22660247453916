import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Button from "../FormComponent/Button";
import { useDispatch, useSelector } from "react-redux";
import { flightActions } from "../../Stores/Actions/flightActions";
import FligtRulesTable from "./FligtRulesTable"
import { Tabs } from "antd";

Modal.setAppElement("#root");
function FareRule({ isOpen, closeModal, flights }) {
    const dispatch = useDispatch();
    const [flightRule, setFlightRule] = useState([]);
    const [status, setStatus] = useState(200);
    useEffect(() => {
        if (isOpen) {
            document
                .getElementsByTagName("body")[0]
                .classList.add("siteBg", "listingPage", "listingFlight", "popupOpned");
        } else {
            document
                .getElementsByTagName("body")[0]
                .classList.remove(
                    "siteBg",
                    "listingPage",
                    "listingFlight",
                    "popupOpned"
                );
        }
    }, [isOpen]);
    useEffect(() => {
        return () => {
            document
                .getElementsByTagName("body")[0]
                .classList.remove(
                    "siteBg",
                    "listingPage",
                    "listingFlight",
                    "popupOpned"
                );
        };
    }, []);
    const PassengerCount = JSON.parse(window.localStorage.getItem("travellingInfo"));
    let flightItmes = [];
    useEffect(() => {
        var payload = {
            sequenceId: flights?.sequenceId,
            cabinClass: PassengerCount?.class,
        };
        dispatch(flightActions.searchFlightFareRuleAction(payload)).then((res) => {
            if (res?.status === 200) {
                setFlightRule(res?.data?.rules);
                setStatus(200)
            } else {
                setFlightRule(null);
                setStatus(400)
            }
        });
    }, []);
    const [tabIndex, setTABIndex] = useState(0);

    flightRule?.map((ele, index) => {
        flightItmes = [
            ...flightItmes,
            {
                key: index,
                label: `${ele?.cityPair}`,
                children: <FligtRulesTable data={flightRule?.[tabIndex]} status={status} />,
            },
        ];
    });





    // HANDLE TAB CHANGE
    const handleTabChangeData = (e) => {
        setTABIndex(e);
    };

    return (
        <>
            <Modal className="flightDetailsModal" isOpen={isOpen} ariaHideApp={false}>
                <div id="flightDetailPopup" className={isOpen ? "popupStyle" : ""}>
                    <div className="popUpBox">
                        <Button onClick={() => closeModal(false)} className="popupCloseBtn">
                            <span className="icon-close"></span>
                        </Button>
                        <h3 className="popupTitle">Fare Rule</h3>
                        {flightRule ? <div className="popupScrollBox">
                            <div className="popupFlightBox">
                                {
                                    <Tabs
                                        onChange={(e) => handleTabChangeData(e)}
                                        defaultActiveKey="1"
                                        items={flightItmes}
                                    />
                                }
                            </div>
                        </div> : <div className="popupScrollBox">
                            <div className="popupFlightBox">
                                <div className="no-fare-rule-found">The fare rules are not available at this moment. Kindly visit airlines website to get more details.</div>
                            </div>
                        </div>}
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default FareRule;
