import React from "react";
import { startsWithURL } from "../../Util/util";

// FligtRulesTable;

const FligtRulesTable = ({ data, status }) => {
    console.log("status", status)
    // VARIABLES
    const rules =
        (data?.type !== "text" && data?.rules) ||
        (data?.rule_details &&
            data?.type !== "text" &&
            Object.keys(data?.rule_details).map((key) => ({
                ...data?.rule_details[key],
            })));

    const newRule =
        data?.type !== "text" &&
        rules?.filter((ele, index) => {
            return ele?.Rules?.length > 0 && ele;
        });

    return (
        <div className="drawerInnerScroll">
            {(status === 200 && data?.type !== "text") ? (
                <table className="font14 text-left tableStyle w-100">
                    <>
                        <thead>
                            <tr>
                                <th>Rules</th>
                                <th>Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {newRule?.map((ele, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        {ele?.Rules?.length > 0 ? (
                                            <tr key={index}>
                                                <td>{ele?.Category}</td>
                                                <td>
                                                    {ele?.Rules ? (
                                                        <a
                                                            href={ele?.Rules}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {ele?.Rules}
                                                        </a>
                                                    ) : (
                                                        <p
                                                            dangerouslySetInnerHTML={{
                                                                __html: ele?.Rules,
                                                            }}
                                                        />
                                                    )}
                                                </td>
                                            </tr>
                                        ) : (
                                            "No details found"
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </>
                </table>
            ) : (status === 200 && data?.rules?.length > 1) ? (
                startsWithURL(data?.rules) ? <a rel="noreferrer" target="_blank" href={data?.rules}>{data?.rules} </a> : <div
                    className="font14 tableStyle w-100 pl-rlue40"
                    dangerouslySetInnerHTML={{
                        __html: data?.rules,
                    }}
                />
            ) : <div className="no-fare-rule-found">The fare rules are not available at this moment. Kindly visit airlines website to get more details.</div>}
        </div>
    );
};
export default FligtRulesTable;
