import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import CarDetails from "./CarDetails";
import FllightDetails from "./FllightDetails";
import HotelDetails from "./HotelDetails";

const AllBookings = ({ bookingData, status }) => {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(hotelActions.getUpcomingBookings("upcoming"));
  // }, []);

  // console.log("bookingData ---", bookingData)
  return bookingData?.length > 0 ? (
    <div className="mainWrap booking">
      {bookingData &&
        bookingData.map((item, index) => {
          if (item.type === "Hotel" || item.type === "weeks") {
            return (
              <HotelDetails

                bookingData={item}
                status={status}
                key={`${index}hotel`}
              />
            );
          } else if (item.type === "Flight") {
            return (
              <FllightDetails
                flights={item}
                status={status}
                key={`${index}flight`}
              />
            );
          }
          if (item.type === "Cars") {
            return (
              <CarDetails item={item} status={status} key={`${index}car`} />
            );
          }
        })}
    </div>
  ) : (
    <div className="not-found-booking">
      <h2>No Bookings</h2>
      <p>You have no upcoming bookings.</p>
      <p>Start your amazing journey with a new booking.</p>
      <Link to={"/landing"} className="btn">
        Back To Home
      </Link>
    </div>
  );
};

export default AllBookings;
