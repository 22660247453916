import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import HeaderNavbar from '../../Component/HeaderNavbar.js';
import PrimaryContactInformation from '../../Component/PrimaryContactInformation.js';
import GuestInfo from '../../Component/GuestInfo.js';
import Loader from '../../Component/Loader/Loader.js';
import TermsCheckbox from '../../Component/TermsCheckbox.js';
import { HotelCheckoutValidation } from '../../Component/Validation/HotelCheckoutValidation.js';
import HotelCheckoutModal from './HotelCheckoutModal.js';
import ReviewAndVerifyModal from "../../Component/Modals/ReviewAndVerifyModal.jsx";
import rightArrowImg from '../../Assets/img/right-arrow.png';
import reviewImg from '../../Assets/img/summary-icon.png';
import lockImage from '../../Assets/img/lock.svg';
import bgImg from '../../Assets/img/checkout-img.jpg';
import locationGray from '../../Assets/img/location-gray.png';
import datePicker from '../../Assets/img/date-picker.png';
import timerImg from '../../Assets/img/timer.png';
import infoGray from '../../Assets/img/info-gray.png';
import FooterWithMediaIcon from '../../Component/FooterWithMediaIcon.js';
import { ErrorModel } from '../../Assets/scss/components/ErrorModal/ErrorModal.js';
import Button from '../../Component/FormComponent/Button.js';
import { isEmpty } from 'lodash';
import Scrollbars from 'react-custom-scrollbars';
import InputField from '../../Component/FormComponent/InputField.js';
import { commafy, getCurrencyAndLanguage } from '../../Util/util.js';

import {
  ageOptionChild,
  countryCode,
  optionsAge,
} from '../../Data/static.service.js';

import WalletDetailInfo from '../../Component/WalletDetailInfo.js';
import paypal from '../../Assets/img/checkout/paypal.png';
import applePay from '../../Assets/img/checkout/apple-pay.png';
import googlePay from '../../Assets/img/checkout/google-pay.png';
import { useDispatch, useSelector } from 'react-redux';
import { checkHotelAvability, hotelActions } from '../../Stores/Actions/index.js';
import HotelDetailCard from './HotelDetailCard.js';
import { StripeForm } from './StripeForm.js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PriceCheckModel from '../../Component/Modals/PriceCheckModal.jsx';
import IncreaseIcon from '../../Assets/img/increase-icon.svg';
import HotelSoldOutModal from './HotelSoldOutModal.js';
import moment from 'moment';

// import getStripeIntentRequest from "../../Stores/Actions/homeActions.js/getStripeIntentRequest"
const type = {
  non_refundable: "No Refund",
  fully_refundable: "Refund",
  partial_refund: "Partial Refund",
};
function HotelCheckout() {
  const currentGeoLocation =
    window.localStorage.getItem("currentGeoLocation") &&
    JSON.parse(window.localStorage.getItem("currentGeoLocation"));

  const dateDisplayFormat =
    currentGeoLocation === "US" ? "MM/DD/YYYY" : "DD/MM/YYYY";

  const params = useParams();
  const formikRef = useRef();
  const childRef = useRef();
  const stripeFormRef = useRef();
  const [openModal, setOpenModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openSoldOutModal, setOpenSoldOutModal] = useState({
    open: false,
    message: ""
  });
  const [usedWalletAmmout, setUsedWalletAmmout] = useState(0);
  const [adultSelectError, setAdultSelectError] = useState(null);
  const [openReviewAndVerifyModal, setReviewAndVerifyModal] = useState(false);

  //settting state for wallet input
  const checkedBox = false;
  const [style, setStyle] = useState('none');
  const [activeTab, setActiveTab] = useState(1);
  const [userFromDrop, setUserFromDrop] = useState(null);
  const [bookHotelPayloadWithGuest, setBookHotelPayloadWithGuest] = useState(
    []
  );
  const dispatch = useDispatch();

  const currencyAndLanguage =
    (window.localStorage.getItem('currencyAndLanguage') &&
      JSON.parse(window.localStorage.getItem('currencyAndLanguage'))) ||
    {};

  const currencySymbol =
    currencyAndLanguage?.currencyLanguageReducer?.data?.currency?.symbol || '$';

  const stripe = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  // let hotelDetails = JSON.parse(localStorage.getItem('hotelDataForCheckout'));
  const stripeIntentData = useSelector(
    (state) => state.hotelReducer.stripeIntentData
  );
  const hotelIntentData = useSelector(
    (state) => state.hotelReducer.hotelIntentData
  );
  const stripeIntentSuccess = useSelector(
    (state) => state.hotelReducer.stripeIntentSuccess
  );

  const hotelIntentSuccess = useSelector(
    (state) => state.hotelReducer.hotelIntentSuccess
  )

  const bookHotelData = useSelector(
    (state) => state.hotelReducer.bookHotelData
  );
  const bookingDataError = useSelector(
    (state) => state.hotelReducer.bookingDataError
  );
  const sessionExpireError = useSelector(
    (state) => state.hotelReducer.sessionExpireError
  );
  const confirmPaymentError = useSelector(
    (state) => state.hotelReducer.confirmPaymentError
  );
  const stripeIntentError = useSelector(
    (state) => state.hotelReducer.stripeIntentError
  );

  const hotelDetails = useSelector(
    (state) => state.hotelReducer.packageData
  );
  // const availableBalance = useSelector(
  //   (state) => state.hotelReducer.availableBalance
  // );
  const [availableBalance, setAvailableBalance] = useState(false)
  const loading = useSelector((state) => state.commonReducer.loading);
  const getGuestData = useSelector((state) => state.commonReducer.getGuestData);
  const guestListOptions = useSelector(
    (state) => state.commonReducer.guestListOptions
  );
  const navigate = useNavigate();



  const { currency } = getCurrencyAndLanguage();
  useEffect(() => {
    const checkHotelAvabilityPayload = {
      // room_ids: [id],
      // currency: currency,
      room_ids: [params.roomId],
      currency: currency,
      // locale: language?.code || 'en-US',
    };
    dispatch(checkHotelAvability(
      checkHotelAvabilityPayload,
      params.hotelID
    )).then((res) => {
      // localStorage.removeItem('hotelDataForCheckout')
      if (res?.status === 200) {
        localStorage.setItem('hotelDataForCheckout', JSON.stringify(res?.data));
      }
      else if (res?.status === 400 && res?.data?.sold_out) {
        // render sold out modal
        handleSoldOutModal(true, res.data?.message);
      } else if (res?.status === 400 && res?.data?.price_updated) {    // open modal with error message (eg. session expired error)
        // open price update modal
        setOpenPriceUpdateModaL(true)
      } else {
        console.log("revaliedte")
      }
    });
  }, [])

  const currencyCode = hotelDetails?.data?.currency?.code
    ? hotelDetails?.data?.currency?.code
    : 'USD';
  useEffect(() => {

    if (!userFromDrop) return;
    formikRef?.current?.setFieldValue('title', userFromDrop?.title || '');
    formikRef?.current?.setFieldValue('firstName', userFromDrop?.fname || '');
    formikRef?.current?.setFieldValue('lastName', userFromDrop?.lname || '');
    formikRef?.current?.setFieldValue('email', userFromDrop?.email || '');
    formikRef?.current?.setFieldValue('phoneNumber', userFromDrop?.phone || '');
    formikRef?.current?.setFieldValue('addressOne', '');
    formikRef?.current?.setFieldValue('addressTwo', '');
    formikRef?.current?.setFieldValue('city', '');
    formikRef?.current?.setFieldValue('state', '');
    formikRef?.current?.setFieldValue('zipCode', '');

    formikRef?.current?.setFieldValue(
      'nationality',
      userFromDrop?.country || ''
    );
  }, [userFromDrop]);


  const hotels = !isEmpty(hotelDetails) ? hotelDetails?.data?.hotels : [];
  const input = !isEmpty(hotelDetails) ? hotelDetails?.data?.input : [];

  const filterRoomId = hotelDetails?.data?.packages?.length > 0 ? hotelDetails?.data?.packages[0] : [];
  //changing state onClick
  const handleCheckbox = (formikProps, field, value) => {
    setStyle(!style);
    formikProps.setFieldValue(field, value);
  };

  //open and close modal on Click
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleReviewAndVerifyModal = () => setReviewAndVerifyModal(false);

  const handleErrorModal = (status) => setOpenErrorModal(status);
  const handleSoldOutModal = (status, message) => setOpenSoldOutModal({
    open: status,
    message: message
  });

  var imageBackground = {
    backgroundImage: `url(${bgImg})`,
  };
  const user = JSON.parse(window.localStorage.getItem('user'));
  // form intialValues
  const initialValues = {
    user: { id: user.email, label: `${user?.first_name} ${user?.last_name}` },
    title: user?.title || '',
    title2: '',
    firstName: user?.first_name,
    lastName: user?.last_name,
    email: user?.email,
    countryCode: '',
    phoneNumber: user?.phone || '',
    nationality: '',
    countryRegion: '',
    addressOne: user?.user_address?.address_line_1 || '',
    addressTwo: user?.user_address?.address_line_2 || '',
    city: user?.user_address?.city || '',
    state: '',
    zipCode: user?.user_address?.zip || '',
    promoCode: '',
    age: '',
    use_wallet: false,
    walletAmount: '',
    amountAdded: '',
    remainingAmount: '',
    terms: false,
    newLeadGuests: [],
    //card
    cardHolderName: '',
    security_code: '',
    exp_month: '',
    exp_year: '',
    billing_zip: '',
    credit_number: '',
    billing_country: '',
    mmyy: '',
  };

  const optionsTitle = [
    { value: 'Mr.', label: 'Mr.' },
    { value: 'Mrs.', label: 'Mrs.' },
    { value: 'Ms.', label: 'Ms.' },
    // { value: "Neutral", label: "Neutral." },
  ];

  const numberCustomChange = (formikProps, field, value) => {
    if (
      field === 'phoneNumber' ||
      field === 'credit_number' ||
      field === 'security_code'
    ) {
      const temp = Number(value);
      if (temp || value === '') {
        formikProps.setFieldValue(field, value);
      }
    }
  };

  const phoneCountryCodeCustomChange = (formikProps, field, value) => {
    formikProps.setFieldValue(field, value?.countryCode);
  };

  useEffect(() => {
    // for handling errors
    if (sessionExpireError || stripeIntentError || bookingDataError) {
      handleErrorModal(true); // open error modal
    }
    if (confirmPaymentError) {
      formikRef.current.setTouched({
        ...formikRef.current.touched,
        cardNumber: true,
      });
      return formikRef.current.setErrors({
        cardNumber: 'Invalid Card Number',
      });
    }
  }, [
    sessionExpireError,
    stripeIntentError,
    bookingDataError,
    confirmPaymentError,
  ]);

  // useEffect(() => {
  //   if (stripeIntentData?.stripe_payment_intent_id && stripeIntentSuccess) {
  //     // if stripe intent api successfully called
  //     stripeFormRef.current.confirmStripePayment().then((res) => {
  //       // if(res?.error) return
  //       dispatch(hotelActions.bookHotelAction(bookHotelPayloadWithGuest, params?.hotelID)); // call book hotel api
  //     });
  //   }
  // }, [stripeIntentData]);

  // useEffect(() => {
  //   if (hotelIntentData?.stripe_payment_intent_id && hotelIntentSuccess) {
  //     // if stripe intent api successfully called
  //     stripeFormRef.current.confirmStripePayment().then((res) => {
  //       // if(res?.error) return
  //       dispatch(hotelActions.bookHotelAction(bookHotelPayloadWithGuest, params?.hotelID)); // call book hotel api
  //     });
  //   }
  // }, [hotelIntentData]);

  // useEffect(() => {
  //   if (bookHotelData?.status === 200) {
  //     navigate(`/hotel-confirmation/${bookHotelData.data.id}?isConfirm=true`); // if booking successful then redirect to hotel comfirmation page.
  //   }
  // }, [bookHotelData]);

  //  check available blance
  useEffect(() => {
    const payload = {
      "room_ids": [params.roomId],
      "currency": currency
    }
    dispatch(hotelActions.getAvailableBalanceNew(payload)).then((res) => {
      if (res?.payload?.status === 200) {
        setAvailableBalance(true)
        return;
      } else {
        setAvailableBalance(false)
      }
    })
  }, []);

  //handleCustomChange function for selectfieldoption input
  const handleCustomChange = (formikProps, field, value) => {
    formikProps.setFieldValue(field, value);
    if (field == 'user') {
      const result = getGuestData?.filter((curr) => {
        return curr.id == value.value;
      });
      setUserFromDrop(result?.length > 0 ? result[0] : null);
    }
  };

  const [stripeError, setStripeError] = useState('');

  const handleSubmit = () => {
    //handleSubmit furnciton
    const cardElementContainer = document.querySelector('.cardWrapper');
    let isCardComplete = cardElementContainer?.classList?.contains(
      'StripeElement--complete'
    );
    if (isCardComplete) {
      setStripeError('');
    } else {
      setStripeError('Required');
      return;
    }
    let adultformError = null;
    let childformError = null;
    const bookHotelPayload = {
      contact_person: {
        salutation: formikRef?.current?.values?.title,
        first_name: formikRef?.current?.values?.firstName,
        last_name: formikRef?.current?.values?.lastName,
        email: formikRef?.current?.values?.email,
        // state: formikRef?.current?.values?.state,
        city: formikRef?.current?.values?.city,
        // contact_no: formikRef?.current?.values?.phoneNumber,
        phone_number: formikRef?.current?.values?.phoneNumber,

        country: formikRef?.current?.values?.nationality,
        nationality: formikRef?.current?.values?.nationality,
        postal_code: formikRef?.current?.values?.zipCode,
        street: `${formikRef?.current?.values?.addressOne} ${formikRef?.current?.values?.addressTwo}`,
        phone_area_code: formikRef?.current?.values?.countryCode || null,
        // street2: formikRef?.current?.values?.addressTwo,
        remarks: "Pre-Booking",
      },
      // wallets: [
      //   {
      //     type_id: JSON.parse(window.localStorage.getItem('user'))?.user_wallet
      //       .wallet_type_id,
      //     value: Number(usedWalletAmmout) || 0,
      //   },
      // ],
      // use_wallets: formikRef?.current?.values?.use_wallet == false ? 0 : 1,
      // hotel_id: params?.hotelID,
      currency: currency,
    };

    const roomsData = childRef.current.getRoomsGuests();
    const FinalFilteredGuests = JSON.parse(JSON.stringify(roomsData)).filter(
      (curr, idx) => {
        //remove touched and errors property from the child array
        curr.room_id = hotelDetails?.data?.selected_room[0];

        const ress = curr.adults.filter((ele) => {
          if (ele.is_lead_guest) {
            ele.age = Number(ele.age);
            delete ele.touched;
            delete ele.email
            if (
              ele.age < 15 ||
              ele.age == null ||
              !ele.first_name.length ||
              !ele.last_name.length ||
              !ele.salutation.length
            ) {
              adultformError = 'Please fill all the required fields';
            }
            return ele;
          }
        });

        curr.adults = ress;

        // check if any guest is empty, if emty then disebled the submit button
        const getLead = curr.adults.filter((val) => val.is_lead_guest === true);
        if (!getLead.length) {
          adultformError = 'Please select atleast one adult' + idx;
          setAdultSelectError({
            msg: 'Please select atleast one adult',
            index: idx,
          });
        }

        //remove touched  and errors property from the child array

        curr.child.map((ele) => {
          delete ele.touched;
          delete ele.errors;

          if (
            !String(ele.age).length ||
            ele.age < 0 ||
            !ele.first_name.length ||
            !ele.last_name.length ||
            !ele.salutation.length
          ) {
            childformError = 'Please fill all the required fields';
          }
          ele.age = Number(ele.age);
        });
        delete curr.children;

        return curr;
      }
    );
    bookHotelPayload.guests = FinalFilteredGuests; // merge guest data with book hotel payload
    bookHotelPayload.hotel_id = params.hotelID;
    setBookHotelPayloadWithGuest(bookHotelPayload);
    // const checkHotelAvabilityPayload = {
    //   room_ids: [params.roomId],
    //   currency: currencyAndLanguage?.currency?.isoCode || 'USD',
    //   locale: currencyAndLanguage?.language?.langCode || 'en-US',
    // };

    if (!childformError && !adultformError) {
      //recheck-price
      setReviewAndVerifyModal(true);
    }
  };
  const handelPayNow = () => {
    const checkHotelAvabilityPayload = {
      room_ids: [params.roomId],
      currency: currency,
      payment_gateway_type: "stripe_usd",
      // locale: currencyAndLanguage?.language?.langCode || 'en-US',
    };
    dispatch(checkHotelAvability(
      checkHotelAvabilityPayload,
      params.hotelID
    )).then((res) => {
      if (res?.status === 200) {
        localStorage.setItem('hotelDataForCheckout', JSON.stringify(res?.data));
        // prebook
        dispatch(hotelActions.hotelPrebook(bookHotelPayloadWithGuest, params?.hotelID)).then((res) => {
          if (res?.status === 200) {
            const payload = {
              bookingRefId: res?.data?.data?.booking_ref_id,
              paymentCode: "stripe_usd",
              travelVertical: "hotels",
            }
            dispatch(hotelActions.paymentIntentHotel(payload)).then((res) => {
              if (res?.status === 200) {
                stripeFormRef.current.confirmStripePayment().then((res) => {
                  dispatch(hotelActions.bookHotelAction(bookHotelPayloadWithGuest, params?.hotelID)).then((res) => {

                    if (res?.status === 200) {
                      navigate(`/hotel-confirmation/${res?.data?.booking_ref_id}?isConfirm=true`); // if booking successful then redirect to hotel comfirmation page.
                    } else {
                      console.log("error")
                    }
                  }) // call book hotel api

                });
              } else {
                console.log("error")
              }
            });
          } else {
            console.log(res?.data?.message)
          }
        });

      } else if (res?.status === 400 && res?.data?.sold_out) {
        // render sold out modal
        handleSoldOutModal(true, res.data?.message);
      } else if (res?.status === 400 && res?.data?.price_updated) {    // open modal with error message (eg. session expired error)
        // open price update modal
        setOpenPriceUpdateModaL(true)
      }
    });
  }

  const localUser = JSON.parse(window.localStorage.getItem('user'));

  const RewardPlaceHolder = () => {
    const availBalance =
      (hotelDetails?.data?.wallets?.available_balance || 0) *
      (hotelDetails?.data?.currency?.exchange_rate || 1);

    if (filterRoomId?.price?.final_price < availBalance) {
      return filterRoomId?.price?.final_price
        ? commafy(filterRoomId?.price?.final_price)
        : 0;
    } else {
      return availBalance ? commafy(availBalance) : 0;
    }
  };
  const renderForm = (formikProps) => {
    const { values, handleSubmit, handleBlur, touched, handleChange, errors } =
      formikProps;

    return (
      <form onSubmit={handleSubmit}>
        {((localUser?.type_id ==
          process.env.REACT_APP_PREMIUM_PLUS_USER_TYPE_ID &&
          localUser?.user_wallet?.available_balance > 0) ||
          (localUser?.type_id ==
            process.env.REACT_APP_AFFILIATE_PREMIUM_USER_TYPE_ID &&
            localUser?.user_wallet?.available_balance > 0)) && (
            <WalletDetailInfo
              userFromDrop={userFromDrop}
              formikProps={formikProps}
              handleCustomChange={handleCustomChange}
              //amountAddeds
              setUsedWalletAmmout={setUsedWalletAmmout}
              available_balance={
                (hotelDetails?.data?.wallets?.available_balance || 0) *
                (hotelDetails?.data?.currency?.exchange_rate || 1)
              }
              roomPrice={Number(filterRoomId?.price?.final_price)}
              amountAdded="amountAdded"
              amountAddedLabel="Wallet Amount Added To Checkout"
              amountAddedPlaceholder={`Only upto ${currencySymbol} ${RewardPlaceHolder()}`}
              remainingAmount="remainingAmount"
              remainingAmountLabel="Remaining Amount Due"
              checkedBoxValue={checkedBox}
              handleCheckbox={handleCheckbox}
              styleValue={style}
              currencyCode={currencyCode}
              currencySymbol={currencySymbol}
            />
          )}

        <PrimaryContactInformation
          formikProps={formikProps}
          handleCustomChange={handleCustomChange}
          numberCustomChange={numberCustomChange}
          phoneCountryCodeCustomChange={phoneCountryCodeCustomChange}
          //User
          user="user"
          userLabel="User"
          userOptions={guestListOptions}
          userFromDrop={userFromDrop}
          userPlaceholder="User"
          hideUserSelect={
            process.env.REACT_APP_FREMIUM_USER_TYPE_ID == localUser.type_id
          }
          //Title
          title="title"
          titleLabel="Title"
          titleOptions={optionsTitle}
          titlePlaceholder="Title"
          disableTitle={
            userFromDrop?.title || (!userFromDrop && user.title) ? true : false
          }
          // First Name
          firstName="firstName"
          firstNameLabel="First Name"
          firstNamePlaceholder="Walter"
          disableFirstName={
            userFromDrop?.fname || (!userFromDrop && user.first_name)
              ? true
              : false
          }
          //Last Name
          lastName="lastName"
          lastNameLabel="Last Name"
          lastNamePlaceholder="White"
          disableLastName={
            userFromDrop?.lname || (!userFromDrop && user.last_name)
              ? true
              : false
          }
          //Email
          email="email"
          emailLabel="Email"
          emailPlaceholder="Email"
          // disableEmail={
          //   userFromDrop?.email || (!userFromDrop && user.email) ? true : false
          // }
          disableEmail={false}
          //Phone Number
          countryCode="countryCode"
          countryCodeLabel="Code"
          countryCodePlaceholder="Code"
          phoneNumber="phoneNumber"
          phoneNumberLabel="Phone Number"
          disablePhone={false
            // userFromDrop?.phone || (!userFromDrop && user.phone) ? true : false
          }
          //Nationality
          nationality="nationality"
          nationalityLabel="Nationality"
          nationalityPlaceholder="Select Country"
          disabledNationality={false
            // userFromDrop?.country ||
            // (!userFromDrop && user.user_address.country)
            //   ? true
            //   : false
          }
          // country region 
          //Nationality
          countryRegion="countryRegion"
          countryRegionLable="Country Region"
          countryRegionPlaceholder="Select Country Region"
          disabledCountryRegion={false}
          //Addressone
          addressOne="addressOne"
          addressOneLabel="Address Line 1"
          addressOnePlaceholder="Address Line 1"
          addressOneDisabled={false
            // !userFromDrop && user.user_address.address_line_1 ? true : false
          }
          //AddressTwo
          addressTwo="addressTwo"
          addressTwoLabel="Address Line 2"
          addressTwoPlaceholder="Address Line 2"
          addressTwoDisabled={
            false
            // !userFromDrop && user.user_address.address_line_2 ? true : false
          }
          //city
          city="city"
          cityLabel="City"
          cityPlaceholder="City"
          disabledCity={false
            // !userFromDrop && user.user_address.city ? true : false
          }
          //state
          state="state"
          stateLabel="State/Province/Region"
          statePlaceholder="Select State"
          stateDisable={false
            // !userFromDrop && user.user_address.state ? true : false
          }
          //zipcode
          zipCode="zipCode"
          zipCodeLabel="ZIP/Postal Code"
          zipCodePlaceholder="ZIP/Postal"
          zipDisable={false
            // !userFromDrop && user.user_address.zip ? true : false
          }
        // promo
        />
        <GuestInfo
          ref={childRef}
          formikProps={formikProps}
          handleCustomChange={handleCustomChange}
          handleSubmit={handleSubmit}
          setAdultSelectError={setAdultSelectError}
          adultSelectError={adultSelectError}
          //title2
          title2="title2"
          title2Label="Title"
          title2Options={optionsTitle}
          //firstName2
          firstName2="firstName2"
          firstName2Placeholder="Walter"
          firstName2Label="First Name"
          //newLeadGuest
          guestFirstNamePlaceholder="First Name"
          guestFirstNameLabel="First Name"
          guestMiddleNamePlaceholder="Middle Name"
          guestMiddleNameLabel="Middle Name (if applicable)"
          guestLastNamePlaceholder="Last Name"
          guestLastNameLabel="Last Name"
          //lastName2
          lastName2="lastName2"
          lastName2Label="Last Name"
          lastName2Placeholder="White"
          //Age
          age="age"
          ageOptions={optionsAge}
          ageLabel="Age"
          newLeadGuests={'newLeadGuests'}
          input={input}
          ageOptionChild={ageOptionChild}
          // cbfuction={cbfuction}
          room_id={params.roomId}
          room_no={params.roomNo}
        />

        {/* cancallation poilcy */}
        <div className="card2">
          <h3 className='sectionTitle'>Hotel Cancellation and Change Policy</h3>
          <div className="flight-penalty-info">
            <div className="a table-wrap">
              <table className="tableStyle font14 w-100">
                <thead>
                  <tr>
                    <th className="">
                      Date
                    </th>
                    <th className="">
                      Type
                    </th>
                    <th className="">
                      Refund Amount
                    </th>
                    <th className="">
                      Cancellation Fee
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {hotelDetails?.data?.packages[0]?.
                    boards?.cancellation_policy_v2?.map(
                      (item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <span>
                                {`From ${moment(item?.from_date, "DD-MM-YYYY").format(dateDisplayFormat)} to ${moment(item?.to_date, "DD-MM-YYYY").format(dateDisplayFormat)}`}
                              </span>
                            </td>
                            <td>
                              <span>
                                {(type[item?.display_refund_type_text])}
                              </span>
                            </td>
                            <td>
                              <span>
                                {item?.currency}{" "}
                                {item?.refund_amount || 0}
                              </span>
                            </td>
                            <td>
                              <span>
                                {item?.currency}{" "}
                                {item.total_cancellation_amount}
                              </span>
                            </td>
                          </tr>
                        );
                      }
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {hotelDetails?.data?.hotels?.policies?.length > 0 && <div className='card2 card-policies'>
          <h3 className='sectionTitle'>Policies</h3>
          <div className="row paraText">
            {hotelDetails?.data?.hotels?.policies?.map((ele, index) => {
              return (
                ele?.values?.length > 0 && (
                  <div key={index} className={`col-6  m-fullwidth`}>
                    <h5 className="font600 font14 mb-20">{ele?.group_name}</h5>
                    {ele?.values?.map((ele, index) => {
                      return (
                        <div
                          key={index}
                          className="bulletsList bulletsList2"
                          dangerouslySetInnerHTML={{ __html: ele }}
                        ></div>
                      );
                    })}
                  </div>
                )
              );
            })}
          </div>
        </div>}

        {hotelDetails?.data?.hotels?.checkin_instructions && <div className='card2 card-imp-info'>
          <h3 className='sectionTitle'>Special Instructions</h3>
          <h4 className='sectionTitle'>Check-in Instructions</h4>

          <div
            className=""
            dangerouslySetInnerHTML={{
              __html: hotelDetails?.data?.hotels?.checkin_instructions,
            }}
          ></div>
          <h4 className='sectionTitle'>special check-in instructions
          </h4>
          <div
            dangerouslySetInnerHTML={{
              __html: hotelDetails?.data?.hotels?.special_instructions,
            }}
          ></div>
        </div>}

        {hotelDetails?.data?.hotels?.importantInfo[0]?.description?.length > 0 && (
          <div className='card2 card-imp-info'>
            <> <h3 className='sectionTitle'>Important Information</h3>
              {hotelDetails?.data?.hotels?.importantInfo?.map(
                (ele, index) => {
                  if (ele?.description?.length > 0) {
                    return (
                      <div key={index} className="mb-20">
                        <h4 className="font16 font700 mb-10">{ele?.title}</h4>
                        <div
                          className="bulletsDanegrouslySetHtml mb-30"
                          dangerouslySetInnerHTML={{
                            __html: ele?.description,
                          }}
                        ></div>
                      </div>
                    );
                  }
                }
              )}
            </>
          </div>)}

        <div className="card2 billingInfo GlobalPaymentIntegrationWrap">
          <div className="billingDetailsToggle">
            <ul>
              <li onClick={() => setActiveTab(1)}>
                <a
                  className={activeTab == 1 ? 'active tablink' : 'tablink'}
                  style={{ cursor: 'pointer' }}
                >
                  Pay Using Debit / Credit Cards{' '}
                </a>
              </li>
              <li onClick={() => setActiveTab(2)}>
                <a
                  className={activeTab == 2 ? 'active tablink' : 'tablink'}
                  style={{ cursor: 'pointer' }}
                >
                  Pay Using Other Options
                </a>
              </li>
            </ul>
          </div>

          <div
            id="cardPaymentOption"
            className={`cardPaymentOption tabContent ${activeTab === 1 ? 'activeTab' : ''
              }`}
          >
            <div className="wallet-options-same-height">
              <div className="sectionTitle walletwrapper">
                <h2>Billing Details</h2>
              </div>
              <div className="row">
                <InputField
                  name={'cardHolderName'}
                  type="text"
                  label={'Card Holder Name'}
                  placeholder={'Card Holder Name'}
                  value={values.cardHolderName}
                  error={errors.cardHolderName}
                  cardError={errors.cardHolderName}
                  touched={touched.cardHolderName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="cardHolder"
                />

                <Elements stripe={stripe}>
                  <StripeForm
                    ref={stripeFormRef}
                    clientSecret={hotelIntentData?.stripe_payment_client_secret}
                    setStripeError={setStripeError}
                    stripeError={stripeError}
                  />
                </Elements>
              </div>
            </div>

            <div className="d-flex checkBoxWrap">
              <TermsCheckbox
                name="terms"
                id="terms"
                value={values.terms}
                onChange={(e) => {
                  handleCustomChange(formikProps, 'terms', e.target.checked);
                }}
                error={errors.terms}
                touched={touched.terms}
                onBlur={handleBlur}
              />
              <div className="buttonWrap">
                <div
                  className="desktopViewDisplayNone"
                  style={{ marginTop: '10px' }}
                >
                  <h4>
                    Amount Due Today:{' '}
                    <strong>
                      {' '}
                      &nbsp;{currencySymbol}
                      {
                        filterRoomId?.boards?.price_detail?.price?.total_with_tax || 0
                      }
                    </strong>
                  </h4>
                </div>
                <Button
                  onClick={() => {
                    childRef.current.getRoomsGuests();
                    // setReviewAndVerifyModal(true);
                  }}
                  // className={
                  //   availableBalance <
                  //     parseFloat(filterRoomId?.price?.public_price)
                  //     ? 'disabled btn'
                  //     : 'btn'
                  // }
                  className={
                    availableBalance ? 'disabled btn'
                      : 'btn'
                  }
                  type="submit"
                  id="sbumit-button"
                  // disabled={
                  //   availableBalance <
                  //   parseFloat(filterRoomId?.price?.public_price)
                  // }
                  disabled={availableBalance}

                >
                  <img src={lockImage} /> Pay Now
                </Button>
              </div>
            </div>
          </div>

          <div
            id="walletPaymentOption"
            className={`tabContent WalletPaymentOption  ${activeTab === 2 ? 'activeTab' : ''
              }`}
          >
            <div className="walletOptionWrap">
              <div className="walletWrapper wallet-options-same-height">
                <div className="walletWrapperIn">
                  <ul>
                    <li>
                      <div className="checkBoxContent">
                        <label
                          htmlFor="paypal-id"
                          className="customCheckbox checkbox-label"
                        >
                          <input
                            type="radio"
                            id="paypal-id"
                            name="WalletPayment"
                          />
                          <span></span>
                          <div className="wallet-icon-wrap">
                            <div className="wallet-icon">
                              <img src={paypal} alt="wallet icon" />
                            </div>
                            <h6>PayPal</h6>
                          </div>
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="checkBoxContent">
                        <label
                          htmlFor="apple-id"
                          className="customCheckbox checkbox-label"
                        >
                          <input
                            type="radio"
                            id="apple-id"
                            name="WalletPayment"
                          />
                          <span></span>
                          <div className="wallet-icon-wrap">
                            <div className="wallet-icon">
                              <img src={applePay} alt="wallet icon" />
                            </div>
                            <h6>Apple Pay</h6>
                          </div>
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="checkBoxContent">
                        <label
                          htmlFor="Google-id"
                          className="customCheckbox checkbox-label"
                        >
                          <input
                            type="radio"
                            id="Google-id"
                            name="WalletPayment"
                          />
                          <span></span>
                          <div className="wallet-icon-wrap">
                            <div className="wallet-icon">
                              <img src={googlePay} alt="wallet icon" />
                            </div>
                            <h6>Google Pay</h6>
                          </div>
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="d-flex checkBoxWrap">
                <TermsCheckbox
                  name="terms"
                  id="terms"
                  value={values.terms}
                  onChange={(e) => {
                    handleCustomChange(formikProps, 'terms', e.target.checked);
                  }}
                  error={errors.terms}
                  touched={touched.terms}
                  onBlur={handleBlur}
                />

                <div className="buttonWrap">
                  <div
                    className="desktopViewDisplayNone"
                    style={{ marginTop: '10px' }}
                  >
                    <h4>
                      Amount Due Today:{' '}
                      <strong>
                        {' '}
                        &nbsp;{currencySymbol}
                        {
                          filterRoomId?.boards?.price_detail?.price?.total_with_tax ||
                          0
                        }
                      </strong>
                    </h4>
                  </div>
                  <Button
                    onClick={() => {
                      // setReviewAndVerifyModal(true);
                      childRef.current.getRoomsGuests();
                    }}
                    // className={
                    //   availableBalance <
                    //     parseFloat(filterRoomId?.price?.public_price)
                    //     ? 'disabled btn'
                    //     : 'btn'
                    // }
                    className={
                      availableBalance ? 'disabled btn'
                        : 'btn'
                    }
                    type="submit"
                    id="sbumit-button"
                    disabled={availableBalance}
                  >
                    <img src={lockImage} /> Pay Now
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form >
    );
  };

  const [openPriceUpdateModal, setOpenPriceUpdateModaL] = useState(
    hotelDetails?.data?.price_update_type === 'up' ||
    hotelDetails?.data?.price_update_type === 'down'
  );
  const modalContent = `
  Please review and verify all your booking information (incl. Names, Dates, Location, etc.) regarding its accuracy to avoid any booking complications. <br />
  Due to Tripello's Dynamic Pricing, Hotel Booking Dates cannot be changed after the Booking has been finalized, as we cannot guarantee the same prices at a different date. Your booking would have to be cancelled and a new booking would have to be placed. This can <strong>ONLY</strong> be done if the booking is <strong>REFUNDABLE</strong> (cancellation policies still apply).
  `;
  return (
    <>
      {!hotelDetails?.data?.price_updated && (
        <PriceCheckModel
          openModal={openPriceUpdateModal}
          closeModal={() => {
            setOpenPriceUpdateModaL(false);
          }}
          text={
            hotelDetails?.data?.price_update_type === 'up' ? (
              <>
                <h2>
                  Price For This Hotel Has Increased By $
                  {hotelDetails?.data?.price_difference}
                </h2>
                <h3>
                  Your selection may have changed. Please review cancellation
                  policies & other inclusions before booking at the revised
                  price.
                </h3>{' '}
              </>
            ) : (
              <>
                <h2>
                  Price For This Hotel Has Decreased By $
                  {hotelDetails?.data?.price_difference}{' '}
                </h2>
                <h3>
                  Your selection may have changed. Please review cancellation
                  policies & other inclusions before booking at the revised
                  price.{' '}
                </h3>{' '}
              </>
            )
          }
          icon={IncreaseIcon}
          type={hotelDetails?.data?.price_update_type}
          routeFlag="hotel"
        />
      )}

      {loading && <Loader />}
      <ErrorModel
        openErrorModal={openErrorModal}
        handleErrorModal={handleErrorModal}
        message={sessionExpireError || stripeIntentError || bookingDataError}
        sessionExpireError={sessionExpireError}
        stripeIntentError={stripeIntentError}
        type="hotel"
      />
      <HotelSoldOutModal
        openSoldOutModal={openSoldOutModal?.open}
        handleSoldOutModal={handleSoldOutModal}
        message={openSoldOutModal?.message}
      // sessionExpireError={sessionExpireError}
      // stripeIntentError={stripeIntentError}
      // type="hotel"
      />
      <div
        className={
          !openModal
            ? 'siteBg listingPage checkoutPageWrap hotel-checkout'
            : 'siteBg listingPage checkoutPageWrap hotel-checkout popupOpned'
        }
      >
        {hotelDetails && <div className="pageWrap pageWrapInner">
          <div className="fixedHeader">
            <HeaderNavbar useBlueIcon={true} />
          </div>
          <section className="mainContent">
            <Scrollbars
              autoHide
              autoHeight={true}
              autoHeightMin={120}
              autoHeightMax={`calc(100%)`}
              className="pageInternalScroll"
            >
              <div className="desktop-hide mobile-popup-bar">
                <div
                  className="booking-details-title"
                  onClick={handleOpenModal}
                >
                  <h4>Review Booking Details</h4>
                  <div className="info-icon">
                    <img src={reviewImg} alt="icon" />
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="checkoutWrapper">
                  <div className="row">
                    <div className="col leftContent">
                      <Formik
                        onSubmit={handleSubmit}
                        innerRef={formikRef}
                        initialValues={initialValues}
                        validationSchema={HotelCheckoutValidation}
                        validateOnBlur={false}
                        enableReinitialize={true}
                      >
                        {renderForm}
                      </Formik>
                    </div>
                    <HotelDetailCard
                      hotels={hotels}
                      rates={filterRoomId}
                      input={input}
                      usedWalletAmmout={usedWalletAmmout}
                      currencyCode={currencyCode}
                      currencySymbol={currencySymbol}
                      isRefundable={
                        hotelDetails?.data?.packages?.[0]?.refundable
                      }
                    />
                  </div>
                </div>
              </div>
            </Scrollbars>
          </section>
          <FooterWithMediaIcon
            className="white-icon"
            iconClassName="icon-white"
          />
        </div>}

        {openModal && (
          <HotelCheckoutModal
            modalOpen={openModal}
            modalClose={handleCloseModal}
            image={imageBackground}
            locationGray={locationGray}
            datePicker={datePicker}
            rightArrowImg={rightArrowImg}
            timerImg={timerImg}
            infoGray={infoGray}
            hotels={hotels}
            rates={filterRoomId}
            input={input}
            usedWalletAmmout={usedWalletAmmout}
            currencyCode={currencyCode}
            currencySymbol={currencySymbol}
            isRefundable={
              hotelDetails?.data?.rates?.[0]?.package?.is_refundable
            }
          />
        )}
        {openReviewAndVerifyModal && <ReviewAndVerifyModal
          openModal={openReviewAndVerifyModal}
          closeModal={handleReviewAndVerifyModal}
          title="For Your Peace Of Mind"
          content={modalContent}
          handelPayNow={handelPayNow}
        />
        }
      </div>
    </>
  );
}
export default HotelCheckout;
