import http from "./http";

function searchFlightService(payload, use_search_id) {
  return http
    .post(`${process.env.REACT_APP_FLIGHT_BASE_URL}/api/flight/search/v3`, {
      // node
      ...payload,
      params: {
        use_flight_search_id: use_search_id ? true : false,
      },
    })
    .then((response) => response);
}

function searchFlightDetailsService(payload, use_search_id) {
  return http
    .post(`${process.env.REACT_APP_FLIGHT_BASE_URL}/api/flight/details`, {
      // node
      ...payload,
      params: {
        use_flight_search_id: true,
      },
    })
    .then((response) => response);
}

function searchFlightFareRuleService(payload, use_search_id) {
  return http
    .post(`${process.env.REACT_APP_FLIGHT_BASE_URL}/api/flight/fare-rules/v2`, {
      // node
      ...payload,
      params: {
        use_flight_search_id: true,
      },
    })
    .then((response) => response);
}

function getFlightFeaturesAction(payload) {
  return http.post(
    `${process.env.REACT_APP_FLIGHT_BASE_URL}/api/flight/features/v2`,
    {
      ...payload,
      params: { use_flight_search_id: true },
    }
  );
}

function cancelledBooking(payload) {
  return http.post(
    `${process.env.REACT_APP_BASE_URL_FLIGHT_BACKOFFICE_URL}/api/v1/flight/cancel`,
    {
      ...payload,
    }
  );
}

function searchFlightPollingService(payload) {
  return http
    .post(
      `${process.env.REACT_APP_FLIGHT_BASE_URL}/flights/search-v2/cabin-prices`,
      {
        ...payload,
        params: {
          flight_search_key: true,
          flight_search_label: "flight-search-key",
          headerName: "search-id-2",
        },
      }
    )
    .then((response) => response);
}

function validateFlight(payload) {
  return http.post(
    // `${process.env.REACT_APP_FLIGHT_BASE_URL}/api/flight/revalidate?sold_out=true`,
    // `${process.env.REACT_APP_FLIGHT_BASE_URL}/api/flight/revalidate?price_down=true`,
    // `${process.env.REACT_APP_FLIGHT_BASE_URL}/api/flight/revalidate?price_up=true`,
    `${process.env.REACT_APP_FLIGHT_BASE_URL}/api/flight/revalidate/v2`,
    {
      ...payload,
      params: {
        use_flight_search_id: true,
      },
    }
  );
}

function checkoutFlightService(payload) {
  // flight book
  return http
    .post(
      `${process.env.REACT_APP_FLIGHT_BASE_URL}/api/flight/book/v2`, // node
      {
        ...payload,
        params: {
          use_flight_search_id: true,
        },
      }
    )
    .then((response) => response);
}

function getFlightBookingDetailsService(id) {
  return http
    .get(
      // `${process.env.REACT_APP_FLIGHT_BASE_URL}/api/flight/bookings/details/${id}`
      `${process.env.REACT_APP_BASE_URL_FLIGHT_BACKOFFICE_URL}/api/v1/flight/${id}`
    )
    .then((res) => res);
}

function getStripeIntent(payload) {
  return http.post(
    // `${process.env.REACT_APP_FLIGHT_BASE_URL}/api/flight/payment/initiate`,/
    `${process.env.REACT_APP_BASE_PAYMENT_INTENT_URL}/api/paymentIntent`,
    {
      // node
      ...payload,
      // params: {
      //   use_flight_search_id: true,
      // },
    }
  );
}

function downloadPdf(id) {
  return http.get(`flight-reciept-download-api`, {
    // replace with flight api
    params: {
      use_base_hotel_url: true,
    },
  });
}

function flightPrebook(payload, id) {
  return http.post(
    `${process.env.REACT_APP_FLIGHT_BASE_URL}/api/flight/pre-book`,
    {
      ...payload,
      params: { use_flight_search_id: true },
    }
  );
}

//  get avaiable in wallet
function getAvailableBalance() {
  return http.get(
    `${process.env.REACT_APP_BASE_URL_TENANT}/v1/balance/?vertical=flights&tenant_id=${process.env.REACT_APP_TENANT_ID}`,
    {}
  );
}

function cancelRefundDetails(id) {
  return http.get(
    `${process.env.REACT_APP_BASE_URL_FLIGHT_BACKOFFICE_URL}/api/v1/flight/cancellation-details/${id}`,
    {}
  );
}

export const flightService = {
  searchFlightService,
  searchFlightDetailsService,
  searchFlightPollingService,
  getFlightFeaturesAction,
  validateFlight,
  checkoutFlightService,
  getFlightBookingDetailsService,
  getStripeIntent,
  cancelledBooking,
  downloadPdf,
  flightPrebook,
  getAvailableBalance,
  searchFlightFareRuleService,
  cancelRefundDetails,
};
