import { Tabs } from "antd";
import React, { useRef, useState } from "react";
// import { getCookie } from "../../Util/util";
import Scrollbars from "react-custom-scrollbars";
import { ReactComponent as CondosWhite } from "../../Assets/img/icons/condos-icon-white.svg";
import memberInviteImg from "../../Assets/img/member-invite-heading.png";
import { ReactComponent as MemberGreyIcon } from "../../Assets/img/support/male-user-shadow-grey.svg";
import SupportForm from "../../Component/CustomerPortal/SupportForm.jsx";
import VHomesTab from "../../Component/CustomerPortal/VHomesTab.jsx";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import HeaderNavbar from "../../Component/HeaderNavbar";

function Support() {
  const scrollPage = useRef(null);
  // const backgroundVideo = useRef(null);
  const [showScrollTopBtn, setShowScrollTopBtn] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  // const [portraitViewModal, setportraitViewModal] = useState(
  //     window.outerWidth > 767 && window.outerWidth < 1025 ? true : false
  // );
  //setting length after which sticky header and scrollToTop button show
  const [isFreemium, setIsFreemium] = useState(false);
  const [tab, setTab] = useState("1");
  const tabsConts = [
    {
      title: "Hotels",
      icon: "icon-apartments",
      tabImage: null,
      slug: "hotel",
      component: <SupportForm type="Hotel" tab={tab} tabKey="1" />,
    },
    {
      title: "Flights",
      icon: "icon-flight",
      tabImage: null,
      slug: "flight",
      component: <SupportForm type="Flight" tab={tab} tabKey="2" />,
    },
    {
      title: "Cars",
      icon: "icon-shape",
      tabImage: null,
      slug: "cars",
      component: <SupportForm type="cars" tab={tab} tabKey="3" />,
    },
    {
      title: "Stays",
      icon: "icon-building",
      tabImage: null,
      slug: "stays",
      component: <SupportForm type="vacation_rentals" tab={tab} tabKey="4" />,
    },
    {
      title: "V – Homes",
      icon: null,
      slug: "v-homes",
      tabImage: <CondosWhite />,
      component: <VHomesTab />,
    },
    {
      title: "Membership",
      icon: null,
      tabImage: <MemberGreyIcon />,
      slug: "membership",
      tabSvgImageClass: "membership-icon",
      component: <SupportForm type="Membership" tab={tab} tabKey="6" />,
    },
  ];
  //  useEffect(() => {
  //      const isFreemium = setIsFreemium(getCookie("is_premium") !== "true");
  // }, []);
  //const isFreemium = process.env.REACT_APP_FREMIUM_USER_TYPE_ID == JSON.parse(localStorage?.user)?.type_id
  //const isFreemium = true

  // const handleScroll = () => {
  //     if (
  //         (scrollPage.current.scrollTop >= 4 && !showHeader) ||
  //         scrollPage.current.scrollTop >= 150
  //     ) {
  //         setShowHeader(true);
  //         setShowScrollTopBtn(true);
  //     }
  //     if (
  //         scrollPage.current.scrollTop < 4 ||
  //         scrollPage.current.scrollTop < 150
  //     ) {
  //         setShowHeader(false);
  //         setShowScrollTopBtn(false);
  //     }
  // };

  //jumping to top of the page on button click
  const scrollElementToTop = () => {
    scrollPage.current.scrollTop = 0;
  };
  // useEffect(() => {
  //     scrollPage.current.addEventListener("scroll", handleScroll);
  //     window.addEventListener("resize", function () {
  //         if (window.outerWidth > 767 && window.outerWidth < 1025) {
  //             setportraitViewModal(true);
  //         } else {
  //             setportraitViewModal(false);
  //         }
  //     });
  //     return () => {
  //         scrollPage?.current?.removeEventListener("scroll", handleScroll);
  //         window.removeEventListener("resize", function () {
  //             if (window.outerWidth > 767 && window.outerWidth < 1025) {
  //                 setportraitViewModal(true);
  //             } else {
  //                 setportraitViewModal(false);
  //             }
  //         });
  //     };
  // }, []);

  return (
    <div
      className={
        !showHeader
          ? `siteBg support-page`
          : "siteBg support-page header-sticky"
      }
    >
      <div className="pageWrap pageWrapInner" ref={scrollPage}>
        <div className="fixedHeader">
          <HeaderNavbar useBlueIcon={true} />
        </div>
        <section className="mainContent">
          <Scrollbars
            autoHeight={true}
            autoHeightMin={120}
            autoHeightMax={`calc(100%)`}
            className="pageInternalScroll mCustomScrollbar _mCS_1"
          >
            <div className="container  pageInternalScrollTopPadding">
              <div className="rewardsSection">
                <div className="row no-gutters">
                  <div className="col leftContent">
                    <div className="container">
                      <div className="content-title">
                        <h1>Tripello Customer Service Portal</h1>
                        <div className="content">
                          <p>
                            24/7 Member Support in all time zones for
                            Membership, Booking or Payment Gateway related
                            issues.
                          </p>
                          <p>
                            {" "}
                            Please choose the category that you need assistance
                            with, then complete and submit the form. To better
                            assist you please provide us with all important
                            details.{" "}
                          </p>
                          <p>
                            If this is a matter of Urgency / Emergency please
                            enter the best call back phone number (including
                            international calling code) as well as email address
                            into the description field.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col rightContent">
                    <div className="imgContent">
                      <div className="image">
                        <img src={memberInviteImg} alt="icon" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tabs-wrapper widget-page-wrap">
                <Tabs
                  defaultActiveKey="1"
                  activeKey={tab}
                  onChange={(key) => setTab(key)}
                  items={tabsConts.map((item, i) => {
                    const id = String(i + 1);
                    const ChildComponent = item.component;
                    return {
                      label: (
                        <>
                          <span key={item.slug} className={item.icon}>
                            <span className="path1"></span>
                          </span>
                          {item.tabImage && (
                            <span
                              className={`icon-image ${item.tabSvgImageClass}`}
                            >
                              {item.tabImage}
                            </span>
                          )}
                          <span className="tab-title">{item.title}</span>
                        </>
                      ),
                      key: id,
                      children: (
                        <>
                          {isFreemium &&
                          (item.slug === "flight" ||
                            item.slug === "cars" ||
                            item.slug === "stays") ? (
                            <div className="condition-base-data">
                              Premium Member Access Only
                            </div>
                          ) : (
                            ChildComponent
                          )}
                        </>
                      ),
                    };
                  })}
                  // onTabClick={(e) => {
                  //     setTab(e);
                  // }}
                />
              </div>
            </div>
          </Scrollbars>
        </section>
      </div>
      <FooterWithMediaIcon />
      {showScrollTopBtn ? (
        <div
          id="back-to-top"
          className="back-to-top"
          onClick={scrollElementToTop}
        >
          <button className="btn btn-primary" title="Back to Top">
            <svg
              version="1.1"
              id="Capa_1"
              x="0px"
              y="0px"
              viewBox="0 0 31.479 31.479"
              style={{ enableBackground: "new 0 0 31.479 31.479" }}
              width="18px"
              height="18px"
              className="svg lazyloading replaced-svg"
            >
              <path
                style={{ fill: "#004185" }}
                d="M26.477,10.274c0.444,0.444,0.444,1.143,0,1.587c-0.429,0.429-1.143,0.429-1.571,0l-8.047-8.047  v26.555c0,0.619-0.492,1.111-1.111,1.111c-0.619,0-1.127-0.492-1.127-1.111V3.813l-8.031,8.047c-0.444,0.429-1.159,0.429-1.587,0  c-0.444-0.444-0.444-1.143,0-1.587l9.952-9.952c0.429-0.429,1.143-0.429,1.571,0L26.477,10.274z"
              ></path>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
          </button>
        </div>
      ) : null}
    </div>
  );
}

export default Support;
