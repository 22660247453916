/* eslint-disable no-mixed-spaces-and-tabs */
export const FlightFaqsList = [
	{
		id: 0,
		title: 'When should I arrive at the airport?',
		description1: 'For Domestic travel, plan to arrive at the airport a minimum of 2 hours in advance of departure.',
		description2: 'For International travel, plan to arrive at the airport a minimum of 3 hours in advance of departure, unless your carrier advises otherwise.',
	},
	{
		id: 1,
		title: 'Baggage Fees and Carry On Limits',
		headingLine1: 'Is there an extra fee for my checked or carry-on bags?',
		description1: 'Each airline has its own baggage policy. Some airlines don’t charge at all for bags, while others add a fee for checked and/or carry-on bags. Airlines that allow you to check in online may have lower baggage fees. Please check the Airlines websites for details.',
		headingLine2: 'Are baggage fees included in the price of my ticket?',
		description2: 'Baggage fees are not included in your ticket price. If your airline charges baggage fees, you’ll pay these fees when you check in at the airport',
	},
	{
		id: 2,
		title: 'Can I cancel my ticket?',
		description1: 'Yes, you can cancel your ticket within the first 24 hours after your purchase. If cancellation is permitted, it may be done online using the Cancel Reservation option on your itinerary or by calling us.',
	},
	{
		id: 3,
		title: 'Can I change or correct the name on my ticket?',
		description1: 'Airline tickets cannot be transferred to another individual. The name on the ticket must match the government-issued photo ID being presented at check-in. If the tickets were purchased within the past 24 hours, you may have the option to cancel and rebook.',
		description2: 'Cancellation rules vary by airline and type of fare purchased.',
		description3: 'If cancellation is permitted, it may be done online using the Cancel Reservation option on your itinerary or by calling us. You can find your itinerary by going to Bookings in the Tripello Portal..',
		description4: 'Once the cancellation is processed, you can make a new booking. Making a new booking is usually a far less costly alternative to the airline fees associated with correcting the name on a ticket but must be done within 24 hours of purchase.',
		description5: 'After the 24-hour period, the payment is submitted to the airline and their name correction policies (including fees) take effect. Tripello has no control over the airline’s name correction policies or fees. Even if it’s been more than 24-hours since you’ve made your purchase, there are some scenarios noted below where you may be able to travel without correcting the name.',
		description6: 'Important Note: In the scenarios below, Domestic Travel is defined as travel within the U.S. and U.S. Territories and International Travel is defined as travel between two or more countries.',
		headingListLine6: 'Used Maiden Name Instead of Married Name:',
		list1:[
			'Domestic Travel - You may be able to present a valid government-issued ID and marriage certificate to pass through security. This policy depends on the airline and the airport security at time of check-in. Please contact your airline and the Transportation Safety Administration to confirm.',
			'International Travel - The name on the ticket must match the name on the passport.'
		],      
		headingList2Line6: 'Misspelled Name by two letters or less:',
		list2:[
			'Domestic Travel - Most airlines and the TSA will allow you to board/pass through security. This policy depends on the airline and the airport security at time of check-in. Please contact your airline and the Transportation Safety Administration to confirm.',
			'International Travel - The name on the ticket must match the name on the passport.'
		],
		headingList3Line6: 'Forgot to add suffix or middle name:',
		list3:[
			'Domestic Travel - Omitting this information should not impact your ability to pass through security. Please contact your airline and the Transportation Safety Administration to confirm.',
			'International Travel - The name on the ticket must match the name on the passport.'
		],
		heaidngList4Line6: 'Hyphenated name merged into single name:',
		list4:{
			list1:'Domestic and International Travel - Airline booking systems automatically remove hyphens and combine the name. This is not a problem on either domestic or international tickets. For all other name issues, contact us and we\'ll be happy to contact your ticketed airline to inquire about a name correction (when allowed, fees will apply!).'
		}
	},
	{
		id: 4,
		title: 'Flight Check-in Guidelines',
		headingLine1: 'How do I check in for my flight?',
		description1: 'Check-in options vary by airline, but you will always check in directly with your airline. See at the top of the page for direct links to the Airlines Check-In pages.',
		headingLine2: 'Online Check-In',
		description2: 'Many airlines allow you to check in online 24 to 72 hours before your scheduled departure time. Online check-in enables you to skip the lines at the airport. Some airlines even offer discounted baggage fees when you check in online. It’s helpful to have your airline confirmation number and ticket number handy when you check-in online. These can be found on your itinerary, which you can access through your Bookings on the Tripello Portal page.',
		headingLine3: 'Airport Check-In',
		description3: 'Unless your airline\'s website suggests an earlier arrival, plan to arrive at the airport a minimum of 2 hours before your departure time for domestic flights and a minimum of 3 hours before departure time for international flights.',
		description4: 'Depending on your airline, your check-in options may include online check-in prior to arriving at the airport, self-service kiosks at the ticket counter or in-person check-in at the ticket counter. Check with your airline to see which options are available.',
		list5:[
			'If you’re flying on multiple airlines, check in on each airline’s website or, if in person, ask the airline representative for your connecting flight’s boarding pass.',
			'If you are flying on a codeshare airline (you’ll see this on your ticket designated as “operated by”), you can check in online with the airline who sold the ticket, then go to the “operating carriers” baggage drop counter and ""operating carriers"" ticket counter.',
			'Airline ticket counters, baggage drop and gate areas are located in the operating airline’s airport ‌terminal.',
			'If you have additional questions about flight check-in procedures that we have not answered, please contact your airline.'
		]
        
	},
	{
		id: 5,
		title: 'What is an E-Ticket?',
		description1: 'E-tickets are digital records stored in your airline\'s reservations system. They record your flight details, ticket cost and form of payment. E-tickets are attached electronically to your airline reservation and are proof of payment for a flight.',
		description2: 'Your airline will require either your E-ticket or confirmation number when checking in for your flight. Both numbers are available in the Passenger and Ticket Information section of your itinerary. You can find your itinerary by going to Bookings in the Tripello Portal.',
	},
	{
		id: 6,
		title: 'How do I correctly enter Passenger Names?',
		description1: 'Enter passenger name(s) exactly as they appear on the government issued photo identification you plan to provide to airline officials at check-in.',
		description2: 'Passengers will encounter difficulty passing through airport security or may be denied boarding if their names do not match the identification being presented, and corrections are often not permitted once tickets are confirmed.',
		description3:
        <>
        	<strong>Important Note:</strong> In the scenarios noted
             below, Domestic Travel is defined as travel within the
             U.S. and U.S. Territories and International Travel is
             defined as travel between two or more countries.
        </>,
		headingLine4: 'What if I don\'t have a middle name?',
		description4:
        <>
        Domestic Travel - This is an optional field.<br /> International Travel - The name on the ticket must match the name on the passport.
        </>,
		headingLine5: 'What about hyphenated or multiple names?',
		description5: 'Domestic and International Travel - Hyphens and spaces are not required and will be removed by the airline reservation systems.',
		headingLine6: 'Do I have to add a suffix?',
		description6:
        <>
        Domestic Travel - This is an optional field.<br /> International Travel - The name on the ticket must match the name on the passport.
        </>,
		headingListLine6: 'What if two names are the same and neither have a suffix?',
		description7: 'Domestic and International Travel - You will need to differentiate by adding a suffix, middle name or middle initial to one of the passengers.',
		headingLine8: 'There is not enough space to enter my entire name!',
		description8: 'Domestic and International Travel - Before shortening or removing a portion of the name to confirm the booking, please contact your airline to obtain guidance on the best way to modify the name so you can complete the booking process.',
		headingLine9: 'What if I only have one name?',
		description9: 'Domestic and International Travel - The same name should be entered in both the first and last name fields.',
		headingLine10: 'What if my last name has only one letter?',
		description10: 'Domestic and International Travel - Consider contacting the airlines directly.',
		headingLine11: 'Should I use my Maiden or Married Name?',
		description11: 'Domestic and International Travel - The passenger name must match the name as it appears on the government issued photo identification you plan to provide to airline officials at check-in. You will encounter difficulty passing through airport security or may be denied boarding if the name does not match the identification being presented.',
		headingLine12: 'Can I enter my nickname instead of my full first name?',
		description12: 'Domestic and International Travel - The passenger name must match the name as it appears on the government issued photo identification you plan to provide to airline officials at check-in. You will encounter difficulty passing through airport security or may be denied boarding if the name does not match the identification being presented.',
		description13: 'If you have already purchased your tickets, please contact your airline to confirm the name entered meets their guidelines.',
	},
	{
		id: 7,
		title: 'How do I exchange?',
		description1: 'Exchange rules and fees vary by airline and type of fare purchased. You can view your flight’s fare rules on the contract before you book, and on your itinerary after you book. You can find your itinerary by going to Bookings in the Tripello Portal. Some airlines require that all flight changes be made a minimum of 24 hours prior to departure time. Here are some basic tips when changing your tickets.',
		list6:[
			'Identify your new travel dates and desired itinerary on the same airline then call us to exchange your ticket.',
			'The price of your new itinerary may be different than your original ticket. You will be responsible for paying the difference along with the airline’s change and any additional fees that may apply. If the new fare is lower, consider calling your airline directly to make the change. We are not able to provide an airline travel voucher for the fare reduction amount.',
			'Name changes and ticket transfers are not permitted.',
			'We are not able to override an airline’s fare rules or change fees.',
			'If you are undecided or not ready to book a new reservation, some airlines will allow you to cancel your existing reservation and retain the ticket value as a credit for future travel on the same airline.'
		]
	},
	{
		id: 8,
		title: 'Identification Guidelines',
		headingLine1: 'Identification and Documentation Requirements',
		description1: 'If you are unsure of your travel requirements, please contact the embassy or consulate of the countries you are entering or passing through to make sure you meet all travel requirements.',
		list6:[
			'Identification and documentation requirements are determined by your citizenship and the countries you will be traveling to, through or from.',
			'Travelers who are unable to provide required documentation are subject to denied entry',
			'You’re responsible to make sure you have the correct documents to enter and pass through any countries during your trip and make your way back home',
			'Everyone traveling, regardless of age, needs their own set of travel documents'
		],
        
		headingLine2: 'Domestic travel within the U.S. and U.S. Territories',
		list7:[
			'U.S. Citizens: Travelers 18 years of age and over, flying within the U.S. will require a government issued photo ID. The name on the ticket should match the name on the ID. Citizens under the age of 18 do not require identification.',
			'Non-US Citizens: (all ages) traveling within the U.S. are required to carry a Passport, Permanent Resident Card and/or other U.S. government issued ID.'
		],
        
		headingListLine3: 'Domestic travel outside the U.S.',
		list8:[
			'Documentation requirements vary by country.',
			'Please check with the local government for documents required for domestic travel.',
		],
		headingListLine4: 'International Travel – Adults and Children',
		list9:[
			'Always use the information exactly as it appears on your passport when booking travel and completing documents.',
			'Some countries require proof of return or onward travel, a visiting address and sufficient funds for your stay.',
			'All International Travelers will require a valid Passport.',
			'Some countries have additional entry/transit restrictions for passports with specific validity periods, number of free pages or countries previously visited.',
			'Some do not allow entry (or to travel through) their country if the visitors is a citizen of certain country',
			'Please contact the embassy or consulate of the countries you are entering or passing through to make sure you meet all travel requirements.'
		],
		headingListLine5: 'Other Required Documents:',
		list10:{
			list1:'In addition to a passport, the countries you are traveling to (or transiting through) may require additional documentation such as entry, exit or transit visas or other notarized documents'
		},
		headingListLine6: 'International Travel – Children Under 18 (with or without parents)',
		list11:[
			'Some countries require additional documentation for Travelers under the age of 18. This typically includes certified birth certificates (certified within the last 3 months) and/or notarized travel authorizations.',
			'Requirements may vary depending on whether the child is traveling with one parent, two parents, or an adult other than a parent.',
			'When checking with each country you are entering (or traveling through), let them know you are traveling with a child and ask about additional children’s documentation.'
		]
	},
	{
		id: 9,
		title: 'What do I do if I lost my ID?',
		description1: 'Identification and documentation requirements are determined by your citizenship and by the countries you will be traveling to, through or from. Travelers who are unable to provide required documentation may be denied boarding without compensation, detained, and/or deported at their own cost. If you are unsure of your travel requirements, please contact www.tsa.gov. Enter the words "ID Requirements" in the search window at the top of the page.',
	},
	{
		id: 10,
		title: 'Advice to International Passengers on Limitations of Liability',
		headingLine1: 'IMPORTANT INFORMATION',
		description1: 'Passengers embarking upon a journey involving an ultimate destination or a stop in a country other than the country of departure are advised that the provisions of an international treaty (the Warsaw Convention, the 1999 Montreal Convention, or other treaty), as well as a carrier’s own contract of carriage or tariff provisions, may be applicable to their entire journey, including any portion entirely within the countries of departure and destination. The applicable treaty governs and may limit the liability of carriers to passengers for death or personal injury, destruction or loss of, or damage to, baggage, and for delay of passengers and baggage.',
		description2: 'Additional protection can usually be obtained by purchasing insurance from a private company. Such insurance is not affected by any limitation of the carrier’s liability under an international treaty. For further information, please consult your airline or insurance company representative.',
		description3: 'Further information may be obtained from the carrier as to the limits applicable to your journey. If your journey involves carriage by different carriers, you should contact each carrier for information on the applicable limits of liability.',
	},
	{
		id: 11,
		title: 'Do I need to Confirm My Flight?',
		description1: 'The easiest way to avoid any last-minute surprises on your trip is to reconfirm each flight 24 hours before each travel date. This allows you to be absolutely certain your flight is leaving when you think it is. Airlines often change flight schedules and it is the Traveler\'s responsibility to be aware of the change.',
		description2: 'Please check your airline’s website or call them for the most up to date flight details. If your airline offers online check-in, it’s a great idea to check-in online after you have confirmed your flight.',
	},
	{
		id: 12,
		title: 'Schedule Changes',
		description1: 'Airlines, for various reasons, will adjust your itinerary, most often the flight times, this is common practice. The easiest way to avoid any last-minute surprises on your trip is to reconfirm your flight and check-in location prior to each departure with your airline. This allows you to be absolutely certain your flight is leaving when you think it is.',
		description2: 'If your itinerary undergoes a schedule change, we will notify you of the change via email. It is the Traveler\'s responsibility to be aware of the change. If a schedule change results in an unacceptable itinerary, please call us as quickly as possible.',
	},
	{
		id: 13,
		title: 'How do I reserve or confirm my seats?',
		description1: <><strong>Seating Guidelines </strong></>,
		description2: 'Not all airlines allow you to choose your seat for free in advance. If the airline allows advance seat selection, you can usually request seats on our site when purchasing your ticket. Seat selections are not guaranteed because airlines sometimes change aircraft and must re-assign seats.',
		description3: 'If you don’t see a seat map during or after your purchase, it means we are not able to request seats in advance for your particular flight. However, you still may be able to request seats with the airline directly.',
		description4: 'Note: Some airlines offer Premium Seating for an extra fee that includes advance seat selection. They charge these non-refundable fees directly. If you purchased a Premium seat and have questions about your charges, contact your airline directly.',
		description5: <><strong>Paid Seating </strong></>,
		description6: 'Paid seating is currently available on select Airlines and may include Main Cabin Extra and/or Preferred Seats.',
		description7: 'Paid seating can be purchased when booking your flights. If paid seating is available, you will see paid seats on the seat map and can select them. Paid seating options can also be purchased after you have booked your reservation; look for the "Paid Seats" banner or click the "Change Seat" link in the Passenger and Ticket information section of your itinerary. If you do not see the "Paid Seating" options, the seating is either unavailable or we are not yet offering paid seating for your flight. You may also check the airline\'s website for seating options.',
		description8: 'Charges are shown per passenger and per flight segment; available flights and prices vary, are non-transferable, and are only valid for the flight purchased.',
		description9: 'Customers who purchase a paid seat and are impacted by an airline initiated flight and/or seat change, equipment change, routing change, or flight cancellation are eligible for a refund, automatically initiated by the airline. For questions on airline refunds, please contact your airline',
		description10:
        <>
        	<small>If you do not want to pay extra for seats, you can have your seats assigned upon check-in with your airline.
        	</small>
        </>,
	},
	{
		id: 14,
		title: 'What are the taxes and fees?',
		description1: 'The following taxes and fees may apply to airline ticket purchases. The actual taxes and fees for your purchase will be displayed during purchase.',
		headingLine2: 'Government Imposed Taxes and Fees:',
		list7:[
			<><strong>September 11th Security Fee:</strong> $5.60 USD applies per one-way trip. There may be multiple one-way trips on the same itinerary.</>,
			<><strong>Passenger Facility Charges:</strong> Up to $18.00 USD depending on the itinerary.</>,
			<><strong>Travel Facilities Tax:</strong> Effective Jan. 1, 2020 a tax of $19.00 will be imposed per round-trip for domestic flights beginning or ending in Alaska or Hawaii.</>,
			<><strong>Federal Domestic Flight Segment Fee:</strong> Effective, Jan. 1, 2020 a fee of $4.30 USD will be imposed per flight segment. A flight segment is defined as one takeoff and one landing.</>,
			<><strong>U.S. International Departure Tax:</strong> Effective Jan. 1, 2020 a tax of 18.90 USD per departure applies to international tickets departing from the U.S.</>,
			<> <strong>U.S. International Arrival Tax:</strong> International flights arriving into the U.S. are assessed the following taxes - US International Arrival tax of $18.90 USD, US Customs fee of $5.89 USD, Immigration (INS) fee of $7.00 USD and Animal and Plant Health Inspection fee (APHIS) of $3.96 USD.</>,
			<><strong>International Taxes:</strong> International travel incurs additional international taxes, the amounts of which can vary dramatically depending upon routing and destination.</>,
			// eslint-disable-next-line react/no-unescaped-entities
			<><strong>Entry/Exit Fees:</strong> If you are traveling internationally, some countries (or airports) charge fees upon entering and/or exiting the country. These fees are not included in the ticket price, or the taxes and fees we display on our website prior to purchase. For more information on a specific country's entry/exit fee, we suggest that you visit <a href="http://www.travel.state.gov/" target="_blank" rel="noreferrer"> www.travel.state.gov</a> prior to departure.</>,
			'Airline Imposed Fees:',
			<><strong>Itinerary Changes, Cancellations or Refunds:</strong> Post-purchase changes, when permitted, may carry airline-imposed change and/or cancellation fees. These fees will be displayed in your airline’s fare rules.</>,
			<> <strong>Baggage Fees and Optional Services:</strong> Airline baggage fees and optional services vary by airline, ‌flight and frequent flyer status. These fees are not included in your airline ticket cost. Please visit the Baggage Fees and Optional Services link on your itinerary for details.</>,
			<><strong>Airline Surcharge:</strong> For many international destinations, airlines impose a surcharge. This could include either a fuel or security surcharge. The amount varies widely depending on the destination and/or carrier. Some airlines include surcharges in their ticket cost. The most common surcharges are either fuel or security surcharges. The amount varies widely depending on both the carrier and the itinerary. When applicable, this amount is included in your airline ticket cost.</>,
			<>For <strong>international travel</strong>, some countries may charge arrival and/or departure taxes that are not included in your total cost. These taxes are collected at the airport, and typically must be paid in local currency.</>
		]
        

	},
	{
		id: 15,
		title: 'Traveling with Infants',
		description1: 'When traveling by air, you will need to decide whether you want your infant to fly as a ticketed passenger in their own seat or as a lap infant.',
		headingLine2: 'Domestic Flights (within the U.S and to Puerto Rico):',
		list7:[
			'If you would like your infant to sit in their own seat, you will need to book your flight directly with the airline. You will be required to seat your infant in an FAA approved car seat.',
			'Most airlines will allow one lap infant per adult Traveler at no charge, proof of age is required. Call your airline immediately, after you make a travel purchase, to let them know you will be holding an infant.',
			'If you\'re traveling with more than one infant per adult, you\'ll need to purchase a seat for each additional infant Traveler.',
			'If you\'re traveling with more than two infants per adult (or if you\'re flying in Premium Economy, Business or First class), check with your airline prior to purchase to confirm it is allowed.'
		],
        
		headingListLine3: 'International Flights (flying between two or more countries):',
		list8:[
			'If you would like your infant to sit in their own seat, you will need to book your flight directly with the airline. You will be required to seat your infant in an FAA approved car seat.',
			'If you will be holding your infant on your lap on an international flight, you will need to purchase an infant ticket directly with your airline. Tripello does not sell infant tickets.'
		]
	},
];
