import React, { useState } from "react";
import FlightTime from "./FlightTime";
import { range, times } from "lodash";
import FlightDetailsModal from "../Modals/FlightDetailsModal";
import { Tooltip } from "antd";
import MultiFlightIcon from "./MultiFlightIcon";
import AirlinePlaceHolderIcon from "./AirlinePlaceHolderIcon";

function SingleFlightDetails(props) {
  const { duration, airlineLogo, flightLegs, segmentIndex, flightSegments } =
    props;

  const timeConvertFlightDuration = (num) => {
    let hours = num / 60;
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);
    return rhours + "h " + rminutes + "m";
  };

  let originAirport = flightLegs?.flightLegs[0]?.departureAirportCode;
  let destinationAirport =
    flightLegs?.flightLegs[flightLegs?.flightLegs?.length - 1]
      ?.arrivalAirportCode;
  let startTime = flightLegs?.flightLegs[0]?.departureDateTime;
  let endTime =
    flightLegs?.flightLegs[flightLegs?.flightLegs?.length - 1]?.arrivalDateTime;

  let stops = flightLegs?.stops;

  let numberOfStops = flightLegs?.numberOfStops;

  const [indexVal, setIndexVal] = useState(0);

  const totalJourneyDuration = flightLegs?.flightLegs.reduce(
    (previousValue, currentValue) => {
      return previousValue + currentValue.duration;
    },
    0
  );
  const totalJourneyDurationInHours =
    timeConvertFlightDuration(totalJourneyDuration);

  window["flightNumber_" + segmentIndex] = "";

  let operatedBy = "";
  flightSegments.map((flight, flightIndex) => {
    if (flight.marketingAirlineCode !== flight.operatingAirlineCode) {
      operatedBy = "Operated by " + flight.operatingAirlineName;
    }
    if (window["flightNumber_" + segmentIndex].length === 0) {
      window["flightNumber_" + segmentIndex] =
        flight.marketingAirlineCode + " " + flight.marketingAirlineFightNumber;
    } else if (
      window["flightNumber_" + segmentIndex].includes(
        flight.marketingAirlineCode
      )
    ) {
      if (flightIndex === flightSegments.length - 1) {
        window["flightNumber_" + segmentIndex] =
          window["flightNumber_" + segmentIndex] +
          " and " +
          flight.marketingAirlineFightNumber;
      } else {
        window["flightNumber_" + segmentIndex] =
          window["flightNumber_" + segmentIndex] +
          ", " +
          flight.marketingAirlineFightNumber;
      }
    } else {
      if (flightIndex === flightSegments.length - 1) {
        window["flightNumber_" + segmentIndex] =
          window["flightNumber_" + segmentIndex] +
          " and " +
          flight.marketingAirlineCode +
          " " +
          flight.marketingAirlineFightNumber;
      } else {
        window["flightNumber_" + segmentIndex] =
          window["flightNumber_" + segmentIndex] +
          ", " +
          flight.marketingAirlineCode +
          " " +
          flight.marketingAirlineFightNumber;
      }
    }
  });
  const [logoImageError, setLogoImageError] = useState(false);

  return (
    <div className="flightInfoRow">
      <div className="airLinesLogo">
        {flightLegs?.flightLegs[0]?.marketingAirlineLogoUrl &&
          !flightLegs?.mixedAirlines &&
          !logoImageError && (
            <Tooltip
              title={flightLegs?.flightLegs[indexVal]["marketingAirlineName"]}
              placement="bottom"
            >
              <img
                src={
                  flightLegs?.flightLegs[indexVal]["marketingAirlineLogoUrl"]
                }
                alt={flightLegs?.flightLegs[indexVal]["marketingAirlineName"]}
                onError={() => {
                  setLogoImageError(true);
                }}
              />
            </Tooltip>
          )}
        {flightLegs?.mixedAirlines && !logoImageError && (
          <Tooltip
            className="toolTipStyle d-flex a-center"
            getPopupContainer={(trigger) => trigger.parentElement}
            title={flightLegs?.flightLegs[indexVal]["marketingAirlineName"]}
          >
            <MultiFlightIcon />
          </Tooltip>
        )}
        {logoImageError && !flightLegs?.mixedAirlines && (
          <Tooltip
            title={flightLegs?.flightLegs[indexVal]["marketingAirlineName"]}
            placement="bottom"
          >
            <AirlinePlaceHolderIcon />
          </Tooltip>
        )}
        {/* <div className="logo-tip">
          {" "}
          {flightLegs?.flightLegs[indexVal]["operatingAirlineName"]}
        </div> */}
      </div>

      <div className="flightStartEnd">
        <FlightTime
          className="flightStartInfo"
          originAndDest={originAirport}
          startEndDate={startTime}
          startEndTime={startTime}
        />
        <div className="flightDurNstop">
          <div className="flightDuration">
            <i className="icon-timer"></i> {timeConvertFlightDuration(duration)}
          </div>
          <div className="flightBar">
            {range(0, numberOfStops).map((ele, index) => {
              return <i key={`${index}_stops`} className="fa fa-circle"></i>;
            })}
            <span className="icon-flight">
              <span className="path1"></span>
            </span>
          </div>
          <div
            className={`flgithStops ${
              numberOfStops > 0 ? "" : "nonStopFlight"
            }`}
          >
            {numberOfStops == 0
              ? "Nonstop"
              : `${numberOfStops} ${
                  numberOfStops < 2 ? "stop" : "stops"
                } (${stops.map((item) => item.iataCode)})`}
          </div>
        </div>
        <FlightTime
          className="flightEndInfo"
          originAndDest={destinationAirport}
          startEndDate={endTime}
          startEndTime={endTime}
          adjustment={"flights[flights.length - 1]?.departureDateAdjustment"}
        />
      </div>
      <div className="flightNumbers">
        {window["flightNumber_" + segmentIndex]}
        {operatedBy ? " " + operatedBy : ""}
      </div>
    </div>
  );
}

export default SingleFlightDetails;
