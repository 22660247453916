/* eslint-disable no-mixed-spaces-and-tabs */
import React, { Fragment, useEffect, useRef, useState } from "react";
import Button from "../../Component/FormComponent/Button";
import HotelName from "../../Component/Filters/HotelName";
import MapView from "./MapView/MapView";
import PriceRange from "../../Component/Filters/PriceRange";
import StarRating from "../../Component/Filters/StarRating";
import CategoriesFilter from "../../Component/Filters/CategoriesFilter";
import SortBy from "../../Component/SortBy/SortBy";
import SingleHomeCard from "./SingleHomeCard/SingleHomeCard";
import Pagination from "../../Component/Pagiantion/Pagination";
import { connect } from "react-redux";
import { homeActions } from "../../Stores/Actions";
import { isEmpty, has } from "lodash";
import HotelOfferCard from "../../Component/Hotel/HotelOfferCard";
import HeaderNavbar from "../../Component/HeaderNavbar";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import HomeWidget from "./HomeWidget";
import MapImg from "../../Assets/img/map-placeholder.png";
import ResultsOnPage from "../../Component/Pagiantion/ResultsOnPage";
import { Scrollbars } from "react-custom-scrollbars";
import FilterDisplay from "../../Component/Filters/FilterDisplay";
import HotelNeighbourhoods from "../../Component/Filters/HotelNeighbourhoods";

function HomeSearchResult(props) {
  const [filters, setFilters] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});
  const [openNeighbourhoodTag, setOpenNeighbourhoodTag] = useState(false);
  const [neighbourhoodAppliedFilters, setNeighbourhoodAppliedFilters] = useState({});
  const { hotels, searchHotelAct, hotelCord, searchHotelCord } = props;
  const [mobileFilter, setMobileFilter] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [toggleFilterDrop, setToggleFilterDrop] = useState(true);
  const [starRemove, setStarRemove] = useState(false);
  const [openCategoryTag, setOpenCategoryTag] = useState(false);
  const [openDistanceTag, setOpenDistanceTag] = useState(false);
  const [openAmenityTag, setOpenAmenityTag] = useState(false);
  const [hideFilter, setHideFilter] = useState(false);
  const [toggleFilter, setToggleFilter] = useState(false);
  const [hideHeader, setHideHeader] = useState(false);
  const searchRef = useRef(null);
  const searchRefMob = useRef(null);
  const priceRef = useRef(null);
  const priceRefMob = useRef(null);
  // Hit the hotel api when we redirect from landing page-hotel tab
  const hotelpayloadData = JSON.parse(window.localStorage.getItem("homePayload"));
  const widgetMapResult = JSON.parse(window.localStorage.getItem("mapPayload"));
  // user data
  const user = JSON.parse(window.localStorage.getItem("user"));
  
  const currencyAndLanguage =
    window.localStorage.getItem("currencyAndLanguage") &&
    JSON.parse(window.localStorage.getItem("currencyAndLanguage"));

  const currency =
    currencyAndLanguage?.currencyLanguageReducer?.data?.currency || {};

  useEffect(() => {
    const prevFilters = JSON.parse(window.localStorage.getItem("homeFilter"));
    const payload = { ...hotelpayloadData };
    payload.currency = currency.isoCode;
    payload.native_currency_symbol = currency.symbol;

    const payloadWidget = { ...widgetMapResult };
    payloadWidget.currency = currency.isoCode;
    payloadWidget.native_currency_symbol = currency.symbol;

    if (prevFilters) {
      payload.filters = prevFilters;
      if (has(prevFilters, "category_ids")) {
        setOpenCategoryTag(!openCategoryTag);
      }
      if (has(prevFilters, "amenities")) setOpenAmenityTag(!openAmenityTag);
      if (has(prevFilters, "distance")) setOpenDistanceTag(!openDistanceTag);

      setFilters(prevFilters);
    }
    if (widgetMapResult) {
      searchHotelAct(payloadWidget);
    } else {
      searchHotelAct(payload);
    }
  }, []);

  const toggleFilterHandler = (status) => {
    setToggleFilter(status);
  };

  // widget bar search payload data get from localStorage
  // const widgetMapResult = JSON.parse(window.localStorage.getItem("mapPayload"));

  // Handle open modal
  const handleOpenCloseModal = () => {
    const payload = {
      ...hotelpayloadData,
      view_type: "map",
      paging: { page_number: 1, per_page: 9999 },
    };
    const payloadFromWidget = {
      ...widgetMapResult,
      view_type: "map",
      paging: { page_number: 1, per_page: 9999 },
    };
    if (!openModal) {
      !isEmpty(widgetMapResult)
        ? searchHotelCord(payloadFromWidget)
        : searchHotelCord(payload);
      setOpenModal(true);
    } else {
      searchHotel(null, null, null, null, true);
      setOpenModal(false);
    }
  };

  const hotelSearchableData = !isEmpty(hotels)
    ? hotels.filters_available[0]
    : [];
  const minPrice =
    !isEmpty(hotels) && !isEmpty(hotels.filters_available)
      ? hotels.filters_available[1].options[0].value
      : 0;
  const maxPrice =
    !isEmpty(hotels) && !isEmpty(hotels.filters_available)
      ? hotels.filters_available[1].options[1].value
      : 1;
  const categoriesFilters =
    !isEmpty(hotels) && !isEmpty(hotels.filters_available)
      ? hotels.filters_available[4].options
      : [];
  const distanceFilters =
    !isEmpty(hotels) && !isEmpty(hotels.filters_available)
      ? hotels.filters_available.filter(ele => ele.name === "distance")?.[0]?.options
      : [];
  const amentiesFilters =
    !isEmpty(hotels) && !isEmpty(hotels.filters_available)
      ? hotels.filters_available[3].options
      : [];
  const neighbourhoodFilters =
    !isEmpty(hotels) && !isEmpty(hotels.filters_available)
      ? hotels.filters_available.filter(ele => ele.name === "neighbourhoods")?.[0]?.options
      : [];
  const sortingFilters =
    !isEmpty(hotels) && !isEmpty(hotels.sorting_fields_available)
      ? hotels.sorting_fields_available
      : [];

  const currencySymbol =
    !isEmpty(hotels) &&
    !isEmpty(hotels.currency) &&
    hotels?.currency?.symbol_native;

  useEffect(() => {
    if (!isEmpty(hotels) && !isEmpty(hotels.input)) {
      setAppliedFilters(hotels.input.filters);
    }
    return () => {
      setAppliedFilters({});
    };
  }, [hotels]);

  // for Map filter
  useEffect(() => {
    if (!isEmpty(hotelCord) && !isEmpty(hotelCord.input)) {
      setAppliedFilters(hotelCord.input.filters);
    }
    return () => {
      setAppliedFilters({});
    };
  }, [hotelCord]);

  useEffect(() => {
    if (!isEmpty(filters)) {
      if (has(filters, "rating") && isEmpty(filters.rating))
        delete filters.rating;
      window.localStorage.setItem("homeFilter", JSON.stringify(filters));
    } else {
      window.localStorage.removeItem("homeFilter");
    }
  }, [filters]);

  function searchHotel(
    page,
    per_page,
    withoutFilter = false,
    sort,
    modalClose = false,
    applyNeighbourHood
  ) {
    let payloadFilters = JSON.parse(window.localStorage.getItem("homeFilter"));
    let preStoaredval = JSON.parse(window.localStorage.getItem("homePayload"));
    let payload = {
      check_in_date: hotels.input.check_in_date,
      check_out_date: hotels.input.check_out_date,
      city_name: preStoaredval.city_name,
      currency: currency.isoCode,
      filter: true,
      filters: payloadFilters ? payloadFilters : {},
      filtersApplied: true,
      home: false,
      locale: hotels.input.locale,
      nationality: hotels.input.nationality,
      native_currency_symbol: currency.symbol,
      onlyLocale: hotels.input.locale,
      paging: {
        page_number: page ? page : hotels.paging.page_number,
        per_page: per_page ? per_page : hotels.paging.per_page,
      },
      region_id: preStoaredval.region_id,
      rooms: hotels.input.rooms,
      search_type: hotels.input.search_type,
      sort: hotels.input.sort,
      source_market: "US",
      token: window.localStorage.getItem("accessToken"),
      type: "city",
      use_wallets: hotels.input.use_wallets,
      view_type: hotels.input.view_type,
    };

    if (!isEmpty(filters) && !withoutFilter) {
      payload.filters = filters;
      if (has(filters, "amenities")) {
        payload.amenities = true;
        payload.optional = "amenities";
      }
    } else if (withoutFilter) {
      payload.filters = {};     // remove filters if sort is applied
    }

    if (sort) {
      payload.sort = { field: sort.name, order: sort.order };
    }
    //  else {
      // payload.sort = {
      //   field: preStoaredval.sort.field,
      //   order: preStoaredval.sort.order,
      // };
    // }
    if(applyNeighbourHood){
      payload.region_id = applyNeighbourHood.value;
      payload.city_name = applyNeighbourHood.label;
    }
    const mapFilterResult = {
      ...payload,
      view_type: "map",
      paging: { page_number: 1, per_page: 9999 },
    };
    // payload = { ...preStoaredval, filters: payload.filters };

    if (openModal && !modalClose) {
      searchHotelCord(mapFilterResult);
    } else {
      searchHotelAct({ ...payload, view_type: "list" });
    }
  }

  function searhByRemoveFilters(newFilters, page = 1, per_page = 15) {
    let payload = {
      check_in_date: hotels.input.check_in_date,
      check_out_date: hotels.input.check_out_date,
      city_name: hotels.city_name_full,

      currency: currency.isoCode,
      native_currency_symbol: currency.symbol,
      filter: true,
      filters: {},
      filtersApplied: true,
      home: false,
      locale: hotels.input.locale,
      nationality: hotels.input.nationality,
      onlyLocale: hotels.input.locale,
      paging: {
        page_number: page ? page : hotels.paging.page_number,
        per_page: per_page ? per_page : hotels.paging.per_page,
      },
      region_id: hotels.input.region_id,
      rooms: hotels.input.rooms,
      search_type: hotels.input.search_type,
      sort: hotels.input.sort,
      source_market: "US",
      token: window.localStorage.getItem("accessToken"),
      type: "city",
      use_wallets: hotels.input.use_wallets,
      view_type: hotels.input.view_type,
    };
    if (!isEmpty(newFilters)) {
      payload.filters = newFilters;
      if (has(newFilters, "amenities")) {
        payload.amenities = true;
        payload.optional = "amenities";
      }
    }
    searchHotelAct(payload);
  }

  function handlePageClick(page, per_page) {
    if (per_page) {
      searchHotel(1, per_page);
    } else {
      searchHotel(page.selected + 1);
    }
  }

  const handleApply = () => {
    setMobileFilter(false);
    searchRefMob.current.clear();
    searchHotel(1); // 1st arg : on apply any filter,it should reset pagination 
  };

  const handleResetFilters = () => {
    if (priceRef.current)
      priceRef.current.props.setRangeVal({
        min: priceRef.current.props.minValue,
        max: priceRef.current.props.maxValue,
      });
    if (priceRefMob.current)
      priceRefMob.current.props.setRangeVal({
        min: priceRefMob.current.props.minValue,
        max: priceRefMob.current.props.maxValue,
      });
    setStarRemove(true);
    window.localStorage.removeItem("homeFilter");
    setFilters({});
    setMobileFilter(false);
    searchHotel(1, null, true);
    if (has(filters, "category_ids")) setOpenCategoryTag(!openCategoryTag);
    if (has(filters, "amenities")) setOpenAmenityTag(!openAmenityTag);
    if (has(filters, "distance")) setOpenDistanceTag(!openDistanceTag);
  };

  function hideFunc() {
    // setHideFilter(!hideFilter);
    setHideFilter((prev) => !prev);
  }

  const [mobileMap, setMobileMap] = useState(false);

  const CloseMobileFilterModal = () =>{
    setMobileFilter(false);
    setToggleFilterDrop(true);
    setHideHeader(!hideHeader);
    setHideFilter(!hideFilter);
  }
  const  handleClearNeighbourhoods = () => {
    var homePayload = JSON.parse(localStorage.getItem("homePayload"));
  
    var hotelParentLocation = JSON.parse(localStorage.getItem("hotelParentLocation"));
    localStorage.removeItem("preserveNeighbourhood");
    localStorage.removeItem("neighbourhoodsSelection");
    homePayload.region_id = hotelParentLocation.value;
    homePayload.city_name = hotelParentLocation.label;
    localStorage.setItem("homePayload", JSON.stringify(homePayload));
    setMobileFilter(false);
    searchHotel(1);
  }
  return (
    <div className="siteBg listingPage listingPageWrap listingHotel listingHome">
      <div className="pageWrap">
        <div className="fixedHeader">
          {!hideHeader && (
            <HeaderNavbar
              hideFunc={hideFunc}
              toggleFilterHandler={toggleFilterHandler}
              useBlueIcon={true}
            />
          )}
        </div>
        <section className="mainContent">
          {!hideFilter && <HomeWidget openModal={openModal} setOpenModal={setOpenModal} toggleFilterDrop={toggleFilterDrop} />}     {/*  openModel = map view */}

          <div
            className={hideFilter ? "hide-content" : "rightInfo-filter d-flex "}
          >
            <div className="mobileSearchFilterButtons">
              <div className="d-flex">
                <Button
                  className="sortingButton mobileButtons"
                  onClick={() => {
                    setMobileFilter(true);
                    setToggleFilterDrop(false);
                    setHideHeader(!hideHeader);
                    setHideFilter(!hideFilter);
                  }}
                >
                  <i className="fa fa-sliders"></i> Filter & Sorting
                </Button>
                {!mobileMap ? (
                  <div
                    className="mapButton mobileButtons"
                    onClick={() => {
                      handleOpenCloseModal();
                      setMobileMap(!mobileMap);
                    }}
                  >
                    <i className="icon-location-on" />
                    Map View
                  </div>
                ) : (
                  <div
                    className="mapButton mobileButtons"
                    onClick={() => {
                      handleOpenCloseModal();
                      setMobileMap(!mobileMap);
                    }}
                  >
                    List View
                  </div>
                )}
              </div>
            </div>
            <div
              className={
                mobileFilter
                  ? "mobileSortFilter mobilePopup showPopup"
                  : "mobileSortFilter mobilePopup"
              }
            >
              <div className="mobileShow">
                <a href="#" className="clearFilter">
                  Clear
                </a>
                <Button
                  className="closePopup closePopup2"
                  onClick={() => {
                    setMobileFilter(false);
                    setToggleFilterDrop(true);
                    setHideHeader(!hideHeader);
                    setHideFilter(!hideFilter);
                  }}
                >
                  <span className="icon-close"></span>
                </Button>
                <h4 className="mobilePopTitle">Sort by</h4>
                <div className="clearBtnMobile">
                  <Button
                    className="btn"
                    type="reset"
                    onClick={() => {
                      handleResetFilters();
                      setToggleFilterDrop(!toggleFilterDrop);
                      setHideHeader(!hideHeader);
                      setHideFilter(!hideFilter);
                    }}
                  >
                    Clear
                  </Button>
                </div>
                <div className="applyBtnMobile">
                  <Button
                    className="btn"
                    type="button"
                    onClick={() => {
                      handleApply();
                      setToggleFilterDrop(!toggleFilterDrop);
                      setHideHeader(!hideHeader);
                      setHideFilter(!hideFilter);
                      handleClearNeighbourhoods();
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </div>
              <div className="mobileShow mobileShowsortFilter">
                <div className="sortFilter">
                  <div className="filterDropdownWrap sortByOption">
                    <SortBy
                      option={sortingFilters}
                      searchHotel={searchHotel}
                      mobileSort={true}
                      CloseMobileFilterModal={CloseMobileFilterModal}
                    />
                  </div>

                  <h4 className="mobilePopTitle filterTitle mobileShow">
                    Filter by
                  </h4>
                  <div
                    className="inputStyle CustominputStyle filterby-input"
                    style={{ width: "100%" }}
                  >
                    <HotelName
                      searchableData={hotelSearchableData}
                      filters={filters}
                      setFilters={setFilters}
                      searchHotel={searchHotel}
                      mobileCategory={true}
                      searchRef={searchRefMob}
                      label="Property Name"
                      placeholder="Property Search"
                    />
                  </div>

                  <div className="filterDropdownWrap">     {/* mobile */}
                    <PriceRange
                      title="Price"
                      minPrice={minPrice}
                      maxPrice={maxPrice}
                      filters={filters}
                      setFilters={setFilters}
                      searchHotel={searchHotel}
                      mobileCategory={true}
                      priceRef={priceRefMob}
                      currencySymbol={currencySymbol}
                    />
                  </div>
                  <div className="filterDropdownWrap">
                    <StarRating
                      title="Star Ratings"
                      filters={filters}
                      setFilters={setFilters}
                      searchHotel={searchHotel}
                      mobileCategory={true}
                      starRemove={starRemove}
                      setStarRemove={setStarRemove}
                    />
                  </div>
                  {neighbourhoodFilters?.length > 0 && <div className="filterDropdownWrap">
                    <HotelNeighbourhoods
                      homePayload={true}
                      title="Neighborhoods"
                      keys="neighbourhoods"
                      filterOptions={neighbourhoodFilters}
                      setFilters={setFilters}
                      filters={filters}
                      searchHotel={searchHotel}
                      appliedFilters={neighbourhoodAppliedFilters}
                      setAppliedFilters={setNeighbourhoodAppliedFilters}
                      setOpenFilterTag={setOpenNeighbourhoodTag}
                      openFilterTag={openNeighbourhoodTag}
                      mobileCategory={true}
                      setHideFilter={setHideFilter}
                      hideFilter={hideFilter}
                      setHideHeader={setHideHeader}
                      hideHeader={hideHeader}
                      mobileFilter={mobileFilter}
                      setMobileFilter={setMobileFilter}
                    />
                  </div>}
                  {distanceFilters?.length > 0 && <div className="filterDropdownWrap">
                    <CategoriesFilter
                      title="Distance from City Center"
                      keys="distance"
                      filterOptions={distanceFilters}
                      setFilters={setFilters}
                      filters={filters}
                      searchHotel={searchHotel}
                      appliedFilters={appliedFilters}
                      setAppliedFilters={setAppliedFilters}
                      mobileCategory={true}
                      setOpenFilterTagMob={setOpenDistanceTag}
                      openFilterTagMob={openDistanceTag}
                    />
                  </div>}
                  <div className="filterDropdownWrap">
                    <CategoriesFilter
                      title="Categories"
                      keys="category_ids"
                      filterOptions={categoriesFilters}
                      setFilters={setFilters}
                      filters={filters}
                      searchHotel={searchHotel}
                      appliedFilters={appliedFilters}
                      setAppliedFilters={setAppliedFilters}
                      mobileCategory={true}
                      setOpenFilterTagMob={setOpenCategoryTag}
                      openFilterTagMob={openCategoryTag}
                    />
                  </div>
                  <div className="filterDropdownWrap">
                    <CategoriesFilter
                      title="Amenities"
                      keys="amenities"
                      filterOptions={amentiesFilters}
                      setFilters={setFilters}
                      filters={filters}
                      searchHotel={searchHotel}
                      appliedFilters={appliedFilters}
                      setAppliedFilters={setAppliedFilters}
                      mobileCategory={true}
                      setOpenFilterTagMob={setOpenAmenityTag}
                      openFilterTagMob={openAmenityTag}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Scrollbars
            // autoHeight={`calc(100vh - 110px)`}
            autoHeight
            autoHeightMin={120}
            // disableHorizontalScrolling
            autoHeightMax={`calc(100%)`}
            className="pageInternalScroll"
          >
            <div className="container listing-margin-top">
              <div className="contentWrapper row">
                <div className="leftSideBar col">
                  {!openModal && (
                    <div className="card mapCard">
                      <div className="mapWrap">
                        <img src={MapImg} alt="map" className="w-100" />
                        <button
                          className="whiteBtn"
                          onClick={() => {
                            handleOpenCloseModal();
                          }}
                        >
                          Map View
                        </button>
                      </div>
                    </div>
                  )}
                  <div className="cardHead dflex">
                    <h3>Filter by</h3>
                    <Button className="clear" onClick={handleResetFilters}>
                      <i className="icon-close"></i>Clear
                    </Button>
                  </div>
                  <div className="card hotel-name-search-card">
                    <HotelName
                      searchableData={hotelSearchableData}
                      filters={filters}
                      setFilters={setFilters}
                      searchHotel={searchHotel}
                      label="Property Name"
                      placeholder="Property Search"
                    // searchRef={searchRef}
                    />
                  </div>
                  <div className="card">    {/* desktop */}
                    <PriceRange
                      title="Price"
                      minPrice={minPrice}
                      maxPrice={maxPrice}
                      filters={filters}
                      setFilters={setFilters}
                      searchHotel={searchHotel}
                      priceRef={priceRef}
                    />
                  </div>
                  <div className="card">
                    <StarRating
                      title="Star Ratings"
                      filters={filters}
                      setFilters={setFilters}
                      searchHotel={searchHotel}
                      starRemove={starRemove}
                      setStarRemove={setStarRemove}
                    />
                  </div>
                  {neighbourhoodFilters?.length > 0 && <div className="card hotel-name-search-card">
                    <HotelNeighbourhoods
                      homePayload={true}
                      title="Neighborhoods"
                      keys="neighbourhoods"
                      filterOptions={neighbourhoodFilters}
                      setFilters={setFilters}
                      filters={filters}
                      searchHotel={searchHotel}
                      appliedFilters={neighbourhoodAppliedFilters}
                      setAppliedFilters={setNeighbourhoodAppliedFilters}
                      setOpenFilterTag={setOpenNeighbourhoodTag}
                      openFilterTag={openNeighbourhoodTag}
                      handleClearNeighbourhoods={handleClearNeighbourhoods}
                    />
                  </div>}
                  <div className="card amenities-wrap">
                    <CategoriesFilter
                      title="Distance from City Center"
                      keys="distance"
                      filterOptions={distanceFilters}
                      setFilters={setFilters}
                      filters={filters}
                      searchHotel={searchHotel}
                      appliedFilters={appliedFilters}
                      setAppliedFilters={setAppliedFilters}
                      setOpenFilterTag={setOpenDistanceTag}
                      openFilterTag={openDistanceTag}
                    />
                  </div>
                  <div className="card">
                    <CategoriesFilter
                      title="Categories"
                      keys="category_ids"
                      filterOptions={categoriesFilters}
                      setFilters={setFilters}
                      filters={filters}
                      searchHotel={searchHotel}
                      appliedFilters={appliedFilters}
                      setAppliedFilters={setAppliedFilters}
                      setOpenFilterTag={setOpenCategoryTag}
                      openFilterTag={openCategoryTag}
                    />
                  </div>
                  <div className="card amenities-wrap">
                    <CategoriesFilter
                      title="Amenities"
                      keys="amenities"
                      filterOptions={amentiesFilters}
                      setFilters={setFilters}
                      filters={filters}
                      searchHotel={searchHotel}
                      appliedFilters={appliedFilters}
                      setAppliedFilters={setAppliedFilters}
                      setOpenFilterTag={setOpenAmenityTag}
                      openFilterTag={openAmenityTag}
                    />
                  </div>
                </div>
                <div className="mainWrap col">
                  {openModal ? (
                    <MapView
                      hotelLocation={hotelCord ? hotelCord.hotels : []}
                      closeModal={handleOpenCloseModal}
                      mobileMap={mobileMap}
                    />
                  ) : (
                    <>
                      <div
                        className="
                      rightInfo
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                      >
                        <h3 className="whiteText">
                          {!isEmpty(hotels) &&
                            `${hotels.paging.total_records} Properties found in ${hotels.city_name_full}`}
                        </h3>
                        <div className="sortWrapper row justify-content-between no-gutters align-items-center">
                          <SortBy
                            option={sortingFilters}
                            searchHotel={searchHotel}
                          />
                        </div>
                      </div>
                      <FilterDisplay
                        filters={appliedFilters}
                        clearFilter={setFilters}
                        searchHotel={searhByRemoveFilters}
                        priceRef={priceRef}
                        priceRefMob={priceRefMob}
                        setStarRemove={setStarRemove}
                        starRemove={starRemove}
                        openCategoryTag={openCategoryTag}
                        setOpenCategoryTag={setOpenCategoryTag}
                        openAmenityTag={openAmenityTag}
                        setOpenAmenityTag={setOpenAmenityTag}
                        openDistanceTag={openDistanceTag}
                        setOpenDistanceTag={setOpenDistanceTag}
                      />

                      {!isEmpty(hotels) && !isEmpty(hotels.hotels) ? (
                        hotels.hotels.map((item, index) => {
                          if (index === 4) {
                            return (
                              <Fragment key={`${item.id}_hotel`}>
                                <SingleHomeCard
                                  {...item}
                                  currencyCode={hotels.currency.code}
                                  currencySymbol={hotels.currency.symbol_native}
                                />
                                {user?.type_id ===
                                  process.env
                                    .REACT_APP_FREMIUM_USER_TYPE_ID && (
                                    <HotelOfferCard />
                                  )}
                              </Fragment>
                            );
                          }

                          return (
                            <SingleHomeCard
                              key={item.id}
                              {...item}
                              currencyCode={hotels.currency.code}
                              currencySymbol={hotels.currency.symbol_native}
                            />
                          );
                        })
                      ) : (
                        <div className="not-found">
                          <h2>No Hotels Found</h2>
                        </div>
                      )}
                      {!isEmpty(hotels?.hotels) &&
                        hotels?.paging?.total_pages > 1 && (
                          <div className="paginationWrapper">
                            <Pagination
                              onPageChange={handlePageClick}
                              pageCount={hotels.paging.total_pages}
                              forcePage={hotels.paging.page_number - 1}
                            />
                            <ResultsOnPage
                              className="results hide-for-mobile"
                              callback={handlePageClick}
                              defaultValue={hotels.paging.per_page}
                            />
                          </div>
                        )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </Scrollbars>
        </section>
        <FooterWithMediaIcon
          className="white-icon"
          iconClassName="icon-white"
        />
      </div>
      <>
        <div className="saver-popup popup">
          <div className="popup-content">
            <button className="icon-close"></button>
            <div className="heading">
              <h1 className="super-heading">uper Saver</h1>
            </div>
            <div className="popup-text">
              <h3>Congratulations!!</h3>
              <p>
                <strong>Upgrade to Premium</strong> before booking this Hotel,
                and Your Membership will be <strong>FREE.</strong>
              </p>
              <p>
                Based on the <strong> Member Savings</strong> of this Booking,
                you would <strong>recoup</strong> your Premium Membership{" "}
                <strong>immediately </strong>
                {""} and you could <strong>save even more </strong> on future
                Bokkings.
              </p>
              <p>
                Don&apos;t miss out on <strong>Massive Savings </strong> and the{" "}
                <strong>Chance</strong> to make your Travel Bookings{" "}
                <strong>more affordable than ever.</strong>
              </p>
            </div>
            <div className="popup-footer">
              <button className="btn"> UPGRADE</button>
              <button className="btn">OK</button>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

const mapStateToProps = (state) => ({
  hotels: state.hotelReducer.hotelSearchData,
  serverErrors: state.hotelReducer.hotelErrors,
  hotelCord: state.hotelReducer.hotelCord,
});

const mapDispatchToProps = (dispatch) => ({
  searchHotelAct: (payload) =>
    dispatch(homeActions.searchHotelAction(payload,null)),
  searchHotelCord: (payload) => dispatch(homeActions.searchHotelMap(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeSearchResult);
