import React, { useState, useEffect, useRef } from 'react';

import { marketingWebsiteAction } from '../../../Stores/Actions/marketingWebsiteAction';
import { useDispatch, useSelector } from 'react-redux';
import SelectFieldOption from '../../../Component/FormComponent/SelectFieldOption.jsx';
import DateRangePickerComponent from '../../../Component/DatePicker/DateRangePickerComponent';
import moment from 'moment';
import { Formik } from 'formik';
import Pagination from '../../../Component/Pagiantion/Pagination';
import ResultsOnPage from '../../../Component/Pagiantion/ResultsOnPage';
import { commafy } from '../../../Util/util';
import Refferal from '../../../Assets/img/refferal.png';
import Apartments from '../../../Assets/img/apartments.png';
// import carImg from "../../../checkout";
import rewardBlueIcon from '../../../Assets/img/reward-blue-icon.png';
import Flight from '../../../Assets/img/path.png';
import accountBalanceWallet from '../../../Assets/img/account-balance-wallet.png';
import paypal from '../../../Assets/img/card2.png';
import Button from '../../../Component/FormComponent/Button';

export const MemberRewardWallet = ({setNavbar}) => {
  const dispatch = useDispatch();
  const formikRef = useRef();
  // State for details info modal
  const [openModal, setOpenModal] = useState(false);
  const [isIpad, setIsIpad] = useState(false);
  // user data 
  // Handle open modal
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const [focused2, setFocused2] = useState(null);
  // Handle close modal
  const handleCloseOpenModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if(window.innerWidth < 993 && window.innerWidth > 767) {
      setIsIpad(true)
    }
  }, [])

  const initialValues = {
    startDate: moment().subtract(7, 'days'),
    endDate: moment(),
    per_page: '10',
    page_number: '1',
    type: 'all',
  };

  const [dateModal, setDateModal] = useState(false)
  const [copiedIndex, setCopiedIndex] = useState(false)

  const walletData = useSelector(
    (state) => state.marketingSiteReducer.walletData
  );
  const isMobile = useSelector((state) => state.commonReducer.isMobile);
  
  const getWalletData = (
    page,
    per_page,
    start_date,
    end_date,
    type,
    search
  ) => {
    const payload = {
      filter: {
        date: {
          start_date:
            start_date || moment().subtract(7, 'days').format('YYYY-MM-DD'),
            end_date: end_date || moment().format('YYYY-MM-DD'),
          // start_date: start_date || "2022-07-25",
          // end_date: end_date || "2022-07-30"
        },
        type: type ? [type] : ['all'],
      },
      paging: {
        per_page: per_page || 15,
        page_number: page || 1,
      },
      type: 'wallet'
    };
    if (search) {
      payload.search = search;
    }
    dispatch(
      marketingWebsiteAction.getWallletTransactionAction(
        JSON.parse(window.localStorage.getItem('user')).user_wallet.id,
        payload
      )
    );
  };

  useEffect(() => {
    getWalletData();
  }, []);

  useEffect(() => {
    setNavbar(!dateModal);
  }, [dateModal]);

 
  function handlePageClick(page, per_page) {
    if (per_page) {
      getWalletData(
        1,
        per_page,
        formikRef.current.values.startDate,
        formikRef.current.values.endDate
      );
    } else {
      getWalletData(page.selected + 1);
    }
  }
  const onDateChange = (startDate, endDate, formikProps) => {

    if (startDate && endDate) {
      getWalletData(
        null,
        null,
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD')
      );
      setDateModal(false);
    }

    formikProps.setFieldValue('startDate', startDate);
    formikProps.setFieldValue('endDate', endDate);
    
  };

  const renderForm = (formikProps) => {
    const { values, errors, touched, handleSubmit, handleBlur, handleChange } =
      formikProps;



    const walletDetails = walletData?.data?.wallet_detail;

    ///////////////////// debouncing /////////////////////////////////
    function debounce(func, timeout = 1000) {
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      };
    }

    function saveInput(e) {
      getWalletData(
        formikRef.current.values.page,
        formikRef.current.values.per_page,
        formikRef.current.values.startDate,
        formikRef.current.values.endDate,
        formikRef.current.values.type,
        e.target.value.trim()
      );
    }

    const processChanges = debounce((e) => saveInput(e));

    ///////////////////// debouncing /////////////////////////////////

    const copy = (text, index) => {
      setCopiedIndex(index);
      setTimeout(function(){
        setCopiedIndex(null);
      }, 2000);
      navigator.clipboard.writeText(text);
    }
    return (
      <>
        <div className="sectionTitle searchWrapper desktopViewDisplayBlock">
          <h2>Your Wallet</h2>
          <div className="formGroup">
            <Button type="submit" className="btnImage" />
            <input
              type="text"
              className="formControl"
              placeholder="Search"
              onKeyUp={processChanges}
            />
          </div>
        </div>

        {!isIpad && <div className="walletWrapper desktopViewDisplayBlock">
          <div className="row record-not-found">
            <div className="col leftContent">
              <div className="card">
                <h3>Wallet Amount</h3>
                <div className="price">
                  <img src={rewardBlueIcon} alt="icon" />
                  <h2>
                    $
                    {walletData?.data?.wallet_detail?.amount
                      ? walletData?.data?.wallet_detail?.amount
                      : '0.00'}
                  </h2>
                </div>
                <div className="dateWrapper">
                  <h5>Filter by Date</h5>

                  <div className="dateBox h-100 d-flex w-100">
                    {!dateModal && <DateRangePickerComponent
                      name="dates"
                      startDate={values.startDate}
                      endDate={values.endDate}
                      startDateId="startDate"
                      endDateId="endDate"
                      focused2={focused2}
                      setFocused2={setFocused2}
                      isRequired
                      icon="icon-invitation"
                      orientation="horizontal"
                      openDirection="down"
                      // startDatePlaceholderText="startDate"
                      // endDatePlaceholderText="endDate"
                      onDatesChange={({ startDate, endDate }) => {
                        if (focused2 == 'startDate') {
                          onDateChange(startDate, null, formikProps);
                        } else {
                          onDateChange(startDate, endDate, formikProps);
                        }
                      }}
                      isOutsideRange={(day) => {
                        if (focused2 === 'startDate')
                          return day.isAfter(moment());
                        if (focused2 == 'endDate') return day.isAfter(moment());
                      }}
                    />}
                  </div>
                </div>

                <div className="dropdownWrapper">
                  <SelectFieldOption
                    label="Transaction Type"
                    className="dropdownBox"
                    name="type"
                    value={values.type}
                    onChange={(formikProps, name, value) => {
                      formikProps?.setFieldValue('type', value);
                      getWalletData(
                        null,
                        null,
                        moment(formikProps.values.startDate).format(
                          'YYYY-MM-DD'
                        ),
                        moment(formikProps.values.endDate).format(
                          'YYYY-MM-DD'
                        ),
                        value
                      );
                    }}
                    options={walletData?.data?.filter?.type?.map((curr) => {
                      return { value: curr.id, label: curr.label };
                    })}
                    formikProps={formikProps}
                  />
                </div>
                <div className="signUpWrapper">
                  <div className="row">
                    <div className="col">
                      <div className="content">
                        <h4>
                          <span>Freemium</span> Sign Ups
                        </h4>
                        <h3>
                          {walletData?.data?.wallet_detail?.freemium_sign_up || '--'}{' '}
                        </h3>
                      </div>
                    </div>
                    <div className="col">
                      <div className="content">
                        <h4>
                          <span>Membership </span>Sign Ups
                        </h4>
                        <h3>
                          {' '}
                          {walletData?.data?.wallet_detail?.membership_sign_up || '--'}
                        </h3>
                      </div>
                    </div>
                    <div className="col">
                      <div className="content">
                        <h4>
                          <span>Conversion </span>Rate
                        </h4>
                        <h3>
                          {walletData?.data?.wallet_detail?.conversion_rate || '--'}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="reward-text">Rewards are credited after 30 days.</div>
              </div>
            </div>
            <div className="col rightontent">
              <div className="card2">
                <div className="tabsWrapper">
                  <a href="#" className="active">
                    <img src={accountBalanceWallet} alt="icon" />
                    Transactions
                  </a>
                  {/* <!-- <a href="#"><img src="img/ic-people.png" alt="icon" />Invites</a> --> */}
                </div>
                <table className="table-data-content">
                  <tbody>
                    {walletData?.data?.result?.map((item, index) => {
                      // desktop view
                      let img;
                      let alt;
                      switch (item.type) {
                        case 'paypal_transfer':
                          img = paypal;
                          alt = 'Paypal';
                          break;
                        case 'membership_referral_commission' || 'membership_commission': 
                            img = Refferal;
                            alt = 'Referral';
                            break;
                          
                        case 'membership_reward' || 'membership_commission': 
                            img = Refferal;
                            alt = 'Referral';
                            break;
                          
                        case 'credit_account': 
                          img = paypal;
                          alt = 'Pay pal';
                          break;
                        
                        case 'hotel_booking': 
                          img = Apartments;
                          alt = 'Hotel Booking';
                          break;
                        
                        case 'flight_booking': 
                          img = Flight;
                          alt = 'Flight Booking';
                          break;
                        
                      }
                      return (
                        <tr key={index}>
                          <td className="iconTd">
                            <img src={img} alt="alt" />
                          </td>
                          <td>
                            <h3>{item?.description || '--'} </h3>
                          </td>
                          <td>
                            <h4 title={item?.user_member_id} style={{cursor: 'pointer'}} onClick={() => copy(item?.user_member_id, index)}>
                              {index !== copiedIndex ? (item?.user_member_id ? item?.user_member_id : '--') : 'Copied'}
                            </h4>
                          </td>
                          <td>
                            <h4>{item?.date_time || '--'}</h4>
                          </td>
                          <td>
                            <h5 className={item?.operation === 'credit' ? 'greenText' : 'redText'}>
                              {item?.operation === 'credit' ? '+' : '-'}{' '}{item?.currency || '$'}{item?.amount ? commafy(item?.amount) : '--'}
                            </h5>
                          </td>
                        </tr>
                      );
                    })}

                    {!walletData?.data?.result?.length && (
                      <>
                        <div className="not-found-record">
                          <h2>Record not found</h2>
                        </div>
                      </>
                    )}
                  </tbody>
                </table>
              </div>

              <div className="paginationWrapper">
                {walletData?.data?.result?.length > 15 && (
                  <>
                    <Pagination
                      onPageChange={handlePageClick}
                      pageCount={walletData?.data?.pagination?.total_pages}
                      forcePage={
                        walletData?.data?.pagination?.page
                          ? Number(walletData?.data?.pagination?.page) - 1
                          : 0
                      }
                    />

                    <ResultsOnPage
                      className="results hide-for-mobile"
                      callback={handlePageClick}
                      defaultValue={15}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>}

        {/* tablet/mobile view
         */}
        <div className="sectionTitle tabletViewDisplayBlock">
          <h2>Wallet</h2>
        </div>
        <div className="walletWrapper tabletViewDisplayBlock">
          <div className="row">
            <div className="col leftContent">
              <div className="card">
                <h3>Wallet Amount</h3>
                <div className="price">
                  <img src={rewardBlueIcon} alt="icon" />
                  <h2>
                    ${walletDetails?.amount ? commafy(walletDetails?.amount) : '0.00'}
                  </h2>
                </div>
                <div className="signUpWrapper">
                  <div className="row">
                    <div className="col">
                      <div className="content">
                        <h4>
                          <span>Freemium</span> Sign Ups
                        </h4>
                        <h3>
                          {walletDetails?.freemium_sign_up
                            ? commafy(walletDetails?.freemium_sign_up)
                            : '--'}
                        </h3>
                      </div>
                    </div>
                    <div className="col">
                      <div className="content">
                        <h4>
                          <span>Membership </span>Sign Ups
                        </h4>
                        <h3>
                          {' '}
                          {walletDetails?.membership_sign_up
                            ? commafy(walletDetails?.membership_sign_up)
                            : '--'}
                        </h3>
                      </div>
                    </div>
                    <div className="col">
                      <div className="content">
                        <h4>
                          <span>Conversion </span>Rate
                        </h4>
                        <h3>
                          {walletDetails?.conversion_rate
                            ? walletDetails?.conversion_rate
                            : '--'}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="reward-text">Rewards are credited after 30 days.</div>
              </div>
            </div>
            <div className="col rightcontent">
              <div className="card2">
                <div className="tabsWrapper">
                  <a href="#" className="active">
                    <img src={accountBalanceWallet} alt="icon" />
                    Transactions
                  </a>
                  {/* <!-- <a href="#"><img src="img/ic-people.png" alt="icon" />Invites</a> --> */}
                </div>
                <div className="contentWrapper">
                  <div className="dateWrapper">
                    <h5>Filter by Date</h5>

                    <div className="dateBox  d-flex ">

                      {!isIpad && (
                        <div className="date-picker-type">
                          <div className="check-in-date date-picker-style">
                            <i className="icon-invitation" />
                            <Button
                              className="inputStyle grow1"
                              onClick={() => {
                                setFocused2('startDate')
                                setDateModal(true)
                              }}
                              type="button"
                              text={
                                values.startDate
                                  ? moment(values.startDate).format('DD/MM/YYYY')
                                  : 'Start-Date'
                              }
                            />
                          </div>
                          <div className="check-out-date date-picker-style">
                            <i className="icon-invitation" />
                            <Button
                              className="inputStyle grow1"
                              onClick={() => {
                                if (focused2 === null) {
                                  setFocused2('startDate')
                                } else {
                                  setFocused2('endDate')
                                }
                              }}
                              type="button"
                              text={
                                values.endDate
                                  ? moment(values.endDate).format('DD/MM/YYYY')
                                  : 'End-Date'
                              }
                            />
                          </div>

                        </div>
                      )}
                      {!isMobile && isIpad && <DateRangePickerComponent
                        name="dates"
                        startDate={values.startDate}
                        endDate={values.endDate}
                        startDateId="startDate"
                        endDateId="endDate"
                        focused2={focused2}
                        setFocused2={setFocused2}
                        isRequired
                        icon="icon-invitation"
                        orientation="horizontal"
                        openDirection="down"
                        startDatePlaceholderText="startDate"
                        endDatePlaceholderText="endDate"
                        onDatesChange={({ startDate, endDate }) => {
                          if (focused2 == 'startDate') {
                            onDateChange(startDate, null, formikProps);
                          } else {
                            onDateChange(startDate, endDate, formikProps);
                          }
                        }}
                        isOutsideRange={(day) => {
                          if (focused2 === 'startDate')
                            return day.isAfter(moment());
                          if (focused2 == 'endDate') return day.isAfter(moment());
                        }}
                      />}

                      {/* dateModal */}
                      {isMobile && dateModal && (
                        <div className="inputStyle showDropdown">

                          <div className="date-picker-popup ">
                            <button type="button" onClick={() => setDateModal(false)} className="closePopup">
                              <span
                                className="icon-close"
                              />
                            </button>
                            <h4 className="inputPopTitle">Select range</h4>
                            <div className="Date-picker-range">
                              <DateRangePickerComponent
                                name="dates"
                                startDate={values.startDate}
                                endDate={values.endDate}
                                startDateId="startDate"
                                endDateId="endDate"
                                focused2={focused2}
                                setFocused2={setFocused2}
                                isRequired
                                icon="icon-invitation"
                                orientation="vertical"
                                numberOfMonths={12}
                                openDirection="down"
                                startDatePlaceholderText="startDate"
                                endDatePlaceholderText="endDate"
                                onDatesChange={({ startDate, endDate }) => {
                                  if (focused2 == 'startDate') {
                                    onDateChange(startDate, null, formikProps);
                                  } else {
                                    onDateChange(startDate, endDate, formikProps);
                                  }
                                }}
                                isOutsideRange={(day) => {
                                  if (focused2 === 'startDate')
                                    return day.isAfter(moment());
                                  if (focused2 == 'endDate') return day.isAfter(moment());
                                }}
                              />

                            </div>
                          </div>
                        </div>
                      )}

                    </div>
                  </div>

                  <div className="dropdownSearchWrapper">
                    <div className="dropdownWrapper">
                      <SelectFieldOption
                        label="Transaction Type"
                        className="dropdownBox"
                        name="type"
                        value={values.type}
                        onChange={(formikProps, name, value) => {
                          formikProps?.setFieldValue('type', value);
                          getWalletData(
                            null,
                            null,
                            moment(formikProps.values.startDate).format(
                              'YYYY-MM-DD'
                            ),
                            moment(formikProps.values.endDate).format(
                              'YYYY-MM-DD'
                            ),
                            value
                          );
                        }}
                        options={walletData?.data?.filter?.type?.map((curr) => {
                          return { value: curr.id, label: curr.label };
                        })}
                        formikProps={formikProps}
                      />
                    </div>
                    <div className="searchBox">
                      {/* <form action=""> */}
                        <div className="formGroup">
                          <button onClick={() => 
                            getWalletData(1,
                              values.per_page,
                              values.startDate,
                              values.endDate,
                              values.type,
                              values.search
                            )
                          } className="btnImage"></button>
                          <input
                            type="text"
                            className="formControl"
                            name="search"
                            placeholder="Search"
                            onChange={handleChange}
                          />
                        </div>
                      {/* </form> */}
                    </div>
                  </div>
                </div>
                <div className="rewardsInfo">
                  {walletData?.data?.result?.map((item, index) => {
                    let img;
                    let alt='';
                    switch (item.type) {
                      case 'paypal_transfer':
                        img = paypal;
                        alt = 'Paypal';
                        break;
                      case 'membership_referral_commission' || 'membership_commission': 
                          img = Refferal;
                          alt = 'Referral';
                          break;
                        
                      case 'membership_reward' || 'membership_commission': 
                          img = Refferal;
                          alt = 'Referral';
                          break;
                        
                      case 'credit_account': 
                        img = paypal;
                        alt = 'Pay pal';
                        break;
                      
                      case 'hotel_booking': 
                        img = Apartments;
                        alt = 'Hotel Booking';
                        break;
                      
                      case 'flight_booking': 
                        img = Flight;
                        alt = 'Flight Booking';
                        break;
                      
                    }
                    return (
                      <>
                        <div className="row no-gutters">
                          <div className="col icon">
                            <img src={img} alt={alt} />
                          </div>
                          <div className="col titleSection">
                            <h3>{item?.description}</h3>
                            <h4>{item?.date_time}</h4>
                          </div>
                          <div className="priceSection">
                            <h5 className={item?.operation === 'credit' ? 'greenText' : 'redText'}>
                              {item?.operation === 'credit' ? '+' : '-'}{' '}{item?.currency || '$'}
                              {item?.amount ? commafy(item?.amount) : '--'}
                            </h5>
                            <h6 title={item?.user_member_id} style={{cursor: 'pointer'}} onClick={() => copy(item?.user_member_id, index)}>
                              {index !== copiedIndex ? (item?.user_member_id ? item?.user_member_id : '--') : 'Copied'}
                            </h6>
                          </div>
                        </div>
                      </>
                    );
                  })}

                  {!walletData?.data?.result?.length && (
                    <>
                      <div className="mob-not-found">
                        <h2>Record not found</h2>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="paginationWrapper">
                {walletData?.data?.result?.length > 15 && (
                  <>
                    <Pagination
                      onPageChange={handlePageClick}
                      pageCount={walletData?.data?.pagination?.total_pages}
                      forcePage={
                        walletData?.data?.pagination?.page
                          ? Number(walletData?.data?.pagination?.page) - 1
                          : 0
                      }
                    />

                    <ResultsOnPage
                      className="results hide-for-mobile"
                      callback={handlePageClick}
                      defaultValue={15}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <Formik
      innerRef={formikRef}
      // validationSchema={}
      initialValues={initialValues}
    // enableReinitialize={true}
    >
      {renderForm}
    </Formik>
  );
};
