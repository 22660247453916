/* eslint-disable react/no-unescaped-entities */
import Modal from 'react-modal';
import CloseIcon from '../../../Assets/img/close-blue-icon.png';
import React from 'react';
import moment from "moment";

const type = {
  non_refundable: "No Refund",
  fully_refundable: "Refund",
  partial_refund: "Partial Refund",
};
const CancelPolicyModal = ({ isOpen, closeModal, policies, policiesNew, isRefundable }) => {
  const currentGeoLocation =
    window.localStorage.getItem("currentGeoLocation") &&
    JSON.parse(window.localStorage.getItem("currentGeoLocation"));

  const dateDisplayFormat =
    currentGeoLocation === "US" ? "MM/DD/YYYY" : "DD/MM/YYYY";

  return (
    <div className="detailPageWrap">
      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        className="popup cancellation-policy-popup popup-open "
      >
        <div className="popup-content-wrap  ">
          <div className="popup-content">
            <div className="popup-title">
              <h3>Cancellation Policy</h3>
              <button
                className="btn close room-details-popup-close-btn"
                onClick={() => closeModal(false)}
              >
                <img src={CloseIcon} alt="icon" />
              </button>
            </div>
            <p>
              Refundable cancellations (as laid out in our{' '}
              <a target="_blank" href="/terms-conditions">Terms and Conditions </a>) will be
              subject to a 3.2% merchant processing fee
            </p>
            <p>
              Booking charges to your Debit/Credit Card will appear in the name
              of "Tripello" and/or "Stripe"
            </p>

            {/* <div>
              {policies?.map((item, index) => (
                <h5 key={index}>{item?.policy}</h5>
              ))}
            </div> */}
            {isRefundable && (
              <h5 className="green">
                Refundable
              </h5>
            )}
            {!isRefundable && (
              <h5 className="red">
                Non-Refundable
              </h5>
            )}
            {
              // <>
              //   {policiesNew?.some((ele) => ele?.refundable_type !== "non_refundable") && <><div className="">New Cancellation Policy</div><br /></>}
              //   {policiesNew?.map(
              //     (ele, index) => {
              //       return <React.Fragment key={index}>
              //         {ele?.refundable_type !== "non_refundable" && <div className="" key={index}>
              //           <span>
              //             {`From ${ele?.from_date} to ${ele?.to_date} - ${type[ele?.display_refund_type_text]} - Refund Amount: ${ele?.currency}${ele?.refund_amount} - Cancellation Charges: ${ele?.currency}${ele?.total_cancellation_amount}`}
              //           </span>
              //         </div>}
              //       </React.Fragment>
              //     }
              //   )}
              //   <br />
              // </>
              <div className="flight-penalty-info">
                <div className="a table-wrap">
                  <table className="tableStyle font14 w-100">
                    <thead>
                      <tr>
                        <th className="">
                          Date
                        </th>
                        <th className="">
                          Type
                        </th>
                        <th className="">
                          Refund Amount
                        </th>
                        <th className="">
                          Cancellation Fee
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {policiesNew?.map(
                        (item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <span>
                                  {`From ${moment(item?.from_date, "DD-MM-YYYY").format(dateDisplayFormat)} to ${moment(item?.to_date, "DD-MM-YYYY").format(dateDisplayFormat)}`}
                                </span>
                              </td>
                              <td>
                                <span>
                                  {(type[item?.display_refund_type_text])}
                                </span>
                              </td>
                              <td>
                                <span>
                                  {item?.currency}{" "}
                                  {item?.refund_amount_display || 0}
                                </span>
                              </td>
                              <td>
                                <span>
                                  {item?.currency}{" "}
                                  {item.total_cancellation_amount_display}
                                </span>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            }
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CancelPolicyModal;
