import { authService } from "../../api/authService";
import http from "../../api/http";
import { authConstants } from "../Constants/authConstants";
import { commonActions } from "./commonActions";
import { CURRENCY, LANGUAGES } from "../../Data/static.service";
import { addCurrencyAndLanguage } from "../../Stores/Actions/languageCurrency";

export const authActions = {
  loginRequest,
  getTenantCongfig,
  forgotPasswordRequest,
  resetPasswordRequest,
  resetServeError,
  isEmailExistRequest,
  freemiumSignupRequest,
  premiumSignupRequest,
  validatePromocodeRequest,
  validateCouponCodeRequest,
  premiumPlusSignupRequest,
  getUserByAffiliateId,
  logoutRequest,
  getUserDetail,
  upgradeMembershipRequest,
  updateUserProfileAction,
  updatePassowrdAction,
  addUserAction,
  resetForgotPasswordDetails,
  resetUserData,
  cancelMembership,
  resetCancelMembershipStatus,
  getGuestListAction,
  updateGuestAction,
  confirmEmailAddressAction,
  getPaypalStatus,
  getPaypalVarificationStatus,
};

function resetUserData() {
  return (dispatch) => {
    dispatch(request(authConstants.REST_USER_ID));
  };
}

function isEmailExistRequest(payload) {
  return async (dispatch) => {
    dispatch(request(authConstants.IS_EMAIL_EXIST_REQUEST));
    dispatch(commonActions.loaderText("SIGNING IN..."));
    dispatch(commonActions.showLoader());
    try {
      const response = await authService.isEmailExistService(payload);
      dispatch(commonActions.hideLoader());
      if (response.status == 200)
        dispatch(success(authConstants.IS_EMAIL_EXIST_SUCCESS, response));
      return response;
    } catch (error) {
      dispatch(failure(authConstants.IS_EMAIL_EXIST_FAILURE, error));
      dispatch(commonActions.hideLoader());
      return error;
    }
  };
}

function resetForgotPasswordDetails() {
  return (dispatch) => {
    dispatch(request(authConstants.RESET_FORGOT_PASSWORD));
  };
}

function freemiumSignupRequest(payload) {
  return (dispatch) => {
    dispatch(request(authConstants.FREEMIUM_SIGNUP_REQUEST));
    dispatch(commonActions.loaderText("Signing up..."));
    dispatch(commonActions.showLoader());
    return authService
      .freemiumSignupService(payload)
      .then((response) => {
        dispatch(success(authConstants.FREEMIUM_SIGNUP_SUCCESS, response));
        dispatch(commonActions.hideLoader());
        window.localStorage.removeItem("accessToken");
        window.localStorage.removeItem("user");
        return response;
      })
      .catch((error) => {
        dispatch(failure(authConstants.FREEMIUM_SIGNUP_FAILURE, error));
        dispatch(commonActions.hideLoader());
        return error;
      });
  };
}

function premiumSignupRequest(payload) {
  return (dispatch) => {
    dispatch(request(authConstants.PREMIUM_SIGNUP_REQUEST));
    dispatch(commonActions.loaderText("Signing up..."));
    dispatch(commonActions.showLoader());
    authService
      .premiumSignupService(payload)
      .then((response) => {
        dispatch(success(authConstants.PREMIUM_SIGNUP_SUCCESS, response));
        dispatch(commonActions.hideLoader());
        window.localStorage.removeItem("accessToken");
        window.localStorage.removeItem("user");
      })
      .catch((error) => {
        dispatch(failure(authConstants.PREMIUM_SIGNUP_FAILURE, error));
        dispatch(commonActions.hideLoader());
      });
  };
}
function validatePromocodeRequest(payload) {
  return (dispatch) => {
    // dispatch(request(authConstants.PREMIUM_SIGNUP_REQUEST));
    dispatch(commonActions.loaderText("validating..."));
    dispatch(commonActions.showLoader());
    return authService
      .validatePromocodeService(payload)
      .then((response) => {
        // dispatch(success(authConstants.PREMIUM_SIGNUP_SUCCESS, response));
        dispatch(commonActions.hideLoader());
        return response;
      })
      .catch((error) => {
        // dispatch(failure(authConstants.PREMIUM_SIGNUP_FAILURE, error));
        dispatch(commonActions.hideLoader());
        return error;
      });
  };
}
function validateCouponCodeRequest(payload) {
  return (dispatch) => {
    // dispatch(request(authConstants.PREMIUM_SIGNUP_REQUEST));
    dispatch(commonActions.loaderText("validating..."));
    dispatch(commonActions.showLoader());
    return authService
      .validateCouponCodeService(payload)
      .then((response) => {
        // dispatch(success(authConstants.PREMIUM_SIGNUP_SUCCESS, response));
        dispatch(commonActions.hideLoader());
        return response;
      })
      .catch((error) => {
        // dispatch(failure(authConstants.PREMIUM_SIGNUP_FAILURE, error));
        dispatch(commonActions.hideLoader());
        return error;
      });
  };
}
function premiumPlusSignupRequest(payload) {
  return (dispatch) => {
    dispatch(request(authConstants.PREMIUM_PLUS_SIGNUP_REQUEST));
    dispatch(commonActions.loaderText("Signing up..."));
    dispatch(commonActions.showLoader());
    authService
      .premiumPlusSignupService(payload)
      .then((response) => {
        dispatch(success(authConstants.PREMIUM_PLUS_SIGNUP_SUCCESS, response));
        dispatch(commonActions.hideLoader());
        window.localStorage.removeItem("accessToken");
        window.localStorage.removeItem("user");
      })
      .catch((error) => {
        dispatch(failure(authConstants.PREMIUM_PLUS_SIGNUP_FAILURE, error));
        dispatch(commonActions.hideLoader());
      });
  };
}

function upgradeMembershipRequest(payload, id) {
  return (dispatch) => {
    dispatch(request(authConstants.UPGRADE_MEMBERSHIP_REQUEST));
    dispatch(commonActions.loaderText("Upgrading..."));
    dispatch(commonActions.showLoader());
    return authService
      .upgradeMembershipService(payload, id)
      .then((response) => {
        dispatch(success(authConstants.UPGRADE_MEMBERSHIP_SUCCESS, response));
        dispatch(commonActions.hideLoader());
        return response;
      })
      .catch((error) => {
        dispatch(failure(authConstants.UPGRADE_MEMBERSHIP_FAILURE, error));
        dispatch(commonActions.hideLoader());
        return error.response;
      });
  };
}

function getUserByAffiliateId(id) {
  return (dispatch) => {
    dispatch(commonActions.loaderText("Signing up..."));
    dispatch(commonActions.showLoader());
    dispatch(request(authConstants.GET_USER_BY_ID_REQUEST));
    return authService
      .getUserByAffiliateId(id)
      .then((response) => {
        dispatch(success(authConstants.GET_USER_BY_ID_SUCCESS, response));
        dispatch(commonActions.hideLoader());
        return response;
      })
      .catch((error) => {
        dispatch(failure(authConstants.GET_USER_BY_ID_FAILURE, error));
        dispatch(commonActions.hideLoader());
        return error;
      });
  };
}

function loginRequest(payload, history) {
  return (dispatch) => {
    dispatch(request(authConstants.LOGIN_REQUEST));
    dispatch(commonActions.loaderText("Signing in..."));
    dispatch(commonActions.showLoader());
    authService
      .loginService(payload)
      .then((response) => {
        if (response.status === 200 && response.data.access_token) {
          dispatch(getUserDetail(history));
          window.localStorage.setItem(
            "accessToken",
            response.data.access_token
          );
          window.localStorage.setItem("lt", new Date());
          window.localStorage.setItem(
            "loginData",
            JSON.stringify(response.data)
          );
          dispatch(
            addCurrencyAndLanguage({
              currency: CURRENCY[0],
              language: LANGUAGES[0],
            })
          );
        }
        dispatch(success(authConstants.LOGIN_SUCCESS, response.status));
        // dispatch(commonActions.hideLoader());
      })
      .catch((error) => {
        dispatch(
          failure(authConstants.LOGIN_FAILURE, error?.response?.data?.message)
        );
        dispatch(commonActions.hideLoader());
      });
  };
}

function getUserDetail(history) {
  return (dispatch) => {
    dispatch(request(authConstants.GET_USER_DETAIL_REQUEST));
    // dispatch(commonActions.loaderText("Loading..."));
    // dispatch(commonActions.showLoader());
    return authService
      .getUserService()
      .then((response) => {
        if (window.localStorage.getItem("accessToken")) {
          if (
            response.data.type_id != process.env.REACT_APP_FREMIUM_USER_TYPE_ID
          ) {
            document.cookie = "is_premium=true;";
          } else {
            document.cookie = "is_premium=false;";
          }
        }
        dispatch(success(authConstants.GET_USER_DETAIL_SUCCESS, response));
        dispatch(commonActions.hideLoader());
        window.localStorage.setItem("user", JSON.stringify(response.data));

        authService.getUserPasses().then((passes) => {
          let userObject = JSON.parse(localStorage.getItem("user"));
          if (userObject) {
            userObject.wallet_pass = passes.data.data;
          }
          localStorage.setItem("user", JSON.stringify(userObject));
        });
        history("/landing");
        return response;
      })
      .catch((error) => {
        dispatch(commonActions.hideLoader());
        if (error && error.response && error.response.status === 401)
          dispatch(failure(authConstants.GET_USER_DETAIL_FAILURE, error));
      });
  };
}

// get Tenant Details
function getTenantCongfig(payload) {
  return async (dispatch) => {
    // dispatch(request(hotelConstants.HOTEL_PAYMENT_INTENT_REQUEST));
    // dispatch(commonActions.showLoader());
    try {
      const res = await http.get(
        `${process.env.REACT_APP_BASE_URL_TENANT}/api/v1/client/${payload?.client_id}/config/${payload?.user_id}/v2`
      );
      // dispatch(success(hotelConstants.HOTEL_PAYMENT_INTENT_SUCCESS, res));
      return res;
    } catch (error) {
      // dispatch(failure(hotelConstants.HOTEL_PAYMENT_INTENT_FAILURE, error));
      // dispatch(commonActions.hideLoader());
      return error;
    }
  };
}

function logoutRequest(payload) {
  return (dispatch) => {
    document.cookie =
      "is_premium=false; expires= Thu, 21 Aug 2014 20:00:00 UTC; ";
    dispatch(request(authConstants.LOGOUT_REQUEST));
    dispatch(commonActions.loaderText("Signing out..."));
    dispatch(commonActions.showLoader());
    authService
      .logoutService(payload)
      .then((response) => {
        dispatch(success(authConstants.LOGOUT_SUCCESS, response));
        document.cookie =
          "is_premium=false; expires= Thu, 21 Aug 2014 20:00:00 UTC; ";
        dispatch(commonActions.hideLoader());
        window.localStorage.clear();
      })
      .catch((error) => {
        dispatch(success(authConstants.LOGOUT_SUCCESS, error));
        dispatch(commonActions.hideLoader());
      });
  };
}

function forgotPasswordRequest(payload) {
  return (dispatch) => {
    dispatch(request(authConstants.FORGOT_PASSWORD_REQUEST));
    dispatch(commonActions.loaderText("Loading..."));
    commonActions.showLoader();

    authService.forgotPasswordService(payload).then(
      (response) => {
        dispatch(success(authConstants.FORGOT_PASSWORD_SUCCESS, response));
        dispatch(commonActions.hideLoader());
      },
      (error) => {
        dispatch(failure(authConstants.FORGOT_PASSWORD_FAILURE, error));
        dispatch(commonActions.hideLoader());
      }
    );
  };
}

function resetPasswordRequest(payload) {
  return (dispatch) => {
    dispatch(request(authConstants.RESET_PASSWORD_REQUEST));
    dispatch(commonActions.loaderText("Loading..."));
    commonActions.showLoader();

    return authService.resetPasswordService(payload).then(
      (response) => {
        dispatch(success(authConstants.RESET_PASSWORD_SUCCESS, response));
        dispatch(commonActions.hideLoader());
        return response;
      },
      (error) => {
        dispatch(failure(authConstants.RESET_PASSWORD_FAILURE, error));
        dispatch(commonActions.hideLoader());
      }
    );
  };
}

function resetServeError() {
  return (dispatch) => {
    dispatch(request(authConstants.RESET_SERVER_ERROR));
  };
}

function updateUserProfileAction(payload, userId) {
  return (dispatch) => {
    dispatch(request(authConstants.UPDATE_USER_DETAIL_REQUEST));
    dispatch(commonActions.loaderText("Updating..."));
    commonActions.showLoader();
    authService.updateUserProfileService(payload, userId).then(
      (response) => {
        window.localStorage.setItem("user", JSON.stringify(response.data));
        dispatch(success(authConstants.UPDATE_USER_DETAIL_SUCCESS, response));
        dispatch(commonActions.hideLoader());
      },
      (error) => {
        dispatch(failure(authConstants.UPDATE_USER_DETAIL_FAILURE, error));
        dispatch(commonActions.hideLoader());
      }
    );
  };
}

function updatePassowrdAction(payload) {
  return (dispatch) => {
    dispatch(request(authConstants.UPDATE_PASSWORD_REQUEST));
    dispatch(commonActions.loaderText("Updating..."));
    commonActions.showLoader();
    return authService.updatePassowrdService(payload).then(
      (response) => {
        dispatch(success(authConstants.UPDATE_PASSWORD_SUCCESS, response));
        dispatch(commonActions.hideLoader());
        return response;
      },
      (error) => {
        dispatch(
          failure(authConstants.UPDATE_PASSWORD_FAILURE, error.response)
        );
        dispatch(commonActions.hideLoader());
        return error;
      }
    );
  };
}

function addUserAction(payload, user_id, setShowAddUserFinalModal, resetForm) {
  return (dispatch) => {
    dispatch(request(authConstants.ADD_USER_DETAIL_REQUEST));
    dispatch(commonActions.loaderText("Adding..."));
    dispatch(commonActions.showLoader());
    authService.addUserService(payload, user_id).then(
      (response) => {
        dispatch(success(authConstants.ADD_USER_DETAIL_SUCCESS, response));
        resetForm();
        setShowAddUserFinalModal(false);
        dispatch(getGuestListAction(user_id));
        dispatch(commonActions.hideLoader());
        dispatch(request(authConstants.CLEAR_ERROR));
      },
      (error) => {
        dispatch(
          failure(authConstants.ADD_USER_DETAIL_FAILURE, error.response)
        );
        dispatch(commonActions.hideLoader());
      }
    );
  };
}

function updateGuestAction(
  payload,
  user_id,
  guest_id,
  setShowAddUserFinalModal
) {
  return (dispatch) => {
    dispatch(request(authConstants.UPDATE_GUEST_DETAIL_REQUEST));
    dispatch(commonActions.loaderText("Updating..."));
    dispatch(commonActions.showLoader());
    authService
      .updateGuestService(payload, user_id, guest_id)
      .then((response) => {
        dispatch(success(authConstants.UPDATE_GUEST_DETAIL_SUCCESS, response));
        setShowAddUserFinalModal(false);
        dispatch(getGuestListAction(user_id));
        dispatch(commonActions.hideLoader());
        dispatch(request(authConstants.CLEAR_ERROR));
      })
      .catch((error) => {
        dispatch(
          failure(authConstants.UPDATE_GUEST_DETAIL_FAILURE, error.request)
        );
        dispatch(commonActions.hideLoader());
      });
  };
}

function getGuestListAction(user_id) {
  return (dispatch) => {
    dispatch(request(authConstants.GET_GUEST_LIST_REQUEST));
    dispatch(commonActions.loaderText("Loading..."));
    dispatch(commonActions.showLoader());

    authService
      .getGuestList(user_id)
      .then((response) => {
        dispatch(commonActions.hideLoader());
        dispatch(success(authConstants.GET_GUEST_LIST_SUCCESS, response));
      })
      .catch((error) => {
        dispatch(commonActions.hideLoader());
        dispatch(failure(authConstants.GET_GUEST_LIST_FAILURE, error.response));
      });
  };
}

function cancelMembership() {
  return (dispatch) => {
    dispatch(request(authConstants.CANCEL_MEMBERSHIP_REQUEST));
    dispatch(commonActions.loaderText("Updating..."));
    dispatch(commonActions.showLoader());
    authService
      .cancelMembership()
      .then((res) => {
        dispatch(success(authConstants.CANCEL_MEMBERSHIP_SUCCESS, res));
        dispatch(commonActions.hideLoader());
      })
      .catch((error) => {
        dispatch(
          failure(authConstants.CANCEL_MEMBERSHIP_FAILURE, error.response)
        );
        dispatch(commonActions.hideLoader());
      });
  };
}

function getPaypalStatus(payload) {
  return async (dispatch) => {
    dispatch(request(authConstants.CANCEL_MEMBERSHIP_REQUEST));
    dispatch(commonActions.loaderText("Updating..."));
    dispatch(commonActions.showLoader());
    try {
      const res = await authService.getPaypalStatus(payload);
      dispatch(commonActions.hideLoader());
      return res;
    } catch (error) {
      dispatch(commonActions.hideLoader());
      return error.response;
    }
  };
}
function getPaypalVarificationStatus(payload) {
  return async (dispatch) => {
    dispatch(request(authConstants.CANCEL_MEMBERSHIP_REQUEST));
    dispatch(commonActions.loaderText("Updating..."));
    dispatch(commonActions.showLoader());
    try {
      const res = await authService.getPaypalVarificationStatus(payload);
      dispatch(commonActions.hideLoader());
      return res;
    } catch (error) {
      dispatch(commonActions.hideLoader());
      return error.response;
    }
  };
}

function resetCancelMembershipStatus() {
  return (dispatch) => {
    dispatch(request(authConstants.RESET_CANCEL_MEMBERSHIP_STATUS));
  };
}

function confirmEmailAddressAction(payload, navigate) {
  return (dispatch) => {
    dispatch(request(authConstants.CONFIRM_EMAIL_REQUEST));
    dispatch(commonActions.loaderText("Confirming..."));
    authService.confirmEmailAddressService(payload).then(
      (response) => {
        dispatch(success(authConstants.CONFIRM_EMAIL_SUCCESS, response));
        window.localStorage.setItem(
          "confirmEmailResponse",
          JSON.stringify(response.data)
        );
        navigate("/thankyou");
        dispatch(commonActions.hideLoader());
      },
      (error) => {
        dispatch(commonActions.hideLoader());
        dispatch(failure(authConstants.CONFIRM_EMAIL_FAILURE, error.response));
      }
    );
  };
}

function request(action, payload) {
  return { type: action, payload };
}

function success(action, response) {
  return { type: action, response };
}

function failure(action, error) {
  return { type: action, error };
}
