import React from "react";

const AirlinePlaceHolderIcon = ({
    width = 32,
    height = 32, // or any default size of your choice
    color = "white", // or any color of your choice
    onClick = () => { },
    className = "",
}) => {
    return (
        <>
            <svg width={width} height={height} className={className} onClick={onClick} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_12120_43490)">
                    <path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="#2684FF" />
                    <path d="M17.5074 14.4632L13.2722 10.228L6.52194 9.72019L14.3862 17.5844L11.4756 20.4951L5.53125 20.7613L16.7534 31.9834C25.2391 31.589 31.9981 24.585 31.9981 16.0017C31.9981 14.4514 31.7768 12.9531 31.3655 11.5355L25.9003 6.07031L17.5074 14.4632Z" fill="#0065FF" />
                    <path d="M8.34219 7.87109L6.52344 9.71866L15.3469 13.9742L18.2786 11.816L19.2194 9.60372L8.34219 7.87109Z" fill="#DFE1E6" />
                    <path d="M9.95363 20.057L21.2371 7.32288C22.149 6.41094 24.4629 4.62869 25.9153 6.08107L18.2488 15.0827L9.16319 22.8331L5.53125 20.7577L6.81988 19.4691L9.95363 20.057Z" fill="#FAFBFC" />
                    <path d="M24.1266 23.6585L22.279 25.4772L18.0234 16.6538L20.1816 13.7221L22.3939 12.7812L24.1266 23.6585Z" fill="#F1F2F4" />
                    <path d="M11.9401 22.0437L24.6743 10.7602C25.5862 9.84828 27.3684 7.53441 25.9161 6.08203L9.16406 22.8341L11.2394 26.466L12.5281 25.1774L11.9401 22.0437Z" fill="#DFE1E6" />
                </g>
                <defs>
                    <clipPath id="clip0_12120_43490">
                        <rect width={width} height={height} fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    );
};
export default AirlinePlaceHolderIcon;
