import RightClickIcon from "../../Assets/img/right-click.svg";
import CloseClickIcon from "../../Assets/img/close-icon.svg";
import DollerIcon from "../../Assets/img/dolller.svg";

const BaggagePolicies = ({ advanceFeatures }) => {
    return <>
        <div className="card2 flight-baggage-info">
            <div className="row">
                <div className="col col-6">
                    <div className="primaryInfoTitle">
                        <h3 className="boxTitle">Baggage </h3>
                    </div>
                    <div className="box-style">
                        {advanceFeatures?.carryOnBags[0]?.allowed && <h4> <img src={RightClickIcon} alt="icon" /> {advanceFeatures?.carryOnBags[0]?.count} Carry-on bags
                            included*</h4>}
                        {advanceFeatures?.checkedBags[0]?.allowed && <h4> <img src={CloseClickIcon} alt="icon" /> {advanceFeatures?.checkedBags[0]?.count}{" "}
                            checked bags included**</h4>}
                        {advanceFeatures?.carryOnBags[0]?.allowed && (
                            <span>
                                *Carry-on bag included for up to{" "}
                                {advanceFeatures?.carryOnBags[0]?.weight}  <br />
                            </span>
                        )}
                        {advanceFeatures?.checkedBags[0]?.allowed && (
                            <span >
                                **Checked bags can weigh up to{" "}
                                {advanceFeatures?.checkedBags[0]?.weight} each
                            </span>
                        )}
                    </div>
                </div>
                <div className="col col-6">
                    <div className="primaryInfoTitle">
                        <h3 className="boxTitle">Flexibility </h3>
                    </div>
                    <div className="box-style">
                        <h4><img src={DollerIcon} alt="icon" />Cancellation fee applies</h4>
                        <h4><img src={DollerIcon} alt="icon" />Change fee applies</h4>
                    </div>
                </div>
            </div>
            <div className="content-box">
                <p>Please check the travel rules of all regulatory websites prior to booking as well as commencing travel. It is the sole responsibility of the passenger to ensure his or her eligibility to enter the destination or transit countries (as applicable). Tripello accepts no liability in this regard.</p>
            </div>
        </div>

    </>
}

export default BaggagePolicies;