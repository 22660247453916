import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LogoWhite from "./FormComponent/LogoWhite";
import logo from "../Assets/img/logo.png";
import logo2 from '../Assets/img/logo2.png';
import Button from "./FormComponent/Button";
import Modal from "react-modal";

import MenuBtn from "../Assets/img/our-story/menu-btn.png"
import MenuBtnBlue from "../Assets/img/our-story/menu-btn-blue.png"
import blueIcon from "../Assets/img/icons/cancel-close-icon-blue.png";
import SupportIcon from "../Assets/img/support/customer-service-icon-blue.png";

function Header({ headerName }) {
  const [isTouch, isTouchedDevice] = useState(false);
  const [isToggleMenu, setToggleMenu] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    isTouchEnabled();
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);
  const isSticky = (e) => {
    const header = document.querySelector("#root");
    const scrollTop = window.scrollY;
    scrollTop >= 120
      ? header.classList.add("header-sticky")
      : header.classList.remove("header-sticky");
  };
  const isTouchEnabled = (e) => {
    if (('ontouchstart' in window) ||
      (navigator.maxTouchPoints > 0) ||
      (navigator.msMaxTouchPoints > 0)) {
      isTouchedDevice(true)
    } else {
      isTouchedDevice(false)
    }
  }

  const handleToggle = () => {
    setToggleMenu((current) => !current);
  };
  const handleOpenCloseModal = () => {
    if (!modalOpen) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  };
  return (
    <div className={isToggleMenu ? "fixedHeader header-toggle" : "fixedHeader"}>
      <header className={headerName ? "header header-removed-nav" : "header"}>
        <div className="container">
          <button type="button" className="mobile-menu-btn" onClick={handleToggle} aria-label="Name">
            <img src={MenuBtn} alt="" className="logo1" />
            <img src={MenuBtnBlue} alt="" className="sticky-icon" />
          </button>
          <Link to={"/"} className="logoWrap">
            <LogoWhite src={logo} />
            <img src={logo2} alt="Tripello" className="logo-sticky" />
          </Link>
          {!headerName && <nav className="navigation">
            {/* {!headerName && <Link to="/our-story" className="our-story-link">
              Contact Us
            </Link>} */}
            {!headerName && <Button
              onClick={handleOpenCloseModal}
              className="btn-style-removed header-contact-us-link"
              text="Contact Us"
            />}
            {!headerName && <Link to="/our-story" className="our-story-link">
              Our Story
            </Link>}
            <ul>
              <li>
                <a href="https://tripello.com/blog/" target="_blank" rel="noopener noreferrer" className="btnBorder3">Tripello Hub</a>
              </li>
            </ul>
          </nav>}
          {!headerName && !window.localStorage.getItem("accessToken") && <Link to="/login" className="btnBorder3">
            Sign In
          </Link>
          }
          {!headerName && window.localStorage.getItem("accessToken") && <Link to="/landing" className="btnBorder3">
            Sign In
          </Link>
          }
        </div>
      </header>
      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          ariaHideApp={false}
          closeModal={handleOpenCloseModal}
          className={`popup home-contact-popup popup-open`}
        >
          <div className="popup-content">
            <div className="popup-title">
              <h3>Contact Us</h3>
              <Button
                onClick={handleOpenCloseModal}
                className="btn close"
                imageSrc={blueIcon}
              />
            </div>
            <div className="content-box">
              <p>You can reach us at <a href="mailto:membersupport@tripello.com">membersupport@tripello.com</a> or (24/7) through the Tripello Customer Service Portal in the Member Section after Login.</p>
              <div className="support-icon">
                <img src={SupportIcon} alt="icon"/>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default Header;