import React, { useState } from "react";
import FlightTime from "./FlightTime";
import { range, times } from "lodash";
import FlightDetailsModal from "../Modals/FlightDetailsModal";
import FlightSubDetails from "./FlightSubDetails";
import { Tooltip } from "antd";
import AirlinePlaceHolderIcon from "../FlightListing/AirlinePlaceHolderIcon";
import MultiFlightIcon from "../FlightListing/MultiFlightIcon";

function SingleFlightModal(props) {
  const { is_domestic, flights, flightLegs } = props;

  const timeConvert = (num) => {
    let hours = num / 60;
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);
    return rhours + "h " + rminutes + "m";
  };

  let originAirport = flightLegs?.flightLegs[0]?.departureAirportCode;
  let destinationAirport =
    flightLegs?.flightLegs[flightLegs?.flightLegs?.length - 1]
      ?.arrivalAirportCode;
  let startTime = flightLegs?.flightLegs[0]?.departureDateTime;
  let endTime =
    flightLegs?.flightLegs[flightLegs?.flightLegs?.length - 1]?.arrivalDateTime;

  let stops = flightLegs?.stops;
  let numberOfStops = flightLegs?.numberOfStops;

  const [indexVal, setIndexVal] = useState(0);

  const totalJourneyDuration = flightLegs?.flightLegs.reduce(
    (previousValue, currentValue) => {
      return previousValue + currentValue.duration;
    },
    0
  );
  const totalJourneyDurationInHours = timeConvert(totalJourneyDuration);
  const [logoImageError, setLogoImageError] = useState(false);

  return (
    <>
      <div className="flightInfoRow">
        <div className="airLinesLogo">
          {/* <Tooltip title={flightLegs?.flightLegs[indexVal].marketingAirlineName} placement="bottom">
            <img
              src={flightLegs?.flightLegs[indexVal]["marketingAirlineLogoUrl"]}
              alt=""
            />
          </Tooltip> */}
          {flightLegs?.flightLegs[0]?.marketingAirlineLogoUrl &&
            !flightLegs?.mixedAirlines &&
            !logoImageError && (
              <Tooltip
                title={flightLegs?.flightLegs[indexVal]["marketingAirlineName"]}
                placement="bottom"
              >
                <img
                  src={
                    flightLegs?.flightLegs[indexVal]["marketingAirlineLogoUrl"]
                  }
                  alt={flightLegs?.flightLegs[indexVal]["marketingAirlineName"]}
                  onError={() => {
                    setLogoImageError(true);
                  }}
                />
              </Tooltip>
            )}
          {flightLegs?.mixedAirlines && !logoImageError && (
            <Tooltip
              className="toolTipStyle d-flex a-center"
              getPopupContainer={(trigger) => trigger.parentElement}
              title={flightLegs?.flightLegs[indexVal]["marketingAirlineName"]}
            >
              <MultiFlightIcon />
            </Tooltip>
          )}
          {logoImageError && !flightLegs?.mixedAirlines && (
            <Tooltip
              title={flightLegs?.flightLegs[indexVal]["marketingAirlineName"]}
              placement="bottom"
            >
              <AirlinePlaceHolderIcon />
            </Tooltip>
          )}
        </div>

        <div className="flightStartEnd">
          <FlightTime
            className="flightStartInfo"
            originAndDest={originAirport}
            startEndDate={startTime}
            startEndTime={startTime}
          />
          <div className="flightDurNstop">
            <div className="flightDuration">
              <i className="icon-timer"></i> {totalJourneyDurationInHours}
            </div>
            <div className="flightBar">
              {range(0, numberOfStops)?.map((ele, index) => {
                return <i key={`${index}_stops`} className="fa fa-circle"></i>;
              })}
              <span className="icon-flight">
                <span className="path1"></span>
              </span>
            </div>
            <div
              className={`flgithStops ${
                numberOfStops > 0 ? "" : "nonStopFlight"
              }`}
            >
              {numberOfStops == 0
                ? "Nonstop"
                : `${numberOfStops} ${
                    numberOfStops < 2 ? "stop" : "stops"
                  } (${stops?.map((item) => item.iataCode)})`}
            </div>
          </div>
          <FlightTime
            className="flightEndInfo"
            originAndDest={destinationAirport}
            startEndDate={endTime}
            startEndTime={endTime}
            // adjustment={"flights[flights?.length - 1]?.departureDateAdjustment"}
          />
        </div>
      </div>
      <FlightSubDetails
        flightLegs={flightLegs}
        totalJourneyDurationInHours={totalJourneyDurationInHours}
      />
    </>
  );
}

export default SingleFlightModal;
