import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../Component/FormComponent/Button';
import { getCurrencyAndLanguage } from '../../../Util/util';
import { CARD_TYPES } from '../../../Util/constants'


function HotelPriceSection({
  hotel,
  priceType,
  setPriceType,
  setShowMemberPrice,
  showMemberPrice,
}) {

  const { currencySymbol } = getCurrencyAndLanguage();

  const no_of_nights = hotel?.number_of_nights;

  // const isFreemiumUser = hotel?.price_detail?.price?.card_type === CARD_TYPES.FREEMIUM;
  const isFreemiumUser = hotel?.price_detail?.member_price ? true : false;

  const { price, member_price } = hotel?.price_detail || {};

  const { savings_percentage, total_with_tax, savings, per_night_retail_price, retail_price, per_night_total_with_tax, per_night_savings } = showMemberPrice ? member_price : price;

  const showNightlyPrice = priceType === 'night';

  const formatedPricesObj = {
    savings_percentage: savings_percentage,
    savings_amount: showNightlyPrice ? per_night_savings : savings,
    public_price: showNightlyPrice ? per_night_retail_price : retail_price,
    from_price: showNightlyPrice ? per_night_total_with_tax : total_with_tax,
  };

  return (
    <>
      <div className="title d-flex">
        <Button
          className={`totalPriceTab ${priceType === 'night' ? 'active' : ''}`}
          onClick={() => {
            setPriceType('night');
          }}
        >
          Nightly Price
        </Button>
        <Button
          className={`nightlyPriceTab ${priceType === 'total' ? 'active' : ''}`}
          onClick={() => {
            setPriceType('total');
          }}
        >
          Total Price
        </Button>
      </div>
      <div className="infoBoxWrapper totalPriceBox">

        {/* {formatedPricesObj?.savings_amount > 0 && ( */}
        <div className="dflex publicPrice">
          <span className="lightText">Public Price:</span>
          <span className="font14">
            {currencySymbol}{formatedPricesObj?.public_price}
          </span>
        </div>
        {/* )} */}
        <div>
          <div className={`dflex btmSpace fromPrice ${formatedPricesObj?.savings_amount > 0 ? '' : 'singlePrdice'}`}>
            <div className="fromPriceWrap">
              <span className={`font17 lightText ${showMemberPrice && 'from-text-label'}`}>
                You Pay:
              </span>
              <p className="btmSpace lightText ">
                ({no_of_nights} {no_of_nights > 1 ? 'nights' : 'night'}  incl. taxes &amp; fees)
              </p>
            </div>
            <span className={`font17 ${showMemberPrice && 'from-text-label'}`}>
              {currencySymbol}{formatedPricesObj?.from_price}
            </span>
          </div>
        </div>

        <div className="buttonAndTextGroup savings-group-wrap">
          <div className="dflex blueText btmSpace2 savings">
            {formatedPricesObj?.savings_percentage ? (
              <>
                <span>Savings ({formatedPricesObj?.savings_percentage}):</span>
                <span className="font14">
                  {currencySymbol}{formatedPricesObj?.savings_amount}
                </span>
              </>
            ) : ''}
          </div>
        </div>

        <div className="buttonGroups">
          {showMemberPrice && (
            <div className="centerAlign buttonSpace upgradeBtn">
              <Link to="/membership-upgrade" className="btn">
                <i className="icon-border-plain"></i>
                Upgrade
              </Link>
              <button className="btn-cancel"
                onClick={() => {
                  setShowMemberPrice(false);
                }}
              >
                Cancel
              </button>
            </div>
          )}

          {isFreemiumUser && !showMemberPrice &&
            <div className="centerAlign buttonSpace">
              <button
                className="btnBorder2 btn-see-member-price"
                onClick={() => {
                  setShowMemberPrice(true);
                }}
              >
                <i className="icon-border-plain"></i>
                See Member Price
              </button>
            </div>
          }

          {!showMemberPrice &&
            <div className="centerAlign buttonSpace buttonSpace-select-room">
              <Link
                to={`/hotel-detail/${hotel?.id}`}
                className="btn btn-select-room"
              >
                Select Room
              </Link>
            </div>
          }

        </div>

      </div>
    </>
  );
}

export default HotelPriceSection;
