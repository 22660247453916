import React from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import business from "../../Assets/img/work-bag.png";
import couple from "../../Assets/img/couple.png";
import solo from "../../Assets/img/single.png";
import family from "../../Assets/img/family.png";
function TravelersCircularBar({ typesOfTravlers }) {
 
  return (
    <div className="progressListRow">
      {typesOfTravlers &&
        typesOfTravlers.map((ele, index) => {
          if(ele.tab_name == "all") return
          let Img = null
          switch (ele.tab_name) {
            case "business": Img = business; break;
            case "couple": Img = couple; break;
            case "single": Img = solo; break;
            case "family": Img = family; break;
            case "solo": Img = solo; break;
          }

          return (
            <div className="progressList" key={index}>
              <div className="rings">
                <div className="percent1">
                  <CircularProgressbarWithChildren
                    value={ele.reviews_percent}
                    strokeWidth={6}
                    styles={{
                      path: { stroke: `#00b7a6`, strokeLinecap: "round" },
                      trail: { stroke: `rgba(126, 148, 168, 0.4)` },
                    }}
                  >
                  <img
                      style={{ width: 30, marginTop: -5 }}
                      src={Img}
                      alt="doge"
                    /> 
                  </CircularProgressbarWithChildren>
                </div>
              </div>
              <h4>
                {ele.tab_name} <span>({ele.reviews_percent}%)</span>
              </h4>
            </div>

          );
        })}
    </div>
  );
}

export default TravelersCircularBar;
