import { Radio } from "antd";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import closeButton from "../../Assets/img/close-blue-icon.png";
import BackArrow from "../../Assets/img/icons/back-arrow.svg";
import Button from "../../Component/FormComponent/Button";
import { flightActions } from '../../Stores/Actions';

function CancellationModalNew({ openModal, closeModal, PNR }) {
  const dispatch = useDispatch();
  const params = useParams();

  const [value, setValue] = useState(1);

  const onChange = (e) => {
    setValue(e.target.value);
  };


  const cancelBookingDetails = useSelector(
    (state) => state.flightReducer.cancelBookingDetails
  );

  // STATES
  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [lastStep, setLastStep] = useState(false);
  const [canclledBooking, setCanclledBooking] = useState(false);
  const [cancelReason, setCancelReason] = useState(null);
  const [cancelIssue, setCancelIssue] = useState(null);
  const [cancelLoader, setCancelLoader] = useState(false);
  const [status, setStatus] = useState();

  const [refundModeState, setRefundModeState] = useState(
    cancelBookingDetails?.data?.refundDetails?.refund_methods[0]?.code
  );
  useEffect(() => {
    setCancelLoader(true);
    dispatch(flightActions.cancelRefundDetails(params.bookingID)).then((res) => {
      if (res?.status === 200) {
        setCancelLoader(false);
        setStatus(true);
      } else {
        setCancelLoader(false);

        setStatus(false);
      }
    });
  }, []);
  // STEP ONE SELECT REASON
  const goToStep1 = () => {
    setStep2(false);
    setStep1(true);
  };
  // STEP TWO REFUND BREAKUP
  const goToStep2 = () => {
    setStep1(false);
    setStep2(true);
  };
  // STEP THREE FINAL STEP
  const goToStep3 = () => {
    setStep2(false);
    setStep3(true);
  };
  // BACK TO STEP 2 REFUND BREAKUP
  const backToStep2 = () => {
    setStep3(false);
    setStep2(true);
  };
  // FINAL CONFORMATION
  const lastStepConfirmation = () => {
    setStep3(false);
    setLastStep(true);
  };

  const backToRefund = () => {
    setLastStep(false);
    setStep3(true);
  };

  // CANCELLATION CALL
  const bookingCancelledConfrim = () => {
    const payload = {
      bookingRefId: params.bookingID,
      cancellationReason: cancelReason,
      cancellationDescription: cancelIssue,
      refundMode: refundModeState,
    };
    setCancelLoader(true);
    dispatch(flightActions.cancelledBooking(payload)).then((res) => {
      if (res?.payload?.status == 200) {
        setCancelLoader(false);
        setLastStep(false);
        setCanclledBooking(true);
        setStatus();
        closeModal();
      } else {
        setCancelLoader(false);
        setLastStep(false);
        setStatus()
        closeModal();

      }
    });
  };

  // disableProceedButton;
  const disableProceedButton = () => {
    if (cancelReason) {
      if (cancelReason != "Other") {
        return false;
      } else if (cancelReason == "Other" && cancelIssue?.length > 0) {
        return false;
      }
      return true;
    }
    return true;
  };

  useEffect(() => {
    setRefundModeState(cancelBookingDetails?.data?.refundDetails?.refund_methods[0]?.code);
  }, [cancelBookingDetails]);

  // CANCELLATION REASONS
  const reasonsArray = [
    "I want to change my dates",
    "My plan is cancelled",
    "I found a better option",
    "Other",
  ];


  return (
    <Modal
      ariaHideApp={false}
      isOpen={openModal}
      onRequestClose={closeModal}
      contentLabel="My dialog"
      className="mymodal"
      overlayClassName="myoverlay"
      closeTimeoutMS={500}
    >
      {status && !cancelLoader &&
        <div className="popup popup-open flight-confirmation-cancel-popup">
          <div className="popup-content">
            <div className="popup-title">
              <h3>Cancel Your Flight</h3>
              <button className="btn close" onClick={closeModal}>
                <img src={closeButton} alt="icon" />
              </button>
            </div>
            <div className="popup-content-title">
              <h4>Booking PNR: {PNR}</h4>
              {/* <div className="status status-green">Refundable</div> */}
            </div>
            <div className="resons-wrap">
              <ul>
                <li className="active">
                  <span className="number">1</span> Select Reason{" "}
                  <span className="dash-line"></span>
                </li>
                <li className={`${!step1 || step2 ? "active" : ""}`}>
                  <span className="number">2</span> View Refund{" "}
                  <span className="dash-line"></span>
                </li>
                <li className={`${!step1 && !step2 && step3 ? "active" : ""}`}>
                  <span className="number">3</span> Confirm Cancellation
                </li>
              </ul>
            </div>
            {step1 && (
              <div className="reasons-content-wrap step-1">
                <h4 className="content-title">
                  Select a reason for cancelling this booking
                </h4>
                <Radio.Group onChange={onChange} value={value}>
                  {reasonsArray?.map((ele, index) => {
                    return (
                      <Radio
                        key={index}
                        value={ele}
                        checked={ele == cancelReason}
                        onChange={(key) => setCancelReason(key.target.value)}
                      >
                        {ele}
                      </Radio>
                    );
                  })}
                </Radio.Group>
                {cancelReason == "Other" && (
                  <div className="form-group">
                    <textarea
                      onChange={(e) => setCancelIssue(e.target.value)}
                      value={cancelIssue}
                      className="form-control"
                      placeholder="Tell us your issue"
                      name=""
                      rows={4}
                      id=""
                    ></textarea>
                  </div>
                )}
              </div>
            )}
            {step2 && (
              <div className="step-2">
                <h4 className="content-title">Refund for Cancelling</h4>
                <div className="box">
                  <div className="row no-gutters">
                    <div className="col col-7">
                      <div className="content">
                        <h4>Total Paid</h4>
                        <h5>Base Fare</h5>
                        <h5>Taxes & Fees</h5>
                      </div>
                    </div>
                    <div className="col col-5 col-right">
                      <div className="content text-right">
                        <h4>
                          {
                            cancelBookingDetails?.data?.refundDetails?.price_details?.currency
                              ?.symbol
                          }
                          {cancelBookingDetails?.data?.refundDetails?.price_details?.total}
                        </h4>
                        <h5>
                          {
                            cancelBookingDetails?.data?.refundDetails?.price_details?.currency
                              ?.symbol
                          }
                          {cancelBookingDetails?.data?.refundDetails?.price_details?.base_price}
                        </h5>
                        <h5>
                          {
                            cancelBookingDetails?.data?.refundDetails?.price_details?.currency
                              ?.symbol
                          }
                          {cancelBookingDetails?.data?.refundDetails?.price_details?.taxes}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters">
                    <div className="col col-7">
                      <div className="content">
                        <h4>Total Deductions</h4>
                        <h5>Cancelation Charges</h5>
                        {/* <h5>Convenience Fee</h5> */}
                        <h5>Payment Gateway Fee</h5>
                        <h5>Admin Fee</h5>
                      </div>
                    </div>
                    <div className="col col-5 col-right">
                      <div className="content text-right">
                        <h4>
                          {
                            cancelBookingDetails?.data?.refundDetails?.price_details?.currency
                              ?.symbol
                          }
                          {
                            cancelBookingDetails?.data?.refundDetails?.deductions
                              ?.total_deduction
                          }
                        </h4>
                        <h5>
                          {
                            cancelBookingDetails?.data?.refundDetails?.price_details?.currency
                              ?.symbol
                          }
                          {
                            cancelBookingDetails?.data?.refundDetails?.deductions
                              ?.booking_fee_deduction
                          }
                        </h5>
                        <h5>
                          {
                            cancelBookingDetails?.data?.refundDetails?.price_details?.currency
                              ?.symbol
                          }
                          {
                            cancelBookingDetails?.data?.refundDetails?.deductions
                              ?.payment_gateway_fee
                          }
                        </h5>
                        {/* {/* <h5>$248.62</h5> */}
                        <h5>{
                          cancelBookingDetails?.data?.refundDetails?.price_details?.currency
                            ?.symbol
                        }
                          {
                            cancelBookingDetails?.data?.refundDetails?.deductions
                              ?.admin_fees
                          }</h5>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters">
                    <div className="col col-7">
                      <div className="content">
                        <h4>Total Refund Amount</h4>
                        <h5>Refund To Payment Method</h5>
                        {cancelBookingDetails?.data?.refunds?.checkout_wallet
                          ?.wallet_name && (
                            <h5>
                              {
                                cancelBookingDetails?.data?.refunds?.checkout_wallet
                                  ?.wallet_name
                              }{" "}
                            </h5>
                          )}
                        {cancelBookingDetails?.data?.refunds?.redemption_wallet
                          ?.wallet_name && (
                            <h5>
                              {
                                cancelBookingDetails?.data?.refunds
                                  ?.redemption_wallet?.wallet_name
                              }
                            </h5>
                          )}
                      </div>
                    </div>
                    <div className="col col-5 col-right">
                      <div className="content text-right">
                        <h4>
                          {
                            cancelBookingDetails?.data?.refundDetails?.price_details?.currency
                              ?.symbol
                          }
                          {
                            cancelBookingDetails?.data?.refundDetails?.refunds
                              ?.total_refund_amout
                          }
                        </h4>
                        <h5>
                          {" "}
                          {
                            cancelBookingDetails?.data?.refundDetails?.price_details?.currency
                              ?.symbol
                          }
                          {
                            cancelBookingDetails?.data?.refundDetails?.refunds
                              ?.refund_to_payment_method_amount
                          }
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="highlighted-box">
                  <p>
                    • This is a tentative refund quote. The actual refund amount
                    may vary upon cancellation.
                  </p>
                </div>
              </div>
            )}
            {step3 && (
              <div className="step-3">
                {cancelBookingDetails?.data?.refund_methods?.length > 0 && (
                  <h4 className="content-title">Select a refund mode</h4>
                )}
                <div className="box">
                  <Radio.Group onChange={onChange} value={refundModeState}>
                    {cancelBookingDetails?.data?.refundDetails?.refund_methods?.map(
                      (mode, index) => (
                        <Radio
                          key={index}
                          value={mode?.code}
                          // checked={mode?.code == refundModeState}
                          onChange={(key) =>
                            setRefundModeState(key?.target.value)
                          }
                        >
                          <div className="row no-gutters">
                            <div className="col col-6">
                              <h4>{mode?.mode}</h4>
                              <h5>{mode?.payment_mode}</h5>
                              {mode?.currency_wallet?.wallet_name && (
                                <h5>{mode?.currency_wallet?.wallet_name}</h5>
                              )}
                              {mode?.points_wallet?.wallet_name && (
                                <h5>{mode?.points_wallet?.wallet_name}</h5>
                              )}
                            </div>
                            <div className="col col-6 text-right">
                              <h4>
                                {mode?.currency}
                                {mode?.amount}
                              </h4>
                              <h5>
                                {mode?.currency}
                                {mode.paid_via_payment}
                              </h5>
                              {mode?.currency_wallet?.wallet_name && (
                                <h5>
                                  {mode?.currency}
                                  {mode?.currency_wallet?.amount}
                                </h5>
                              )}
                              {mode?.points_wallet?.wallet_name && (
                                <h5>
                                  {mode?.currency}
                                  {mode?.points_wallet?.amount}
                                </h5>
                              )}
                            </div>
                          </div>
                          <p>Note:</p>
                          <ol start="1">
                            <li>
                              Refund will be credited to wallet within 60 seconds.
                              This amount in wallet has no expiry and no
                              restrictions. You can use this amount to book travel
                            </li>
                            <li>
                              The amount to payment method will be credited within
                              5-12 working days
                            </li>
                          </ol>
                        </Radio>
                      )
                    )}
                    {/* <Radio value={6}>
                    <div className="row no-gutters">
                      <div className="col col-6">
                        <h4>Wallet</h4>
                        <h5>Visa</h5>
                      </div>
                      <div className="col col-6 text-right">
                        <h4>$4838.27</h4>
                        <h5>S4838.27</h5>
                      </div>
                    </div>
                    <p>
                      Refund of $12,923 will be processed within 60 seconds.
                      This amount in wallet has no expiry and no restrictions.
                      You can use this amount to book a hotel, flight, cars or
                      more with Tripello.
                    </p>
                  </Radio> */}
                  </Radio.Group>
                </div>
              </div>
            )}
            {step1 && (
              <div className="popup-footer">
                <div className="button-wrap">
                  <div>
                    {/* <Button  className="btn btn-back">
                    <img src={BackArrow} alt="icon" />
                    Back to Select Reason
                  </Button> */}
                  </div>
                  <Button
                    onClick={goToStep2}
                    disabled={disableProceedButton()}
                    className={`btn btn-proceed ${disableProceedButton() ? "disabled" : ""
                      }`}
                  >
                    Proceed to Cancel
                  </Button>
                </div>
              </div>
            )}
            {step2 && (
              <div className="popup-footer">
                <div className="button-wrap">
                  <div>
                    <Button onClick={goToStep1} className="btn btn-back">
                      <img src={BackArrow} alt="icon" />
                      Back to Select Reason
                    </Button>
                  </div>
                  <Button onClick={goToStep3} className="btn btn-proceed">
                    Proceed to Cancel
                  </Button>
                </div>
              </div>
            )}
            {step3 && (
              <div className="popup-footer">
                <div className="button-wrap">
                  <div>
                    <Button onClick={backToStep2} className="btn btn-back">
                      <img src={BackArrow} alt="icon" />
                      Back to Select Reason
                    </Button>
                  </div>
                  <Button
                    onClick={bookingCancelledConfrim}
                    className="btn btn-proceed"
                  >
                    Proceed to Cancel
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>}

      {!status && !cancelLoader && <div className="popup popup-open flight-confirmation-cancel-popup">  <div className="popup-content">
        <div className="popup-title">
          <h3>Cancel Your Flight</h3>
          <button className="btn close" onClick={closeModal}>
            <img src={closeButton} alt="icon" />
          </button>
        </div>
        <div className="popup-content-title">
          <h4>Booking PNR: {PNR}</h4>
        </div>
        <div className="resons-wrap">
          <span>Online cancellation for this booking is not supported because it is non-refundable.</span>
        </div>
      </div>
      </div>}
    </Modal>
  );
}

export default CancellationModalNew;
