import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { flightActions } from "../../Stores/Actions/flightActions";
import { commonActions } from "../../Stores/Actions";
import { FlightCheckoutValidation } from "./FlightCheckoutValidation";
import { ErrorModel } from "../../Assets/scss/components/ErrorModal/ErrorModal";
import moment from "moment";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import infoICon from "../../Assets/img/info.png";
import IncreaseIcon from "../../Assets/img/increase-icon.svg";
// import BasicModal from "../../Component/Modals/BasicModal"
import PriceCheckModel from "../../Component/Modals/PriceCheckModal.jsx";
import ReviewAndVerifyModal from "../../Component/Modals/ReviewAndVerifyModal.jsx";
import TimerIcon from "../../Assets/img/timer.svg";
import {
  countries,
  checkoutCountries,
  optionsAge,
} from "../../Data/static.service";

import Traveler from "./Traveler";
import FlightInfo from "./FlightInfo";
import InputField from "./InputField";
import PrimaryInfo from "./PrimaryInfo";
import Childs from "./Childs";
import Infant from "./Infant";
import WalletDetails from "./WalletDetails";
import Scrollbars from "react-custom-scrollbars";
import HeaderNavbar from "../../Component/HeaderNavbar";
import TermsCheckbox from "../../Component/TermsCheckbox";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import BaggagePolicies from "./BaggagePolicies.jsx";
import PenaltyInformation from "./PenaltyInformation";
// Images
import summeryIcon from "../../Assets/img/summary-icon.png";

// Stripe imports
import { Elements } from "@stripe/react-stripe-js";
import { StripeForm } from "../TravelWebappHotel/StripeForm";
import { loadStripe } from "@stripe/stripe-js";
import { commafy } from "../../Util/util";

function FlightCheckout() {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stripeFormRef = useRef();
  const stripe = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);
  const loading = useSelector((state) => state.commonReducer.loading);
  const [openReviewAndVerifyModal, setReviewAndVerifyModal] = useState(false);
  const handleReviewAndVerifyModal = () => setReviewAndVerifyModal(false);

  // const [style, setStyle] = useState("none");
  const [activeTab, setActiveTab] = useState(1);
  const [userOptions, setUserOptions] = useState([]);
  // const [checkedBox, setCheckedBox] = useState(false);
  const [userFromDrop, setUserFromDrop] = useState(null);
  const [expireDateFocus, setExpireDateFocus] = useState(false);
  const [stripeError, setStripeError] = useState("");

  const [showFlightInfoPopup, setShowFlightInfoPopup] = useState(false);

  const validatePayload =
    window.localStorage.getItem("validatePayload") &&
    JSON.parse(window.localStorage.getItem("validatePayload"));
  const flightPayload =
    window.localStorage.getItem("flightPayload") &&
    JSON.parse(window.localStorage.getItem("flightPayload"));

  let cabinClass = "";
  switch (validatePayload?.cabinClass) {
    case "Y":
      cabinClass = "economy";
      break;
    case "C":
      cabinClass = "business";
      break;
    case "F":
      cabinClass = "first";
      break;
    case "S":
      cabinClass = "premiumEconomy";
      break;
  }

  // const revalidatedFlightData = JSON.parse(
  //   localStorage.getItem("flightValidateRes")
  // );

  const stripeIntentData = useSelector(
    (state) => state.flightReducer.stripeIntentData
  );

  const revalidatedFlightData = useSelector(
    (state) => state.flightReducer.revalidatedFlightData
  );
  const availableBalance = useSelector(
    (state) => state.flightReducer.availableBalance
  );
  const currency = useSelector(
    (state) => state.currencyLanguageReducer?.data?.currency || {}
  );

  const usersData = useSelector((state) => state.commonReducer.getGuestData);
  const guestListOptions =
    useSelector((state) => state.commonReducer.guestListOptions) || [];

  const [popUpErrorMsg, setPopUpErrorMsg] = useState(null);

  const flightsWidgetData =
    window.localStorage.getItem("flightPayload") &&
    JSON.parse(window.localStorage.getItem("flightPayload"));

  const passengersCount =
    flightsWidgetData?.adults +
    flightsWidgetData?.children +
    flightsWidgetData?.infants;

  const handleErrorModal = () => {
    setPopUpErrorMsg(null);
  };

  const optionsTitle = [
    { value: "Mr.", label: "Mr." },
    { value: "Mrs.", label: "Mrs." },
    { value: "Ms.", label: "Ms." },
  ];

  useEffect(() => {
    if (!usersData) return;
    const result = usersData?.map((curr) => {
      return { id: curr.email, label: `${curr.fname} ${curr.lname}` };
    });
    setUserOptions(result);
  }, [usersData]);

  useEffect(() => {
    if (window.localStorage.getItem("domesticFlight")) {
      const domesticFlight = JSON.parse(
        window.localStorage.getItem("domesticFlight")
      );
      if (domesticFlight) {
        formikRef?.current?.setFieldValue("domesticFlight", true);
      } else {
        formikRef?.current?.setFieldValue("domesticFlight", false);
      }
      // window.localStorage.removeItem("domesticFlight")
      // return
    }

    validatePayload.currency_code = currency?.isoCode;
    const payload = {
      sequenceId: validatePayload?.sequenceNo,
      cabinClass: validatePayload?.cabinClass,
      currency: currency.isoCode,
    };

    dispatch(flightActions.validateFlightActions(payload)).then(
      (validateData) => {
        if (validateData?.status != 200) {
          setPopUpErrorMsg(validateData?.data?.message);
        }
        // else if (validateData?.status == 200) {
        //   formikRef?.current?.setFieldValue("domesticFlight", validateData?.data?.passportRequired);
        // }
      }
    );
  }, []);

  // const handleCheckbox = () => {
  //   setCheckedBox(!checkedBox);
  //   setStyle(!style);
  // };

  const handleExpireDate = (state) => {
    setExpireDateFocus(state);
  };

  const user = JSON.parse(window.localStorage.getItem("user"));

  const intialValues = {
    user: { id: user.email, label: `${user?.first_name} ${user?.last_name}` },
    title: user?.title || "",
    title2: "",
    firstName: user?.first_name,
    lastName: user?.last_name,
    email: user?.email,
    phoneNumber: user?.phone || "",
    phoneNumberCode: "",
    frequentFlyNumber: "",
    gender: "",
    nationality: user?.country || "",
    dob: null,
    city: user?.user_address?.city || "",
    state: "",
    addressLineOne: user?.user_address?.address_line_1 || "",
    addressLineTwo: user?.user_address?.address_line_2 || "",
    passportNumber: "",
    passportExpiryDate: null,
    cardHolderName: "",
    cardNumber: "",
    mmyy: "",
    cvv: "",
    zipCode: user?.user_address?.zip || "",
    terms: false,
    policy: false,
    adults: [],
    childs: [],
    infants: [],
    passengerArray: [],
    use_wallet: false,
    amountAdded: 0,
    domesticFlight:
      JSON.parse(window.localStorage.getItem("domesticFlight")) || true,
    phoneCode: "1",
  };

  const [adultsError, setAdultError] = useState(null);
  const [bookFlightPayloadWithGuest, setBookFlightPayloadWithGuest] =
    useState();

  useEffect(() => {
    dispatch(
      commonActions.getGuestListAction(
        JSON.parse(window.localStorage.getItem("user")).user_id
      )
    );
  }, []);

  // get available balance

  useEffect(() => {
    dispatch(flightActions.getAvailableBalance());
  }, []);

  const [stateOption, setStateOption] = useState([]);

  const countrySelect = (e) => {
    if (e?.value) {
      const stateSelect = checkoutCountries
        ?.find((ele) => ele["alpha-2"] === e?.value)
        ?.states.map((ele) => ({ value: ele.code, label: ele.name }));
      if (stateSelect && !isEmpty(stateSelect)) {
        setStateOption(stateSelect);
        formikRef?.current?.setFieldValue(
          "state",
          stateSelect.length > 0 && stateSelect[0].value
        );
      } else {
        setStateOption([]);
      }
    }
  };

  useEffect(() => {
    const stateSelect = checkoutCountries
      ?.find((ele) => ele["alpha-2"] == user?.country || "")
      ?.states.map((ele) => ({ value: ele.code, label: ele.name }));
    if (stateSelect && !isEmpty(stateSelect)) {
      setStateOption(stateSelect);
      if (user?.user_address?.state) {
        formikRef?.current?.setFieldValue("state", user?.user_address?.state);
      } else {
        formikRef?.current?.setFieldValue("state", stateSelect[0].value);
      }
    } else {
      setStateOption([]);
    }
  }, []);

  useEffect(() => {
    if (!userFromDrop) return;

    const result = usersData?.filter((curr) => {
      return curr.email == userFromDrop.id;
    });

    const stateSelect = checkoutCountries
      ?.find((ele) => ele["alpha-2"] == result[0]?.country || "")
      ?.states.map((ele) => ({ value: ele.code, label: ele.name }));
    if (stateSelect && !isEmpty(stateSelect)) {
      setStateOption(stateSelect);
      formikRef?.current?.setFieldValue("state", stateSelect[0].value);
    } else {
      setStateOption([]);
    }

    formikRef?.current?.setFieldValue("title", result[0]?.title);
    formikRef?.current?.setFieldValue("firstName", result[0]?.fname);
    formikRef?.current?.setFieldValue("lastName", result[0]?.lname);
    formikRef?.current?.setFieldValue("email", result[0]?.email);
    formikRef?.current?.setFieldValue("nationality", result[0]?.country);
    formikRef?.current?.setFieldValue("phoneNumber", result[0]?.phone);

    countrySelect(null, result[0]?.country);
  }, [userFromDrop]);

  const countryOptions = countries?.map((ele) => {
    return { value: ele.sortname, label: ele.name, id: ele.id };
  });

  const handleCustomChange = (formikProps, field, value) => {
    formikProps.setFieldValue(field, value);
    if (field == "user") setUserFromDrop(value);
  };

  const handleSubmit = (value) => {
    const domesticFlight = JSON.parse(
      window.localStorage.getItem("domesticFlight")
    );

    const cardElementContainer = document.querySelector(".cardWrapper");
    let isCardComplete = cardElementContainer?.classList?.contains(
      "StripeElement--complete"
    );
    if (isCardComplete) {
      setStripeError("");
    } else {
      setStripeError("Required");
      return;
    }

    // need to change this logic
    const newPhoneNumber = value?.phoneNumber.substring(
      value?.phoneCode?.length
    );

    const bookingPayload = {
      sequenceId: validatePayload?.sequenceNo,
      cabinClass: validatePayload?.cabinClass,
      primaryContact: {
        title: value?.title?.replace(".", ""),
        firstName: value?.firstName,
        lastName: value?.lastName,
        phoneCountryCode: value?.phoneCode,
        phoneNumber: newPhoneNumber,
        email: value?.email,
        postalCode: value?.zipCode,
        // streetAddress:value?.addressLineOne
      },
      travellers: [],
    };

    value?.adults?.forEach((adult) => {
      if (adult.check) {
        const temp = {
          passengerType: "ADT",
          gender: adult?.gender,
          title: adult?.title.replace(".", ""),
          firstName: adult?.firstName,
          lastName: adult?.lastName,
          middleName: adult?.middleName ? adult?.middleName : null,
          dateOfBirth: moment(adult?.dob).format("YYYY-MM-DD"),
          passportNumber: !domesticFlight
            ? adult?.passportNumber
              ? adult?.passportNumber
              : null
            : null,
          // passportExpiryDate: moment(adult.passportExpireDate).format("YYYY-MM-DD"),
          // passportExpiryDate: adult.passportExpiryDate,
          passportExpiryDate: !domesticFlight
            ? adult?.passportExpiryDate
              ? adult?.passportExpiryDate
              : null
            : null,
          passportCountry: adult?.passportCountry
            ? adult?.passportCountry
            : null,
          frequentFlyerNumber: String(adult?.frequentFlyerNumber) || null,
          nationality: adult?.nationality ? adult?.nationality : null,
          knownTravelerNumber: null,
          redressNo: null,
          nationalId: null,
        };
        bookingPayload.travellers.push(temp);
      }
    });
    value?.childs?.forEach((adult) => {
      const temp = {
        passengerType: "CHD",
        gender: adult?.gender,
        title: adult?.title.replace(".", ""),
        firstName: adult?.firstName,
        lastName: adult?.lastName,
        middleName: adult?.middleName ? adult?.middleName : null,
        dateOfBirth: moment(adult?.dob).format("YYYY-MM-DD"),
        // passportNumber: adult.passportNumber,
        passportNumber: !domesticFlight
          ? adult?.passportNumber
            ? adult?.passportNumber
            : null
          : null,
        passportExpiryDate: !domesticFlight
          ? adult?.passportExpiryDate
            ? adult?.passportExpiryDate
            : null
          : null,

        // passportExpiryDate: adult.passportExpiryDate ? adult.passportExpiryDate : "" ,
        // passportExpiryDate: moment(adult.passportExpireDate).format("YYYY-MM-DD"),
        // passportExpiryDate: adult.passportExpireDate !== "" ? moment(adult.passportExpireDate).format("YYYY-MM-DD") : "",
        // passportExpiryDate: adult.passportExpiryDate,
        passportCountry: adult?.passportCountry ? adult?.passportCountry : null,
        frequentFlyerNumber: String(adult?.frequentFlyerNumber) || null,
        nationality: adult?.nationality ? adult?.nationality : null,
        knownTravelerNumber: null,
        redressNo: null,
        nationalId: null,
      };
      bookingPayload.travellers.push(temp);
    });

    value?.infants?.forEach((adult) => {
      const temp = {
        passengerType: "INF",
        gender: adult?.gender,
        title: adult?.title.replace(".", ""),
        firstName: adult?.firstName,
        lastName: adult?.lastName,
        middleName: adult?.middleName ? adult?.middleName : null,
        dateOfBirth: moment(adult?.dob).format("YYYY-MM-DD"),
        // passportNumber: adult.passportNumber,
        // passportExpiryDate: adult.passportExpireDate !== "" ? moment(adult.passportExpireDate).format("YYYY-MM-DD") : "",
        // passportExpiryDate: adult.passportExpiryDate,
        // passportExpiryDate: adult.passportExpiryDate ? adult.passportExpiryDate : "" ,
        passportNumber: !domesticFlight
          ? adult?.passportNumber
            ? adult?.passportNumber
            : null
          : null,
        passportExpiryDate: !domesticFlight
          ? adult?.passportExpiryDate
            ? adult?.passportExpiryDate
            : null
          : null,

        passportCountry: adult?.passportCountry ? adult?.passportCountry : null,
        frequentFlyerNumber: String(adult?.frequentFlyerNumber) || null,
        nationality: adult?.nationality ? adult?.nationality : null,
        knownTravelerNumber: null,
        redressNo: null,
        nationalId: null,
      };
      bookingPayload.travellers.push(temp);
    });

    validatePayload.currency_code = currency?.isoCode;
    setBookFlightPayloadWithGuest(bookingPayload);
    // return

    //  flight pre booking api
    setReviewAndVerifyModal(true);
  };

  const handelPayNow = () => {
    dispatch(
      flightActions.preBookingFlightActions(bookFlightPayloadWithGuest)
    ).then((res) => {
      if (res?.status === 200) {
        const reValidatePayload = {
          sequenceId: validatePayload?.sequenceNo,
          cabinClass: validatePayload?.cabinClass,
          payment_gateway_type: "stripe_usd",
          currency: currency?.isoCode,
        };
        dispatch(flightActions.reValidateFlightActions(reValidatePayload)).then(
          (revalidateData) => {
            if (revalidateData?.status != 200) {
              setPopUpErrorMsg(revalidateData?.data?.message);
              setReviewAndVerifyModal(false);
              return;
            }
            const paymentIntentPayload = {
              bookingRefId: res?.data?.bookingRefId,
              paymentCode: "stripe_usd",
              travelVertical: "flights",
            };
            dispatch(
              flightActions.getStripeIntentRequest(paymentIntentPayload)
            ).then((stripeIntentData) => {
              if (stripeIntentData?.status != 200) {
                // setPopUpErrorMsg(stripeIntentData?.data?.message);
                setPopUpErrorMsg("Stripe Intent not found");
                setReviewAndVerifyModal(false);
                return;
              }
              if (stripeIntentData?.data?.stripe_payment_client_secret) {
                stripeFormRef.current.confirmStripePayment().then((comfirm) => {
                  if (!comfirm) {
                    setPopUpErrorMsg("Payment Error");
                    setReviewAndVerifyModal(false);

                    return;
                  }
                  dispatch(
                    flightActions.checkoutFlightActions(
                      bookFlightPayloadWithGuest
                    )
                  ).then((bookData) => {
                    if (bookData?.data?.id) {
                      navigate(
                        `/flight-confirmation/${bookData?.data?.bookingRefId}`
                      );
                    } else {
                      setPopUpErrorMsg(bookData?.data?.message);
                      setReviewAndVerifyModal(false);
                      return;
                    }
                  });
                });
              }
            });
          }
        );
      }
    });
  };

  const handleAdultsError = () => {
    const selectedAdults =
      formikRef?.current?.values?.adults?.filter((curr, i) => curr.check)
        .length || 0;
    if (selectedAdults != validatePayload?.adults) {
      setAdultError(`Please select ${validatePayload?.adults} adults`);
      return;
    } else {
      setAdultError(null);
    }
  };

  const numberCustomChange = (formikProps, field, value) => {
    if (
      field === "phoneNumber" ||
      field === "cardNumber" ||
      field === "cvv" ||
      field === "phoneCode"
    ) {
      const temp = Number(value);
      if (temp || value === "") {
        formikProps.setFieldValue(field, value);
      }
    }
  };

  const renderForm = (formikProps) => {
    const { values, handleSubmit, handleBlur, errors, touched, handleChange } =
      formikProps;
    const selectedAdults =
      formikRef?.current?.values?.adults?.filter((curr, i) => curr.check)
        .length || 0;
    if (selectedAdults == validatePayload?.adults) {
      setAdultError(null);
    }

    return (
      <form onSubmit={handleSubmit}>
        <PrimaryInfo
          formikProps={formikProps}
          handleCustomChange={handleCustomChange}
          nationalityOptions={countryOptions}
          stateOption={stateOption}
          setStateOption={setStateOption}
          titleOption={optionsTitle}
          countrySelect={countrySelect}
          userOptions={guestListOptions}
          numberCustomChange={numberCustomChange}
          hideUserSelect={
            process.env.REACT_APP_FREMIUM_USER_TYPE_ID == user.type_id
          }
        />
        <BaggagePolicies
          advanceFeatures={revalidatedFlightData?.advanceFeatures}
        />
        {/* flight-baggage-info end */}
        {(revalidatedFlightData?.refundPenalties?.length > 0 ||
          revalidatedFlightData?.reissuePenalties?.length > 0) && (
          <PenaltyInformation penalties={revalidatedFlightData} />
        )}
        {/* flight-baggage-info end */}
        {flightPayload?.adults > 0 && (
          <Traveler
            formikProps={formikProps}
            handleCustomChange={handleCustomChange}
            expireDateFocus={expireDateFocus}
            handleExpireDate={handleExpireDate}
            ageOptions={optionsAge}
            titleOption={optionsTitle}
            nationalityOptions={countryOptions}
            passengersCount={passengersCount}
            formikValues={values}
            adultsError={adultsError}
            domesticFlight={JSON.parse(
              window.localStorage.getItem("domesticFlight")
            )}
          />
        )}

        {flightPayload?.children > 0 && (
          <Childs
            formikProps={formikProps}
            handleCustomChange={handleCustomChange}
            expireDateFocus={expireDateFocus}
            handleExpireDate={handleExpireDate}
            ageOptions={optionsAge}
            titleOption={optionsTitle}
            nationalityOptions={countryOptions}
            passengersCount={passengersCount}
            getGuestData={usersData}
            childOrInfrant={flightPayload.children}
            travlerType={"childs"}
            domesticFlight={JSON.parse(
              window.localStorage.getItem("domesticFlight")
            )}
          />
        )}

        {flightPayload?.infants > 0 && (
          <Infant
            formikProps={formikProps}
            handleCustomChange={handleCustomChange}
            expireDateFocus={expireDateFocus}
            handleExpireDate={handleExpireDate}
            ageOptions={optionsAge}
            titleOption={optionsTitle}
            nationalityOptions={countryOptions}
            passengersCount={passengersCount}
            getGuestData={usersData}
            childOrInfrant={flightPayload.infants}
            domesticFlight={JSON.parse(
              window.localStorage.getItem("domesticFlight")
            )}
          />
        )}

        <div className="card2 billingInfo GlobalPaymentIntegrationWrap">
          <div className="billingDetailsToggle">
            <ul>
              <li onClick={() => setActiveTab(1)}>
                <a
                  className={activeTab == 1 ? "active" : ""}
                  style={{ cursor: "pointer" }}
                >
                  Pay Using Debit / Credit Cards
                </a>
              </li>

              <li onClick={() => setActiveTab(2)}>
                <a
                  className={activeTab == 2 ? "active" : ""}
                  style={{ cursor: "pointer" }}
                >
                  Pay Using Other Options
                </a>
              </li>
            </ul>
          </div>

          <div
            id="cardPaymentOption"
            className={`cardPaymentOption tabContent ${
              activeTab === 1 ? "activeTab" : ""
            }`}
          >
            <div className="wallet-options-same-height">
              <div className="sectionTitle walletwrapper">
                <h4 className="boxTitle">Billing Details</h4>
              </div>
              <div className="cardDetailsBox row">
                <InputField
                  name="cardHolderName"
                  type="text"
                  label="Card Holder Name"
                  placeholder="Card Holder Name"
                  value={values.cardHolderName}
                  error={errors.cardHolderName}
                  cardError={errors.cardHolderName}
                  touched={touched.cardHolderName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="cardHolder"
                />

                <Elements stripe={stripe}>
                  <StripeForm
                    ref={stripeFormRef}
                    clientSecret={
                      stripeIntentData?.stripe_payment_client_secret
                    }
                    setStripeError={setStripeError}
                    stripeError={stripeError}
                  />
                </Elements>
              </div>
            </div>
          </div>
          <div
            id="walletPaymentOption"
            className={`tabContent WalletPaymentOption  ${
              activeTab === 2 ? "activeTab" : ""
            }`}
          >
            {/* <div className="sectionTitle walletwrapper">
              <h4 className="boxTitle">Billing Details</h4>
            </div> */}
            <WalletDetails />
          </div>

          <div className="d-flex checkBoxWrap">
            <div className="checkboxDoubleWrap">
              <TermsCheckbox
                name="terms"
                id="terms"
                value={values.terms}
                onChange={(e) => {
                  handleCustomChange(formikProps, "terms", e.target.checked);
                }}
                error={errors.terms}
                touched={touched.terms}
                onBlur={handleBlur}
              />
              <TermsCheckbox
                name="policy"
                id="policy"
                value={values.policy}
                onChange={(e) => {
                  handleCustomChange(formikProps, "policy", e.target.checked);
                }}
                error={errors.policy}
                touched={touched.policy}
                onBlur={handleBlur}
                showPolicy={true}
              />
            </div>
            <div className="buttonWrap">
              <div className="desktopViewDisplayNone">
                <h4>
                  Amount Due Today:{" "}
                  <strong>
                    {" "}
                    &nbsp;{revalidatedFlightData?.price?.currenncySymbol}
                    {commafy(revalidatedFlightData?.price?.price?.totalPrice)}
                  </strong>
                </h4>
              </div>
              <button
                onClick={() => {
                  // setReviewAndVerifyModal(true);
                  handleAdultsError;
                }}
                type="submit"
                className="btn btn2 centerAlign"
                disabled={
                  availableBalance <
                  parseFloat(revalidatedFlightData?.price?.price?.totalPrice)
                }
              >
                <span className="icon-lock"></span>Pay Now
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  };

  const [openPriceUpdateModal, setOpenPriceUpdateModaL] = useState(
    revalidatedFlightData?.priceUpdateType === "up" ||
      revalidatedFlightData?.priceUpdateType === "down"
  );

  const modalContent = `
  Please review and verify all your booking information (incl. Names, Dates, Airlines, etc.) regarding its accuracy to avoid any booking complications. <br />
  This will also prevent any possible fees based on the <a>Cancellation and Change Policy.</a><br/>
  Please be aware if your Flight Booking is  <strong>NON-REFUNDABLE</strong> or <strong>REFUNDABLE</strong> <br/>
  (Airline change / cancellation policies still apply).
  `;

  return (
    <>
      {/* {revalidatedFlightData?.priceUpdateType !== null ?  */}
      {revalidatedFlightData?.priceUpdateType &&
        revalidatedFlightData?.priceUpdated && (
          <PriceCheckModel
            openModal={openPriceUpdateModal}
            closeModal={() => {
              setOpenPriceUpdateModaL(false);
            }}
            text={
              revalidatedFlightData?.priceUpdateType === "up" ? (
                <>
                  <h2>
                    Price For This Flight Has Increased By{" "}
                    {revalidatedFlightData?.priceDifference}
                  </h2>
                  <h3>
                    Your selection may have changed. Please review cancellation
                    policies & other inclusions before booking at the revised
                    price.
                  </h3>{" "}
                </>
              ) : (
                <>
                  <h2>
                    Price For This Flight Has Decreased By{" "}
                    {revalidatedFlightData?.priceDifference}
                  </h2>
                  <h3>
                    Your selection may have changed. Please review cancellation
                    policies & other inclusions before booking at the revised
                    price.{" "}
                  </h3>{" "}
                </>
              )
            }
            icon={IncreaseIcon}
            type={revalidatedFlightData?.priceUpdateType}
            routeFlag="flight"
          />
        )}

      <ErrorModel
        openErrorModal={popUpErrorMsg}
        handleErrorModal={handleErrorModal}
        message={popUpErrorMsg}
        showFlightSearchBtn={true}
      />

      <div className="siteBg checkOutPage checkoutPageWrap flight-checkout">
        <div className="pageWrap">
          <div className="fixedHeader">
            <HeaderNavbar useBlueIcon={true} />
          </div>

          <section className="mainContent">
            <Scrollbars
              autoHide
              autoHeight={true}
              autoHeightMin={120}
              autoHeightMax={`calc(100%)`}
              className="pageInternalScroll"
            >
              <div
                className="desktop-hide mobile-popup-bar"
                onClick={() => setShowFlightInfoPopup(true)}
              >
                <div className="booking-details-title">
                  <h4>Review Booking Details</h4>
                  <a className="detailsLink" href="#"></a>
                  <div className="info-icon">
                    <img src={summeryIcon} alt="icon" className="" />
                  </div>
                </div>
              </div>

              <div className="container dflex pageInternalScrollTopPadding">
                <div className="mainWrap">
                  <Formik
                    innerRef={formikRef}
                    onSubmit={handleSubmit}
                    initialValues={intialValues}
                    validationSchema={FlightCheckoutValidation(
                      JSON.parse(window.localStorage.getItem("domesticFlight"))
                    )}
                  >
                    {renderForm}
                  </Formik>
                </div>

                <div className="sideBar">
                  <div className="card2">
                    <FlightInfo revalidatedFlightData={revalidatedFlightData} />
                  </div>
                  {revalidatedFlightData?.freeCancellation && (
                    <div className="card2 flight-free-cancelation">
                      <div className="content">
                        <h4>
                          {" "}
                          <img src={TimerIcon} alt="icon" /> 12hr Free
                          Cancelation
                        </h4>
                        <p>
                          There’s no fee to cancel within 12 hours of booking.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Scrollbars>
          </section>

          <FooterWithMediaIcon
            className="white-icon"
            iconClassName="icon-white"
          />
        </div>
      </div>

      {showFlightInfoPopup && (
        <div id="flightDetailPopup" className="popupStyle flightDetailPopup">
          <div className="popUpBox">
            <button
              type="button"
              className="popupCloseBtn"
              onClick={() => setShowFlightInfoPopup(false)}
            >
              <span className="icon-close"></span>
            </button>
            <h3 className="popupTitle">Booking Details</h3>
            <div className="popupScrollBox">
              <div className="card2">
                <FlightInfo revalidatedFlightData={revalidatedFlightData} />
              </div>
              {revalidatedFlightData?.freeCancellation && (
                <div className="card2 flight-free-cancelation">
                  <div className="content">
                    <h4>
                      {" "}
                      <img src={TimerIcon} alt="icon" /> 12hr Free Cancelation
                    </h4>
                    <p>There’s no fee to cancel within 12 hours of booking.</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {openReviewAndVerifyModal && (
        <ReviewAndVerifyModal
          openModal={openReviewAndVerifyModal}
          closeModal={handleReviewAndVerifyModal}
          title="For Your Peace Of Mind"
          content={modalContent}
          handelPayNow={handelPayNow}
        />
      )}
    </>
  );
}
export default FlightCheckout;
