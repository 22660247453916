import { hotelConstants } from "../Constants";

const initialState = {
  newHotelSearchData: null,
  hotelDataMapView: null,
  hotelSearchData: null,
  hotelErrors: null,
  hotelCord: null,
  loading: false,
  zipData: null,
  error: null,
  hotelDetails: null,
  hotelPackageDetails: null,
  hotelDetailsError: null,
  hotelRoom: null,
  hotelRoomError: null,
  userBookingDetails: null,
  pdfUrl: null,
  cancelledBooking: null,
  packageData: null,
  stripeIntentData: null,
  hotelIntentData: null,
  stripeIntentSuccess: false,
  hotelIntentSuccess: false,
  confirmPaymentData: null,
  bookHotelData: null,
  stripeIntentError: null,
  hotelIntentError: null,
  bookingDataError: null,
  sessionExpireError: null,
  confirmPaymentError: null,
  errorCancelBooking: null,
  recheckHotelData: null,
  hotelPreBookingData: null,
  hotelBookingError: null,
  isPrebook: null,
  myTripBookings: null,
  myTripBookingCurrentPage: 1,
  cancelBookingDetails: null,
};

const hotelReducer = (state = initialState, action) => {
  switch (action.type) {
    case hotelConstants.NEW_HOTEL_SEARCH_DATA_SUCCESS:
      return { ...state, newHotelSearchData: action.response?.data?.data };

    case hotelConstants.HOTEL_DATA_FOR_MAP_VIEW:
      return { ...state, hotelDataMapView: action.response?.data?.data };

    // ---------------------------------------------------------
    case hotelConstants.SEARCH_HOTEL_REQUEST:
      return { ...state, hotelSearchData: null, hotelErrors: null };
    case hotelConstants.SEARCH_HOTEL_SUCCESS:
      return {
        ...state,
        hotelSearchData: action.response.data,
        hotelErrors: null,
      };
    case hotelConstants.SEARCH_HOTEL_FAILURE:
      return { ...state, hotelSearchData: null, hotelErrors: action.error };

    // ---------------------------------------------------------

    case hotelConstants.SEARCH_HOTEL_CORD_REQUEST:
      return { ...state, hotelCord: null, hotelErrors: null };
    case hotelConstants.SEARCH_HOTEL_CORD_SUCCESS:
      return {
        ...state,
        hotelCord: action.response.data,
        hotelErrors: null,
      };
    case hotelConstants.SEARCH_HOTEL_CORD_FAILURE:
      return { ...state, hotelCord: null, hotelErrors: action.error };
    case hotelConstants.VALIDATE_ZIP:
      return {
        ...state,
        loading: true,
      };

    // ---------------------------------------------------------

    case hotelConstants.VALIDATE_ZIP_SUCCESS:
      return {
        ...state,
        loading: false,
        zipData: action.response.data,
      };
    case hotelConstants.VALIDATE_ZIP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    // ---------------------------------------------------------

    case hotelConstants.HOTEL_GET_AVABILITY:
      return {
        ...state,
        packageData: null,
        loading: true,
        error: null,
        stripeIntentSuccess: false,
        hotelIntentSuccess: false,
        avabilityError: null,
        sessionExpireError: null,
        confirmPaymentError: null,
        bookingDataError: null,
      };
    // ---------------------------------------------------------

    case hotelConstants.HOTEL_GET_AVABILITY_SUCCESS:
      return {
        ...state,
        loading: false,
        packageData: { ...action.response.data },
      };
    case hotelConstants.HOTEL_GET_AVABILITY_FAILURE:
      return {
        ...state,
        loading: false,
        avabilityError: action.error,
        packageData: null,
        sessionExpireError: action?.error?.response?.data?.message || null,
        confirmPaymentError: null,
      };

    // ---------------------------------------------------------

    case hotelConstants.HOTEL_DETAILS_REQUEST:
      return { ...state, hotelDetails: null, hotelDetailsError: null };
    case hotelConstants.HOTEL_DETAILS_SUCCESS:
      return {
        ...state,
        hotelDetails: action.response.data.data,
        hotelDetailsError: null,
      };
    case hotelConstants.HOTEL_DETAILS_FAILURE:
      return { ...state, hotelDetails: null, hotelDetailsError: action.error };

    // new node api
    case hotelConstants.NEW_HOTEL_DETAILS_REQUEST:
      return { ...state, hotelDetails: null, hotelDetailsError: null };
    case hotelConstants.NEW_HOTEL_DETAILS_SUCCESS:
      return {
        ...state,
        hotelDetails: action.response.data.data,
        hotelDetailsError: null,
      };
    case hotelConstants.NEW_HOTEL_DETAILS_FAILURE:
      return { ...state, hotelDetails: null, hotelDetailsError: action.error };

    //new node package api call
    case hotelConstants.NEW_HOTEL_PACKAGE_REQUEST:
      return { ...state, hotelPackageDetails: null, hotelDetailsError: null };
    case hotelConstants.NEW_HOTEL_PACKAGE_SUCCESS:
      return {
        ...state,
        hotelPackageDetails: action.response.data.data,
        hotelDetailsError: null,
      };
    case hotelConstants.NEW_HOTEL_PACKAGE_FAILURE:
      return {
        ...state,
        hotelPackageDetails: null,
        hotelDetailsError: action.error,
      };

    case hotelConstants.HOTEL_ROOMS_REQUEST:
      return {
        ...state,
        hotelRoom: null,
        hotelRoomError: null,
        error: null,
        stripeIntentSuccess: false,
        avabilityError: null,
        sessionExpireError: null,
        confirmPaymentError: null,
        bookingDataError: null,
      };
    case hotelConstants.HOTEL_ROOMS_SUCCESS:
      return {
        ...state,
        hotelRoom: action.response.data.data,
        hotelRoomError: null,
      };

    case hotelConstants.HOTEL_ROOMS_FAILURE:
      return { ...state, hotelRoom: null, hotelRoomError: action.error };

    // ---------------------------------------------------------

    case hotelConstants.GET_USER_BOOKING_DETAILS:
      return {
        ...state,
        userBookingDetails: null,
        loading: true,
        error: null,
      };
    case hotelConstants.GET_USER_BOOKING_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        bookHotelData: null,
        userBookingDetails: { ...action.response },
      };
    case hotelConstants.GET_USER_BOOKING_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        bookHotelData: null,
        error: action.error,
      };
    // ---------------------------------------------------------

    case hotelConstants.DOWNLOAD_INVOICE_SUCCESS:
      return {
        ...state,
        pdfUrl: action.response.data.url,
      };

    case hotelConstants.DOWNLOAD_INVOICE_FAILURE:
      return {
        ...state,
        keepLoading: false,
        packageData: null,
        sessionExpireError: null,
      };
    // ---------------------------------------------------------

    case hotelConstants.EMAIL_BOOKING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case hotelConstants.EMAIL_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        emailSent: true,
      };
    case hotelConstants.EMAIL_BOOKING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.response.data,
      };
    // ---------------------------------------------------------

    case hotelConstants.CANCEL_BOOKING:
      return {
        ...state,
        loading: true,
        errorCancelBooking: null,
      };
    case hotelConstants.CANCEL_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        cancelledBooking: action.response,
      };
    case hotelConstants.CANCEL_BOOKING_FAILURE:
      return {
        ...state,
        loading: false,
        errorCancelBooking: action.error.response.data,
      };
    // ---------------------------------------------------------

    case hotelConstants.HOTEL_RECHECK_PRICE_SUCCESS:
      return {
        ...state,
        loading: false,
        recheckHotelData: { ...action.response.data },
        avabilityError: null,
        sessionExpireError: null,
        confirmPaymentError: null,
      };
    case hotelConstants.HOTEL_RECHECK_PRICE_FAILURE:
      return {
        ...state,
        loading: false,
        avabilityError: action.error,
        recheckHotelData: null,
        sessionExpireError: action?.error?.response?.data?.message || null,
        confirmPaymentError: null,
      };

    // ---------------------------------------------------------

    // stripe payment
    case hotelConstants.GET_STRIPE_INTENT_REQUEST: {
      return {
        ...state,
        stripeIntentData: null,
        bookingDataError: null,
        stripeIntentError: null,
      };
    }
    case hotelConstants.GET_STRIPE_INTENT_SUCCESS: {
      return {
        ...state,
        stripeIntentData: { ...action?.response?.data },
        stripeIntentSuccess: true,
        error: null,
      };
    }
    case hotelConstants.GET_STRIPE_INTENT_FAILURE: {
      return {
        ...state,
        stripeIntentData: null,
        stripeIntentError: action?.error?.response?.data?.message || null,
      };
    }

    // ---------------------------------------------------------

    // hotel payment intent
    case hotelConstants.HOTEL_PAYMENT_INTENT_REQUEST: {
      return {
        ...state,
        hotelIntentData: null,
        bookingDataError: null,
        hotelIntentError: null,
      };
    }
    case hotelConstants.HOTEL_PAYMENT_INTENT_SUCCESS: {
      return {
        ...state,
        hotelIntentData: { ...action?.response?.data },
        hotelIntentSuccess: true,
        error: null,
      };
    }
    case hotelConstants.HOTEL_PAYMENT_INTENT_FAILURE: {
      return {
        ...state,
        hotelIntentData: null,
        hotelIntentError: action?.error?.response?.data?.message || null,
      };
    }

    // ---------------------------------------------------------

    case hotelConstants.BOOK_HOTEL_REQUEST:
      return {
        ...state,
        bookHotelData: null,
      };
    case hotelConstants.BOOK_HOTEL_SUCCESS:
      return {
        ...state,
        bookHotelData: action?.response,
        stripeIntentSuccess: false,
        avabilityError: null,
        sessionExpireError: null,
        confirmPaymentError: null,
        bookingDataError: null,
      };
    case hotelConstants.BOOK_HOTEL_FAILURE:
      return {
        ...state,
        bookHotelData: null,
        bookingDataError: action?.error?.response?.data?.message,
      };
    // ---------------------------------------------------------

    // booking
    case hotelConstants.GET_USER_BOOKINGS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case hotelConstants.GET_USER_BOOKINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        userBookings: action.response.data,
        error: null,
      };
    case hotelConstants.GET_USER_BOOKINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    // ---------------------------------------------------------

    case hotelConstants.GET_CANCELLED_BOOKINGS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case hotelConstants.GET_CANCELLED_BOOKINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        cancelledBookings: action.response.data,
        error: null,
      };
    case hotelConstants.GET_CANCELLED_BOOKINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.response.data,
      };

    // ---------------------------------------------------------

    case hotelConstants.GET_COMPLETED_BOOKINGS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case hotelConstants.GET_COMPLETED_BOOKINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        completedBookings: action.response.data,
        error: null,
      };
    case hotelConstants.GET_COMPLETED_BOOKINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    // ---------------------------------------------------------

    case hotelConstants.GET_FAILED_BOOKINGS:
      return {
        ...state,
        laoding: true,
        error: null,
      };
    case hotelConstants.GET_FAILED_BOOKINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        failedBookings: action.response.data,
        error: null,
      };
    case hotelConstants.GET_FAILED_BOOKINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    // ------------------------MY TRIP BOOKINGS------------------
    case hotelConstants.GET_MY_TRIP_BOOKINGS:
      return {
        ...state,
        laoding: true,
        error: null,
      };
    case hotelConstants.GET_MY_TRIP_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        myTripBookings: action?.response?.data?.data,
        error: null,
      };
    case hotelConstants.GET_MY_TRIP_BOOKING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    // ---------------------------------------------------------

    case hotelConstants.PREBOOK_HOTEL_BOOKING_REQUEST:
      return {
        ...state,
        loading: true,
        hotelPreBookingData: null,
        hotelBookingError: null,
        isPrebook: null,
      };
    case hotelConstants.PREBOOK_HOTEL_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        hotelPreBookingData: action.response.data,
      };
    case hotelConstants.PREBOOK_HOTEL_BOOKING_FAILURE:
      return {
        ...state,
        loading: false,
        hotelBookingError: action.error?.response?.data,
        hotelPreBookingData: null,
      };

    // ---------------------------------------------------------

    // Get Available Balance
    case hotelConstants.GET_AVAILABLE_BALANCE:
      return {
        ...state,
        availableBalance: action.response.data,
      };
    // Get CANCEL_BOOKING_DETAIL_SUCCESS
    // ---------------------------------------------------------
    case hotelConstants.CANCEL_BOOKING_DETAIL_REQUEST:
      return { ...state, cancelBookingDetails: null };
    case hotelConstants.CANCEL_BOOKING_DETAIL_SUCCESS:
      return {
        ...state,
        cancelBookingDetails: action.response.data,
      };
    case hotelConstants.CANCEL_BOOKING_DETAIL_FAILURE:
      return { ...state, cancelBookingDetails: null };

    // ---------------------------------------------------------
    default:
      return state;
  }
};

export default hotelReducer;
