import React, { useEffect, useRef, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import Button from "../../../Component/FormComponent/Button";
import { useSelector } from "react-redux";
import { Input } from "antd";
// import { CrossIco

function HotelName({ isMobileView, handleSearchHotel, filters, setFilters }) {

  const [openFilter, setOpenFilter] = useState(true);
  const typeaheadRef = useRef(null);

  const { newHotelSearchData } = useSelector((state) => state.hotelReducer);

  // eslint-disable-next-line no-unsafe-optional-chaining
  const { options } = newHotelSearchData?.filters?.length > 0 ? newHotelSearchData?.filters[0] : {}

  const onChangeValue = filters?.hotelName?.onChangeValue;

  const handleSearch = (e) => {
    if (e?.length > 0) {
      const value = e[0]?.value;
      setFilters({ ...filters, hotelName: { ...filters.hotelName, onChangeValue: value, value: null } });
    } else {
      const updatedFilters = { ...filters, hotelName: { ...filters.hotelName, onChangeValue: null, value: null } }
      setFilters(updatedFilters);
      handleSearchHotel(updatedFilters) // if you want to call api on clear button insted of apply btn
    }
  }

  const handleApply = () => {
    const updatedFilters = { ...filters, hotelName: { ...filters.hotelName, onChangeValue: onChangeValue, value: onChangeValue } }
    setFilters(updatedFilters);
    handleSearchHotel(updatedFilters)
  }

  useEffect(() => {
    if (!onChangeValue && typeaheadRef.current) {
      typeaheadRef.current.clear(); // Clear selected option
    } else if (typeaheadRef?.current) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      typeaheadRef.current.inputNode.value = (options?.filter((item) => item.value === onChangeValue))[0].label
    }
  }, [onChangeValue, isMobileView]);

  return isMobileView ? (
    <>
      <div className="showDropdown">
        <AsyncTypeahead
          clearButton
          editable={false}
          options={options}
          isLoading={false}
          minLength={3}
          placeholder={"Hotel Name Search"}
          onChange={(e) => handleSearch(e)}
          searchText="Searching..."
          onSearch={() => { }}
          name="hotel_search"
          labelKey={(option) => option.label}
          valueKey={(option) => option.value}
          ref={typeaheadRef}
          id="hotel_search"
          renderMenuItemChildren={(option) => (
            <div className="hotel-list">
              <span className="locationCode">{option.label}</span>
            </div>
          )}
        />
      </div>
    </>
  ) : (
    <>
      <div
        className="cardHead dflex"
        onClick={() => setOpenFilter((prev) => !prev)}
      >
        <h6>Hotel Name</h6>
        <span className={`fa fa-angle-${openFilter ? "up" : "down"}`}></span>
      </div>
      <div className={`cardBody ${!openFilter ? "d-none" : ""}`}>
        <div className="showDropdown">
          <AsyncTypeahead
            clearButton
            options={options}
            isLoading={false}
            editable={false}
            inputProps={{ className: "inputDropdown" }}
            onChange={(e) => handleSearch(e)}
            onSearch={() => { }}
            labelKey={(option) => option.label}
            valueKey={(option) => option.value}
            minLength={3}
            placeholder={"Hotel Name Search"}
            ref={typeaheadRef}
            searchText="Searching..."
            name="hotel_search"
            id="hotel_search"
            renderMenuItemChildren={(option) => (
              <div className="hotel-list">
                <span className="locationCode">{option.label}</span>
              </div>
            )}
          />
        </div>
        <Button
          className={`btnBorder ${!onChangeValue ? "disabled" : ""}`}
          disabled={onChangeValue ? false : true}
          type="button"
          onClick={handleApply}
        >
          Apply
        </Button>
      </div>
    </>
  );
}

export default HotelName;
