import { commonConstants } from "../Constants/commonConstants";

const currencyLanguageReducer = (state = {}, action) => {
  switch (action.type) {
    case commonConstants.SET_CURRENCY_AND_LANGUAGE:
      return { ...state, data: action.payload };
    default:
      return state;
  }
};

export default currencyLanguageReducer;
