import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LogoWhite from "./FormComponent/LogoWhite";
import AffiliateLogo from "../Assets/img/affiliateLogo.png";
import logo from "../Assets/img/logo2.png";
import Icon from "./FormComponent/Icon";
import Icomoon from "../Component/FormComponent/Icomoon";
import { Field, Form, Formik } from "formik";
import Button from "./FormComponent/Button";
import { CURRENCY, LANGUAGES } from "../Data/static.service";
import SelectComponent from "./Select/SelectComponent";
import { useSelector } from "react-redux";
import { addCurrencyAndLanguage } from "../Stores/Actions/languageCurrency";
import { useDispatch } from "react-redux";
import IconButton from "./FormComponent/IconButton";
import Select from "react-select";
import { authActions, commonActions } from "../Stores/Actions";
import useOutsideHandler from "../Stores/Utils/useOutsideHandler";
import SupportIcon from "../Assets/img/support/customer-service-icon-blue.png";
import SupportWhiteIcon from "../Assets/img/support/customer-service-icon-white.png";
import { clearPersistedStore } from "../Stores/store.js";
function HeaderNavbar({
  logowhite,
  hideFunc,
  toggleFilterHandler,
  noHeader,
  useBlueIcon,
}) {
  const navigate = useNavigate();
  // Toggle button dropdown
  const [isActive, setIsActive] = useState(false);
  const [isNetwork, setIsNetwork] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownActive, setDropdownActive] = useState(false);
  const [open, setOpen] = useState(false);
  const [showCurrencyForm, setShowCurrencyForm] = useState(false);
  const currencyFormRef = useRef(null);
  const profileRef = useRef(null);
  const [isTouch, isTouchedDevice] = useState(false);
  const isTouchEnabled = (e) => {
    if (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    ) {
      isTouchedDevice(true);
    } else {
      isTouchedDevice(false);
    }
  };

  useOutsideHandler(currencyFormRef, showCurrencyForm, () => {
    setShowCurrencyForm((pre) => !pre);
  });

  useOutsideHandler(profileRef, setDropdownActive, () => {
    setDropdownActive(false);
  });

  const currencyAndLanguage = useSelector(
    (state) => state.currencyLanguageReducer
  );

  // Logout functionality
  const logoutData = useSelector((state) => state.authReducer.logout);

  // user data
  const user = JSON.parse(window.localStorage.getItem("user"));
  const dahboardRoutes = [
    "/member-invites",
    "/affiliate-dashboard",
    "/affiliate-invites",
    "/marketing",
    "/wallet",
  ];
  useEffect(() => {
    if (logoutData) {
      navigate("/login");
    }
  }, [logoutData]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (dahboardRoutes.indexOf(window.location.pathname) > -1) {
      setIsNetwork(true);
    }
    if (!currencyAndLanguage.data) {
      dispatch(
        addCurrencyAndLanguage({
          currency: CURRENCY[0],
          language: LANGUAGES[0],
        })
      );
    }
    isTouchEnabled();
  }, []);

  const handleToggle = () => {
    setIsActive(!isActive);
  };
  const CurrencyForm = () => {
    const initialValues = {
      currency: currencyAndLanguage.data?.currency
        ? currencyAndLanguage.data.currency.isoCode
        : "USD",
      language: currencyAndLanguage.data?.language
        ? currencyAndLanguage.data.language.langCode
        : "en-US",
    };
    const handleSubmit = (values) => {
      let currency = CURRENCY.find((curr) => curr.isoCode === values.currency);
      let language = LANGUAGES.find(
        (lang) => lang.langCode === values.language
      );
      dispatch(
        addCurrencyAndLanguage({ currency: currency, language: language })
      );
      setIsDropdownOpen(false);
      window.location.reload();
    };

    const FormikSelect = ({ options, form, field }) => {
      return (
        <>
          <SelectComponent
            name={field.name}
            className="currency-font"
            onBlur={field.onBlur}
            onChange={({ value }) => form.setFieldValue(field.name, value)}
            options={options}
            defaultValue={
              field.name === "currency"
                ? currencyAndLanguage.data?.currency
                  ? options.find(
                      (option) =>
                        option.value ===
                        currencyAndLanguage.data.currency.isoCode
                    )
                  : options[0]
                : currencyAndLanguage.data?.language
                ? options.find(
                    (option) =>
                      option.value ===
                      currencyAndLanguage.data.language.langCode
                  )
                : options[0]
            }
          />
        </>
      );
    };

    const createOptions = (arrayData) => {
      let result = [];
      arrayData.forEach((data) => {
        result.push({
          value: data.langCode
            ? data.langCode
            : data.isoCode
            ? data.isoCode
            : null,
          label: data.langCode
            ? `${data.country} ${data.langCode}`
            : data.isoCode
            ? `${data.name} ${data.isoCode} (${data.symbol})`
            : null,
        });
      });
      return result;
    };
    return (
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form className="currency-form">
          {/* <div className="currency-form-group">
            <label className="currency-form-label">Select Language</label>
            <Field
              name="language"
              component={FormikSelect}
              options={createOptions(LANGUAGES)}
            />
          </div> */}
          <div className="currency-form-group">
            <label className="currency-form-label">Select Currency</label>
            <Field
              name="currency"
              component={FormikSelect}
              options={createOptions(CURRENCY)}
            />
          </div>
          <IconButton
            className="currency-form-btn"
            text="Change"
            type="submit"
          />
        </Form>
      </Formik>
    );
  };

  const headerNavbarList = [
    {
      title: "New Search",
      path: "/landing",
    },
    {
      title: "Dashboard",
      // path: `${process.env.REACT_APP_AFFILIATE_PORTAL_URL}/?path=affiliate-dashboard&token=${window.localStorage.getItem("accessToken")}`,
      path: "/affiliate-dashboard",
      target: "",
    },
    {
      title: "Bookings",
      path: "/booking-details",
    },
    {
      title: "Rewards",
      path: "/member-rewards",
    },
    {
      title: "Upgrade",
      path: "/membership-upgrade",
    },
    {
      title: "FAQ's",
      path: "/hotel-faq",
    },
    {
      path: "/membersupport",
      img: true,
      className: "icon-support",
    },
    {
      title: "English (US) – USD",
      path: "/home",
      icon: true,
      className: "icon-globe",
    },
    {
      title: "Hi, Sven",
      path: "/",
      icon: true,
      className: "icon-account-circle",
    },
  ];

  const logout = async () => {
    dispatch(commonActions.loaderText("Logging out..."));
    dispatch(authActions.logoutRequest());
    window.localStorage.clear();
    // clearPersistedStore();
    // navigate("/login");
  };

  return (
    <header className={!isActive ? "header" : "header openMainDropdown"}>
      <div className="container dflex">
        <Link to={!noHeader ? "/landing" : "/"} className="logoWrap">
          {isNetwork ? (
            <div className="network-logo-sticky">
              <img src={AffiliateLogo} />
              <span>Network</span>
            </div>
          ) : (
            <LogoWhite src={logowhite || logo} />
          )}
        </Link>
        {!noHeader ? (
          <>
            <nav className="navigation nav-popup">
              <button
                className="cancel nav-close"
                onClick={() => {
                  setOpen(!handleToggle());
                  if (hideFunc) {
                    hideFunc();
                  }
                  if (toggleFilterHandler) {
                    toggleFilterHandler(true);
                  }
                }}
              >
                <i className="icon-close" />
              </button>
              <ul>
                <li className="mobile-profile">
                  <div className="mobile-profile-info">
                    <div className="profile-info">
                      <div className="user">
                        <div className="user-img">
                          <div
                            className="img"
                            style={
                              user?.profile_image_url
                                ? {
                                    backgroundImage: `url(${user?.profile_image_url})`,
                                  }
                                : {}
                            }
                          ></div>
                        </div>
                      </div>
                      <div className="user-info">
                        <h4>{user?.first_name}</h4>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="mobile-view-profile">
                  <Link to="/profile" className="view-profile">
                    My Profile
                  </Link>
                </li>
                {headerNavbarList.map((item, index) => {
                  if (item.img === true) {
                    return (
                      <li key={index} className="support-page-url">
                        <Link to={item.path}>
                          <img
                            src={useBlueIcon ? SupportIcon : SupportWhiteIcon}
                            alt="support icon"
                          />
                          <span className="support-mobile-url">Support</span>
                        </Link>
                      </li>
                    );
                  } else if (item.icon === true) {
                    if (item.className === "icon-globe") {
                      return (
                        <li
                          className="dropdownMenu select-lang-hide"
                          key={index}
                          ref={currencyFormRef}
                        >
                          <Button
                            onClick={() => setShowCurrencyForm((pre) => !pre)}
                          >
                            <Icomoon className="icon-globe" />{" "}
                            {currencyAndLanguage.data?.language
                              ? `${currencyAndLanguage.data.language.country} (${currencyAndLanguage.data.language.langCode})`
                              : "English (US)"}{" "}
                            –{" "}
                            {currencyAndLanguage.data?.currency
                              ? currencyAndLanguage.data.currency.isoCode
                              : "USD"}
                            <Icon iconName="fa fa-angle-down" />
                          </Button>
                          {showCurrencyForm && <CurrencyForm />}
                        </li>
                      );
                    }
                    return (
                      <li
                        className={`dropdownMenu profile ${
                          dropdownActive ? "active" : ""
                        }`}
                        key={index}
                        ref={profileRef}
                      >
                        <Button
                          type="button"
                          onClick={() =>
                            isTouch ? setDropdownActive(true) : {}
                          }
                        >
                          <Icomoon className="icon-account-circle" />
                          Hi, {user?.first_name}
                          <Icon iconName="fa fa-angle-down" />
                        </Button>

                        <div className={`profile-dropdown`}>
                          <div className="dropdown-content">
                            <div className="profile-info">
                              <div className="user">
                                <div className="user-img">
                                  <div
                                    className="img"
                                    style={
                                      user?.profile_image_url
                                        ? {
                                            backgroundImage: `url(${user?.profile_image_url})`,
                                          }
                                        : {}
                                    }
                                  ></div>
                                </div>
                              </div>
                              <div className="user-info">
                                <h4>{user?.first_name}</h4>

                                <Link to="/profile" className="view-profile">
                                  View Profile
                                </Link>
                              </div>
                            </div>
                            <div className="my-booking">
                              <Link to="/booking-details">My Booking</Link>
                            </div>

                            <div className="logout-btn">
                              <Button
                                type="button"
                                className="log-out"
                                onClick={() => logout()}
                              >
                                Log Out
                              </Button>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  } else if (
                    (!(
                      item.title === "Upgrade" &&
                      (user?.type_id ==
                        process.env.REACT_APP_PREMIUM_PLUS_USER_TYPE_ID ||
                        user?.type_id ==
                          process.env
                            .REACT_APP_AFFILIATE_PREMIUM_USER_TYPE_ID ||
                        user?.type_id ===
                          process.env.REACT_APP_PREMIUM_USER_TYPE_ID ||
                        user?.type_id ===
                          process.env.REACT_APP_PREMIUM_TYPE_ID_3_MONTH_PROMO ||
                        user?.type_id ===
                          process.env.REACT_APP_PREMIUM_TYPE_ID_6_MONTH_PROMO ||
                        user?.type_id ===
                          process.env
                            .REACT_APP_PREMIUM_PLUS_USER_TYPE_ID_LIFETIME ||
                        user?.type_id ===
                          process.env
                            .REACT_APP_AFFILIATE_PREMIUM_USER_TYPE_ID_LIFETIME)
                    ) &&
                      !(
                        item.title === "Rewards" &&
                        user?.type_id !==
                          process.env.REACT_APP_PREMIUM_PLUS_USER_TYPE_ID &&
                        user?.type_id !==
                          process.env
                            .REACT_APP_PREMIUM_PLUS_USER_TYPE_ID_LIFETIME
                      ) &&
                      !(
                        item.title === "Resource Center" &&
                        user?.type_id ===
                          process.env.REACT_APP_FREMIUM_USER_TYPE_ID &&
                        user?.type_id ===
                          process.env.REACT_APP_PREMIUM_USER_TYPE_ID
                      ) &&
                      !(
                        item.title == "Dashboard" &&
                        user?.type_id !==
                          process.env
                            .REACT_APP_AFFILIATE_PREMIUM_USER_TYPE_ID &&
                        user?.type_id !==
                          process.env
                            .REACT_APP_AFFILIATE_PREMIUM_USER_TYPE_ID_LIFETIME
                      ) &&
                      !(item.path === "/landing")) ||
                    (item.path === "/landing" &&
                      window.location.pathname !== "/landing")
                  ) {
                    return (
                      <li key={index}>
                        {item.target ? (
                          <a href={item.path} target={item?.target}>
                            {item.title}
                          </a>
                        ) : (
                          <Link to={item.path}>{item.title}</Link>
                        )}
                      </li>
                    );
                  }
                })}
                <li className="logout-btn btn-logout-2">
                  <Button type="button" onClick={() => logout()}>
                    Log Out
                  </Button>
                </li>
                <li className="currency-form-wrap">
                  <CurrencyForm />
                </li>
              </ul>
            </nav>
            <button
              onClick={() => {
                handleToggle();
                if (hideFunc) {
                  hideFunc();
                }
                if (toggleFilterHandler) {
                  toggleFilterHandler(true);
                }
              }}
              to="home"
              className="menuBtn"
            >
              <span className="navbar-toggler-icon">
                <span />
              </span>
            </button>
          </>
        ) : null}
      </div>
    </header>
  );
}

export default HeaderNavbar;
