import React from 'react';

function HotelDetailsTabs({ scrollToView, activeTab, hasReviews,hasAminites }) {
  return (
    <ul className="list-unstyled d-flex m-0">
      <li className={activeTab == 'overview' ? 'active' : ''}>
        <a onClick={() => scrollToView('overview')} className="scroll-section">
          Overview
        </a>
      </li>
      <li className={activeTab == 'rooms' ? 'active' : ''}>
        <a
          onClick={() => {
            scrollToView('rooms');
          }}
          className="scroll-section"
        >
          Rooms
        </a>
      </li>
     { hasAminites && <li className={activeTab == 'amenities' ? 'active' : ''}>
        <a onClick={() => scrollToView('amenities')} className="scroll-section">
          Amenities
        </a>
      </li>}
      <li className={activeTab == 'property-details' ? 'active' : ''}>
        <a onClick={() => scrollToView('property-details')} className="scroll-section">
          Property Details
        </a>
      </li>
      {hasReviews &&
        <li className={activeTab == 'reviews' ? 'active' : ''}>
          <a onClick={() => scrollToView('reviews')} className="scroll-section">
            Reviews
          </a>
        </li>
      }
    </ul>
  );
}

export default HotelDetailsTabs;
