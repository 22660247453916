import React from "react";
import RoomCard from "./RoomCard";
function RoomContainer(props) {
  return (
    <div className="new-card-box" key={`${props.index}_roomContainer`}>
      <div className="row no-gutters">
        <RoomCard {...props} />
      </div> 
    </div>
  );
}

export default RoomContainer;
