import moment from "moment";
import React from "react";
import { convertTime24To12 } from "../../Util/util";

// date and time details

function DateNTime({ date, time, label, iconName }) {
  const currentGeoLocation =
    window.localStorage.getItem("currentGeoLocation") &&
    JSON.parse(window.localStorage.getItem("currentGeoLocation"));

  const isUS = currentGeoLocation === "US";

  const dateDisplayFormat = isUS ? "MM/DD/YYYY" : "DD/MM/YYYY";

  return (
    <div className="col">
      <label htmlFor="">
        {iconName && <i className={iconName}></i>} {label}
      </label>
      <span className="dateInWrap">
        {moment(date).format(dateDisplayFormat)}
      </span>{" "}
      <span>{isUS ? convertTime24To12(time) : time}</span>
    </div>
  );
}

export default DateNTime;
