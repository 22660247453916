import React, { useState } from "react";
import bgImg from "../../Assets/img/checkout-img.jpg";
import locationGray from "../../Assets/img/location-gray.png";
import datePicker from "../../Assets/img/date-picker.png";
import rightArrowImg from "../../Assets/img/right-arrow.png";
import { isEmpty, has } from "lodash";
import timerImg from "../../Assets/img/timer.png";
import infoGray from "../../Assets/img/info-gray.png";
import warningImg from "../../Assets/img/warning.svg";
import moment from "moment";

function HotelDetailCard({
  hotels,
  rates,
  input,
  usedWalletAmmout,
  currencyCode,
  currencySymbol,
  isRefundable,
}) {
  const [hide, setHide] = useState(false);
  //   const { cancellation_policy } = rates;
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode,
  });

  const currentGeoLocation =
    window.localStorage.getItem("currentGeoLocation") &&
    JSON.parse(window.localStorage.getItem("currentGeoLocation"));

  const isFreemium =
    window.localStorage.getItem("user") &&
    JSON.parse(window.localStorage.getItem("user"));

  const dateDisplayFormat =
    currentGeoLocation === "US" ? "MM/DD/YYYY" : "DD/MM/YYYY";

  let numberOfAdults = !isEmpty(input)
    ? input.rooms.map((ele, i) => ele.adults).reduce((a, b) => a + b)
    : [];

  let numberOfChild = !isEmpty(input)
    ? input.rooms.map((ele, i) => ele.child).reduce((a, b) => a + b)
    : [];

  return (
    <div className="col rightContent">
      <div className="infoBox">
        <div className="customShadow">
          <div
            className="imgWrap bgImg"
            style={{
              backgroundImage: `url('${hotels?.thumbnail_image}')`,
            }}
          ></div>
        </div>
        <div className="Infocontent">
          <div className="contentGroup">
            <h2 className="cartTitle">{hotels?.hotel_name}</h2>
            <div className="contactInfo">
              <div className="location">
                <img src={locationGray} alt="icon" />
                {hotels?.address}
              </div>
              <div className="phone">
                <span className="icon-phone"></span>
                <span>{hotels?.phone?.replaceAll("|", " | ")}</span>
              </div>
            </div>
          </div>
          <div className="contentGroup timeWrapper">
            <div className="d-flex">
              <img src={datePicker} alt="icon" />
              {hotels?.check_in_date && (
                <span>
                  {moment(hotels?.check_in_date, "YYYY-MM-DD").format(
                    dateDisplayFormat
                  )}
                </span>
              )}

              <img src={rightArrowImg} alt="icon" />
              {hotels?.check_out_date && (
                <span>
                  {moment(hotels?.check_out_date, "YYYY-MM-DD").format(
                    dateDisplayFormat
                  )}
                </span>
              )}
            </div>
            <div className="d-flex checkin-checkout-detail">
              <div className=" tooltip">
                <img src={timerImg} className="clock-icon  " alt="icon" />
                <span className="tooltiptext">Check-in / Check-out Time</span>
              </div>

              <span>{hotels?.check_in_time}</span>
              <img src={rightArrowImg} alt="icon" />
              <span>{hotels?.check_out_time}</span>
            </div>
          </div>
          <div className="contentGroup roomInfo">
            <h3>
              <span> {rates?.bed_type} </span>
              <button
                className={
                  isRefundable ? "btn-refundable" : "btn-non-refundable"
                }
              >
                {isRefundable ? "Refundable" : "Non Refundable"}
              </button>
              <div className="d-flex justify-content-between">
                <h5>Number of Nights:</h5>
                <h5>
                  <strong>
                    {!isEmpty(hotels) && hotels?.number_of_nights} Nights
                  </strong>
                </h5>
              </div>
              <div className="d-flex justify-content-between">
                <h5>Number of Guests:</h5>
                <h5>
                  <strong>
                    {/* {numberOfAdults + numberOfChild} */}
                    {hotels?.number_of_guests} Guests
                  </strong>
                </h5>
              </div>
            </h3>
          </div>
          <div className="amountCalculation">
            <div className="amountInfo">
              <p>
                {hotels?.number_of_nights} night
                {hotels?.number_of_nights > 1 ? "s" : ""} incl. taxes & fees
              </p>
              <div className="d-flex justify-content-between publicPrice">
                <h5>Public Price:</h5>
                <h4>
                  {
                    !isEmpty(rates) &&
                      `${currencySymbol}${rates?.boards?.price_detail?.price?.retail_price}`
                    // formatter.format(rates?.boards?.price_detail?.price?.retail_price)
                  }
                </h4>
              </div>
              {isFreemium?.type === "Free User" && (
                <div className="d-flex justify-content-between savings">
                  {!isEmpty(rates) &&
                    rates?.boards?.price_detail?.price?.savings_percentage !==
                      0 && (
                      <>
                        <h5>Savings:</h5>
                        <h4>
                          {!isEmpty(rates) &&
                            formatter.format(
                              rates?.boards?.price_detail?.price?.voucher_credit
                            )}
                        </h4>
                      </>
                    )}
                </div>
              )}
              {isFreemium?.type !== "Free User" && (
                <div className="d-flex justify-content-between savings">
                  {!isEmpty(rates) &&
                    rates?.boards?.price_detail?.price?.savings_percentage !==
                      0 && (
                      <>
                        <h5>Savings:</h5>
                        <h4>
                          {
                            !isEmpty(rates) &&
                              `${currencySymbol}${rates?.boards?.price_detail?.price?.savings}`
                            // formatter.format(parseFloat(rates?.boards?.price_detail?.price?.savings))
                          }
                        </h4>
                      </>
                    )}
                </div>
              )}
            </div>
            <div className="payWrapper">
              <div className="d-flex justify-content-between savings">
                <div className="priceDetailsWrapper">
                  <h5>
                    You Pay:
                    <div className="tooltip-wrap">
                      <img src={infoGray} alt="icon" className="infoIcon" />
                      <div className="pricedetail-dropdown">
                        <div className="dropdown-box">
                          <div
                            className="dropdown-content"
                            style={{
                              borderBottom:
                                rates?.boards?.price_detail?.price
                                  ?.savings_percentage > 0
                                  ? ""
                                  : "none",
                            }}
                          >
                            {/* {true && (
                              <div className="note">
                                <p>Free cancellation</p>
                              </div>
                            )} */}
                            <div className="dropdown-info">
                              <h3>Price Details</h3>
                              {!isEmpty(rates) && (
                                <h4>
                                  {rates?.number_of_rooms} Room
                                  {rates?.number_of_rooms > 1 ? "s" : ""} x{" "}
                                  {rates?.number_of_nights} Night
                                  {rates?.number_of_nights > 1 ? "s" : ""}
                                  <span>
                                    {`${currencySymbol}${rates?.boards?.price_detail?.price?.base_rate}`}
                                    {/* {formatter.format(rates?.boards?.price_detail?.price?.base_rate)} */}
                                  </span>
                                </h4>
                              )}
                              {rates?.boards?.price_detail?.price?.tax !==
                                "0.00" && (
                                <h4>
                                  Taxes and Fees
                                  {!isEmpty(rates) && (
                                    <span>
                                      {`${currencySymbol}${rates?.boards?.price_detail?.price?.tax}`}
                                      {/* {formatter.format(rates?.boards?.price_detail?.price?.tax)} */}
                                    </span>
                                  )}
                                </h4>
                              )}
                              <div className="total-amount">
                                <h4>
                                  <strong className="total-text">Total:</strong>
                                  {!isEmpty(rates) && (
                                    <span>
                                      {`${currencySymbol}${rates?.boards?.price_detail?.price?.total_with_tax}`}
                                      {/* {formatter.format(rates?.boards?.price_detail?.price?.total_with_tax)} */}
                                    </span>
                                  )}
                                </h4>
                              </div>
                              <div className="saving-box">
                                {!isEmpty(rates) &&
                                  rates?.boards?.price_detail?.price
                                    ?.savings_percentage > 0 &&
                                  (rates?.boards?.price_detail?.price
                                    ?.savings_percentage > 0 ? (
                                    <h4>
                                      Savings (
                                      {
                                        rates?.boards?.price_detail?.price
                                          ?.savings_percentage
                                      }
                                      %):
                                      <span>
                                        {`${currencySymbol}${rates?.boards?.price_detail?.price?.savings}`}
                                        {/* {formatter.format(
                                          rates?.boards?.price_detail?.price?.savings
                                        )} */}
                                      </span>
                                    </h4>
                                  ) : (
                                    ""
                                  ))}
                              </div>
                            </div>
                            {!isEmpty(rates) &&
                            rates?.boards?.price_detail?.price?.mandatory_fees
                              .length > 0 ? (
                              <div className="resortFeessection">
                                <h3>
                                  {" "}
                                  <span className="circle">
                                    <img
                                      src={warningImg}
                                      alt="icon"
                                      className="mCS_img_loaded"
                                    />
                                  </span>
                                  <strong>
                                    Resort Fees per Room:
                                    {` ${rates?.boards?.price_detail?.price?.mandatory_fees[0].currency} ${rates?.boards?.price_detail?.price?.mandatory_fees[0].amount}`}
                                    {/* {formatter.format(
                                      rates?.boards?.price_detail?.price?.mandatory_fees[0].amount
                                    )} */}
                                  </strong>
                                </h3>
                                <p>
                                  (<strong>NOT</strong> included in the price,
                                  due at the Hotel)
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </h5>
                </div>

                <h4>
                  {
                    !isEmpty(rates) &&
                      `${currencySymbol}${rates?.boards?.price_detail?.price?.total_with_tax}`
                    // formatter.format(rates?.boards?.price_detail?.price?.total_with_tax)
                  }
                  <span>
                    (
                    {
                      !isEmpty(rates) &&
                        `${currencySymbol}${rates?.boards?.price_detail?.price?.per_night_base_rate}`
                      // formatter.format(rates?.boards?.price_detail?.price?.per_night_base_rate)
                    }
                    /night)
                  </span>
                </h4>
              </div>

              <>
                <div className="pay-deduction">
                  {usedWalletAmmout > 0 && (
                    <div className="d-flex justify-content-between">
                      <h5>Your Wallet Deduction:</h5>
                      <h4>
                        {`${currencySymbol}${Number(usedWalletAmmout)}`}
                        {/* {formatter.format(Number(usedWalletAmmout))}  */}
                      </h4>
                    </div>
                  )}
                  <div className="d-flex justify-content-between amount-due">
                    <h5>Amount Due Today:</h5>
                    <h4>
                      {
                        `${currencySymbol}${rates?.boards?.price_detail?.price?.total_with_tax}`
                        // formatter.format(
                        //   rates?.boards?.price_detail?.price?.total_with_tax - usedWalletAmmout
                        // )
                      }
                    </h4>
                  </div>
                </div>
              </>
            </div>
          </div>
          {!isEmpty(rates) &&
          !isEmpty(rates?.boards?.price_detail?.price?.mandatory_fees) ? (
            <div className="resortFeessection">
              <h3>
                <span className="circle">
                  <img src={warningImg} alt="icon" />
                </span>
                <strong>
                  Resort Fees per Room:&nbsp;&nbsp;{" "}
                  {
                    `${rates?.boards?.price_detail?.price?.mandatory_fees[0].currency} ${rates?.boards?.price_detail?.price?.mandatory_fees[0].amount}`
                    // {formatter.format(
                    //   rates?.boards?.price_detail?.price?.mandatory_fees[0].amount
                    // )
                  }
                </strong>
              </h3>
              <p>
                (<strong>NOT</strong>&nbsp;included in the price, due at the
                Hotel)
              </p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default HotelDetailCard;
