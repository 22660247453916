import React from "react";

function ReviewCommentCard({
  text
}) {
  return (
    <div className="card2">
      <div className="heading">
        <div className="d-flex justify-content-between">
          {/* <div className="ratingComment font14">
            <span className="commentCount">{"score"}</span>
            {text[0].sentences}
          </div> */}
          {/* <h4>{date}</h4> */}
        </div>
      </div>
      <h3>{text[0].sentences || text[0].sentence}</h3>
      {
        text.map((curr,i) => {
          if(i == 0)return
          return <>
            <p>{curr?.sentences || curr?.sentence}</p>
          </>
        })
      }
      {/* <h5>
        <img src={countryIcon} alt="icon" />
        {"userName"}
      </h5> */}
    </div>
  );
}

export default ReviewCommentCard;
