import { times } from "lodash";
import { FieldArray } from "formik";
import { Fragment, useEffect, useState, useRef } from "react";

import moment from "moment";
import InputField from "./InputField";
import CustomPhoneInput from "../../Component/PhoneInput";
import Icomoon from "../../Component/FormComponent/Icomoon";
import Checkbox from "../../Component/FormComponent/Checkbox";
import OptionButton from "../../Component/FormComponent/OptionButton";
import SelectFieldOption from "../../Component/FormComponent/SelectFieldOption.jsx";
import DateInput from "../../Component/DatePicker/DateInput";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "../../Stores/Actions";
import SimpleInputField from "../../Component/FormComponent/SimpleInputField";
import { handleMaskedInput } from "../../Util/util";

function Traveler(props) {
  const {
    formikProps,
    titleOption,
    ageOptions,
    nationalityOptions,
    passengersCount,
    adults,
    formikValues,
    adultsError,
    domesticFlight,
  } = props;

  const fieldArrayPropsRef = useRef(null);
  const getGuestData = useSelector((state) => state.commonReducer.getGuestData);

  const flightsWidgetData =
    window.localStorage.getItem("flightPayload") &&
    JSON.parse(window.localStorage.getItem("flightPayload"));

  const flight =
    window.localStorage.getItem("selectedLegs") &&
    JSON.parse(window.localStorage.getItem("selectedLegs"));
  const passportMinArrivalDate = moment(
    flight[flight?.length - 1]?.flightLegs[
      flight[flight?.length - 1]?.flightLegs?.length - 1
    ]?.arrivalDateTime
  )?.format("MM/DD/YYYY");

  const { values, errors, touched, handleBlur, handleChange } = formikProps;

  const [dateInputFocused, setDateInputFocused] = useState({
    dob: new Array(passengersCount).fill(false),
    passportExpiryDate: new Array(passengersCount).fill(false),
  });

  const handleFocusChange = (index, focused, dateColl) => {
    setDateInputFocused((pre) => {
      let copy = { ...pre };
      copy[dateColl][index] = focused;
      return copy;
    });
  };

  const handleSelectChange = (value, fieldName, formikProps) => {
    formikProps.setFieldValue(fieldName, value);
  };

  const handleCheckboxChange = (value, fieldName, formikProps) => {
    const checkedLength = formikValues?.adults?.filter((item, index) => {
      if (item.check) {
        return item;
      }
    }).length;

    if (value && checkedLength < flightsWidgetData?.adults) {
      formikProps.setFieldValue(fieldName, value);
    }
    if (!value) {
      formikProps.setFieldValue(fieldName, value);
    }
  };

  const handleDateChange = (value, fieldName, formikProps) => {
    // if(value){
    formikProps.setFieldValue(fieldName, moment(value._d).format("YYYY-MM-DD"));
    // }else{
    //   formikProps.setFieldValue(fieldName,"");

    // }
  };

  const [status, setStatus] = useState(true);

  useEffect(() => {
    if (getGuestData && status) {
      setStatus(false);
      // const adult =  getGuestData.filter((adult)=>moment().diff(moment(adult.dob), "years") >= 18)
      getGuestData?.map((curr, i) => {
        fieldArrayPropsRef?.current?.push({
          title: curr?.title,
          gender: curr?.gender || "M",
          frequentFlyerNumber: "",
          firstName: curr?.fname || "",
          middleName: curr?.middlename,
          lastName: curr?.lname || "",
          nationality: curr?.country,
          dob: curr.dob,
          passportCountry: curr?.country || "",
          phoneNumber: "",
          passportNumber: curr?.passport_number,
          passportExpiryDate: curr?.passport_expiry_date
            ? moment(curr?.passport_expiry_date).format("YYYY-MM-DD")
            : "",
          // passportExpiryDate: (curr?.passport_exp_month && curr?.passport_exp_year) ? moment(`01/${curr.passport_exp_month}/${curr?.passport_exp_year}`).format("YYYY-MM-DD") : null,
          check: false,
        });
      });
    }
  }, [getGuestData]);

  const isFreemium =
    process.env.REACT_APP_FREMIUM_USER_TYPE_ID ==
    JSON.parse(localStorage?.user)?.type_id;

  return (
    <FieldArray
      name="adults"
      render={(fieldArrayProps) => {
        const { push, remove, form } = fieldArrayProps;
        const { values, errors, handleChange, setFieldError, touched } = form;

        fieldArrayPropsRef.current = fieldArrayProps;

        return (
          <>
            {/* {times(passengersCount, (index) => { */}
            <div className="card2 roomsInfo">
              <div className="sectionTitle justify-space-between">
                <h2> Adults Information</h2>
                <p>
                  {`${
                    !errors?.adults
                      ? values.adults.filter((item) => item.check)?.length
                      : flightsWidgetData?.adults -
                        errors?.adults.filter((item) => item).length
                  } / ${flightsWidgetData?.adults} added`}
                </p>
              </div>
              <div className="d-flex promocodeWrap row">
                {values?.adults?.map((curr, index) => {
                  return (
                    <Fragment key={index}>
                      <div className="d-flex">
                        <Checkbox
                          name="check"
                          value={values?.adults[index]?.check}
                          onChange={() => {
                            handleCheckboxChange(
                              !values?.adults[index]?.check,
                              `adults[${index}].check`,
                              formikProps,
                              index
                            );
                          }}
                        />

                        <div className="checkBoxContent">
                          <label
                            className="checkbox-label"
                            htmlFor="checkbox-id"
                          >
                            {index < getGuestData?.length
                              ? `${values?.adults[index]?.firstName} ${values?.adults[index]?.lastName}`
                              : `Guest ${
                                  index - (getGuestData?.length || 0) + 1
                                }`}
                          </label>

                          <p className="add-traveller-button flex-center">
                            {index >= getGuestData?.length && (
                              <>
                                <Icomoon className="icon-minus add-traveller-icon" />
                                <span onClick={() => remove(index)}>
                                  <i className="icon-close"></i>
                                </span>
                              </>
                            )}
                          </p>
                        </div>
                      </div>
                    </Fragment>
                  );
                })}
              </div>
              {!domesticFlight && (
                <>
                  <p className="errorMessage text-center">
                    IMPORTANT: This Flight requires your complete Passport
                    Information for the Booking
                  </p>
                  <br />
                </>
              )}
              {values?.adults?.map((curr, index) => {
                return (
                  <Fragment key={index}>
                    {values?.adults[index]?.check && (
                      <>
                        <div className="row new-lead-roomss-row mobile-view-room">
                          <div className="col wrapper-padding profileTitle2 ">
                            <SelectFieldOption
                              label="Title"
                              options={titleOption}
                              name={`adults[${index}].title`}
                              value={values.adults[index]?.title}
                              onChange={(_, name, value) =>
                                handleSelectChange(
                                  value,
                                  `adults[${index}].title`,
                                  formikProps
                                )
                              }
                              disabled={
                                index < getGuestData?.length && !isFreemium
                              }
                              formikProps={formikProps}
                              onBlur={handleBlur}
                              touched={
                                touched.adults &&
                                touched.adults[index] &&
                                touched.adults[index]?.title
                              }
                              error={
                                errors.adults &&
                                errors.adults[index] &&
                                errors?.adults[index]?.title
                              }
                            />
                          </div>

                          <div className="col wrapper-padding">
                            <OptionButton
                              textOptionOne="M"
                              textOptionTwo="F"
                              type="button"
                              groupClassName="genderBtnGroup"
                              className="genderBtn"
                              activeClassName="active"
                              label="Gender"
                              name={`adults[${index}].gender`}
                              value={values.adults[index]?.gender}
                              onClick={(name, value) => {
                                handleSelectChange(
                                  value,
                                  `adults[${index}].gender`,
                                  formikProps
                                );
                              }}
                              disabled={
                                index < getGuestData?.length &&
                                !isFreemium &&
                                values.adults[index]?.gender
                              }
                              touched={
                                touched.adults &&
                                touched.adults[index] &&
                                touched.adults[index]?.gender
                              }
                              error={
                                errors.adults &&
                                errors.adults[index] &&
                                errors.adults[index]?.gender
                              }
                            />
                          </div>

                          <div className="col wrapper-padding mobile-view-width">
                            <InputField
                              type={"text"}
                              label="Frequent Flyer Number"
                              placeholder="Frequent Flyer Number"
                              name={
                                `adults[${index}].frequentFlyerNumber` ??
                                undefined
                              }
                              value={values.adults[index]?.frequentFlyerNumber}
                              onChange={handleChange}
                              formikProps={formikProps}
                              touched={
                                touched.adults &&
                                touched.adults[index] &&
                                touched.adults[index]?.frequentFlyerNumber
                              }
                              error={
                                errors.adults &&
                                errors.adults[index] &&
                                errors.adults[index]?.frequentFlyerNumber
                              }
                            />
                          </div>
                        </div>

                        <div className="row new-lead-roomss-row">
                          <div className="col col-room ">
                            <InputField
                              label="First Name"
                              placeholder="First Name"
                              name={`adults[${index}].firstName`}
                              value={
                                values.adults[index]?.firstName ?? undefined
                              }
                              onChange={handleChange}
                              formikProps={formikProps}
                              onBlur={handleBlur}
                              disabled={
                                index < getGuestData?.length && !isFreemium
                              }
                              touched={
                                touched.adults &&
                                touched.adults[index] &&
                                touched.adults[index]?.firstName
                              }
                              error={
                                errors.adults &&
                                errors.adults[index] &&
                                errors.adults[index]?.firstName
                              }
                            />
                          </div>

                          <div className="col  col-room ">
                            <InputField
                              label="Middle Name"
                              placeholder="Middle Name"
                              name={`adults[${index}].middleName`}
                              value={
                                values.adults[index]?.middleName ?? undefined
                              }
                              onChange={handleChange}
                              formikProps={formikProps}
                            />{" "}
                          </div>

                          <div className="col  col-room ">
                            <InputField
                              label="Last Name"
                              placeholder="Last Name"
                              name={`adults[${index}].lastName`}
                              value={
                                values.adults[index]?.lastName ?? undefined
                              }
                              onChange={handleChange}
                              formikProps={formikProps}
                              disabled={
                                index < getGuestData?.length && !isFreemium
                              }
                              onBlur={handleBlur}
                              touched={
                                touched.adults &&
                                touched.adults[index] &&
                                touched.adults[index]?.lastName
                              }
                              error={
                                errors.adults &&
                                errors.adults[index] &&
                                errors.adults[index]?.lastName
                              }
                            />
                          </div>
                        </div>

                        <div className="row new-lead-roomss-row">
                          <div className="col wrapper-padding profileTitle2">
                            <SelectFieldOption
                              label="Nationality"
                              options={nationalityOptions}
                              name={`adults[${index}].nationality`}
                              value={values?.adults[index]?.nationality}
                              // disabled={index < getGuestData?.length && !isFreemium }
                              onChange={(_, name, value) =>
                                handleSelectChange(
                                  value,
                                  `adults[${index}].nationality`,
                                  formikProps
                                )
                              }
                              formikProps={formikProps}
                              onBlur={handleBlur}
                              touched={
                                touched.adults &&
                                touched.adults[index] &&
                                touched.adults[index]?.nationality
                              }
                              error={
                                errors.adults &&
                                errors.adults[index] &&
                                errors.adults[index]?.nationality
                              }
                            />
                          </div>

                          <div
                            className="col wrapper-padding single-date-picker-wrap"
                            style={{ flexDirection: "column" }}
                          >
                            <DateInput
                              {...formikProps}
                              placeholder="Date of Birth"
                              label="Date of Birth"
                              value={
                                values.adults[index]?.dob &&
                                moment(values.adults[index]?.dob).format(
                                  "YYYY-MM-DD"
                                )
                              }
                              icon="icon-invitation"
                              name={`adults[${index}].dob`}
                              onDateChange={(event) =>
                                handleDateChange(
                                  event,
                                  `adults[${index}].dob`,
                                  formikProps,
                                  index,
                                  setFieldError
                                )
                              }
                              focused={dateInputFocused.dob[index]}
                              onFocusChange={({ focused }) =>
                                handleFocusChange(index, focused, "dob")
                              }
                              isOutsideRange={(day) =>
                                moment().subtract(18, "years").diff(day) < 0
                              }
                              renderMonthYearSelector={true}
                              arrayFieldArray={true}
                            />
                            {touched.adults &&
                              touched.adults[index] &&
                              touched.adults[index]?.dob &&
                              errors.adults &&
                              errors.adults[index] &&
                              errors.adults[index]?.dob && (
                                <div className="inputStyle error-messages">
                                  {errors.adults[index]?.dob}
                                </div>
                              )}
                          </div>

                          <div className="col wrapper-padding profileTitle2">
                            <SelectFieldOption
                              label="Country/Territory Code"
                              options={nationalityOptions}
                              name={`adults[${index}].passportCountry`}
                              value={values.adults[index]?.passportCountry}
                              onChange={(_, name, value) =>
                                handleSelectChange(
                                  value,
                                  `adults[${index}].passportCountry`,
                                  formikProps
                                )
                              }
                              formikProps={formikProps}
                              onBlur={handleBlur}
                              touched={
                                touched.adults &&
                                touched.adults[index] &&
                                touched.adults[index]?.passportCountry
                              }
                              error={
                                errors.adults &&
                                errors.adults[index] &&
                                errors.adults[index]?.passportCountry
                              }
                            />
                          </div>
                        </div>

                        <div className="row new-lead-roomss-row">
                          <div className=" wrapper-padding ">
                            <CustomPhoneInput
                              label="Phone Number"
                              placeholder="Phone Number"
                              inputProps={{
                                name: `adults[${index}].phoneNumber`,
                              }}
                              value={values.adults[index]?.phoneNumber}
                              onChange={(phoneNumber) => {
                                formikProps.setFieldValue(
                                  `adults[${index}].phoneNumber`,
                                  phoneNumber
                                );
                              }}
                              formikProps={formikProps}
                              onBlur={handleBlur}
                              touched={
                                touched.adults &&
                                touched.adults[index] &&
                                touched.adults[index]?.phoneNumber
                              }
                              error={
                                errors.adults &&
                                errors.adults[index] &&
                                errors.adults[index]?.phoneNumber
                              }
                            />
                          </div>

                          {!domesticFlight && (
                            <div className="col wrapper-padding">
                              <InputField
                                label="Passport Number"
                                placeholder="Passport Number"
                                name={`adults[${index}].passportNumber`}
                                value={
                                  values.adults[index]?.passportNumber ??
                                  undefined
                                }
                                onChange={handleChange}
                                formikProps={formikProps}
                                onBlur={handleBlur}
                                disabled={
                                  index < getGuestData?.length &&
                                  !isFreemium &&
                                  index != 0
                                }
                                touched={
                                  touched.adults &&
                                  touched.adults[index] &&
                                  touched.adults[index]?.passportNumber
                                }
                                error={
                                  errors.adults &&
                                  errors.adults[index] &&
                                  errors.adults[index]?.passportNumber
                                }
                              />
                            </div>
                          )}

                          {!domesticFlight && (
                            <div
                              className="col wrapper-padding single-date-picker-wrap"
                              style={{ flexDirection: "column" }}
                            >
                              <DateInput
                                {...formikProps}
                                placeholder="Passport Expire Date"
                                label="Passport Expire Date"
                                // value={
                                //   values?.adults && values?.adults[index]?.passportExpiryDate &&
                                //   moment(values?.adults && values?.adults[index]?.passportExpiryDate).format(
                                //     "YYYY-MM-DD"
                                //   )
                                // }
                                value={
                                  values.adults[index]?.passportExpiryDate &&
                                  moment(
                                    values.adults[index]?.passportExpiryDate
                                  ).format("YYYY-MM-DD")
                                }
                                disabled={
                                  values.adults[index]?.passportNumber === ""
                                }
                                icon="icon-invitation"
                                name={`adults[${index}].passportExpiryDate`}
                                onDateChange={(event) =>
                                  handleDateChange(
                                    event,
                                    `adults[${index}].passportExpiryDate`,
                                    formikProps,
                                    index,
                                    setFieldError
                                  )
                                }
                                // disabled={index < getGuestData?.length && !isFreemium }

                                focused={
                                  dateInputFocused?.passportExpiryDate[index]
                                }
                                onFocusChange={({ focused }) =>
                                  handleFocusChange(
                                    index,
                                    focused,
                                    "passportExpiryDate"
                                  )
                                }
                                isOutsideRange={(day) =>
                                  day.isBefore(
                                    moment(passportMinArrivalDate).add(
                                      10,
                                      "days"
                                    )
                                  )
                                }
                                renderMonthYearSelector={true}
                                arrayFieldArray={true}
                                selectFromFuture={true}
                              />

                              {touched?.adults &&
                                touched?.adults[index] &&
                                touched?.adults[index].passportExpiryDate &&
                                errors.adults &&
                                errors.adults[index] &&
                                errors.adults[index]?.passportExpiryDate && (
                                  <div className="inputStyle error-messages">
                                    {errors?.adults &&
                                      errors?.adults[index]?.passportExpiryDate}
                                  </div>
                                )}
                            </div>
                          )}
                        </div>
                        <div />
                      </>
                    )}
                  </Fragment>
                );
              })}

              <div>
                {values?.adults?.length <
                  Number(flightsWidgetData?.adults) +
                    Number(getGuestData?.length || 0) &&
                  values?.adults?.filter((curr, i) => curr.check).length <
                    flightsWidgetData?.adults && (
                    <p
                      className="add-traveller-button add-guest"
                      onClick={() => {
                        push({
                          title: "",
                          gender: "M",
                          frequentFlyerNumber: "",
                          firstName: "",
                          middleName: "",
                          lastName: "",
                          nationality: "",
                          dob: null,
                          passportCountry: "",
                          phoneNumber: "",
                          passportNumber: "",
                          passportExpiryDate: null,
                          check: true,
                        });
                      }}
                    >
                      <Icomoon className="icon-add add-traveller-icon" />
                      <span>ADD NEW ADULT</span>
                    </p>
                  )}
                {adultsError && <p className="error-messages">{adultsError}</p>}
              </div>
            </div>
          </>
        );
      }}
    />
  );
}

export default Traveler;
