import React from 'react';
import { Link } from 'react-router-dom';
import PinImg from '../../../Assets/img/location-gray.png';
import StarImg from '../../../Assets/img/yellow-star.png';
import { utilitiesImg } from '../../../Data/hotelMockData';
import { isEmpty } from 'lodash';
import infoImg from '../../../Assets/img/info-circle.svg';
import GreyStarImg from '../../../Assets/img/star.png';
import { getCurrencyAndLanguage } from '../../../Util/util';


let amenities = [];

function HotelDetails({
  priceType,
  showMemberPrice,
  hotel
}) {


  const { currencySymbol } = getCurrencyAndLanguage();

  function starprint() {
    let star = [];
    let darkStar = 5 - hotel?.star_rating;
    for (let i = 0; i < hotel?.star_rating; i++) {
      star.push(<img src={StarImg} alt="yellow-star" key={`${i}_rating1`} />);
    }
    for (let i = 0; i < darkStar; i++) {
      star.push(
        <img
          src={GreyStarImg}
          alt="yellow-star"
          key={`${i}_rating2`}
          width="24"
        />
      );
    }
    return star;
  }

  const { price, member_price } = hotel && hotel.price_detail;
  const { savings_percentage, base_rate, mandetory_fees, total_with_tax, tax, savings, per_night_base_rate, per_night_tax, per_night_total_with_tax, per_night_savings } = showMemberPrice ? member_price : price;

  const showNightlyPrice = priceType === 'night';

  const formatedPricesObj = {
    saving_percentage: savings_percentage,
    savings_amount: showNightlyPrice ? per_night_savings : savings,
    roomsMultiNights: showNightlyPrice ? per_night_base_rate : base_rate,
    tax: showNightlyPrice ? per_night_tax : tax,
    total: showNightlyPrice ? per_night_total_with_tax : total_with_tax,
  };

  return (
    <div className="cardContentWrap h-100">
      <div className="innerCnt dflexColm">

        <div className="title-wrap">
          <Link to={`/hotel-detail/${hotel?.id || 0}`}>
            <h3 className="cartTitle">{hotel?.hotel_name}</h3>
          </Link>
          {hotel?.star_rating !== 0 && <div className="topSection dflex justifyStart">
            <div className="rating"> {starprint()}</div>
            {/* <div className="ratingComment font14">
              <span className="commentCount">{hotel?.star_rating}</span>
              Very Good
            </div> */}
          </div>}
        </div>

        <ul className="bulletList">
          {isEmpty(amenities) ? (
            // <li>Wi-Fi</li>
            <></>
          ) : (
            amenities?.map((ele, index) => {
              if (index < 4)
                return (
                  <li
                    key={`${index}_amenity_${index}`}
                  >
                    {ele}
                  </li>
                );
            })
          )}
        </ul>

        <div className="mobileViewWrapper">
          {utilitiesImg?.map((ele, index) => {
            return (
              <img src={ele} alt="icons" key={index} />
            );
          })}
          <span>+4</span>
        </div>

        <div className="btmSection font14">
          <div className="location">
            <img src={PinImg} alt="icon" />
            {hotel?.address}
          </div>
          <div className="price-wrapper">
            <div className="compare-price-wrap">
              <div className="comparePrice">

                {hotel?.price_comparison?.length > 0 &&
                  <>
                    <span className="graph"></span>
                    <span className="comparePriceText">
                      Compare Price <i className="fa fa-angle-down"></i>
                    </span>
                    <div className="comparePriceContent">
                      <div className="comparePrice-flex">

                        <div className="col">
                          {hotel?.price_comparison?.map((ele, index) => {
                            if (index < 2)
                              return (
                                <div
                                  className="dflex"
                                  key={index}
                                >
                                  <span className="key">{ele?.label}</span>&nbsp;<span className="value">{ele?.value}</span>
                                </div>
                              );
                          })}
                        </div>

                      </div>
                    </div>
                  </>
                }
              </div>

              {/* {priceType === 'total' && ( */}
              <div className="priceDetailsWrapper">
                <div className="priceDetails d-flex align-items-center">
                  <h4>
                    <img src={infoImg} alt="icon" /> Price Details
                  </h4>
                  <i className="fa fa-angle-down"></i>

                  <div className="pricedetail-dropdown">
                    <div className="dropdown-box">
                      <div
                        className="dropdown-content"
                        style={{
                          borderBottom:
                            formatedPricesObj?.savings_amount > 0 ? '' : 'none',
                        }}
                      >
                        <div className="note">
                          <p>Free cancellation</p>
                        </div>

                        <div className="dropdown-info">
                          <h3>Price Details </h3>
                          <h4>
                            {hotel?.number_of_rooms} Room{hotel?.number_of_rooms > 1 ? 's' : ''} x{' '}
                            {hotel?.number_of_nights} Night{hotel?.number_of_nights > 1 ? 's' : ''}
                            <span>
                              {currencySymbol}
                              {formatedPricesObj?.roomsMultiNights}

                            </span>
                          </h4>
                          {parseFloat(formatedPricesObj?.tax) !== 0 && <h4>
                            Taxes and Fees
                            <span>
                              {currencySymbol}
                              {formatedPricesObj?.tax}
                            </span>
                          </h4>}
                          <div className="total-amount">
                            <h4>
                              <strong className="total-text">Total:</strong>
                              <span>
                                {currencySymbol}
                                {formatedPricesObj?.total}
                              </span>
                            </h4>
                          </div>
                          {formatedPricesObj?.saving_percentage ? (
                            <div className="saving-box">
                              <h4>
                                Savings ({formatedPricesObj?.saving_percentage}):
                                <span>
                                  {currencySymbol}
                                  {formatedPricesObj?.savings_amount}
                                </span>
                              </h4>
                            </div>
                          ) : ''}
                        </div>
                        {mandetory_fees?.length > 0 && (
                          <div className="resortFeessection">
                            <h3>
                              {' '}
                              <span className="circle">
                                <img
                                  src="img/profile/warning.svg"
                                  alt="icon"
                                  className="mCS_img_loaded"
                                />
                              </span>
                              <strong>
                                Resort Fees per Room:
                                {mandetory_fees[0]?.currency}
                                {mandetory_fees[0]?.amount}
                              </strong>
                            </h3>
                            <p>
                              (<strong>NOT</strong> included in the price, due
                              at the Hotel)
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default HotelDetails;
