import * as Yup from 'yup';
import moment from 'moment';

export const FlightCheckoutValidation = (domesticFlight) => { 
  // Validation for affiliate premium membership
  const frequentFlyerNumberRegex =  /^[A-Z]{2}[0-9]{1,13}$/
  const passportRegex = /^(?!^0+$)[a-zA-Z0-9]{3,10}$/
  const zipCodeRegExp = /^([a-zA-Z0-9]){3,10}$/;

  return Yup.object().shape({
    firstName: Yup.string('')
      .matches(/^[aA-zZ\s]+$/, 'Must only have alphabet letters')
      .required('Required'),
    lastName: Yup.string('')
      .matches(/^[aA-zZ\s]+$/, 'Must only have alphabet letters')
      .required('Required'),
    email: Yup.string('')
      .email('Required')
      .required('Required'),
    phoneNumber: Yup.string('')
      .nullable(true)
      .required('Required').min(3,'Invalid phone number'),
    addressLineOne: Yup.string('').required('Required'),
    addressLineTwo: Yup.string(''),
    city: Yup.string('').required('Required'),
    state: Yup.string('').required('Required'),
    cardHolderName: Yup.string('').required('Required'),
    // zipCode: Yup.string("").required("Required").max(10).min(4),
    // zipCode: Yup.string('').required('Required').matches(/^([a-zA-Z0-9]){3,10}$/,"Invaild zip code"),
    zipCode: Yup.string('').required('Required').min(3, 'Invalid zip code').max(10),
    
    nationality: Yup.string('').required('Required'), 
    title: Yup.string('').required('Required'),
    terms: Yup.bool().oneOf([true], 'Please Accept Terms & Conditions'),
    policy: Yup.bool().oneOf([true], 'Please Accept Cancellation & Change Policy'),

    adults: Yup.array().of(
      Yup.object().shape({
        // frequentFlyerNumber :  Yup.string("").when("check", { is: true, then: Yup.string().required("Required").matches(frequentFlyerNumberRegex, "Enter valid frequent flyer number").nullable(true)}).nullable(true),
        // passportNumber :  Yup.string("").when("domesticFlight", { is: true, then: Yup.string().required("Required")}).nullable(true),
        phoneNumber :  Yup.string('').when('check', { is: true, then: Yup.string().required('Required')}),
        title: Yup.string('').when('check', { is: true, then: Yup.string().required('Required')}).nullable(true),  
        gender: Yup.string('').when('check', { is: true, then: Yup.string().required('Required').nullable(true)}).nullable(true),
        firstName: Yup.string('').when('check', { is: true, then: Yup.string().required('Required')}),
        lastName: Yup.string('').when('check', { is: true, then: Yup.string().required('Required')}),
        nationality: Yup.string('').when('check', { is: true, then: Yup.string().required('Required')}).nullable(true),
        passportCountry: Yup.string('').when('check', { is: true, then: Yup.string().required('Required')}),
        dob: Yup.date(null).when('check', { is: true, then: Yup.date(null).required('Required').test(
          'dob',
          'Age should be more than 18 years.',
          (date) => moment().diff(moment(date), 'years') >= 18
        ).nullable(true)}).nullable(true),
        // passportExpiryDate: Yup.date(null).when("passportNumber", { is: true, then: Yup.date(null).required("Required").nullable(true)}).nullable(true),
        // passportNumber :  Yup.string("").when("domesticFlight", { is: true, then: Yup.string().required("Required")}).nullable(true),
        passportNumber: Yup.string().when('check', (val) => {
          if (!domesticFlight && val) {  
            return Yup.string('').required('Required').nullable(true).matches(passportRegex, 'Enter valid passport number');
          } else {
            return Yup.string('').notRequired().nullable(true).matches(passportRegex, 'Enter valid passport number');
          }
        }),
        passportExpiryDate: Yup.string().when('passportNumber', (val) => {
          if (val?.length > 0) {  //if address exist then apply min max else not
            return Yup.date(null).required('Required').nullable(true);
          } else {
            return Yup.date().notRequired().nullable(true);
          }
        })
      })
    ),

    childs: Yup.array().of(
      Yup.object().shape({
        // frequentFlyerNumber :  Yup.string("").required("Required").matches(frequentFlyerNumberRegex, "Enter valid frequent flyer number").nullable(true),
        title: Yup.string('').required('Required'),
        // passportNumber: Yup.string("").required("Required").nullable(true),
        // passportNumber :  Yup.string("").when("domesticFlight", { is: true, then: Yup.string().required("Required")}).nullable(true),
        gender: Yup.string('').required('Required').nullable(true),
        phoneNumber: Yup.string('').required('Required').min(3,'Invalid phone number'),
        firstName: Yup.string('').required('Required'),
        passportCountry: Yup.string('').required('Required'),
        lastName: Yup.string('').required('Required'),
        nationality: Yup.string('').required('Required'),
        // passportExpiryDate: Yup.date(null).required("Required").nullable(true),
        passportNumber: Yup.string().when('check', (val, schema) => {
          if (!domesticFlight) {  
            return Yup.string('').required('Required').nullable(true).matches(passportRegex, 'Enter valid passport number');
          } else {
            return Yup.string('').notRequired().nullable(true).matches(passportRegex, 'Enter valid passport number');
          }
        }),
        passportExpiryDate: Yup.string().when('passportNumber', (val, schema) => {
          if (val?.length > 0) {  //if address exist then apply min max else not
            return Yup.date(null).required('Required').nullable(true);
          } else {
            return Yup.date().notRequired().nullable(true);
          }
        }),
        dob: Yup.date(null)
          .required('Required')
          .nullable(true)
          .test(
            'dob',
            'Age should be less than 12 and more than 2.',
            (date) =>
              moment().diff(moment(date), 'years') >= 2 &&
              moment().diff(moment(date), 'years') < 18
          ),
      })
    ),
    infants: Yup.array().of(
      Yup.object().shape({
        // frequentFlyerNumber :  Yup.string("").required("Required").matches(frequentFlyerNumberRegex, "Enter valid frequent flyer number").nullable(true),
        title: Yup.string('').required('Required'),
        // passportNumber: Yup.string("").required("Required").nullable(true),
        passportNumber :  Yup.string('').when('domesticFlight', { is: true, then: Yup.string().required('Required')}).nullable(true),
        gender: Yup.string('').required('Required').nullable(true),
        phoneNumber: Yup.string('').required('Required').min(3,'Invalid phone number'),
        firstName: Yup.string('').required('Required'),
        passportCountry: Yup.string('').required('Required'),
        lastName: Yup.string('').required('Required'),
        nationality: Yup.string('').required('Required'),
        // passportExpiryDate: Yup.date(null).required("Required").nullable(true),
        // eslint-disable-next-line no-dupe-keys
        passportNumber: Yup.string().when('check', (val, schema) => {
          if (!domesticFlight) {  
            return Yup.string('').required('Required').nullable(true).matches(passportRegex, 'Enter valid passport number');
          } else {
            return Yup.string('').notRequired().nullable(true).matches(passportRegex, 'Enter valid passport number');
          }
        }),
        passportExpiryDate: Yup.string().when('passportNumber', (val, schema) => {
          if (val?.length > 0) {  //if address exist then apply min max else not
            return Yup.date(null).required('Required').nullable(true);
          } else {
            return Yup.date().notRequired().nullable(true);
          }
        }),
        dob: Yup.date(null)
          .required('Required')
          .nullable(true)
          .test(
            'dob',
            'Age should be between 1 month and 2 years..',
            (date) =>
              moment().diff(moment(date), 'years') < 2 &&
              moment().diff(moment(date), 'years') >= 0
          ),
      })
    ),
  });
};
