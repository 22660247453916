import React, { useRef, memo, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import useSupercluster from 'use-supercluster';
import starImg from '../../../Assets/img/yellow-star.png';
import arrowImg from '../../../Assets/img/hotel/ic-arrow-back.png';
import { utilitiesImgForMapView } from '../../../Data/hotelMockData';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrencyAndLanguage } from '../../../Util/util';
import { getCardDataAction } from '../../../Stores/Actions/hotelActions';
import { Link } from 'react-router-dom';
import _ from 'lodash'

const Marker = ({ children }) => {
  return children || null;
};

// eslint-disable-next-line react/prop-types
function MapView({ hideMapView, mobileMap }) {
  const dispatch = useDispatch()
  const mapRef = useRef();
  const [zoom, setZoom] = useState(5);
  const [bounds, setBounds] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  // const loading = useSelector((state) => state.commonReducer.loader);
  const hotelDataMapView = useSelector((state) => state.hotelReducer.hotelDataMapView);
  const { currencySymbol } = getCurrencyAndLanguage();

  // console.log('hotelDataMapView', hotelDataMapView);

  const latAndLong = hotelDataMapView?.length > 0 ? hotelDataMapView?.map((ele) => ({
    lat: ele?.latitude,
    lng: ele?.longitude,
  })) : [];

  const points = hotelDataMapView?.length > 0 ? hotelDataMapView?.map((data) => ({
    type: 'Feature',
    properties: {
      // cluster: false,
      id: data?.id,
      hotelPrice: data?.price,
    },
    geometry: {
      type: 'Point',
      coordinates: [data?.longitude, data?.latitude]
    },
  })) : [];

  // get clusters
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 60, maxZoom: 10 },
  });

  const getMapBounds = (map, maps, places) => {
    const bounds = new maps.LatLngBounds();
    places?.forEach((place) => {
      bounds.extend(new maps.LatLng(place.lat, place.lng));
    });
    return bounds;
  };

  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds);
      });
    });
  };

  const apiIsLoaded = (map, maps, places) => {
    mapRef.current = map;
    const bounds = getMapBounds(map, maps, places);
    map.fitBounds(bounds);
    bindResizeListener(map, maps, bounds);
  };

  // set info window of hotel on map,
  const handleActiveMarker = (id) => {
    if (id === activeMarker) {
      return;
    }
    setActiveMarker(id);
  };

  const [tooltipCardData, setTooltipCardData] = useState()

  // ----- get data on click of mark -------
  const getCardData = (id) => {
    dispatch(getCardDataAction(id)).then((res) => {
      if (res.status === 200) {
        setTooltipCardData(res?.data?.data)
      }
    })
  }

  const mapOptions = {
    fullscreenControl: false,
    mapTypeControl: false
  };

  return (
    <>
      {hotelDataMapView?.length > 0 &&
        <div className="popup-content map-view-popup">
          <div className="box-content">
            <div className="map-model">
              {!mobileMap && (
                <button
                  className="back-to-view"
                  onClick={() => {
                    hideMapView();
                  }}
                >
                  <span>
                    <img src={arrowImg} />
                    List View
                  </span>
                </button>
              )}
            </div>
            <div
              style={{
                height: '73vh',
                width: '100%',
                position: 'relative',
                overflow: 'hidden',
                borderRadius: '10px',
              }}
            >
              <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}
                defaultCenter={{ lat: 28.70406, lng: 77.102493 }}
                defaultZoom={10}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => {
                  // mapRef.current = map;
                  apiIsLoaded(map, maps, latAndLong);
                }}
                // onGoogleApiLoaded={({ map }) => {
                //   mapRef.current = map;
                // }}
                options={mapOptions}
                onChange={({ zoom, bounds }) => {
                  setZoom(zoom);
                  setBounds([
                    bounds.nw.lng,
                    bounds.se.lat,
                    bounds.se.lng,
                    bounds.nw.lat,
                  ]);
                }}
              // onClick={() => setActiveMarker(null)}
              >
                {clusters.map((cluster, i) => {
                  const [longitude, latitude] = cluster.geometry.coordinates;
                  const {
                    cluster: isCluster,
                    point_count: pointCount,
                    id: markerId,
                    hotelPrice
                  } = cluster.properties;



                  if (isCluster) {
                    return (
                      <Marker key={i} lat={latitude} lng={longitude}>
                        <span
                          className="cluster-marker"
                          onClick={() => {
                            const expansionZoom = Math.min(
                              supercluster.getClusterExpansionZoom(cluster.id),
                              60
                            );
                            mapRef.current.setZoom(expansionZoom);
                            mapRef.current.panTo({
                              lat: latitude,
                              lng: longitude,
                            });
                          }}
                        >
                          {`${pointCount} hotels`}
                        </span>
                      </Marker>
                    );
                  } else {
                    return (
                      <Marker key={i} lat={latitude} lng={longitude}>
                        <span
                          // className="single-marker"
                          className={
                            activeMarker === markerId
                              ? 'active-single-marker'
                              : 'single-marker'
                          }
                          onClick={() => {
                            handleActiveMarker(markerId);
                            getCardData(markerId);
                            mapRef.current.panTo({
                              lat: latitude,
                              lng: longitude,
                            });
                          }}
                        >
                          {currencySymbol}{hotelPrice}
                        </span>
                        {activeMarker === markerId ? (
                          <div style={{ zIndex: 10000 }}>
                            <div className="map-popup">
                              <div className="d-flex">
                                <div
                                  className="item-img"
                                  style={{
                                    backgroundImage: `url('${tooltipCardData?.thumbnail_image}')`,
                                  }}
                                ></div>
                                <div className="innerCnt">
                                  <div className="rating-info">
                                    <button
                                      className="cancel"
                                      onClick={() => {
                                        setActiveMarker(null);
                                      }}
                                    >
                                      <i className="icon-close" />
                                    </button>
                                    <Link to={`/hotel-detail/${markerId}`}>
                                      <h3 className="cartTitle" title={tooltipCardData?.hotel_name}>
                                        {tooltipCardData?.hotel_name}
                                      </h3>
                                    </Link>
                                    <div className="topSection dflex justifyStart">
                                      <div className="rating">
                                        {_.range(1, tooltipCardData?.star_rating + 1).map(
                                          (ele, index) => {
                                            return (
                                              <img
                                                key={`${index}_stars`}
                                                src={starImg}
                                                alt="yellow-star"
                                                className=""
                                              />
                                            );
                                          }
                                        )}
                                      </div>
                                      <div className="ratingComment font14">
                                        <span className="commentCount">
                                          {tooltipCardData?.star_rating}
                                        </span>
                                        Very Good
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mobileViewWrapper">
                                    <div className="facility-icon">
                                      {utilitiesImgForMapView?.map(
                                        (ele, index) => {
                                          return (
                                            <img
                                              src={ele}
                                              alt="icons"
                                              key={`${index}_stars_mob`}
                                            />
                                          );
                                        }
                                      )}
                                      <span className="more-icon">+4</span>
                                    </div>
                                    <div className="price">
                                      <p>From:</p>
                                      <span>{`${currencySymbol}${hotelPrice}`}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </Marker>
                    );
                  }
                })}
              </GoogleMapReact>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default memo(MapView);
