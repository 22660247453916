import { flightService } from "../../api/flightService";
import { flightConstants } from "../Constants";
import { hotelConstants } from "../Constants";
import { commonActions } from "./commonActions";

export const flightActions = {
  searchFlightAction,
  searchFlightDetailsAction,
  getFlightFeaturesAction,
  validateFlightActions,
  checkoutFlightActions,
  searchFlightPollingAction,
  getFlightBookingDetails,
  getStripeIntentRequest,
  reValidateFlightActions,
  cancelledBooking,
  resetCancelledBooking,
  downloadPdf,
  updateFlightFilter,
  preBookingFlightActions,
  getAvailableBalance,
  searchFlightFareRuleAction,
  cancelRefundDetails,
};
function searchFlightAction(payload, navigateTo, use_flight_search_id) {
  return (dispatch) => {
    dispatch(request(flightConstants.SEARCH_FLIGHT_REQUEST));
    dispatch(commonActions.loaderText("loading"));
    dispatch(commonActions.newLoader("newloader"));

    flightService.searchFlightService(payload, use_flight_search_id).then(
      (response) => {
        dispatch(commonActions.loaderText());
        dispatch(commonActions.newLoader());
        dispatch(success(flightConstants.SEARCH_FLIGHT_SUCCESS, response));
        if (
          response.data
          // && !use_flight_search_id
        ) {
          window.localStorage.setItem(
            "flight_search_id",
            JSON.stringify(response.data.searchId)
          );
        }

        if (navigateTo) navigateTo();
        dispatch(commonActions.hideLoader());
      },
      (error) => {
        dispatch(commonActions.loaderText());
        dispatch(commonActions.hideLoader());
        dispatch(failure(flightConstants.SEARCH_FLIGHT_FAILURE, error));
      }
    );
  };
}
function searchFlightDetailsAction(payload) {
  return (dispatch) => {
    dispatch(commonActions.loaderText("SEARCHING..."));
    return flightService.searchFlightDetailsService(payload).then(
      (response) => {
        dispatch(commonActions.hideLoader());
        return response;
      },
      (error) => {
        dispatch(commonActions.hideLoader());
        return error?.response;
      }
    );
  };
}

function searchFlightFareRuleAction(payload) {
  return (dispatch) => {
    dispatch(commonActions.loaderText("SEARCHING..."));
    return flightService.searchFlightFareRuleService(payload).then(
      (response) => {
        dispatch(commonActions.hideLoader());
        return response;
      },
      (error) => {
        dispatch(commonActions.hideLoader());
        return error?.response;
      }
    );
  };
}

function getFlightFeaturesAction(payload) {
  return (dispatch) => {
    dispatch(request(flightConstants.FLIGHT_FEATURES_REQUEST));
    dispatch(commonActions.showLoader());
    flightService.getFlightFeaturesAction(payload).then(
      (response) => {
        dispatch(success(flightConstants.FLIGHT_FEATURES_SUCCESS, response));
        // if(response.data && response.data){
        //     window.localStorage.setItem("flightBookingFEATURES", JSON.stringify(response.data));
        // }
        dispatch(commonActions.hideLoader());
      },
      (error) => {
        dispatch(commonActions.hideLoader());
        dispatch(failure(flightConstants.FLIGHT_FEATURES_FAILURE, error));
      }
    );
  };
}

function downloadPdf(data) {
  return (dispatch) => {
    dispatch(request(hotelConstants.DOWNLOAD_INVOICE));
    dispatch(commonActions.showLoader());
    return flightService
      .downloadPdf(data)
      .then((res) => {
        dispatch(success(hotelConstants.DOWNLOAD_INVOICE_SUCCESS, res));
        window.open(res.data.url, "_blank");
        dispatch(commonActions.hideLoader());
      })
      .catch((error) => {
        dispatch(failure(hotelConstants.DOWNLOAD_INVOICE_FAILURE, error));
        dispatch(commonActions.hideLoader());
      });
  };
}

function cancelledBooking(payload) {
  return (dispatch) => {
    dispatch(request(hotelConstants.CANCEL_BOOKING));
    dispatch(commonActions.showLoader());
    return flightService
      .cancelledBooking(payload)
      .then((res) => {
        dispatch(success(hotelConstants.CANCEL_BOOKING_SUCCESS, res));
        dispatch(commonActions.hideLoader());
      })
      .catch((error) => {
        dispatch(failure(hotelConstants.CANCEL_BOOKING_FAILURE, error));
        dispatch(commonActions.hideLoader());
      });
  };
}

function cancelRefundDetails(id) {
  return (dispatch) => {
    dispatch(request(flightConstants.CANCEL_BOOKING_DETAIL_REQUEST));
    // dispatch(commonActions.showLoader());
    return flightService
      .cancelRefundDetails(id)
      .then((res) => {
        dispatch(success(flightConstants.CANCEL_BOOKING_DETAIL_SUCCESS, res));
        // dispatch(commonActions.hideLoader());
        return res;
      })
      .catch((error) => {
        dispatch(failure(flightConstants.CANCEL_BOOKING_DETAIL_FAILURE, error));
        return error;
        // dispatch(commonActions.hideLoader());
      });
  };
}

function resetCancelledBooking() {
  return (dispatch) => {
    dispatch(request(hotelConstants.CANCEL_BOOKING));
  };
}

function searchFlightPollingAction(payload) {
  return (dispatch) => {
    dispatch(request(flightConstants.SEARCH_POLL_FLIGHT_REQUEST));
    // dispatch(commonActions.showLoader());
    flightService.searchFlightPollingService(payload).then(
      (response) => {
        dispatch(success(flightConstants.SEARCH_POLL_FLIGHT_SUCCESS, response));
        dispatch(commonActions.hideLoader());
      },
      (error) => {
        dispatch(commonActions.hideLoader());
        dispatch(failure(flightConstants.SEARCH_POLL_FLIGHT_FAILURE, error));
      }
    );
  };
}

function validateFlightActions(payload) {
  return (dispatch) => {
    dispatch(request(flightConstants.REVALIDATE_FLIGHT_REQUEST));
    dispatch(commonActions.showLoader());
    dispatch(commonActions.loaderText("Validating..."));
    return flightService.validateFlight(payload).then(
      (response) => {
        dispatch(success(flightConstants.REVALIDATE_FLIGHT_SUCCESS, response));
        dispatch(commonActions.hideLoader());
        return response;
      },
      (error) => {
        dispatch(commonActions.hideLoader());
        dispatch(failure(flightConstants.REVALIDATE_FLIGHT_FAILURE, error));
        return error?.response;
      }
    );
  };
}

function checkoutFlightActions(payload) {
  return (dispatch) => {
    dispatch(request(flightConstants.CHECKOUT_FLIGHT_REQUEST));
    dispatch(commonActions.showLoader());
    return flightService.checkoutFlightService(payload).then(
      (response) => {
        dispatch(success(flightConstants.CHECKOUT_FLIGHT_SUCCESS, response));
        dispatch(commonActions.hideLoader());
        return response;
      },
      (error) => {
        dispatch(commonActions.hideLoader());
        dispatch(failure(flightConstants.CHECKOUT_FLIGHT_FAILURE, error));
        return error?.response;
      }
    );
  };
}

function getFlightBookingDetails(id) {
  return (dispatch) => {
    dispatch(request(flightConstants.GET_FLIGHT_BOOKING_DETAILS_REQUEST));
    dispatch(commonActions.showLoader());

    return flightService
      .getFlightBookingDetailsService(id)
      .then((res) => {
        dispatch(
          success(flightConstants.GET_FLIGHT_BOOKING_DETAILS_SUCCESS, res.data)
        );
        dispatch(commonActions.hideLoader());
      })
      .catch((error) => {
        dispatch(
          failure(flightConstants.GET_FLIGHT_BOOKING_DETAILS_FAILURE, error)
        );
        dispatch(commonActions.hideLoader());
      });
  };
}

function updateFlightFilter(payload) {
  return {
    type: "UPDATE_FLIGHT_FILTERS",
    payload,
  };
}

function reValidateFlightActions(payload) {
  return (dispatch) => {
    dispatch(request(flightConstants.REVALIDATE_FLIGHT_REQUEST));
    dispatch(commonActions.showLoader());
    return flightService.validateFlight(payload).then(
      (response) => {
        dispatch(success(flightConstants.REVALIDATE_FLIGHT_SUCCESS, response));
        dispatch(commonActions.hideLoader());
        return response;
      },
      (error) => {
        dispatch(commonActions.hideLoader());
        dispatch(failure(flightConstants.REVALIDATE_FLIGHT_FAILURE, error));
        return error?.response;
      }
    );
  };
}

// flight pre booking action
function preBookingFlightActions(payload) {
  return (dispatch) => {
    dispatch(request(flightConstants.PREBOOK_FLIGHT_BOOKING_REQUEST));
    dispatch(commonActions.showLoader());
    return flightService.flightPrebook(payload).then(
      (response) => {
        dispatch(
          success(flightConstants.PREBOOK_FLIGHT_BOOKING_SUCCESS, response)
        );
        dispatch(commonActions.hideLoader());
        return response;
      },
      (error) => {
        dispatch(commonActions.hideLoader());
        dispatch(
          failure(flightConstants.PREBOOK_FLIGHT_BOOKING_FAILURE, error)
        );
        return error;
      }
    );
  };
}

function getStripeIntentRequest(payload) {
  return (dispatch) => {
    dispatch(request(flightConstants.GET_STRIPE_INTENT_REQUEST));
    dispatch(commonActions.showLoader());
    return flightService
      .getStripeIntent(payload)
      .then((response) => {
        dispatch(success(flightConstants.GET_STRIPE_INTENT_SUCCESS, response));
        return response;
      })
      .catch((error) => {
        dispatch(failure(flightConstants.GET_STRIPE_INTENT_FAILURE, error));
        dispatch(commonActions.hideLoader());
        return error?.response;
      });
  };
}

// Get available balance
function getAvailableBalance() {
  return (dispatch) => {
    flightService.getAvailableBalance().then(
      (response) => {
        dispatch(success(flightConstants.GET_AVAILABLE_BALANCE, response));
      },
      (error) => {}
    );
  };
}

function request(action, payload) {
  return { type: action, payload };
}

function success(action, response) {
  return { type: action, response };
}

function failure(action, error) {
  return { type: action, error };
}
