import React from "react";
import Modal from "react-modal";
import CloseIcon from "../../../Assets/img/close-blue-icon.png";
import HotelImage from "../../../Assets/img/placeholder-image.png";
import Slider from "react-slick";


function RoomDetaisModal({ isOpen, closeModal, hotelImages, group_name, facilities }) {
console.log("group_name",group_name)
  const SlickSlider = () => {
    let settings = {
      dots: false,
      infinite:true,
      autoplay: true,
      autoplaySpeed: 3000,
    }
    return <>
      <Slider {...settings} >
        {hotelImages?.map((imgSrc,i)=>{
          return <div key={i} className="slider-image-wrap"><div className="slider-image" style={{ backgroundImage: `url(${imgSrc})` }}></div></div>
        })} 
      </Slider>
    </>
  }


  return (
    <div className="detailPageWrap">
      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        className="popup room-details-popup popup-open  "
      >
        <div className="popup-content-wrap  ">
          <div className="popup-content room-detail">
            <div className="popup-title">
              <h3>Room {hotelImages?.length > 1 ? "Photos" :"Photo"} </h3>
              <button
                className="btn close room-details-popup-close-btn"
                onClick={() => closeModal(false)}
              >
                <img src={CloseIcon} alt="icon" />
              </button>
            </div>
            <div className="box-content">
              <div className="room-detail-slider">
                <div className="slider-image-slider">

                  {hotelImages?.length > 1 ?
                    <> 
                      <SlickSlider/>
                    </>
                    : 
                    <>
                    <div className="slider-image" style={{ backgroundImage: `url(${hotelImages && hotelImages[0] || HotelImage})` }}></div>
                      {/* <img src={ hotelImages && hotelImages[0] || HotelImage} alt="hotel detail room image" /> */}
                    </>
                  }
                </div>
              </div>
              <div className="room-details-content">
                <h3>{group_name}</h3>
                {/* <p>
                  A flat-screen TV, iHome docking station, a spacious work desk,
                  premium linens, bathrobes, spa-quality toiletries and an organic
                  honor bar are included in this room. Rates are based on 2
                  guests. A maximum of 4 guests can stay in this room. Please
                  refer to the policies for fees that will apply for additional
                  guests.
                </p> */}

                {/* <div className="progress-wrap">
                  <div className="d-flex justify-content-between">
                    <h4>Cleaness</h4>
                    <h3>95</h3>
                  </div>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "95%" }}
                      aria-valuenow="95"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div> */}
              </div>
              {/* <div className="room-facilities-wrap"> */}
              {/* <h3>Room Facilities</h3> */}
              {/* <div className="row">
                  <div className="col">
                    <ul>
                      { facilities?.map((data) => {
                        return <>
                          <li> {data}  </li>
                        </>
                      })}
                    </ul>
                  </div>
                </div> */}

              {/* <div className="row">
                <div className="col">
                  <ul>
                    <li>Accessible by lift</li>
                    <li>Air conditioning</li>
                    <li>Alarm clock</li>
                    <li>Bath or Shower</li>
                    <li>Bathrobe(s)</li>
                    <li>Body soap</li>
                    <li>Carpeting</li>
                  </ul>
                </div>
                <div className="col">
                  <ul>
                    <li>Closet</li>
                    <li>Clothes rack</li>
                    <li>Conditioner</li>
                    <li>Crib(s)</li>
                    <li>Desk</li>
                    <li>Dressing room</li>
                    <li>Feather pillow(s)</li>
                  </ul>
                </div>
                <div className="col">
                  <ul>
                    <li>Laptop safe</li>
                    <li>Linens</li>
                    <li>Mini bar</li>
                    <li>Non-feather pillow</li>
                    <li>On-demand media</li>
                    <li>Phone</li>
                    <li>Pool towels</li>
                  </ul>
                </div>
                <div className="col">
                  <ul>
                    <li>Laptop safe</li>
                    <li>Linens</li>
                    <li>Mini bar</li>
                    <li>Non-feather pillow</li>
                    <li>On-demand media</li>
                    <li>Phone</li>
                    <li>Pool towels</li>
                  </ul>
                </div>
              </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default RoomDetaisModal;
