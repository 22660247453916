import React, { Fragment, useEffect, useCallback, useState, useRef } from 'react';
import Button from '../../Component/FormComponent/Button';
import MapView from './MapView/NewMapView.jsx';
import SingleHotelCard from './SingleHotelCard/Index.newSingleHotelCard.jsx';
import Pagination from '../../Component/Pagiantion/Pagination';
import { newSearchHotelAction, getHotelsForMapAction } from '../../Stores/Actions';
import HotelOfferCard from '../../Component/Hotel/HotelOfferCard';
import HeaderNavbar from '../../Component/HeaderNavbar';
import FooterWithMediaIcon from '../../Component/FooterWithMediaIcon';
import HotelWidget from './hotelComponents/NewHotelWidget.jsx';
import MapImg from '../../Assets/img/map-placeholder.png';
import ResultsOnPage from '../../Component/Pagiantion/ResultsOnPage';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import HotelName from './hotelComponents/HotelName.jsx';
import PriceRange from './hotelComponents/PriceRange.jsx';
import StarRating from './hotelComponents/StarRating.jsx';
import AmenitiesFilter from './hotelComponents/AmenitiesFilter.jsx';
import MealPlansFilter from './hotelComponents/MealPlansFilter.jsx';
import HotelChainFilter from './hotelComponents/HotelChainFilter.jsx';
import AccesbilityFilter from './hotelComponents/AccesbilityFilter.jsx';
import FilterDisplay from './hotelComponents/NewFilterDisplay.jsx';
import PropertyFilter from './hotelComponents/PropertyFilter.jsx';
import SortBy from './hotelComponents/SortBy.jsx';
import { isEmptyValue, getCurrencyAndLanguage } from '../../Util/util.js'

const initialFiltersValue = {
  hotelName: {
    valueType: 'number',
    payloadKey: 'id',
    onChangeValue: null,
    value: null,
    label: 'Hotel Name'
  },
  priceRange: {
    valueType: 'object',
    payloadKey: 'priceRange',
    onChangeValue: { min: 0, max: 0 },
    value: { min: 0, max: 0 },
    label: 'Price'
  },
  starRating: {
    valueType: 'array',
    payloadKey: 'rating',
    onChangeValue: [],
    value: [],
    label: 'Star Ratings'
  },
  amenities: {
    valueType: 'array',
    payloadKey: 'amenities',
    onChangeValue: [],
    value: [],
    label: 'Amenities'
  },
  meals: {
    valueType: 'array',
    payloadKey: 'meals',
    onChangeValue: [],
    value: [],
    label: 'Meals'
  },
  hotel_brand: {
    valueType: 'array',
    payloadKey: 'hotel_brand',
    onChangeValue: [],
    value: [],
    label: 'Hotel Chain'
  },
  accessibilities: {
    valueType: 'array',
    payloadKey: 'accessibilities',
    onChangeValue: [],
    value: [],
    label: 'Accessibilities'
  },
  property_type: {
    valueType: 'array',
    payloadKey: 'property_type',
    onChangeValue: [],
    value: [],
    label: 'Property Type'
  }
}

// new node apis
function HotelSearchResult() {

  const scrollbarsRef = useRef(null);


  const [mobileFilter, setMobileFilter] = useState(false);

  const { currency, currencySymbol } = getCurrencyAndLanguage();

  const [showMap, setShowMap] = useState(false);

  // user data
  const user = JSON.parse(window.localStorage.getItem('user'));

  useEffect(() => {
    window.localStorage.removeItem('hotel_search_key');
  }, [])

  const [mobileMap, setMobileMap] = useState(false);

  const dispatch = useDispatch();

  const [filters, setFilters] = useState(initialFiltersValue);

  const hotelPayload = JSON.parse(window.localStorage.getItem('hotelPayload'));

  const [payload, setPayload] = useState({ ...hotelPayload, sort: { field: 'savings_percentage', order: 'desc' } });

  const { newHotelSearchData } = useSelector((state) => state.hotelReducer);

  const payloadClone = { ...payload };
  const filtersClone = { ...filters };

  // ---------- change filter to suitable payload format -------------
  const formatFiltersForPayload = (filters) => {
    let finalFormatedFilters = {};
    Object.entries(filters).forEach(([key, item]) => {
      if (!isEmptyValue(item?.value, item.valueType) && item.valueType !== 'object') {
        finalFormatedFilters[item.payloadKey] = item.value;
      } else if (key === 'priceRange' && item?.value?.max && item?.value?.min) {  // need to handle all object here manually
        finalFormatedFilters.max_price = item.value.max
        finalFormatedFilters.min_price = item.value.min
      }
    })
    return finalFormatedFilters;
  }


  //----------------- search hotel ----------------
  const handleSearchHotel = (updatedFilters, updatedPayload, isMapView) => {
    let payload = payloadClone;
    let filters = filtersClone;

    if (updatedFilters) {
      let updatedFiltersNew = {};
      Object.entries(updatedFilters).forEach(([key, item]) => {
        let singleFilter = { ...item };
        // singleFilter.value = item.onChangeValue;
        singleFilter.onChangeValue = item.value;
        updatedFiltersNew = { ...updatedFiltersNew, [key]: singleFilter };
      })
      setFilters(updatedFiltersNew);
      // filters = updatedFilters;
      filters = updatedFiltersNew;
    } else {
      setFilters(initialFiltersValue)
      filters = initialFiltersValue;
    }

    if (updatedPayload) {
      setPayload(updatedPayload)
      payload = updatedPayload
    }

    payload.filters = formatFiltersForPayload(filters);

    const formateRoomGuest = payload?.rooms?.map((item) => {
      return {
        adults: item.adults,
        children: item.children?.map((i) => i.toString())
      }
    }) || []

    payload.rooms = formateRoomGuest;


    if ((showMap && isMapView === undefined) || isMapView) {
      const mapPayload = {
        currency: currency,
        filters: payload.filters
      }
      dispatch(getHotelsForMapAction(mapPayload));
    } else {
      dispatch(newSearchHotelAction(payload));
    }
  }

  //----------------- search hotel func end ----------------
  useEffect(() => {
    // localStorage.removeItem("hotel_search_key");
    handleSearchHotel();
  }, []);

  function handlePageChange(page_number, per_page) {
    if (page_number) {
      handleSearchHotel(filters, { ...payload, paging: { ...payload.paging, page_number: page_number?.selected + 1 } })
    } else if (per_page) {
      handleSearchHotel(filters, { ...payload, paging: { ...payload.paging, page_number: 1, per_page } })
    }
    // scroll to top
    scrollToTop();
  }

  const scrollToTop = () => {
    if (scrollbarsRef.current) {
      scrollbarsRef.current.scrollTop(0);
    }
  };


  // ------ mobile view apply changed filters and close modal ---------
  const handleMobileFilterApply = () => {
    let updatedFilters = {};
    Object.entries(filters).forEach(([key, item]) => {
      let singleFilter = { ...item };
      singleFilter.value = item.onChangeValue;
      updatedFilters = { ...updatedFilters, [key]: singleFilter };
    })
    handleSearchHotel(updatedFilters);
    setMobileFilter(false)
  };

  // --------------- reset all filters -------------
  const resetFilters = () => {
    handleSearchHotel(null, null)
  };


  // -------- Show map view for both desktop and mobile -----------
  const showMapView = useCallback(() => {
    setShowMap(true)
    handleSearchHotel(filters, null, true);
  }, [filters]);

  // -------- Hide map view for both desktop and mobile -----------
  const hideMapView = useCallback(() => {
    setShowMap((prev) => !prev);
    handleSearchHotel(filters, null, false);
  }, [filters]);


  return (
    <div className="siteBg listingPage listingPageWrap listingHotel">
      <div className="pageWrap">
        <div className="fixedHeader">
          <HeaderNavbar
            toggleFilterHandler={() => { }}
            useBlueIcon={true}
          />
        </div>
        <section className="mainContent">
          {!mobileFilter &&
            <HotelWidget
              setFilters={setFilters}
              handleSearchHotel={handleSearchHotel}
              setShowMap={setShowMap}
            />
          }

          {/* mobile map view  */}
          <div
            className={`rightInfo-filter d-flex ${mobileFilter ? 'rightInfo-filter-active' : ''}`}
          >
            <div className="mobileSearchFilterButtons">
              <div className="d-flex">
                <Button
                  className="sortingButton mobileButtons"
                  onClick={() => {
                    setMobileFilter(true);
                  }}
                >
                  <i className="fa fa-sliders"></i> Filter & Sorting
                </Button>
                {!mobileMap ? (
                  <div
                    className="mapButton mobileButtons"
                    onClick={() => {
                      showMapView();
                      setMobileMap(!mobileMap);
                    }}
                  >
                    <i className="icon-location-on" />
                    Map View
                  </div>
                ) : (
                  <div
                    className="mapButton mobileButtons"
                    onClick={() => {
                      hideMapView();
                      setMobileMap(!mobileMap);
                    }}
                  >
                    List View
                  </div>
                )}
              </div>
            </div>
            <div
              className={`${mobileFilter && 'showPopup'} mobileSortFilter mobilePopup`}
            >
              <div className="mobileShow">
                <a href="#" className="clearFilter">
                  Clear
                </a>

                <Button
                  className="closePopup closePopup2"
                  onClick={() => {
                    setMobileFilter(false)
                  }}
                >
                  <span className="icon-close"></span>
                </Button>

                <h4 className="mobilePopTitle">Sort by</h4>
                <div className="clearBtnMobile">
                  <Button
                    className="btn"
                    type="reset"
                    onClick={() => {
                      resetFilters();
                      setMobileFilter(false);
                    }}
                  >
                    Clear
                  </Button>
                </div>
                <div className="applyBtnMobile">
                  <Button
                    className="btn"
                    type="button"
                    onClick={() => {
                      handleMobileFilterApply();
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </div>
              <div className="mobileShow mobileShowsortFilter">
                <div className="sortFilter">
                  <div className="filterDropdownWrap sortByOption">
                    <SortBy
                      isMobileView={mobileFilter}
                      payload={payload}
                      handleSearchHotel={handleSearchHotel}
                    />
                  </div>

                  <h4 className="mobilePopTitle filterTitle mobileShow">
                    Filter by
                  </h4>
                  <div
                    className="inputStyle CustominputStyle filterby-input"
                    style={{ width: '100%' }}
                  >
                    <HotelName
                      isMobileView={mobileFilter}
                      filters={filters}
                      setFilters={setFilters}
                      handleSearchHotel={handleSearchHotel}
                    />
                  </div>

                  <div className="filterDropdownWrap">     {/* mobile */}
                    <PriceRange
                      isMobileView={mobileFilter}
                      filters={filters}
                      setFilters={setFilters}
                      handleSearchHotel={handleSearchHotel}
                    />
                  </div>
                  <div className="filterDropdownWrap">
                    <StarRating
                      isMobileView={mobileFilter}
                      filters={filters}
                      setFilters={setFilters}
                      handleSearchHotel={handleSearchHotel}
                    />
                  </div>

                  <div className="filterDropdownWrap">
                    <AmenitiesFilter
                      title="Amenities"
                      type="amenities"
                      isMobileView={true}
                      filters={filters}
                      setFilters={setFilters}
                      handleSearchHotel={handleSearchHotel}
                    />
                  </div>
                  <div className="filterDropdownWrap">
                    <MealPlansFilter
                      title="Meal Plans"
                      type="meals"
                      isMobileView={true}
                      filters={filters}
                      setFilters={setFilters}
                      handleSearchHotel={handleSearchHotel}
                    />
                  </div>
                  <div className="filterDropdownWrap">
                    <HotelChainFilter
                      title="Hotel Chain"
                      type="hotel_brand"
                      isMobileView={true}
                      filters={filters}
                      setFilters={setFilters}
                      handleSearchHotel={handleSearchHotel}
                    />
                  </div>
                  <div className="filterDropdownWrap">
                    <AccesbilityFilter
                      title="Accessibilities"
                      type="accessibilities"
                      isMobileView={true}
                      filters={filters}
                      setFilters={setFilters}
                      handleSearchHotel={handleSearchHotel}
                    />
                  </div>
                  <div className="filterDropdownWrap">
                    <PropertyFilter
                      title="Property Type"
                      type="property_type"
                      isMobileView={true}
                      filters={filters}
                      setFilters={setFilters}
                      handleSearchHotel={handleSearchHotel}
                    />
                  </div>

                </div>
              </div>
            </div>
          </div>


          <Scrollbars
            autoHeight
            autoHeightMin={120}
            autoHeightMax={`calc(100%)`}
            className="pageInternalScroll"
            ref={scrollbarsRef}
          >
            <div className="container listing-margin-top">
              <div className="contentWrapper row">
                <div className="leftSideBar col">
                  {!showMap && (
                    <div className="card mapCard">
                      <div className="mapWrap">
                        <img src={MapImg} alt="map" className="w-100" />
                        <button
                          className="whiteBtn"
                          onClick={() => {
                            showMapView();
                          }}
                        >
                          Map View
                        </button>
                      </div>
                    </div>
                  )}
                  <div className="cardHead dflex">
                    <h3>Filter by</h3>
                    <Button className="clear" onClick={resetFilters}>
                      <i className="icon-close"></i>Clear
                    </Button>
                  </div>
                  <div className="card hotel-name-search-card">
                    <HotelName
                      isMobileView={mobileFilter}
                      filters={filters}
                      setFilters={setFilters}
                      handleSearchHotel={handleSearchHotel}
                    />
                  </div>
                  <div className="card">    {/* desktop */}
                    <PriceRange
                      isMobileView={mobileFilter}
                      filters={filters}
                      setFilters={setFilters}
                      handleSearchHotel={handleSearchHotel}
                    />
                  </div>
                  <div className="card">
                    <StarRating
                      isMobileView={mobileFilter}
                      filters={filters}
                      setFilters={setFilters}
                      handleSearchHotel={handleSearchHotel}
                    />
                  </div>

                  <div className="card amenities-wrap">
                    <AmenitiesFilter
                      title="Amenities"
                      isMobileView={false}
                      filters={filters}
                      setFilters={setFilters}
                      type="amenities"
                      handleSearchHotel={handleSearchHotel}
                    />
                  </div>
                  <div className="card amenities-wrap">
                    <MealPlansFilter
                      title="Meal Plans"
                      type="meals"
                      isMobileView={false}
                      filters={filters}
                      setFilters={setFilters}
                      handleSearchHotel={handleSearchHotel}
                    />
                  </div>
                  <div className="card amenities-wrap">
                    <HotelChainFilter
                      title="Hotel Chain"
                      type="hotel_brand"
                      isMobileView={false}
                      filters={filters}
                      setFilters={setFilters}
                      handleSearchHotel={handleSearchHotel}
                    />
                  </div>
                  <div className="card amenities-wrap">
                    <AccesbilityFilter
                      title="Accessibilities"
                      type="accessibilities"
                      isMobileView={false}
                      filters={filters}
                      setFilters={setFilters}
                      handleSearchHotel={handleSearchHotel}
                    />
                  </div>
                  <div className="card amenities-wrap">
                    <PropertyFilter
                      title="Property Type"
                      type="property_type"
                      isMobileView={false}
                      filters={filters}
                      setFilters={setFilters}
                      handleSearchHotel={handleSearchHotel}
                    />
                  </div>
                </div>
                <div className="mainWrap col">

                  {showMap &&
                    <MapView
                      hideMapView={hideMapView}
                      mobileMap={mobileMap}
                    />
                  }

                  {!showMap &&
                    <>
                      <div
                        className="rightInfo d-flex justify-content-between  align-items-center"
                      >
                        <h3 className="whiteText">
                          {newHotelSearchData?.paging?.total_records} hotels found in {payload?.city_name}
                        </h3>
                        <div className="sortWrapper row justify-content-between no-gutters align-items-center">
                          <SortBy
                            isMobileView={mobileFilter}
                            payload={payload}
                            handleSearchHotel={handleSearchHotel}
                          />
                        </div>
                      </div>

                      <FilterDisplay
                        filters={filters}
                        handleSearchHotel={handleSearchHotel}
                      />

                      {newHotelSearchData?.hotels?.map((item, index) => {
                        if (index === 4) {
                          return (
                            <Fragment key={`${item.id}_hotel`}>
                              <SingleHotelCard
                                hotel={item}
                                currencyCode={newHotelSearchData?.currency.isoCode}
                                currencySymbol={newHotelSearchData.currency?.symbol_native}
                              />
                              {user?.type_id === process.env.REACT_APP_FREMIUM_USER_TYPE_ID && (
                                <HotelOfferCard />
                              )}
                            </Fragment>
                          );
                        }

                        return (
                          <SingleHotelCard
                            key={index}
                            hotel={item}
                            currencyCode={newHotelSearchData?.currency.isoCode}
                            currencySymbol={newHotelSearchData.currency?.symbol_native}
                          />
                        );
                      })}

                      {
                        (newHotelSearchData?.hotels?.length === 0 || !newHotelSearchData?.hotels) &&
                        <div className="not-found">
                          <h2>No Hotels Found</h2>
                        </div>
                      }

                      <div className="paginationWrapper">
                        <Pagination
                          onPageChange={handlePageChange}
                          pageCount={newHotelSearchData?.paging?.total_pages}
                        // forcePage={payload?.paging?.page_number + 1}
                        />
                        <ResultsOnPage
                          className="results hide-for-mobile"
                          callback={handlePageChange}
                          defaultValue={payload?.paging?.per_page}
                        />
                      </div>
                    </>
                  }

                </div>
              </div>
            </div>
          </Scrollbars>
        </section>
        <FooterWithMediaIcon
          className="white-icon"
          iconClassName="icon-white"
        />
      </div>
      <>
        <div className="saver-popup popup">
          <div className="popup-content">
            <button className="icon-close"></button>
            <div className="heading">
              <h1 className="super-heading">uper Saver</h1>
            </div>
            <div className="popup-text">
              <h3>Congratulations!!</h3>
              <p>
                <strong>Upgrade to Premium</strong> before booking this Hotel,
                and Your Membership will be <strong>FREE.</strong>
              </p>
              <p>
                Based on the <strong> Member Savings</strong> of this Booking,
                you would <strong>recoup</strong> your Premium Membership{' '}
                <strong>immediately </strong>
                {''} and you could <strong>save even more </strong> on future
                Bokkings.
              </p>
              <p>
                Don&apos;t miss out on <strong>Massive Savings </strong> and the{' '}
                <strong>Chance</strong> to make your Travel Bookings{' '}
                <strong>more affordable than ever.</strong>
              </p>
            </div>
            <div className="popup-footer">
              <button className="btn"> UPGRADE</button>
              <button className="btn">OK</button>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}


export default HotelSearchResult;
