import React, { useEffect, useState, useRef } from 'react';
import { hotelDetailMockData } from '../../Data/hotelMockData';
import HotelDetailsTabs from './HotelDetailsTabs.jsx';
import HotelDetailCard from './HotelDetailCard/HotelDetailCard.jsx';
import Overview from './Overview/Overview';
import Rooms from './Rooms/Index.Rooms.jsx';
import Amenities from './Amenities/Amenities';
import PropertiesDetails from './PropertyDetails/PropertiesDetails';
import Reviews from './Reviews/Reviews';
import { useParams, NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import HeaderNavbar from '../../Component/HeaderNavbar';
import FooterWithMediaIcon from '../../Component/FooterWithMediaIcon';
import HotelWidget from '../newHotelSearchResult/hotelComponents/NewHotelWidget.jsx';
import { Scrollbars } from 'react-custom-scrollbars';
import ExpireRate from '../../Component/ExpireRate/ExpireRate';
import ExpireIcon from '../../Assets/img/icons/Search Expired Icon.png';
import Modal from 'react-modal';
import Button from '../../Component/FormComponent/Button';
import blueIcon from '../../Assets/img/close-blue-icon.png';
import { ReactComponent as Crown } from '../../Assets/img/icons/crown.svg';
import { ReactComponent as Arrow } from '../../Assets/img/icons/arrow-right.svg';
import { newGetHotelDetails, newGetHotelPackage, hotelActions } from '../../Stores/Actions';
import { getCurrencyAndLanguage } from '../../Util/util.js'

function HotelDetail() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = JSON.parse(window.localStorage.getItem('user'));
  const storedPayload = window.localStorage.getItem('hotelPayload')
    ? JSON.parse(window.localStorage.getItem('hotelPayload'))
    : JSON.parse(window.localStorage.getItem('mapPayload'));

  const hotelDetails = useSelector((state) => state.hotelReducer.hotelDetails);
  const hotelPackageDetails = useSelector((state) => state.hotelReducer.hotelPackageDetails);
  // const hotelDetailsError = useSelector((state) => state.hotelReducer.hotelDetailsError);
  const [galleryImages, setGalleryImages] = useState([]);
  const [hideFilter, setHideFilter] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [activeTab, setActiveTab] = useState('overview');

  useEffect(() => {
    if (hotelDetails?.hotel_data?.image) {
      var tempArray = []
      hotelDetails?.hotel_data?.image.map(image => {
        tempArray.push(image?.url);
      });
      setGalleryImages(tempArray)

    }
  }, [hotelDetails])

  const scrollRef = useRef(null);

  const { currency } = getCurrencyAndLanguage()

  const scrollToView = (idName) => {
    const offsetTop = document.getElementById(idName)?.offsetTop

    if (window.innerWidth > 991) {
      scrollRef.current.view.scroll({
        top: offsetTop,
        behavior: 'smooth',
      })
    } else {
      if (scrollRef?.current?.view?.scroll) {
        scrollRef.current.view.scroll({
          top: offsetTop,
          behavior: 'smooth',
        })
      }
      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      })
    }
    setActiveTab(idName);
  }
  const [sortKey, setSortKey] = useState('savings');

  useEffect(() => {

    if (window.localStorage.getItem('hotelPayload')) {
      // // dispatch(hotelActions.getHotelDetails(initialPayload, params?.hotel_id));
      dispatch(newGetHotelDetails(params?.hotel_id));
      const payload = {
        'region_id': storedPayload?.region_id,
        'city_name': storedPayload?.city_name,
        'search_type': storedPayload?.search_type,
        'check_in_date': storedPayload?.check_in_date,
        'check_out_date': storedPayload?.check_out_date,
        'rooms': storedPayload?.rooms,
        'currency': currency,
        'locale': storedPayload?.locale,
        'nationality': storedPayload?.nationality,
        'price_structure': storedPayload?.type_name,
        'hotel_id': params?.hotel_id,
        "sort": sortKey,
        // "sort": "savings"
      }
      dispatch(newGetHotelPackage(payload, params?.hotel_id));
    } else {
      navigate('/landing');
    }

    return () => {
      dispatch(hotelActions.resetHotelDetails());
    };
  }, []);


  //  call pacakge 

  const handelOnCahnge = (sortType) => {
    if (window.localStorage.getItem('hotelPayload')) {
      // // dispatch(hotelActions.getHotelDetails(initialPayload, params?.hotel_id));
      dispatch(newGetHotelDetails(params?.hotel_id));
      const payload = {
        'region_id': storedPayload?.region_id,
        'city_name': storedPayload?.city_name,
        'search_type': storedPayload?.search_type,
        'check_in_date': storedPayload?.check_in_date,
        'check_out_date': storedPayload?.check_out_date,
        'rooms': storedPayload?.rooms,
        'currency': currency,
        'locale': storedPayload?.locale,
        'nationality': storedPayload?.nationality,
        'price_structure': storedPayload?.type_name,
        'hotel_id': params?.hotel_id,
        "sort": sortType,
        // "sort": "savings"
      }
      dispatch(newGetHotelPackage(payload, params?.hotel_id));
    } else {
      navigate('/landing');
    }

  }

  function hideFunc() {
    setHideFilter((prev) => !prev);
  }

  const onComplete = () => {
    const initialPayload = {
      rooms: storedPayload?.rooms,
      region_id: storedPayload?.region_id,
      check_in_date: storedPayload?.check_in_date,
      check_out_date: storedPayload?.check_out_date,
      children_count: 0,
      city_name: storedPayload?.city_name,
      currency: currency,
      home: storedPayload?.home,
      hotel_id: params?.hotel_id,
      locale: storedPayload?.locale,
      onlyLocale: storedPayload?.onlyLocale,
      search_type: storedPayload?.search_type,
      source_market: storedPayload?.source_market,
      token: window.localStorage.getItem('accessToken'),
      type: storedPayload?.search_type,
      nationality: storedPayload?.nationality,
      use_wallets:
        user.type_id === process.env.REACT_APP_FREMIUM_USER_TYPE_ID ? 1 : 0,
    };
    dispatch(hotelActions.getHotelRooms(initialPayload, params?.hotel_id));
  }


  const Countdown = () => {
    return <>
      <ExpireRate
        className="expireTimer mobileViewDisplayNone"
        showIcon={true}
        text="Rate will expire"
        time={1200000}
        onComplete={onComplete}
      />
    </>
  }

  const CountDownForMobile = () => {
    return <>
      <ExpireRate
        className="expireTimer desktopViewDisplayNone"
        showIcon={true}
        text="Rate will expire"
        time={1200000}
        onComplete={onComplete}
      />
    </>
  }

  const redirectToUpgrade = () => {
    navigate('/membership-upgrade');
  }

  return (
    <div className="siteBg listingPage  detailPageWrap">
      <div className="pageWrap ">
        <div className="fixedHeader">
          <HeaderNavbar hideFunc={hideFunc} useBlueIcon={true} />
        </div>
        <section className="mainContent">
          {!hideFilter && (
            <HotelWidget
              isFromDetailPage={true}
            />
          )}

          {hotelDetails && hotelPackageDetails && (
            <Scrollbars
              ref={scrollRef}
              autoHeightMin={120}
              autoHeightMax={`calc(100%)`}
              className="pageInternalScroll"
            >
              <div className="container">
                <HotelDetailCard
                  {...hotelDetailMockData}
                  scrollToView={scrollToView}
                  name={hotelDetails ? hotelDetails?.hotel_data?.hotel_name : ''}
                  images={
                    galleryImages?.length > 0
                      ? galleryImages
                      : []
                  }
                  heroImage={
                    galleryImages.length > 0
                      ? galleryImages[0]
                      : ''
                  }
                  checkIn={hotelDetails?.hotel_data?.check_in_time}
                  checkOut={hotelDetails?.hotel_data?.check_out_time}
                  address={hotelDetails ? hotelDetails?.hotel_data?.address : ''}
                  contactNo={hotelDetails ? hotelDetails?.hotel_data?.phone : ''}
                  themes={
                    hotelDetails && hotelDetails?.hotel_data?.themes
                      ? hotelDetails?.hotel_data?.themes
                      : []
                  }
                  rating={hotelDetails ? hotelDetails?.hotel_data?.star_rating : 0}
                  latitude={
                    hotelDetails && hotelDetails?.hotel_data?.latitude
                      ? hotelDetails?.hotel_data?.latitude
                      : 0
                  }
                  longitude={
                    hotelDetails && hotelDetails?.hotel_data?.longitude
                      ? hotelDetails?.hotel_data?.longitude
                      : 0
                  }
                  currencySymbol={
                    hotelDetails &&
                    hotelDetails?.currency &&
                    hotelDetails?.currency?.symbol_native
                  }
                  currencyCode={
                    hotelDetails &&
                    hotelDetails?.currency &&
                    hotelDetails?.currency?.code
                  }
                />
                <div className="overviewTitle sectionTitle desktopViewDisplayNone">
                  <h2>Overview</h2>
                </div>
                <div className="tabsWrapper mobileViewDisplayNone">
                  <HotelDetailsTabs
                    activeTab={activeTab}
                    scrollToView={scrollToView}
                    hasReviews={hotelDetails?.hotel_data?.reviews?.review_count}
                    hasAminites={hotelDetails?.hotel_data?.facilities}
                  />
                </div>
                <Overview
                  reviews={hotelDetails?.hotel_data?.reviews ? hotelDetails?.hotel_data?.reviews : {}}
                  scrollToView={scrollToView}
                  amenities={
                    hotelDetails?.hotel_data?.amenities ? hotelDetails?.hotel_data?.amenities : []
                  }
                />

                <Rooms
                  Countdown={<Countdown />}
                  CountDownForMobile={<CountDownForMobile />}
                  CountdownStatus={null}
                  hotel_id={params?.hotel_id}
                  showUpgradeModal={showUpgradeModal}
                  currencySymbol={
                    hotelPackageDetails &&
                    hotelPackageDetails?.currency &&
                    hotelPackageDetails?.currency?.symbol_native
                  }
                  currencyCode={
                    hotelPackageDetails &&
                    hotelPackageDetails?.currency &&
                    hotelPackageDetails?.currency?.isoCode
                  }
                  setShowUpgradeModal={setShowUpgradeModal}
                  sortKey={sortKey}
                  setSortKey={setSortKey}
                  handelOnCahnge={handelOnCahnge}
                />

                <Modal
                  isOpen={showUpgradeModal}
                  ariaHideApp={false}
                  className={`popup upgradePremiumModal ${true ? 'popup-open' : ''} `}
                >
                  <div className="popup-content">
                    <div className="popup-title">
                      <Button
                        onClick={() => { setShowUpgradeModal(false) }}
                        className="btn close"
                        imageSrc={blueIcon}
                      />
                    </div>
                    <div className="content-box">
                      <Crown />
                      <p>Upgrade to <strong>premium</strong> to unlock this room rate</p>
                      <button type="button" className="premiumUpgradeBtn" onClick={redirectToUpgrade}>
                        <span className="wrapper">Upgrade to  <strong>Premium</strong> <span><Arrow /></span></span>
                      </button>
                      <button className="type-anchor" type="button" onClick={() => { setShowUpgradeModal(false) }}>Skip for now</button>
                    </div>
                  </div>
                </Modal>

                <Amenities
                  amenities={
                    hotelDetails && hotelDetails?.hotel_data?.facilities
                      ? hotelDetails?.hotel_data?.facilities
                      : []
                  }
                />
                <PropertiesDetails
                  description={
                    hotelDetails && hotelDetails?.hotel_data?.property_description
                      ? hotelDetails?.hotel_data?.property_description
                      : 'No Description...'
                  }
                  nearBy={
                    hotelDetails && hotelDetails?.hotel_data?.near_by_area
                      ? hotelDetails?.hotel_data?.near_by_area
                      : 'No NearBy...'
                  }
                />

                {(hotelDetails?.hotel_data?.reviews?.review_count) && (
                  <Reviews
                    {...hotelDetailMockData?.reviews}
                    reviews={hotelDetails ? hotelDetails?.hotel_data?.reviews : {}}
                  />
                )}
              </div>
            </Scrollbars>
          )}

          {/* Your Search Expired modal */}
          {!hotelDetails && !hotelPackageDetails && (
            <Scrollbars
              autoHeightMin={120}
              autoHeightMax={`calc(100%)`}
              className="pageInternalScroll"
            >
              <div className="container not-found-container">
                <div className="not-found-content">
                  <div className="not-found-content-wrap text-center">
                    <div className="expire-icon">
                      <img src={ExpireIcon} alt="icon" />
                    </div>
                    <h2>Your Search Expired.</h2>
                    <p>
                      The hotel you have selected is currently sold out, Please
                      try again!
                    </p>
                    <NavLink className="btn" to={`/hotel-search-result/`}>
                      Search Again
                    </NavLink>
                  </div>
                </div>
              </div>
            </Scrollbars>
          )}
        </section>
        <FooterWithMediaIcon
          className="white-icon"
          iconClassName="icon-white"
        />
      </div>
    </div>
  );
}

export default HotelDetail;
