
import { isEmpty } from "lodash";
import { Formik } from "formik";
import { carsActions, commonActions } from "../../Stores/Actions";
import { Link, useNavigate } from "react-router-dom";
import { Fragment, useEffect, useState,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CarCheckoutValidation } from "../../Component/Validation/CarCheckoutValidation";
import Scrollbars from "react-custom-scrollbars";
import { convertTime24To12, handleMaskedInput } from "../../Util/util";

import {
  countries,
  airlineOption,
  cardTypeOptions,
  checkoutCountries,
} from "../../Data/static.service";

import YesNo from "./YesNo";
import StartToEnd from "./StartToEnd";
import MobileHide from "./MobileHide";
import DriverInfo from "./DriverInfo";
import CarInputField from "./CarInputField";
import CarDetailsInfo from "./CarDetailsInfo";
import HeaderNavbar from "../../Component/HeaderNavbar";
import carCompany from "../../Assets/img/carCompany.png";
import SelectFieldOption from "../../Component/FormComponent/SelectFieldOption.jsx";
import SimpleInputField from "../../Component/FormComponent/SimpleInputField";
import FooterWtihMediaIcon from "../../Component/FooterWithMediaIcon";

import creditCardImage from "../../Assets/img/credit-card.png";
import carImage from "../../Assets/img/car5.png";
import moment from "moment";


const titleOption = [
  { value: "Mr.", label: "Mr." },
  { value: "Mrs.", label: "Mrs." },
  { value: "Ms.", label: "Ms." },
  { value: "Neutral.", label: "Neutral." },
];

function CarsCheckout() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const formikRef = useRef();
  const [nameOfStates, setNameOfStates] = useState([]);
  const [toggleModal, setToggleModal] = useState(false);
  const [insuranceCharges, setInsuranceCharges] = useState(0);
  const { carsContractData } = useSelector((state) => state.carsReducer);

  const showModal = () => {
    setToggleModal(true);
  };

  const hideModal = () => {
    setToggleModal(false);
  };

  const {
    image,
    similar,
    name,
    features,
    isUnlimitedMiles,
    moreDetails,
    carCompanyLogo,
  } =
    window.localStorage.getItem("selectedCarsDetails") &&
    JSON.parse(window.localStorage.getItem("selectedCarsDetails"));

  const currentGeoLocation =
    window.localStorage.getItem("currentGeoLocation") &&
    JSON.parse(window.localStorage.getItem("currentGeoLocation"));

  const isUS = currentGeoLocation === "US";
  const dateFormat = isUS ? "MM/DD/YYYY" : "DD/MM/YYYY";

  const pickUpTimeInfo =
    isUS && !isEmpty(carsContractData) && carsContractData?.pickup_time
      ? `${convertTime24To12(carsContractData?.pickup_time)}`
      : `${carsContractData?.pickup_time}`;

  const dropOffTimeInfo =
    isUS && !isEmpty(carsContractData) && carsContractData?.pickup_time
      ? `${convertTime24To12(carsContractData?.pickup_time)}`
      : `${carsContractData?.pickup_time}`;

  const LocalUser = JSON.parse(window.localStorage.getItem("user"));


  const initialValues = {
    title: LocalUser.title || "",
    firstName: LocalUser?.first_name || "",
    lastName: LocalUser?.last_name || "",
    email: LocalUser?.email || "",
    phoneNumber: LocalUser?.phone || "",
    nationality: "",
    addressLine1: LocalUser?.user_address?.address_line_1 || "",
    addressLine2: "",
    city: LocalUser?.user_address?.city || "",
    state: "",
    zipCode: LocalUser?.user_address?.zip || "",
    country: LocalUser?.user_address?.country || "",
    airline: "",
    flightNumber: "",

    cardType: "",
    cardHolderName: "",
    cardNumber: "",
    card_mmyy: "",
    securityCode: "",

    checkBox1: false,
    checkBox2: false,
    yesNo: "No",
  };

  const countryOption = countries.map((ele) => {
    return { value: ele.sortname, label: ele.name, id: ele.id };
  });

  const currencyAndLanguage =
    (window.localStorage.getItem("currencyAndLanguage") &&
      JSON.parse(window.localStorage.getItem("currencyAndLanguage"))) ||
    {};
  const currencySymbol =
    currencyAndLanguage?.currencyLanguageReducer?.data?.currency?.symbol || "$";


  const numberCustomChange = (formikProps, field, value) => {
    if (field === "phoneNumber" || "cardNumber" || "securityCode") {
      if (field === "cardNumber" && value.length > 16) {
        return;
      }
      if (field === "securityCode" && value.length > 3) {
        return;
      }

      let temp = Number(value);
      if (temp || value === "") {
        formikProps.setFieldValue(field, value);
      }
    }
  };

  const handleValidateZip = (e, formikProps) => {
    formikProps.setFieldValue("zipCode", e.target.value);
    if (e.target.value.length >= 4) {
      dispatch(carsActions.validateZip(e.target.value))
        .then((res) => res.data)
        .then((zipData) => {
          if (zipData) {
            checkoutCountries.map((ele) => {
              if (ele["alpha-2"] == zipData.country_short_name) {
                formikProps.setFieldValue("nationality", ele["alpha-2"]);
                formikProps.setFieldValue("country", ele["alpha-2"]);
                formikProps.setFieldValue("city", zipData.city_long_name);

                setNameOfStates(
                  checkoutCountries
                    .find(
                      (ele) => ele["alpha-2"] === zipData.country_short_name
                    )
                    .states.map((ele) => ({
                      value: ele.code,
                      label: ele.name,
                    }))
                );

                formikProps.setFieldValue("state", zipData.short_name);
              }
            });
          }
        });
    }
  };

  const handleCustomChange = (formikProps, field, value) => {
    if (field === "nationality") {
      setNameOfStates(
        checkoutCountries
          .find((ele) => ele["alpha-2"] === value)
          .states.map((ele) => ({ value: ele.code, label: ele.name }))
      );
    }

    if (field == "yesNo") {
      setInsuranceCharges(
        value == "Yes" ? carsContractData?.cdw?.total?.all : 0
      );
    }
    formikProps.setFieldValue(field, value);
  };

  useEffect(() => {
    dispatch(commonActions.loaderText("Getting Details..."));

    const dataPayload =
      window.localStorage.getItem("carCheckOutPayload") &&
      JSON.parse(window.localStorage.getItem("carCheckOutPayload"));

    dispatch(carsActions.getCarContract(dataPayload));
  }, []);

  const handleSubmit = (value) => {
    const formData = new FormData();
    let dataPayload = {
      format: "json",
      refid: process.env.REACT_APP_BASE_CAR_REF_ID,
      api_key: process.env.REACT_APP_CARS_AUTO_DATA_API_KEY,
    };

    formData.append("ppn_bundle", carsContractData?.car_book_bundle);
    // formData.append("cust_email", value.email);
    formData.append("cust_email", "bookings@rewardscompany.com");
    formData.append("cust_street", value.addressLine1);
    formData.append("street2", value.addressLine2);
    formData.append("cust_city", value.city);
    formData.append("cust_state_code", value.state);
    formData.append("cust_country_code", value.country);
    formData.append("cust_post_code", value.zipCode);
    formData.append("cust_phone", value.phoneNumber);
    formData.append("pi_email", value.email);
    formData.append("driver_first_name", value.firstName);
    formData.append("driver_last_name", value.lastName);
    formData.append("sms_message_flag", !value.checkBox1);
    formData.append("sms_opt_in_out", value.checkBox2 === true ? 0 : 1);

    // if (value.yesNo === "Yes") {
      formData.append("cc_type", value.cardType);
      formData.append("cc_mo", value.card_mmyy.slice(0, 2));
      formData.append("cc_yr", value.card_mmyy.slice(3));
      formData.append("cc_code", value.securityCode);
      formData.append("cardholder_first_name", value.cardHolderName);
      formData.append("cdw_selected", 1);
      formData.append("cust_first_name", value.firstName);
      formData.append("cc_number", value.cardNumber);
    // }

    window.localStorage.setItem(
      "carsCheckoutPayload",
      JSON.stringify({ dataPayload, formData })
    );
// car booking api call on pricline
    dispatch(carsActions.bookCarAction(dataPayload, formData)).then((res) => {
      if (res?.status === 200) {
          const  payload = {
            bookingId: res?.data?.getCarBookRequest?.results?.booking_id.toString() ,
            // email: res?.data?.getCarBookRequest?.results?.email,
            // email: value.email,
            email:"bookings@rewardscompany.com",
            bookingStatus: res?.data?.getCarBookRequest?.results?.booking_status ,
            iternanry : res.data.getCarBookRequest.results
          }  
          // car booking api call for backoffice 
          dispatch(carsActions.createCarBooking(payload)).then((res) => {
            if(res?.status === 200){
              navigate(`/cars-booking-confirmation/${res?.data?.id}`);
            }
          })
      }
    })
  };

  const damageProtectionList = [
    "Pays up to $75,000 with $0 deductible",
    "Receive primary coverage without having to go through your insurance",
    "Covers damage to rental due to collision, theft, vandalism, fire or hail",
    "Cancel for free up until your requested pick-up time",
  ];

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: carsContractData?.pricing?.display_currency ?? "USD",
  });

  const renderForm = (formikProps) => {
    const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
      formikProps;

    return (
      <form onSubmit={handleSubmit}>
        <DriverInfo
          showModal={showModal}
          formikProps={formikProps}
          handleCustomChange={handleCustomChange}
          title="title"
          titleOption={titleOption}
          firstName="firstName"
          lastName="lastName"
          email="email"
          phoneNumber="phoneNumber"
          numberCustomChange={numberCustomChange}
          nationality="nationality"
          addressLine1="addressLine1"
          addressLine2="addressLine2"
          city="city"
          state="state"
          zipCode="zipCode"
          country="country"
          countryOption={countryOption}
          checkBox1="checkBox1"
          checkBox2="checkBox2"
          nameOfStates={nameOfStates}
          handleValidateZip={handleValidateZip}
        />
        <div className="card2 collision-card">
          <h4 className="boxTitle sheildTitle">
            <span className="icon-verified-user"></span> Add Collision Damage
            Protection
          </h4>

          <div className="listStyleBg">
            <ul>
              {damageProtectionList.map((list, index) => {
                return (
                  <li key={index}>
                    <span className="icon-tick2"></span>
                    {list}
                  </li>
                );
              })}
            </ul>
          </div>

          <YesNo
            formikProps={formikProps}
            handleCustomChange={handleCustomChange}
            yesNo="yesNo"
            name="yesNo"
          />

          <div className="borderTopDivider">
            <span className="icon-info"></span> COVID-19: Coverage Limitations
            And Special Accommodations
          </div>

          <div className="copyStyle copyMB">
            Recommended/offered/sold by Allianz Global Assistance. Underwriter
            by Jefferson Insurance Company. Plan incl. insurance & assistance
            services. Terms and exclusions (incl. for pre-existing conditions)
            apply. <a href="https://www.priceline.com/home" target="_blank" rel="noreferrer">Plan & Pricing details, disclosures.</a>
          </div>
        </div>

        {/* card info */}
        {/* {values.yesNo === "Yes" && ( */}
          <div className="card2 flight-info-card">
            <h4 className="boxTitle">Card Information</h4>

            <div className="col3 cardDetailsBox flightInfoOption">
              <SelectFieldOption
                name="cardType"
                label="Select Card Type"
                label2="Select Card Type"
                placeholder="Card Type"
                value={values.cardType}
                error={errors.cardType}
                touched={touched.cardType}
                onChange={handleCustomChange}
                onBlur={handleBlur}
                className="col selectStyle"
                options={cardTypeOptions ? cardTypeOptions : []}
                formikProps={formikProps}
              />

              <CarInputField
                name="cardHolderName"
                type="text"
                label="Card Holder Name"
                placeholder="Card Holder Name"
                value={values.cardHolderName}
                error={errors.cardHolderName}
                touched={touched.cardHolderName}
                onChange={handleChange}
                onBlur={handleBlur}
                className="cardDetails"
              />
            </div>
            {/* card details */}
            <div className="col formGroup cardDetails">
              <h5>Enter Card Details</h5>
              <div className="cardWrapper">
                <div className="cardImage">
                  <img src={creditCardImage} alt="credit-card" />
                </div>
                <SimpleInputField
                  name="cardNumber"
                  placeholder={"Card Number"}
                  type="text"
                  value={values.cardNumber}
                  touched={touched.cardNumber}
                  cardError={errors.cardNumber}
                  onChange={(e) => {
                    numberCustomChange(
                      formikProps,
                      "cardNumber",
                      e.target.value
                    );
                  }}
                  formikProps={formikProps}
                  onBlur={handleBlur}
                />
                <SimpleInputField
                  name={"card_mmyy"}
                  type="text"
                  placeholder={"MM/YY"}
                  value={values.card_mmyy}
                  cardError={errors.card_mmyy}
                  touched={touched.card_mmyy}
                  onChange={(e) => {
                    const maskedValue = handleMaskedInput(e.target.value);
                    if (maskedValue.error) {
                      formikProps.setErrors({ mmyy: "Invalid MM/YY" });
                    }
                    if (e.target.value.length > 5) return
                    formikProps.setFieldValue("card_mmyy", maskedValue.value);
                  }}

                  onBlur={handleBlur}
                  formikProps={formikProps}
                  className="mm-yy"
                />
                <SimpleInputField
                  name={"securityCode"}
                  type="text"
                  placeholder={"CVV"}
                  value={values.securityCode}
                  cardError={errors.securityCode}
                  touched={touched.securityCode}
                  onChange={(e) => {
                    numberCustomChange(
                      formikProps,
                      "securityCode",
                      e.target.value
                    );
                  }}
                  formikProps={formikProps}
                  onBlur={handleBlur}
                  className="cvv"
                />
              </div>
            </div>
          </div>
        {/* )} */}
        {/* card info */}

        <div className="card2 flight-info-card">
          <h4 className="boxTitle">Flight Information (optional)</h4>

          <div className="col3 cardDetailsBox flightInfoOption">
            <SelectFieldOption
              name="airline"
              label="Select Airline"
              label2="Select Airline"
              placeholder="Airline"
              value={values.airline}
              error={errors.airline}
              touched={touched.airline}
              onChange={handleCustomChange}
              onBlur={handleBlur}
              className="col selectStyle"
              options={airlineOption}
              formikProps={formikProps}
            />

            <CarInputField
              name="flightNumber"
              type="text"
              label="Flight Number"
              placeholder="Flight Number"
              value={values.flightNumber}
              error={errors.flightNumber}
              touched={touched.flightNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              className="cardDetails"
            />
          </div>
        </div>
        <div className="card2 freeCancellationBox dflex">
          <div className="cancellationLabel">
            {moreDetails?.carPriceDetails?.freeCancellation && (
              <>
                <span className="icon-tick"> </span>
                Free Cancellation
              </>
            )}
          </div>

          <button 
          className="btn"
           type="submit">
            Reserve Now
          </button>
        </div>
      </form>
    );
  };

  return (
    <div className="siteBg checkOutPage carcheckOutPage checkoutPageWrap">
      <div className="pageWrap pageWrapIxnner">
        <div className="fixedHeader">
          <HeaderNavbar useBlueIcon={true}/>
        </div>

        <section className="mainContent carCheckout">
          <Scrollbars
            autoHide
            autoHeight={true}
            autoHeightMin={175}
            autoHeightMax={`calc(100%)`}
            className="pageInternalScroll"
          >
            <div className="container dflex pageInternalScrollTopPadding">
              <div className="mainWrap">
                <Formik
                  onSubmit={handleSubmit}
                  initialValues={initialValues}
                  validationSchema={CarCheckoutValidation}
                  enableReinitialize={true}
                  innerRef={formikRef}
                >
                  {renderForm}
                </Formik>

                <div className="card2 impInfoBox mobileHide">
                  <h4 className="boxTitle">Important Information</h4>

                  {carsContractData?.important_information &&
                    Object.keys(carsContractData?.important_information).map(
                      (ele) => {
                        if (
                          carsContractData.important_information[ele][
                          "title"
                          ] === "Payment options"
                        ) {
                          return (
                            <p className="book-small-paragraph" key={ele}>
                              <strong className="ml-0">
                                {
                                  carsContractData.important_information[ele][
                                  "title"
                                  ]
                                }
                              </strong>

                              {
                                carsContractData.important_information[ele][
                                  "text"
                                ].split("#")[0]
                              }
                              <button
                                className="show-modal"
                                onClick={showModal}
                              >
                                Payment Method Options.
                              </button>
                            </p>
                          );
                        } else if (
                          carsContractData.important_information[ele][
                            "text"
                          ].startsWith("Please see location specific")
                        ) {
                          return (
                            <p className="book-small-paragraph" key={ele}>
                              <strong className="ml-0">
                                {
                                  carsContractData.important_information[ele][
                                  "title"
                                  ]
                                }
                              </strong>
                              {
                                carsContractData.important_information[ele][
                                  "text"
                                ].split("#")[0]
                              }
                              <button
                                className="show-modal"
                                onClick={showModal}
                              >
                                rental policy and rules information.
                              </button>
                            </p>
                          );
                        } else if (
                          carsContractData.important_information[ele][
                            "text"
                          ].startsWith("You hereby agree to abide by our")
                        ) {
                          return (
                            <p className="book-small-paragraph" key={ele}>
                              <strong className="ml-0">
                                {
                                  carsContractData.important_information[ele][
                                  "title"
                                  ]
                                }
                              </strong>
                              {
                                carsContractData.important_information[ele][
                                  "text"
                                ].split("#")[0]
                              }
                              <a target="_blank" href="/terms-conditions">
                                terms and conditions
                              </a>{" "}
                              and{" "}
                              <a target="_blank" href="/privacy-policy">
                                privacy policy.
                              </a>
                            </p>
                          );
                        } else {
                          return (
                            <p className="book-small-paragraph" key={ele}>
                              <strong className="ml-0">
                                {
                                  carsContractData.important_information[ele][
                                  "title"
                                  ]
                                }
                              </strong>
                              {
                                carsContractData.important_information[ele][
                                "text"
                                ]
                              }
                            </p>
                          );
                        }
                      }
                    )}
                </div>
              </div>

              {/* Right side content */}

              <div className="sideBar carCheckoutSidebar">
                <div className="card2">
                  <div className="cardImg">
                    <div className="dflex carImgInfo">
                      <img
                        src={!carCompanyLogo ? carCompany : carCompanyLogo}
                        alt="company logo"
                      />
                      <span className="imageNote">
                        Actual car may vary from the one shown
                      </span>
                    </div>

                    <div className="carImgWrap">
                      <img src={!image ? carImage : image} alt="selected-car" />
                    </div>
                  </div>

                  <div className="sideBarInSpace">
                    <h4 className="contentTitle">{similar}</h4>
                    <h5 className="contentSubTitle">{name}</h5>

                    <div className="mobileHide">
                      {moreDetails?.people &&
                        <CarDetailsInfo
                          iconName="icon-group font12"
                          detail1="People:"
                          detail2={`${moreDetails?.people} people`}
                          spanClassName="mobileHide"
                        />
                      }
                      
                      {moreDetails?.suitcases &&
                        <div className="dflex mb-5">
                          <span className="font14 lightText">
                            <span className="icon-work font12">
                              <span className="path1"></span>
                            </span>{" "}
                            <span className="mobileHide">Suitcases:</span>
                          </span>

                          <span className="font14 darkText">
                            {moreDetails?.suitcases} suitcases
                          </span>
                        </div>
                      }
                      {features["Auto Transmission"] ===
                        "Auto Transmission" && (
                          <CarDetailsInfo
                            iconName="icon-transmission font12"
                            detail1="Transmission:"
                            detail2="Automatic"
                            spanClassName="mobileHide"
                          />
                        )}

                      {features["Air Conditioning"] === "Air Conditioning" && (
                        <CarDetailsInfo
                          iconName="icon-icon3 font12"
                          detail1={"Air Conditioning:"}
                          detail2={"AC Included"}
                          spanClassName="mobileHide"
                        />
                      )}

                      {isUnlimitedMiles && (
                        <CarDetailsInfo
                          iconName={"icon-speedometer font12"}
                          detail1={"Mileage:"}
                          detail2={"Unlimited"}
                          spanClassName="mobileHide"
                        />
                      )}
                    </div>

                    <div className="startToEnd">
                      <StartToEnd
                        className={"startLocation"}
                        dateNTime={`${moment(
                          carsContractData?.dropoff_date
                        ).format(dateFormat)} ${pickUpTimeInfo}`}
                        content={moreDetails?.startAdd}
                      />

                      <StartToEnd
                        className={"endLocation"}
                        dateNTime={`${moment(
                          carsContractData?.pickup_date
                        ).format(dateFormat)} ${dropOffTimeInfo}`}
                        content={
                          moreDetails?.startAdd === moreDetails?.endAdd
                            ? "Same as Pick-up"
                            : moreDetails?.endAdd
                        }
                      />
                    </div>

                    <div className="mobileCarBtmBox mobileShow">
                      <div className="dflex">
                        <div>
                          {carsContractData && moreDetails && (
                            <div className="totalAmtMobile">
                              {formatter.format(parseFloat(carsContractData?.pricing?.display_total).toFixed(2))}
                            </div>
                          )}

                          {insuranceCharges !== 0 && (
                            <div className={`dflex`}>
                              <div className="chargesWrap">
                                <label className="labelStyle">{"Amount Due Now: "}</label>
                                <label className="labelStyle"><small>(Insurance charges)</small></label>
                              </div>
                              <span className="rightValue"> {formatter.format(insuranceCharges)}</span>
                            </div>
                          )}

                          <div className="cancellationLabel d-flex align-items-center">
                            {moreDetails?.carPriceDetails?.freeCancellation && (
                              <>
                                <span className="icon-tick"> </span>
                                <span>Free Cancellation</span>
                              </>
                            )}
                          </div>
                        </div>

                        <div>
                          <button
                            onClick={handleSubmit}
                            className="btn"
                            type="submit"
                          >
                            Reserve Now
                          </button>
                        </div>
                      </div>

                      <p className="boxLightText">
                        This is an estimate of your rental car reservation
                        total. Prices may vary depending on special requests and
                        items purchased at the counter.
                      </p>
                    </div>
                  </div>

                  <div className="shadowBox mobileHide">
                    <MobileHide
                      detail={`Base Fare (${moreDetails?.carPriceDetails?.days} day):`}
                      price={`${currencySymbol}${carsContractData?.pricing?.breakdown_data?.breakdown_0?.display_total}`}
                    />

                    <MobileHide
                      detail={"Taxes & Fees:"}

                      price={`${currencySymbol}${carsContractData?.pricing?.taxes_and_fees?.display_total}`}
                    />



                    <MobileHide
                      detail={"Amount Due at Pick-up:"}
                      price={`${currencySymbol}${carsContractData?.pricing?.display_total}`}
                      className={"totalValueBox"}
                    />

                    {insuranceCharges !== 0 && (
                      <div className={`dflex`}>
                        <div className="chargesWrap">
                          <label className="labelStyle">{"Amount Due Now:"}</label>
                          <label className="labelStyle"><small>(Insurance charges)</small></label>
                        </div>
                        <span className="rightValue">{formatter.format(insuranceCharges)}</span>
                      </div>
                    )}

                    <p className="centerAlign font12 boxLightText">
                      This is an estimate of your rental car reservation total.
                      Prices may vary depending on special requests and items
                      purchased at the counter.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Scrollbars>
        </section>

        {toggleModal && (
          <div className="modal-wrap popup popup-open modal-policy">
            <div className="modal-content popup-content">
              <div className="popup-title">
                <h3 className="modal-title">Policy Details</h3>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={hideModal}
                >
                  <i className="icon-close"></i>
                </button>
              </div>

              {carsContractData && (
                <div className="model-content">
                  {Object.keys(carsContractData.car_policy_data).map((ele) => {
                    return (
                      <Fragment key={ele}>
                        <h4>{carsContractData.car_policy_data[ele].title}</h4>
                        <div
                          className="model-text"
                          dangerouslySetInnerHTML={{
                            __html:
                              carsContractData.car_policy_data[ele].description,
                          }}
                        />
                      </Fragment>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}

        <FooterWtihMediaIcon
          className={"white-icon "}
          iconClassName={"icon-white"}
        />
      </div>
    </div>
  );
}

export default CarsCheckout;
