import React from "react";
import InfoBox from "./InfoBox";
import DateNTime from "./DateNTime";
import PriceDetails from "./PriceDetails";

function FllightDetails({ flights, status, key }) {
  const flightTripType = (flightType, sideBar = false) => {
    if (flightType === "round-trip") {
      return !sideBar ? "Round trip flight" : "Round Trip";
    } else if (flightType === "one-way") {
      return !sideBar ? "One way flight" : "One Way";
    } else if (flightType === "Multi-City") {
      return !sideBar ? "Multi city flight" : "Multi City";
    }
  };

  return (
    <div className="card2">
      <div className="dflex alignFlexStart">
        <div className="iconWrapMain">
          <div className="iconWrap">
            <span className="icon-flight">
              <span className="path1"></span>
              <span className="path2"></span>
            </span>
          </div>

          <span className="booking-status">{flights?.bookingStatus}</span>
        </div>

        <div className="cardContent">
          <h3 className="cartTitle">
            {flights &&
              `${flights?.flightSegments?.[0]?.flightLegs?.[0]?.departure_city_name}(
                ${flights?.flightSegments?.[0]?.flightLegs?.[0]?.departure_airport_code}
              ) `}

            <span className="arrow">
              {flights?.flightType === "one-way" ? (
                <svg
                  className=""
                  xmlns="http://www.w3.org/2000/svg"
                  width="25.000000pt"
                  height="12.000000pt"
                  viewBox="0 0 14 7"
                  fill="#1d6ec9"
                >
                  <path
                    d="M10.5859 1L12.9859 3.50526L10.5859 6.01053"
                    stroke="#1d6ec9"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1 3.50781H13"
                    stroke="#1d6ec9"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  version="1.0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="25.000000pt"
                  height="12.000000pt"
                  viewBox="0 0 25.000000 12.000000"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <g
                    transform="translate(0.000000,12.000000) scale(0.100000,-0.100000)"
                    fill="#1d6ec9"
                    stroke="none"
                  >
                    <path
                      d="M30 85 l-23 -25 23 -25 c28 -29 51 -34 25 -5 -18 20 -17 20 70 20 87
                        0 88 0 70 -20 -26 -29 -3 -24 25 5 l23 25 -23 25 c-28 29 -51 34 -25 5 18 -20
                        17 -20 -70 -20 -87 0 -88 0 -70 20 26 29 3 24 -25 -5z"
                    />
                  </g>
                </svg>
              )}
            </span>
            {` ${
              flights?.flightSegments?.[0]?.flightLegs?.[
                flights?.flightSegments?.[0]?.flightLegs?.length - 1
              ]?.arrival_city_name
            }(
                ${
                  flights?.flightSegments?.[0]?.flightLegs?.[
                    flights?.flightSegments?.[0]?.flightLegs?.length - 1
                  ]?.arrival_country_code
                }
              )`}
          </h3>

          <div className="dflex stretch">
            <div className="innerCnt">
              <div className="topSection dflex justifyStart copyText">
                <span className="arrow mobileShow">
                  <svg
                    version="1.0"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="12.000000pt"
                    viewBox="0 0 25.000000 12.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,12.000000) scale(0.100000,-0.100000)"
                      fill="#7e94a8"
                      stroke="none"
                    >
                      <path
                        d="M30 85 l-23 -25 23 -25 c28 -29 51 -34 25 -5 -18 20 -17 20 70 20 87
                        0 88 0 70 -20 -26 -29 -3 -24 25 5 l23 25 -23 25 c-28 29 -51 34 -25 5 18 -20
                        17 -20 -70 -20 -87 0 -88 0 -70 20 26 29 3 24 -25 -5z"
                      />
                    </g>
                  </svg>
                </span>
                {flightTripType(flights?.flightType)}
              </div>

              {flights?.flightSegments?.map((segment, index) => (
                <div key={index} className="flightStart">
                  <div className="smlText">
                    {index == 0 ? `Departure Flight: ` : `Arrival Flight: `}
                    {flights &&
                      segment?.flightLegs?.[segment?.flightLegs?.length - 1]
                        ?.operating_airline_name}
                  </div>

                  <div className="cardCol no-gutters">
                    <DateNTime
                      label={
                        segment?.flightLegs?.[segment?.flightLegs?.length - 1]
                          ?.departure_city_name
                      }
                      date={
                        segment?.flightLegs?.[segment?.flightLegs?.length - 1]
                          ?.departure_date_wd
                      }
                      time={
                        segment?.flightLegs?.[segment?.flightLegs?.length - 1]
                          ?.departure_time
                      }
                    />
                    <span className="icon-flight">
                      <span className="path1"></span>
                    </span>
                    <DateNTime
                      label={
                        segment?.flightLegs?.[segment?.flightLegs?.length - 1]
                          ?.arrival_city_name
                      }
                      date={
                        segment?.flightLegs?.[segment?.flightLegs?.length - 1]
                          ?.arrival_date_wd
                      }
                      time={
                        segment?.flightLegs?.[segment?.flightLegs?.length - 1]
                          ?.arrival_time
                      }
                    />
                  </div>
                </div>
              ))}

              {/* {flights?.flightType !== "one-way" && (
                <div className="flightEnd">
                  <div className="smlText">
                    Departure Flight :
                    {flights && flights[flights.length - 1]?.airline_name}
                  </div>
                  <div className="cardCol no-gutters">
                    <DateNTime
                      label={
                        flights?.flightSegments?.[0]?.flightLegs?.[
                          flights?.flightSegments?.[0]?.flightLegs?.length - 1
                        ]?.departure_city_name
                      }
                      date={
                        flights?.flightSegments?.[0]?.flightLegs?.[
                          flights?.flightSegments?.[0]?.flightLegs?.length - 1
                        ]?.departure_date_wd
                      }
                      time={
                        flights?.flightSegments?.[0]?.flightLegs?.[
                          flights?.flightSegments?.[0]?.flightLegs?.length - 1
                        ]?.departure_time
                      }
                    />
                    <span className="icon-flight">
                      <span className="path1"></span>
                    </span>
                    <DateNTime
                      label={
                        flights?.flightSegments?.[0]?.flightLegs?.[
                          flights?.flightSegments?.[0]?.flightLegs?.length - 1
                        ]?.arrival_city_name
                      }
                      date={
                        flights?.flightSegments?.[0]?.flightLegs?.[
                          flights?.flightSegments?.[0]?.flightLegs?.length - 1
                        ]?.arrival_date_wd
                      }
                      time={
                        flights?.flightSegments?.[0]?.flightLegs?.[
                          flights?.flightSegments?.[0]?.flightLegs?.length - 1
                        ]?.arrival_time
                      }
                    />
                  </div>
                </div>
              )} */}
            </div>

            <div className="infoBox">
              <div className="dflex mb-10 bookingIdWrap">
                <span className="font14 lightText">Booking ID:</span>
                <span className="font14 darkText">
                  {flights?.internalRefId}
                </span>
              </div>
              <div className="keyValueWrap">
                <div>
                  <InfoBox
                    divClassName={"mb-5"}
                    iconName="icon-group font12"
                    detail1={"People:"}
                    detail2={`${flights?.travellerDetails?.length}`}
                  />
                  <InfoBox
                    divClassName={"mb-5"}
                    iconName="icon-cabin"
                    detail1={"Cabin Class:"}
                    detail2={flights?.cabinClassName}
                  />
                  <InfoBox
                    iconName={"icon-flight font17"}
                    detail1="Flight:"
                    detail2={flightTripType(flights?.flightType, true)}
                  />
                </div>
                <PriceDetails
                  details={"Total:"}
                  // price={`${price}`}
                  text="View & Manage"
                  nextPage={`/flight-confirmation/${flights?.internalRefId}`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FllightDetails;
