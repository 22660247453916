/* eslint-disable react/no-unescaped-entities */
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { authActions } from "../../Stores/Actions";
import { useDispatch, useSelector } from "react-redux";

import logo from "../../Assets/img/logo.png";
import heart from "../../Assets/img/heart.svg";
import Button from "../../Component/FormComponent/Button";
import FaceBook from "../../Views/MarketingWebsite/FaceBook"
import TikTokScript from "../../Views/MarketingWebsite/TikTokScript"
import TaboolaPixel from "../MarketingWebsite/TaboolaPixel";

export const Thankyou = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  let { pageId } = useParams();
  const navigate = useNavigate();

  const { search, pathname } = useLocation();
  const getQueryParam = new URLSearchParams(search);
  const emailConfirmEmailToken = getQueryParam.get("token");

  const emailConfirmationResponse =
    window.localStorage.getItem("confirmEmailResponse") &&
    JSON.parse(window.localStorage.getItem("confirmEmailResponse"));

  const clearLocalStorage = () => {
    window.localStorage.removeItem("confirmEmailResponse");
  };

  const emailConfirmationError = useSelector(
    (state) => state.authReducer.confirmEmailError
  );

  useEffect(() => {
    window.localStorage.removeItem("accessToken");
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("lt");
  }, []);

  useEffect(() => {
    if (!emailConfirmEmailToken) {
      return;
    }
    dispatch(
      authActions.confirmEmailAddressAction(
        { token: emailConfirmEmailToken },
        navigate
      )
    );
  }, [pathname]);

  const premiumUpgrade = (
    <>
      <h1>Thank You for Upgrading to Tripello Premium!</h1>
      <p>We have sent you a confirmation email </p>
      <p>with your account details.</p>
      <br />
      <br />
      <Button
        onClick={() => {
          clearLocalStorage();
          history("/login");
        }}
        text="Login"
        className="btn"
      />
    </>
  );
  const premiumPlusUpgrade = (
    <>
      <h1>Thank You for Upgrading to Tripello Premium Plus!</h1>
      <p>We have sent you a confirmation email </p>
      <p>with your account details.</p>
      <br />
      <br />
      <Button
        onClick={() => {
          clearLocalStorage();
          history("/login");
        }}
        text="Login"
        className="btn"
      />
    </>
  );

  const RequestToConfirmEmail = (
    <>
      <h1>Thank You for Registering with Tripello!</h1>
      <p>Almost ready to Go. You'll receive an email to confirm your</p>
      <p>registered email in order to complete the Sign-Up and</p>
      <p>get access to the Tripello Platform.</p>
      <p>You will have 24 hours to activate your email.</p>
      <p className="font-weight-700">Please check your Spam and Junk Mail Folders.</p>
      <p>You can close this browser window now.</p>
      <br />
      <p>See You on the other side!</p>
    </>
  );

  const affiliateUpgrade = (
    <>
      <h1>Thank You for Upgrading to Tripello Affiliate!</h1>
      <p>We have sent you a confirmation email </p>
      <p>with your account details.</p>
      <br />
      <br />
      <Button
        onClick={() => {
          clearLocalStorage();
          history("/login");
        }}
        text="Login"
        className="btn"
      />
    </>
  );

  const paidUser = (
    <>
      <h1>Thank You for Registering with Tripello!</h1>

      <p>You are all set! You'll receive an email with your</p>
      <p>Account Details.</p>
      <br />
      <br />
      <Button
        onClick={() => {
          clearLocalStorage();
          history("/login");
        }}
        text="Login"
        className="btn"
      />
    </>
  );

  return (
    <>
      {/* Facebook start */}
      <FaceBook/>
      {/* Facebook end */}
      {/* TikTokScript start  */}
        <TikTokScript/>
      {/* TikTokScript end  */}

      {/* TaboolaPixel start */}
      <TaboolaPixel/>
      {/* TaboolaPixel end */}
      
      <div className="pageBg loginPageWrap thank-you-wrapper">
        <div className="pageLoginWrap siteBg min-height-100vh w-100">
          <div className="container w-100">
            <Link to="/" className="logo" onClick={clearLocalStorage}>
              <img src={logo} alt="Tripello" />
            </Link>

            <div className="boxBg">
              <div className="text-center">
                {pathname == "/user/confirmation" && emailConfirmationError ? (
                  <div>{emailConfirmationError}</div>
                ) : (
                  <>
                    <img src={heart} className="icon-heart" />
                    {!pageId && (
                      <>
                        {emailConfirmationResponse ? (
                          <>
                            <h1>
                              {" "}
                              Thank You for Confirming <br /> Your Email!
                            </h1>
                          </>
                        ) : (
                          <h1>Thank You for Registering with Tripello!</h1>
                        )}

                        {(pathname == "/user/confirmation" ||
                          pathname == "/thankyou") &&
                          emailConfirmationResponse?.success ? (
                          <>
                            <p>
                              You are all set! You'll receive an email with your
                            </p>
                            <p>Account Details.</p>
                          </>
                        ) : (
                          <>
                            <p>
                              Almost ready to Go. You'll receive an email to
                              confirm your
                            </p>
                            <p>
                              registered email in order to complete the Sign-Up
                              and
                            </p>
                            <p>get access to the Tripello Platform.</p>
                            <p>You will have 24 hours to activate your email.</p>
                            <p className="font-weight-700">Please check your Spam and Junk Mail Folders.</p>
                            <p>You can close this browser window now.</p>
                            <p className="mt-5">See You on the other side!</p>
                          </>
                        )}
                      </>
                    )}

                    {pageId == "premium-upgrade" && premiumUpgrade}
                    {pageId == "premium-plus-upgrade" && premiumPlusUpgrade}
                    {pageId == "paiduser" && paidUser}
                    {pageId == "affiliate-upgrade" && affiliateUpgrade}
                    {pageId == "request-to-confirm-email" &&
                      RequestToConfirmEmail}

                    <br />
                    <br />

                    {((pageId != "request-to-confirm-email" ||
                      pathname === "/user/confirmation") &&
                      pathname !== "/thankyou") ||
                      (emailConfirmationResponse?.success && (
                        <Button
                          onClick={() => {
                            clearLocalStorage();
                            history("/login");
                          }}
                          text="Login"
                          className="btn"
                        />
                      ))}
                  </>
                )}
              </div>
            </div>

            <p className="footCopy">
              Copyright © 2023 Tripello Inc. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
