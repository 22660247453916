import { serialize } from "../Util/util";
import http from "./http";

export const supportPageService = {
  getIssueListByCategory,
  sendIssueToSupportTeamService,
  getAutoCompletedData,
  sendIssueToAccountsettings,
};
async function sendIssueToSupportTeamService(payload) {
  const qs = serialize(payload);
  const res = await http.post(
    // `${process.env.REACT_APP_BASE_URL_USER}/api/v2/support/create-ticket`,
    `${process.env.REACT_APP_BASE_URL_BACKOFFICE_URL}/api/support/exestingReservation`,
    payload,
    {
      params: { use_multi_form_data: true },
    }
  );
  return res;
}

async function sendIssueToAccountsettings(payload) {
  const qs = serialize(payload);
  const res = await http.post(
    `${process.env.REACT_APP_BASE_URL_BACKOFFICE_URL}/api/support/accountsettings`,
    payload,
    {
      params: { use_multi_form_data: true },
    }
  );
  return res;
}

async function getIssueListByCategory(payload) {
  const res = await http
    // .get(`${process.env.REACT_APP_BASE_URL_USER}/api/v2/support/get-list?type=${payload.type}`);
    .get(
      `${process.env.REACT_APP_BASE_URL_BACKOFFICE_URL}/api/support/booking-query-list?vertical=${payload.type}`
    );
  return res;
}

async function getAutoCompletedData(payload) {
  const res = await http.get(
    `${process.env.REACT_APP_BASE_URL_USER}/api/v2/support/autocomplete?type=${payload.type}&term=${payload.term}`
  );
  return res;
}
