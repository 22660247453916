import React, { useState } from 'react';
import HotelDetails from './NewHotelDetails';
import HotelPriceSection from './NewHotelPriceSection';
import { Link } from 'react-router-dom';
import placeholderImage from '../../../Assets/img/placeholder-image.png'
import { httpGetAsync } from '../../../Util/util'

function SingleHotelCard({ hotel }) {

  const [priceType, setPriceType] = useState('total'); // night
  const [showMemberPrice, setShowMemberPrice] = useState(false);

  const [isImgLoad, setImgLoad] = useState(false)

  httpGetAsync(`${hotel?.thumbnail_image}`, (res) => { setImgLoad(res) })

  return (
    <div className="card2">
      <div className="row no-gutters">
        {/* <HotelImageSection
          hotel={hotel}
        /> */}
        <div className="imgWrap col col-width">
          <Link className="detail-hotel-url" to={`/hotel-detail/${hotel?.id}`}></Link>
          <div className="bg-image" style={{ backgroundImage: `url('${isImgLoad ? hotel?.thumbnail_image : placeholderImage}')` }}>
            {parseFloat(hotel?.price_detail?.price?.savings) > 0 &&
              <div className="savings">{hotel?.price_detail?.price?.savings_percentage} Savings</div>
            }
          </div>
        </div>

        <div className="cardContent col">
          <HotelDetails
            hotel={hotel}
            priceType={priceType}
            showMemberPrice={showMemberPrice}
          />
        </div>

        <div className="infoBox col col-width">
          <HotelPriceSection
            hotel={hotel}
            setPriceType={setPriceType}
            priceType={priceType}
            setShowMemberPrice={setShowMemberPrice}
            showMemberPrice={showMemberPrice}
          />
        </div>
      </div>
    </div>
  );
}

export default SingleHotelCard;
