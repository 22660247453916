import React, { useRef, useState } from 'react';
import HotelInfo from '../../../Component/Hotel/HotelInfo';
import ImageSlider from '../../../Component/ImageSlider/ImageSlider';
import Lightbox from 'react-image-lightbox';
import { isEmpty } from 'lodash';
import SingleHotelMapView from '../../HotelSearchResult/SingleHotelCard/SingleHotelMapView';
import { useSelector } from 'react-redux';
import RoomPriceInfo from '../../../Component/Hotel/RoomPriceInfo';
import Slider from 'react-slick';
import hotelImg from '../../../Assets/img/placeholder-image.png';
import { CiDiscount1 } from 'react-icons/ci';

function HotelDetailCard({
  scrollToView,
  name,
  address,
  contactNo,
  checkIn,
  checkOut,
  themes,
  images,
  heroImage,
  rating,
  latitude,
  longitude,
  currencySymbol,
  currencyCode,
}) {

  const [showGallery, setshowGallery] = useState(false);
  const [photoIndex, setphotoIndex] = useState(0);
  const navigatiorClickHistoryRef = useRef(false);
  const loading = useSelector((state) => state.commonReducer.loader);
  const roomsPrice = useSelector((state) => state.hotelReducer.hotelRoom);

  const hotelPackageDetails = useSelector((state) => state.hotelReducer.hotelPackageDetails);

  const toggleShowGallery = (index) => {
    if (index) {
      setphotoIndex(index);
    } else {
      setphotoIndex(1);
    }
    setshowGallery(true);
  };

  const imgRef = useRef(null);

  const bestRoomPrice = roomsPrice?.rates?.firstPackage?.packages[0].boards[0];
  const firstPackageIndex = roomsPrice?.rates?.firstPackageIndex;
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  return (
    <div className="bannerSlider">
      <div className="row no-gutters">
        <div className="fullImages col">
          <div
            className="imagesGroup customShadow"
            style={isEmpty(images) ? {
              backgroundImage: `url(${isEmpty(images) ? heroImage : images[0]
                })`,
            } : {}}
            disabled={isEmpty(images)}
          >
            {heroImage ?
              <Slider
                asNavFor={nav2}
                ref={(slider1) => setNav1(slider1)}
                arrows={true}
                speed={1500}
                slidesToShow={1}
              >
                {images?.map((num, index) => {
                  return (
                    <div className="imagesGroupWrap" key={index}>
                      <div
                        className="imagesGroup customShadow"
                        style={{ backgroundImage: `url(${num})` }}
                        onClick={() => {
                          navigatiorClickHistoryRef.current = true;
                          toggleShowGallery(index);
                        }}
                        // eslint-disable-next-line react/no-unknown-property
                        onError={(e) => {
                          e.target.src = hotelImg;
                        }}
                        ref={imgRef}
                      ></div>
                    </div>
                  );
                })}
              </Slider>
              : ''}
          </div>
        </div>
        <div className="thumbnail row no-gutters desktopViewDisplayNone">
          <ImageSlider
            hotelImages={images}
            toggleShowGallery={toggleShowGallery}
          />
        </div>
        <div className="content image-content-col col">
          {!loading && (
            <SingleHotelMapView
              className="customShadow mobileViewDisplayNone"
              latitude={latitude}
              longitude={longitude}
            />
          )}
          {parseFloat(hotelPackageDetails?.savings_starting_from) != 0 && <div className="saving-starts-from-wrap">
            <div className="saving-starts-from">
              <div>
                <div className="icon">
                  <CiDiscount1 className="blue-color" />
                </div>
                <h3>Savings Upto</h3>
                <h4>{hotelPackageDetails?.savings_starting_from}%</h4>
              </div>
              <button onClick={() => scrollToView(`rooms`)} className="btn btn-select-room">Select Room</button>
            </div>
          </div>}
          {/* {!loading && !isEmpty(bestRoomPrice) && (
            <div className="infoBoxWrap">
              <div className="infoBox">
                <RoomPriceInfo
                  {...bestRoomPrice}
                  price={bestRoomPrice?.price_detail}
                  disableLink={true}
                  scrollToView={scrollToView}
                  hideSaveMember={true}
                  currencySymbol={currencySymbol}
                  currencyCode={currencyCode}
                  firstPackageIndex={firstPackageIndex}
                />
              </div>
            </div>
          )} */}
        </div>
      </div>
      <div className="thumbnail row no-gutters mobileViewDisplayNone">
        {heroImage && <Slider
          infinite={true}
          centerMode={false}
          centerPadding="0PX"
          slidesToShow={5} // it shoud be 5 , change for testing
          lazyLoad={true}
          asNavFor={nav1}
          variableWidth={false}
          focusOnSelect={true}
          // initialSlide={10}
          className="images col"
          ref={(slider2) => {
            if (!navigatiorClickHistoryRef.current) {
              // slider2?.slickNext();   // it removed for testing
            }
            setNav2(slider2);
          }}
        >
          {
            images?.map((num, index) => (
              <img
                src={num}
                alt="hotel-detail-image"
                className="w-100"
                key={`${index}_img`}
                onError={(e) => {
                  e.target.src = hotelImg;
                }}
              />
            ))}
        </Slider>}
      </div>
      <div className="SliderContent">
        <HotelInfo
          name={name}
          contactNo={contactNo}
          address={address}
          checkOut={checkOut}
          checkIn={checkIn}
          themes={themes}
          rating={rating}
          latitude={latitude}
          longitude={longitude}
        />
      </div>
      {showGallery && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setshowGallery(false)}
          onMovePrevRequest={() =>
            setphotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setphotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
}

export default HotelDetailCard;
