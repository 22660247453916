import React, { useEffect, useState } from 'react';
import Button from '../../../Component/FormComponent/Button';
import InputRange from 'react-input-range';
import { numFormatter } from '../../../Util/util';
import { useSelector } from 'react-redux';

function PriceRange({ isMobileView, filters, handleSearchHotel, setFilters }) {
    const [showFilter, setshowFilter] = useState(false);

    const { newHotelSearchData } = useSelector((state) => state.hotelReducer);

    // const [rangeVal, setRangeVal] = useState({
    //     min: 0,
    //     max: 0,
    // });

    const minFromAPI = newHotelSearchData?.filters[1]?.options[0]?.value;
    const maxFromAPI = newHotelSearchData?.filters[1]?.options[1]?.value;

    const currencyAndLanguage =
        (window.localStorage.getItem('currencyAndLanguage') &&
            JSON.parse(window.localStorage.getItem('currencyAndLanguage'))) ||
        {};

    const currencySymbol =
        currencyAndLanguage?.currencyLanguageReducer?.data?.currency?.symbol || '$';

    const onChangeValue = filters?.priceRange?.onChangeValue;
    const value = filters?.priceRange?.value;



    const handleRangeChange = (val) => {
        setFilters({ ...filters, priceRange: { ...filters.priceRange, onChangeValue: val, value: null } })
    }

    const resetRange = () => {
        const updatedFilters = { ...filters, priceRange: { ...filters.priceRange, onChangeValue: { min: minFromAPI, max: maxFromAPI }, value: null } }
        setFilters(updatedFilters)
        handleSearchHotel(updatedFilters);
    }

    useEffect(() => {
        if (!filters?.priceRange?.onChangeValue?.min) {
            setFilters({ ...filters, priceRange: { ...filters.priceRange, onChangeValue: { min: onChangeValue?.min || minFromAPI, max: onChangeValue?.max || maxFromAPI }, value: null } })
        }
    }, [newHotelSearchData]);

    const handleApply = () => {
        const updatedFilters = { ...filters, priceRange: { ...filters.priceRange, onChangeValue: onChangeValue, value: onChangeValue } }
        handleSearchHotel(updatedFilters, null);
    }

    const disabledCancelBtn = onChangeValue?.min === minFromAPI && onChangeValue?.max === maxFromAPI && !value?.main && !value?.max;

    const disabledApplyBtn = onChangeValue?.min === minFromAPI && onChangeValue?.max === maxFromAPI;

    return (
        <>
            <div
                className={isMobileView ? 'flterOption' : 'cardHead dflex'}
                onClick={() => setshowFilter(!showFilter)}
            >
                {isMobileView ? <span>Price</span> : <h6>Price</h6>}
                <span className={`fa fa-angle-${showFilter ? 'up' : 'down'}`}></span>
            </div>
            <div className={`priceRange ${!showFilter ? 'd-none' : ''}`}>
                <h4>
                    {currencySymbol}
                    {numFormatter(onChangeValue?.min)}
                </h4>
                <h4>
                    {currencySymbol}
                    {numFormatter(onChangeValue?.max)}
                </h4>
            </div>
            <div className={`cardBody  ${!showFilter ? 'd-none' : ''}`}>
                <div className="inputRange">
                    <InputRange
                        maxValue={Number(maxFromAPI)}
                        minValue={Number(minFromAPI)}
                        value={onChangeValue}
                        onChange={(value) => handleRangeChange(value)}
                        formatLabel={(e) => { }} //don't remove this, it is for removing lable from the range input
                    />
                </div>
                <div className="priceBtn">
                    <Button
                        className={`btnBorder ${disabledApplyBtn && 'disabled'}`}
                        type="button"
                        onClick={handleApply}
                        disabled={disabledApplyBtn}
                    >
                        Apply
                    </Button>
                    {isMobileView ? (
                        ''
                    ) : (
                        <Button
                            className={`linkColor font14 cancelBtn ${disabledCancelBtn && 'disabled'}`}
                            type="button"
                            onClick={resetRange}
                            disabled={disabledCancelBtn}
                        >
                            Cancel
                        </Button>
                    )}
                </div>
            </div>
        </>
    );
}

export default PriceRange;
