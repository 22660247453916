import React, { useState, useEffect } from 'react';
import PersonLogo from '../../../Assets/img/person.png';
import sqftIcon from '../../../Assets/img/maximize.svg';
import NoSmokeImg from '../../../Assets/img/no-smoking.svg';
import SmokeImg from '../../../Assets/img/smoking.svg';
import BedImg from '../../../Assets/img/bed.png';
import RoomPriceInfo from './RoomPriceInfo.jsx';
import RoomDetaisModal from './RoomDetaisModal.jsx';
import NewExtras from './NewExtras.jsx'
import Button from '../../../Component/FormComponent/Button.js';
import { ReactComponent as RightArrowImg } from '../../../Assets/img/icons/right-shift-arrow.svg';
import { httpGetAsync } from '../../../Util/util';
import DefaultImage from '../../../Assets/img/placeholder-image.png';


const allExtrasInitialValues = {
  'room_only': {
    id: 0,
    lable: 'No extras',
    disabled: true,
    data: null,
  },
  'breakfast': {
    id: 1,
    lable: 'Breakfast',
    disabled: true,
    data: null,
  },
  'half_board': {
    id: 2,
    lable: 'Half board',
    disabled: true,
    data: null,
  },
  'full_board': {
    id: 3,
    lable: 'Full board',
    disabled: true,
    data: null,
  }
}


function RoomCard(props) {

  const {
    roomdata,
    currencySymbol,
    containerIndex,
    setShowUpgradeModal
  } = props;

  const [isImgLoad, setImgLoad] = useState(false);
  const [thumbnailImage, setThumbnailImage] = useState([])
  async function checkImage(url) {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  }

  async function filterInvalidImages(imageUrls) {
    const validImageUrls = [];
    for (const url of imageUrls) {
      const isValid = await checkImage(url);
      if (isValid) {
        validImageUrls.push(url);
      }
    }
    return validImageUrls;
  }
  useEffect(() => {
    if (roomdata?.thumbnail_image?.length > 0) {
      filterInvalidImages(roomdata?.thumbnail_image)
        .then((validImageUrls) => {
          setThumbnailImage(validImageUrls);
        })
        .catch((error) => {
        });
    }
  }, [])
  httpGetAsync(`${roomdata?.thumbnail_image?.length > 0 ? thumbnailImage[0] : ''}`, (res) => { setImgLoad(res) })

  const [refundableType, setRefundableType] = useState(
    {
      data: roomdata?.boards[roomdata?.boards?.tab_active],
      type: roomdata?.boards?.tab_active,
    }
  );


  const [allExtrass, setAllExtrass] = useState(allExtrasInitialValues);

  const [selectedExtrasType, setSelectedExtrasType] = useState({ type: null, data: null });


  const updateAllExtrass = (data, isTypeChange) => {
    const selectedRoomTypeData = data;

    let updatedExtras = isTypeChange ? allExtrasInitialValues : allExtrass;

    selectedRoomTypeData?.map((item, index) => {

      updatedExtras = { ...updatedExtras, [item.key]: { ...updatedExtras[item.key], disabled: false, data: item } };

      if (index === 0) setSelectedExtrasType({ type: item.key, data: item });

    });

    setAllExtrass(updatedExtras)
  }

  useEffect(() => {
    if (roomdata) {
      updateAllExtrass(refundableType?.data, false)
    }
  }, [roomdata]);


  const handleRoomTypeChange = (type) => {
    setRefundableType({ data: roomdata?.boards[type], type })
    updateAllExtrass(roomdata?.boards[type], true)
  }

  // component states
  const [openModal, setOpenModal] = useState(false);
  // return component JSX
  return (
    <div className="new-card-box">
      <div className="row no-gutters">

        <div id={`roomIndex_${containerIndex}`} className="col leftContent ">
          <div className="imageContent">
            <div className="mobileViewDisplayNone">

              <div className="row no-gutters heading-wrap">
                <div className="col room-status-type">
                </div>
                <div className="col heading-group2">
                  {selectedExtrasType?.data?.available_rooms > 0 && (
                    <div className="availableRoomsWrap mobileViewDisplayNone">
                      <h4 className="availableRooms">
                        We have {selectedExtrasType?.data?.available_rooms} left
                      </h4>
                    </div>
                  )}
                  <div className="savings-heading">
                    {selectedExtrasType?.data?.price_detail?.price?.savings_percentage ? (
                      <p>{selectedExtrasType?.data?.price_detail?.price?.savings_percentage} Savings</p>
                    ) : ''}
                  </div>
                </div>
              </div>

            </div>
            <div className="row no-gutters">
              {/* RoomDetails.js */}
              <div className="col image">
                <div className="customShadow">

                  {roomdata?.thumbnail_image?.length > 0 ?
                    <Button
                      className="room-details-show-popup"
                      type="button"
                      onClick={() => setOpenModal(!openModal)}
                    >
                      {roomdata?.thumbnail_image?.length} Photos <RightArrowImg />
                    </Button>
                    : ''
                  }
                  <img onClick={() => roomdata?.thumbnail_image[0] && setOpenModal(!openModal)} src={isImgLoad ? thumbnailImage[0] : DefaultImage} alt="detail-page-image" />

                </div>
                <div className="heading-group">
                  <div className="heading">
                    <h3>{roomdata?.room_name}</h3>
                  </div>
                  <div className="smoking-status">
                    <img src={roomdata?.smoking_room ? SmokeImg : NoSmokeImg} />{' '}
                  </div>
                </div>
                <div className="hotelFacilities">
                  <div className="sq-ft-wrap">
                    {roomdata?.size && <h4 className="sq-ft-content"> <img src={sqftIcon} alt="icons" /> {roomdata?.size}</h4>}
                    <h4>
                      <img src={PersonLogo} alt="icons" />
                      <span>Sleeps {roomdata?.sleeps}</span>
                    </h4>
                  </div>
                  {roomdata?.bed_type && (
                    <h4>
                      <img src={BedImg} alt="icons" />
                      <span>{roomdata?.bed_type}</span>
                    </h4>
                  )}
                </div>
              </div>

              <div className="col content">
                <div className="content-wrap">
                  <h5 className="content-title">Rate Type</h5>

                  <div className="refundable-title">
                    <label
                      htmlFor={'non_refundable' + containerIndex}
                      className={roomdata?.boards?.non_refundable ? 'customRadio refundable-box non-refundable-ui' : 'customRadio refundable-box'}
                      onClick={() => {
                        if (roomdata?.boards?.non_refundable?.length >= 1) {
                          handleRoomTypeChange('non_refundable')
                        }
                      }}
                    >
                      <input
                        type="radio"
                        checked={refundableType.type === 'non_refundable'}
                        name={`refundBtns${containerIndex}`}
                        id={'non_refundable' + containerIndex}
                        value={'non_refundable' + containerIndex}
                        disabled={roomdata?.boards?.non_refundable?.length < 1}
                        readOnly
                      />

                      <span
                        className={`checkStyle ${roomdata?.boards?.non_refundable?.length < 1 ? ` disabled` : ``}`}>
                        Non Refundable
                      </span>
                      <label
                        className={`${roomdata?.boards?.non_refundable?.length > 0 ? `` : `disabled`}`}
                      >
                        {roomdata?.boards?.non_refundable?.length > 0 &&
                          roomdata?.boards?.tab_active === 'non_refundable'
                          ? `+ ${currencySymbol}0.00`
                          : <>{roomdata?.boards?.non_refundable?.length > 0 ? <> +&nbsp;{currencySymbol}{roomdata?.boards?.price_difference}</> : '--'}</>}
                      </label>
                    </label>
                    {/* non-refundable end */}

                    {/* refundable */}
                    <label
                      htmlFor={'refundable' + containerIndex}
                      className={roomdata?.boards?.refundable ? 'customRadio refundable-box refundable-ui' : 'customRadio refundable-box'}
                      onClick={() => {
                        if (roomdata?.boards?.refundable?.length >= 1) {
                          handleRoomTypeChange('refundable')
                        }
                      }}
                    >
                      <input
                        type="radio"
                        checked={refundableType.type === 'refundable'}
                        name={`refundBtns${containerIndex}`}
                        id={'refundable' + containerIndex}
                        value={'refundable' + containerIndex}
                        disabled={roomdata?.boards?.refundable?.length < 1}
                        readOnly
                      />

                      <span className={`checkStyle ${roomdata?.boards?.refundable?.length < 1 ? ` disabled` : ``}`}>
                        Refundable
                      </span>
                      <label
                        className={`${roomdata?.boards?.refundable?.length > 0
                          ? ``
                          : `disabled`
                          }`}
                      >
                        {roomdata?.boards?.refundable?.length > 0 &&
                          roomdata?.boards?.tab_active === 'refundable'
                          ? `+ ${currencySymbol}0.00`
                          : <>{roomdata?.boards?.refundable?.length > 0 ? <> +&nbsp;{currencySymbol}{roomdata?.boards?.price_difference}</> : '--'}</>}
                      </label>
                    </label>
                    {/* refundable */}
                  </div>
                  {/* extras */}
                  <div className="extra-data-wrap">
                    {/* new extras */}
                    <div className="new-extra-wrap">
                      {roomdata?.boards && (
                        <NewExtras
                          containerIndex={containerIndex}
                          allExtrass={allExtrass}
                          setSelectedExtrasType={setSelectedExtrasType}
                          selectedExtrasType={selectedExtrasType}
                        />)}
                    </div>
                    {/* new extras */}
                  </div>
                </div>
              </div>
              <RoomPriceInfo
                selectedBoardData={selectedExtrasType?.data}
                roomdata={roomdata}
                setShowUpgradeModal={setShowUpgradeModal}
              />
            </div>
          </div>

          {openModal && (
            <RoomDetaisModal
              isOpen={openModal}
              closeModal={() => setOpenModal(false)}
              hotelImages={thumbnailImage}
              group_name={roomdata?.group_name}
              facilities={roomdata?.amenities}
            />
          )}
        </div>

      </div>
    </div>
  );
}

export default RoomCard;
