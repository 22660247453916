/* eslint-disable react/display-name */
import React, {
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

export const StripeForm = forwardRef((props, ref) => {

  const stripe = useStripe();
  const elements = useElements();

  useImperativeHandle(ref, () => ({

    async confirmStripePayment() {
      if (!stripe || !elements) {
        return;
      } 
      const paymentResult = await stripe.confirmCardPayment(
        props.clientSecret,
        {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              // name: "",
            },
          },
        }
      );
      return paymentResult;
    },
    handleError() {
      // const cardElementContainer = document.querySelector('.cardWrapper');
      // let isCardComplete = cardElementContainer?.classList?.contains('StripeElement--complete');
      // console.log("isCardComplete",isCardComplete);
      // const cardNumber = elements.getElement(CardElement);
      // const isInvalid = cardNumber._invalid;
      // const isEmpty = cardNumber._empty;
      // const isValid = !(isEmpty || isInvalid);
      // console.log("isInvalid", isInvalid, "isEmpty", isEmpty);
      // return isValid
      // return isCardComplete
    }

  }));

  return (
    <>
      <div
        className="col"
      >
        <h5>Enter Card Details</h5>
        <CardElement
          onChange={() => {
            setTimeout(() => {
              const cardElementContainer = document.querySelector('.cardWrapper');
              let isCardComplete = cardElementContainer?.classList?.contains('StripeElement--complete');
              if (isCardComplete) {
                props.setStripeError("")
              }
            }, 0);
          }}
          className="cardWrapper stripe-card"
        />
        <p className="error-messages"> {props.stripeError} </p>

      </div>
    </>
  );
});
