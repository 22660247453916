export const CURRENCY = [
  {
    name: "US Dollar",
    isoCode: "USD",
    symbol: "$",
  },
  {
    name: "Australian Dollar",
    isoCode: "AUD",
    symbol: "AU$",
  },
  {
    name: "Singapore Dollar",
    isoCode: "SGD",
    symbol: "$",
  },
  {
    name: "Euro",
    isoCode: "EUR",
    symbol: "€",
  },
  {
    name: "Yen",
    isoCode: "JPY",
    symbol: "¥",
  },
  {
    name: "Afghani",
    isoCode: "AFN",
    symbol: "؋",
  },
  {
    name: "Lek",
    isoCode: "ALL",
    symbol: "Lek",
  },
  {
    name: "Algerian Dinar",
    isoCode: "DZD",
    symbol: "د.ج",
  },
  {
    name: "Kwanza",
    isoCode: "AOA",
    symbol: "Kz",
  },
  {
    name: "East Caribbean Dollar",
    isoCode: "XCD",
    symbol: "C$",
  },
  {
    name: "Argentine Peso",
    isoCode: "ARS",
    symbol: "$",
  },
  {
    name: "Armenian Dram",
    isoCode: "AMD",
    symbol: "",
  },
  {
    name: "Aruban Florin",
    isoCode: "AWG",
    symbol: "ƒ",
  },
  {
    name: "Azerbaijanian Manat",
    isoCode: "AZN",
    symbol: "ман",
  },
  {
    name: "Bahamian Dollar",
    isoCode: "BSD",
    symbol: "BS$",
  },
  {
    name: "Bahraini Dinar",
    isoCode: "BHD",
    symbol: "BD",
  },
  {
    name: "Taka",
    isoCode: "BDT",
    symbol: "৳",
  },
  {
    name: "Barbados Dollar",
    isoCode: "BBD",
    symbol: "BB$",
  },
  {
    name: "Belarussian Ruble",
    isoCode: "BYR",
    symbol: "p.",
  },
  {
    name: "Belize Dollar",
    isoCode: "BZD",
    symbol: "BZ$",
  },
  {
    name: "CF Franc BCEAO",
    isoCode: "XOF",
    symbol: "CFA",
  },
  {
    name: "Bermudian Dollar",
    isoCode: "BMD",
    symbol: "BM$",
  },
  {
    name: "Ngultrum",
    isoCode: "BTN",
    symbol: "Nu",
  },
  {
    name: "Indian Rupee",
    isoCode: "INR",
    symbol: "₹",
  },
  {
    name: "Boliviano",
    isoCode: "BOB",
    symbol: "$b",
  },
  {
    name: "Mvdol",
    isoCode: "BOV",
    symbol: "$b",
  },
  {
    name: "Convertible Mark",
    isoCode: "BAM",
    symbol: "KM",
  },
  {
    name: "Pula",
    isoCode: "BWP",
    symbol: "P",
  },
  {
    name: "Norwegian Krone",
    isoCode: "NOK",
    symbol: "kr",
  },
  {
    name: "Brazilian Real",
    isoCode: "BRL",
    symbol: "R$",
  },
  {
    name: "Brunei Dollar",
    isoCode: "BND",
    symbol: "BN$",
  },
  {
    name: "Bulgarian Lev",
    isoCode: "BGN",
    symbol: "лв",
  },
  {
    name: "Burundi Franc",
    isoCode: "BIF",
    symbol: "BIF",
  },
  {
    name: "Riel",
    isoCode: "KHR",
    symbol: "៛",
  },
  {
    name: "CF Franc BEAC",
    isoCode: "XAF",
    symbol: "",
  },
  {
    name: "Canadian Dollar",
    isoCode: "CAD",
    symbol: "CAD$",
  },
  {
    name: "Cabo Verde Escudo",
    isoCode: "CVE",
    symbol: "$",
  },
  {
    name: "Cayman Islands Dollar",
    isoCode: "KYD",
    symbol: "KYD$",
  },
  {
    name: "Unidad de Fomento",
    isoCode: "CLF",
    symbol: "",
  },
  {
    name: "Chilean Peso",
    isoCode: "CLP",
    symbol: "$",
  },
  {
    name: "Yuan Renminbi",
    isoCode: "CNY",
    symbol: "¥",
  },
  {
    name: "Colombian Peso",
    isoCode: "COP",
    symbol: "$",
  },
  {
    name: "Unidad de Valor Real",
    isoCode: "COU",
    symbol: "",
  },
  {
    name: "Comoro Franc",
    isoCode: "KMF",
    symbol: "",
  },
  {
    name: "Congolese Franc",
    isoCode: "CDF",
    symbol: "",
  },
  {
    name: "New Zealand Dollar",
    isoCode: "NZD",
    symbol: "NZD$",
  },
  {
    name: "Cost Rican Colon",
    isoCode: "CRC",
    symbol: "₡",
  },
  {
    name: "Croatian Kuna",
    isoCode: "HRK",
    symbol: "kn",
  },
  {
    name: "Peso Convertible",
    isoCode: "CUC",
    symbol: "",
  },
  {
    name: "Cuban Peso",
    isoCode: "CUP",
    symbol: "₱",
  },
  {
    name: "Netherlands Antillean Guilder",
    isoCode: "ANG",
    symbol: "ƒ",
  },
  {
    name: "Czech Koruna",
    isoCode: "CZK",
    symbol: "Kč",
  },
  {
    name: "Danish Krone",
    isoCode: "DKK",
    symbol: "kr",
  },
  {
    name: "Djibouti Franc",
    isoCode: "DJF",
    symbol: "",
  },
  {
    name: "Dominican Peso",
    isoCode: "DOP",
    symbol: "RD$",
  },
  {
    name: "Egyptian Pound",
    isoCode: "EGP",
    symbol: "£",
  },
  {
    name: "El Salvador Colon",
    isoCode: "SVC",
    symbol: "$",
  },
  {
    name: "Nakfa",
    isoCode: "ERN",
    symbol: "",
  },
  {
    name: "Ethiopian Birr",
    isoCode: "ETB",
    symbol: "",
  },
  {
    name: "Falkland Islands Pound",
    isoCode: "FKP",
    symbol: "£",
  },
  {
    name: "Fiji Dollar",
    isoCode: "FJD",
    symbol: "FJD$",
  },
  {
    name: "CFP Franc",
    isoCode: "XPF",
    symbol: "",
  },
  {
    name: "Dalasi",
    isoCode: "GMD",
    symbol: "",
  },
  {
    name: "Lari",
    isoCode: "GEL",
    symbol: "",
  },
  {
    name: "Ghan Cedi",
    isoCode: "GHS",
    symbol: "",
  },
  {
    name: "Gibraltar Pound",
    isoCode: "GIP",
    symbol: "£",
  },
  {
    name: "Quetzal",
    isoCode: "GTQ",
    symbol: "Q",
  },
  {
    name: "Pound Sterling",
    isoCode: "GBP",
    symbol: "£",
  },
  {
    name: "Guine Franc",
    isoCode: "GNF",
    symbol: "",
  },
  {
    name: "Guyan Dollar",
    isoCode: "GYD",
    symbol: "GYD$",
  },
  {
    name: "Gourde",
    isoCode: "HTG",
    symbol: "",
  },
  {
    name: "Lempira",
    isoCode: "HNL",
    symbol: "L",
  },
  {
    name: "Hong Kong Dollar",
    isoCode: "HKD",
    symbol: "HKD$",
  },
  {
    name: "Forint",
    isoCode: "HUF",
    symbol: "Ft",
  },
  {
    name: "Iceland Krona",
    isoCode: "ISK",
    symbol: "kr",
  },
  {
    name: "Rupiah",
    isoCode: "IDR",
    symbol: "Rp",
  },
  {
    name: "SDR (Special Drawing Right)",
    isoCode: "XDR",
    symbol: "",
  },
  {
    name: "Iranian Rial",
    isoCode: "IRR",
    symbol: "﷼",
  },
  {
    name: "Iraqi Dinar",
    isoCode: "IQD",
    symbol: "",
  },
  {
    name: "New Israeli Sheqel",
    isoCode: "ILS",
    symbol: "₪",
  },
  {
    name: "Jamaican Dollar",
    isoCode: "JMD",
    symbol: "JMD$",
  },
  {
    name: "Jordanian Dinar",
    isoCode: "JOD",
    symbol: "",
  },
  {
    name: "Tenge",
    isoCode: "KZT",
    symbol: "лв",
  },
  {
    name: "Kenyan Shilling",
    isoCode: "KES",
    symbol: "",
  },
  {
    name: "North Korean Won",
    isoCode: "KPW",
    symbol: "₩",
  },
  {
    name: "Won",
    isoCode: "KRW",
    symbol: "₩",
  },
  {
    name: "Kuwaiti Dinar",
    isoCode: "KWD",
    symbol: "",
  },
  {
    name: "Som",
    isoCode: "KGS",
    symbol: "лв",
  },
  {
    name: "Kip",
    isoCode: "LAK",
    symbol: "₭",
  },
  {
    name: "Lebanese Pound",
    isoCode: "LBP",
    symbol: "£",
  },
  {
    name: "Loti",
    isoCode: "LSL",
    symbol: "",
  },
  {
    name: "Rand",
    isoCode: "ZAR",
    symbol: "R",
  },
  {
    name: "Liberian Dollar",
    isoCode: "LRD",
    symbol: "LRD$",
  },
  {
    name: "Libyan Dinar",
    isoCode: "LYD",
    symbol: "",
  },
  {
    name: "Swiss Franc",
    isoCode: "CHF",
    symbol: "CHF",
  },
  {
    name: "Lithuanian Litas",
    isoCode: "LTL",
    symbol: "Lt",
  },
  {
    name: "Pataca",
    isoCode: "MOP",
    symbol: "",
  },
  {
    name: "Denar",
    isoCode: "MKD",
    symbol: "ден",
  },
  {
    name: "Malagasy riary",
    isoCode: "MGA",
    symbol: "",
  },
  {
    name: "Kwacha",
    isoCode: "MWK",
    symbol: "",
  },
  {
    name: "Malaysian Ringgit",
    isoCode: "MYR",
    symbol: "RM",
  },
  {
    name: "Rufiyaa",
    isoCode: "MVR",
    symbol: "",
  },
  {
    name: "Ouguiya",
    isoCode: "MRO",
    symbol: "",
  },
  {
    name: "Mauritius Rupee",
    isoCode: "MUR",
    symbol: "₨",
  },
  {
    name: "ADB Unit of ccount",
    isoCode: "XUA",
    symbol: "",
  },
  {
    name: "Mexican Peso",
    isoCode: "MXN",
    symbol: "$",
  },
  {
    name: "Mexican Unidad de Inversion (UDI)",
    isoCode: "MXV",
    symbol: "",
  },
  {
    name: "Moldovan Leu",
    isoCode: "MDL",
    symbol: "",
  },
  {
    name: "Tugrik",
    isoCode: "MNT",
    symbol: "₮",
  },
  {
    name: "Moroccan Dirham",
    isoCode: "MAD",
    symbol: "",
  },
  {
    name: "Mozambique Metical",
    isoCode: "MZN",
    symbol: "MT",
  },
  {
    name: "Kyat",
    isoCode: "MMK",
    symbol: "",
  },
  {
    name: "Namibi Dollar",
    isoCode: "NAD",
    symbol: "NAD$",
  },
  {
    name: "Nepalese Rupee",
    isoCode: "NPR",
    symbol: "₨",
  },
  {
    name: "Cordob Oro",
    isoCode: "NIO",
    symbol: "C$",
  },
  {
    name: "Naira",
    isoCode: "NGN",
    symbol: "₦",
  },
  {
    name: "Rial Omani",
    isoCode: "OMR",
    symbol: "﷼",
  },
  {
    name: "Pakistan Rupee",
    isoCode: "PKR",
    symbol: "₨",
  },
  {
    name: "Balboa",
    isoCode: "PAB",
    symbol: "B/.",
  },
  {
    name: "Kina",
    isoCode: "PGK",
    symbol: "K",
  },
  {
    name: "Guarani",
    isoCode: "PYG",
    symbol: "Gs",
  },
  {
    name: "Nuevo Sol",
    isoCode: "PEN",
    symbol: "S/.",
  },
  {
    name: "Philippine Peso",
    isoCode: "PHP",
    symbol: "₱",
  },
  {
    name: "Zloty",
    isoCode: "PLN",
    symbol: "zł",
  },
  {
    name: "Qatari Rial",
    isoCode: "QAR",
    symbol: "﷼",
  },
  {
    name: "New Romanian Leu",
    isoCode: "RON",
    symbol: "lei",
  },
  {
    name: "Russian Ruble",
    isoCode: "RUB",
    symbol: "руб",
  },
  {
    name: "Rwand Franc",
    isoCode: "RWF",
    symbol: "R₣",
  },
  {
    name: "Saint Helen Pound",
    isoCode: "SHP",
    symbol: "£",
  },
  {
    name: "Tala",
    isoCode: "WST",
    symbol: "$",
  },
  {
    name: "Dobra",
    isoCode: "STD",
    symbol: "",
  },
  {
    name: "Saudi Riyal",
    isoCode: "SAR",
    symbol: "﷼",
  },
  {
    name: "Serbian Dinar",
    isoCode: "RSD",
    symbol: "Дин.",
  },
  {
    name: "Seychelles Rupee",
    isoCode: "SCR",
    symbol: "₨",
  },
  {
    name: "Leone",
    isoCode: "SLL",
    symbol: "Le",
  },
  {
    name: "Sucre",
    isoCode: "XSU",
    symbol: "",
  },
  {
    name: "Solomon Islands Dollar",
    isoCode: "SBD",
    symbol: "SBD$",
  },
  {
    name: "Somali Shilling",
    isoCode: "SOS",
    symbol: "S",
  },
  {
    name: "South Sudanese Pound",
    isoCode: "SSP",
    symbol: "",
  },
  {
    name: "Sri Lank Rupee",
    isoCode: "LKR",
    symbol: "₨",
  },
  {
    name: "Sudanese Pound",
    isoCode: "SDG",
    symbol: "",
  },
  {
    name: "Surinam Dollar",
    isoCode: "SRD",
    symbol: "SRD$",
  },
  {
    name: "Lilangeni",
    isoCode: "SZL",
    symbol: "",
  },
  {
    name: "Swedish Krona",
    isoCode: "SEK",
    symbol: "kr",
  },
  {
    name: "WIR Euro",
    isoCode: "CHE",
    symbol: "",
  },
  {
    name: "WIR Franc",
    isoCode: "CHW",
    symbol: "",
  },
  {
    name: "Syrian Pound",
    isoCode: "SYP",
    symbol: "£",
  },
  {
    name: "New Taiwan Dollar",
    isoCode: "TWD",
    symbol: "NT$",
  },
  {
    name: "Somoni",
    isoCode: "TJS",
    symbol: "",
  },
  {
    name: "Tanzanian Shilling",
    isoCode: "TZS",
    symbol: "",
  },
  {
    name: "Baht",
    isoCode: "THB",
    symbol: "฿",
  },
  {
    name: "Pa’anga",
    isoCode: "TOP",
    symbol: "",
  },
  {
    name: "Trinidad nd Tobago Dollar",
    isoCode: "TTD",
    symbol: "TT$",
  },
  {
    name: "Tunisian Dinar",
    isoCode: "TND",
    symbol: "",
  },
  {
    name: "Turkish Lira",
    isoCode: "TRY",
    symbol: "₺",
  },
  {
    name: "Turkmenistan New Manat",
    isoCode: "TMT",
    symbol: "",
  },
  {
    name: "Ugand Shilling",
    isoCode: "UGX",
    symbol: "",
  },
  {
    name: "Hryvnia",
    isoCode: "UAH",
    symbol: "₴",
  },
  {
    name: "UAE Dirham",
    isoCode: "AED",
    symbol: "",
  },
  {
    name: "US Dollar (Next day)",
    isoCode: "USN",
    symbol: "USN",
  },
  {
    name: "Uruguay Peso en Unidades Indexadas (URUIURUI)",
    isoCode: "UYI",
    symbol: "",
  },
  {
    name: "Peso Uruguayo",
    isoCode: "UYU",
    symbol: "$U",
  },
  {
    name: "Uzbekistan Sum",
    isoCode: "UZS",
    symbol: "лв",
  },
  {
    name: "Vatu",
    isoCode: "VUV",
    symbol: "",
  },
  {
    name: "Bolivar",
    isoCode: "VEF",
    symbol: "Bs",
  },
  {
    name: "Dong",
    isoCode: "VND",
    symbol: "₫",
  },
  {
    name: "Yemeni Rial",
    isoCode: "YER",
    symbol: "﷼",
  },
  {
    name: "Zambian Kwacha",
    isoCode: "ZMW",
    symbol: "",
  },
  {
    name: "Zimbabwe Dollar",
    isoCode: "ZWL",
    symbol: "ZWL$",
  },
];

export const LANGUAGES = [
  {
    country: "English (US)",
    langCode: "en-US",
  },
  {
    country: "Czech (Czech Republic)",
    langCode: "cs-CZ",
  },
  {
    country: "Chinese (Simplified)",
    langCode: "zh-CN",
  },
  {
    country: "Turkish (Turkey)",
    langCode: "tr-TR",
  },
  {
    country: "Thai (Thailand)",
    langCode: "th-TH",
  },
  {
    country: "Swedish (Sweden)",
    langCode: "sv-SE",
  },
  {
    country: "Russian (Russia)",
    langCode: "ru-RU",
  },
  {
    country: "Portuguese (Portugal)	",
    langCode: "pt-PT",
  },
  {
    country: "Portuguese (Brazil)",
    langCode: "pt-BR",
  },
  {
    country: "Polish (Poland)",
    langCode: "pl-PL",
  },
  {
    country: "Norwegian (Nynorsk)",
    langCode: "nn-NO",
  },
  {
    country: "Dutch (Netherlands)",
    langCode: "nl-NL",
  },
  {
    country: "Malay (Malaysia)",
    langCode: "ms-MY",
  },
  {
    country: "Korean (Korea)",
    langCode: "ko-KR",
  },
  {
    country: "Japanese (Japan)",
    langCode: "ja-JP",
  },
  {
    country: "Italian (Italy)",
    langCode: "it-IT",
  },
  {
    country: "Indonesian (Indonesia)",
    langCode: "id-ID",
  },
  {
    country: "French (France)",
    langCode: "fr-FR",
  },
  {
    country: "French (Canada)",
    langCode: "fr-CA",
  },
  {
    country: "Finnish (Finland)",
    langCode: "fi-FI",
  },
  {
    country: "Spanish (Mexico)",
    langCode: "es-MX",
  },
  {
    country: "Spanish (Spain)",
    langCode: "es-ES",
  },
  {
    country: "Greek (Greece)",
    langCode: "el-GR",
  },
  {
    country: "German (Germany)",
    langCode: "de-DE",
  },
  {
    country: "Danish (Denmark)",
    langCode: "da-DK",
  },
  {
    country: "Arabic (Saudi Arabia)",
    langCode: "ar-SA",
  },
  {
    country: "Chinese (Traditional)",
    langCode: "zh-TW",
  },
  {
    country: "Vietnamese (Viet Nam)",
    langCode: "vi-VN",
  },
];

export const countries = [
  {
    id: "231",
    sortname: "US",
    name: "United States",
  },
  {
    id: "1",
    sortname: "AF",
    name: "Afghanistan",
  },
  {
    id: "2",
    sortname: "AL",
    name: "Albania",
  },
  {
    id: "3",
    sortname: "DZ",
    name: "Algeria",
  },
  {
    id: "4",
    sortname: "AS",
    name: "American Samoa",
  },
  {
    id: "5",
    sortname: "AD",
    name: "Andorra",
  },
  {
    id: "6",
    sortname: "AO",
    name: "Angola",
  },
  {
    id: "7",
    sortname: "AI",
    name: "Anguilla",
  },
  {
    id: "8",
    sortname: "AQ",
    name: "Antarctica",
  },
  {
    id: "9",
    sortname: "AG",
    name: "Antigua And Barbuda",
  },
  {
    id: "10",
    sortname: "AR",
    name: "Argentina",
  },
  {
    id: "11",
    sortname: "AM",
    name: "Armenia",
  },
  {
    id: "12",
    sortname: "AW",
    name: "Aruba",
  },
  {
    id: "13",
    sortname: "AU",
    name: "Australia",
  },
  {
    id: "14",
    sortname: "AT",
    name: "Austria",
  },
  {
    id: "15",
    sortname: "AZ",
    name: "Azerbaijan",
  },
  {
    id: "16",
    sortname: "BS",
    name: "Bahamas The",
  },
  {
    id: "17",
    sortname: "BH",
    name: "Bahrain",
  },
  {
    id: "18",
    sortname: "BD",
    name: "Bangladesh",
  },
  {
    id: "19",
    sortname: "BB",
    name: "Barbados",
  },
  {
    id: "20",
    sortname: "BY",
    name: "Belarus",
  },
  {
    id: "21",
    sortname: "BE",
    name: "Belgium",
  },
  {
    id: "22",
    sortname: "BZ",
    name: "Belize",
  },
  {
    id: "23",
    sortname: "BJ",
    name: "Benin",
  },
  {
    id: "24",
    sortname: "BM",
    name: "Bermuda",
  },
  {
    id: "25",
    sortname: "BT",
    name: "Bhutan",
  },
  {
    id: "26",
    sortname: "BO",
    name: "Bolivia",
  },
  {
    id: "27",
    sortname: "BA",
    name: "Bosnia and Herzegovina",
  },
  {
    id: "28",
    sortname: "BW",
    name: "Botswana",
  },
  {
    id: "29",
    sortname: "BV",
    name: "Bouvet Island",
  },
  {
    id: "30",
    sortname: "BR",
    name: "Brazil",
  },
  {
    id: "31",
    sortname: "IO",
    name: "British Indian Ocean Territory",
  },
  {
    id: "32",
    sortname: "BN",
    name: "Brunei",
  },
  {
    id: "33",
    sortname: "BG",
    name: "Bulgaria",
  },
  {
    id: "34",
    sortname: "BF",
    name: "Burkina Faso",
  },
  {
    id: "35",
    sortname: "BI",
    name: "Burundi",
  },
  {
    id: "36",
    sortname: "KH",
    name: "Cambodia",
  },
  {
    id: "37",
    sortname: "CM",
    name: "Cameroon",
  },
  {
    id: "38",
    sortname: "CA",
    name: "Canada",
  },
  {
    id: "39",
    sortname: "CV",
    name: "Cape Verde",
  },
  {
    id: "40",
    sortname: "KY",
    name: "Cayman Islands",
  },
  {
    id: "41",
    sortname: "CF",
    name: "Central African Republic",
  },
  {
    id: "42",
    sortname: "TD",
    name: "Chad",
  },
  {
    id: "43",
    sortname: "CL",
    name: "Chile",
  },
  {
    id: "44",
    sortname: "CN",
    name: "China",
  },
  {
    id: "45",
    sortname: "CX",
    name: "Christmas Island",
  },
  {
    id: "46",
    sortname: "CC",
    name: "Cocos (Keeling) Islands",
  },
  {
    id: "47",
    sortname: "CO",
    name: "Colombia",
  },
  {
    id: "48",
    sortname: "KM",
    name: "Comoros",
  },
  {
    id: "49",
    sortname: "CG",
    name: "Congo",
  },
  {
    id: "50",
    sortname: "CD",
    name: "Congo The Democratic Republic Of The",
  },
  {
    id: "51",
    sortname: "CK",
    name: "Cook Islands",
  },
  {
    id: "52",
    sortname: "CR",
    name: "Costa Rica",
  },
  {
    id: "53",
    sortname: "CI",
    name: "Cote D''Ivoire (Ivory Coast)",
  },
  {
    id: "54",
    sortname: "HR",
    name: "Croatia (Hrvatska)",
  },
  {
    id: "55",
    sortname: "CU",
    name: "Cuba",
  },
  {
    id: "56",
    sortname: "CY",
    name: "Cyprus",
  },
  {
    id: "57",
    sortname: "CZ",
    name: "Czech Republic",
  },
  {
    id: "58",
    sortname: "DK",
    name: "Denmark",
  },
  {
    id: "59",
    sortname: "DJ",
    name: "Djibouti",
  },
  {
    id: "60",
    sortname: "DM",
    name: "Dominica",
  },
  {
    id: "61",
    sortname: "DO",
    name: "Dominican Republic",
  },
  {
    id: "62",
    sortname: "TP",
    name: "East Timor",
  },
  {
    id: "63",
    sortname: "EC",
    name: "Ecuador",
  },
  {
    id: "64",
    sortname: "EG",
    name: "Egypt",
  },
  {
    id: "65",
    sortname: "SV",
    name: "El Salvador",
  },
  {
    id: "66",
    sortname: "GQ",
    name: "Equatorial Guinea",
  },
  {
    id: "67",
    sortname: "ER",
    name: "Eritrea",
  },
  {
    id: "68",
    sortname: "EE",
    name: "Estonia",
  },
  {
    id: "69",
    sortname: "ET",
    name: "Ethiopia",
  },
  {
    id: "70",
    sortname: "XA",
    name: "External Territories of Australia",
  },
  {
    id: "71",
    sortname: "FK",
    name: "Falkland Islands",
  },
  {
    id: "72",
    sortname: "FO",
    name: "Faroe Islands",
  },
  {
    id: "73",
    sortname: "FJ",
    name: "Fiji Islands",
  },
  {
    id: "74",
    sortname: "FI",
    name: "Finland",
  },
  {
    id: "75",
    sortname: "FR",
    name: "France",
  },
  {
    id: "76",
    sortname: "GF",
    name: "French Guiana",
  },
  {
    id: "77",
    sortname: "PF",
    name: "French Polynesia",
  },
  {
    id: "78",
    sortname: "TF",
    name: "French Southern Territories",
  },
  {
    id: "79",
    sortname: "GA",
    name: "Gabon",
  },
  {
    id: "80",
    sortname: "GM",
    name: "Gambia The",
  },
  {
    id: "81",
    sortname: "GE",
    name: "Georgia",
  },
  {
    id: "82",
    sortname: "DE",
    name: "Germany",
  },
  {
    id: "83",
    sortname: "GH",
    name: "Ghana",
  },
  {
    id: "84",
    sortname: "GI",
    name: "Gibraltar",
  },
  {
    id: "85",
    sortname: "GR",
    name: "Greece",
  },
  {
    id: "86",
    sortname: "GL",
    name: "Greenland",
  },
  {
    id: "87",
    sortname: "GD",
    name: "Grenada",
  },
  {
    id: "88",
    sortname: "GP",
    name: "Guadeloupe",
  },
  {
    id: "89",
    sortname: "GU",
    name: "Guam",
  },
  {
    id: "90",
    sortname: "GT",
    name: "Guatemala",
  },
  {
    id: "91",
    sortname: "XU",
    name: "Guernsey and Alderney",
  },
  {
    id: "92",
    sortname: "GN",
    name: "Guinea",
  },
  {
    id: "93",
    sortname: "GW",
    name: "Guinea-Bissau",
  },
  {
    id: "94",
    sortname: "GY",
    name: "Guyana",
  },
  {
    id: "95",
    sortname: "HT",
    name: "Haiti",
  },
  {
    id: "96",
    sortname: "HM",
    name: "Heard and McDonald Islands",
  },
  {
    id: "97",
    sortname: "HN",
    name: "Honduras",
  },
  {
    id: "98",
    sortname: "HK",
    name: "Hong Kong S.A.R.",
  },
  {
    id: "99",
    sortname: "HU",
    name: "Hungary",
  },
  {
    id: "100",
    sortname: "IS",
    name: "Iceland",
  },
  {
    id: "101",
    sortname: "IN",
    name: "India",
  },
  {
    id: "102",
    sortname: "ID",
    name: "Indonesia",
  },
  {
    id: "103",
    sortname: "IR",
    name: "Iran",
  },
  {
    id: "104",
    sortname: "IQ",
    name: "Iraq",
  },
  {
    id: "105",
    sortname: "IE",
    name: "Ireland",
  },
  {
    id: "106",
    sortname: "IL",
    name: "Israel",
  },
  {
    id: "107",
    sortname: "IT",
    name: "Italy",
  },
  {
    id: "108",
    sortname: "JM",
    name: "Jamaica",
  },
  {
    id: "109",
    sortname: "JP",
    name: "Japan",
  },
  {
    id: "110",
    sortname: "XJ",
    name: "Jersey",
  },
  {
    id: "111",
    sortname: "JO",
    name: "Jordan",
  },
  {
    id: "112",
    sortname: "KZ",
    name: "Kazakhstan",
  },
  {
    id: "113",
    sortname: "KE",
    name: "Kenya",
  },
  {
    id: "114",
    sortname: "KI",
    name: "Kiribati",
  },
  {
    id: "115",
    sortname: "KP",
    name: "Korea North",
  },
  {
    id: "116",
    sortname: "KR",
    name: "Korea South",
  },
  {
    id: "117",
    sortname: "KW",
    name: "Kuwait",
  },
  {
    id: "118",
    sortname: "KG",
    name: "Kyrgyzstan",
  },
  {
    id: "119",
    sortname: "LA",
    name: "Laos",
  },
  {
    id: "120",
    sortname: "LV",
    name: "Latvia",
  },
  {
    id: "121",
    sortname: "LB",
    name: "Lebanon",
  },
  {
    id: "122",
    sortname: "LS",
    name: "Lesotho",
  },
  {
    id: "123",
    sortname: "LR",
    name: "Liberia",
  },
  {
    id: "124",
    sortname: "LY",
    name: "Libya",
  },
  {
    id: "125",
    sortname: "LI",
    name: "Liechtenstein",
  },
  {
    id: "126",
    sortname: "LT",
    name: "Lithuania",
  },
  {
    id: "127",
    sortname: "LU",
    name: "Luxembourg",
  },
  {
    id: "128",
    sortname: "MO",
    name: "Macau S.A.R.",
  },
  {
    id: "129",
    sortname: "MK",
    name: "Macedonia",
  },
  {
    id: "130",
    sortname: "MG",
    name: "Madagascar",
  },
  {
    id: "131",
    sortname: "MW",
    name: "Malawi",
  },
  {
    id: "132",
    sortname: "MY",
    name: "Malaysia",
  },
  {
    id: "133",
    sortname: "MV",
    name: "Maldives",
  },
  {
    id: "134",
    sortname: "ML",
    name: "Mali",
  },
  {
    id: "135",
    sortname: "MT",
    name: "Malta",
  },
  {
    id: "136",
    sortname: "XM",
    name: "Man (Isle of)",
  },
  {
    id: "137",
    sortname: "MH",
    name: "Marshall Islands",
  },
  {
    id: "138",
    sortname: "MQ",
    name: "Martinique",
  },
  {
    id: "139",
    sortname: "MR",
    name: "Mauritania",
  },
  {
    id: "140",
    sortname: "MU",
    name: "Mauritius",
  },
  {
    id: "141",
    sortname: "YT",
    name: "Mayotte",
  },
  {
    id: "142",
    sortname: "MX",
    name: "Mexico",
  },
  {
    id: "143",
    sortname: "FM",
    name: "Micronesia",
  },
  {
    id: "144",
    sortname: "MD",
    name: "Moldova",
  },
  {
    id: "145",
    sortname: "MC",
    name: "Monaco",
  },
  {
    id: "146",
    sortname: "MN",
    name: "Mongolia",
  },
  {
    id: "147",
    sortname: "MS",
    name: "Montserrat",
  },
  {
    id: "148",
    sortname: "MA",
    name: "Morocco",
  },
  {
    id: "149",
    sortname: "MZ",
    name: "Mozambique",
  },
  {
    id: "150",
    sortname: "MM",
    name: "Myanmar",
  },
  {
    id: "151",
    sortname: "NA",
    name: "Namibia",
  },
  {
    id: "152",
    sortname: "NR",
    name: "Nauru",
  },
  {
    id: "153",
    sortname: "NP",
    name: "Nepal",
  },
  {
    id: "154",
    sortname: "AN",
    name: "Netherlands Antilles",
  },
  {
    id: "155",
    sortname: "NL",
    name: "Netherlands The",
  },
  {
    id: "156",
    sortname: "NC",
    name: "New Caledonia",
  },
  {
    id: "157",
    sortname: "NZ",
    name: "New Zealand",
  },
  {
    id: "158",
    sortname: "NI",
    name: "Nicaragua",
  },
  {
    id: "159",
    sortname: "NE",
    name: "Niger",
  },
  {
    id: "160",
    sortname: "NG",
    name: "Nigeria",
  },
  {
    id: "161",
    sortname: "NU",
    name: "Niue",
  },
  {
    id: "162",
    sortname: "NF",
    name: "Norfolk Island",
  },
  {
    id: "163",
    sortname: "MP",
    name: "Northern Mariana Islands",
  },
  {
    id: "164",
    sortname: "NO",
    name: "Norway",
  },
  {
    id: "165",
    sortname: "OM",
    name: "Oman",
  },
  {
    id: "166",
    sortname: "PK",
    name: "Pakistan",
  },
  {
    id: "167",
    sortname: "PW",
    name: "Palau",
  },
  {
    id: "168",
    sortname: "PS",
    name: "Palestinian Territory Occupied",
  },
  {
    id: "169",
    sortname: "PA",
    name: "Panama",
  },
  {
    id: "170",
    sortname: "PG",
    name: "Papua new Guinea",
  },
  {
    id: "171",
    sortname: "PY",
    name: "Paraguay",
  },
  {
    id: "172",
    sortname: "PE",
    name: "Peru",
  },
  {
    id: "173",
    sortname: "PH",
    name: "Philippines",
  },
  {
    id: "174",
    sortname: "PN",
    name: "Pitcairn Island",
  },
  {
    id: "175",
    sortname: "PL",
    name: "Poland",
  },
  {
    id: "176",
    sortname: "PT",
    name: "Portugal",
  },
  {
    id: "177",
    sortname: "PR",
    name: "Puerto Rico",
  },
  {
    id: "178",
    sortname: "QA",
    name: "Qatar",
  },
  {
    id: "179",
    sortname: "RE",
    name: "Reunion",
  },
  {
    id: "180",
    sortname: "RO",
    name: "Romania",
  },
  {
    id: "181",
    sortname: "RU",
    name: "Russia",
  },
  {
    id: "182",
    sortname: "RW",
    name: "Rwanda",
  },
  {
    id: "183",
    sortname: "SH",
    name: "Saint Helena",
  },
  {
    id: "184",
    sortname: "KN",
    name: "Saint Kitts And Nevis",
  },
  {
    id: "185",
    sortname: "LC",
    name: "Saint Lucia",
  },
  {
    id: "186",
    sortname: "PM",
    name: "Saint Pierre and Miquelon",
  },
  {
    id: "187",
    sortname: "VC",
    name: "Saint Vincent And The Grenadines",
  },
  {
    id: "188",
    sortname: "WS",
    name: "Samoa",
  },
  {
    id: "189",
    sortname: "SM",
    name: "San Marino",
  },
  {
    id: "190",
    sortname: "ST",
    name: "Sao Tome and Principe",
  },
  {
    id: "191",
    sortname: "SA",
    name: "Saudi Arabia",
  },
  {
    id: "192",
    sortname: "SN",
    name: "Senegal",
  },
  {
    id: "193",
    sortname: "RS",
    name: "Serbia",
  },
  {
    id: "194",
    sortname: "SC",
    name: "Seychelles",
  },
  {
    id: "195",
    sortname: "SL",
    name: "Sierra Leone",
  },
  {
    id: "196",
    sortname: "SG",
    name: "Singapore",
  },
  {
    id: "197",
    sortname: "SK",
    name: "Slovakia",
  },
  {
    id: "198",
    sortname: "SI",
    name: "Slovenia",
  },
  {
    id: "199",
    sortname: "XG",
    name: "Smaller Territories of the UK",
  },
  {
    id: "200",
    sortname: "SB",
    name: "Solomon Islands",
  },
  {
    id: "201",
    sortname: "SO",
    name: "Somalia",
  },
  {
    id: "202",
    sortname: "ZA",
    name: "South Africa",
  },
  {
    id: "203",
    sortname: "GS",
    name: "South Georgia",
  },
  {
    id: "204",
    sortname: "SS",
    name: "South Sudan",
  },
  {
    id: "205",
    sortname: "ES",
    name: "Spain",
  },
  {
    id: "206",
    sortname: "LK",
    name: "Sri Lanka",
  },
  {
    id: "207",
    sortname: "SD",
    name: "Sudan",
  },
  {
    id: "208",
    sortname: "SR",
    name: "Suriname",
  },
  {
    id: "209",
    sortname: "SJ",
    name: "Svalbard And Jan Mayen Islands",
  },
  {
    id: "210",
    sortname: "SZ",
    name: "Swaziland",
  },
  {
    id: "211",
    sortname: "SE",
    name: "Sweden",
  },
  {
    id: "212",
    sortname: "CH",
    name: "Switzerland",
  },
  {
    id: "213",
    sortname: "SY",
    name: "Syria",
  },
  {
    id: "214",
    sortname: "TW",
    name: "Taiwan",
  },
  {
    id: "215",
    sortname: "TJ",
    name: "Tajikistan",
  },
  {
    id: "216",
    sortname: "TZ",
    name: "Tanzania",
  },
  {
    id: "217",
    sortname: "TH",
    name: "Thailand",
  },
  {
    id: "218",
    sortname: "TG",
    name: "Togo",
  },
  {
    id: "219",
    sortname: "TK",
    name: "Tokelau",
  },
  {
    id: "220",
    sortname: "TO",
    name: "Tonga",
  },
  {
    id: "221",
    sortname: "TT",
    name: "Trinidad And Tobago",
  },
  {
    id: "222",
    sortname: "TN",
    name: "Tunisia",
  },
  {
    id: "223",
    sortname: "TR",
    name: "Turkey",
  },
  {
    id: "224",
    sortname: "TM",
    name: "Turkmenistan",
  },
  {
    id: "225",
    sortname: "TC",
    name: "Turks And Caicos Islands",
  },
  {
    id: "226",
    sortname: "TV",
    name: "Tuvalu",
  },
  {
    id: "227",
    sortname: "UG",
    name: "Uganda",
  },
  {
    id: "228",
    sortname: "UA",
    name: "Ukraine",
  },
  {
    id: "229",
    sortname: "AE",
    name: "United Arab Emirates",
  },
  {
    id: "230",
    sortname: "GB",
    name: "United Kingdom",
  },
  {
    id: "232",
    sortname: "UM",
    name: "United States Minor Outlying Islands",
  },
  {
    id: "233",
    sortname: "UY",
    name: "Uruguay",
  },
  {
    id: "234",
    sortname: "UZ",
    name: "Uzbekistan",
  },
  {
    id: "235",
    sortname: "VU",
    name: "Vanuatu",
  },
  {
    id: "236",
    sortname: "VA",
    name: "Vatican City State (Holy See)",
  },
  {
    id: "237",
    sortname: "VE",
    name: "Venezuela",
  },
  {
    id: "238",
    sortname: "VN",
    name: "Vietnam",
  },
  {
    id: "239",
    sortname: "VG",
    name: "Virgin Islands (British)",
  },
  {
    id: "240",
    sortname: "VI",
    name: "Virgin Islands (US)",
  },
  {
    id: "241",
    sortname: "WF",
    name: "Wallis And Futuna Islands",
  },
  {
    id: "242",
    sortname: "EH",
    name: "Western Sahara",
  },
  {
    id: "243",
    sortname: "YE",
    name: "Yemen",
  },
  {
    id: "244",
    sortname: "YU",
    name: "Yugoslavia",
  },
  {
    id: "245",
    sortname: "ZM",
    name: "Zambia",
  },
  {
    id: "246",
    sortname: "ZW",
    name: "Zimbabwe",
  },
];

export const checkoutCountries = [
  {
    name: "United States of America",
    "alpha-2": "US",
    "alpha-3": "USA",
    "country-code": "840",
    "iso_3166-2": "ISO 3166-2:US",
    region: "Americas",
    "sub-region": "Northern America",
    "region-code": "019",
    "sub-region-code": "021",
    states: [
      {
        name: "Alabama",
        code: "AL",
      },
      {
        name: "Alaska",
        code: "AK",
      },
      {
        name: "Arizona",
        code: "AZ",
      },
      {
        name: "Arkansas",
        code: "AR",
      },
      {
        name: "California",
        code: "CA",
      },
      {
        name: "Colorado",
        code: "CO",
      },
      {
        name: "Connecticut",
        code: "CT",
      },
      {
        name: "Delaware",
        code: "DE",
      },
      {
        name: "Florida",
        code: "FL",
      },
      {
        name: "Georgia",
        code: "GA",
      },
      {
        name: "Hawaii",
        code: "HI",
      },
      {
        name: "Idaho",
        code: "ID",
      },
      {
        name: "Illinois",
        code: "IL",
      },
      {
        name: "Indiana",
        code: "IN",
      },
      {
        name: "Iowa",
        code: "IA",
      },
      {
        name: "Kansas",
        code: "KS",
      },
      {
        name: "Kentucky",
        code: "KY",
      },
      {
        name: "Louisiana",
        code: "LA",
      },
      {
        name: "Maine",
        code: "ME",
      },
      {
        name: "Maryland",
        code: "MD",
      },
      {
        name: "Massachusetts",
        code: "MA",
      },
      {
        name: "Michigan",
        code: "MI",
      },
      {
        name: "Minnesota",
        code: "MN",
      },
      {
        name: "Mississippi",
        code: "MS",
      },
      {
        name: "Missouri",
        code: "MO",
      },
      {
        name: "Montana",
        code: "MT",
      },
      {
        name: "Nebraska",
        code: "NE",
      },
      {
        name: "Nevada",
        code: "NV",
      },
      {
        name: "New Hampshire",
        code: "NH",
      },
      {
        name: "New Jersey",
        code: "NJ",
      },
      {
        name: "New Mexico",
        code: "NM",
      },
      {
        name: "New York",
        code: "NY",
      },
      {
        name: "North Carolina",
        code: "NC",
      },
      {
        name: "North Dakota",
        code: "ND",
      },
      {
        name: "Ohio",
        code: "OH",
      },
      {
        name: "Oklahoma",
        code: "OK",
      },
      {
        name: "Oregon",
        code: "OR",
      },
      {
        name: "Pennsylvania",
        code: "PA",
      },
      {
        name: "Rhode Island",
        code: "RI",
      },
      {
        name: "South Carolina",
        code: "SC",
      },
      {
        name: "South Dakota",
        code: "SD",
      },
      {
        name: "Tennessee",
        code: "TN",
      },
      {
        name: "Texas",
        code: "TX",
      },
      {
        name: "Utah",
        code: "UT",
      },
      {
        name: "Vermont",
        code: "VT",
      },
      {
        name: "Virginia",
        code: "VA",
      },
      {
        name: "Washington",
        code: "WA",
      },
      {
        name: "West Virginia",
        code: "WV",
      },
      {
        name: "Wisconsin",
        code: "WI",
      },
      {
        name: "Wyoming",
        code: "WY",
      },
      {
        name: "District of Columbia",
        code: "DC",
      },
      {
        name: "American Samoa",
        code: "AS",
      },
      {
        name: "Guam",
        code: "GU",
      },
      {
        name: "Northern Mariana Islands",
        code: "MP",
      },
      {
        name: "Puerto Rico",
        code: "PR",
      },
      {
        name: "Virgin Islands",
        code: "VI",
      },
      {
        name: "United States Minor Outlying Islands",
        code: "UM",
      },
      {
        name: "Armed Forces Europe",
        code: "AE",
      },
      {
        name: "Armed Forces Americas",
        code: "AA",
      },
      {
        name: "Armed Forces Pacific",
        code: "AP",
      },
    ],
  },
  {
    name: "Afghanistan",
    "alpha-2": "AF",
    "alpha-3": "AFG",
    "country-code": "004",
    "iso_3166-2": "ISO 3166-2:AF",
    region: "Asia",
    "sub-region": "Southern Asia",
    "region-code": "142",
    "sub-region-code": "034",
    states: [
      {
        name: "Badakhshan",
        code: "BDS",
      },
      {
        name: "Badghis",
        code: "BDG",
      },
      {
        name: "Baghlan",
        code: "BGL",
      },
      {
        name: "Balkh",
        code: "BAL",
      },
      {
        name: "Bamian",
        code: "BAM",
      },
      {
        name: "Farah",
        code: "FRA",
      },
      {
        name: "Faryab",
        code: "FYB",
      },
      {
        name: "Ghazni",
        code: "GHA",
      },
      {
        name: "Ghowr",
        code: "GHO",
      },
      {
        name: "Helmand",
        code: "HEL",
      },
      {
        name: "Herat",
        code: "HER",
      },
      {
        name: "Jowzjan",
        code: "JOW",
      },
      {
        name: "Kabul",
        code: "KAB",
      },
      {
        name: "Kandahar",
        code: "KAN",
      },
      {
        name: "Kapisa",
        code: "KAP",
      },
      {
        name: "Khowst",
        code: "KHO",
      },
      {
        name: "Konar",
        code: "KNR",
      },
      {
        name: "Kondoz",
        code: "KDZ",
      },
      {
        name: "Laghman",
        code: "LAG",
      },
      {
        name: "Lowgar",
        code: "LOW",
      },
      {
        name: "Nangrahar",
        code: "NAN",
      },
      {
        name: "Nimruz",
        code: "NIM",
      },
      {
        name: "Nurestan",
        code: "NUR",
      },
      {
        name: "Oruzgan",
        code: "ORU",
      },
      {
        name: "Paktia",
        code: "PIA",
      },
      {
        name: "Paktika",
        code: "PKA",
      },
      {
        name: "Parwan",
        code: "PAR",
      },
      {
        name: "Samangan",
        code: "SAM",
      },
      {
        name: "Sar-e Pol",
        code: "SAR",
      },
      {
        name: "Takhar",
        code: "TAK",
      },
      {
        name: "Wardak",
        code: "WAR",
      },
      {
        name: "Zabol",
        code: "ZAB",
      },
    ],
  },
  {
    name: "Albania",
    "alpha-2": "AL",
    "alpha-3": "ALB",
    "country-code": "008",
    "iso_3166-2": "ISO 3166-2:AL",
    region: "Europe",
    "sub-region": "Southern Europe",
    "region-code": "150",
    "sub-region-code": "039",
    states: [
      {
        name: "Berat",
        code: "BR",
      },
      {
        name: "Bulqizë",
        code: "BU",
      },
      {
        name: "Delvinë",
        code: "DL",
      },
      {
        name: "Devoll",
        code: "DV",
      },
      {
        name: "Dibër",
        code: "DI",
      },
      {
        name: "Durrsës",
        code: "DR",
      },
      {
        name: "Elbasan",
        code: "EL",
      },
      {
        name: "Fier",
        code: "FR",
      },
      {
        name: "Gramsh",
        code: "GR",
      },
      {
        name: "Gjirokastër",
        code: "GJ",
      },
      {
        name: "Has",
        code: "HA",
      },
      {
        name: "Kavajë",
        code: "KA",
      },
      {
        name: "Kolonjë",
        code: "ER",
      },
      {
        name: "Korcë",
        code: "KO",
      },
      {
        name: "Krujë",
        code: "KR",
      },
      {
        name: "Kuçovë",
        code: "KC",
      },
      {
        name: "Kukës",
        code: "KU",
      },
      {
        name: "Kurbin",
        code: "KB",
      },
      {
        name: "Lezhë",
        code: "LE",
      },
      {
        name: "Librazhd",
        code: "LB",
      },
      {
        name: "Lushnjë",
        code: "LU",
      },
      {
        name: "Malësi e Madhe",
        code: "MM",
      },
      {
        name: "Mallakastër",
        code: "MK",
      },
      {
        name: "Mat",
        code: "MT",
      },
      {
        name: "Mirditë",
        code: "MR",
      },
      {
        name: "Peqin",
        code: "PQ",
      },
      {
        name: "Përmet",
        code: "PR",
      },
      {
        name: "Pogradec",
        code: "PG",
      },
      {
        name: "Pukë",
        code: "PU",
      },
      {
        name: "Sarandë",
        code: "SR",
      },
      {
        name: "Skrapar",
        code: "SK",
      },
      {
        name: "Shkodër",
        code: "SH",
      },
      {
        name: "Tepelenë",
        code: "TE",
      },
      {
        name: "Tiranë",
        code: "TR",
      },
      {
        name: "Tropojë",
        code: "TP",
      },
      {
        name: "Vlorë",
        code: "VL",
      },
    ],
  },
  {
    name: "Algeria",
    "alpha-2": "DZ",
    "alpha-3": "DZA",
    "country-code": "012",
    "iso_3166-2": "ISO 3166-2:DZ",
    region: "Africa",
    "sub-region": "Northern Africa",
    "region-code": "002",
    "sub-region-code": "015",
    states: [
      {
        name: "Adrar",
        code: "01",
      },
      {
        name: "Ain Defla",
        code: "44",
      },
      {
        name: "Ain Tmouchent",
        code: "46",
      },
      {
        name: "Alger",
        code: "16",
      },
      {
        name: "Annaba",
        code: "23",
      },
      {
        name: "Batna",
        code: "05",
      },
      {
        name: "Bechar",
        code: "08",
      },
      {
        name: "Bejaia",
        code: "06",
      },
      {
        name: "Biskra",
        code: "07",
      },
      {
        name: "Blida",
        code: "09",
      },
      {
        name: "Bordj Bou Arreridj",
        code: "34",
      },
      {
        name: "Bouira",
        code: "10",
      },
      {
        name: "Boumerdes",
        code: "35",
      },
      {
        name: "Chlef",
        code: "02",
      },
      {
        name: "Constantine",
        code: "25",
      },
      {
        name: "Djelfa",
        code: "17",
      },
      {
        name: "El Bayadh",
        code: "32",
      },
      {
        name: "El Oued",
        code: "39",
      },
      {
        name: "El Tarf",
        code: "36",
      },
      {
        name: "Ghardaia",
        code: "47",
      },
      {
        name: "Guelma",
        code: "24",
      },
      {
        name: "Illizi",
        code: "33",
      },
      {
        name: "Jijel",
        code: "18",
      },
      {
        name: "Khenchela",
        code: "40",
      },
      {
        name: "Laghouat",
        code: "03",
      },
      {
        name: "Mascara",
        code: "29",
      },
      {
        name: "Medea",
        code: "26",
      },
      {
        name: "Mila",
        code: "43",
      },
      {
        name: "Mostaganem",
        code: "27",
      },
      {
        name: "Msila",
        code: "28",
      },
      {
        name: "Naama",
        code: "45",
      },
      {
        name: "Oran",
        code: "31",
      },
      {
        name: "Ouargla",
        code: "30",
      },
      {
        name: "Oum el Bouaghi",
        code: "04",
      },
      {
        name: "Relizane",
        code: "48",
      },
      {
        name: "Saida",
        code: "20",
      },
      {
        name: "Setif",
        code: "19",
      },
      {
        name: "Sidi Bel Abbes",
        code: "22",
      },
      {
        name: "Skikda",
        code: "21",
      },
      {
        name: "Souk Ahras",
        code: "41",
      },
      {
        name: "Tamanghasset",
        code: "11",
      },
      {
        name: "Tebessa",
        code: "12",
      },
      {
        name: "Tiaret",
        code: "14",
      },
      {
        name: "Tindouf",
        code: "37",
      },
      {
        name: "Tipaza",
        code: "42",
      },
      {
        name: "Tissemsilt",
        code: "38",
      },
      {
        name: "Tizi Ouzou",
        code: "15",
      },
      {
        name: "Tlemcen",
        code: "13",
      },
    ],
  },
  {
    name: "American Samoa",
    "alpha-2": "AS",
    "alpha-3": "ASM",
    "country-code": "016",
    "iso_3166-2": "ISO 3166-2:AS",
    region: "Oceania",
    "sub-region": "Polynesia",
    "region-code": "009",
    "sub-region-code": "061",
    states: [],
  },
  {
    name: "Andorra",
    "alpha-2": "AD",
    "alpha-3": "AND",
    "country-code": "020",
    "iso_3166-2": "ISO 3166-2:AD",
    region: "Europe",
    "sub-region": "Southern Europe",
    "region-code": "150",
    "sub-region-code": "039",
    states: [],
  },
  {
    name: "Angola",
    "alpha-2": "AO",
    "alpha-3": "AGO",
    "country-code": "024",
    "iso_3166-2": "ISO 3166-2:AO",
    region: "Africa",
    "sub-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "017",
    states: [
      {
        name: "Bengo",
        code: "BGO",
      },
      {
        name: "Benguela",
        code: "BGU",
      },
      {
        name: "Bie",
        code: "BIE",
      },
      {
        name: "Cabinda",
        code: "CAB",
      },
      {
        name: "Cuando-Cubango",
        code: "CCU",
      },
      {
        name: "Cuanza Norte",
        code: "CNO",
      },
      {
        name: "Cuanza Sul",
        code: "CUS",
      },
      {
        name: "Cunene",
        code: "CNN",
      },
      {
        name: "Huambo",
        code: "HUA",
      },
      {
        name: "Huila",
        code: "HUI",
      },
      {
        name: "Luanda",
        code: "LUA",
      },
      {
        name: "Lunda Norte",
        code: "LNO",
      },
      {
        name: "Lunda Sul",
        code: "LSU",
      },
      {
        name: "Malange",
        code: "MAL",
      },
      {
        name: "Moxico",
        code: "MOX",
      },
      {
        name: "Namibe",
        code: "NAM",
      },
      {
        name: "Uige",
        code: "UIG",
      },
      {
        name: "Zaire",
        code: "ZAI",
      },
    ],
  },
  {
    name: "Anguilla",
    "alpha-2": "AI",
    "alpha-3": "AIA",
    "country-code": "660",
    "iso_3166-2": "ISO 3166-2:AI",
    region: "Americas",
    "sub-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "029",
    states: [],
  },
  {
    name: "Antarctica",
    "alpha-2": "AQ",
    "alpha-3": "ATA",
    "country-code": "010",
    "iso_3166-2": "ISO 3166-2:AQ",
    "sub-region-code": null,
    "region-code": null,
    "sub-region": null,
    region: null,
    states: [
      {
        name: "Australian Antarctic Territory",
        code: "AAT",
      },
    ],
  },
  {
    name: "Antigua and Barbuda",
    "alpha-2": "AG",
    "alpha-3": "ATG",
    "country-code": "028",
    "iso_3166-2": "ISO 3166-2:AG",
    region: "Americas",
    "sub-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "029",
    states: [],
  },
  {
    name: "Argentina",
    "alpha-2": "AR",
    "alpha-3": "ARG",
    "country-code": "032",
    "iso_3166-2": "ISO 3166-2:AR",
    region: "Americas",
    "sub-region": "South America",
    "region-code": "019",
    "sub-region-code": "005",
    states: [
      {
        name: "Capital federal",
        code: "C",
      },
      {
        name: "Buenos Aires",
        code: "B",
      },
      {
        name: "Catamarca",
        code: "K",
      },
      {
        name: "Cordoba",
        code: "X",
      },
      {
        name: "Corrientes",
        code: "W",
      },
      {
        name: "Chaco",
        code: "H",
      },
      {
        name: "Chubut",
        code: "U",
      },
      {
        name: "Entre Rios",
        code: "E",
      },
      {
        name: "Formosa",
        code: "P",
      },
      {
        name: "Jujuy",
        code: "Y",
      },
      {
        name: "La Pampa",
        code: "L",
      },
      {
        name: "Mendoza",
        code: "M",
      },
      {
        name: "Misiones",
        code: "N",
      },
      {
        name: "Neuquen",
        code: "Q",
      },
      {
        name: "Rio Negro",
        code: "R",
      },
      {
        name: "Salta",
        code: "A",
      },
      {
        name: "San Juan",
        code: "J",
      },
      {
        name: "San Luis",
        code: "D",
      },
      {
        name: "Santa Cruz",
        code: "Z",
      },
      {
        name: "Santa Fe",
        code: "S",
      },
      {
        name: "Santiago del Estero",
        code: "G",
      },
      {
        name: "Tierra del Fuego",
        code: "V",
      },
      {
        name: "Tucuman",
        code: "T",
      },
    ],
  },
  {
    name: "Armenia",
    "alpha-2": "AM",
    "alpha-3": "ARM",
    "country-code": "051",
    "iso_3166-2": "ISO 3166-2:AM",
    region: "Asia",
    "sub-region": "Western Asia",
    "region-code": "142",
    "sub-region-code": "145",
    states: [
      {
        name: "Erevan",
        code: "ER",
      },
      {
        name: "Aragacotn",
        code: "AG",
      },
      {
        name: "Ararat",
        code: "AR",
      },
      {
        name: "Armavir",
        code: "AV",
      },
      {
        name: "Gegarkunik'",
        code: "GR",
      },
      {
        name: "Kotayk'",
        code: "KT",
      },
      {
        name: "Lory",
        code: "LO",
      },
      {
        name: "Sirak",
        code: "SH",
      },
      {
        name: "Syunik'",
        code: "SU",
      },
      {
        name: "Tavus",
        code: "TV",
      },
      {
        name: "Vayoc Jor",
        code: "VD",
      },
    ],
  },
  {
    name: "Aruba",
    "alpha-2": "AW",
    "alpha-3": "ABW",
    "country-code": "533",
    "iso_3166-2": "ISO 3166-2:AW",
    region: "Americas",
    "sub-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "029",
    states: [],
  },
  {
    name: "Australia",
    "alpha-2": "AU",
    "alpha-3": "AUS",
    "country-code": "036",
    "iso_3166-2": "ISO 3166-2:AU",
    region: "Oceania",
    "sub-region": "Australia and New Zealand",
    "region-code": "009",
    "sub-region-code": "053",
    states: [
      {
        name: "Australian Capital Territory",
        code: "AC",
      },
      {
        name: "Northern Territory",
        code: "NO",
      },
      {
        name: "New South Wales",
        code: "NW",
      },
      {
        name: "Queensland",
        code: "QL",
      },
      {
        name: "South Australia",
        code: "SA",
      },
      {
        name: "Tasmania",
        code: "TS",
      },
      {
        name: "Victoria",
        code: "VC",
      },
      {
        name: "Western Australia",
        code: "WT",
      },
    ],
  },
  {
    name: "Austria",
    "alpha-2": "AT",
    "alpha-3": "AUT",
    "country-code": "040",
    "iso_3166-2": "ISO 3166-2:AT",
    region: "Europe",
    "sub-region": "Western Europe",
    "region-code": "150",
    "sub-region-code": "155",
    states: [
      {
        name: "Burgenland",
        code: "1",
      },
      {
        name: "Kärnten",
        code: "2",
      },
      {
        name: "Niederosterreich",
        code: "3",
      },
      {
        name: "Oberosterreich",
        code: "4",
      },
      {
        name: "Salzburg",
        code: "5",
      },
      {
        name: "Steiermark",
        code: "6",
      },
      {
        name: "Tirol",
        code: "7",
      },
      {
        name: "Vorarlberg",
        code: "8",
      },
      {
        name: "Wien",
        code: "9",
      },
    ],
  },
  {
    name: "Azerbaijan",
    "alpha-2": "AZ",
    "alpha-3": "AZE",
    "country-code": "031",
    "iso_3166-2": "ISO 3166-2:AZ",
    region: "Asia",
    "sub-region": "Western Asia",
    "region-code": "142",
    "sub-region-code": "145",
    states: [
      {
        name: "Naxcivan",
        code: "NX",
      },
      {
        name: "Ali Bayramli",
        code: "AB",
      },
      {
        name: "Baki",
        code: "BA",
      },
      {
        name: "Ganca",
        code: "GA",
      },
      {
        name: "Lankaran",
        code: "LA",
      },
      {
        name: "Mingacevir",
        code: "MI",
      },
      {
        name: "Naftalan",
        code: "NA",
      },
      {
        name: "Saki",
        code: "SA",
      },
      {
        name: "Sumqayit",
        code: "SM",
      },
      {
        name: "Susa",
        code: "SS",
      },
      {
        name: "Xankandi",
        code: "XA",
      },
      {
        name: "Yevlax",
        code: "YE",
      },
      {
        name: "Abseron",
        code: "ABS",
      },
      {
        name: "Agcabadi",
        code: "AGC",
      },
      {
        name: "Agdam",
        code: "AGM",
      },
      {
        name: "Agdas",
        code: "AGS",
      },
      {
        name: "Agstafa",
        code: "AGA",
      },
      {
        name: "Agsu",
        code: "AGU",
      },
      {
        name: "Astara",
        code: "AST",
      },
      {
        name: "Babak",
        code: "BAB",
      },
      {
        name: "Balakan",
        code: "BAL",
      },
      {
        name: "Barda",
        code: "BAR",
      },
      {
        name: "Beylagan",
        code: "BEY",
      },
      {
        name: "Bilasuvar",
        code: "BIL",
      },
      {
        name: "Cabrayll",
        code: "CAB",
      },
      {
        name: "Calilabad",
        code: "CAL",
      },
      {
        name: "Culfa",
        code: "CUL",
      },
      {
        name: "Daskasan",
        code: "DAS",
      },
      {
        name: "Davaci",
        code: "DAV",
      },
      {
        name: "Fuzuli",
        code: "FUZ",
      },
      {
        name: "Gadabay",
        code: "GAD",
      },
      {
        name: "Goranboy",
        code: "GOR",
      },
      {
        name: "Goycay",
        code: "GOY",
      },
      {
        name: "Haciqabul",
        code: "HAC",
      },
      {
        name: "Imisli",
        code: "IMI",
      },
      {
        name: "Ismayilli",
        code: "ISM",
      },
      {
        name: "Kalbacar",
        code: "KAL",
      },
      {
        name: "Kurdamir",
        code: "KUR",
      },
      {
        name: "Lacin",
        code: "LAC",
      },
      {
        name: "Lerik",
        code: "LER",
      },
      {
        name: "Masalli",
        code: "MAS",
      },
      {
        name: "Neftcala",
        code: "NEF",
      },
      {
        name: "Oguz",
        code: "OGU",
      },
      {
        name: "Ordubad",
        code: "ORD",
      },
      {
        name: "Qabala",
        code: "QAB",
      },
      {
        name: "Qax",
        code: "QAX",
      },
      {
        name: "Qazax",
        code: "QAZ",
      },
      {
        name: "Qobustan",
        code: "QOB",
      },
      {
        name: "Quba",
        code: "QBA",
      },
      {
        name: "Qubadli",
        code: "QBI",
      },
      {
        name: "Qusar",
        code: "QUS",
      },
      {
        name: "Saatli",
        code: "SAT",
      },
      {
        name: "Sabirabad",
        code: "SAB",
      },
      {
        name: "Sadarak",
        code: "SAD",
      },
      {
        name: "Sahbuz",
        code: "SAH",
      },
      {
        name: "Salyan",
        code: "SAL",
      },
      {
        name: "Samaxi",
        code: "SMI",
      },
      {
        name: "Samkir",
        code: "SKR",
      },
      {
        name: "Samux",
        code: "SMX",
      },
      {
        name: "Sarur",
        code: "SAR",
      },
      {
        name: "Siyazan",
        code: "SIY",
      },
      {
        name: "Tartar",
        code: "TAR",
      },
      {
        name: "Tovuz",
        code: "TOV",
      },
      {
        name: "Ucar",
        code: "UCA",
      },
      {
        name: "Xacmaz",
        code: "XAC",
      },
      {
        name: "Xanlar",
        code: "XAN",
      },
      {
        name: "Xizi",
        code: "XIZ",
      },
      {
        name: "Xocali",
        code: "XCI",
      },
      {
        name: "Xocavand",
        code: "XVD",
      },
      {
        name: "Yardimli",
        code: "YAR",
      },
      {
        name: "Zangilan",
        code: "ZAN",
      },
      {
        name: "Zaqatala",
        code: "ZAQ",
      },
      {
        name: "Zardab",
        code: "ZAR",
      },
    ],
  },
  {
    name: "Bahrain",
    "alpha-2": "BH",
    "alpha-3": "BHR",
    "country-code": "048",
    "iso_3166-2": "ISO 3166-2:BH",
    region: "Asia",
    "sub-region": "Western Asia",
    "region-code": "142",
    "sub-region-code": "145",
    states: [
      {
        name: "Al Hadd",
        code: "01",
      },
      {
        name: "Al Manamah",
        code: "03",
      },
      {
        name: "Al Mintaqah al Gharbiyah",
        code: "10",
      },
      {
        name: "Al Mintagah al Wusta",
        code: "07",
      },
      {
        name: "Al Mintaqah ash Shamaliyah",
        code: "05",
      },
      {
        name: "Al Muharraq",
        code: "02",
      },
      {
        name: "Ar Rifa",
        code: "09",
      },
      {
        name: "Jidd Hafs",
        code: "04",
      },
      {
        name: "Madluat Jamad",
        code: "12",
      },
      {
        name: "Madluat Isa",
        code: "08",
      },
      {
        name: "Mintaqat Juzur tawar",
        code: "11",
      },
      {
        name: "Sitrah",
        code: "06",
      },
      {
        name: "Al Manāmah (Al ‘Āşimah)",
        code: "13",
      },
      {
        name: "Al Janūbīyah",
        code: "14",
      },
      {
        name: "Al Wusţá",
        code: "16",
      },
      {
        name: "Ash Shamālīyah",
        code: "17",
      },
    ],
  },
  {
    name: "Bangladesh",
    "alpha-2": "BD",
    "alpha-3": "BGD",
    "country-code": "050",
    "iso_3166-2": "ISO 3166-2:BD",
    region: "Asia",
    "sub-region": "Southern Asia",
    "region-code": "142",
    "sub-region-code": "034",
    states: [
      {
        name: "Bagerhat zila",
        code: "05",
      },
      {
        name: "Bandarban zila",
        code: "01",
      },
      {
        name: "Barguna zila",
        code: "02",
      },
      {
        name: "Barisal zila",
        code: "06",
      },
      {
        name: "Bhola zila",
        code: "07",
      },
      {
        name: "Bogra zila",
        code: "03",
      },
      {
        name: "Brahmanbaria zila",
        code: "04",
      },
      {
        name: "Chandpur zila",
        code: "09",
      },
      {
        name: "Chittagong zila",
        code: "10",
      },
      {
        name: "Chuadanga zila",
        code: "12",
      },
      {
        name: "Comilla zila",
        code: "08",
      },
      {
        name: "Cox's Bazar zila",
        code: "11",
      },
      {
        name: "Dhaka zila",
        code: "13",
      },
      {
        name: "Dinajpur zila",
        code: "14",
      },
      {
        name: "Faridpur zila",
        code: "15",
      },
      {
        name: "Feni zila",
        code: "16",
      },
      {
        name: "Gaibandha zila",
        code: "19",
      },
      {
        name: "Gazipur zila",
        code: "18",
      },
      {
        name: "Gopalganj zila",
        code: "17",
      },
      {
        name: "Habiganj zila",
        code: "20",
      },
      {
        name: "Jaipurhat zila",
        code: "24",
      },
      {
        name: "Jamalpur zila",
        code: "21",
      },
      {
        name: "Jessore zila",
        code: "22",
      },
      {
        name: "Jhalakati zila",
        code: "25",
      },
      {
        name: "Jhenaidah zila",
        code: "23",
      },
      {
        name: "Khagrachari zila",
        code: "29",
      },
      {
        name: "Khulna zila",
        code: "27",
      },
      {
        name: "Kishorganj zila",
        code: "26",
      },
      {
        name: "Kurigram zila",
        code: "28",
      },
      {
        name: "Kushtia zila",
        code: "30",
      },
      {
        name: "Lakshmipur zila",
        code: "31",
      },
      {
        name: "Lalmonirhat zila",
        code: "32",
      },
      {
        name: "Madaripur zila",
        code: "36",
      },
      {
        name: "Magura zila",
        code: "37",
      },
      {
        name: "Manikganj zila",
        code: "33",
      },
      {
        name: "Meherpur zila",
        code: "39",
      },
      {
        name: "Moulvibazar zila",
        code: "38",
      },
      {
        name: "Munshiganj zila",
        code: "35",
      },
      {
        name: "Mymensingh zila",
        code: "34",
      },
      {
        name: "Naogaon zila",
        code: "48",
      },
      {
        name: "Narail zila",
        code: "43",
      },
      {
        name: "Narayanganj zila",
        code: "40",
      },
      {
        name: "Narsingdi zila",
        code: "42",
      },
      {
        name: "Natore zila",
        code: "44",
      },
      {
        name: "Nawabganj zila",
        code: "45",
      },
      {
        name: "Netrakona zila",
        code: "41",
      },
      {
        name: "Nilphamari zila",
        code: "46",
      },
      {
        name: "Noakhali zila",
        code: "47",
      },
      {
        name: "Pabna zila",
        code: "49",
      },
      {
        name: "Panchagarh zila",
        code: "52",
      },
      {
        name: "Patuakhali zila",
        code: "51",
      },
      {
        name: "Pirojpur zila",
        code: "50",
      },
      {
        name: "Rajbari zila",
        code: "53",
      },
      {
        name: "Rajshahi zila",
        code: "54",
      },
      {
        name: "Rangamati zila",
        code: "56",
      },
      {
        name: "Rangpur zila",
        code: "55",
      },
      {
        name: "Satkhira zila",
        code: "58",
      },
      {
        name: "Shariatpur zila",
        code: "62",
      },
      {
        name: "Sherpur zila",
        code: "57",
      },
      {
        name: "Sirajganj zila",
        code: "59",
      },
      {
        name: "Sunamganj zila",
        code: "61",
      },
      {
        name: "Sylhet zila",
        code: "60",
      },
      {
        name: "Tangail zila",
        code: "63",
      },
      {
        name: "Thakurgaon zila",
        code: "64",
      },
    ],
  },
  {
    name: "Barbados",
    "alpha-2": "BB",
    "alpha-3": "BRB",
    "country-code": "052",
    "iso_3166-2": "ISO 3166-2:BB",
    region: "Americas",
    "sub-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "029",
    states: [],
  },
  {
    name: "Belarus",
    "alpha-2": "BY",
    "alpha-3": "BLR",
    "country-code": "112",
    "iso_3166-2": "ISO 3166-2:BY",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "region-code": "150",
    "sub-region-code": "151",
    states: [
      {
        name: "Brèsckaja voblasc'",
        code: "BR",
      },
      {
        name: "Homel'skaja voblasc'",
        code: "HO",
      },
      {
        name: "Hrodzenskaja voblasc'",
        code: "HR",
      },
      {
        name: "Mahilëuskaja voblasc'",
        code: "MA",
      },
      {
        name: "Minskaja voblasc'",
        code: "MI",
      },
      {
        name: "Vicebskaja voblasc'",
        code: "VI",
      },
    ],
  },
  {
    name: "Belgium",
    "alpha-2": "BE",
    "alpha-3": "BEL",
    "country-code": "056",
    "iso_3166-2": "ISO 3166-2:BE",
    region: "Europe",
    "sub-region": "Western Europe",
    "region-code": "150",
    "sub-region-code": "155",
    states: [
      {
        name: "Antwerpen",
        code: "VAN",
      },
      {
        name: "Brabant Wallon",
        code: "WBR",
      },
      {
        name: "Hainaut",
        code: "WHT",
      },
      {
        name: "Liege",
        code: "WLG",
      },
      {
        name: "Limburg",
        code: "VLI",
      },
      {
        name: "Luxembourg",
        code: "WLX",
      },
      {
        name: "Namur",
        code: "WNA",
      },
      {
        name: "Oost-Vlaanderen",
        code: "VOV",
      },
      {
        name: "Vlaams-Brabant",
        code: "VBR",
      },
      {
        name: "West-Vlaanderen",
        code: "VWV",
      },
      {
        name: "Brussels",
        code: "BRU",
      },
    ],
  },
  {
    name: "Belize",
    "alpha-2": "BZ",
    "alpha-3": "BLZ",
    "country-code": "084",
    "iso_3166-2": "ISO 3166-2:BZ",
    region: "Americas",
    "sub-region": "Central America",
    "region-code": "019",
    "sub-region-code": "013",
    states: [
      {
        name: "Belize",
        code: "BZ",
      },
      {
        name: "Cayo",
        code: "CY",
      },
      {
        name: "Corozal",
        code: "CZL",
      },
      {
        name: "Orange Walk",
        code: "OW",
      },
      {
        name: "Stann Creek",
        code: "SC",
      },
      {
        name: "Toledo",
        code: "TOL",
      },
    ],
  },
  {
    name: "Benin",
    "alpha-2": "BJ",
    "alpha-3": "BEN",
    "country-code": "204",
    "iso_3166-2": "ISO 3166-2:BJ",
    region: "Africa",
    "sub-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "011",
    states: [
      {
        name: "Alibori",
        code: "AL",
      },
      {
        name: "Atakora",
        code: "AK",
      },
      {
        name: "Atlantique",
        code: "AQ",
      },
      {
        name: "Borgou",
        code: "BO",
      },
      {
        name: "Collines",
        code: "CO",
      },
      {
        name: "Donga",
        code: "DO",
      },
      {
        name: "Kouffo",
        code: "KO",
      },
      {
        name: "Littoral",
        code: "LI",
      },
      {
        name: "Mono",
        code: "MO",
      },
      {
        name: "Oueme",
        code: "OU",
      },
      {
        name: "Plateau",
        code: "PL",
      },
      {
        name: "Zou",
        code: "ZO",
      },
    ],
  },
  {
    name: "Bermuda",
    "alpha-2": "BM",
    "alpha-3": "BMU",
    "country-code": "060",
    "iso_3166-2": "ISO 3166-2:BM",
    region: "Americas",
    "sub-region": "Northern America",
    "region-code": "019",
    "sub-region-code": "021",
    states: [],
  },
  {
    name: "Bhutan",
    "alpha-2": "BT",
    "alpha-3": "BTN",
    "country-code": "064",
    "iso_3166-2": "ISO 3166-2:BT",
    region: "Asia",
    "sub-region": "Southern Asia",
    "region-code": "142",
    "sub-region-code": "034",
    states: [
      {
        name: "Bumthang",
        code: "33",
      },
      {
        name: "Chhukha",
        code: "12",
      },
      {
        name: "Dagana",
        code: "22",
      },
      {
        name: "Gasa",
        code: "GA",
      },
      {
        name: "Ha",
        code: "13",
      },
      {
        name: "Lhuentse",
        code: "44",
      },
      {
        name: "Monggar",
        code: "42",
      },
      {
        name: "Paro",
        code: "11",
      },
      {
        name: "Pemagatshel",
        code: "43",
      },
      {
        name: "Punakha",
        code: "23",
      },
      {
        name: "Samdrup Jongkha",
        code: "45",
      },
      {
        name: "Samtee",
        code: "14",
      },
      {
        name: "Sarpang",
        code: "31",
      },
      {
        name: "Thimphu",
        code: "15",
      },
      {
        name: "Trashigang",
        code: "41",
      },
      {
        name: "Trashi Yangtse",
        code: "TY",
      },
      {
        name: "Trongsa",
        code: "32",
      },
      {
        name: "Tsirang",
        code: "21",
      },
      {
        name: "Wangdue Phodrang",
        code: "24",
      },
      {
        name: "Zhemgang",
        code: "34",
      },
    ],
  },
  {
    name: "Bolivia (Plurinational State of)",
    "alpha-2": "BO",
    "alpha-3": "BOL",
    "country-code": "068",
    "iso_3166-2": "ISO 3166-2:BO",
    region: "Americas",
    "sub-region": "South America",
    "region-code": "019",
    "sub-region-code": "005",
    states: [
      {
        name: "Cochabamba",
        code: "C",
      },
      {
        name: "Chuquisaca",
        code: "H",
      },
      {
        name: "El Beni",
        code: "B",
      },
      {
        name: "La Paz",
        code: "L",
      },
      {
        name: "Oruro",
        code: "O",
      },
      {
        name: "Pando",
        code: "N",
      },
      {
        name: "Potosi",
        code: "P",
      },
      {
        name: "Tarija",
        code: "T",
      },
    ],
  },
  {
    name: "Bosnia and Herzegovina",
    "alpha-2": "BA",
    "alpha-3": "BIH",
    "country-code": "070",
    "iso_3166-2": "ISO 3166-2:BA",
    region: "Europe",
    "sub-region": "Southern Europe",
    "region-code": "150",
    "sub-region-code": "039",
    states: [
      {
        name: "Federacija Bosna i Hercegovina",
        code: "BIH",
      },
      {
        name: "Republika Srpska",
        code: "SRP",
      },
    ],
  },
  {
    name: "Botswana",
    "alpha-2": "BW",
    "alpha-3": "BWA",
    "country-code": "072",
    "iso_3166-2": "ISO 3166-2:BW",
    region: "Africa",
    "sub-region": "Southern Africa",
    "region-code": "002",
    "sub-region-code": "018",
    states: [
      {
        name: "Central",
        code: "CE",
      },
      {
        name: "Ghanzi",
        code: "GH",
      },
      {
        name: "Kgalagadi",
        code: "KG",
      },
      {
        name: "Kgatleng",
        code: "KL",
      },
      {
        name: "Kweneng",
        code: "KW",
      },
      {
        name: "Ngamiland",
        code: "NG",
      },
      {
        name: "North-East",
        code: "NE",
      },
      {
        name: "North-West",
        code: "NW",
      },
      {
        name: "South-East",
        code: "SE",
      },
      {
        name: "Southern",
        code: "SO",
      },
    ],
  },
  {
    name: "Bouvet Island",
    "alpha-2": "BV",
    "alpha-3": "BVT",
    "country-code": "074",
    "iso_3166-2": "ISO 3166-2:BV",
    "sub-region-code": null,
    "region-code": null,
    "sub-region": null,
    region: null,
    states: [],
  },
  {
    name: "Brazil",
    "alpha-2": "BR",
    "alpha-3": "BRA",
    "country-code": "076",
    "iso_3166-2": "ISO 3166-2:BR",
    region: "Americas",
    "sub-region": "South America",
    "region-code": "019",
    "sub-region-code": "005",
    states: [
      {
        name: "Acre",
        code: "AC",
      },
      {
        name: "Alagoas",
        code: "AL",
      },
      {
        name: "Amazonas",
        code: "AM",
      },
      {
        name: "Amapa",
        code: "AP",
      },
      {
        name: "Baia",
        code: "BA",
      },
      {
        name: "Ceara",
        code: "CE",
      },
      {
        name: "Distrito Federal",
        code: "DF",
      },
      {
        name: "Espirito Santo",
        code: "ES",
      },
      {
        name: "Fernando de Noronha",
        code: "FN",
      },
      {
        name: "Goias",
        code: "GO",
      },
      {
        name: "Maranhao",
        code: "MA",
      },
      {
        name: "Minas Gerais",
        code: "MG",
      },
      {
        name: "Mato Grosso do Sul",
        code: "MS",
      },
      {
        name: "Mato Grosso",
        code: "MT",
      },
      {
        name: "Para",
        code: "PA",
      },
      {
        name: "Paraiba",
        code: "PB",
      },
      {
        name: "Pernambuco",
        code: "PE",
      },
      {
        name: "Piaui",
        code: "PI",
      },
      {
        name: "Parana",
        code: "PR",
      },
      {
        name: "Rio de Janeiro",
        code: "RJ",
      },
      {
        name: "Rio Grande do Norte",
        code: "RN",
      },
      {
        name: "Rondonia",
        code: "RO",
      },
      {
        name: "Roraima",
        code: "RR",
      },
      {
        name: "Rio Grande do Sul",
        code: "RS",
      },
      {
        name: "Santa Catarina",
        code: "SC",
      },
      {
        name: "Sergipe",
        code: "SE",
      },
      {
        name: "Sao Paulo",
        code: "SP",
      },
      {
        name: "Tocatins",
        code: "TO",
      },
    ],
  },
  {
    name: "British Indian Ocean Territory",
    "alpha-2": "IO",
    "alpha-3": "IOT",
    "country-code": "086",
    "iso_3166-2": "ISO 3166-2:IO",
    "sub-region-code": null,
    "region-code": null,
    "sub-region": null,
    region: null,
    states: [],
  },
  {
    name: "Virgin Islands (British)",
    "alpha-2": "VG",
    "alpha-3": "VGB",
    "country-code": "092",
    "iso_3166-2": "ISO 3166-2:VG",
    region: "Americas",
    "sub-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "029",
    states: [],
  },
  {
    name: "Brunei Darussalam",
    "alpha-2": "BN",
    "alpha-3": "BRN",
    "country-code": "096",
    "iso_3166-2": "ISO 3166-2:BN",
    region: "Asia",
    "sub-region": "South-Eastern Asia",
    "region-code": "142",
    "sub-region-code": "035",
    states: [
      {
        name: "Belait",
        code: "BE",
      },
      {
        name: "Brunei-Muara",
        code: "BM",
      },
      {
        name: "Temburong",
        code: "TE",
      },
      {
        name: "Tutong",
        code: "TU",
      },
    ],
  },
  {
    name: "Bulgaria",
    "alpha-2": "BG",
    "alpha-3": "BGR",
    "country-code": "100",
    "iso_3166-2": "ISO 3166-2:BG",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "region-code": "150",
    "sub-region-code": "151",
    states: [
      {
        name: "Blagoevgrad",
        code: "01",
      },
      {
        name: "Burgas",
        code: "02",
      },
      {
        name: "Dobric",
        code: "08",
      },
      {
        name: "Gabrovo",
        code: "07",
      },
      {
        name: "Haskovo",
        code: "26",
      },
      {
        name: "Jambol",
        code: "28",
      },
      {
        name: "Kardzali",
        code: "09",
      },
      {
        name: "Kjstendil",
        code: "10",
      },
      {
        name: "Lovec",
        code: "11",
      },
      {
        name: "Montana",
        code: "12",
      },
      {
        name: "Pazardzik",
        code: "13",
      },
      {
        name: "Pernik",
        code: "14",
      },
      {
        name: "Pleven",
        code: "15",
      },
      {
        name: "Plovdiv",
        code: "16",
      },
      {
        name: "Razgrad",
        code: "17",
      },
      {
        name: "Ruse",
        code: "18",
      },
      {
        name: "Silistra",
        code: "19",
      },
      {
        name: "Sliven",
        code: "20",
      },
      {
        name: "Smoljan",
        code: "21",
      },
      {
        name: "Sofia",
        code: "23",
      },
      {
        name: "Stara Zagora",
        code: "24",
      },
      {
        name: "Sumen",
        code: "27",
      },
      {
        name: "Targoviste",
        code: "25",
      },
      {
        name: "Varna",
        code: "03",
      },
      {
        name: "Veliko Tarnovo",
        code: "04",
      },
      {
        name: "Vidin",
        code: "05",
      },
      {
        name: "Vraca",
        code: "06",
      },
    ],
  },
  {
    name: "Burkina Faso",
    "alpha-2": "BF",
    "alpha-3": "BFA",
    "country-code": "854",
    "iso_3166-2": "ISO 3166-2:BF",
    region: "Africa",
    "sub-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "011",
    states: [
      {
        name: "Bale",
        code: "BAL",
      },
      {
        name: "Bam",
        code: "BAM",
      },
      {
        name: "Banwa",
        code: "BAN",
      },
      {
        name: "Bazega",
        code: "BAZ",
      },
      {
        name: "Bougouriba",
        code: "BGR",
      },
      {
        name: "Boulgou",
        code: "BLG",
      },
      {
        name: "Boulkiemde",
        code: "BLK",
      },
      {
        name: "Comoe",
        code: "COM",
      },
      {
        name: "Ganzourgou",
        code: "GAN",
      },
      {
        name: "Gnagna",
        code: "GNA",
      },
      {
        name: "Gourma",
        code: "GOU",
      },
      {
        name: "Houet",
        code: "HOU",
      },
      {
        name: "Ioba",
        code: "IOB",
      },
      {
        name: "Kadiogo",
        code: "KAD",
      },
      {
        name: "Kenedougou",
        code: "KEN",
      },
      {
        name: "Komondjari",
        code: "KMD",
      },
      {
        name: "Kompienga",
        code: "KMP",
      },
      {
        name: "Kossi",
        code: "KOS",
      },
      {
        name: "Koulpulogo",
        code: "KOP",
      },
      {
        name: "Kouritenga",
        code: "KOT",
      },
      {
        name: "Kourweogo",
        code: "KOW",
      },
      {
        name: "Leraba",
        code: "LER",
      },
      {
        name: "Loroum",
        code: "LOR",
      },
      {
        name: "Mouhoun",
        code: "MOU",
      },
      {
        name: "Nahouri",
        code: "NAO",
      },
      {
        name: "Namentenga",
        code: "NAM",
      },
      {
        name: "Nayala",
        code: "NAY",
      },
      {
        name: "Noumbiel",
        code: "NOU",
      },
      {
        name: "Oubritenga",
        code: "OUB",
      },
      {
        name: "Oudalan",
        code: "OUD",
      },
      {
        name: "Passore",
        code: "PAS",
      },
      {
        name: "Poni",
        code: "PON",
      },
      {
        name: "Sanguie",
        code: "SNG",
      },
      {
        name: "Sanmatenga",
        code: "SMT",
      },
      {
        name: "Seno",
        code: "SEN",
      },
      {
        name: "Siasili",
        code: "SIS",
      },
      {
        name: "Soum",
        code: "SOM",
      },
      {
        name: "Sourou",
        code: "SOR",
      },
      {
        name: "Tapoa",
        code: "TAP",
      },
      {
        name: "Tui",
        code: "TUI",
      },
      {
        name: "Yagha",
        code: "YAG",
      },
      {
        name: "Yatenga",
        code: "YAT",
      },
      {
        name: "Ziro",
        code: "ZIR",
      },
      {
        name: "Zondoma",
        code: "ZON",
      },
      {
        name: "Zoundweogo",
        code: "ZOU",
      },
    ],
  },
  {
    name: "Myanmar",
    "alpha-2": "MM",
    "alpha-3": "MMR",
    "country-code": "104",
    "iso_3166-2": "ISO 3166-2:MM",
    region: "Asia",
    "sub-region": "South-Eastern Asia",
    "region-code": "142",
    "sub-region-code": "035",
    states: [
      {
        name: "Ayeyarwady",
        code: "07",
      },
      {
        name: "Bago",
        code: "02",
      },
      {
        name: "Magway",
        code: "03",
      },
      {
        name: "Mandalay",
        code: "04",
      },
      {
        name: "Sagaing",
        code: "01",
      },
      {
        name: "Tanintharyi",
        code: "05",
      },
      {
        name: "Yangon",
        code: "06",
      },
      {
        name: "Chin",
        code: "14",
      },
      {
        name: "Kachin",
        code: "11",
      },
      {
        name: "Kayah",
        code: "12",
      },
      {
        name: "Kayin",
        code: "13",
      },
      {
        name: "Mon",
        code: "15",
      },
      {
        name: "Rakhine",
        code: "16",
      },
      {
        name: "Shan",
        code: "17",
      },
    ],
  },
  {
    name: "Burundi",
    "alpha-2": "BI",
    "alpha-3": "BDI",
    "country-code": "108",
    "iso_3166-2": "ISO 3166-2:BI",
    region: "Africa",
    "sub-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "014",
    states: [
      {
        name: "Bubanza",
        code: "BB",
      },
      {
        name: "Bujumbura",
        code: "BJ",
      },
      {
        name: "Bururi",
        code: "BR",
      },
      {
        name: "Cankuzo",
        code: "CA",
      },
      {
        name: "Cibitoke",
        code: "CI",
      },
      {
        name: "Gitega",
        code: "GI",
      },
      {
        name: "Karuzi",
        code: "KR",
      },
      {
        name: "Kayanza",
        code: "KY",
      },
      {
        name: "Makamba",
        code: "MA",
      },
      {
        name: "Muramvya",
        code: "MU",
      },
      {
        name: "Mwaro",
        code: "MW",
      },
      {
        name: "Ngozi",
        code: "NG",
      },
      {
        name: "Rutana",
        code: "RT",
      },
      {
        name: "Ruyigi",
        code: "RY",
      },
    ],
  },
  {
    name: "Cambodia",
    "alpha-2": "KH",
    "alpha-3": "KHM",
    "country-code": "116",
    "iso_3166-2": "ISO 3166-2:KH",
    region: "Asia",
    "sub-region": "South-Eastern Asia",
    "region-code": "142",
    "sub-region-code": "035",
    states: [
      {
        name: "Krong Kaeb",
        code: "23",
      },
      {
        name: "Krong Pailin",
        code: "24",
      },
      {
        name: "Xrong Preah Sihanouk",
        code: "18",
      },
      {
        name: "Phnom Penh",
        code: "12",
      },
      {
        name: "Baat Dambang",
        code: "2",
      },
      {
        name: "Banteay Mean Chey",
        code: "1",
      },
      {
        name: "Rampong Chaam",
        code: "3",
      },
      {
        name: "Kampong Chhnang",
        code: "4",
      },
      {
        name: "Kampong Spueu",
        code: "5",
      },
      {
        name: "Kampong Thum",
        code: "6",
      },
      {
        name: "Kampot",
        code: "7",
      },
      {
        name: "Kandaal",
        code: "8",
      },
      {
        name: "Kach Kong",
        code: "9",
      },
      {
        name: "Krachoh",
        code: "10",
      },
      {
        name: "Mondol Kiri",
        code: "11",
      },
      {
        name: "Otdar Mean Chey",
        code: "22",
      },
      {
        name: "Pousaat",
        code: "15",
      },
      {
        name: "Preah Vihear",
        code: "13",
      },
      {
        name: "Prey Veaeng",
        code: "14",
      },
      {
        name: "Rotanak Kiri",
        code: "16",
      },
      {
        name: "Siem Reab",
        code: "17",
      },
      {
        name: "Stueng Traeng",
        code: "19",
      },
      {
        name: "Svaay Rieng",
        code: "20",
      },
      {
        name: "Taakaev",
        code: "21",
      },
    ],
  },
  {
    name: "Cameroon",
    "alpha-2": "CM",
    "alpha-3": "CMR",
    "country-code": "120",
    "iso_3166-2": "ISO 3166-2:CM",
    region: "Africa",
    "sub-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "017",
    states: [
      {
        name: "Adamaoua",
        code: "AD",
      },
      {
        name: "Centre",
        code: "CE",
      },
      {
        name: "East",
        code: "ES",
      },
      {
        name: "Far North",
        code: "EN",
      },
      {
        name: "North",
        code: "NO",
      },
      {
        name: "South",
        code: "SW",
      },
      {
        name: "South-West",
        code: "SW",
      },
      {
        name: "West",
        code: "OU",
      },
    ],
  },
  {
    name: "Canada",
    "alpha-2": "CA",
    "alpha-3": "CAN",
    "country-code": "124",
    "iso_3166-2": "ISO 3166-2:CA",
    region: "Americas",
    "sub-region": "Northern America",
    "region-code": "019",
    "sub-region-code": "021",
    states: [
      {
        name: "Alberta",
        code: "AB",
      },
      {
        name: "British Columbia",
        code: "BC",
      },
      {
        name: "Manitoba",
        code: "MB",
      },
      {
        name: "New Brunswick",
        code: "NB",
      },
      {
        name: "Newfoundland and Labrador",
        code: "NL",
      },
      {
        name: "Northwest Territories",
        code: "NT",
      },
      {
        name: "Nova Scotia",
        code: "NS",
      },
      {
        name: "Nunavut",
        code: "NU",
      },
      {
        name: "Ontario",
        code: "ON",
      },
      {
        name: "Prince Edward Island",
        code: "PE",
      },
      {
        name: "Quebec",
        code: "QC",
      },
      {
        name: "Saskatchewan",
        code: "SK",
      },
      {
        name: "Yukon Territory",
        code: "YT",
      },
    ],
  },
  {
    name: "Cabo Verde",
    "alpha-2": "CV",
    "alpha-3": "CPV",
    "country-code": "132",
    "iso_3166-2": "ISO 3166-2:CV",
    region: "Africa",
    "sub-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "011",
    states: [
      {
        name: "Boa Vista",
        code: "BV",
      },
      {
        name: "Brava",
        code: "BR",
      },
      {
        name: "Calheta de Sao Miguel",
        code: "CS",
      },
      {
        name: "Fogo",
        code: "FO",
      },
      {
        name: "Maio",
        code: "MA",
      },
      {
        name: "Mosteiros",
        code: "MO",
      },
      {
        name: "Paul",
        code: "PA",
      },
      {
        name: "Porto Novo",
        code: "PN",
      },
      {
        name: "Praia",
        code: "PR",
      },
      {
        name: "Ribeira Grande",
        code: "RG",
      },
      {
        name: "Sal",
        code: "SL",
      },
      {
        name: "Sao Domingos",
        code: "SD",
      },
      {
        name: "Sao Filipe",
        code: "SF",
      },
      {
        name: "Sao Nicolau",
        code: "SN",
      },
      {
        name: "Sao Vicente",
        code: "SV",
      },
      {
        name: "Tarrafal",
        code: "TA",
      },
    ],
  },
  {
    name: "Cayman Islands",
    "alpha-2": "KY",
    "alpha-3": "CYM",
    "country-code": "136",
    "iso_3166-2": "ISO 3166-2:KY",
    region: "Americas",
    "sub-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "029",
    states: [],
  },
  {
    name: "Central African Republic",
    "alpha-2": "CF",
    "alpha-3": "CAF",
    "country-code": "140",
    "iso_3166-2": "ISO 3166-2:CF",
    region: "Africa",
    "sub-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "017",
    states: [
      {
        name: "Bangui",
        code: "BGF",
      },
      {
        name: "Bamingui-Bangoran",
        code: "BB",
      },
      {
        name: "Basse-Kotto",
        code: "BK",
      },
      {
        name: "Haute-Kotto",
        code: "HK",
      },
      {
        name: "Haut-Mbomou",
        code: "HM",
      },
      {
        name: "Kemo",
        code: "KG",
      },
      {
        name: "Lobaye",
        code: "LB",
      },
      {
        name: "Mambere-Kadei",
        code: "HS",
      },
      {
        name: "Mbomou",
        code: "MB",
      },
      {
        name: "Nana-Grebizi",
        code: "KB",
      },
      {
        name: "Nana-Mambere",
        code: "NM",
      },
      {
        name: "Ombella-Mpoko",
        code: "MP",
      },
      {
        name: "Ouaka",
        code: "UK",
      },
      {
        name: "Ouham",
        code: "AC",
      },
      {
        name: "Ouham-Pende",
        code: "OP",
      },
      {
        name: "Sangha-Mbaere",
        code: "SE",
      },
      {
        name: "Vakaga",
        code: "VR",
      },
    ],
  },
  {
    name: "Chad",
    "alpha-2": "TD",
    "alpha-3": "TCD",
    "country-code": "148",
    "iso_3166-2": "ISO 3166-2:TD",
    region: "Africa",
    "sub-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "017",
    states: [
      {
        name: "Batha",
        code: "BA",
      },
      {
        name: "Biltine",
        code: "BI",
      },
      {
        name: "Borkou-Ennedi-Tibesti",
        code: "BET",
      },
      {
        name: "Chari-Baguirmi",
        code: "CB",
      },
      {
        name: "Guera",
        code: "GR",
      },
      {
        name: "Kanem",
        code: "KA",
      },
      {
        name: "Lac",
        code: "LC",
      },
      {
        name: "Logone-Occidental",
        code: "LO",
      },
      {
        name: "Logone-Oriental",
        code: "LR",
      },
      {
        name: "Mayo-Kebbi",
        code: "MK",
      },
      {
        name: "Moyen-Chari",
        code: "MC",
      },
      {
        name: "Ouaddai",
        code: "OD",
      },
      {
        name: "Salamat",
        code: "SA",
      },
      {
        name: "Tandjile",
        code: "TA",
      },
    ],
  },
  {
    name: "Chile",
    "alpha-2": "CL",
    "alpha-3": "CHL",
    "country-code": "152",
    "iso_3166-2": "ISO 3166-2:CL",
    region: "Americas",
    "sub-region": "South America",
    "region-code": "019",
    "sub-region-code": "005",
    states: [
      {
        name: "Aisen del General Carlos Ibanez del Campo",
        code: "AI",
      },
      {
        name: "Antofagasta",
        code: "AN",
      },
      {
        name: "Araucania",
        code: "AR",
      },
      {
        name: "Atacama",
        code: "AT",
      },
      {
        name: "Bio-Bio",
        code: "BI",
      },
      {
        name: "Coquimbo",
        code: "CO",
      },
      {
        name: "Libertador General Bernardo O'Higgins",
        code: "LI",
      },
      {
        name: "Los Lagos",
        code: "LL",
      },
      {
        name: "Magallanes",
        code: "MA",
      },
      {
        name: "Maule",
        code: "ML",
      },
      {
        name: "Region Metropolitana de Santiago",
        code: "RM",
      },
      {
        name: "Tarapaca",
        code: "TA",
      },
      {
        name: "Valparaiso",
        code: "VS",
      },
    ],
  },
  {
    name: "China",
    "alpha-2": "CN",
    "alpha-3": "CHN",
    "country-code": "156",
    "iso_3166-2": "ISO 3166-2:CN",
    region: "Asia",
    "sub-region": "Eastern Asia",
    "region-code": "142",
    "sub-region-code": "030",
    states: [
      {
        name: "Beijing",
        code: "11",
      },
      {
        name: "Chongqing",
        code: "50",
      },
      {
        name: "Shanghai",
        code: "31",
      },
      {
        name: "Tianjin",
        code: "12",
      },
      {
        name: "Anhui",
        code: "34",
      },
      {
        name: "Fujian",
        code: "35",
      },
      {
        name: "Gansu",
        code: "62",
      },
      {
        name: "Guangdong",
        code: "44",
      },
      {
        name: "Guizhou",
        code: "52",
      },
      {
        name: "Hainan",
        code: "46",
      },
      {
        name: "Hebei",
        code: "13",
      },
      {
        name: "Heilongjiang",
        code: "23",
      },
      {
        name: "Henan",
        code: "41",
      },
      {
        name: "Hubei",
        code: "42",
      },
      {
        name: "Hunan",
        code: "43",
      },
      {
        name: "Jiangsu",
        code: "32",
      },
      {
        name: "Jiangxi",
        code: "36",
      },
      {
        name: "Jilin",
        code: "22",
      },
      {
        name: "Liaoning",
        code: "21",
      },
      {
        name: "Qinghai",
        code: "63",
      },
      {
        name: "Shaanxi",
        code: "61",
      },
      {
        name: "Shandong",
        code: "37",
      },
      {
        name: "Shanxi",
        code: "14",
      },
      {
        name: "Sichuan",
        code: "51",
      },
      {
        name: "Taiwan",
        code: "71",
      },
      {
        name: "Yunnan",
        code: "53",
      },
      {
        name: "Zhejiang",
        code: "33",
      },
      {
        name: "Guangxi",
        code: "45",
      },
      {
        name: "Neia Mongol (mn)",
        code: "15",
      },
      {
        name: "Xinjiang",
        code: "65",
      },
      {
        name: "Xizang",
        code: "54",
      },
      {
        name: "Hong Kong",
        code: "91",
      },
      {
        name: "Macau",
        code: "92",
      },
    ],
  },
  {
    name: "Christmas Island",
    "alpha-2": "CX",
    "alpha-3": "CXR",
    "country-code": "162",
    "iso_3166-2": "ISO 3166-2:CX",
    "sub-region-code": null,
    "region-code": null,
    "sub-region": null,
    region: null,
    states: [],
  },
  {
    name: "Cocos (Keeling) Islands",
    "alpha-2": "CC",
    "alpha-3": "CCK",
    "country-code": "166",
    "iso_3166-2": "ISO 3166-2:CC",
    "sub-region-code": null,
    "region-code": null,
    "sub-region": null,
    region: null,
    states: [],
  },
  {
    name: "Colombia",
    "alpha-2": "CO",
    "alpha-3": "COL",
    "country-code": "170",
    "iso_3166-2": "ISO 3166-2:CO",
    region: "Americas",
    "sub-region": "South America",
    "region-code": "019",
    "sub-region-code": "005",
    states: [
      {
        name: "Distrito Capital de Bogotá",
        code: "DC",
      },
      {
        name: "Amazonea",
        code: "AMA",
      },
      {
        name: "Antioquia",
        code: "ANT",
      },
      {
        name: "Arauca",
        code: "ARA",
      },
      {
        name: "Atlántico",
        code: "ATL",
      },
      {
        name: "Bolívar",
        code: "BOL",
      },
      {
        name: "Boyacá",
        code: "BOY",
      },
      {
        name: "Caldea",
        code: "CAL",
      },
      {
        name: "Caquetá",
        code: "CAQ",
      },
      {
        name: "Casanare",
        code: "CAS",
      },
      {
        name: "Cauca",
        code: "CAU",
      },
      {
        name: "Cesar",
        code: "CES",
      },
      {
        name: "Córdoba",
        code: "COR",
      },
      {
        name: "Cundinamarca",
        code: "CUN",
      },
      {
        name: "Chocó",
        code: "CHO",
      },
      {
        name: "Guainía",
        code: "GUA",
      },
      {
        name: "Guaviare",
        code: "GUV",
      },
      {
        name: "La Guajira",
        code: "LAG",
      },
      {
        name: "Magdalena",
        code: "MAG",
      },
      {
        name: "Meta",
        code: "MET",
      },
      {
        name: "Nariño",
        code: "NAR",
      },
      {
        name: "Norte de Santander",
        code: "NSA",
      },
      {
        name: "Putumayo",
        code: "PUT",
      },
      {
        name: "Quindio",
        code: "QUI",
      },
      {
        name: "Risaralda",
        code: "RIS",
      },
      {
        name: "San Andrés, Providencia y Santa Catalina",
        code: "SAP",
      },
      {
        name: "Santander",
        code: "SAN",
      },
      {
        name: "Sucre",
        code: "SUC",
      },
      {
        name: "Tolima",
        code: "TOL",
      },
      {
        name: "Valle del Cauca",
        code: "VAC",
      },
      {
        name: "Vaupés",
        code: "VAU",
      },
      {
        name: "Vichada",
        code: "VID",
      },
    ],
  },
  {
    name: "Comoros",
    "alpha-2": "KM",
    "alpha-3": "COM",
    "country-code": "174",
    "iso_3166-2": "ISO 3166-2:KM",
    region: "Africa",
    "sub-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "014",
    states: [
      {
        name: "Anjouan Ndzouani",
        code: "A",
      },
      {
        name: "Grande Comore Ngazidja",
        code: "G",
      },
      {
        name: "Moheli Moili",
        code: "M",
      },
    ],
  },
  {
    name: "Congo (Democratic Republic of the)",
    "alpha-2": "CD",
    "alpha-3": "COD",
    "country-code": "180",
    "iso_3166-2": "ISO 3166-2:CD",
    region: "Africa",
    "sub-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "017",
    states: [
      {
        name: "Kinshasa",
        code: "KN",
      },
      {
        name: "Bandundu",
        code: "BN",
      },
      {
        name: "Bas-Congo",
        code: "BC",
      },
      {
        name: "Equateur",
        code: "EQ",
      },
      {
        name: "Haut-Congo",
        code: "HC",
      },
      {
        name: "Kasai-Occidental",
        code: "KW",
      },
      {
        name: "Kasai-Oriental",
        code: "KE",
      },
      {
        name: "Katanga",
        code: "KA",
      },
      {
        name: "Maniema",
        code: "MA",
      },
      {
        name: "Nord-Kivu",
        code: "NK",
      },
      {
        name: "Orientale",
        code: "OR",
      },
      {
        name: "Sud-Kivu",
        code: "SK",
      },
    ],
  },
  {
    name: "Congo",
    "alpha-2": "CG",
    "alpha-3": "COG",
    "country-code": "178",
    "iso_3166-2": "ISO 3166-2:CG",
    region: "Africa",
    "sub-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "017",
    states: [
      {
        name: "Brazzaville",
        code: "BZV",
      },
      {
        name: "Bouenza",
        code: "11",
      },
      {
        name: "Cuvette",
        code: "8",
      },
      {
        name: "Cuvette-Ouest",
        code: "15",
      },
      {
        name: "Kouilou",
        code: "5",
      },
      {
        name: "Lekoumou",
        code: "2",
      },
      {
        name: "Likouala",
        code: "7",
      },
      {
        name: "Niari",
        code: "9",
      },
      {
        name: "Plateaux",
        code: "14",
      },
      {
        name: "Pool",
        code: "12",
      },
      {
        name: "Sangha",
        code: "13",
      },
    ],
  },
  {
    name: "Cook Islands",
    "alpha-2": "CK",
    "alpha-3": "COK",
    "country-code": "184",
    "iso_3166-2": "ISO 3166-2:CK",
    region: "Oceania",
    "sub-region": "Polynesia",
    "region-code": "009",
    "sub-region-code": "061",
    states: [],
  },
  {
    name: "Costa Rica",
    "alpha-2": "CR",
    "alpha-3": "CRI",
    "country-code": "188",
    "iso_3166-2": "ISO 3166-2:CR",
    region: "Americas",
    "sub-region": "Central America",
    "region-code": "019",
    "sub-region-code": "013",
    states: [
      {
        name: "Alajuela",
        code: "A",
      },
      {
        name: "Cartago",
        code: "C",
      },
      {
        name: "Guanacaste",
        code: "G",
      },
      {
        name: "Heredia",
        code: "H",
      },
      {
        name: "Limon",
        code: "L",
      },
      {
        name: "Puntarenas",
        code: "P",
      },
      {
        name: "San Jose",
        code: "SJ",
      },
    ],
  },
  {
    name: "Côte d'Ivoire",
    "alpha-2": "CI",
    "alpha-3": "CIV",
    "country-code": "384",
    "iso_3166-2": "ISO 3166-2:CI",
    region: "Africa",
    "sub-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "011",
    states: [
      {
        name: "18 Montagnes",
        code: "06",
      },
      {
        name: "Agnebi",
        code: "16",
      },
      {
        name: "Bas-Sassandra",
        code: "09",
      },
      {
        name: "Denguele",
        code: "10",
      },
      {
        name: "Haut-Sassandra",
        code: "02",
      },
      {
        name: "Lacs",
        code: "07",
      },
      {
        name: "Lagunes",
        code: "01",
      },
      {
        name: "Marahoue",
        code: "12",
      },
      {
        name: "Moyen-Comoe",
        code: "05",
      },
      {
        name: "Nzi-Comoe",
        code: "11",
      },
      {
        name: "Savanes",
        code: "03",
      },
      {
        name: "Sud-Bandama",
        code: "15",
      },
      {
        name: "Sud-Comoe",
        code: "13",
      },
      {
        name: "Vallee du Bandama",
        code: "04",
      },
      {
        name: "Worodouqou",
        code: "14",
      },
      {
        name: "Zanzan",
        code: "08",
      },
    ],
  },
  {
    name: "Croatia",
    "alpha-2": "HR",
    "alpha-3": "HRV",
    "country-code": "191",
    "iso_3166-2": "ISO 3166-2:HR",
    region: "Europe",
    "sub-region": "Southern Europe",
    "region-code": "150",
    "sub-region-code": "039",
    states: [
      {
        name: "Bjelovarsko-bilogorska zupanija",
        code: "07",
      },
      {
        name: "Brodsko-posavska zupanija",
        code: "12",
      },
      {
        name: "Dubrovacko-neretvanska zupanija",
        code: "19",
      },
      {
        name: "Istarska zupanija",
        code: "18",
      },
      {
        name: "Karlovacka zupanija",
        code: "04",
      },
      {
        name: "Koprivnickco-krizevacka zupanija",
        code: "06",
      },
      {
        name: "Krapinako-zagorska zupanija",
        code: "02",
      },
      {
        name: "Licko-senjska zupanija",
        code: "09",
      },
      {
        name: "Medimurska zupanija",
        code: "20",
      },
      {
        name: "Osjecko-baranjska zupanija",
        code: "14",
      },
      {
        name: "Pozesko-slavonska zupanija",
        code: "11",
      },
      {
        name: "Primorsko-goranska zupanija",
        code: "08",
      },
      {
        name: "Sisacko-moelavacka Iupanija",
        code: "03",
      },
      {
        name: "Splitako-dalmatinska zupanija",
        code: "17",
      },
      {
        name: "Sibenako-kninska zupanija",
        code: "15",
      },
      {
        name: "Varaidinska zupanija",
        code: "05",
      },
      {
        name: "VirovitiEko-podravska zupanija",
        code: "10",
      },
      {
        name: "VuRovarako-srijemska zupanija",
        code: "16",
      },
      {
        name: "Zadaraka",
        code: "13",
      },
      {
        name: "Zagrebacka zupanija",
        code: "01",
      },
    ],
  },
  {
    name: "Cuba",
    "alpha-2": "CU",
    "alpha-3": "CUB",
    "country-code": "192",
    "iso_3166-2": "ISO 3166-2:CU",
    region: "Americas",
    "sub-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "029",
    states: [
      {
        name: "Camagey",
        code: "09",
      },
      {
        name: "Ciego de `vila",
        code: "08",
      },
      {
        name: "Cienfuegos",
        code: "06",
      },
      {
        name: "Ciudad de La Habana",
        code: "03",
      },
      {
        name: "Granma",
        code: "12",
      },
      {
        name: "Guantanamo",
        code: "14",
      },
      {
        name: "Holquin",
        code: "11",
      },
      {
        name: "La Habana",
        code: "02",
      },
      {
        name: "Las Tunas",
        code: "10",
      },
      {
        name: "Matanzas",
        code: "04",
      },
      {
        name: "Pinar del Rio",
        code: "01",
      },
      {
        name: "Sancti Spiritus",
        code: "07",
      },
      {
        name: "Santiago de Cuba",
        code: "13",
      },
      {
        name: "Villa Clara",
        code: "05",
      },
      {
        name: "Isla de la Juventud",
        code: "99",
      },
      {
        name: "Pinar del Roo",
        code: "PR",
      },
      {
        name: "Ciego de Avila",
        code: "CA",
      },
      {
        name: "Camagoey",
        code: "CG",
      },
      {
        name: "Holgun",
        code: "HO",
      },
      {
        name: "Sancti Spritus",
        code: "SS",
      },
      {
        name: "Municipio Especial Isla de la Juventud",
        code: "IJ",
      },
    ],
  },
  {
    name: "Cyprus",
    "alpha-2": "CY",
    "alpha-3": "CYP",
    "country-code": "196",
    "iso_3166-2": "ISO 3166-2:CY",
    region: "Asia",
    "sub-region": "Western Asia",
    "region-code": "142",
    "sub-region-code": "145",
    states: [
      {
        name: "Ammochostos Magusa",
        code: "04",
      },
      {
        name: "Keryneia",
        code: "06",
      },
      {
        name: "Larnaka",
        code: "03",
      },
      {
        name: "Lefkosia",
        code: "01",
      },
      {
        name: "Lemesos",
        code: "02",
      },
      {
        name: "Pafos",
        code: "05",
      },
    ],
  },
  {
    name: "Czech Republic",
    "alpha-2": "CZ",
    "alpha-3": "CZE",
    "country-code": "203",
    "iso_3166-2": "ISO 3166-2:CZ",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "region-code": "150",
    "sub-region-code": "151",
    states: [
      {
        name: "Jihočeský kraj",
        code: "JC",
      },
      {
        name: "Jihomoravský kraj",
        code: "JM",
      },
      {
        name: "Karlovarský kraj",
        code: "KA",
      },
      {
        name: "Královéhradecký kraj",
        code: "KR",
      },
      {
        name: "Liberecký kraj",
        code: "LI",
      },
      {
        name: "Moravskoslezský kraj",
        code: "MO",
      },
      {
        name: "Olomoucký kraj",
        code: "OL",
      },
      {
        name: "Pardubický kraj",
        code: "PA",
      },
      {
        name: "Plzeňský kraj",
        code: "PL",
      },
      {
        name: "Praha, hlavní město",
        code: "PR",
      },
      {
        name: "Středočeský kraj",
        code: "ST",
      },
      {
        name: "Ústecký kraj",
        code: "US",
      },
      {
        name: "Vysočina",
        code: "VY",
      },
      {
        name: "Zlínský kraj",
        code: "ZL",
      },
    ],
  },
  {
    name: "Denmark",
    "alpha-2": "DK",
    "alpha-3": "DNK",
    "country-code": "208",
    "iso_3166-2": "ISO 3166-2:DK",
    region: "Europe",
    "sub-region": "Northern Europe",
    "region-code": "150",
    "sub-region-code": "154",
    states: [
      {
        name: "Frederiksberg",
        code: "147",
      },
      {
        name: "Copenhagen City",
        code: "101",
      },
      {
        name: "Copenhagen",
        code: "015",
      },
      {
        name: "Frederiksborg",
        code: "020",
      },
      {
        name: "Roskilde",
        code: "025",
      },
      {
        name: "Vestsjælland",
        code: "030",
      },
      {
        name: "Storstrøm",
        code: "035",
      },
      {
        name: "Bornholm",
        code: "040",
      },
      {
        name: "Fyn",
        code: "042",
      },
      {
        name: "South Jutland",
        code: "050",
      },
      {
        name: "Ribe",
        code: "055",
      },
      {
        name: "Vejle",
        code: "060",
      },
      {
        name: "Ringkjøbing",
        code: "065",
      },
      {
        name: "Århus",
        code: "070",
      },
      {
        name: "Viborg",
        code: "076",
      },
      {
        name: "North Jutland",
        code: "080",
      },
    ],
  },
  {
    name: "Djibouti",
    "alpha-2": "DJ",
    "alpha-3": "DJI",
    "country-code": "262",
    "iso_3166-2": "ISO 3166-2:DJ",
    region: "Africa",
    "sub-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "014",
    states: [
      {
        name: "Ali Sabiah",
        code: "AS",
      },
      {
        name: "Dikhil",
        code: "DI",
      },
      {
        name: "Djibouti",
        code: "DJ",
      },
      {
        name: "Obock",
        code: "OB",
      },
      {
        name: "Tadjoura",
        code: "TA",
      },
    ],
  },
  {
    name: "Dominica",
    "alpha-2": "DM",
    "alpha-3": "DMA",
    "country-code": "212",
    "iso_3166-2": "ISO 3166-2:DM",
    region: "Americas",
    "sub-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "029",
    states: [],
  },
  {
    name: "Dominican Republic",
    "alpha-2": "DO",
    "alpha-3": "DOM",
    "country-code": "214",
    "iso_3166-2": "ISO 3166-2:DO",
    region: "Americas",
    "sub-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "029",
    states: [
      {
        name: "Distrito Nacional (Santo Domingo)",
        code: "01",
      },
      {
        name: "Azua",
        code: "02",
      },
      {
        name: "Bahoruco",
        code: "03",
      },
      {
        name: "Barahona",
        code: "04",
      },
      {
        name: "Dajabón",
        code: "05",
      },
      {
        name: "Duarte",
        code: "06",
      },
      {
        name: "El Seybo [El Seibo]",
        code: "08",
      },
      {
        name: "Espaillat",
        code: "09",
      },
      {
        name: "Hato Mayor",
        code: "30",
      },
      {
        name: "Independencia",
        code: "10",
      },
      {
        name: "La Altagracia",
        code: "11",
      },
      {
        name: "La Estrelleta [Elias Pina]",
        code: "07",
      },
      {
        name: "La Romana",
        code: "12",
      },
      {
        name: "La Vega",
        code: "13",
      },
      {
        name: "Maroia Trinidad Sánchez",
        code: "14",
      },
      {
        name: "Monseñor Nouel",
        code: "28",
      },
      {
        name: "Monte Cristi",
        code: "15",
      },
      {
        name: "Monte Plata",
        code: "29",
      },
      {
        name: "Pedernales",
        code: "16",
      },
      {
        name: "Peravia",
        code: "17",
      },
      {
        name: "Puerto Plata",
        code: "18",
      },
      {
        name: "Salcedo",
        code: "19",
      },
      {
        name: "Samaná",
        code: "20",
      },
      {
        name: "San Cristóbal",
        code: "21",
      },
      {
        name: "San Pedro de Macorís",
        code: "23",
      },
      {
        name: "Sánchez Ramírez",
        code: "24",
      },
      {
        name: "Santiago",
        code: "25",
      },
      {
        name: "Santiago Rodríguez",
        code: "26",
      },
      {
        name: "Valverde",
        code: "27",
      },
    ],
  },
  {
    name: "Timor-Leste",
    "alpha-2": "TL",
    "alpha-3": "TLS",
    "country-code": "626",
    "iso_3166-2": "ISO 3166-2:TL",
    region: "Asia",
    "sub-region": "South-Eastern Asia",
    "region-code": "142",
    "sub-region-code": "035",
    states: [
      {
        name: "Aileu",
        code: "AL",
      },
      {
        name: "Ainaro",
        code: "AN",
      },
      {
        name: "Bacucau",
        code: "BA",
      },
      {
        name: "Bobonaro",
        code: "BO",
      },
      {
        name: "Cova Lima",
        code: "CO",
      },
      {
        name: "Dili",
        code: "DI",
      },
      {
        name: "Ermera",
        code: "ER",
      },
      {
        name: "Laulem",
        code: "LA",
      },
      {
        name: "Liquica",
        code: "LI",
      },
      {
        name: "Manatuto",
        code: "MT",
      },
      {
        name: "Manafahi",
        code: "MF",
      },
      {
        name: "Oecussi",
        code: "OE",
      },
      {
        name: "Viqueque",
        code: "VI",
      },
    ],
  },
  {
    name: "Ecuador",
    "alpha-2": "EC",
    "alpha-3": "ECU",
    "country-code": "218",
    "iso_3166-2": "ISO 3166-2:EC",
    region: "Americas",
    "sub-region": "South America",
    "region-code": "019",
    "sub-region-code": "005",
    states: [
      {
        name: "Azuay",
        code: "A",
      },
      {
        name: "Bolivar",
        code: "B",
      },
      {
        name: "Canar",
        code: "F",
      },
      {
        name: "Carchi",
        code: "C",
      },
      {
        name: "Cotopaxi",
        code: "X",
      },
      {
        name: "Chimborazo",
        code: "H",
      },
      {
        name: "El Oro",
        code: "O",
      },
      {
        name: "Esmeraldas",
        code: "E",
      },
      {
        name: "Galapagos",
        code: "W",
      },
      {
        name: "Guayas",
        code: "G",
      },
      {
        name: "Imbabura",
        code: "I",
      },
      {
        name: "Loja",
        code: "L",
      },
      {
        name: "Los Rios",
        code: "R",
      },
      {
        name: "Manabi",
        code: "M",
      },
      {
        name: "Morona-Santiago",
        code: "S",
      },
      {
        name: "Napo",
        code: "N",
      },
      {
        name: "Orellana",
        code: "D",
      },
      {
        name: "Pastaza",
        code: "Y",
      },
      {
        name: "Pichincha",
        code: "P",
      },
      {
        name: "Sucumbios",
        code: "U",
      },
      {
        name: "Tungurahua",
        code: "T",
      },
      {
        name: "Zamora-Chinchipe",
        code: "Z",
      },
    ],
  },
  {
    name: "Egypt",
    "alpha-2": "EG",
    "alpha-3": "EGY",
    "country-code": "818",
    "iso_3166-2": "ISO 3166-2:EG",
    region: "Africa",
    "sub-region": "Northern Africa",
    "region-code": "002",
    "sub-region-code": "015",
    states: [
      {
        name: "Ad Daqahllyah",
        code: "DK",
      },
      {
        name: "Al Bahr al Ahmar",
        code: "BA",
      },
      {
        name: "Al Buhayrah",
        code: "BH",
      },
      {
        name: "Al Fayym",
        code: "FYM",
      },
      {
        name: "Al Gharbiyah",
        code: "GH",
      },
      {
        name: "Al Iskandarlyah",
        code: "ALX",
      },
      {
        name: "Al Isma illyah",
        code: "IS",
      },
      {
        name: "Al Jizah",
        code: "GZ",
      },
      {
        name: "Al Minuflyah",
        code: "MNF",
      },
      {
        name: "Al Minya",
        code: "MN",
      },
      {
        name: "Al Qahirah",
        code: "C",
      },
      {
        name: "Al Qalyublyah",
        code: "KB",
      },
      {
        name: "Al Wadi al Jadid",
        code: "WAD",
      },
      {
        name: "Ash Sharqiyah",
        code: "SHR",
      },
      {
        name: "As Suways",
        code: "SUZ",
      },
      {
        name: "Aswan",
        code: "ASN",
      },
      {
        name: "Asyut",
        code: "AST",
      },
      {
        name: "Bani Suwayf",
        code: "BNS",
      },
      {
        name: "Bur Sa'id",
        code: "PTS",
      },
      {
        name: "Dumyat",
        code: "DT",
      },
      {
        name: "Janub Sina'",
        code: "JS",
      },
      {
        name: "Kafr ash Shaykh",
        code: "KFS",
      },
      {
        name: "Matruh",
        code: "MT",
      },
      {
        name: "Qina",
        code: "KN",
      },
      {
        name: "Shamal Sina'",
        code: "SIN",
      },
      {
        name: "Suhaj",
        code: "SHG",
      },
    ],
  },
  {
    name: "El Salvador",
    "alpha-2": "SV",
    "alpha-3": "SLV",
    "country-code": "222",
    "iso_3166-2": "ISO 3166-2:SV",
    region: "Americas",
    "sub-region": "Central America",
    "region-code": "019",
    "sub-region-code": "013",
    states: [
      {
        name: "Ahuachapan",
        code: "AH",
      },
      {
        name: "Cabanas",
        code: "CA",
      },
      {
        name: "Cuscatlan",
        code: "CU",
      },
      {
        name: "Chalatenango",
        code: "CH",
      },
      {
        name: "Morazan",
        code: "MO",
      },
      {
        name: "San Miguel",
        code: "SM",
      },
      {
        name: "San Salvador",
        code: "SS",
      },
      {
        name: "Santa Ana",
        code: "SA",
      },
      {
        name: "San Vicente",
        code: "SV",
      },
      {
        name: "Sonsonate",
        code: "SO",
      },
      {
        name: "Usulutan",
        code: "US",
      },
    ],
  },
  {
    name: "Equatorial Guinea",
    "alpha-2": "GQ",
    "alpha-3": "GNQ",
    "country-code": "226",
    "iso_3166-2": "ISO 3166-2:GQ",
    region: "Africa",
    "sub-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "017",
    states: [
      {
        name: "Region Continental",
        code: "C",
      },
      {
        name: "Region Insular",
        code: "I",
      },
      {
        name: "Annobon",
        code: "AN",
      },
      {
        name: "Bioko Norte",
        code: "BN",
      },
      {
        name: "Bioko Sur",
        code: "BS",
      },
      {
        name: "Centro Sur",
        code: "CS",
      },
      {
        name: "Kie-Ntem",
        code: "KN",
      },
      {
        name: "Litoral",
        code: "LI",
      },
      {
        name: "Wele-Nzas",
        code: "WN",
      },
    ],
  },
  {
    name: "Eritrea",
    "alpha-2": "ER",
    "alpha-3": "ERI",
    "country-code": "232",
    "iso_3166-2": "ISO 3166-2:ER",
    region: "Africa",
    "sub-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "014",
    states: [
      {
        name: "Anseba",
        code: "AN",
      },
      {
        name: "Debub",
        code: "DU",
      },
      {
        name: "Debubawi Keyih Bahri [Debub-Keih-Bahri]",
        code: "DK",
      },
      {
        name: "Gash-Barka",
        code: "GB",
      },
      {
        name: "Maakel [Maekel]",
        code: "MA",
      },
      {
        name: "Semenawi Keyih Bahri [Semien-Keih-Bahri]",
        code: "SK",
      },
    ],
  },
  {
    name: "Estonia",
    "alpha-2": "EE",
    "alpha-3": "EST",
    "country-code": "233",
    "iso_3166-2": "ISO 3166-2:EE",
    region: "Europe",
    "sub-region": "Northern Europe",
    "region-code": "150",
    "sub-region-code": "154",
    states: [
      {
        name: "Harjumsa",
        code: "37",
      },
      {
        name: "Hitumea",
        code: "39",
      },
      {
        name: "Ida-Virumsa",
        code: "44",
      },
      {
        name: "Jogevamsa",
        code: "49",
      },
      {
        name: "Jarvamsa",
        code: "51",
      },
      {
        name: "Lasnemsa",
        code: "57",
      },
      {
        name: "Laane-Virumaa",
        code: "59",
      },
      {
        name: "Polvamea",
        code: "65",
      },
      {
        name: "Parnumsa",
        code: "67",
      },
      {
        name: "Raplamsa",
        code: "70",
      },
      {
        name: "Saaremsa",
        code: "74",
      },
      {
        name: "Tartumsa",
        code: "7B",
      },
      {
        name: "Valgamaa",
        code: "82",
      },
      {
        name: "Viljandimsa",
        code: "84",
      },
      {
        name: "Vorumaa",
        code: "86",
      },
    ],
  },
  {
    name: "Ethiopia",
    "alpha-2": "ET",
    "alpha-3": "ETH",
    "country-code": "231",
    "iso_3166-2": "ISO 3166-2:ET",
    region: "Africa",
    "sub-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "014",
    states: [
      {
        name: "Addis Ababa",
        code: "AA",
      },
      {
        name: "Dire Dawa",
        code: "DD",
      },
      {
        name: "Afar",
        code: "AF",
      },
      {
        name: "Amara",
        code: "AM",
      },
      {
        name: "Benshangul-Gumaz",
        code: "BE",
      },
      {
        name: "Gambela Peoples",
        code: "GA",
      },
      {
        name: "Harari People",
        code: "HA",
      },
      {
        name: "Oromia",
        code: "OR",
      },
      {
        name: "Somali",
        code: "SO",
      },
      {
        name: "Southern Nations, Nationalities and Peoples",
        code: "SN",
      },
      {
        name: "Tigrai",
        code: "TI",
      },
    ],
  },
  {
    name: "Falkland Islands (Malvinas)",
    "alpha-2": "FK",
    "alpha-3": "FLK",
    "country-code": "238",
    "iso_3166-2": "ISO 3166-2:FK",
    region: "Americas",
    "sub-region": "South America",
    "region-code": "019",
    "sub-region-code": "005",
    states: [],
  },
  {
    name: "Faroe Islands",
    "alpha-2": "FO",
    "alpha-3": "FRO",
    "country-code": "234",
    "iso_3166-2": "ISO 3166-2:FO",
    region: "Europe",
    "sub-region": "Northern Europe",
    "region-code": "150",
    "sub-region-code": "154",
    states: [],
  },
  {
    name: "Fiji",
    "alpha-2": "FJ",
    "alpha-3": "FJI",
    "country-code": "242",
    "iso_3166-2": "ISO 3166-2:FJ",
    region: "Oceania",
    "sub-region": "Melanesia",
    "region-code": "009",
    "sub-region-code": "054",
    states: [
      {
        name: "Eastern",
        code: "E",
      },
      {
        name: "Northern",
        code: "N",
      },
      {
        name: "Western",
        code: "W",
      },
      {
        name: "Rotuma",
        code: "R",
      },
    ],
  },
  {
    name: "Finland",
    "alpha-2": "FI",
    "alpha-3": "FIN",
    "country-code": "246",
    "iso_3166-2": "ISO 3166-2:FI",
    region: "Europe",
    "sub-region": "Northern Europe",
    "region-code": "150",
    "sub-region-code": "154",
    states: [
      {
        name: "South Karelia",
        code: "SK",
      },
      {
        name: "South Ostrobothnia",
        code: "SO",
      },
      {
        name: "Etelä-Savo",
        code: "ES",
      },
      {
        name: "Häme",
        code: "HH",
      },
      {
        name: "Itä-Uusimaa",
        code: "IU",
      },
      {
        name: "Kainuu",
        code: "KA",
      },
      {
        name: "Central Ostrobothnia",
        code: "CO",
      },
      {
        name: "Central Finland",
        code: "CF",
      },
      {
        name: "Kymenlaakso",
        code: "KY",
      },
      {
        name: "Lapland",
        code: "LA",
      },
      {
        name: "Tampere Region",
        code: "TR",
      },
      {
        name: "Ostrobothnia",
        code: "OB",
      },
      {
        name: "North Karelia",
        code: "NK",
      },
      {
        name: "Nothern Ostrobothnia",
        code: "NO",
      },
      {
        name: "Northern Savo",
        code: "NS",
      },
      {
        name: "Päijät-Häme",
        code: "PH",
      },
      {
        name: "Satakunta",
        code: "SK",
      },
      {
        name: "Uusimaa",
        code: "UM",
      },
      {
        name: "South-West Finland",
        code: "SW",
      },
      {
        name: "Åland",
        code: "AL",
      },
    ],
  },
  {
    name: "France",
    "alpha-2": "FR",
    "alpha-3": "FRA",
    "country-code": "250",
    "iso_3166-2": "ISO 3166-2:FR",
    region: "Europe",
    "sub-region": "Western Europe",
    "region-code": "150",
    "sub-region-code": "155",
    states: [
      {
        name: "Ain",
        code: "01",
      },
      {
        name: "Aisne",
        code: "02",
      },
      {
        name: "Allier",
        code: "03",
      },
      {
        name: "Alpes-de-Haute-Provence",
        code: "04",
      },
      {
        name: "Alpes-Maritimes",
        code: "06",
      },
      {
        name: "Ardèche",
        code: "07",
      },
      {
        name: "Ardennes",
        code: "08",
      },
      {
        name: "Ariège",
        code: "09",
      },
      {
        name: "Aube",
        code: "10",
      },
      {
        name: "Aude",
        code: "11",
      },
      {
        name: "Aveyron",
        code: "12",
      },
      {
        name: "Bas-Rhin",
        code: "67",
      },
      {
        name: "Bouches-du-Rhône",
        code: "13",
      },
      {
        name: "Calvados",
        code: "14",
      },
      {
        name: "Cantal",
        code: "15",
      },
      {
        name: "Charente",
        code: "16",
      },
      {
        name: "Charente-Maritime",
        code: "17",
      },
      {
        name: "Cher",
        code: "18",
      },
      {
        name: "Corrèze",
        code: "19",
      },
      {
        name: "Corse-du-Sud",
        code: "20A",
      },
      {
        name: "Côte-d'Or",
        code: "21",
      },
      {
        name: "Côtes-d'Armor",
        code: "22",
      },
      {
        name: "Creuse",
        code: "23",
      },
      {
        name: "Deux-Sèvres",
        code: "79",
      },
      {
        name: "Dordogne",
        code: "24",
      },
      {
        name: "Doubs",
        code: "25",
      },
      {
        name: "Drôme",
        code: "26",
      },
      {
        name: "Essonne",
        code: "91",
      },
      {
        name: "Eure",
        code: "27",
      },
      {
        name: "Eure-et-Loir",
        code: "28",
      },
      {
        name: "Finistère",
        code: "29",
      },
      {
        name: "Gard",
        code: "30",
      },
      {
        name: "Gers",
        code: "32",
      },
      {
        name: "Gironde",
        code: "33",
      },
      {
        name: "Haut-Rhin",
        code: "68",
      },
      {
        name: "Haute-Corse",
        code: "20B",
      },
      {
        name: "Haute-Garonne",
        code: "31",
      },
      {
        name: "Haute-Loire",
        code: "43",
      },
      {
        name: "Haute-Saône",
        code: "70",
      },
      {
        name: "Haute-Savoie",
        code: "74",
      },
      {
        name: "Haute-Vienne",
        code: "87",
      },
      {
        name: "Hautes-Alpes",
        code: "05",
      },
      {
        name: "Hautes-Pyrénées",
        code: "65",
      },
      {
        name: "Hauts-de-Seine",
        code: "92",
      },
      {
        name: "Hérault",
        code: "34",
      },
      {
        name: "Indre",
        code: "36",
      },
      {
        name: "Ille-et-Vilaine",
        code: "35",
      },
      {
        name: "Indre-et-Loire",
        code: "37",
      },
      {
        name: "Isère",
        code: "38",
      },
      {
        name: "Landes",
        code: "40",
      },
      {
        name: "Loir-et-Cher",
        code: "41",
      },
      {
        name: "Loire",
        code: "42",
      },
      {
        name: "Loire-Atlantique",
        code: "44",
      },
      {
        name: "Loiret",
        code: "45",
      },
      {
        name: "Lot",
        code: "46",
      },
      {
        name: "Lot-et-Garonne",
        code: "47",
      },
      {
        name: "Lozère",
        code: "48",
      },
      {
        name: "Maine-et-Loire",
        code: "49",
      },
      {
        name: "Manche",
        code: "50",
      },
      {
        name: "Marne",
        code: "51",
      },
      {
        name: "Mayenne",
        code: "53",
      },
      {
        name: "Meurthe-et-Moselle",
        code: "54",
      },
      {
        name: "Meuse",
        code: "55",
      },
      {
        name: "Morbihan",
        code: "56",
      },
      {
        name: "Moselle",
        code: "57",
      },
      {
        name: "Nièvre",
        code: "58",
      },
      {
        name: "Nord",
        code: "59",
      },
      {
        name: "Oise",
        code: "60",
      },
      {
        name: "Orne",
        code: "61",
      },
      {
        name: "Paris",
        code: "75",
      },
      {
        name: "Pas-de-Calais",
        code: "62",
      },
      {
        name: "Puy-de-Dôme",
        code: "63",
      },
      {
        name: "Pyrénées-Atlantiques",
        code: "64",
      },
      {
        name: "Pyrénées-Orientales",
        code: "66",
      },
      {
        name: "Rhône",
        code: "69",
      },
      {
        name: "Saône-et-Loire",
        code: "71",
      },
      {
        name: "Sarthe",
        code: "72",
      },
      {
        name: "Savoie",
        code: "73",
      },
      {
        name: "Seine-et-Marne",
        code: "77",
      },
      {
        name: "Seine-Maritime",
        code: "76",
      },
      {
        name: "Seine-Saint-Denis",
        code: "93",
      },
      {
        name: "Somme",
        code: "80",
      },
      {
        name: "Tarn",
        code: "81",
      },
      {
        name: "Tarn-et-Garonne",
        code: "82",
      },
      {
        name: "Val d'Oise",
        code: "95",
      },
      {
        name: "Territoire de Belfort",
        code: "90",
      },
      {
        name: "Val-de-Marne",
        code: "94",
      },
      {
        name: "Var",
        code: "83",
      },
      {
        name: "Vaucluse",
        code: "84",
      },
      {
        name: "Vendée",
        code: "85",
      },
      {
        name: "Vienne",
        code: "86",
      },
      {
        name: "Vosges",
        code: "88",
      },
      {
        name: "Yonne",
        code: "89",
      },
      {
        name: "Yvelines",
        code: "78",
      },
      {
        name: "Jura",
        code: "39",
      },
    ],
  },
  {
    name: "French Guiana",
    "alpha-2": "GF",
    "alpha-3": "GUF",
    "country-code": "254",
    "iso_3166-2": "ISO 3166-2:GF",
    region: "Americas",
    "sub-region": "South America",
    "region-code": "019",
    "sub-region-code": "005",
    states: [],
  },
  {
    name: "French Polynesia",
    "alpha-2": "PF",
    "alpha-3": "PYF",
    "country-code": "258",
    "iso_3166-2": "ISO 3166-2:PF",
    region: "Oceania",
    "sub-region": "Polynesia",
    "region-code": "009",
    "sub-region-code": "061",
    states: [],
  },
  {
    name: "French Southern Territories",
    "alpha-2": "TF",
    "alpha-3": "ATF",
    "country-code": "260",
    "iso_3166-2": "ISO 3166-2:TF",
    "sub-region-code": null,
    "region-code": null,
    "sub-region": null,
    region: null,
    states: [],
  },
  {
    name: "Gabon",
    "alpha-2": "GA",
    "alpha-3": "GAB",
    "country-code": "266",
    "iso_3166-2": "ISO 3166-2:GA",
    region: "Africa",
    "sub-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "017",
    states: [],
  },
  {
    name: "Georgia",
    "alpha-2": "GE",
    "alpha-3": "GEO",
    "country-code": "268",
    "iso_3166-2": "ISO 3166-2:GE",
    region: "Asia",
    "sub-region": "Western Asia",
    "region-code": "142",
    "sub-region-code": "145",
    states: [],
  },
  {
    name: "Germany",
    "alpha-2": "DE",
    "alpha-3": "DEU",
    "country-code": "276",
    "iso_3166-2": "ISO 3166-2:DE",
    region: "Europe",
    "sub-region": "Western Europe",
    "region-code": "150",
    "sub-region-code": "155",
    states: [
      {
        name: "Baden-Wuerttemberg",
        code: "BW",
      },
      {
        name: "Bayern",
        code: "BY",
      },
      {
        name: "Bremen",
        code: "HB",
      },
      {
        name: "Hamburg",
        code: "HH",
      },
      {
        name: "Hessen",
        code: "HE",
      },
      {
        name: "Niedersachsen",
        code: "NI",
      },
      {
        name: "Nordrhein-Westfalen",
        code: "NW",
      },
      {
        name: "Rheinland-Pfalz",
        code: "RP",
      },
      {
        name: "Saarland",
        code: "SL",
      },
      {
        name: "Schleswig-Holstein",
        code: "SH",
      },
      {
        name: "Berlin",
        code: "BR",
      },
      {
        name: "Brandenburg",
        code: "BB",
      },
      {
        name: "Mecklenburg-Vorpommern",
        code: "MV",
      },
      {
        name: "Sachsen",
        code: "SN",
      },
      {
        name: "Sachsen-Anhalt",
        code: "ST",
      },
      {
        name: "Thueringen",
        code: "TH",
      },
    ],
  },
  {
    name: "Ghana",
    "alpha-2": "GH",
    "alpha-3": "GHA",
    "country-code": "288",
    "iso_3166-2": "ISO 3166-2:GH",
    region: "Africa",
    "sub-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "011",
    states: [
      {
        name: "Ashanti",
        code: "AH",
      },
      {
        name: "Brong-Ahafo",
        code: "BA",
      },
      {
        name: "Greater Accra",
        code: "AA",
      },
      {
        name: "Upper East",
        code: "UE",
      },
      {
        name: "Upper West",
        code: "UW",
      },
      {
        name: "Volta",
        code: "TV",
      },
    ],
  },
  {
    name: "Gibraltar",
    "alpha-2": "GI",
    "alpha-3": "GIB",
    "country-code": "292",
    "iso_3166-2": "ISO 3166-2:GI",
    region: "Europe",
    "sub-region": "Southern Europe",
    "region-code": "150",
    "sub-region-code": "039",
    states: [],
  },
  {
    name: "Greece",
    "alpha-2": "GR",
    "alpha-3": "GRC",
    "country-code": "300",
    "iso_3166-2": "ISO 3166-2:GR",
    region: "Europe",
    "sub-region": "Southern Europe",
    "region-code": "150",
    "sub-region-code": "039",
    states: [
      {
        name: "Achaïa",
        code: "13",
      },
      {
        name: "Aitolia-Akarnania",
        code: "01",
      },
      {
        name: "Argolis",
        code: "11",
      },
      {
        name: "Arkadia",
        code: "12",
      },
      {
        name: "Arta",
        code: "31",
      },
      {
        name: "Attiki",
        code: "A1",
      },
      {
        name: "Chalkidiki",
        code: "64",
      },
      {
        name: "Chania",
        code: "94",
      },
      {
        name: "Chios",
        code: "85",
      },
      {
        name: "Dodekanisos",
        code: "81",
      },
      {
        name: "Drama",
        code: "52",
      },
      {
        name: "Evros",
        code: "71",
      },
      {
        name: "Evrytania",
        code: "05",
      },
      {
        name: "Evvoia",
        code: "04",
      },
      {
        name: "Florina",
        code: "63",
      },
      {
        name: "Fokis",
        code: "07",
      },
      {
        name: "Fthiotis",
        code: "06",
      },
      {
        name: "Grevena",
        code: "51",
      },
      {
        name: "Ileia",
        code: "14",
      },
      {
        name: "Imathia",
        code: "53",
      },
      {
        name: "Ioannina",
        code: "33",
      },
      {
        name: "Irakleion",
        code: "91",
      },
      {
        name: "Karditsa",
        code: "41",
      },
      {
        name: "Kastoria",
        code: "56",
      },
      {
        name: "Kavalla",
        code: "55",
      },
      {
        name: "Kefallinia",
        code: "23",
      },
      {
        name: "Kerkyra",
        code: "22",
      },
      {
        name: "Kilkis",
        code: "57",
      },
      {
        name: "Korinthia",
        code: "15",
      },
      {
        name: "Kozani",
        code: "58",
      },
      {
        name: "Kyklades",
        code: "82",
      },
      {
        name: "Lakonia",
        code: "16",
      },
      {
        name: "Larisa",
        code: "42",
      },
      {
        name: "Lasithion",
        code: "92",
      },
      {
        name: "Lefkas",
        code: "24",
      },
      {
        name: "Lesvos",
        code: "83",
      },
      {
        name: "Magnisia",
        code: "43",
      },
      {
        name: "Messinia",
        code: "17",
      },
      {
        name: "Pella",
        code: "59",
      },
      {
        name: "Preveza",
        code: "34",
      },
      {
        name: "Rethymnon",
        code: "93",
      },
      {
        name: "Rodopi",
        code: "73",
      },
      {
        name: "Samos",
        code: "84",
      },
      {
        name: "Serrai",
        code: "62",
      },
      {
        name: "Thesprotia",
        code: "32",
      },
      {
        name: "Thessaloniki",
        code: "54",
      },
      {
        name: "Trikala",
        code: "44",
      },
      {
        name: "Voiotia",
        code: "03",
      },
      {
        name: "Xanthi",
        code: "72",
      },
      {
        name: "Zakynthos",
        code: "21",
      },
      {
        name: "Agio Oros",
        code: "69",
      },
    ],
  },
  {
    name: "Greenland",
    "alpha-2": "GL",
    "alpha-3": "GRL",
    "country-code": "304",
    "iso_3166-2": "ISO 3166-2:GL",
    region: "Americas",
    "sub-region": "Northern America",
    "region-code": "019",
    "sub-region-code": "021",
    states: [],
  },
  {
    name: "Grenada",
    "alpha-2": "GD",
    "alpha-3": "GRD",
    "country-code": "308",
    "iso_3166-2": "ISO 3166-2:GD",
    region: "Americas",
    "sub-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "029",
    states: [],
  },
  {
    name: "Guadeloupe",
    "alpha-2": "GP",
    "alpha-3": "GLP",
    "country-code": "312",
    "iso_3166-2": "ISO 3166-2:GP",
    region: "Americas",
    "sub-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "029",
    states: [],
  },
  {
    name: "Guam",
    "alpha-2": "GU",
    "alpha-3": "GUM",
    "country-code": "316",
    "iso_3166-2": "ISO 3166-2:GU",
    region: "Oceania",
    "sub-region": "Micronesia",
    "region-code": "009",
    "sub-region-code": "057",
    states: [],
  },
  {
    name: "Guatemala",
    "alpha-2": "GT",
    "alpha-3": "GTM",
    "country-code": "320",
    "iso_3166-2": "ISO 3166-2:GT",
    region: "Americas",
    "sub-region": "Central America",
    "region-code": "019",
    "sub-region-code": "013",
    states: [
      {
        name: "Alta Verapez",
        code: "AV",
      },
      {
        name: "Baja Verapez",
        code: "BV",
      },
      {
        name: "Chimaltenango",
        code: "CM",
      },
      {
        name: "Chiquimula",
        code: "CQ",
      },
      {
        name: "El Progreso",
        code: "PR",
      },
      {
        name: "Escuintla",
        code: "ES",
      },
      {
        name: "Guatemala",
        code: "GU",
      },
      {
        name: "Huehuetenango",
        code: "HU",
      },
      {
        name: "Izabal",
        code: "IZ",
      },
      {
        name: "Jalapa",
        code: "JA",
      },
      {
        name: "Jutiapa",
        code: "JU",
      },
      {
        name: "Peten",
        code: "PE",
      },
      {
        name: "Quetzaltenango",
        code: "QZ",
      },
      {
        name: "Quiche",
        code: "QC",
      },
      {
        name: "Reta.thuleu",
        code: "RE",
      },
      {
        name: "Sacatepequez",
        code: "SA",
      },
      {
        name: "San Marcos",
        code: "SM",
      },
      {
        name: "Santa Rosa",
        code: "SR",
      },
      {
        name: "Solol6",
        code: "SO",
      },
      {
        name: "Suchitepequez",
        code: "SU",
      },
      {
        name: "Totonicapan",
        code: "TO",
      },
      {
        name: "Zacapa",
        code: "ZA",
      },
    ],
  },
  {
    name: "Guinea",
    "alpha-2": "GN",
    "alpha-3": "GIN",
    "country-code": "324",
    "iso_3166-2": "ISO 3166-2:GN",
    region: "Africa",
    "sub-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "011",
    states: [
      {
        name: "Beyla",
        code: "BE",
      },
      {
        name: "Boffa",
        code: "BF",
      },
      {
        name: "Boke",
        code: "BK",
      },
      {
        name: "Coyah",
        code: "CO",
      },
      {
        name: "Dabola",
        code: "DB",
      },
      {
        name: "Dalaba",
        code: "DL",
      },
      {
        name: "Dinguiraye",
        code: "DI",
      },
      {
        name: "Dubreka",
        code: "DU",
      },
      {
        name: "Faranah",
        code: "FA",
      },
      {
        name: "Forecariah",
        code: "FO",
      },
      {
        name: "Fria",
        code: "FR",
      },
      {
        name: "Gaoual",
        code: "GA",
      },
      {
        name: "Guekedou",
        code: "GU",
      },
      {
        name: "Kankan",
        code: "KA",
      },
      {
        name: "Kerouane",
        code: "KE",
      },
      {
        name: "Kindia",
        code: "KD",
      },
      {
        name: "Kissidougou",
        code: "KS",
      },
      {
        name: "Koubia",
        code: "KB",
      },
      {
        name: "Koundara",
        code: "KN",
      },
      {
        name: "Kouroussa",
        code: "KO",
      },
      {
        name: "Labe",
        code: "LA",
      },
      {
        name: "Lelouma",
        code: "LE",
      },
      {
        name: "Lola",
        code: "LO",
      },
      {
        name: "Macenta",
        code: "MC",
      },
      {
        name: "Mali",
        code: "ML",
      },
      {
        name: "Mamou",
        code: "MM",
      },
      {
        name: "Mandiana",
        code: "MD",
      },
      {
        name: "Nzerekore",
        code: "NZ",
      },
      {
        name: "Pita",
        code: "PI",
      },
      {
        name: "Siguiri",
        code: "SI",
      },
      {
        name: "Telimele",
        code: "TE",
      },
      {
        name: "Tougue",
        code: "TO",
      },
      {
        name: "Yomou",
        code: "YO",
      },
    ],
  },
  {
    name: "Guinea-Bissau",
    "alpha-2": "GW",
    "alpha-3": "GNB",
    "country-code": "624",
    "iso_3166-2": "ISO 3166-2:GW",
    region: "Africa",
    "sub-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "011",
    states: [
      {
        name: "Bissau",
        code: "BS",
      },
      {
        name: "Bafata",
        code: "BA",
      },
      {
        name: "Biombo",
        code: "BM",
      },
      {
        name: "Bolama",
        code: "BL",
      },
      {
        name: "Cacheu",
        code: "CA",
      },
      {
        name: "Gabu",
        code: "GA",
      },
      {
        name: "Oio",
        code: "OI",
      },
      {
        name: "Quloara",
        code: "QU",
      },
      {
        name: "Tombali S",
        code: "TO",
      },
    ],
  },
  {
    name: "Guyana",
    "alpha-2": "GY",
    "alpha-3": "GUY",
    "country-code": "328",
    "iso_3166-2": "ISO 3166-2:GY",
    region: "Americas",
    "sub-region": "South America",
    "region-code": "019",
    "sub-region-code": "005",
    states: [
      {
        name: "Barima-Waini",
        code: "BA",
      },
      {
        name: "Cuyuni-Mazaruni",
        code: "CU",
      },
      {
        name: "Demerara-Mahaica",
        code: "DE",
      },
      {
        name: "East Berbice-Corentyne",
        code: "EB",
      },
      {
        name: "Essequibo Islands-West Demerara",
        code: "ES",
      },
      {
        name: "Mahaica-Berbice",
        code: "MA",
      },
      {
        name: "Pomeroon-Supenaam",
        code: "PM",
      },
      {
        name: "Potaro-Siparuni",
        code: "PT",
      },
      {
        name: "Upper Demerara-Berbice",
        code: "UD",
      },
      {
        name: "Upper Takutu-Upper Essequibo",
        code: "UT",
      },
    ],
  },
  {
    name: "Haiti",
    "alpha-2": "HT",
    "alpha-3": "HTI",
    "country-code": "332",
    "iso_3166-2": "ISO 3166-2:HT",
    region: "Americas",
    "sub-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "029",
    states: [
      {
        name: "Grande-Anse",
        code: "GA",
      },
      {
        name: "Nord-Est",
        code: "NE",
      },
      {
        name: "Nord-Ouest",
        code: "NO",
      },
      {
        name: "Ouest",
        code: "OU",
      },
      {
        name: "Sud",
        code: "SD",
      },
      {
        name: "Sud-Est",
        code: "SE",
      },
      {
        name: "Artibonite",
        code: "AR",
      },
      {
        name: "Centre",
        code: "CE",
      },
      {
        name: "Nippes",
        code: "NI",
      },
      {
        name: "Nord",
        code: "ND",
      },
    ],
  },
  {
    name: "Heard Island and McDonald Islands",
    "alpha-2": "HM",
    "alpha-3": "HMD",
    "country-code": "334",
    "iso_3166-2": "ISO 3166-2:HM",
    "sub-region-code": null,
    "region-code": null,
    "sub-region": null,
    region: null,
    states: [],
  },
  {
    name: "Holy See",
    "alpha-2": "VA",
    "alpha-3": "VAT",
    "country-code": "336",
    "iso_3166-2": "ISO 3166-2:VA",
    region: "Europe",
    "sub-region": "Southern Europe",
    "region-code": "150",
    "sub-region-code": "039",
    states: [],
  },
  {
    name: "Honduras",
    "alpha-2": "HN",
    "alpha-3": "HND",
    "country-code": "340",
    "iso_3166-2": "ISO 3166-2:HN",
    region: "Americas",
    "sub-region": "Central America",
    "region-code": "019",
    "sub-region-code": "013",
    states: [
      {
        name: "Atlantida",
        code: "AT",
      },
      {
        name: "Colon",
        code: "CL",
      },
      {
        name: "Comayagua",
        code: "CM",
      },
      {
        name: "Copan",
        code: "CP",
      },
      {
        name: "Cortes",
        code: "CR",
      },
      {
        name: "Choluteca",
        code: "CH",
      },
      {
        name: "El Paraiso",
        code: "EP",
      },
      {
        name: "Francisco Morazan",
        code: "FM",
      },
      {
        name: "Gracias a Dios",
        code: "GD",
      },
      {
        name: "Intibuca",
        code: "IN",
      },
      {
        name: "Islas de la Bahia",
        code: "IB",
      },
      {
        name: "Lempira",
        code: "LE",
      },
      {
        name: "Ocotepeque",
        code: "OC",
      },
      {
        name: "Olancho",
        code: "OL",
      },
      {
        name: "Santa Barbara",
        code: "SB",
      },
      {
        name: "Valle",
        code: "VA",
      },
      {
        name: "Yoro",
        code: "YO",
      },
    ],
  },
  {
    name: "Hong Kong",
    "alpha-2": "HK",
    "alpha-3": "HKG",
    "country-code": "344",
    "iso_3166-2": "ISO 3166-2:HK",
    region: "Asia",
    "sub-region": "Eastern Asia",
    "region-code": "142",
    "sub-region-code": "030",
    states: [
      {
        name: "Central and Western",
        code: "CW",
      },
      {
        name: "Eastern",
        code: "EA",
      },
      {
        name: "Southern",
        code: "SO",
      },
      {
        name: "Wan Chai",
        code: "WC",
      },
      {
        name: "Kowloon City",
        code: "KC",
      },
      {
        name: "Kwun Tong",
        code: "KU",
      },
      {
        name: "Sham Shui Po",
        code: "SS",
      },
      {
        name: "Wong Tai Sin",
        code: "WT",
      },
      {
        name: "Yau Tsim Mong",
        code: "YT",
      },
      {
        name: "Islands",
        code: "IS",
      },
      {
        name: "Kwai Tsing",
        code: "KI",
      },
      {
        name: "North",
        code: "NO",
      },
      {
        name: "Sai Kung",
        code: "SK",
      },
      {
        name: "Sha Tin",
        code: "ST",
      },
      {
        name: "Tai Po",
        code: "TP",
      },
      {
        name: "Tsuen Wan",
        code: "TW",
      },
      {
        name: "Tuen Mun",
        code: "TM",
      },
      {
        name: "Yuen Long",
        code: "YL",
      },
    ],
  },
  {
    name: "Hungary",
    "alpha-2": "HU",
    "alpha-3": "HUN",
    "country-code": "348",
    "iso_3166-2": "ISO 3166-2:HU",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "region-code": "150",
    "sub-region-code": "151",
    states: [
      {
        name: "Budapest",
        code: "BU",
      },
      {
        name: "Bács-Kiskun",
        code: "BK",
      },
      {
        name: "Baranya",
        code: "BA",
      },
      {
        name: "Békés",
        code: "BE",
      },
      {
        name: "Borsod-Abaúj-Zemplén",
        code: "BZ",
      },
      {
        name: "Csongrád",
        code: "CS",
      },
      {
        name: "Fejér",
        code: "FE",
      },
      {
        name: "Győr-Moson-Sopron",
        code: "GS",
      },
      {
        name: "Hajdu-Bihar",
        code: "HB",
      },
      {
        name: "Heves",
        code: "HE",
      },
      {
        name: "Jász-Nagykun-Szolnok",
        code: "JN",
      },
      {
        name: "Komárom-Esztergom",
        code: "KE",
      },
      {
        name: "Nográd",
        code: "NO",
      },
      {
        name: "Pest",
        code: "PE",
      },
      {
        name: "Somogy",
        code: "SO",
      },
      {
        name: "Szabolcs-Szatmár-Bereg",
        code: "SZ",
      },
      {
        name: "Tolna",
        code: "TO",
      },
      {
        name: "Vas",
        code: "VA",
      },
      {
        name: "Veszprém",
        code: "VE",
      },
      {
        name: "Zala",
        code: "ZA",
      },
      {
        name: "Békéscsaba",
        code: "BC",
      },
      {
        name: "Debrecen",
        code: "DE",
      },
      {
        name: "Dunaújváros",
        code: "DU",
      },
      {
        name: "Eger",
        code: "EG",
      },
      {
        name: "Győr",
        code: "GY",
      },
      {
        name: "Hódmezővásárhely",
        code: "HV",
      },
      {
        name: "Kaposvár",
        code: "KV",
      },
      {
        name: "Kecskemét",
        code: "KM",
      },
      {
        name: "Miskolc",
        code: "MI",
      },
      {
        name: "Nagykanizsa",
        code: "NK",
      },
      {
        name: "Nyiregyháza",
        code: "NY",
      },
      {
        name: "Pécs",
        code: "PS",
      },
      {
        name: "Salgótarján",
        code: "ST",
      },
      {
        name: "Sopron",
        code: "SN",
      },
      {
        name: "Szeged",
        code: "SD",
      },
      {
        name: "Székesfehérvár",
        code: "SF",
      },
      {
        name: "Szekszárd",
        code: "SS",
      },
      {
        name: "Szolnok",
        code: "SK",
      },
      {
        name: "Szombathely",
        code: "SH",
      },
      {
        name: "Tatabánya",
        code: "TB",
      },
      {
        name: "Zalaegerszeg",
        code: "ZE",
      },
    ],
  },
  {
    name: "Iceland",
    "alpha-2": "IS",
    "alpha-3": "ISL",
    "country-code": "352",
    "iso_3166-2": "ISO 3166-2:IS",
    region: "Europe",
    "sub-region": "Northern Europe",
    "region-code": "150",
    "sub-region-code": "154",
    states: [
      {
        name: "Austurland",
        code: "7",
      },
      {
        name: "Hofuoborgarsvaeoi utan Reykjavikur",
        code: "1",
      },
      {
        name: "Norourland eystra",
        code: "6",
      },
      {
        name: "Norourland vestra",
        code: "5",
      },
      {
        name: "Reykjavik",
        code: "0",
      },
      {
        name: "Suourland",
        code: "8",
      },
      {
        name: "Suournes",
        code: "2",
      },
      {
        name: "Vestfirolr",
        code: "4",
      },
      {
        name: "Vesturland",
        code: "3",
      },
    ],
  },
  {
    name: "India",
    "alpha-2": "IN",
    "alpha-3": "IND",
    "country-code": "356",
    "iso_3166-2": "ISO 3166-2:IN",
    region: "Asia",
    "sub-region": "Southern Asia",
    "region-code": "142",
    "sub-region-code": "034",
    states: [
      {
        name: "Maharashtra",
        code: "MH",
      },
      {
        name: "Karnataka",
        code: "KA",
      },
      {
        name: "Andhra Pradesh",
        code: "AP",
      },
      {
        name: "Arunachal Pradesh",
        code: "AR",
      },
      {
        name: "Assam",
        code: "AS",
      },
      {
        name: "Bihar",
        code: "BR",
      },
      {
        name: "Chhattisgarh",
        code: "CH",
      },
      {
        name: "Goa",
        code: "GA",
      },
      {
        name: "Gujarat",
        code: "GJ",
      },
      {
        name: "Haryana",
        code: "HR",
      },
      {
        name: "Himachal Pradesh",
        code: "HP",
      },
      {
        name: "Jammu and Kashmir",
        code: "JK",
      },
      {
        name: "Jharkhand",
        code: "JH",
      },
      {
        name: "Kerala",
        code: "KL",
      },
      {
        name: "Madhya Pradesh",
        code: "MP",
      },
      {
        name: "Manipur",
        code: "MN",
      },
      {
        name: "Meghalaya",
        code: "ML",
      },
      {
        name: "Mizoram",
        code: "MZ",
      },
      {
        name: "Nagaland",
        code: "NL",
      },
      {
        name: "Orissa",
        code: "OR",
      },
      {
        name: "Punjab",
        code: "PB",
      },
      {
        name: "Rajasthan",
        code: "RJ",
      },
      {
        name: "Sikkim",
        code: "SK",
      },
      {
        name: "Tamil Nadu",
        code: "TN",
      },
      {
        name: "Tripura",
        code: "TR",
      },
      {
        name: "Uttaranchal",
        code: "UL",
      },
      {
        name: "Uttar Pradesh",
        code: "UP",
      },
      {
        name: "West Bengal",
        code: "WB",
      },
      {
        name: "Andaman and Nicobar Islands",
        code: "AN",
      },
      {
        name: "Dadra and Nagar Haveli",
        code: "DN",
      },
      {
        name: "Daman and Diu",
        code: "DD",
      },
      {
        name: "Delhi",
        code: "DL",
      },
      {
        name: "Lakshadweep",
        code: "LD",
      },
      {
        name: "Pondicherry",
        code: "PY",
      },
    ],
  },
  {
    name: "Indonesia",
    "alpha-2": "ID",
    "alpha-3": "IDN",
    "country-code": "360",
    "iso_3166-2": "ISO 3166-2:ID",
    region: "Asia",
    "sub-region": "South-Eastern Asia",
    "region-code": "142",
    "sub-region-code": "035",
    states: [
      {
        name: "Bali",
        code: "BA",
      },
      {
        name: "Bangka Belitung",
        code: "BB",
      },
      {
        name: "Banten",
        code: "BT",
      },
      {
        name: "Bengkulu",
        code: "BE",
      },
      {
        name: "Gorontalo",
        code: "GO",
      },
      {
        name: "Irian Jaya",
        code: "IJ",
      },
      {
        name: "Jambi",
        code: "JA",
      },
      {
        name: "Jawa Barat",
        code: "JB",
      },
      {
        name: "Jawa Tengah",
        code: "JT",
      },
      {
        name: "Jawa Timur",
        code: "JI",
      },
      {
        name: "Kalimantan Barat",
        code: "KB",
      },
      {
        name: "Kalimantan Timur",
        code: "KT",
      },
      {
        name: "Kalimantan Selatan",
        code: "KS",
      },
      {
        name: "Kepulauan Riau",
        code: "KR",
      },
      {
        name: "Lampung",
        code: "LA",
      },
      {
        name: "Maluku",
        code: "MA",
      },
      {
        name: "Maluku Utara",
        code: "MU",
      },
      {
        name: "Nusa Tenggara Barat",
        code: "NB",
      },
      {
        name: "Nusa Tenggara Timur",
        code: "NT",
      },
      {
        name: "Papua",
        code: "PA",
      },
      {
        name: "Riau",
        code: "RI",
      },
      {
        name: "Sulawesi Selatan",
        code: "SN",
      },
      {
        name: "Sulawesi Tengah",
        code: "ST",
      },
      {
        name: "Sulawesi Tenggara",
        code: "SG",
      },
      {
        name: "Sulawesi Utara",
        code: "SA",
      },
      {
        name: "Sumatra Barat",
        code: "SB",
      },
      {
        name: "Sumatra Selatan",
        code: "SS",
      },
      {
        name: "Sumatera Utara",
        code: "SU",
      },
      {
        name: "Jakarta Raya",
        code: "JK",
      },
      {
        name: "Aceh",
        code: "AC",
      },
      {
        name: "Yogyakarta",
        code: "YO",
      },
    ],
  },
  {
    name: "Iran (Islamic Republic of)",
    "alpha-2": "IR",
    "alpha-3": "IRN",
    "country-code": "364",
    "iso_3166-2": "ISO 3166-2:IR",
    region: "Asia",
    "sub-region": "Southern Asia",
    "region-code": "142",
    "sub-region-code": "034",
    states: [
      {
        name: "Ardabil",
        code: "03",
      },
      {
        name: "Azarbayjan-e Gharbi",
        code: "02",
      },
      {
        name: "Azarbayjan-e Sharqi",
        code: "01",
      },
      {
        name: "Bushehr",
        code: "06",
      },
      {
        name: "Chahar Mahall va Bakhtiari",
        code: "08",
      },
      {
        name: "Esfahan",
        code: "04",
      },
      {
        name: "Fars",
        code: "14",
      },
      {
        name: "Gilan",
        code: "19",
      },
      {
        name: "Golestan",
        code: "27",
      },
      {
        name: "Hamadan",
        code: "24",
      },
      {
        name: "Hormozgan",
        code: "23",
      },
      {
        name: "Iiam",
        code: "05",
      },
      {
        name: "Kerman",
        code: "15",
      },
      {
        name: "Kermanshah",
        code: "17",
      },
      {
        name: "Khorasan",
        code: "09",
      },
      {
        name: "Khuzestan",
        code: "10",
      },
      {
        name: "Kohjiluyeh va Buyer Ahmad",
        code: "18",
      },
      {
        name: "Kordestan",
        code: "16",
      },
      {
        name: "Lorestan",
        code: "20",
      },
      {
        name: "Markazi",
        code: "22",
      },
      {
        name: "Mazandaran",
        code: "21",
      },
      {
        name: "Qazvin",
        code: "28",
      },
      {
        name: "Qom",
        code: "26",
      },
      {
        name: "Semnan",
        code: "12",
      },
      {
        name: "Sistan va Baluchestan",
        code: "13",
      },
      {
        name: "Tehran",
        code: "07",
      },
      {
        name: "Yazd",
        code: "25",
      },
      {
        name: "Zanjan",
        code: "11",
      },
    ],
  },
  {
    name: "Iraq",
    "alpha-2": "IQ",
    "alpha-3": "IRQ",
    "country-code": "368",
    "iso_3166-2": "ISO 3166-2:IQ",
    region: "Asia",
    "sub-region": "Western Asia",
    "region-code": "142",
    "sub-region-code": "145",
    states: [
      {
        name: "Al Anbar",
        code: "AN",
      },
      {
        name: "Al Ba,rah",
        code: "BA",
      },
      {
        name: "Al Muthanna",
        code: "MU",
      },
      {
        name: "Al Qadisiyah",
        code: "QA",
      },
      {
        name: "An Najef",
        code: "NA",
      },
      {
        name: "Arbil",
        code: "AR",
      },
      {
        name: "As Sulaymaniyah",
        code: "SW",
      },
      {
        name: "At Ta'mim",
        code: "TS",
      },
      {
        name: "Babil",
        code: "BB",
      },
      {
        name: "Baghdad",
        code: "BG",
      },
      {
        name: "Dahuk",
        code: "DA",
      },
      {
        name: "Dhi Qar",
        code: "DQ",
      },
      {
        name: "Diyala",
        code: "DI",
      },
      {
        name: "Karbala'",
        code: "KA",
      },
      {
        name: "Maysan",
        code: "MA",
      },
      {
        name: "Ninawa",
        code: "NI",
      },
      {
        name: "Salah ad Din",
        code: "SD",
      },
      {
        name: "Wasit",
        code: "WA",
      },
    ],
  },
  {
    name: "Ireland",
    "alpha-2": "IE",
    "alpha-3": "IRL",
    "country-code": "372",
    "iso_3166-2": "ISO 3166-2:IE",
    region: "Europe",
    "sub-region": "Northern Europe",
    "region-code": "150",
    "sub-region-code": "154",
    states: [
      {
        name: "Cork",
        code: "C",
      },
      {
        name: "Clare",
        code: "CE",
      },
      {
        name: "Cavan",
        code: "CN",
      },
      {
        name: "Carlow",
        code: "CW",
      },
      {
        name: "Dublin",
        code: "D",
      },
      {
        name: "Donegal",
        code: "DL",
      },
      {
        name: "Galway",
        code: "G",
      },
      {
        name: "Kildare",
        code: "KE",
      },
      {
        name: "Kilkenny",
        code: "KK",
      },
      {
        name: "Kerry",
        code: "KY",
      },
      {
        name: "Longford",
        code: "LD",
      },
      {
        name: "Louth",
        code: "LH",
      },
      {
        name: "Limerick",
        code: "LK",
      },
      {
        name: "Leitrim",
        code: "LM",
      },
      {
        name: "Laois",
        code: "LS",
      },
      {
        name: "Meath",
        code: "MH",
      },
      {
        name: "Monaghan",
        code: "MN",
      },
      {
        name: "Mayo",
        code: "MO",
      },
      {
        name: "Offaly",
        code: "OY",
      },
      {
        name: "Roscommon",
        code: "RN",
      },
      {
        name: "Sligo",
        code: "SO",
      },
      {
        name: "Tipperary",
        code: "TA",
      },
      {
        name: "Waterford",
        code: "WD",
      },
      {
        name: "Westmeath",
        code: "WH",
      },
      {
        name: "Wicklow",
        code: "WW",
      },
      {
        name: "Wexford",
        code: "WX",
      },
    ],
  },
  {
    name: "Israel",
    "alpha-2": "IL",
    "alpha-3": "ISR",
    "country-code": "376",
    "iso_3166-2": "ISO 3166-2:IL",
    region: "Asia",
    "sub-region": "Western Asia",
    "region-code": "142",
    "sub-region-code": "145",
    states: [
      {
        name: "HaDarom",
        code: "D",
      },
      {
        name: "HaMerkaz",
        code: "M",
      },
      {
        name: "HaZafon",
        code: "Z",
      },
      {
        name: "Haifa",
        code: "HA",
      },
      {
        name: "Tel-Aviv",
        code: "TA",
      },
      {
        name: "Jerusalem",
        code: "JM",
      },
    ],
  },
  {
    name: "Italy",
    "alpha-2": "IT",
    "alpha-3": "ITA",
    "country-code": "380",
    "iso_3166-2": "ISO 3166-2:IT",
    region: "Europe",
    "sub-region": "Southern Europe",
    "region-code": "150",
    "sub-region-code": "039",
    states: [
      {
        name: "Agrigento",
        code: "AG",
      },
      {
        name: "Alessandria",
        code: "AL",
      },
      {
        name: "Ancona",
        code: "AN",
      },
      {
        name: "Aosta",
        code: "AO",
      },
      {
        name: "Arezzo",
        code: "AR",
      },
      {
        name: "Ascoli Piceno",
        code: "AP",
      },
      {
        name: "Asti",
        code: "AT",
      },
      {
        name: "Avellino",
        code: "AV",
      },
      {
        name: "Bari",
        code: "BA",
      },
      {
        name: "Belluno",
        code: "BL",
      },
      {
        name: "Benevento",
        code: "BN",
      },
      {
        name: "Bergamo",
        code: "BG",
      },
      {
        name: "Biella",
        code: "BI",
      },
      {
        name: "Bologna",
        code: "BO",
      },
      {
        name: "Bolzano",
        code: "BZ",
      },
      {
        name: "Brescia",
        code: "BS",
      },
      {
        name: "Brindisi",
        code: "BR",
      },
      {
        name: "Cagliari",
        code: "CA",
      },
      {
        name: "Caltanissetta",
        code: "CL",
      },
      {
        name: "Campobasso",
        code: "CB",
      },
      {
        name: "Caserta",
        code: "CE",
      },
      {
        name: "Catania",
        code: "CT",
      },
      {
        name: "Catanzaro",
        code: "CZ",
      },
      {
        name: "Chieti",
        code: "CH",
      },
      {
        name: "Como",
        code: "CO",
      },
      {
        name: "Cosenza",
        code: "CS",
      },
      {
        name: "Cremona",
        code: "CR",
      },
      {
        name: "Crotone",
        code: "KR",
      },
      {
        name: "Cuneo",
        code: "CN",
      },
      {
        name: "Enna",
        code: "EN",
      },
      {
        name: "Ferrara",
        code: "FE",
      },
      {
        name: "Firenze",
        code: "FI",
      },
      {
        name: "Foggia",
        code: "FG",
      },
      {
        name: "Forlì-Cesena",
        code: "FC",
      },
      {
        name: "Frosinone",
        code: "FR",
      },
      {
        name: "Genova",
        code: "GE",
      },
      {
        name: "Gorizia",
        code: "GO",
      },
      {
        name: "Grosseto",
        code: "GR",
      },
      {
        name: "Imperia",
        code: "IM",
      },
      {
        name: "Isernia",
        code: "IS",
      },
      {
        name: "L'Aquila",
        code: "AQ",
      },
      {
        name: "La Spezia",
        code: "SP",
      },
      {
        name: "Latina",
        code: "LT",
      },
      {
        name: "Lecce",
        code: "LE",
      },
      {
        name: "Lecco",
        code: "LC",
      },
      {
        name: "Livorno",
        code: "LI",
      },
      {
        name: "Lodi",
        code: "LO",
      },
      {
        name: "Lucca",
        code: "LU",
      },
      {
        name: "Macerata",
        code: "MC",
      },
      {
        name: "Mantova",
        code: "MN",
      },
      {
        name: "Massa-Carrara",
        code: "MS",
      },
      {
        name: "Matera",
        code: "MT",
      },
      {
        name: "Messina",
        code: "ME",
      },
      {
        name: "Milano",
        code: "MI",
      },
      {
        name: "Modena",
        code: "MO",
      },
      {
        name: "Napoli",
        code: "NA",
      },
      {
        name: "Novara",
        code: "NO",
      },
      {
        name: "Nuoro",
        code: "NU",
      },
      {
        name: "Oristano",
        code: "OR",
      },
      {
        name: "Padova",
        code: "PD",
      },
      {
        name: "Palermo",
        code: "PA",
      },
      {
        name: "Parma",
        code: "PR",
      },
      {
        name: "Pavia",
        code: "PV",
      },
      {
        name: "Perugia",
        code: "PG",
      },
      {
        name: "Pesaro e Urbino",
        code: "PU",
      },
      {
        name: "Pescara",
        code: "PE",
      },
      {
        name: "Piacenza",
        code: "PC",
      },
      {
        name: "Pisa",
        code: "PI",
      },
      {
        name: "Pistoia",
        code: "PT",
      },
      {
        name: "Pordenone",
        code: "PN",
      },
      {
        name: "Potenza",
        code: "PZ",
      },
      {
        name: "Prato",
        code: "PO",
      },
      {
        name: "Ragusa",
        code: "RG",
      },
      {
        name: "Ravenna",
        code: "RA",
      },
      {
        name: "Reggio Calabria",
        code: "RC",
      },
      {
        name: "Reggio Emilia",
        code: "RE",
      },
      {
        name: "Rieti",
        code: "RI",
      },
      {
        name: "Rimini",
        code: "RN",
      },
      {
        name: "Roma",
        code: "RM",
      },
      {
        name: "Rovigo",
        code: "RO",
      },
      {
        name: "Salerno",
        code: "SA",
      },
      {
        name: "Sassari",
        code: "SS",
      },
      {
        name: "Savona",
        code: "SV",
      },
      {
        name: "Siena",
        code: "SI",
      },
      {
        name: "Siracusa",
        code: "SR",
      },
      {
        name: "Sondrio",
        code: "SO",
      },
      {
        name: "Taranto",
        code: "TA",
      },
      {
        name: "Teramo",
        code: "TE",
      },
      {
        name: "Terni",
        code: "TR",
      },
      {
        name: "Torino",
        code: "TO",
      },
      {
        name: "Trapani",
        code: "TP",
      },
      {
        name: "Trento",
        code: "TN",
      },
      {
        name: "Treviso",
        code: "TV",
      },
      {
        name: "Trieste",
        code: "TS",
      },
      {
        name: "Udine",
        code: "UD",
      },
      {
        name: "Varese",
        code: "VA",
      },
      {
        name: "Venezia",
        code: "VE",
      },
      {
        name: "Verbano-Cusio-Ossola",
        code: "VB",
      },
      {
        name: "Vercelli",
        code: "VC",
      },
      {
        name: "Verona",
        code: "VR",
      },
      {
        name: "Vibo Valentia",
        code: "VV",
      },
      {
        name: "Vicenza",
        code: "VI",
      },
      {
        name: "Viterbo",
        code: "VT",
      },
      {
        name: "Carbonia-Iglesias",
        code: "CI",
      },
      {
        name: "Olbia-Tempio",
        code: "OT",
      },
      {
        name: "Medio Campidano",
        code: "VS",
      },
      {
        name: "Ogliastra",
        code: "OG",
      },
      {
        name: "Barletta-Andria-Trani",
        code: "Bar",
      },
      {
        name: "Fermo",
        code: "Fer",
      },
      {
        name: "Monza e Brianza",
        code: "Mon",
      },
    ],
  },
  {
    name: "Jamaica",
    "alpha-2": "JM",
    "alpha-3": "JAM",
    "country-code": "388",
    "iso_3166-2": "ISO 3166-2:JM",
    region: "Americas",
    "sub-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "029",
    states: [
      {
        name: "Clarendon",
        code: "CN",
      },
      {
        name: "Hanover",
        code: "HR",
      },
      {
        name: "Kingston",
        code: "KN",
      },
      {
        name: "Portland",
        code: "PD",
      },
      {
        name: "Saint Andrew",
        code: "AW",
      },
      {
        name: "Saint Ann",
        code: "AN",
      },
      {
        name: "Saint Catherine",
        code: "CE",
      },
      {
        name: "Saint Elizabeth",
        code: "EH",
      },
      {
        name: "Saint James",
        code: "JS",
      },
      {
        name: "Saint Mary",
        code: "MY",
      },
      {
        name: "Saint Thomas",
        code: "TS",
      },
      {
        name: "Trelawny",
        code: "TY",
      },
      {
        name: "Westmoreland",
        code: "WD",
      },
      {
        name: "Manchester",
        code: "MR",
      },
    ],
  },
  {
    name: "Japan",
    "alpha-2": "JP",
    "alpha-3": "JPN",
    "country-code": "392",
    "iso_3166-2": "ISO 3166-2:JP",
    region: "Asia",
    "sub-region": "Eastern Asia",
    "region-code": "142",
    "sub-region-code": "030",
    states: [
      {
        name: "Aichi",
        code: "23",
      },
      {
        name: "Akita",
        code: "05",
      },
      {
        name: "Aomori",
        code: "02",
      },
      {
        name: "Chiba",
        code: "12",
      },
      {
        name: "Ehime",
        code: "38",
      },
      {
        name: "Fukui",
        code: "18",
      },
      {
        name: "Fukuoka",
        code: "40",
      },
      {
        name: "Fukusima",
        code: "07",
      },
      {
        name: "Gifu",
        code: "21",
      },
      {
        name: "Gunma",
        code: "10",
      },
      {
        name: "Hiroshima",
        code: "34",
      },
      {
        name: "Hokkaido",
        code: "01",
      },
      {
        name: "Hyogo",
        code: "28",
      },
      {
        name: "Ibaraki",
        code: "08",
      },
      {
        name: "Ishikawa",
        code: "17",
      },
      {
        name: "Iwate",
        code: "03",
      },
      {
        name: "Kagawa",
        code: "37",
      },
      {
        name: "Kagoshima",
        code: "46",
      },
      {
        name: "Kanagawa",
        code: "14",
      },
      {
        name: "Kochi",
        code: "39",
      },
      {
        name: "Kumamoto",
        code: "43",
      },
      {
        name: "Kyoto",
        code: "26",
      },
      {
        name: "Mie",
        code: "24",
      },
      {
        name: "Miyagi",
        code: "04",
      },
      {
        name: "Miyazaki",
        code: "45",
      },
      {
        name: "Nagano",
        code: "20",
      },
      {
        name: "Nagasaki",
        code: "42",
      },
      {
        name: "Nara",
        code: "29",
      },
      {
        name: "Niigata",
        code: "15",
      },
      {
        name: "Oita",
        code: "44",
      },
      {
        name: "Okayama",
        code: "33",
      },
      {
        name: "Okinawa",
        code: "47",
      },
      {
        name: "Osaka",
        code: "27",
      },
      {
        name: "Saga",
        code: "41",
      },
      {
        name: "Saitama",
        code: "11",
      },
      {
        name: "Shiga",
        code: "25",
      },
      {
        name: "Shimane",
        code: "32",
      },
      {
        name: "Shizuoka",
        code: "22",
      },
      {
        name: "Tochigi",
        code: "09",
      },
      {
        name: "Tokushima",
        code: "36",
      },
      {
        name: "Tokyo",
        code: "13",
      },
      {
        name: "Tottori",
        code: "31",
      },
      {
        name: "Toyama",
        code: "16",
      },
      {
        name: "Wakayama",
        code: "30",
      },
      {
        name: "Yamagata",
        code: "06",
      },
      {
        name: "Yamaguchi",
        code: "35",
      },
      {
        name: "Yamanashi",
        code: "19",
      },
    ],
  },
  {
    name: "Jordan",
    "alpha-2": "JO",
    "alpha-3": "JOR",
    "country-code": "400",
    "iso_3166-2": "ISO 3166-2:JO",
    region: "Asia",
    "sub-region": "Western Asia",
    "region-code": "142",
    "sub-region-code": "145",
    states: [
      {
        name: "Ajln",
        code: "AJ",
      },
      {
        name: "Al 'Aqaba",
        code: "AQ",
      },
      {
        name: "Al Balqa'",
        code: "BA",
      },
      {
        name: "Al Karak",
        code: "KA",
      },
      {
        name: "Al Mafraq",
        code: "MA",
      },
      {
        name: "Amman",
        code: "AM",
      },
      {
        name: "At Tafilah",
        code: "AT",
      },
      {
        name: "Az Zarga",
        code: "AZ",
      },
      {
        name: "Irbid",
        code: "JR",
      },
      {
        name: "Jarash",
        code: "JA",
      },
      {
        name: "Ma'an",
        code: "MN",
      },
      {
        name: "Madaba",
        code: "MD",
      },
    ],
  },
  {
    name: "Kazakhstan",
    "alpha-2": "KZ",
    "alpha-3": "KAZ",
    "country-code": "398",
    "iso_3166-2": "ISO 3166-2:KZ",
    region: "Asia",
    "sub-region": "Central Asia",
    "region-code": "142",
    "sub-region-code": "143",
    states: [
      {
        name: "Almaty",
        code: "ALA",
      },
      {
        name: "Astana",
        code: "AST",
      },
      {
        name: "Almaty oblysy",
        code: "ALM",
      },
      {
        name: "Aqmola oblysy",
        code: "AKM",
      },
      {
        name: "Aqtobe oblysy",
        code: "AKT",
      },
      {
        name: "Atyrau oblyfiy",
        code: "ATY",
      },
      {
        name: "Batys Quzaqstan oblysy",
        code: "ZAP",
      },
      {
        name: "Mangghystau oblysy",
        code: "MAN",
      },
      {
        name: "Ongtustik Quzaqstan oblysy",
        code: "YUZ",
      },
      {
        name: "Pavlodar oblysy",
        code: "PAV",
      },
      {
        name: "Qaraghandy oblysy",
        code: "KAR",
      },
      {
        name: "Qostanay oblysy",
        code: "KUS",
      },
      {
        name: "Qyzylorda oblysy",
        code: "KZY",
      },
      {
        name: "Shyghys Quzaqstan oblysy",
        code: "VOS",
      },
      {
        name: "Soltustik Quzaqstan oblysy",
        code: "SEV",
      },
      {
        name: "Zhambyl oblysy Zhambylskaya oblast'",
        code: "ZHA",
      },
    ],
  },
  {
    name: "Kenya",
    "alpha-2": "KE",
    "alpha-3": "KEN",
    "country-code": "404",
    "iso_3166-2": "ISO 3166-2:KE",
    region: "Africa",
    "sub-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "014",
    states: [
      {
        name: "Nairobi Municipality",
        code: "110",
      },
      {
        name: "Coast",
        code: "300",
      },
      {
        name: "North-Eastern Kaskazini Mashariki",
        code: "500",
      },
      {
        name: "Rift Valley",
        code: "700",
      },
      {
        name: "Western Magharibi",
        code: "900",
      },
    ],
  },
  {
    name: "Kiribati",
    "alpha-2": "KI",
    "alpha-3": "KIR",
    "country-code": "296",
    "iso_3166-2": "ISO 3166-2:KI",
    region: "Oceania",
    "sub-region": "Micronesia",
    "region-code": "009",
    "sub-region-code": "057",
    states: [
      {
        name: "Gilbert Islands",
        code: "G",
      },
      {
        name: "Line Islands",
        code: "L",
      },
      {
        name: "Phoenix Islands",
        code: "P",
      },
    ],
  },
  {
    name: "Korea (Democratic People's Republic of)",
    "alpha-2": "KP",
    "alpha-3": "PRK",
    "country-code": "408",
    "iso_3166-2": "ISO 3166-2:KP",
    region: "Asia",
    "sub-region": "Eastern Asia",
    "region-code": "142",
    "sub-region-code": "030",
    states: [
      {
        name: "Kaesong-si",
        code: "KAE",
      },
      {
        name: "Nampo-si",
        code: "NAM",
      },
      {
        name: "Pyongyang-ai",
        code: "PYO",
      },
      {
        name: "Chagang-do",
        code: "CHA",
      },
      {
        name: "Hamgyongbuk-do",
        code: "HAB",
      },
      {
        name: "Hamgyongnam-do",
        code: "HAN",
      },
      {
        name: "Hwanghaebuk-do",
        code: "HWB",
      },
      {
        name: "Hwanghaenam-do",
        code: "HWN",
      },
      {
        name: "Kangwon-do",
        code: "KAN",
      },
      {
        name: "Pyonganbuk-do",
        code: "PYB",
      },
      {
        name: "Pyongannam-do",
        code: "PYN",
      },
      {
        name: "Yanggang-do",
        code: "YAN",
      },
      {
        name: "Najin Sonbong-si",
        code: "NAJ",
      },
    ],
  },
  {
    name: "Korea (Republic of)",
    "alpha-2": "KR",
    "alpha-3": "KOR",
    "country-code": "410",
    "iso_3166-2": "ISO 3166-2:KR",
    region: "Asia",
    "sub-region": "Eastern Asia",
    "region-code": "142",
    "sub-region-code": "030",
    states: [
      {
        name: "Seoul Teugbyeolsi",
        code: "11",
      },
      {
        name: "Busan Gwang'yeogsi",
        code: "26",
      },
      {
        name: "Daegu Gwang'yeogsi",
        code: "27",
      },
      {
        name: "Daejeon Gwang'yeogsi",
        code: "30",
      },
      {
        name: "Gwangju Gwang'yeogsi",
        code: "29",
      },
      {
        name: "Incheon Gwang'yeogsi",
        code: "28",
      },
      {
        name: "Ulsan Gwang'yeogsi",
        code: "31",
      },
      {
        name: "Chungcheongbugdo",
        code: "43",
      },
      {
        name: "Chungcheongnamdo",
        code: "44",
      },
      {
        name: "Gang'weondo",
        code: "42",
      },
      {
        name: "Gyeonggido",
        code: "41",
      },
      {
        name: "Gyeongsangbugdo",
        code: "47",
      },
      {
        name: "Gyeongsangnamdo",
        code: "48",
      },
      {
        name: "Jejudo",
        code: "49",
      },
      {
        name: "Jeonrabugdo",
        code: "45",
      },
      {
        name: "Jeonranamdo",
        code: "46",
      },
    ],
  },
  {
    name: "Kuwait",
    "alpha-2": "KW",
    "alpha-3": "KWT",
    "country-code": "414",
    "iso_3166-2": "ISO 3166-2:KW",
    region: "Asia",
    "sub-region": "Western Asia",
    "region-code": "142",
    "sub-region-code": "145",
    states: [
      {
        name: "Al Ahmadi",
        code: "AH",
      },
      {
        name: "Al Farwanlyah",
        code: "FA",
      },
      {
        name: "Al Jahrah",
        code: "JA",
      },
      {
        name: "Al Kuwayt",
        code: "KU",
      },
      {
        name: "Hawalli",
        code: "HA",
      },
    ],
  },
  {
    name: "Kyrgyzstan",
    "alpha-2": "KG",
    "alpha-3": "KGZ",
    "country-code": "417",
    "iso_3166-2": "ISO 3166-2:KG",
    region: "Asia",
    "sub-region": "Central Asia",
    "region-code": "142",
    "sub-region-code": "143",
    states: [
      {
        name: "Bishkek",
        code: "GB",
      },
      {
        name: "Batken",
        code: "B",
      },
      {
        name: "Chu",
        code: "C",
      },
      {
        name: "Jalal-Abad",
        code: "J",
      },
      {
        name: "Naryn",
        code: "N",
      },
      {
        name: "Osh",
        code: "O",
      },
      {
        name: "Talas",
        code: "T",
      },
      {
        name: "Ysyk-Kol",
        code: "Y",
      },
    ],
  },
  {
    name: "Lao People's Democratic Republic",
    "alpha-2": "LA",
    "alpha-3": "LAO",
    "country-code": "418",
    "iso_3166-2": "ISO 3166-2:LA",
    region: "Asia",
    "sub-region": "South-Eastern Asia",
    "region-code": "142",
    "sub-region-code": "035",
    states: [
      {
        name: "Vientiane",
        code: "VT",
      },
      {
        name: "Attapu",
        code: "AT",
      },
      {
        name: "Bokeo",
        code: "BK",
      },
      {
        name: "Bolikhamxai",
        code: "BL",
      },
      {
        name: "Champasak",
        code: "CH",
      },
      {
        name: "Houaphan",
        code: "HO",
      },
      {
        name: "Khammouan",
        code: "KH",
      },
      {
        name: "Louang Namtha",
        code: "LM",
      },
      {
        name: "Louangphabang",
        code: "LP",
      },
      {
        name: "Oudomxai",
        code: "OU",
      },
      {
        name: "Phongsali",
        code: "PH",
      },
      {
        name: "Salavan",
        code: "SL",
      },
      {
        name: "Savannakhet",
        code: "SV",
      },
      {
        name: "Xaignabouli",
        code: "XA",
      },
      {
        name: "Xiasomboun",
        code: "XN",
      },
      {
        name: "Xekong",
        code: "XE",
      },
      {
        name: "Xiangkhoang",
        code: "XI",
      },
    ],
  },
  {
    name: "Latvia",
    "alpha-2": "LV",
    "alpha-3": "LVA",
    "country-code": "428",
    "iso_3166-2": "ISO 3166-2:LV",
    region: "Europe",
    "sub-region": "Northern Europe",
    "region-code": "150",
    "sub-region-code": "154",
    states: [
      {
        name: "Aizkraukles Apripkis",
        code: "AI",
      },
      {
        name: "Alkanes Apripkis",
        code: "AL",
      },
      {
        name: "Balvu Apripkis",
        code: "BL",
      },
      {
        name: "Bauskas Apripkis",
        code: "BU",
      },
      {
        name: "Cesu Aprikis",
        code: "CE",
      },
      {
        name: "Daugavpile Apripkis",
        code: "DA",
      },
      {
        name: "Dobeles Apripkis",
        code: "DO",
      },
      {
        name: "Gulbenes Aprlpkis",
        code: "GU",
      },
      {
        name: "Jelgavas Apripkis",
        code: "JL",
      },
      {
        name: "Jekabpils Apripkis",
        code: "JK",
      },
      {
        name: "Kraslavas Apripkis",
        code: "KR",
      },
      {
        name: "Kuldlgas Apripkis",
        code: "KU",
      },
      {
        name: "Limbazu Apripkis",
        code: "LM",
      },
      {
        name: "Liepajas Apripkis",
        code: "LE",
      },
      {
        name: "Ludzas Apripkis",
        code: "LU",
      },
      {
        name: "Madonas Apripkis",
        code: "MA",
      },
      {
        name: "Ogres Apripkis",
        code: "OG",
      },
      {
        name: "Preilu Apripkis",
        code: "PR",
      },
      {
        name: "Rezaknes Apripkis",
        code: "RE",
      },
      {
        name: "Rigas Apripkis",
        code: "RI",
      },
      {
        name: "Saldus Apripkis",
        code: "SA",
      },
      {
        name: "Talsu Apripkis",
        code: "TA",
      },
      {
        name: "Tukuma Apriplcis",
        code: "TU",
      },
      {
        name: "Valkas Apripkis",
        code: "VK",
      },
      {
        name: "Valmieras Apripkis",
        code: "VM",
      },
      {
        name: "Ventspils Apripkis",
        code: "VE",
      },
      {
        name: "Daugavpils",
        code: "DGV",
      },
      {
        name: "Jelgava",
        code: "JEL",
      },
      {
        name: "Jurmala",
        code: "JUR",
      },
      {
        name: "Liepaja",
        code: "LPX",
      },
      {
        name: "Rezekne",
        code: "REZ",
      },
      {
        name: "Riga",
        code: "RIX",
      },
      {
        name: "Ventspils",
        code: "VEN",
      },
    ],
  },
  {
    name: "Lebanon",
    "alpha-2": "LB",
    "alpha-3": "LBN",
    "country-code": "422",
    "iso_3166-2": "ISO 3166-2:LB",
    region: "Asia",
    "sub-region": "Western Asia",
    "region-code": "142",
    "sub-region-code": "145",
    states: [
      {
        name: "Beirout",
        code: "BA",
      },
      {
        name: "El Begsa",
        code: "BI",
      },
      {
        name: "Jabal Loubnane",
        code: "JL",
      },
      {
        name: "Loubnane ech Chemali",
        code: "AS",
      },
      {
        name: "Loubnane ej Jnoubi",
        code: "JA",
      },
      {
        name: "Nabatiye",
        code: "NA",
      },
    ],
  },
  {
    name: "Lesotho",
    "alpha-2": "LS",
    "alpha-3": "LSO",
    "country-code": "426",
    "iso_3166-2": "ISO 3166-2:LS",
    region: "Africa",
    "sub-region": "Southern Africa",
    "region-code": "002",
    "sub-region-code": "018",
    states: [
      {
        name: "Berea",
        code: "D",
      },
      {
        name: "Butha-Buthe",
        code: "B",
      },
      {
        name: "Leribe",
        code: "C",
      },
      {
        name: "Mafeteng",
        code: "E",
      },
      {
        name: "Maseru",
        code: "A",
      },
      {
        name: "Mohale's Hoek",
        code: "F",
      },
      {
        name: "Mokhotlong",
        code: "J",
      },
      {
        name: "Qacha's Nek",
        code: "H",
      },
      {
        name: "Quthing",
        code: "G",
      },
      {
        name: "Thaba-Tseka",
        code: "K",
      },
    ],
  },
  {
    name: "Liberia",
    "alpha-2": "LR",
    "alpha-3": "LBR",
    "country-code": "430",
    "iso_3166-2": "ISO 3166-2:LR",
    region: "Africa",
    "sub-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "011",
    states: [
      {
        name: "Bomi",
        code: "BM",
      },
      {
        name: "Bong",
        code: "BG",
      },
      {
        name: "Grand Basaa",
        code: "GB",
      },
      {
        name: "Grand Cape Mount",
        code: "CM",
      },
      {
        name: "Grand Gedeh",
        code: "GG",
      },
      {
        name: "Grand Kru",
        code: "GK",
      },
      {
        name: "Lofa",
        code: "LO",
      },
      {
        name: "Margibi",
        code: "MG",
      },
      {
        name: "Maryland",
        code: "MY",
      },
      {
        name: "Montserrado",
        code: "MO",
      },
      {
        name: "Nimba",
        code: "NI",
      },
      {
        name: "Rivercess",
        code: "RI",
      },
      {
        name: "Sinoe",
        code: "SI",
      },
    ],
  },
  {
    name: "Libya",
    "alpha-2": "LY",
    "alpha-3": "LBY",
    "country-code": "434",
    "iso_3166-2": "ISO 3166-2:LY",
    region: "Africa",
    "sub-region": "Northern Africa",
    "region-code": "002",
    "sub-region-code": "015",
    states: [
      {
        name: "Ajdābiyā",
        code: "AJ",
      },
      {
        name: "Al Buţnān",
        code: "BU",
      },
      {
        name: "Al Hizām al Akhdar",
        code: "HZ",
      },
      {
        name: "Al Jabal al Akhdar",
        code: "JA",
      },
      {
        name: "Al Jifārah",
        code: "JI",
      },
      {
        name: "Al Jufrah",
        code: "JU",
      },
      {
        name: "Al Kufrah",
        code: "KF",
      },
      {
        name: "Al Marj",
        code: "MJ",
      },
      {
        name: "Al Marqab",
        code: "MB",
      },
      {
        name: "Al Qaţrūn",
        code: "QT",
      },
      {
        name: "Al Qubbah",
        code: "QB",
      },
      {
        name: "Al Wāhah",
        code: "WA",
      },
      {
        name: "An Nuqaţ al Khams",
        code: "NQ",
      },
      {
        name: "Ash Shāţi'",
        code: "SH",
      },
      {
        name: "Az Zāwiyah",
        code: "ZA",
      },
      {
        name: "Banghāzī",
        code: "BA",
      },
      {
        name: "Banī Walīd",
        code: "BW",
      },
      {
        name: "Darnah",
        code: "DR",
      },
      {
        name: "Ghadāmis",
        code: "GD",
      },
      {
        name: "Gharyān",
        code: "GR",
      },
      {
        name: "Ghāt",
        code: "GT",
      },
      {
        name: "Jaghbūb",
        code: "JB",
      },
      {
        name: "Mişrātah",
        code: "MI",
      },
      {
        name: "Mizdah",
        code: "MZ",
      },
      {
        name: "Murzuq",
        code: "MQ",
      },
      {
        name: "Nālūt",
        code: "NL",
      },
      {
        name: "Sabhā",
        code: "SB",
      },
      {
        name: "Şabrātah Şurmān",
        code: "SS",
      },
      {
        name: "Surt",
        code: "SR",
      },
      {
        name: "Tājūrā' wa an Nawāhī al Arbāh",
        code: "TN",
      },
      {
        name: "Ţarābulus",
        code: "TB",
      },
      {
        name: "Tarhūnah-Masallātah",
        code: "TM",
      },
      {
        name: "Wādī al hayāt",
        code: "WD",
      },
      {
        name: "Yafran-Jādū",
        code: "YJ",
      },
    ],
  },
  {
    name: "Liechtenstein",
    "alpha-2": "LI",
    "alpha-3": "LIE",
    "country-code": "438",
    "iso_3166-2": "ISO 3166-2:LI",
    region: "Europe",
    "sub-region": "Western Europe",
    "region-code": "150",
    "sub-region-code": "155",
    states: [],
  },
  {
    name: "Lithuania",
    "alpha-2": "LT",
    "alpha-3": "LTU",
    "country-code": "440",
    "iso_3166-2": "ISO 3166-2:LT",
    region: "Europe",
    "sub-region": "Northern Europe",
    "region-code": "150",
    "sub-region-code": "154",
    states: [
      {
        name: "Alytaus Apskritis",
        code: "AL",
      },
      {
        name: "Kauno Apskritis",
        code: "KU",
      },
      {
        name: "Klaipedos Apskritis",
        code: "KL",
      },
      {
        name: "Marijampoles Apskritis",
        code: "MR",
      },
      {
        name: "Panevezio Apskritis",
        code: "PN",
      },
      {
        name: "Sisuliu Apskritis",
        code: "SA",
      },
      {
        name: "Taurages Apskritis",
        code: "TA",
      },
      {
        name: "Telsiu Apskritis",
        code: "TE",
      },
      {
        name: "Utenos Apskritis",
        code: "UT",
      },
      {
        name: "Vilniaus Apskritis",
        code: "VL",
      },
    ],
  },
  {
    name: "Luxembourg",
    "alpha-2": "LU",
    "alpha-3": "LUX",
    "country-code": "442",
    "iso_3166-2": "ISO 3166-2:LU",
    region: "Europe",
    "sub-region": "Western Europe",
    "region-code": "150",
    "sub-region-code": "155",
    states: [
      {
        name: "Diekirch",
        code: "D",
      },
      {
        name: "GreveNmacher",
        code: "G",
      },
    ],
  },
  {
    name: "Macao",
    "alpha-2": "MO",
    "alpha-3": "MAC",
    "country-code": "446",
    "iso_3166-2": "ISO 3166-2:MO",
    region: "Asia",
    "sub-region": "Eastern Asia",
    "region-code": "142",
    "sub-region-code": "030",
    states: [],
  },
  {
    name: "Macedonia (the former Yugoslav Republic of)",
    "alpha-2": "MK",
    "alpha-3": "MKD",
    "country-code": "807",
    "iso_3166-2": "ISO 3166-2:MK",
    region: "Europe",
    "sub-region": "Southern Europe",
    "region-code": "150",
    "sub-region-code": "039",
    states: [],
  },
  {
    name: "Madagascar",
    "alpha-2": "MG",
    "alpha-3": "MDG",
    "country-code": "450",
    "iso_3166-2": "ISO 3166-2:MG",
    region: "Africa",
    "sub-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "014",
    states: [
      {
        name: "Antananarivo",
        code: "T",
      },
      {
        name: "Antsiranana",
        code: "D",
      },
      {
        name: "Fianarantsoa",
        code: "F",
      },
      {
        name: "Mahajanga",
        code: "M",
      },
      {
        name: "Toamasina",
        code: "A",
      },
      {
        name: "Toliara",
        code: "U",
      },
    ],
  },
  {
    name: "Malawi",
    "alpha-2": "MW",
    "alpha-3": "MWI",
    "country-code": "454",
    "iso_3166-2": "ISO 3166-2:MW",
    region: "Africa",
    "sub-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "014",
    states: [
      {
        name: "Balaka",
        code: "BA",
      },
      {
        name: "Blantyre",
        code: "BL",
      },
      {
        name: "Chikwawa",
        code: "CK",
      },
      {
        name: "Chiradzulu",
        code: "CR",
      },
      {
        name: "Chitipa",
        code: "CT",
      },
      {
        name: "Dedza",
        code: "DE",
      },
      {
        name: "Dowa",
        code: "DO",
      },
      {
        name: "Karonga",
        code: "KR",
      },
      {
        name: "Kasungu",
        code: "KS",
      },
      {
        name: "Likoma Island",
        code: "LK",
      },
      {
        name: "Lilongwe",
        code: "LI",
      },
      {
        name: "Machinga",
        code: "MH",
      },
      {
        name: "Mangochi",
        code: "MG",
      },
      {
        name: "Mchinji",
        code: "MC",
      },
      {
        name: "Mulanje",
        code: "MU",
      },
      {
        name: "Mwanza",
        code: "MW",
      },
      {
        name: "Mzimba",
        code: "MZ",
      },
      {
        name: "Nkhata Bay",
        code: "NB",
      },
      {
        name: "Nkhotakota",
        code: "NK",
      },
      {
        name: "Nsanje",
        code: "NS",
      },
      {
        name: "Ntcheu",
        code: "NU",
      },
      {
        name: "Ntchisi",
        code: "NI",
      },
      {
        name: "Phalomba",
        code: "PH",
      },
      {
        name: "Rumphi",
        code: "RU",
      },
      {
        name: "Salima",
        code: "SA",
      },
      {
        name: "Thyolo",
        code: "TH",
      },
      {
        name: "Zomba",
        code: "ZO",
      },
    ],
  },
  {
    name: "Malaysia",
    "alpha-2": "MY",
    "alpha-3": "MYS",
    "country-code": "458",
    "iso_3166-2": "ISO 3166-2:MY",
    region: "Asia",
    "sub-region": "South-Eastern Asia",
    "region-code": "142",
    "sub-region-code": "035",
    states: [
      {
        name: "Wilayah Persekutuan Kuala Lumpur",
        code: "14",
      },
      {
        name: "Wilayah Persekutuan Labuan",
        code: "15",
      },
      {
        name: "Wilayah Persekutuan Putrajaya",
        code: "16",
      },
      {
        name: "Johor",
        code: "01",
      },
      {
        name: "Kedah",
        code: "02",
      },
      {
        name: "Kelantan",
        code: "03",
      },
      {
        name: "Melaka",
        code: "04",
      },
      {
        name: "Negeri Sembilan",
        code: "05",
      },
      {
        name: "Pahang",
        code: "06",
      },
      {
        name: "Perak",
        code: "08",
      },
      {
        name: "Perlis",
        code: "09",
      },
      {
        name: "Pulau Pinang",
        code: "07",
      },
      {
        name: "Sabah",
        code: "12",
      },
      {
        name: "Sarawak",
        code: "13",
      },
      {
        name: "Selangor",
        code: "10",
      },
      {
        name: "Terengganu",
        code: "11",
      },
    ],
  },
  {
    name: "Maldives",
    "alpha-2": "MV",
    "alpha-3": "MDV",
    "country-code": "462",
    "iso_3166-2": "ISO 3166-2:MV",
    region: "Asia",
    "sub-region": "Southern Asia",
    "region-code": "142",
    "sub-region-code": "034",
    states: [
      {
        name: "Male",
        code: "MLE",
      },
      {
        name: "Alif",
        code: "02",
      },
      {
        name: "Baa",
        code: "20",
      },
      {
        name: "Dhaalu",
        code: "17",
      },
      {
        name: "Faafu",
        code: "14",
      },
      {
        name: "Gaaf Alif",
        code: "27",
      },
      {
        name: "Gaefu Dhaalu",
        code: "28",
      },
      {
        name: "Gnaviyani",
        code: "29",
      },
      {
        name: "Haa Alif",
        code: "07",
      },
      {
        name: "Haa Dhaalu",
        code: "23",
      },
      {
        name: "Kaafu",
        code: "26",
      },
      {
        name: "Laamu",
        code: "05",
      },
      {
        name: "Lhaviyani",
        code: "03",
      },
      {
        name: "Meemu",
        code: "12",
      },
      {
        name: "Noonu",
        code: "25",
      },
      {
        name: "Raa",
        code: "13",
      },
      {
        name: "Seenu",
        code: "01",
      },
      {
        name: "Shaviyani",
        code: "24",
      },
      {
        name: "Thaa",
        code: "08",
      },
      {
        name: "Vaavu",
        code: "04",
      },
    ],
  },
  {
    name: "Mali",
    "alpha-2": "ML",
    "alpha-3": "MLI",
    "country-code": "466",
    "iso_3166-2": "ISO 3166-2:ML",
    region: "Africa",
    "sub-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "011",
    states: [
      {
        name: "Bamako",
        code: "BK0",
      },
      {
        name: "Gao",
        code: "7",
      },
      {
        name: "Kayes",
        code: "1",
      },
      {
        name: "Kidal",
        code: "8",
      },
      {
        name: "Xoulikoro",
        code: "2",
      },
      {
        name: "Mopti",
        code: "5",
      },
      {
        name: "S69ou",
        code: "4",
      },
      {
        name: "Sikasso",
        code: "3",
      },
      {
        name: "Tombouctou",
        code: "6",
      },
    ],
  },
  {
    name: "Malta",
    "alpha-2": "MT",
    "alpha-3": "MLT",
    "country-code": "470",
    "iso_3166-2": "ISO 3166-2:MT",
    region: "Europe",
    "sub-region": "Southern Europe",
    "region-code": "150",
    "sub-region-code": "039",
    states: [],
  },
  {
    name: "Marshall Islands",
    "alpha-2": "MH",
    "alpha-3": "MHL",
    "country-code": "584",
    "iso_3166-2": "ISO 3166-2:MH",
    region: "Oceania",
    "sub-region": "Micronesia",
    "region-code": "009",
    "sub-region-code": "057",
    states: [
      {
        name: "Ailinglapalap",
        code: "ALL",
      },
      {
        name: "Ailuk",
        code: "ALK",
      },
      {
        name: "Arno",
        code: "ARN",
      },
      {
        name: "Aur",
        code: "AUR",
      },
      {
        name: "Ebon",
        code: "EBO",
      },
      {
        name: "Eniwetok",
        code: "ENI",
      },
      {
        name: "Jaluit",
        code: "JAL",
      },
      {
        name: "Kili",
        code: "KIL",
      },
      {
        name: "Kwajalein",
        code: "KWA",
      },
      {
        name: "Lae",
        code: "LAE",
      },
      {
        name: "Lib",
        code: "LIB",
      },
      {
        name: "Likiep",
        code: "LIK",
      },
      {
        name: "Majuro",
        code: "MAJ",
      },
      {
        name: "Maloelap",
        code: "MAL",
      },
      {
        name: "Mejit",
        code: "MEJ",
      },
      {
        name: "Mili",
        code: "MIL",
      },
      {
        name: "Namorik",
        code: "NMK",
      },
      {
        name: "Namu",
        code: "NMU",
      },
      {
        name: "Rongelap",
        code: "RON",
      },
      {
        name: "Ujae",
        code: "UJA",
      },
      {
        name: "Ujelang",
        code: "UJL",
      },
      {
        name: "Utirik",
        code: "UTI",
      },
      {
        name: "Wotho",
        code: "WTN",
      },
      {
        name: "Wotje",
        code: "WTJ",
      },
    ],
  },
  {
    name: "Martinique",
    "alpha-2": "MQ",
    "alpha-3": "MTQ",
    "country-code": "474",
    "iso_3166-2": "ISO 3166-2:MQ",
    region: "Americas",
    "sub-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "029",
    states: [],
  },
  {
    name: "Mauritania",
    "alpha-2": "MR",
    "alpha-3": "MRT",
    "country-code": "478",
    "iso_3166-2": "ISO 3166-2:MR",
    region: "Africa",
    "sub-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "011",
    states: [
      {
        name: "Nouakchott",
        code: "NKC",
      },
      {
        name: "Assaba",
        code: "03",
      },
      {
        name: "Brakna",
        code: "05",
      },
      {
        name: "Dakhlet Nouadhibou",
        code: "08",
      },
      {
        name: "Gorgol",
        code: "04",
      },
      {
        name: "Guidimaka",
        code: "10",
      },
      {
        name: "Hodh ech Chargui",
        code: "01",
      },
      {
        name: "Hodh el Charbi",
        code: "02",
      },
      {
        name: "Inchiri",
        code: "12",
      },
      {
        name: "Tagant",
        code: "09",
      },
      {
        name: "Tiris Zemmour",
        code: "11",
      },
      {
        name: "Trarza",
        code: "06",
      },
    ],
  },
  {
    name: "Mauritius",
    "alpha-2": "MU",
    "alpha-3": "MUS",
    "country-code": "480",
    "iso_3166-2": "ISO 3166-2:MU",
    region: "Africa",
    "sub-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "014",
    states: [
      {
        name: "Beau Bassin-Rose Hill",
        code: "BR",
      },
      {
        name: "Curepipe",
        code: "CU",
      },
      {
        name: "Port Louis",
        code: "PU",
      },
      {
        name: "Quatre Bornes",
        code: "QB",
      },
      {
        name: "Vacosa-Phoenix",
        code: "VP",
      },
      {
        name: "Black River",
        code: "BL",
      },
      {
        name: "Flacq",
        code: "FL",
      },
      {
        name: "Grand Port",
        code: "GP",
      },
      {
        name: "Moka",
        code: "MO",
      },
      {
        name: "Pamplemousses",
        code: "PA",
      },
      {
        name: "Plaines Wilhems",
        code: "PW",
      },
      {
        name: "Riviere du Rempart",
        code: "RP",
      },
      {
        name: "Savanne",
        code: "SA",
      },
      {
        name: "Agalega Islands",
        code: "AG",
      },
      {
        name: "Cargados Carajos Shoals",
        code: "CC",
      },
      {
        name: "Rodrigues Island",
        code: "RO",
      },
    ],
  },
  {
    name: "Mayotte",
    "alpha-2": "YT",
    "alpha-3": "MYT",
    "country-code": "175",
    "iso_3166-2": "ISO 3166-2:YT",
    region: "Africa",
    "sub-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "014",
    states: [],
  },
  {
    name: "Mexico",
    "alpha-2": "MX",
    "alpha-3": "MEX",
    "country-code": "484",
    "iso_3166-2": "ISO 3166-2:MX",
    region: "Americas",
    "sub-region": "Central America",
    "region-code": "019",
    "sub-region-code": "013",
    states: [
      {
        name: "Aguascalientes",
        code: "AGU",
      },
      {
        name: "Baja California",
        code: "BCN",
      },
      {
        name: "Baja California Sur",
        code: "BCS",
      },
      {
        name: "Campeche",
        code: "CAM",
      },
      {
        name: "Coahuila",
        code: "COA",
      },
      {
        name: "Colima",
        code: "COL",
      },
      {
        name: "Chiapas",
        code: "CHP",
      },
      {
        name: "Chihuahua",
        code: "CHH",
      },
      {
        name: "Durango",
        code: "DUR",
      },
      {
        name: "Guanajuato",
        code: "GUA",
      },
      {
        name: "Guerrero",
        code: "GRO",
      },
      {
        name: "Hidalgo",
        code: "HID",
      },
      {
        name: "Jalisco",
        code: "JAL",
      },
      {
        name: "Mexico",
        code: "MEX",
      },
      {
        name: "Michoacin",
        code: "MIC",
      },
      {
        name: "Morelos",
        code: "MOR",
      },
      {
        name: "Nayarit",
        code: "NAY",
      },
      {
        name: "Nuevo Leon",
        code: "NLE",
      },
      {
        name: "Oaxaca",
        code: "OAX",
      },
      {
        name: "Puebla",
        code: "PUE",
      },
      {
        name: "Queretaro",
        code: "QUE",
      },
      {
        name: "Quintana Roo",
        code: "ROO",
      },
      {
        name: "San Luis Potosi",
        code: "SLP",
      },
      {
        name: "Sinaloa",
        code: "SIN",
      },
      {
        name: "Sonora",
        code: "SON",
      },
      {
        name: "Tabasco",
        code: "TAB",
      },
      {
        name: "Tamaulipas",
        code: "TAM",
      },
      {
        name: "Tlaxcala",
        code: "TLA",
      },
      {
        name: "Veracruz",
        code: "VER",
      },
      {
        name: "Yucatan",
        code: "YUC",
      },
      {
        name: "Zacatecas",
        code: "ZAC",
      },
      {
        name: "Distrito Federal",
        code: "DIF",
      },
    ],
  },
  {
    name: "Micronesia (Federated States of)",
    "alpha-2": "FM",
    "alpha-3": "FSM",
    "country-code": "583",
    "iso_3166-2": "ISO 3166-2:FM",
    region: "Oceania",
    "sub-region": "Micronesia",
    "region-code": "009",
    "sub-region-code": "057",
    states: [
      {
        name: "Chuuk",
        code: "TRK",
      },
      {
        name: "Kosrae",
        code: "KSA",
      },
      {
        name: "Pohnpei",
        code: "PNI",
      },
      {
        name: "Yap",
        code: "YAP",
      },
    ],
  },
  {
    name: "Moldova (Republic of)",
    "alpha-2": "MD",
    "alpha-3": "MDA",
    "country-code": "498",
    "iso_3166-2": "ISO 3166-2:MD",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "region-code": "150",
    "sub-region-code": "151",
    states: [
      {
        name: "Gagauzia, Unitate Teritoriala Autonoma",
        code: "GA",
      },
      {
        name: "Chisinau",
        code: "CU",
      },
      {
        name: "Stinga Nistrului, unitatea teritoriala din",
        code: "SN",
      },
      {
        name: "Balti",
        code: "BA",
      },
      {
        name: "Cahul",
        code: "CA",
      },
      {
        name: "Edinet",
        code: "ED",
      },
      {
        name: "Lapusna",
        code: "LA",
      },
      {
        name: "Orhei",
        code: "OR",
      },
      {
        name: "Soroca",
        code: "SO",
      },
      {
        name: "Taraclia",
        code: "TA",
      },
      {
        name: "Tighina [Bender]",
        code: "TI",
      },
      {
        name: "Ungheni",
        code: "UN",
      },
    ],
  },
  {
    name: "Monaco",
    "alpha-2": "MC",
    "alpha-3": "MCO",
    "country-code": "492",
    "iso_3166-2": "ISO 3166-2:MC",
    region: "Europe",
    "sub-region": "Western Europe",
    "region-code": "150",
    "sub-region-code": "155",
    states: [],
  },
  {
    name: "Mongolia",
    "alpha-2": "MN",
    "alpha-3": "MNG",
    "country-code": "496",
    "iso_3166-2": "ISO 3166-2:MN",
    region: "Asia",
    "sub-region": "Eastern Asia",
    "region-code": "142",
    "sub-region-code": "030",
    states: [
      {
        name: "Ulaanbaatar",
        code: "1",
      },
      {
        name: "Arhangay",
        code: "073",
      },
      {
        name: "Bayanhongor",
        code: "069",
      },
      {
        name: "Bayan-Olgiy",
        code: "071",
      },
      {
        name: "Bulgan",
        code: "067",
      },
      {
        name: "Darhan uul",
        code: "037",
      },
      {
        name: "Dornod",
        code: "061",
      },
      {
        name: "Dornogov,",
        code: "063",
      },
      {
        name: "DundgovL",
        code: "059",
      },
      {
        name: "Dzavhan",
        code: "057",
      },
      {
        name: "Govi-Altay",
        code: "065",
      },
      {
        name: "Govi-Smber",
        code: "064",
      },
      {
        name: "Hentiy",
        code: "039",
      },
      {
        name: "Hovd",
        code: "043",
      },
      {
        name: "Hovsgol",
        code: "041",
      },
      {
        name: "Omnogovi",
        code: "053",
      },
      {
        name: "Orhon",
        code: "035",
      },
      {
        name: "Ovorhangay",
        code: "055",
      },
      {
        name: "Selenge",
        code: "049",
      },
      {
        name: "Shbaatar",
        code: "051",
      },
      {
        name: "Tov",
        code: "047",
      },
      {
        name: "Uvs",
        code: "046",
      },
    ],
  },
  {
    name: "Montserrat",
    "alpha-2": "MS",
    "alpha-3": "MSR",
    "country-code": "500",
    "iso_3166-2": "ISO 3166-2:MS",
    region: "Americas",
    "sub-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "029",
    states: [],
  },
  {
    name: "Morocco",
    "alpha-2": "MA",
    "alpha-3": "MAR",
    "country-code": "504",
    "iso_3166-2": "ISO 3166-2:MA",
    region: "Africa",
    "sub-region": "Northern Africa",
    "region-code": "002",
    "sub-region-code": "015",
    states: [
      {
        name: "Agadir",
        code: "AGD",
      },
      {
        name: "Aït Baha",
        code: "BAH",
      },
      {
        name: "Aït Melloul",
        code: "MEL",
      },
      {
        name: "Al Haouz",
        code: "HAO",
      },
      {
        name: "Al Hoceïma",
        code: "HOC",
      },
      {
        name: "Assa-Zag",
        code: "ASZ",
      },
      {
        name: "Azilal",
        code: "AZI",
      },
      {
        name: "Beni Mellal",
        code: "BEM",
      },
      {
        name: "Ben Sllmane",
        code: "BES",
      },
      {
        name: "Berkane",
        code: "BER",
      },
      {
        name: "Boujdour",
        code: "BOD",
      },
      {
        name: "Boulemane",
        code: "BOM",
      },
      {
        name: "Casablanca  [Dar el Beïda]",
        code: "CAS",
      },
      {
        name: "Chefchaouene",
        code: "CHE",
      },
      {
        name: "Chichaoua",
        code: "CHI",
      },
      {
        name: "El Hajeb",
        code: "HAJ",
      },
      {
        name: "El Jadida",
        code: "JDI",
      },
      {
        name: "Errachidia",
        code: "ERR",
      },
      {
        name: "Essaouira",
        code: "ESI",
      },
      {
        name: "Es Smara",
        code: "ESM",
      },
      {
        name: "Fès",
        code: "FES",
      },
      {
        name: "Figuig",
        code: "FIG",
      },
      {
        name: "Guelmim",
        code: "GUE",
      },
      {
        name: "Ifrane",
        code: "IFR",
      },
      {
        name: "Jerada",
        code: "JRA",
      },
      {
        name: "Kelaat Sraghna",
        code: "KES",
      },
      {
        name: "Kénitra",
        code: "KEN",
      },
      {
        name: "Khemisaet",
        code: "KHE",
      },
      {
        name: "Khenifra",
        code: "KHN",
      },
      {
        name: "Khouribga",
        code: "KHO",
      },
      {
        name: "Laâyoune (EH)",
        code: "LAA",
      },
      {
        name: "Larache",
        code: "LAP",
      },
      {
        name: "Marrakech",
        code: "MAR",
      },
      {
        name: "Meknsès",
        code: "MEK",
      },
      {
        name: "Nador",
        code: "NAD",
      },
      {
        name: "Ouarzazate",
        code: "OUA",
      },
      {
        name: "Oued ed Dahab (EH)",
        code: "OUD",
      },
      {
        name: "Oujda",
        code: "OUJ",
      },
      {
        name: "Rabat-Salé",
        code: "RBA",
      },
      {
        name: "Safi",
        code: "SAF",
      },
      {
        name: "Sefrou",
        code: "SEF",
      },
      {
        name: "Settat",
        code: "SET",
      },
      {
        name: "Sidl Kacem",
        code: "SIK",
      },
      {
        name: "Tanger",
        code: "TNG",
      },
      {
        name: "Tan-Tan",
        code: "TNT",
      },
      {
        name: "Taounate",
        code: "TAO",
      },
      {
        name: "Taroudannt",
        code: "TAR",
      },
      {
        name: "Tata",
        code: "TAT",
      },
      {
        name: "Taza",
        code: "TAZ",
      },
      {
        name: "Tétouan",
        code: "TET",
      },
      {
        name: "Tiznit",
        code: "TIZ",
      },
    ],
  },
  {
    name: "Mozambique",
    "alpha-2": "MZ",
    "alpha-3": "MOZ",
    "country-code": "508",
    "iso_3166-2": "ISO 3166-2:MZ",
    region: "Africa",
    "sub-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "014",
    states: [
      {
        name: "Maputo",
        code: "MPM",
      },
      {
        name: "Cabo Delgado",
        code: "P",
      },
      {
        name: "Gaza",
        code: "G",
      },
      {
        name: "Inhambane",
        code: "I",
      },
      {
        name: "Manica",
        code: "B",
      },
      {
        name: "Numpula",
        code: "N",
      },
      {
        name: "Niaaea",
        code: "A",
      },
      {
        name: "Sofala",
        code: "S",
      },
      {
        name: "Tete",
        code: "T",
      },
      {
        name: "Zambezia",
        code: "Q",
      },
    ],
  },
  {
    name: "Namibia",
    "alpha-2": "NA",
    "alpha-3": "NAM",
    "country-code": "516",
    "iso_3166-2": "ISO 3166-2:NA",
    region: "Africa",
    "sub-region": "Southern Africa",
    "region-code": "002",
    "sub-region-code": "018",
    states: [
      {
        name: "Caprivi",
        code: "CA",
      },
      {
        name: "Erongo",
        code: "ER",
      },
      {
        name: "Hardap",
        code: "HA",
      },
      {
        name: "Karas",
        code: "KA",
      },
      {
        name: "Khomae",
        code: "KH",
      },
      {
        name: "Kunene",
        code: "KU",
      },
      {
        name: "Ohangwena",
        code: "OW",
      },
      {
        name: "Okavango",
        code: "OK",
      },
      {
        name: "Omaheke",
        code: "OH",
      },
      {
        name: "Omusati",
        code: "OS",
      },
      {
        name: "Oshana",
        code: "ON",
      },
      {
        name: "Oshikoto",
        code: "OT",
      },
      {
        name: "Otjozondjupa",
        code: "OD",
      },
    ],
  },
  {
    name: "Nauru",
    "alpha-2": "NR",
    "alpha-3": "NRU",
    "country-code": "520",
    "iso_3166-2": "ISO 3166-2:NR",
    region: "Oceania",
    "sub-region": "Micronesia",
    "region-code": "009",
    "sub-region-code": "057",
    states: [],
  },
  {
    name: "Nepal",
    "alpha-2": "NP",
    "alpha-3": "NPL",
    "country-code": "524",
    "iso_3166-2": "ISO 3166-2:NP",
    region: "Asia",
    "sub-region": "Southern Asia",
    "region-code": "142",
    "sub-region-code": "034",
    states: [],
  },
  {
    name: "Netherlands",
    "alpha-2": "NL",
    "alpha-3": "NLD",
    "country-code": "528",
    "iso_3166-2": "ISO 3166-2:NL",
    region: "Europe",
    "sub-region": "Western Europe",
    "region-code": "150",
    "sub-region-code": "155",
    states: [
      {
        name: "Drente",
        code: "DR",
      },
      {
        name: "Flevoland",
        code: "FL",
      },
      {
        name: "Friesland",
        code: "FR",
      },
      {
        name: "Gelderland",
        code: "GL",
      },
      {
        name: "Groningen",
        code: "GR",
      },
      {
        name: "Noord-Brabant",
        code: "NB",
      },
      {
        name: "Noord-Holland",
        code: "NH",
      },
      {
        name: "Overijssel",
        code: "OV",
      },
      {
        name: "Utrecht",
        code: "UT",
      },
      {
        name: "Zuid-Holland",
        code: "ZH",
      },
      {
        name: "Zeeland",
        code: "ZL",
      },
      {
        name: "Limburg",
        code: "LI",
      },
    ],
  },
  {
    name: "New Caledonia",
    "alpha-2": "NC",
    "alpha-3": "NCL",
    "country-code": "540",
    "iso_3166-2": "ISO 3166-2:NC",
    region: "Oceania",
    "sub-region": "Melanesia",
    "region-code": "009",
    "sub-region-code": "054",
    states: [],
  },
  {
    name: "New Zealand",
    "alpha-2": "NZ",
    "alpha-3": "NZL",
    "country-code": "554",
    "iso_3166-2": "ISO 3166-2:NZ",
    region: "Oceania",
    "sub-region": "Australia and New Zealand",
    "region-code": "009",
    "sub-region-code": "053",
    states: [
      {
        name: "Auckland",
        code: "AUK",
      },
      {
        name: "Bay of Plenty",
        code: "BOP",
      },
      {
        name: "Canterbury",
        code: "CAN",
      },
      {
        name: "Gisborne",
        code: "GIS",
      },
      {
        name: "Hawkes Bay",
        code: "HKB",
      },
      {
        name: "Manawatu-Wanganui",
        code: "MWT",
      },
      {
        name: "Marlborough",
        code: "MBH",
      },
      {
        name: "Nelson",
        code: "NSN",
      },
      {
        name: "Northland",
        code: "NTL",
      },
      {
        name: "Otago",
        code: "OTA",
      },
      {
        name: "Southland",
        code: "STL",
      },
      {
        name: "Taranaki",
        code: "TKI",
      },
      {
        name: "Tasman",
        code: "TAS",
      },
      {
        name: "waikato",
        code: "WKO",
      },
      {
        name: "Wellington",
        code: "WGN",
      },
      {
        name: "West Coast",
        code: "WTC",
      },
    ],
  },
  {
    name: "Nicaragua",
    "alpha-2": "NI",
    "alpha-3": "NIC",
    "country-code": "558",
    "iso_3166-2": "ISO 3166-2:NI",
    region: "Americas",
    "sub-region": "Central America",
    "region-code": "019",
    "sub-region-code": "013",
    states: [
      {
        name: "Boaco",
        code: "BO",
      },
      {
        name: "Carazo",
        code: "CA",
      },
      {
        name: "Chinandega",
        code: "CI",
      },
      {
        name: "Chontales",
        code: "CO",
      },
      {
        name: "Esteli",
        code: "ES",
      },
      {
        name: "Jinotega",
        code: "JI",
      },
      {
        name: "Leon",
        code: "LE",
      },
      {
        name: "Madriz",
        code: "MD",
      },
      {
        name: "Managua",
        code: "MN",
      },
      {
        name: "Masaya",
        code: "MS",
      },
      {
        name: "Matagalpa",
        code: "MT",
      },
      {
        name: "Nueva Segovia",
        code: "NS",
      },
      {
        name: "Rio San Juan",
        code: "SJ",
      },
      {
        name: "Rivas",
        code: "RI",
      },
      {
        name: "Atlantico Norte",
        code: "AN",
      },
      {
        name: "Atlantico Sur",
        code: "AS",
      },
    ],
  },
  {
    name: "Niger",
    "alpha-2": "NE",
    "alpha-3": "NER",
    "country-code": "562",
    "iso_3166-2": "ISO 3166-2:NE",
    region: "Africa",
    "sub-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "011",
    states: [
      {
        name: "Niamey",
        code: "8",
      },
      {
        name: "Agadez",
        code: "1",
      },
      {
        name: "Diffa",
        code: "2",
      },
      {
        name: "Dosso",
        code: "3",
      },
      {
        name: "Maradi",
        code: "4",
      },
      {
        name: "Tahoua",
        code: "S",
      },
      {
        name: "Tillaberi",
        code: "6",
      },
      {
        name: "Zinder",
        code: "7",
      },
    ],
  },
  {
    name: "Nigeria",
    "alpha-2": "NG",
    "alpha-3": "NGA",
    "country-code": "566",
    "iso_3166-2": "ISO 3166-2:NG",
    region: "Africa",
    "sub-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "011",
    states: [
      {
        name: "Abuja Capital Territory",
        code: "FC",
      },
      {
        name: "Abia",
        code: "AB",
      },
      {
        name: "Adamawa",
        code: "AD",
      },
      {
        name: "Akwa Ibom",
        code: "AK",
      },
      {
        name: "Anambra",
        code: "AN",
      },
      {
        name: "Bauchi",
        code: "BA",
      },
      {
        name: "Bayelsa",
        code: "BY",
      },
      {
        name: "Benue",
        code: "BE",
      },
      {
        name: "Borno",
        code: "BO",
      },
      {
        name: "Cross River",
        code: "CR",
      },
      {
        name: "Delta",
        code: "DE",
      },
      {
        name: "Ebonyi",
        code: "EB",
      },
      {
        name: "Edo",
        code: "ED",
      },
      {
        name: "Ekiti",
        code: "EK",
      },
      {
        name: "Enugu",
        code: "EN",
      },
      {
        name: "Gombe",
        code: "GO",
      },
      {
        name: "Imo",
        code: "IM",
      },
      {
        name: "Jigawa",
        code: "JI",
      },
      {
        name: "Kaduna",
        code: "KD",
      },
      {
        name: "Kano",
        code: "KN",
      },
      {
        name: "Katsina",
        code: "KT",
      },
      {
        name: "Kebbi",
        code: "KE",
      },
      {
        name: "Kogi",
        code: "KO",
      },
      {
        name: "Kwara",
        code: "KW",
      },
      {
        name: "Lagos",
        code: "LA",
      },
      {
        name: "Nassarawa",
        code: "NA",
      },
      {
        name: "Niger",
        code: "NI",
      },
      {
        name: "Ogun",
        code: "OG",
      },
      {
        name: "Ondo",
        code: "ON",
      },
      {
        name: "Osun",
        code: "OS",
      },
      {
        name: "Oyo",
        code: "OY",
      },
      {
        name: "Rivers",
        code: "RI",
      },
      {
        name: "Sokoto",
        code: "SO",
      },
      {
        name: "Taraba",
        code: "TA",
      },
      {
        name: "Yobe",
        code: "YO",
      },
      {
        name: "Zamfara",
        code: "ZA",
      },
    ],
  },
  {
    name: "Niue",
    "alpha-2": "NU",
    "alpha-3": "NIU",
    "country-code": "570",
    "iso_3166-2": "ISO 3166-2:NU",
    region: "Oceania",
    "sub-region": "Polynesia",
    "region-code": "009",
    "sub-region-code": "061",
    states: [],
  },
  {
    name: "Norfolk Island",
    "alpha-2": "NF",
    "alpha-3": "NFK",
    "country-code": "574",
    "iso_3166-2": "ISO 3166-2:NF",
    region: "Oceania",
    "sub-region": "Australia and New Zealand",
    "region-code": "009",
    "sub-region-code": "053",
    states: [],
  },
  {
    name: "Northern Mariana Islands",
    "alpha-2": "MP",
    "alpha-3": "MNP",
    "country-code": "580",
    "iso_3166-2": "ISO 3166-2:MP",
    region: "Oceania",
    "sub-region": "Micronesia",
    "region-code": "009",
    "sub-region-code": "057",
    states: [],
  },
  {
    name: "Norway",
    "alpha-2": "NO",
    "alpha-3": "NOR",
    "country-code": "578",
    "iso_3166-2": "ISO 3166-2:NO",
    region: "Europe",
    "sub-region": "Northern Europe",
    "region-code": "150",
    "sub-region-code": "154",
    states: [
      {
        name: "Akershus",
        code: "02",
      },
      {
        name: "Aust-Agder",
        code: "09",
      },
      {
        name: "Buskerud",
        code: "06",
      },
      {
        name: "Finumark",
        code: "20",
      },
      {
        name: "Hedmark",
        code: "04",
      },
      {
        name: "Hordaland",
        code: "12",
      },
      {
        name: "Mire og Romsdal",
        code: "15",
      },
      {
        name: "Nordland",
        code: "18",
      },
      {
        name: "Nord-Trindelag",
        code: "17",
      },
      {
        name: "Oppland",
        code: "05",
      },
      {
        name: "Oslo",
        code: "03",
      },
      {
        name: "Rogaland",
        code: "11",
      },
      {
        name: "Sogn og Fjordane",
        code: "14",
      },
      {
        name: "Sir-Trindelag",
        code: "16",
      },
      {
        name: "Telemark",
        code: "06",
      },
      {
        name: "Troms",
        code: "19",
      },
      {
        name: "Vest-Agder",
        code: "10",
      },
      {
        name: "Vestfold",
        code: "07",
      },
      {
        name: "Ostfold",
        code: "01",
      },
      {
        name: "Jan Mayen",
        code: "22",
      },
      {
        name: "Svalbard",
        code: "21",
      },
    ],
  },
  {
    name: "Oman",
    "alpha-2": "OM",
    "alpha-3": "OMN",
    "country-code": "512",
    "iso_3166-2": "ISO 3166-2:OM",
    region: "Asia",
    "sub-region": "Western Asia",
    "region-code": "142",
    "sub-region-code": "145",
    states: [
      {
        name: "Ad Dakhillyah",
        code: "DA",
      },
      {
        name: "Al Batinah",
        code: "BA",
      },
      {
        name: "Al Janblyah",
        code: "JA",
      },
      {
        name: "Al Wusta",
        code: "WU",
      },
      {
        name: "Ash Sharqlyah",
        code: "SH",
      },
      {
        name: "Az Zahirah",
        code: "ZA",
      },
      {
        name: "Masqat",
        code: "MA",
      },
      {
        name: "Musandam",
        code: "MU",
      },
    ],
  },
  {
    name: "Pakistan",
    "alpha-2": "PK",
    "alpha-3": "PAK",
    "country-code": "586",
    "iso_3166-2": "ISO 3166-2:PK",
    region: "Asia",
    "sub-region": "Southern Asia",
    "region-code": "142",
    "sub-region-code": "034",
    states: [
      {
        name: "Islamabad",
        code: "IS",
      },
      {
        name: "Baluchistan (en)",
        code: "BA",
      },
      {
        name: "North-West Frontier",
        code: "NW",
      },
      {
        name: "Sind (en)",
        code: "SD",
      },
      {
        name: "Federally Administered Tribal Aresa",
        code: "TA",
      },
      {
        name: "Azad Rashmir",
        code: "JK",
      },
      {
        name: "Northern Areas",
        code: "NA",
      },
    ],
  },
  {
    name: "Palau",
    "alpha-2": "PW",
    "alpha-3": "PLW",
    "country-code": "585",
    "iso_3166-2": "ISO 3166-2:PW",
    region: "Oceania",
    "sub-region": "Micronesia",
    "region-code": "009",
    "sub-region-code": "057",
    states: [],
  },
  {
    name: "Palestine, State of",
    "alpha-2": "PS",
    "alpha-3": "PSE",
    "country-code": "275",
    "iso_3166-2": "ISO 3166-2:PS",
    region: "Asia",
    "sub-region": "Western Asia",
    "region-code": "142",
    "sub-region-code": "145",
    states: [
      {
        name: "Jenin",
        code: "_A",
      },
      {
        name: "Tubas",
        code: "_B",
      },
      {
        name: "Tulkarm",
        code: "_C",
      },
      {
        name: "Nablus",
        code: "_D",
      },
      {
        name: "Qalqilya",
        code: "_E",
      },
      {
        name: "Salfit",
        code: "_F",
      },
      {
        name: "Ramallah and Al-Bireh",
        code: "_G",
      },
      {
        name: "Jericho",
        code: "_H",
      },
      {
        name: "Jerusalem",
        code: "_I",
      },
      {
        name: "Bethlehem",
        code: "_J",
      },
      {
        name: "Hebron",
        code: "_K",
      },
      {
        name: "North Gaza",
        code: "_L",
      },
      {
        name: "Gaza",
        code: "_M",
      },
      {
        name: "Deir el-Balah",
        code: "_N",
      },
      {
        name: "Khan Yunis",
        code: "_O",
      },
      {
        name: "Rafah",
        code: "_P",
      },
    ],
  },
  {
    name: "Panama",
    "alpha-2": "PA",
    "alpha-3": "PAN",
    "country-code": "591",
    "iso_3166-2": "ISO 3166-2:PA",
    region: "Americas",
    "sub-region": "Central America",
    "region-code": "019",
    "sub-region-code": "013",
    states: [
      {
        name: "Bocas del Toro",
        code: "1",
      },
      {
        name: "Cocle",
        code: "2",
      },
      {
        name: "Chiriqui",
        code: "4",
      },
      {
        name: "Darien",
        code: "5",
      },
      {
        name: "Herrera",
        code: "6",
      },
      {
        name: "Loa Santoa",
        code: "7",
      },
      {
        name: "Panama",
        code: "8",
      },
      {
        name: "Veraguas",
        code: "9",
      },
      {
        name: "Comarca de San Blas",
        code: "Q",
      },
    ],
  },
  {
    name: "Papua New Guinea",
    "alpha-2": "PG",
    "alpha-3": "PNG",
    "country-code": "598",
    "iso_3166-2": "ISO 3166-2:PG",
    region: "Oceania",
    "sub-region": "Melanesia",
    "region-code": "009",
    "sub-region-code": "054",
    states: [
      {
        name: "National Capital District (Port Moresby)",
        code: "NCD",
      },
      {
        name: "Chimbu",
        code: "CPK",
      },
      {
        name: "Eastern Highlands",
        code: "EHG",
      },
      {
        name: "East New Britain",
        code: "EBR",
      },
      {
        name: "East Sepik",
        code: "ESW",
      },
      {
        name: "Enga",
        code: "EPW",
      },
      {
        name: "Gulf",
        code: "GPK",
      },
      {
        name: "Madang",
        code: "MPM",
      },
      {
        name: "Manus",
        code: "MRL",
      },
      {
        name: "Milne Bay",
        code: "MBA",
      },
      {
        name: "Morobe",
        code: "MPL",
      },
      {
        name: "New Ireland",
        code: "NIK",
      },
      {
        name: "North Solomons",
        code: "NSA",
      },
      {
        name: "Santaun",
        code: "SAN",
      },
      {
        name: "Southern Highlands",
        code: "SHM",
      },
      {
        name: "Western Highlands",
        code: "WHM",
      },
      {
        name: "West New Britain",
        code: "WBK",
      },
    ],
  },
  {
    name: "Paraguay",
    "alpha-2": "PY",
    "alpha-3": "PRY",
    "country-code": "600",
    "iso_3166-2": "ISO 3166-2:PY",
    region: "Americas",
    "sub-region": "South America",
    "region-code": "019",
    "sub-region-code": "005",
    states: [
      {
        name: "Asuncion",
        code: "ASU",
      },
      {
        name: "Alto Paraguay",
        code: "16",
      },
      {
        name: "Alto Parana",
        code: "10",
      },
      {
        name: "Amambay",
        code: "13",
      },
      {
        name: "Boqueron",
        code: "19",
      },
      {
        name: "Caeguazu",
        code: "5",
      },
      {
        name: "Caazapl",
        code: "6",
      },
      {
        name: "Canindeyu",
        code: "14",
      },
      {
        name: "Concepcion",
        code: "1",
      },
      {
        name: "Cordillera",
        code: "3",
      },
      {
        name: "Guaira",
        code: "4",
      },
      {
        name: "Itapua",
        code: "7",
      },
      {
        name: "Miaiones",
        code: "8",
      },
      {
        name: "Neembucu",
        code: "12",
      },
      {
        name: "Paraguari",
        code: "9",
      },
      {
        name: "Presidente Hayes",
        code: "15",
      },
      {
        name: "San Pedro",
        code: "2",
      },
    ],
  },
  {
    name: "Peru",
    "alpha-2": "PE",
    "alpha-3": "PER",
    "country-code": "604",
    "iso_3166-2": "ISO 3166-2:PE",
    region: "Americas",
    "sub-region": "South America",
    "region-code": "019",
    "sub-region-code": "005",
    states: [
      {
        name: "El Callao",
        code: "CAL",
      },
      {
        name: "Ancash",
        code: "ANC",
      },
      {
        name: "Apurimac",
        code: "APU",
      },
      {
        name: "Arequipa",
        code: "ARE",
      },
      {
        name: "Ayacucho",
        code: "AYA",
      },
      {
        name: "Cajamarca",
        code: "CAJ",
      },
      {
        name: "Cuzco",
        code: "CUS",
      },
      {
        name: "Huancavelica",
        code: "HUV",
      },
      {
        name: "Huanuco",
        code: "HUC",
      },
      {
        name: "Ica",
        code: "ICA",
      },
      {
        name: "Junin",
        code: "JUN",
      },
      {
        name: "La Libertad",
        code: "LAL",
      },
      {
        name: "Lambayeque",
        code: "LAM",
      },
      {
        name: "Lima",
        code: "LIM",
      },
      {
        name: "Loreto",
        code: "LOR",
      },
      {
        name: "Madre de Dios",
        code: "MDD",
      },
      {
        name: "Moquegua",
        code: "MOQ",
      },
      {
        name: "Pasco",
        code: "PAS",
      },
      {
        name: "Piura",
        code: "PIU",
      },
      {
        name: "Puno",
        code: "PUN",
      },
      {
        name: "San Martin",
        code: "SAM",
      },
      {
        name: "Tacna",
        code: "TAC",
      },
      {
        name: "Tumbes",
        code: "TUM",
      },
      {
        name: "Ucayali",
        code: "UCA",
      },
    ],
  },
  {
    name: "Philippines",
    "alpha-2": "PH",
    "alpha-3": "PHL",
    "country-code": "608",
    "iso_3166-2": "ISO 3166-2:PH",
    region: "Asia",
    "sub-region": "South-Eastern Asia",
    "region-code": "142",
    "sub-region-code": "035",
    states: [
      {
        name: "Abra",
        code: "ABR",
      },
      {
        name: "Agusan del Norte",
        code: "AGN",
      },
      {
        name: "Agusan del Sur",
        code: "AGS",
      },
      {
        name: "Aklan",
        code: "AKL",
      },
      {
        name: "Albay",
        code: "ALB",
      },
      {
        name: "Antique",
        code: "ANT",
      },
      {
        name: "Apayao",
        code: "APA",
      },
      {
        name: "Aurora",
        code: "AUR",
      },
      {
        name: "Basilan",
        code: "BAS",
      },
      {
        name: "Batasn",
        code: "BAN",
      },
      {
        name: "Batanes",
        code: "BTN",
      },
      {
        name: "Batangas",
        code: "BTG",
      },
      {
        name: "Benguet",
        code: "BEN",
      },
      {
        name: "Biliran",
        code: "BIL",
      },
      {
        name: "Bohol",
        code: "BOH",
      },
      {
        name: "Bukidnon",
        code: "BUK",
      },
      {
        name: "Bulacan",
        code: "BUL",
      },
      {
        name: "Cagayan",
        code: "CAG",
      },
      {
        name: "Camarines Norte",
        code: "CAN",
      },
      {
        name: "Camarines Sur",
        code: "CAS",
      },
      {
        name: "Camiguin",
        code: "CAM",
      },
      {
        name: "Capiz",
        code: "CAP",
      },
      {
        name: "Catanduanes",
        code: "CAT",
      },
      {
        name: "Cavite",
        code: "CAV",
      },
      {
        name: "Cebu",
        code: "CEB",
      },
      {
        name: "Compostela Valley",
        code: "COM",
      },
      {
        name: "Davao",
        code: "DAV",
      },
      {
        name: "Davao del Sur",
        code: "DAS",
      },
      {
        name: "Davao Oriental",
        code: "DAO",
      },
      {
        name: "Eastern Samar",
        code: "EAS",
      },
      {
        name: "Guimaras",
        code: "GUI",
      },
      {
        name: "Ifugao",
        code: "IFU",
      },
      {
        name: "Ilocos Norte",
        code: "ILN",
      },
      {
        name: "Ilocos Sur",
        code: "ILS",
      },
      {
        name: "Iloilo",
        code: "ILI",
      },
      {
        name: "Isabela",
        code: "ISA",
      },
      {
        name: "Kalinga-Apayso",
        code: "KAL",
      },
      {
        name: "Laguna",
        code: "LAG",
      },
      {
        name: "Lanao del Norte",
        code: "LAN",
      },
      {
        name: "Lanao del Sur",
        code: "LAS",
      },
      {
        name: "La Union",
        code: "LUN",
      },
      {
        name: "Leyte",
        code: "LEY",
      },
      {
        name: "Maguindanao",
        code: "MAG",
      },
      {
        name: "Marinduque",
        code: "MAD",
      },
      {
        name: "Masbate",
        code: "MAS",
      },
      {
        name: "Mindoro Occidental",
        code: "MDC",
      },
      {
        name: "Mindoro Oriental",
        code: "MDR",
      },
      {
        name: "Misamis Occidental",
        code: "MSC",
      },
      {
        name: "Misamis Oriental",
        code: "MSR",
      },
      {
        name: "Mountain Province",
        code: "MOU",
      },
      {
        name: "Negroe Occidental",
        code: "NEC",
      },
      {
        name: "Negros Oriental",
        code: "NER",
      },
      {
        name: "North Cotabato",
        code: "NCO",
      },
      {
        name: "Northern Samar",
        code: "NSA",
      },
      {
        name: "Nueva Ecija",
        code: "NUE",
      },
      {
        name: "Nueva Vizcaya",
        code: "NUV",
      },
      {
        name: "Palawan",
        code: "PLW",
      },
      {
        name: "Pampanga",
        code: "PAM",
      },
      {
        name: "Pangasinan",
        code: "PAN",
      },
      {
        name: "Quezon",
        code: "QUE",
      },
      {
        name: "Quirino",
        code: "QUI",
      },
      {
        name: "Rizal",
        code: "RIZ",
      },
      {
        name: "Romblon",
        code: "ROM",
      },
      {
        name: "Sarangani",
        code: "SAR",
      },
      {
        name: "Siquijor",
        code: "SIG",
      },
      {
        name: "Sorsogon",
        code: "SOR",
      },
      {
        name: "South Cotabato",
        code: "SCO",
      },
      {
        name: "Southern Leyte",
        code: "SLE",
      },
      {
        name: "Sultan Kudarat",
        code: "SUK",
      },
      {
        name: "Sulu",
        code: "SLU",
      },
      {
        name: "Surigao del Norte",
        code: "SUN",
      },
      {
        name: "Surigao del Sur",
        code: "SUR",
      },
      {
        name: "Tarlac",
        code: "TAR",
      },
      {
        name: "Tawi-Tawi",
        code: "TAW",
      },
      {
        name: "Western Samar",
        code: "WSA",
      },
      {
        name: "Zambales",
        code: "ZMB",
      },
      {
        name: "Zamboanga del Norte",
        code: "ZAN",
      },
      {
        name: "Zamboanga del Sur",
        code: "ZAS",
      },
      {
        name: "Zamboanga Sibiguey",
        code: "ZSI",
      },
    ],
  },
  {
    name: "Pitcairn",
    "alpha-2": "PN",
    "alpha-3": "PCN",
    "country-code": "612",
    "iso_3166-2": "ISO 3166-2:PN",
    region: "Oceania",
    "sub-region": "Polynesia",
    "region-code": "009",
    "sub-region-code": "061",
    states: [],
  },
  {
    name: "Poland",
    "alpha-2": "PL",
    "alpha-3": "POL",
    "country-code": "616",
    "iso_3166-2": "ISO 3166-2:PL",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "region-code": "150",
    "sub-region-code": "151",
    states: [
      {
        name: "mazowieckie",
        code: "MZ",
      },
      {
        name: "pomorskie",
        code: "PM",
      },
      {
        name: "dolnośląskie",
        code: "DS",
      },
      {
        name: "kujawsko-pomorskie",
        code: "KP",
      },
      {
        name: "lubelskie",
        code: "LU",
      },
      {
        name: "lubuskie",
        code: "LB",
      },
      {
        name: "łódzkie",
        code: "LD",
      },
      {
        name: "małopolskie",
        code: "MA",
      },
      {
        name: "opolskie",
        code: "OP",
      },
      {
        name: "podkarpackie",
        code: "PK",
      },
      {
        name: "podlaskie",
        code: "PD",
      },
      {
        name: "śląskie",
        code: "SL",
      },
      {
        name: "świętokrzyskie",
        code: "SK",
      },
      {
        name: "warmińsko-mazurskie",
        code: "WN",
      },
      {
        name: "wielkopolskie",
        code: "WP",
      },
      {
        name: "zachodniopomorskie",
        code: "ZP",
      },
    ],
  },
  {
    name: "Portugal",
    "alpha-2": "PT",
    "alpha-3": "PRT",
    "country-code": "620",
    "iso_3166-2": "ISO 3166-2:PT",
    region: "Europe",
    "sub-region": "Southern Europe",
    "region-code": "150",
    "sub-region-code": "039",
    states: [
      {
        name: "Aveiro",
        code: "01",
      },
      {
        name: "Beja",
        code: "02",
      },
      {
        name: "Braga",
        code: "03",
      },
      {
        name: "Braganca",
        code: "04",
      },
      {
        name: "Castelo Branco",
        code: "05",
      },
      {
        name: "Colmbra",
        code: "06",
      },
      {
        name: "Ovora",
        code: "07",
      },
      {
        name: "Faro",
        code: "08",
      },
      {
        name: "Guarda",
        code: "09",
      },
      {
        name: "Leiria",
        code: "10",
      },
      {
        name: "Lisboa",
        code: "11",
      },
      {
        name: "Portalegre",
        code: "12",
      },
      {
        name: "Porto",
        code: "13",
      },
      {
        name: "Santarem",
        code: "14",
      },
      {
        name: "Setubal",
        code: "15",
      },
      {
        name: "Viana do Castelo",
        code: "16",
      },
      {
        name: "Vila Real",
        code: "17",
      },
      {
        name: "Viseu",
        code: "18",
      },
      {
        name: "Regiao Autonoma dos Acores",
        code: "20",
      },
      {
        name: "Regiao Autonoma da Madeira",
        code: "30",
      },
    ],
  },
  {
    name: "Puerto Rico",
    "alpha-2": "PR",
    "alpha-3": "PRI",
    "country-code": "630",
    "iso_3166-2": "ISO 3166-2:PR",
    region: "Americas",
    "sub-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "029",
    states: [],
  },
  {
    name: "Qatar",
    "alpha-2": "QA",
    "alpha-3": "QAT",
    "country-code": "634",
    "iso_3166-2": "ISO 3166-2:QA",
    region: "Asia",
    "sub-region": "Western Asia",
    "region-code": "142",
    "sub-region-code": "145",
    states: [
      {
        name: "Ad Dawhah",
        code: "DA",
      },
      {
        name: "Al Ghuwayriyah",
        code: "GH",
      },
      {
        name: "Al Jumayliyah",
        code: "JU",
      },
      {
        name: "Al Khawr",
        code: "KH",
      },
      {
        name: "Al Wakrah",
        code: "WA",
      },
      {
        name: "Ar Rayyan",
        code: "RA",
      },
      {
        name: "Jariyan al Batnah",
        code: "JB",
      },
      {
        name: "Madinat ash Shamal",
        code: "MS",
      },
      {
        name: "Umm Salal",
        code: "US",
      },
    ],
  },
  {
    name: "Romania",
    "alpha-2": "RO",
    "alpha-3": "ROU",
    "country-code": "642",
    "iso_3166-2": "ISO 3166-2:RO",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "region-code": "150",
    "sub-region-code": "151",
    states: [
      {
        name: "Bucuresti",
        code: "B",
      },
      {
        name: "Alba",
        code: "AB",
      },
      {
        name: "Arad",
        code: "AR",
      },
      {
        name: "Arges",
        code: "AG",
      },
      {
        name: "Bacau",
        code: "BC",
      },
      {
        name: "Bihor",
        code: "BH",
      },
      {
        name: "Bistrita-Nasaud",
        code: "BN",
      },
      {
        name: "Boto'ani",
        code: "BT",
      },
      {
        name: "Bra'ov",
        code: "BV",
      },
      {
        name: "Braila",
        code: "BR",
      },
      {
        name: "Buzau",
        code: "BZ",
      },
      {
        name: "Caras-Severin",
        code: "CS",
      },
      {
        name: "Ca la ras'i",
        code: "CL",
      },
      {
        name: "Cluj",
        code: "CJ",
      },
      {
        name: "Constant'a",
        code: "CT",
      },
      {
        name: "Covasna",
        code: "CV",
      },
      {
        name: "Dambovit'a",
        code: "DB",
      },
      {
        name: "Dolj",
        code: "DJ",
      },
      {
        name: "Galat'i",
        code: "GL",
      },
      {
        name: "Giurgiu",
        code: "GR",
      },
      {
        name: "Gorj",
        code: "GJ",
      },
      {
        name: "Harghita",
        code: "HR",
      },
      {
        name: "Hunedoara",
        code: "HD",
      },
      {
        name: "Ialomit'a",
        code: "IL",
      },
      {
        name: "Ias'i",
        code: "IS",
      },
      {
        name: "Ilfov",
        code: "IF",
      },
      {
        name: "Maramures",
        code: "MM",
      },
      {
        name: "Mehedint'i",
        code: "MH",
      },
      {
        name: "Mures",
        code: "MS",
      },
      {
        name: "Neamt",
        code: "NT",
      },
      {
        name: "Olt",
        code: "OT",
      },
      {
        name: "Prahova",
        code: "PH",
      },
      {
        name: "Satu Mare",
        code: "SM",
      },
      {
        name: "Sa laj",
        code: "SJ",
      },
      {
        name: "Sibiu",
        code: "SB",
      },
      {
        name: "Suceava",
        code: "SV",
      },
      {
        name: "Teleorman",
        code: "TR",
      },
      {
        name: "Timis",
        code: "TM",
      },
      {
        name: "Tulcea",
        code: "TL",
      },
      {
        name: "Vaslui",
        code: "VS",
      },
      {
        name: "Valcea",
        code: "VL",
      },
      {
        name: "Vrancea",
        code: "VN",
      },
    ],
  },
  {
    name: "Russian Federation",
    "alpha-2": "RU",
    "alpha-3": "RUS",
    "country-code": "643",
    "iso_3166-2": "ISO 3166-2:RU",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "region-code": "150",
    "sub-region-code": "151",
    states: [
      {
        name: "Adygeya, Respublika",
        code: "AD",
      },
      {
        name: "Altay, Respublika",
        code: "AL",
      },
      {
        name: "Bashkortostan, Respublika",
        code: "BA",
      },
      {
        name: "Buryatiya, Respublika",
        code: "BU",
      },
      {
        name: "Chechenskaya Respublika",
        code: "CE",
      },
      {
        name: "Chuvashskaya Respublika",
        code: "CU",
      },
      {
        name: "Dagestan, Respublika",
        code: "DA",
      },
      {
        name: "Ingushskaya Respublika",
        code: "IN",
      },
      {
        name: "Kabardino-Balkarskaya",
        code: "KB",
      },
      {
        name: "Kalmykiya, Respublika",
        code: "KL",
      },
      {
        name: "Karachayevo-Cherkesskaya Respublika",
        code: "KC",
      },
      {
        name: "Kareliya, Respublika",
        code: "KR",
      },
      {
        name: "Khakasiya, Respublika",
        code: "KK",
      },
      {
        name: "Komi, Respublika",
        code: "KO",
      },
      {
        name: "Mariy El, Respublika",
        code: "ME",
      },
      {
        name: "Mordoviya, Respublika",
        code: "MO",
      },
      {
        name: "Sakha, Respublika [Yakutiya]",
        code: "SA",
      },
      {
        name: "Severnaya Osetiya, Respublika",
        code: "SE",
      },
      {
        name: "Tatarstan, Respublika",
        code: "TA",
      },
      {
        name: "Tyva, Respublika [Tuva]",
        code: "TY",
      },
      {
        name: "Udmurtskaya Respublika",
        code: "UD",
      },
      {
        name: "Altayskiy kray",
        code: "ALT",
      },
      {
        name: "Khabarovskiy kray",
        code: "KHA",
      },
      {
        name: "Krasnodarskiy kray",
        code: "KDA",
      },
      {
        name: "Krasnoyarskiy kray",
        code: "KYA",
      },
      {
        name: "Primorskiy kray",
        code: "PRI",
      },
      {
        name: "Stavropol'skiy kray",
        code: "STA",
      },
      {
        name: "Amurskaya oblast'",
        code: "AMU",
      },
      {
        name: "Arkhangel'skaya oblast'",
        code: "ARK",
      },
      {
        name: "Astrakhanskaya oblast'",
        code: "AST",
      },
      {
        name: "Belgorodskaya oblast'",
        code: "BEL",
      },
      {
        name: "Bryanskaya oblast'",
        code: "BRY",
      },
      {
        name: "Chelyabinskaya oblast'",
        code: "CHE",
      },
      {
        name: "Chitinskaya oblast'",
        code: "CHI",
      },
      {
        name: "Irkutskaya oblast'",
        code: "IRK",
      },
      {
        name: "Ivanovskaya oblast'",
        code: "IVA",
      },
      {
        name: "Kaliningradskaya oblast'",
        code: "KGD",
      },
      {
        name: "Kaluzhskaya oblast'",
        code: "KLU",
      },
      {
        name: "Kamchatskaya oblast'",
        code: "KAM",
      },
      {
        name: "Kemerovskaya oblast'",
        code: "KEM",
      },
      {
        name: "Kirovskaya oblast'",
        code: "KIR",
      },
      {
        name: "Kostromskaya oblast'",
        code: "KOS",
      },
      {
        name: "Kurganskaya oblast'",
        code: "KGN",
      },
      {
        name: "Kurskaya oblast'",
        code: "KRS",
      },
      {
        name: "Leningradskaya oblast'",
        code: "LEN",
      },
      {
        name: "Lipetskaya oblast'",
        code: "LIP",
      },
      {
        name: "Magadanskaya oblast'",
        code: "MAG",
      },
      {
        name: "Moskovskaya oblast'",
        code: "MOS",
      },
      {
        name: "Murmanskaya oblast'",
        code: "MUR",
      },
      {
        name: "Nizhegorodskaya oblast'",
        code: "NIZ",
      },
      {
        name: "Novgorodskaya oblast'",
        code: "NGR",
      },
      {
        name: "Novosibirskaya oblast'",
        code: "NVS",
      },
      {
        name: "Omskaya oblast'",
        code: "OMS",
      },
      {
        name: "Orenburgskaya oblast'",
        code: "ORE",
      },
      {
        name: "Orlovskaya oblast'",
        code: "ORL",
      },
      {
        name: "Penzenskaya oblast'",
        code: "PNZ",
      },
      {
        name: "Permskaya oblast'",
        code: "PER",
      },
      {
        name: "Pskovskaya oblast'",
        code: "PSK",
      },
      {
        name: "Rostovskaya oblast'",
        code: "ROS",
      },
      {
        name: "Ryazanskaya oblast'",
        code: "RYA",
      },
      {
        name: "Sakhalinskaya oblast'",
        code: "SAK",
      },
      {
        name: "Samarskaya oblast'",
        code: "SAM",
      },
      {
        name: "Saratovskaya oblast'",
        code: "SAR",
      },
      {
        name: "Smolenskaya oblast'",
        code: "SMO",
      },
      {
        name: "Sverdlovskaya oblast'",
        code: "SVE",
      },
      {
        name: "Tambovskaya oblast'",
        code: "TAM",
      },
      {
        name: "Tomskaya oblast'",
        code: "TOM",
      },
      {
        name: "Tul'skaya oblast'",
        code: "TUL",
      },
      {
        name: "Tverskaya oblast'",
        code: "TVE",
      },
      {
        name: "Tyumenskaya oblast'",
        code: "TYU",
      },
      {
        name: "Ul'yanovskaya oblast'",
        code: "ULY",
      },
      {
        name: "Vladimirskaya oblast'",
        code: "VLA",
      },
      {
        name: "Volgogradskaya oblast'",
        code: "VGG",
      },
      {
        name: "Vologodskaya oblast'",
        code: "VLG",
      },
      {
        name: "Voronezhskaya oblast'",
        code: "VOR",
      },
      {
        name: "Yaroslavskaya oblast'",
        code: "YAR",
      },
      {
        name: "Moskva",
        code: "MOW",
      },
      {
        name: "Sankt-Peterburg",
        code: "SPE",
      },
      {
        name: "Yevreyskaya avtonomnaya oblast'",
        code: "YEV",
      },
      {
        name: "Aginskiy Buryatskiy avtonomnyy",
        code: "AGB",
      },
      {
        name: "Chukotskiy avtonomnyy okrug",
        code: "CHU",
      },
      {
        name: "Evenkiyskiy avtonomnyy okrug",
        code: "EVE",
      },
      {
        name: "Khanty-Mansiyskiy avtonomnyy okrug",
        code: "KHM",
      },
      {
        name: "Komi-Permyatskiy avtonomnyy okrug",
        code: "KOP",
      },
      {
        name: "Koryakskiy avtonomnyy okrug",
        code: "KOR",
      },
      {
        name: "Nenetskiy avtonomnyy okrug",
        code: "NEN",
      },
      {
        name: "Taymyrskiy (Dolgano-Nenetskiy)",
        code: "TAY",
      },
      {
        name: "Ust'-Ordynskiy Buryatskiy",
        code: "UOB",
      },
      {
        name: "Yamalo-Nenetskiy avtonomnyy okrug",
        code: "YAN",
      },
    ],
  },
  {
    name: "Rwanda",
    "alpha-2": "RW",
    "alpha-3": "RWA",
    "country-code": "646",
    "iso_3166-2": "ISO 3166-2:RW",
    region: "Africa",
    "sub-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "014",
    states: [
      {
        name: "Butare",
        code: "C",
      },
      {
        name: "Byumba",
        code: "I",
      },
      {
        name: "Cyangugu",
        code: "E",
      },
      {
        name: "Gikongoro",
        code: "D",
      },
      {
        name: "Gisenyi",
        code: "G",
      },
      {
        name: "Gitarama",
        code: "B",
      },
      {
        name: "Kibungo",
        code: "J",
      },
      {
        name: "Kibuye",
        code: "F",
      },
      {
        name: "Kigali-Rural Kigali y' Icyaro",
        code: "K",
      },
      {
        name: "Kigali-Ville Kigali Ngari",
        code: "L",
      },
      {
        name: "Mutara",
        code: "M",
      },
      {
        name: "Ruhengeri",
        code: "H",
      },
    ],
  },
  {
    name: "Réunion",
    "alpha-2": "RE",
    "alpha-3": "REU",
    "country-code": "638",
    "iso_3166-2": "ISO 3166-2:RE",
    region: "Africa",
    "sub-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "014",
    states: [],
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    "alpha-2": "SH",
    "alpha-3": "SHN",
    "country-code": "654",
    "iso_3166-2": "ISO 3166-2:SH",
    region: "Africa",
    "sub-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "011",
    states: [
      {
        name: "Saint Helena",
        code: "SH",
      },
      {
        name: "Ascension",
        code: "AC",
      },
      {
        name: "Tristan da Cunha",
        code: "TA",
      },
    ],
  },
  {
    name: "Saint Kitts and Nevis",
    "alpha-2": "KN",
    "alpha-3": "KNA",
    "country-code": "659",
    "iso_3166-2": "ISO 3166-2:KN",
    region: "Americas",
    "sub-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "029",
    states: [],
  },
  {
    name: "Saint Lucia",
    "alpha-2": "LC",
    "alpha-3": "LCA",
    "country-code": "662",
    "iso_3166-2": "ISO 3166-2:LC",
    region: "Americas",
    "sub-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "029",
    states: [],
  },
  {
    name: "Saint Pierre and Miquelon",
    "alpha-2": "PM",
    "alpha-3": "SPM",
    "country-code": "666",
    "iso_3166-2": "ISO 3166-2:PM",
    region: "Americas",
    "sub-region": "Northern America",
    "region-code": "019",
    "sub-region-code": "021",
    states: [],
  },
  {
    name: "Saint Vincent and the Grenadines",
    "alpha-2": "VC",
    "alpha-3": "VCT",
    "country-code": "670",
    "iso_3166-2": "ISO 3166-2:VC",
    region: "Americas",
    "sub-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "029",
    states: [],
  },
  {
    name: "Samoa",
    "alpha-2": "WS",
    "alpha-3": "WSM",
    "country-code": "882",
    "iso_3166-2": "ISO 3166-2:WS",
    region: "Oceania",
    "sub-region": "Polynesia",
    "region-code": "009",
    "sub-region-code": "061",
    states: [
      {
        name: "A'ana",
        code: "AA",
      },
      {
        name: "Aiga-i-le-Tai",
        code: "AL",
      },
      {
        name: "Atua",
        code: "AT",
      },
      {
        name: "Fa'aaaleleaga",
        code: "FA",
      },
      {
        name: "Gaga'emauga",
        code: "GE",
      },
      {
        name: "Gagaifomauga",
        code: "GI",
      },
      {
        name: "Palauli",
        code: "PA",
      },
      {
        name: "Satupa'itea",
        code: "SA",
      },
      {
        name: "Tuamasaga",
        code: "TU",
      },
      {
        name: "Va'a-o-Fonoti",
        code: "VF",
      },
      {
        name: "Vaisigano",
        code: "VS",
      },
    ],
  },
  {
    name: "San Marino",
    "alpha-2": "SM",
    "alpha-3": "SMR",
    "country-code": "674",
    "iso_3166-2": "ISO 3166-2:SM",
    region: "Europe",
    "sub-region": "Southern Europe",
    "region-code": "150",
    "sub-region-code": "039",
    states: [],
  },
  {
    name: "Saudi Arabia",
    "alpha-2": "SA",
    "alpha-3": "SAU",
    "country-code": "682",
    "iso_3166-2": "ISO 3166-2:SA",
    region: "Asia",
    "sub-region": "Western Asia",
    "region-code": "142",
    "sub-region-code": "145",
    states: [
      {
        name: "Al Batah",
        code: "11",
      },
      {
        name: "Al H,udd ash Shamallyah",
        code: "08",
      },
      {
        name: "Al Jawf",
        code: "12",
      },
      {
        name: "Al Madinah",
        code: "03",
      },
      {
        name: "Al Qasim",
        code: "05",
      },
      {
        name: "Ar Riyad",
        code: "01",
      },
      {
        name: "Asir",
        code: "14",
      },
      {
        name: "Ha'il",
        code: "06",
      },
      {
        name: "Jlzan",
        code: "09",
      },
      {
        name: "Makkah",
        code: "02",
      },
      {
        name: "Najran",
        code: "10",
      },
      {
        name: "Tabuk",
        code: "07",
      },
    ],
  },
  {
    name: "Senegal",
    "alpha-2": "SN",
    "alpha-3": "SEN",
    "country-code": "686",
    "iso_3166-2": "ISO 3166-2:SN",
    region: "Africa",
    "sub-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "011",
    states: [
      {
        name: "Dakar",
        code: "DK",
      },
      {
        name: "Diourbel",
        code: "DB",
      },
      {
        name: "Fatick",
        code: "FK",
      },
      {
        name: "Kaolack",
        code: "KL",
      },
      {
        name: "Kolda",
        code: "KD",
      },
      {
        name: "Louga",
        code: "LG",
      },
      {
        name: "Matam",
        code: "MT",
      },
      {
        name: "Saint-Louis",
        code: "SL",
      },
      {
        name: "Tambacounda",
        code: "TC",
      },
      {
        name: "Thies",
        code: "TH",
      },
      {
        name: "Ziguinchor",
        code: "ZG",
      },
    ],
  },
  {
    name: "Seychelles",
    "alpha-2": "SC",
    "alpha-3": "SYC",
    "country-code": "690",
    "iso_3166-2": "ISO 3166-2:SC",
    region: "Africa",
    "sub-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "014",
    states: [],
  },
  {
    name: "Sierra Leone",
    "alpha-2": "SL",
    "alpha-3": "SLE",
    "country-code": "694",
    "iso_3166-2": "ISO 3166-2:SL",
    region: "Africa",
    "sub-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "011",
    states: [
      {
        name: "Western Area (Freetown)",
        code: "W",
      },
    ],
  },
  {
    name: "Singapore",
    "alpha-2": "SG",
    "alpha-3": "SGP",
    "country-code": "702",
    "iso_3166-2": "ISO 3166-2:SG",
    region: "Asia",
    "sub-region": "South-Eastern Asia",
    "region-code": "142",
    "sub-region-code": "035",
    states: [],
  },
  {
    name: "Slovakia",
    "alpha-2": "SK",
    "alpha-3": "SVK",
    "country-code": "703",
    "iso_3166-2": "ISO 3166-2:SK",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "region-code": "150",
    "sub-region-code": "151",
    states: [
      {
        name: "Banskobystrický kraj",
        code: "BC",
      },
      {
        name: "Bratislavský kraj",
        code: "BL",
      },
      {
        name: "Košický kraj",
        code: "KI",
      },
      {
        name: "Nitriansky kraj",
        code: "NJ",
      },
      {
        name: "Prešovský kraj",
        code: "PV",
      },
      {
        name: "Trenčiansky kraj",
        code: "TC",
      },
      {
        name: "Trnavský kraj",
        code: "TA",
      },
      {
        name: "Žilinský kraj",
        code: "ZI",
      },
    ],
  },
  {
    name: "Slovenia",
    "alpha-2": "SI",
    "alpha-3": "SVN",
    "country-code": "705",
    "iso_3166-2": "ISO 3166-2:SI",
    region: "Europe",
    "sub-region": "Southern Europe",
    "region-code": "150",
    "sub-region-code": "039",
    states: [
      {
        name: "Ajdovscina",
        code: "001",
      },
      {
        name: "Beltinci",
        code: "002",
      },
      {
        name: "Benedikt",
        code: "148",
      },
      {
        name: "Bistrica ob Sotli",
        code: "149",
      },
      {
        name: "Bled",
        code: "003",
      },
      {
        name: "Bloke",
        code: "150",
      },
      {
        name: "Bohinj",
        code: "004",
      },
      {
        name: "Borovnica",
        code: "005",
      },
      {
        name: "Bovec",
        code: "006",
      },
      {
        name: "Braslovce",
        code: "151",
      },
      {
        name: "Brda",
        code: "007",
      },
      {
        name: "Brezovica",
        code: "008",
      },
      {
        name: "Brezica",
        code: "009",
      },
      {
        name: "Cankova",
        code: "152",
      },
      {
        name: "Celje",
        code: "011",
      },
      {
        name: "Cerklje na Gorenjskem",
        code: "012",
      },
      {
        name: "Cerknica",
        code: "013",
      },
      {
        name: "Cerkno",
        code: "014",
      },
      {
        name: "Cerkvenjak",
        code: "153",
      },
      {
        name: "Crensovci",
        code: "015",
      },
      {
        name: "Crna na Koroskem",
        code: "016",
      },
      {
        name: "Crnomelj",
        code: "017",
      },
      {
        name: "Destrnik",
        code: "018",
      },
      {
        name: "Divaca",
        code: "019",
      },
      {
        name: "Dobje",
        code: "154",
      },
      {
        name: "Dobrepolje",
        code: "020",
      },
      {
        name: "Dobrna",
        code: "155",
      },
      {
        name: "Dobrova-Polhov Gradec",
        code: "021",
      },
      {
        name: "Dobrovnik",
        code: "156",
      },
      {
        name: "Dol pri Ljubljani",
        code: "022",
      },
      {
        name: "Dolenjske Toplice",
        code: "157",
      },
      {
        name: "Domzale",
        code: "023",
      },
      {
        name: "Dornava",
        code: "024",
      },
      {
        name: "Dravograd",
        code: "025",
      },
      {
        name: "Duplek",
        code: "026",
      },
      {
        name: "Gorenja vas-Poljane",
        code: "027",
      },
      {
        name: "Gorsnica",
        code: "028",
      },
      {
        name: "Gornja Radgona",
        code: "029",
      },
      {
        name: "Gornji Grad",
        code: "030",
      },
      {
        name: "Gornji Petrovci",
        code: "031",
      },
      {
        name: "Grad",
        code: "158",
      },
      {
        name: "Grosuplje",
        code: "032",
      },
      {
        name: "Hajdina",
        code: "159",
      },
      {
        name: "Hoce-Slivnica",
        code: "160",
      },
      {
        name: "Hodos",
        code: "161",
      },
      {
        name: "Jorjul",
        code: "162",
      },
      {
        name: "Hrastnik",
        code: "034",
      },
      {
        name: "Hrpelje-Kozina",
        code: "035",
      },
      {
        name: "Idrija",
        code: "036",
      },
      {
        name: "Ig",
        code: "037",
      },
      {
        name: "IIrska Bistrica",
        code: "038",
      },
      {
        name: "Ivancna Gorica",
        code: "039",
      },
      {
        name: "Izola",
        code: "040",
      },
      {
        name: "Jesenice",
        code: "041",
      },
      {
        name: "Jezersko",
        code: "163",
      },
      {
        name: "Jursinci",
        code: "042",
      },
      {
        name: "Kamnik",
        code: "043",
      },
      {
        name: "Kanal",
        code: "044",
      },
      {
        name: "Kidricevo",
        code: "045",
      },
      {
        name: "Kobarid",
        code: "046",
      },
      {
        name: "Kobilje",
        code: "047",
      },
      {
        name: "Jovevje",
        code: "048",
      },
      {
        name: "Komen",
        code: "049",
      },
      {
        name: "Komenda",
        code: "164",
      },
      {
        name: "Koper",
        code: "050",
      },
      {
        name: "Kostel",
        code: "165",
      },
      {
        name: "Kozje",
        code: "051",
      },
      {
        name: "Kranj",
        code: "052",
      },
      {
        name: "Kranjska Gora",
        code: "053",
      },
      {
        name: "Krizevci",
        code: "166",
      },
      {
        name: "Krsko",
        code: "054",
      },
      {
        name: "Kungota",
        code: "055",
      },
      {
        name: "Kuzma",
        code: "056",
      },
      {
        name: "Lasko",
        code: "057",
      },
      {
        name: "Lenart",
        code: "058",
      },
      {
        name: "Lendava",
        code: "059",
      },
      {
        name: "Litija",
        code: "060",
      },
      {
        name: "Ljubljana",
        code: "061",
      },
      {
        name: "Ljubno",
        code: "062",
      },
      {
        name: "Ljutomer",
        code: "063",
      },
      {
        name: "Logatec",
        code: "064",
      },
      {
        name: "Loska dolina",
        code: "065",
      },
      {
        name: "Loski Potok",
        code: "066",
      },
      {
        name: "Lovrenc na Pohorju",
        code: "167",
      },
      {
        name: "Luce",
        code: "067",
      },
      {
        name: "Lukovica",
        code: "068",
      },
      {
        name: "Majsperk",
        code: "069",
      },
      {
        name: "Maribor",
        code: "070",
      },
      {
        name: "Markovci",
        code: "168",
      },
      {
        name: "Medvode",
        code: "071",
      },
      {
        name: "Menges",
        code: "072",
      },
      {
        name: "Metlika",
        code: "073",
      },
      {
        name: "Mezica",
        code: "074",
      },
      {
        name: "Miklavz na Dravskern polju",
        code: "169",
      },
      {
        name: "Miren-Kostanjevica",
        code: "075",
      },
      {
        name: "Mirna Pec",
        code: "170",
      },
      {
        name: "Mislinja",
        code: "076",
      },
      {
        name: "Moravce",
        code: "077",
      },
      {
        name: "Moravske Toplice",
        code: "078",
      },
      {
        name: "Mozirje",
        code: "079",
      },
      {
        name: "Murska Sobota",
        code: "080",
      },
      {
        name: "Muta",
        code: "081",
      },
      {
        name: "Naklo",
        code: "082",
      },
      {
        name: "Nazarje",
        code: "083",
      },
      {
        name: "Nova Gorica",
        code: "084",
      },
      {
        name: "Nova mesto",
        code: "085",
      },
      {
        name: "Sveta Ana",
        code: "181",
      },
      {
        name: "Sveti Andraz v Slovenskih goricah",
        code: "182",
      },
      {
        name: "Sveti Jurij",
        code: "116",
      },
      {
        name: "Salovci",
        code: "033",
      },
      {
        name: "Sempeter-Vrtojba",
        code: "183",
      },
      {
        name: "Sencur",
        code: "117",
      },
      {
        name: "Sentilj",
        code: "118",
      },
      {
        name: "Sentjernej",
        code: "119",
      },
      {
        name: "Sentjur pri Celju",
        code: "120",
      },
      {
        name: "Skocjan",
        code: "121",
      },
      {
        name: "Skofja Loka",
        code: "122",
      },
      {
        name: "Skoftjica",
        code: "123",
      },
      {
        name: "Smarje pri Jelsah",
        code: "124",
      },
      {
        name: "Smartno ob Paki",
        code: "125",
      },
      {
        name: "Smartno pri Litiji",
        code: "194",
      },
      {
        name: "Sostanj",
        code: "126",
      },
      {
        name: "Store",
        code: "127",
      },
      {
        name: "Tabor",
        code: "184",
      },
      {
        name: "Tisina",
        code: "010",
      },
      {
        name: "Tolmin",
        code: "128",
      },
      {
        name: "Trbovje",
        code: "129",
      },
      {
        name: "Trebnje",
        code: "130",
      },
      {
        name: "Trnovska vas",
        code: "185",
      },
      {
        name: "Trzic",
        code: "131",
      },
      {
        name: "Trzin",
        code: "186",
      },
      {
        name: "Turnisce",
        code: "132",
      },
      {
        name: "Velenje",
        code: "133",
      },
      {
        name: "Velika Polana",
        code: "187",
      },
      {
        name: "Velika Lasce",
        code: "134",
      },
      {
        name: "Verzej",
        code: "188",
      },
      {
        name: "Videm",
        code: "135",
      },
      {
        name: "Vipava",
        code: "136",
      },
      {
        name: "Vitanje",
        code: "137",
      },
      {
        name: "Vojnik",
        code: "138",
      },
      {
        name: "Vransko",
        code: "189",
      },
      {
        name: "Vrhnika",
        code: "140",
      },
      {
        name: "Vuzenica",
        code: "141",
      },
      {
        name: "Zagorje ob Savi",
        code: "142",
      },
      {
        name: "Zavrc",
        code: "143",
      },
      {
        name: "Zrece",
        code: "144",
      },
      {
        name: "Zalec",
        code: "190",
      },
      {
        name: "Zelezniki",
        code: "146",
      },
      {
        name: "Zetale",
        code: "191",
      },
      {
        name: "Ziri",
        code: "147",
      },
      {
        name: "Zirovnica",
        code: "192",
      },
      {
        name: "Zuzemberk",
        code: "193",
      },
    ],
  },
  {
    name: "Solomon Islands",
    "alpha-2": "SB",
    "alpha-3": "SLB",
    "country-code": "090",
    "iso_3166-2": "ISO 3166-2:SB",
    region: "Oceania",
    "sub-region": "Melanesia",
    "region-code": "009",
    "sub-region-code": "054",
    states: [
      {
        name: "Capital Territory (Honiara)",
        code: "CT",
      },
      {
        name: "Guadalcanal",
        code: "GU",
      },
      {
        name: "Isabel",
        code: "IS",
      },
      {
        name: "Makira",
        code: "MK",
      },
      {
        name: "Malaita",
        code: "ML",
      },
      {
        name: "Temotu",
        code: "TE",
      },
    ],
  },
  {
    name: "Somalia",
    "alpha-2": "SO",
    "alpha-3": "SOM",
    "country-code": "706",
    "iso_3166-2": "ISO 3166-2:SO",
    region: "Africa",
    "sub-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "014",
    states: [
      {
        name: "Awdal",
        code: "AW",
      },
      {
        name: "Bakool",
        code: "BK",
      },
      {
        name: "Banaadir",
        code: "BN",
      },
      {
        name: "Bay",
        code: "BY",
      },
      {
        name: "Galguduud",
        code: "GA",
      },
      {
        name: "Gedo",
        code: "GE",
      },
      {
        name: "Hiirsan",
        code: "HI",
      },
      {
        name: "Jubbada Dhexe",
        code: "JD",
      },
      {
        name: "Jubbada Hoose",
        code: "JH",
      },
      {
        name: "Mudug",
        code: "MU",
      },
      {
        name: "Nugaal",
        code: "NU",
      },
      {
        name: "Saneag",
        code: "SA",
      },
      {
        name: "Shabeellaha Dhexe",
        code: "SD",
      },
      {
        name: "Shabeellaha Hoose",
        code: "SH",
      },
      {
        name: "Sool",
        code: "SO",
      },
      {
        name: "Togdheer",
        code: "TO",
      },
      {
        name: "Woqooyi Galbeed",
        code: "WO",
      },
    ],
  },
  {
    name: "South Africa",
    "alpha-2": "ZA",
    "alpha-3": "ZAF",
    "country-code": "710",
    "iso_3166-2": "ISO 3166-2:ZA",
    region: "Africa",
    "sub-region": "Southern Africa",
    "region-code": "002",
    "sub-region-code": "018",
    states: [
      {
        name: "Eastern Cape",
        code: "EC",
      },
      {
        name: "Free State",
        code: "FS",
      },
      {
        name: "Gauteng",
        code: "GT",
      },
      {
        name: "Kwazulu-Natal",
        code: "NL",
      },
      {
        name: "Mpumalanga",
        code: "MP",
      },
      {
        name: "Northern Cape",
        code: "NC",
      },
      {
        name: "Limpopo",
        code: "NP",
      },
      {
        name: "Western Cape",
        code: "WC",
      },
      {
        name: "North West",
        code: "NW",
      },
    ],
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    "alpha-2": "GS",
    "alpha-3": "SGS",
    "country-code": "239",
    "iso_3166-2": "ISO 3166-2:GS",
    "sub-region-code": null,
    "region-code": null,
    "sub-region": null,
    region: null,
    states: [],
  },
  {
    name: "Spain",
    "alpha-2": "ES",
    "alpha-3": "ESP",
    "country-code": "724",
    "iso_3166-2": "ISO 3166-2:ES",
    region: "Europe",
    "sub-region": "Southern Europe",
    "region-code": "150",
    "sub-region-code": "039",
    states: [
      {
        name: "Álava",
        code: "VI",
      },
      {
        name: "Albacete",
        code: "AB",
      },
      {
        name: "Alicante",
        code: "A",
      },
      {
        name: "Almería",
        code: "AL",
      },
      {
        name: "Asturias",
        code: "O",
      },
      {
        name: "Ávila",
        code: "AV",
      },
      {
        name: "Badajoz",
        code: "BA",
      },
      {
        name: "Baleares",
        code: "PM",
      },
      {
        name: "Barcelona",
        code: "B",
      },
      {
        name: "Burgos",
        code: "BU",
      },
      {
        name: "Cáceres",
        code: "CC",
      },
      {
        name: "Cádiz",
        code: "CA",
      },
      {
        name: "Cantabria",
        code: "S",
      },
      {
        name: "Castellón",
        code: "CS",
      },
      {
        name: "Ciudad Real",
        code: "CR",
      },
      {
        name: "Cuenca",
        code: "CU",
      },
      {
        name: "Girona [Gerona]",
        code: "GE",
      },
      {
        name: "Granada",
        code: "GR",
      },
      {
        name: "Guadalajara",
        code: "GU",
      },
      {
        name: "Guipúzcoa",
        code: "SS",
      },
      {
        name: "Huelva",
        code: "H",
      },
      {
        name: "Huesca",
        code: "HU",
      },
      {
        name: "Jaén",
        code: "J",
      },
      {
        name: "La Coruña",
        code: "C",
      },
      {
        name: "La Rioja",
        code: "LO",
      },
      {
        name: "Las Palmas",
        code: "GC",
      },
      {
        name: "León",
        code: "LE",
      },
      {
        name: "Lleida [Lérida]",
        code: "L",
      },
      {
        name: "Lugo",
        code: "LU",
      },
      {
        name: "Madrid",
        code: "M",
      },
      {
        name: "Málaga",
        code: "MA",
      },
      {
        name: "Murcia",
        code: "MU",
      },
      {
        name: "Navarra",
        code: "NA",
      },
      {
        name: "Ourense",
        code: "OR",
      },
      {
        name: "Palencia",
        code: "P",
      },
      {
        name: "Pontevedra",
        code: "PO",
      },
      {
        name: "Salamanca",
        code: "SA",
      },
      {
        name: "Santa Cruz de Tenerife",
        code: "TF",
      },
      {
        name: "Segovia",
        code: "SG",
      },
      {
        name: "Sevilla",
        code: "SE",
      },
      {
        name: "Soria",
        code: "SO",
      },
      {
        name: "Tarragona",
        code: "T",
      },
      {
        name: "Teruel",
        code: "TE",
      },
      {
        name: "Valencia",
        code: "V",
      },
      {
        name: "Valladolid",
        code: "VA",
      },
      {
        name: "Vizcaya",
        code: "BI",
      },
      {
        name: "Zamora",
        code: "ZA",
      },
      {
        name: "Zaragoza",
        code: "Z",
      },
      {
        name: "Ceuta",
        code: "CE",
      },
      {
        name: "Melilla",
        code: "ML",
      },
    ],
  },
  {
    name: "Sri Lanka",
    "alpha-2": "LK",
    "alpha-3": "LKA",
    "country-code": "144",
    "iso_3166-2": "ISO 3166-2:LK",
    region: "Asia",
    "sub-region": "Southern Asia",
    "region-code": "142",
    "sub-region-code": "034",
    states: [
      {
        name: "Ampara",
        code: "52",
      },
      {
        name: "Anuradhapura",
        code: "71",
      },
      {
        name: "Badulla",
        code: "81",
      },
      {
        name: "Batticaloa",
        code: "51",
      },
      {
        name: "Colombo",
        code: "11",
      },
      {
        name: "Galle",
        code: "31",
      },
      {
        name: "Gampaha",
        code: "12",
      },
      {
        name: "Hambantota",
        code: "33",
      },
      {
        name: "Jaffna",
        code: "41",
      },
      {
        name: "Kalutara",
        code: "13",
      },
      {
        name: "Kandy",
        code: "21",
      },
      {
        name: "Kegalla",
        code: "92",
      },
      {
        name: "Kilinochchi",
        code: "42",
      },
      {
        name: "Kurunegala",
        code: "61",
      },
      {
        name: "Mannar",
        code: "43",
      },
      {
        name: "Matale",
        code: "22",
      },
      {
        name: "Matara",
        code: "32",
      },
      {
        name: "Monaragala",
        code: "82",
      },
      {
        name: "Mullaittivu",
        code: "45",
      },
      {
        name: "Nuwara Eliya",
        code: "23",
      },
      {
        name: "Polonnaruwa",
        code: "72",
      },
      {
        name: "Puttalum",
        code: "62",
      },
      {
        name: "Ratnapura",
        code: "91",
      },
      {
        name: "Trincomalee",
        code: "53",
      },
      {
        name: "VavunLya",
        code: "44",
      },
    ],
  },
  {
    name: "Sudan",
    "alpha-2": "SD",
    "alpha-3": "SDN",
    "country-code": "729",
    "iso_3166-2": "ISO 3166-2:SD",
    region: "Africa",
    "sub-region": "Northern Africa",
    "region-code": "002",
    "sub-region-code": "015",
    states: [
      {
        name: "A'ali an Nil",
        code: "23",
      },
      {
        name: "Al Bah al Ahmar",
        code: "26",
      },
      {
        name: "Al Buhayrat",
        code: "18",
      },
      {
        name: "Al Jazirah",
        code: "07",
      },
      {
        name: "Al Khartum",
        code: "03",
      },
      {
        name: "Al Qadarif",
        code: "06",
      },
      {
        name: "Al Wahdah",
        code: "22",
      },
      {
        name: "An Nil",
        code: "04",
      },
      {
        name: "An Nil al Abyaq",
        code: "08",
      },
      {
        name: "An Nil al Azraq",
        code: "24",
      },
      {
        name: "Ash Shamallyah",
        code: "01",
      },
      {
        name: "Bahr al Jabal",
        code: "17",
      },
      {
        name: "Gharb al Istiwa'iyah",
        code: "16",
      },
      {
        name: "Gharb Ba~r al Ghazal",
        code: "14",
      },
      {
        name: "Gharb Darfur",
        code: "12",
      },
      {
        name: "Gharb Kurdufan",
        code: "10",
      },
      {
        name: "Janub Darfur",
        code: "11",
      },
      {
        name: "Janub Rurdufan",
        code: "13",
      },
      {
        name: "Jnqall",
        code: "20",
      },
      {
        name: "Kassala",
        code: "05",
      },
      {
        name: "Shamal Batr al Ghazal",
        code: "15",
      },
      {
        name: "Shamal Darfur",
        code: "02",
      },
      {
        name: "Shamal Kurdufan",
        code: "09",
      },
      {
        name: "Sharq al Istiwa'iyah",
        code: "19",
      },
      {
        name: "Sinnar",
        code: "25",
      },
      {
        name: "Warab",
        code: "21",
      },
    ],
  },
  {
    name: "Suriname",
    "alpha-2": "SR",
    "alpha-3": "SUR",
    "country-code": "740",
    "iso_3166-2": "ISO 3166-2:SR",
    region: "Americas",
    "sub-region": "South America",
    "region-code": "019",
    "sub-region-code": "005",
    states: [
      {
        name: "Brokopondo",
        code: "BR",
      },
      {
        name: "Commewijne",
        code: "CM",
      },
      {
        name: "Coronie",
        code: "CR",
      },
      {
        name: "Marowijne",
        code: "MA",
      },
      {
        name: "Nickerie",
        code: "NI",
      },
      {
        name: "Paramaribo",
        code: "PM",
      },
      {
        name: "Saramacca",
        code: "SA",
      },
      {
        name: "Sipaliwini",
        code: "SI",
      },
      {
        name: "Wanica",
        code: "WA",
      },
    ],
  },
  {
    name: "Svalbard and Jan Mayen",
    "alpha-2": "SJ",
    "alpha-3": "SJM",
    "country-code": "744",
    "iso_3166-2": "ISO 3166-2:SJ",
    region: "Europe",
    "sub-region": "Northern Europe",
    "region-code": "150",
    "sub-region-code": "154",
    states: [],
  },
  {
    name: "Swaziland",
    "alpha-2": "SZ",
    "alpha-3": "SWZ",
    "country-code": "748",
    "iso_3166-2": "ISO 3166-2:SZ",
    region: "Africa",
    "sub-region": "Southern Africa",
    "region-code": "002",
    "sub-region-code": "018",
    states: [
      {
        name: "Hhohho",
        code: "HH",
      },
      {
        name: "Lubombo",
        code: "LU",
      },
      {
        name: "Manzini",
        code: "MA",
      },
      {
        name: "Shiselweni",
        code: "SH",
      },
    ],
  },
  {
    name: "Sweden",
    "alpha-2": "SE",
    "alpha-3": "SWE",
    "country-code": "752",
    "iso_3166-2": "ISO 3166-2:SE",
    region: "Europe",
    "sub-region": "Northern Europe",
    "region-code": "150",
    "sub-region-code": "154",
    states: [
      {
        name: "Blekinge lan",
        code: "K",
      },
      {
        name: "Dalarnas lan",
        code: "W",
      },
      {
        name: "Gotlands lan",
        code: "I",
      },
      {
        name: "Gavleborge lan",
        code: "X",
      },
      {
        name: "Hallands lan",
        code: "N",
      },
      {
        name: "Jamtlande lan",
        code: "Z",
      },
      {
        name: "Jonkopings lan",
        code: "F",
      },
      {
        name: "Kalmar lan",
        code: "H",
      },
      {
        name: "Kronoberge lan",
        code: "G",
      },
      {
        name: "Norrbottena lan",
        code: "BD",
      },
      {
        name: "Skane lan",
        code: "M",
      },
      {
        name: "Stockholms lan",
        code: "AB",
      },
      {
        name: "Sodermanlands lan",
        code: "D",
      },
      {
        name: "Uppsala lan",
        code: "C",
      },
      {
        name: "Varmlanda lan",
        code: "S",
      },
      {
        name: "Vasterbottens lan",
        code: "AC",
      },
      {
        name: "Vasternorrlands lan",
        code: "Y",
      },
      {
        name: "Vastmanlanda lan",
        code: "U",
      },
      {
        name: "Vastra Gotalands lan",
        code: "Q",
      },
      {
        name: "Orebro lan",
        code: "T",
      },
      {
        name: "Ostergotlands lan",
        code: "E",
      },
    ],
  },
  {
    name: "Switzerland",
    "alpha-2": "CH",
    "alpha-3": "CHE",
    "country-code": "756",
    "iso_3166-2": "ISO 3166-2:CH",
    region: "Europe",
    "sub-region": "Western Europe",
    "region-code": "150",
    "sub-region-code": "155",
    states: [
      {
        name: "Aargau",
        code: "AG",
      },
      {
        name: "Appenzell Innerrhoden",
        code: "AI",
      },
      {
        name: "Appenzell Ausserrhoden",
        code: "AR",
      },
      {
        name: "Bern",
        code: "BE",
      },
      {
        name: "Basel-Landschaft",
        code: "BL",
      },
      {
        name: "Basel-Stadt",
        code: "BS",
      },
      {
        name: "Fribourg",
        code: "FR",
      },
      {
        name: "Geneva",
        code: "GE",
      },
      {
        name: "Glarus",
        code: "GL",
      },
      {
        name: "Graubunden",
        code: "GR",
      },
      {
        name: "Jura",
        code: "JU",
      },
      {
        name: "Luzern",
        code: "LU",
      },
      {
        name: "Neuchatel",
        code: "NE",
      },
      {
        name: "Nidwalden",
        code: "NW",
      },
      {
        name: "Obwalden",
        code: "OW",
      },
      {
        name: "Sankt Gallen",
        code: "SG",
      },
      {
        name: "Schaffhausen",
        code: "SH",
      },
      {
        name: "Solothurn",
        code: "SO",
      },
      {
        name: "Schwyz",
        code: "SZ",
      },
      {
        name: "Thurgau",
        code: "TG",
      },
      {
        name: "Ticino",
        code: "TI",
      },
      {
        name: "Uri",
        code: "UR",
      },
      {
        name: "Vaud",
        code: "VD",
      },
      {
        name: "Valais",
        code: "VS",
      },
      {
        name: "Zug",
        code: "ZG",
      },
      {
        name: "Zurich",
        code: "ZH",
      },
    ],
  },
  {
    name: "Syrian Arab Republic",
    "alpha-2": "SY",
    "alpha-3": "SYR",
    "country-code": "760",
    "iso_3166-2": "ISO 3166-2:SY",
    region: "Asia",
    "sub-region": "Western Asia",
    "region-code": "142",
    "sub-region-code": "145",
    states: [
      {
        name: "Al Hasakah",
        code: "HA",
      },
      {
        name: "Al Ladhiqiyah",
        code: "LA",
      },
      {
        name: "Al Qunaytirah",
        code: "QU",
      },
      {
        name: "Ar Raqqah",
        code: "RA",
      },
      {
        name: "As Suwayda'",
        code: "SU",
      },
      {
        name: "Dar'a",
        code: "DR",
      },
      {
        name: "Dayr az Zawr",
        code: "DY",
      },
      {
        name: "Dimashq",
        code: "DI",
      },
      {
        name: "Halab",
        code: "HL",
      },
      {
        name: "Hamah",
        code: "HM",
      },
      {
        name: "Jim'",
        code: "HI",
      },
      {
        name: "Idlib",
        code: "ID",
      },
      {
        name: "Rif Dimashq",
        code: "RD",
      },
      {
        name: "Tarts",
        code: "TA",
      },
    ],
  },
  {
    name: "Sao Tome and Principe",
    "alpha-2": "ST",
    "alpha-3": "STP",
    "country-code": "678",
    "iso_3166-2": "ISO 3166-2:ST",
    region: "Africa",
    "sub-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "017",
    states: [
      {
        name: "Principe",
        code: "P",
      },
      {
        name: "Sao Tome",
        code: "S",
      },
    ],
  },
  {
    name: "Taiwan, Province of China",
    "alpha-2": "TW",
    "alpha-3": "TWN",
    "country-code": "158",
    "iso_3166-2": "ISO 3166-2:TW",
    region: "Asia",
    "sub-region": "Eastern Asia",
    "region-code": "142",
    "sub-region-code": "030",
    states: [
      {
        name: "Changhua",
        code: "CHA",
      },
      {
        name: "Chiayi",
        code: "CYQ",
      },
      {
        name: "Hsinchu",
        code: "HSQ",
      },
      {
        name: "Hualien",
        code: "HUA",
      },
      {
        name: "Ilan",
        code: "ILA",
      },
      {
        name: "Kaohsiung",
        code: "KHQ",
      },
      {
        name: "Miaoli",
        code: "MIA",
      },
      {
        name: "Nantou",
        code: "NAN",
      },
      {
        name: "Penghu",
        code: "PEN",
      },
      {
        name: "Pingtung",
        code: "PIF",
      },
      {
        name: "Taichung",
        code: "TXQ",
      },
      {
        name: "Tainan",
        code: "TNQ",
      },
      {
        name: "Taipei",
        code: "TPQ",
      },
      {
        name: "Taitung",
        code: "TTT",
      },
      {
        name: "Taoyuan",
        code: "TAO",
      },
      {
        name: "Yunlin",
        code: "YUN",
      },
      {
        name: "Keelung",
        code: "KEE",
      },
    ],
  },
  {
    name: "Tajikistan",
    "alpha-2": "TJ",
    "alpha-3": "TJK",
    "country-code": "762",
    "iso_3166-2": "ISO 3166-2:TJ",
    region: "Asia",
    "sub-region": "Central Asia",
    "region-code": "142",
    "sub-region-code": "143",
    states: [
      {
        name: "Sughd",
        code: "SU",
      },
      {
        name: "Khatlon",
        code: "KT",
      },
      {
        name: "Gorno-Badakhshan",
        code: "GB",
      },
    ],
  },
  {
    name: "Tanzania, United Republic of",
    "alpha-2": "TZ",
    "alpha-3": "TZA",
    "country-code": "834",
    "iso_3166-2": "ISO 3166-2:TZ",
    region: "Africa",
    "sub-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "014",
    states: [
      {
        name: "Arusha",
        code: "01",
      },
      {
        name: "Dar-es-Salaam",
        code: "02",
      },
      {
        name: "Dodoma",
        code: "03",
      },
      {
        name: "Iringa",
        code: "04",
      },
      {
        name: "Kagera",
        code: "05",
      },
      {
        name: "Kaskazini Pemba",
        code: "06",
      },
      {
        name: "Kaskazini Unguja",
        code: "07",
      },
      {
        name: "Xigoma",
        code: "08",
      },
      {
        name: "Kilimanjaro",
        code: "09",
      },
      {
        name: "Rusini Pemba",
        code: "10",
      },
      {
        name: "Kusini Unguja",
        code: "11",
      },
      {
        name: "Lindi",
        code: "12",
      },
      {
        name: "Manyara",
        code: "26",
      },
      {
        name: "Mara",
        code: "13",
      },
      {
        name: "Mbeya",
        code: "14",
      },
      {
        name: "Mjini Magharibi",
        code: "15",
      },
      {
        name: "Morogoro",
        code: "16",
      },
      {
        name: "Mtwara",
        code: "17",
      },
      {
        name: "Pwani",
        code: "19",
      },
      {
        name: "Rukwa",
        code: "20",
      },
      {
        name: "Ruvuma",
        code: "21",
      },
      {
        name: "Shinyanga",
        code: "22",
      },
      {
        name: "Singida",
        code: "23",
      },
      {
        name: "Tabora",
        code: "24",
      },
      {
        name: "Tanga",
        code: "25",
      },
    ],
  },
  {
    name: "Thailand",
    "alpha-2": "TH",
    "alpha-3": "THA",
    "country-code": "764",
    "iso_3166-2": "ISO 3166-2:TH",
    region: "Asia",
    "sub-region": "South-Eastern Asia",
    "region-code": "142",
    "sub-region-code": "035",
    states: [
      {
        name: "Krung Thep Maha Nakhon Bangkok",
        code: "10",
      },
      {
        name: "Phatthaya",
        code: "S",
      },
      {
        name: "Amnat Charoen",
        code: "37",
      },
      {
        name: "Ang Thong",
        code: "15",
      },
      {
        name: "Buri Ram",
        code: "31",
      },
      {
        name: "Chachoengsao",
        code: "24",
      },
      {
        name: "Chai Nat",
        code: "18",
      },
      {
        name: "Chaiyaphum",
        code: "36",
      },
      {
        name: "Chanthaburi",
        code: "22",
      },
      {
        name: "Chiang Mai",
        code: "50",
      },
      {
        name: "Chiang Rai",
        code: "57",
      },
      {
        name: "Chon Buri",
        code: "20",
      },
      {
        name: "Chumphon",
        code: "86",
      },
      {
        name: "Kalasin",
        code: "46",
      },
      {
        name: "Kamphasng Phet",
        code: "62",
      },
      {
        name: "Kanchanaburi",
        code: "71",
      },
      {
        name: "Khon Kaen",
        code: "40",
      },
      {
        name: "Krabi",
        code: "81",
      },
      {
        name: "Lampang",
        code: "52",
      },
      {
        name: "Lamphun",
        code: "51",
      },
      {
        name: "Loei",
        code: "42",
      },
      {
        name: "Lop Buri",
        code: "16",
      },
      {
        name: "Mae Hong Son",
        code: "58",
      },
      {
        name: "Maha Sarakham",
        code: "44",
      },
      {
        name: "Mukdahan",
        code: "49",
      },
      {
        name: "Nakhon Nayok",
        code: "26",
      },
      {
        name: "Nakhon Pathom",
        code: "73",
      },
      {
        name: "Nakhon Phanom",
        code: "48",
      },
      {
        name: "Nakhon Ratchasima",
        code: "30",
      },
      {
        name: "Nakhon Sawan",
        code: "60",
      },
      {
        name: "Nakhon Si Thammarat",
        code: "80",
      },
      {
        name: "Nan",
        code: "55",
      },
      {
        name: "Narathiwat",
        code: "96",
      },
      {
        name: "Nong Bua Lam Phu",
        code: "39",
      },
      {
        name: "Nong Khai",
        code: "43",
      },
      {
        name: "Nonthaburi",
        code: "12",
      },
      {
        name: "Pathum Thani",
        code: "13",
      },
      {
        name: "Pattani",
        code: "94",
      },
      {
        name: "Phangnga",
        code: "82",
      },
      {
        name: "Phatthalung",
        code: "93",
      },
      {
        name: "Phayao",
        code: "56",
      },
      {
        name: "Phetchabun",
        code: "67",
      },
      {
        name: "Phetchaburi",
        code: "76",
      },
      {
        name: "Phichit",
        code: "66",
      },
      {
        name: "Phitsanulok",
        code: "65",
      },
      {
        name: "Phrae",
        code: "54",
      },
      {
        name: "Phra Nakhon Si Ayutthaya",
        code: "14",
      },
      {
        name: "Phaket",
        code: "83",
      },
      {
        name: "Prachin Buri",
        code: "25",
      },
      {
        name: "Prachuap Khiri Khan",
        code: "77",
      },
      {
        name: "Ranong",
        code: "85",
      },
      {
        name: "Ratchaburi",
        code: "70",
      },
      {
        name: "Rayong",
        code: "21",
      },
      {
        name: "Roi Et",
        code: "45",
      },
      {
        name: "Sa Kaeo",
        code: "27",
      },
      {
        name: "Sakon Nakhon",
        code: "47",
      },
      {
        name: "Samut Prakan",
        code: "11",
      },
      {
        name: "Samut Sakhon",
        code: "74",
      },
      {
        name: "Samut Songkhram",
        code: "75",
      },
      {
        name: "Saraburi",
        code: "19",
      },
      {
        name: "Satun",
        code: "91",
      },
      {
        name: "Sing Buri",
        code: "17",
      },
      {
        name: "Si Sa Ket",
        code: "33",
      },
      {
        name: "Songkhla",
        code: "90",
      },
      {
        name: "Sukhothai",
        code: "64",
      },
      {
        name: "Suphan Buri",
        code: "72",
      },
      {
        name: "Surat Thani",
        code: "84",
      },
      {
        name: "Surin",
        code: "32",
      },
      {
        name: "Tak",
        code: "63",
      },
      {
        name: "Trang",
        code: "92",
      },
      {
        name: "Trat",
        code: "23",
      },
      {
        name: "Ubon Ratchathani",
        code: "34",
      },
      {
        name: "Udon Thani",
        code: "41",
      },
      {
        name: "Uthai Thani",
        code: "61",
      },
      {
        name: "Uttaradit",
        code: "53",
      },
      {
        name: "Yala",
        code: "95",
      },
      {
        name: "Yasothon",
        code: "35",
      },
    ],
  },
  {
    name: "Bahamas",
    "alpha-2": "BS",
    "alpha-3": "BHS",
    "country-code": "044",
    "iso_3166-2": "ISO 3166-2:BS",
    region: "Americas",
    "sub-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "029",
    states: [
      {
        name: "Acklins and Crooked Islands",
        code: "AC",
      },
      {
        name: "Bimini",
        code: "BI",
      },
      {
        name: "Cat Island",
        code: "CI",
      },
      {
        name: "Exuma",
        code: "EX",
      },
      {
        name: "Freeport",
        code: "FP",
      },
      {
        name: "Fresh Creek",
        code: "FC",
      },
      {
        name: "Governor's Harbour",
        code: "GH",
      },
      {
        name: "Green Turtle Cay",
        code: "GT",
      },
      {
        name: "Harbour Island",
        code: "HI",
      },
      {
        name: "High Rock",
        code: "HR",
      },
      {
        name: "Inagua",
        code: "IN",
      },
      {
        name: "Kemps Bay",
        code: "KB",
      },
      {
        name: "Long Island",
        code: "LI",
      },
      {
        name: "Marsh Harbour",
        code: "MH",
      },
      {
        name: "Mayaguana",
        code: "MG",
      },
      {
        name: "New Providence",
        code: "NP",
      },
      {
        name: "Nicholls Town and Berry Islands",
        code: "NB",
      },
      {
        name: "Ragged Island",
        code: "RI",
      },
      {
        name: "Rock Sound",
        code: "RS",
      },
      {
        name: "Sandy Point",
        code: "SP",
      },
      {
        name: "San Salvador and Rum Cay",
        code: "SR",
      },
    ],
  },
  {
    name: "Gambia",
    "alpha-2": "GM",
    "alpha-3": "GMB",
    "country-code": "270",
    "iso_3166-2": "ISO 3166-2:GM",
    region: "Africa",
    "sub-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "011",
    states: [
      {
        name: "Banjul",
        code: "B",
      },
      {
        name: "Lower River",
        code: "L",
      },
      {
        name: "MacCarthy Island",
        code: "M",
      },
      {
        name: "North Bank",
        code: "N",
      },
      {
        name: "Upper River",
        code: "U",
      },
    ],
  },
  {
    name: "Togo",
    "alpha-2": "TG",
    "alpha-3": "TGO",
    "country-code": "768",
    "iso_3166-2": "ISO 3166-2:TG",
    region: "Africa",
    "sub-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "011",
    states: [
      {
        name: "Kara",
        code: "K",
      },
      {
        name: "Maritime (Region)",
        code: "M",
      },
      {
        name: "Savannes",
        code: "S",
      },
    ],
  },
  {
    name: "Tokelau",
    "alpha-2": "TK",
    "alpha-3": "TKL",
    "country-code": "772",
    "iso_3166-2": "ISO 3166-2:TK",
    region: "Oceania",
    "sub-region": "Polynesia",
    "region-code": "009",
    "sub-region-code": "061",
    states: [],
  },
  {
    name: "Tonga",
    "alpha-2": "TO",
    "alpha-3": "TON",
    "country-code": "776",
    "iso_3166-2": "ISO 3166-2:TO",
    region: "Oceania",
    "sub-region": "Polynesia",
    "region-code": "009",
    "sub-region-code": "061",
    states: [],
  },
  {
    name: "Trinidad and Tobago",
    "alpha-2": "TT",
    "alpha-3": "TTO",
    "country-code": "780",
    "iso_3166-2": "ISO 3166-2:TT",
    region: "Americas",
    "sub-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "029",
    states: [
      {
        name: "Couva-Tabaquite-Talparo",
        code: "CTT",
      },
      {
        name: "Diego Martin",
        code: "DMN",
      },
      {
        name: "Eastern Tobago",
        code: "ETO",
      },
      {
        name: "Penal-Debe",
        code: "PED",
      },
      {
        name: "Princes Town",
        code: "PRT",
      },
      {
        name: "Rio Claro-Mayaro",
        code: "RCM",
      },
      {
        name: "Sangre Grande",
        code: "SGE",
      },
      {
        name: "San Juan-Laventille",
        code: "SJL",
      },
      {
        name: "Siparia",
        code: "SIP",
      },
      {
        name: "Tunapuna-Piarco",
        code: "TUP",
      },
      {
        name: "Western Tobago",
        code: "WTO",
      },
      {
        name: "Arima",
        code: "ARI",
      },
      {
        name: "Chaguanas",
        code: "CHA",
      },
      {
        name: "Point Fortin",
        code: "PTF",
      },
      {
        name: "Port of Spain",
        code: "POS",
      },
      {
        name: "San Fernando",
        code: "SFO",
      },
    ],
  },
  {
    name: "Tunisia",
    "alpha-2": "TN",
    "alpha-3": "TUN",
    "country-code": "788",
    "iso_3166-2": "ISO 3166-2:TN",
    region: "Africa",
    "sub-region": "Northern Africa",
    "region-code": "002",
    "sub-region-code": "015",
    states: [
      {
        name: "Béja",
        code: "31",
      },
      {
        name: "Ben Arous",
        code: "13",
      },
      {
        name: "Bizerte",
        code: "23",
      },
      {
        name: "Gabès",
        code: "81",
      },
      {
        name: "Gafsa",
        code: "71",
      },
      {
        name: "Jendouba",
        code: "32",
      },
      {
        name: "Kairouan",
        code: "41",
      },
      {
        name: "Rasserine",
        code: "42",
      },
      {
        name: "Kebili",
        code: "73",
      },
      {
        name: "L'Ariana",
        code: "12",
      },
      {
        name: "Le Ref",
        code: "33",
      },
      {
        name: "Mahdia",
        code: "53",
      },
      {
        name: "La Manouba",
        code: "14",
      },
      {
        name: "Medenine",
        code: "82",
      },
      {
        name: "Moneatir",
        code: "52",
      },
      {
        name: "Naboul",
        code: "21",
      },
      {
        name: "Sfax",
        code: "61",
      },
      {
        name: "Sidi Bouxid",
        code: "43",
      },
      {
        name: "Siliana",
        code: "34",
      },
      {
        name: "Sousse",
        code: "51",
      },
      {
        name: "Tataouine",
        code: "83",
      },
      {
        name: "Tozeur",
        code: "72",
      },
      {
        name: "Tunis",
        code: "11",
      },
      {
        name: "Zaghouan",
        code: "22",
      },
    ],
  },
  {
    name: "Turkey",
    "alpha-2": "TR",
    "alpha-3": "TUR",
    "country-code": "792",
    "iso_3166-2": "ISO 3166-2:TR",
    region: "Asia",
    "sub-region": "Western Asia",
    "region-code": "142",
    "sub-region-code": "145",
    states: [
      {
        name: "Adana",
        code: "01",
      },
      {
        name: "Ad yaman",
        code: "02",
      },
      {
        name: "Afyon",
        code: "03",
      },
      {
        name: "Ag r",
        code: "04",
      },
      {
        name: "Aksaray",
        code: "68",
      },
      {
        name: "Amasya",
        code: "05",
      },
      {
        name: "Ankara",
        code: "06",
      },
      {
        name: "Antalya",
        code: "07",
      },
      {
        name: "Ardahan",
        code: "75",
      },
      {
        name: "Artvin",
        code: "08",
      },
      {
        name: "Aydin",
        code: "09",
      },
      {
        name: "Bal kesir",
        code: "10",
      },
      {
        name: "Bartin",
        code: "74",
      },
      {
        name: "Batman",
        code: "72",
      },
      {
        name: "Bayburt",
        code: "69",
      },
      {
        name: "Bilecik",
        code: "11",
      },
      {
        name: "Bingol",
        code: "12",
      },
      {
        name: "Bitlis",
        code: "13",
      },
      {
        name: "Bolu",
        code: "14",
      },
      {
        name: "Burdur",
        code: "15",
      },
      {
        name: "Bursa",
        code: "16",
      },
      {
        name: "Canakkale",
        code: "17",
      },
      {
        name: "Cankir",
        code: "18",
      },
      {
        name: "Corum",
        code: "19",
      },
      {
        name: "Denizli",
        code: "20",
      },
      {
        name: "Diyarbakir",
        code: "21",
      },
      {
        name: "Duzce",
        code: "81",
      },
      {
        name: "Edirne",
        code: "22",
      },
      {
        name: "Elazig",
        code: "23",
      },
      {
        name: "Erzincan",
        code: "24",
      },
      {
        name: "Erzurum",
        code: "25",
      },
      {
        name: "Eskis'ehir",
        code: "26",
      },
      {
        name: "Gaziantep",
        code: "27",
      },
      {
        name: "Giresun",
        code: "28",
      },
      {
        name: "Gms'hane",
        code: "29",
      },
      {
        name: "Hakkari",
        code: "30",
      },
      {
        name: "Hatay",
        code: "31",
      },
      {
        name: "Igidir",
        code: "76",
      },
      {
        name: "Isparta",
        code: "32",
      },
      {
        name: "Icel",
        code: "33",
      },
      {
        name: "Istanbul",
        code: "34",
      },
      {
        name: "Izmir",
        code: "35",
      },
      {
        name: "Kahramanmaras",
        code: "46",
      },
      {
        name: "Karabk",
        code: "78",
      },
      {
        name: "Karaman",
        code: "70",
      },
      {
        name: "Kars",
        code: "36",
      },
      {
        name: "Kastamonu",
        code: "37",
      },
      {
        name: "Kayseri",
        code: "38",
      },
      {
        name: "Kirikkale",
        code: "71",
      },
      {
        name: "Kirklareli",
        code: "39",
      },
      {
        name: "Kirs'ehir",
        code: "40",
      },
      {
        name: "Kilis",
        code: "79",
      },
      {
        name: "Kocaeli",
        code: "41",
      },
      {
        name: "Konya",
        code: "42",
      },
      {
        name: "Ktahya",
        code: "43",
      },
      {
        name: "Malatya",
        code: "44",
      },
      {
        name: "Manisa",
        code: "45",
      },
      {
        name: "Mardin",
        code: "47",
      },
      {
        name: "Mugila",
        code: "48",
      },
      {
        name: "Mus",
        code: "49",
      },
      {
        name: "Nevs'ehir",
        code: "50",
      },
      {
        name: "Nigide",
        code: "51",
      },
      {
        name: "Ordu",
        code: "52",
      },
      {
        name: "Osmaniye",
        code: "80",
      },
      {
        name: "Rize",
        code: "53",
      },
      {
        name: "Sakarya",
        code: "54",
      },
      {
        name: "Samsun",
        code: "55",
      },
      {
        name: "Siirt",
        code: "56",
      },
      {
        name: "Sinop",
        code: "57",
      },
      {
        name: "Sivas",
        code: "58",
      },
      {
        name: "S'anliurfa",
        code: "63",
      },
      {
        name: "S'rnak",
        code: "73",
      },
      {
        name: "Tekirdag",
        code: "59",
      },
      {
        name: "Tokat",
        code: "60",
      },
      {
        name: "Trabzon",
        code: "61",
      },
      {
        name: "Tunceli",
        code: "62",
      },
      {
        name: "Us'ak",
        code: "64",
      },
      {
        name: "Van",
        code: "65",
      },
      {
        name: "Yalova",
        code: "77",
      },
      {
        name: "Yozgat",
        code: "66",
      },
      {
        name: "Zonguldak",
        code: "67",
      },
    ],
  },
  {
    name: "Turkmenistan",
    "alpha-2": "TM",
    "alpha-3": "TKM",
    "country-code": "795",
    "iso_3166-2": "ISO 3166-2:TM",
    region: "Asia",
    "sub-region": "Central Asia",
    "region-code": "142",
    "sub-region-code": "143",
    states: [
      {
        name: "Ahal",
        code: "A",
      },
      {
        name: "Balkan",
        code: "B",
      },
      {
        name: "Dasoguz",
        code: "D",
      },
      {
        name: "Lebap",
        code: "L",
      },
      {
        name: "Mary",
        code: "M",
      },
    ],
  },
  {
    name: "Turks and Caicos Islands",
    "alpha-2": "TC",
    "alpha-3": "TCA",
    "country-code": "796",
    "iso_3166-2": "ISO 3166-2:TC",
    region: "Americas",
    "sub-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "029",
    states: [],
  },
  {
    name: "Tuvalu",
    "alpha-2": "TV",
    "alpha-3": "TUV",
    "country-code": "798",
    "iso_3166-2": "ISO 3166-2:TV",
    region: "Oceania",
    "sub-region": "Polynesia",
    "region-code": "009",
    "sub-region-code": "061",
    states: [],
  },
  {
    name: "Uganda",
    "alpha-2": "UG",
    "alpha-3": "UGA",
    "country-code": "800",
    "iso_3166-2": "ISO 3166-2:UG",
    region: "Africa",
    "sub-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "014",
    states: [
      {
        name: "Adjumani",
        code: "301",
      },
      {
        name: "Apac",
        code: "302",
      },
      {
        name: "Arua",
        code: "303",
      },
      {
        name: "Bugiri",
        code: "201",
      },
      {
        name: "Bundibugyo",
        code: "401",
      },
      {
        name: "Bushenyi",
        code: "402",
      },
      {
        name: "Busia",
        code: "202",
      },
      {
        name: "Gulu",
        code: "304",
      },
      {
        name: "Hoima",
        code: "403",
      },
      {
        name: "Iganga",
        code: "203",
      },
      {
        name: "Jinja",
        code: "204",
      },
      {
        name: "Kabale",
        code: "404",
      },
      {
        name: "Kabarole",
        code: "405",
      },
      {
        name: "Kaberamaido",
        code: "213",
      },
      {
        name: "Kalangala",
        code: "101",
      },
      {
        name: "Kampala",
        code: "102",
      },
      {
        name: "Kamuli",
        code: "205",
      },
      {
        name: "Kamwenge",
        code: "413",
      },
      {
        name: "Kanungu",
        code: "414",
      },
      {
        name: "Kapchorwa",
        code: "206",
      },
      {
        name: "Kasese",
        code: "406",
      },
      {
        name: "Katakwi",
        code: "207",
      },
      {
        name: "Kayunga",
        code: "112",
      },
      {
        name: "Kibaale",
        code: "407",
      },
      {
        name: "Kiboga",
        code: "103",
      },
      {
        name: "Kisoro",
        code: "408",
      },
      {
        name: "Kitgum",
        code: "305",
      },
      {
        name: "Kotido",
        code: "306",
      },
      {
        name: "Kumi",
        code: "208",
      },
      {
        name: "Kyenjojo",
        code: "415",
      },
      {
        name: "Lira",
        code: "307",
      },
      {
        name: "Luwero",
        code: "104",
      },
      {
        name: "Masaka",
        code: "105",
      },
      {
        name: "Masindi",
        code: "409",
      },
      {
        name: "Mayuge",
        code: "214",
      },
      {
        name: "Mbale",
        code: "209",
      },
      {
        name: "Mbarara",
        code: "410",
      },
      {
        name: "Moroto",
        code: "308",
      },
      {
        name: "Moyo",
        code: "309",
      },
      {
        name: "Mpigi",
        code: "106",
      },
      {
        name: "Mubende",
        code: "107",
      },
      {
        name: "Mukono",
        code: "108",
      },
      {
        name: "Nakapiripirit",
        code: "311",
      },
      {
        name: "Nakasongola",
        code: "109",
      },
      {
        name: "Nebbi",
        code: "310",
      },
      {
        name: "Ntungamo",
        code: "411",
      },
      {
        name: "Pader",
        code: "312",
      },
      {
        name: "Pallisa",
        code: "210",
      },
      {
        name: "Rakai",
        code: "110",
      },
      {
        name: "Rukungiri",
        code: "412",
      },
      {
        name: "Sembabule",
        code: "111",
      },
      {
        name: "Sironko",
        code: "215",
      },
      {
        name: "Soroti",
        code: "211",
      },
      {
        name: "Tororo",
        code: "212",
      },
      {
        name: "Wakiso",
        code: "113",
      },
      {
        name: "Yumbe",
        code: "313",
      },
    ],
  },
  {
    name: "Ukraine",
    "alpha-2": "UA",
    "alpha-3": "UKR",
    "country-code": "804",
    "iso_3166-2": "ISO 3166-2:UA",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "region-code": "150",
    "sub-region-code": "151",
    states: [
      {
        name: "Cherkas'ka Oblast'",
        code: "71",
      },
      {
        name: "Chernihivs'ka Oblast'",
        code: "74",
      },
      {
        name: "Chernivets'ka Oblast'",
        code: "77",
      },
      {
        name: "Dnipropetrovs'ka Oblast'",
        code: "12",
      },
      {
        name: "Donets'ka Oblast'",
        code: "14",
      },
      {
        name: "Ivano-Frankivs'ka Oblast'",
        code: "26",
      },
      {
        name: "Kharkivs'ka Oblast'",
        code: "63",
      },
      {
        name: "Khersons'ka Oblast'",
        code: "65",
      },
      {
        name: "Khmel'nyts'ka Oblast'",
        code: "68",
      },
      {
        name: "Kirovohrads'ka Oblast'",
        code: "35",
      },
      {
        name: "Kyivs'ka Oblast'",
        code: "32",
      },
      {
        name: "Luhans'ka Oblast'",
        code: "09",
      },
      {
        name: "L'vivs'ka Oblast'",
        code: "46",
      },
      {
        name: "Mykolaivs'ka Oblast'",
        code: "48",
      },
      {
        name: "Odes 'ka Oblast'",
        code: "51",
      },
      {
        name: "Poltavs'ka Oblast'",
        code: "53",
      },
      {
        name: "Rivnens'ka Oblast'",
        code: "56",
      },
      {
        name: "Sums 'ka Oblast'",
        code: "59",
      },
      {
        name: "Ternopil's'ka Oblast'",
        code: "61",
      },
      {
        name: "Vinnyts'ka Oblast'",
        code: "05",
      },
      {
        name: "Volyos'ka Oblast'",
        code: "07",
      },
      {
        name: "Zakarpats'ka Oblast'",
        code: "21",
      },
      {
        name: "Zaporiz'ka Oblast'",
        code: "23",
      },
      {
        name: "Zhytomyrs'ka Oblast'",
        code: "18",
      },
      {
        name: "Respublika Krym",
        code: "43",
      },
      {
        name: "Kyiv",
        code: "30",
      },
      {
        name: "Sevastopol",
        code: "40",
      },
    ],
  },
  {
    name: "United Arab Emirates",
    "alpha-2": "AE",
    "alpha-3": "ARE",
    "country-code": "784",
    "iso_3166-2": "ISO 3166-2:AE",
    region: "Asia",
    "sub-region": "Western Asia",
    "region-code": "142",
    "sub-region-code": "145",
    states: [
      {
        name: "Abu Zaby",
        code: "AZ",
      },
      {
        name: "'Ajman",
        code: "AJ",
      },
      {
        name: "Al Fujayrah",
        code: "FU",
      },
      {
        name: "Ash Shariqah",
        code: "SH",
      },
      {
        name: "Dubayy",
        code: "DU",
      },
      {
        name: "Ra's al Khaymah",
        code: "RK",
      },
      {
        name: "Umm al Qaywayn",
        code: "UQ",
      },
    ],
  },
  {
    name: "United Kingdom",
    "alpha-2": "GB",
    "alpha-3": "GBR",
    "country-code": "826",
    "iso_3166-2": "ISO 3166-2:GB",
    region: "Europe",
    "sub-region": "Northern Europe",
    "region-code": "150",
    "sub-region-code": "154",
    states: [
      {
        name: "Aberdeen City",
        code: "ABE",
      },
      {
        name: "Aberdeenshire",
        code: "ABD",
      },
      {
        name: "Angus",
        code: "ANS",
      },
      {
        name: "Co Antrim",
        code: "ANT",
      },
      {
        name: "Argyll and Bute",
        code: "AGB",
      },
      {
        name: "Co Armagh",
        code: "ARM",
      },
      {
        name: "Bedfordshire",
        code: "BDF",
      },
      {
        name: "Gwent",
        code: "BGW",
      },
      {
        name: "Bristol, City of",
        code: "BST",
      },
      {
        name: "Buckinghamshire",
        code: "BKM",
      },
      {
        name: "Cambridgeshire",
        code: "CAM",
      },
      {
        name: "Cheshire",
        code: "CHS",
      },
      {
        name: "Clackmannanshire",
        code: "CLK",
      },
      {
        name: "Cornwall",
        code: "CON",
      },
      {
        name: "Cumbria",
        code: "CMA",
      },
      {
        name: "Derbyshire",
        code: "DBY",
      },
      {
        name: "Co Londonderry",
        code: "DRY",
      },
      {
        name: "Devon",
        code: "DEV",
      },
      {
        name: "Dorset",
        code: "DOR",
      },
      {
        name: "Co Down",
        code: "DOW",
      },
      {
        name: "Dumfries and Galloway",
        code: "DGY",
      },
      {
        name: "Dundee City",
        code: "DND",
      },
      {
        name: "County Durham",
        code: "DUR",
      },
      {
        name: "East Ayrshire",
        code: "EAY",
      },
      {
        name: "East Dunbartonshire",
        code: "EDU",
      },
      {
        name: "East Lothian",
        code: "ELN",
      },
      {
        name: "East Renfrewshire",
        code: "ERW",
      },
      {
        name: "East Riding of Yorkshire",
        code: "ERY",
      },
      {
        name: "East Sussex",
        code: "ESX",
      },
      {
        name: "Edinburgh, City of",
        code: "EDH",
      },
      {
        name: "Na h-Eileanan Siar",
        code: "ELS",
      },
      {
        name: "Essex",
        code: "ESS",
      },
      {
        name: "Falkirk",
        code: "FAL",
      },
      {
        name: "Co Fermanagh",
        code: "FER",
      },
      {
        name: "Fife",
        code: "FIF",
      },
      {
        name: "Glasgow City",
        code: "GLG",
      },
      {
        name: "Gloucestershire",
        code: "GLS",
      },
      {
        name: "Gwynedd",
        code: "GWN",
      },
      {
        name: "Hampshire",
        code: "HAM",
      },
      {
        name: "Herefordshire",
        code: "HEF",
      },
      {
        name: "Hertfordshire",
        code: "HRT",
      },
      {
        name: "Highland",
        code: "HED",
      },
      {
        name: "Inverclyde",
        code: "IVC",
      },
      {
        name: "Isle of Wight",
        code: "IOW",
      },
      {
        name: "Kent",
        code: "KEN",
      },
      {
        name: "Lancashire",
        code: "LAN",
      },
      {
        name: "Leicestershire",
        code: "LEC",
      },
      {
        name: "Midlothian",
        code: "MLN",
      },
      {
        name: "Moray",
        code: "MRY",
      },
      {
        name: "Norfolk",
        code: "NFK",
      },
      {
        name: "North Ayrshire",
        code: "NAY",
      },
      {
        name: "North Lanarkshire",
        code: "NLK",
      },
      {
        name: "North Yorkshire",
        code: "NYK",
      },
      {
        name: "Northamptonshire",
        code: "NTH",
      },
      {
        name: "Northumberland",
        code: "NBL",
      },
      {
        name: "Nottinghamshire",
        code: "NTT",
      },
      {
        name: "Oldham",
        code: "OLD",
      },
      {
        name: "Omagh",
        code: "OMH",
      },
      {
        name: "Orkney Islands",
        code: "ORR",
      },
      {
        name: "Oxfordshire",
        code: "OXF",
      },
      {
        name: "Perth and Kinross",
        code: "PKN",
      },
      {
        name: "Powys",
        code: "POW",
      },
      {
        name: "Renfrewshire",
        code: "RFW",
      },
      {
        name: "Rutland",
        code: "RUT",
      },
      {
        name: "Scottish Borders",
        code: "SCB",
      },
      {
        name: "Shetland Islands",
        code: "ZET",
      },
      {
        name: "Shropshire",
        code: "SHR",
      },
      {
        name: "Somerset",
        code: "SOM",
      },
      {
        name: "South Ayrshire",
        code: "SAY",
      },
      {
        name: "South Gloucestershire",
        code: "SGC",
      },
      {
        name: "South Lanarkshire",
        code: "SLK",
      },
      {
        name: "Staffordshire",
        code: "STS",
      },
      {
        name: "Stirling",
        code: "STG",
      },
      {
        name: "Suffolk",
        code: "SFK",
      },
      {
        name: "Surrey",
        code: "SRY",
      },
      {
        name: "Mid Glamorgan",
        code: "VGL",
      },
      {
        name: "Warwickshire",
        code: "WAR",
      },
      {
        name: "West Dunbartonshire",
        code: "WDU",
      },
      {
        name: "West Lothian",
        code: "WLN",
      },
      {
        name: "West Sussex",
        code: "WSX",
      },
      {
        name: "Wiltshire",
        code: "WIL",
      },
      {
        name: "Worcestershire",
        code: "WOR",
      },
      {
        name: "Tyne and Wear",
        code: "TWR",
      },
      {
        name: "Greater Manchester",
        code: "GTM",
      },
      {
        name: "Co Tyrone",
        code: "TYR",
      },
      {
        name: "West Yorkshire",
        code: "WYK",
      },
      {
        name: "South Yorkshire",
        code: "SYK",
      },
      {
        name: "Merseyside",
        code: "MSY",
      },
      {
        name: "Berkshire",
        code: "BRK",
      },
      {
        name: "West Midlands",
        code: "WMD",
      },
      {
        name: "West Glamorgan",
        code: "WGM",
      },
      {
        name: "Greater London",
        code: "LON",
      },
      {
        name: "Clwyd",
        code: "CWD",
      },
      {
        name: "Dyfed",
        code: "DFD",
      },
      {
        name: "South Glamorgan",
        code: "SGM",
      },
    ],
  },
  {
    name: "United States Minor Outlying Islands",
    "alpha-2": "UM",
    "alpha-3": "UMI",
    "country-code": "581",
    "iso_3166-2": "ISO 3166-2:UM",
    "sub-region-code": null,
    "region-code": null,
    "sub-region": null,
    region: null,
    states: [
      {
        name: "Baker Island",
        code: "81",
      },
      {
        name: "Howland Island",
        code: "84",
      },
      {
        name: "Jarvis Island",
        code: "86",
      },
      {
        name: "Johnston Atoll",
        code: "67",
      },
      {
        name: "Kingman Reef",
        code: "89",
      },
      {
        name: "Midway Islands",
        code: "71",
      },
      {
        name: "Navassa Island",
        code: "76",
      },
      {
        name: "Palmyra Atoll",
        code: "95",
      },
      {
        name: "Wake Ialand",
        code: "79",
      },
    ],
  },
  {
    name: "Uruguay",
    "alpha-2": "UY",
    "alpha-3": "URY",
    "country-code": "858",
    "iso_3166-2": "ISO 3166-2:UY",
    region: "Americas",
    "sub-region": "South America",
    "region-code": "019",
    "sub-region-code": "005",
    states: [
      {
        name: "Artigsa",
        code: "AR",
      },
      {
        name: "Canelones",
        code: "CA",
      },
      {
        name: "Cerro Largo",
        code: "CL",
      },
      {
        name: "Colonia",
        code: "CO",
      },
      {
        name: "Durazno",
        code: "DU",
      },
      {
        name: "Flores",
        code: "FS",
      },
      {
        name: "Lavalleja",
        code: "LA",
      },
      {
        name: "Maldonado",
        code: "MA",
      },
      {
        name: "Montevideo",
        code: "MO",
      },
      {
        name: "Paysandu",
        code: "PA",
      },
      {
        name: "Rivera",
        code: "RV",
      },
      {
        name: "Rocha",
        code: "RO",
      },
      {
        name: "Salto",
        code: "SA",
      },
      {
        name: "Soriano",
        code: "SO",
      },
      {
        name: "Tacuarembo",
        code: "TA",
      },
      {
        name: "Treinta y Tres",
        code: "TT",
      },
    ],
  },
  {
    name: "Uzbekistan",
    "alpha-2": "UZ",
    "alpha-3": "UZB",
    "country-code": "860",
    "iso_3166-2": "ISO 3166-2:UZ",
    region: "Asia",
    "sub-region": "Central Asia",
    "region-code": "142",
    "sub-region-code": "143",
    states: [
      {
        name: "Toshkent (city)",
        code: "TK",
      },
      {
        name: "Qoraqalpogiston Respublikasi",
        code: "QR",
      },
      {
        name: "Andijon",
        code: "AN",
      },
      {
        name: "Buxoro",
        code: "BU",
      },
      {
        name: "Farg'ona",
        code: "FA",
      },
      {
        name: "Jizzax",
        code: "JI",
      },
      {
        name: "Khorazm",
        code: "KH",
      },
      {
        name: "Namangan",
        code: "NG",
      },
      {
        name: "Navoiy",
        code: "NW",
      },
      {
        name: "Qashqadaryo",
        code: "QA",
      },
      {
        name: "Samarqand",
        code: "SA",
      },
      {
        name: "Sirdaryo",
        code: "SI",
      },
      {
        name: "Surxondaryo",
        code: "SU",
      },
      {
        name: "Toshkent",
        code: "TO",
      },
      {
        name: "Xorazm",
        code: "XO",
      },
    ],
  },
  {
    name: "Vanuatu",
    "alpha-2": "VU",
    "alpha-3": "VUT",
    "country-code": "548",
    "iso_3166-2": "ISO 3166-2:VU",
    region: "Oceania",
    "sub-region": "Melanesia",
    "region-code": "009",
    "sub-region-code": "054",
    states: [
      {
        name: "Malampa",
        code: "MAP",
      },
      {
        name: "Penama",
        code: "PAM",
      },
      {
        name: "Sanma",
        code: "SAM",
      },
      {
        name: "Shefa",
        code: "SEE",
      },
      {
        name: "Tafea",
        code: "TAE",
      },
      {
        name: "Torba",
        code: "TOB",
      },
    ],
  },
  {
    name: "Venezuela (Bolivarian Republic of)",
    "alpha-2": "VE",
    "alpha-3": "VEN",
    "country-code": "862",
    "iso_3166-2": "ISO 3166-2:VE",
    region: "Americas",
    "sub-region": "South America",
    "region-code": "019",
    "sub-region-code": "005",
    states: [
      {
        name: "Diatrito Federal",
        code: "A",
      },
      {
        name: "Anzoategui",
        code: "B",
      },
      {
        name: "Apure",
        code: "C",
      },
      {
        name: "Aragua",
        code: "D",
      },
      {
        name: "Barinas",
        code: "E",
      },
      {
        name: "Carabobo",
        code: "G",
      },
      {
        name: "Cojedes",
        code: "H",
      },
      {
        name: "Falcon",
        code: "I",
      },
      {
        name: "Guarico",
        code: "J",
      },
      {
        name: "Lara",
        code: "K",
      },
      {
        name: "Merida",
        code: "L",
      },
      {
        name: "Miranda",
        code: "M",
      },
      {
        name: "Monagas",
        code: "N",
      },
      {
        name: "Nueva Esparta",
        code: "O",
      },
      {
        name: "Portuguesa",
        code: "P",
      },
      {
        name: "Tachira",
        code: "S",
      },
      {
        name: "Trujillo",
        code: "T",
      },
      {
        name: "Vargas",
        code: "X",
      },
      {
        name: "Yaracuy",
        code: "U",
      },
      {
        name: "Zulia",
        code: "V",
      },
      {
        name: "Delta Amacuro",
        code: "Y",
      },
      {
        name: "Dependencias Federales",
        code: "W",
      },
    ],
  },
  {
    name: "Viet Nam",
    "alpha-2": "VN",
    "alpha-3": "VNM",
    "country-code": "704",
    "iso_3166-2": "ISO 3166-2:VN",
    region: "Asia",
    "sub-region": "South-Eastern Asia",
    "region-code": "142",
    "sub-region-code": "035",
    states: [
      {
        name: "Dac Lac",
        code: "33",
      },
      {
        name: "An Giang",
        code: "44",
      },
      {
        name: "Ba Ria - Vung Tau",
        code: "43",
      },
      {
        name: "Bac Can",
        code: "53",
      },
      {
        name: "Bac Giang",
        code: "54",
      },
      {
        name: "Bac Lieu",
        code: "55",
      },
      {
        name: "Bac Ninh",
        code: "56",
      },
      {
        name: "Ben Tre",
        code: "50",
      },
      {
        name: "Binh Dinh",
        code: "31",
      },
      {
        name: "Binh Duong",
        code: "57",
      },
      {
        name: "Binh Phuoc",
        code: "58",
      },
      {
        name: "Binh Thuan",
        code: "40",
      },
      {
        name: "Ca Mau",
        code: "59",
      },
      {
        name: "Can Tho",
        code: "48",
      },
      {
        name: "Cao Bang",
        code: "04",
      },
      {
        name: "Da Nang, thanh pho",
        code: "60",
      },
      {
        name: "Dong Nai",
        code: "39",
      },
      {
        name: "Dong Thap",
        code: "45",
      },
      {
        name: "Gia Lai",
        code: "30",
      },
      {
        name: "Ha Giang",
        code: "03",
      },
      {
        name: "Ha Nam",
        code: "63",
      },
      {
        name: "Ha Noi, thu do",
        code: "64",
      },
      {
        name: "Ha Tay",
        code: "15",
      },
      {
        name: "Ha Tinh",
        code: "23",
      },
      {
        name: "Hai Duong",
        code: "61",
      },
      {
        name: "Hai Phong, thanh pho",
        code: "62",
      },
      {
        name: "Hoa Binh",
        code: "14",
      },
      {
        name: "Ho Chi Minh, thanh pho [Sai Gon]",
        code: "65",
      },
      {
        name: "Hung Yen",
        code: "66",
      },
      {
        name: "Khanh Hoa",
        code: "34",
      },
      {
        name: "Kien Giang",
        code: "47",
      },
      {
        name: "Kon Tum",
        code: "28",
      },
      {
        name: "Lai Chau",
        code: "01",
      },
      {
        name: "Lam Dong",
        code: "35",
      },
      {
        name: "Lang Son",
        code: "09",
      },
      {
        name: "Lao Cai",
        code: "02",
      },
      {
        name: "Long An",
        code: "41",
      },
      {
        name: "Nam Dinh",
        code: "67",
      },
      {
        name: "Nghe An",
        code: "22",
      },
      {
        name: "Ninh Binh",
        code: "18",
      },
      {
        name: "Ninh Thuan",
        code: "36",
      },
      {
        name: "Phu Tho",
        code: "68",
      },
      {
        name: "Phu Yen",
        code: "32",
      },
      {
        name: "Quang Binh",
        code: "24",
      },
      {
        name: "Quang Nam",
        code: "27",
      },
      {
        name: "Quang Ngai",
        code: "29",
      },
      {
        name: "Quang Ninh",
        code: "13",
      },
      {
        name: "Quang Tri",
        code: "25",
      },
      {
        name: "Soc Trang",
        code: "52",
      },
      {
        name: "Son La",
        code: "05",
      },
      {
        name: "Tay Ninh",
        code: "37",
      },
      {
        name: "Thai Binh",
        code: "20",
      },
      {
        name: "Thai Nguyen",
        code: "69",
      },
      {
        name: "Thanh Hoa",
        code: "21",
      },
      {
        name: "Thua Thien-Hue",
        code: "26",
      },
      {
        name: "Tien Giang",
        code: "46",
      },
      {
        name: "Tra Vinh",
        code: "51",
      },
      {
        name: "Tuyen Quang",
        code: "07",
      },
      {
        name: "Vinh Long",
        code: "49",
      },
      {
        name: "Vinh Phuc",
        code: "70",
      },
      {
        name: "Yen Bai",
        code: "06",
      },
    ],
  },
  {
    name: "Virgin Islands (U.S.)",
    "alpha-2": "VI",
    "alpha-3": "VIR",
    "country-code": "850",
    "iso_3166-2": "ISO 3166-2:VI",
    region: "Americas",
    "sub-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "029",
    states: [],
  },
  {
    name: "Wallis and Futuna",
    "alpha-2": "WF",
    "alpha-3": "WLF",
    "country-code": "876",
    "iso_3166-2": "ISO 3166-2:WF",
    region: "Oceania",
    "sub-region": "Polynesia",
    "region-code": "009",
    "sub-region-code": "061",
    states: [],
  },
  {
    name: "Western Sahara",
    "alpha-2": "EH",
    "alpha-3": "ESH",
    "country-code": "732",
    "iso_3166-2": "ISO 3166-2:EH",
    region: "Africa",
    "sub-region": "Northern Africa",
    "region-code": "002",
    "sub-region-code": "015",
    states: [],
  },
  {
    name: "Yemen",
    "alpha-2": "YE",
    "alpha-3": "YEM",
    "country-code": "887",
    "iso_3166-2": "ISO 3166-2:YE",
    region: "Asia",
    "sub-region": "Western Asia",
    "region-code": "142",
    "sub-region-code": "145",
    states: [
      {
        name: "Abyan",
        code: "AB",
      },
      {
        name: "Adan",
        code: "AD",
      },
      {
        name: "Ad Dali",
        code: "DA",
      },
      {
        name: "Al Bayda'",
        code: "BA",
      },
      {
        name: "Al Hudaydah",
        code: "MU",
      },
      {
        name: "Al Mahrah",
        code: "MR",
      },
      {
        name: "Al Mahwit",
        code: "MW",
      },
      {
        name: "Amran",
        code: "AM",
      },
      {
        name: "Dhamar",
        code: "DH",
      },
      {
        name: "Hadramawt",
        code: "HD",
      },
      {
        name: "Hajjah",
        code: "HJ",
      },
      {
        name: "Ibb",
        code: "IB",
      },
      {
        name: "Lahij",
        code: "LA",
      },
      {
        name: "Ma'rib",
        code: "MA",
      },
      {
        name: "Sa'dah",
        code: "SD",
      },
      {
        name: "San'a'",
        code: "SN",
      },
      {
        name: "Shabwah",
        code: "SH",
      },
      {
        name: "Ta'izz",
        code: "TA",
      },
    ],
  },
  {
    name: "Zambia",
    "alpha-2": "ZM",
    "alpha-3": "ZMB",
    "country-code": "894",
    "iso_3166-2": "ISO 3166-2:ZM",
    region: "Africa",
    "sub-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "014",
    states: [
      {
        name: "Copperbelt",
        code: "08",
      },
      {
        name: "Luapula",
        code: "04",
      },
      {
        name: "Lusaka",
        code: "09",
      },
      {
        name: "North-Western",
        code: "06",
      },
    ],
  },
  {
    name: "Zimbabwe",
    "alpha-2": "ZW",
    "alpha-3": "ZWE",
    "country-code": "716",
    "iso_3166-2": "ISO 3166-2:ZW",
    region: "Africa",
    "sub-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "014",
    states: [
      {
        name: "Bulawayo",
        code: "BU",
      },
      {
        name: "Harare",
        code: "HA",
      },
      {
        name: "Manicaland",
        code: "MA",
      },
      {
        name: "Mashonaland Central",
        code: "MC",
      },
      {
        name: "Mashonaland East",
        code: "ME",
      },
      {
        name: "Mashonaland West",
        code: "MW",
      },
      {
        name: "Masvingo",
        code: "MV",
      },
      {
        name: "Matabeleland North",
        code: "MN",
      },
      {
        name: "Matabeleland South",
        code: "MS",
      },
      {
        name: "Midlands",
        code: "MI",
      },
    ],
  },
];

export const optionsAge = [
  { value: "0", label: "Under 1" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
  { value: "25", label: "25" },
  { value: "26", label: "26" },
  { value: "27", label: "27" },
  { value: "28", label: "28" },
  { value: "29", label: "29" },
  { value: "30", label: "30" },
  { value: "31", label: "31" },
  { value: "32", label: "32" },
  { value: "33", label: "33" },
  { value: "34", label: "34" },
  { value: "35", label: "35" },
  { value: "36", label: "36" },
  { value: "37", label: "37" },
  { value: "38", label: "38" },
  { value: "39", label: "39" },
  { value: "40", label: "40" },
  { value: "41", label: "41" },
  { value: "42", label: "42" },
  { value: "43", label: "43" },
  { value: "44", label: "44" },
  { value: "45", label: "45" },
  { value: "46", label: "46" },
  { value: "47", label: "47" },
  { value: "48", label: "48" },
  { value: "49", label: "49" },
  { value: "50", label: "50" },
  { value: "51", label: "51" },
  { value: "52", label: "52" },
  { value: "53", label: "53" },
  { value: "54", label: "54" },
  { value: "55", label: "55" },
  { value: "56", label: "56" },
  { value: "57", label: "57" },
  { value: "58", label: "58" },
  { value: "59", label: "59" },
  { value: "60", label: "60" },
  { value: "61", label: "61" },
  { value: "62", label: "62" },
  { value: "63", label: "63" },
  { value: "64", label: "64" },
  { value: "65", label: "65" },
  { value: "66", label: "66" },
  { value: "67", label: "67" },
  { value: "68", label: "68" },
  { value: "69", label: "69" },
  { value: "70", label: "70" },
  { value: "71", label: "71" },
  { value: "72", label: "72" },
  { value: "73", label: "73" },
  { value: "74", label: "74" },
  { value: "75", label: "75" },
  { value: "76", label: "76" },
  { value: "77", label: "77" },
  { value: "78", label: "78" },
  { value: "79", label: "79" },
  { value: "80", label: "80" },
  { value: "81", label: "81" },
  { value: "82", label: "82" },
  { value: "83", label: "83" },
  { value: "84", label: "84" },
  { value: "85", label: "85" },
  { value: "86", label: "86" },
  { value: "87", label: "87" },
  { value: "88", label: "88" },
  { value: "89", label: "89" },
  { value: "90", label: "90" },
  { value: "91", label: "91" },
  { value: "92", label: "92" },
  { value: "93", label: "93" },
  { value: "94", label: "94" },
  { value: "95", label: "95" },
  { value: "96", label: "96" },
  { value: "97", label: "97" },
  { value: "98", label: "98" },
  { value: "99", label: "99" },
  { value: "100", label: "100" },
];

export const ageOptionChild = [
  { value: "0", label: "Under 1" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  // { value: "15", label: "15" },
  // { value: "16", label: "16" },
  // { value: "17", label: "17" },
];

export const countryCode = [
  {
    label: "US (+1)",
    name: "United States",
    path: "usa",
    id: "+1",
  },
  {
    label: "AF (+93)",
    name: "Afghanistan",
    path: "afghanistan",
    id: "+93",
  },
  {
    label: "AL (+355)",
    name: "Albania",
    path: "albania",
    id: "+355",
  },
  {
    label: "DZ (+213)",
    name: "Algeria",
    path: "algeria",
    id: "+213",
  },
  {
    label: "AS (+1-684)",
    name: "American Samoa",
    path: "americansamoa",
    id: "+1-684",
  },
  {
    label: "AD (+376)",
    name: "Andorra",
    path: "andorra",
    id: "+376",
  },
  {
    label: "AO (+244)",
    name: "Angola",
    path: "angola",
    id: "+244",
  },
  {
    label: "AI (+1-264)",
    name: "Anguilla",
    path: "anguilla",
    id: "+1-264",
  },
  {
    label: "AQ (+672)",
    name: "Antarctica",
    path: "antarctica",
    id: "+672",
  },
  {
    label: "AG (+1-268)",
    name: "Antigua and Barbuda",
    path: "antiguaandbarbuda",
    id: "+1-268",
  },
  {
    label: "AR (+54)",
    name: "Argentina",
    path: "argentina",
    id: "+54",
  },
  {
    label: "AM (+374)",
    name: "Armenia",
    path: "armenia",
    id: "+374",
  },
  {
    label: "AW (+297)",
    name: "Aruba",
    path: "aruba",
    id: "+297",
  },
  {
    label: "AU (+61)",
    name: "Australia",
    path: "australia",
    id: "+61",
  },
  {
    label: "AT (+43)",
    name: "Austria",
    path: "austria",
    id: "+43",
  },
  {
    label: "AZ (+994)",
    name: "Azerbaijan",
    path: "azerbaijan",
    id: "+994",
  },
  {
    label: "BS (+1-242)",
    name: "Bahamas",
    path: "bahamas",
    id: "+1-242",
  },
  {
    label: "BH (+973)",
    name: "Bahrain",
    path: "bahrain",
    id: "+973",
  },
  {
    label: "BD (+672)",
    name: "Bangladesh",
    path: "bangladesh",
    id: "+672",
  },
  {
    label: "BB (+1-246)",
    name: "Barbados",
    path: "barbados",
    id: "+1-246",
  },
  {
    label: "BY (+375)",
    name: "Belarus",
    path: "belarus",
    id: "+375",
  },
  {
    label: "BE (+32)",
    name: "Belgium",
    path: "belgium",
    id: "+32",
  },
  {
    label: "BZ (+501)",
    name: "Belize",
    path: "belize",
    id: "+501",
  },
  {
    label: "BJ (+229)",
    name: "Benin",
    path: "benin",
    id: "+229",
  },
  {
    label: "BM (+1-441)",
    name: "Bermuda",
    path: "bermuda",
    id: "+1-441",
  },
  {
    label: "BT (+975)",
    name: "Bhutan",
    path: "bhutan",
    id: "+975",
  },
  {
    label: "BO (+591)",
    name: "Bolivia",
    path: "bolivia",
    id: "+591",
  },
  {
    label: "BA (+387)",
    name: "Bosnia and Herzegovina",
    path: "bosnia",
    id: "+387",
  },
  {
    label: "BW (+267)",
    name: "Botswana",
    path: "botswana",
    id: "+267",
  },
  {
    label: "BR (+55)",
    name: "Brazil",
    path: "brazil",
    id: "+55",
  },
  {
    label: "IO (+246)",
    name: "British Indian Ocean Territory",
    path: "britishindianoceanterritory",
    id: "+246",
  },
  {
    label: "VG (+1-284)",
    name: "British Virgin Islands",
    path: "britishvirginislands",
    id: "+1-284",
  },
  {
    label: "BN (+673)",
    name: "Brunei",
    path: "brunei",
    id: "+673",
  },
  {
    label: "BG (+359)",
    name: "Bulgaria",
    path: "bulgaria",
    id: "+359",
  },
  {
    label: "BF (+226)",
    name: "Burkina Faso",
    path: "burkinafaso",
    id: "+226",
  },
  {
    label: "BI (+257)",
    name: "Burundi",
    path: "burundi",
    id: "+257",
  },
  {
    label: "KH (+855)",
    name: "Cambodia",
    path: "cambodia",
    id: "+855",
  },
  {
    label: "CM (+237)",
    name: "Cameroon",
    path: "cameroon",
    id: "+237",
  },
  {
    label: "CV (+238)",
    name: "Cape Verde",
    path: "capeverde",
    id: "+238",
  },
  {
    label: "KY (+1-345)",
    name: "Cayman Islands",
    path: "caymanislands",
    id: "+1-345",
  },
  {
    label: "CF (+236)",
    name: "Central African Republic",
    path: "centralafricanrepublic",
    id: "+236",
  },
  {
    label: "TD (+235)",
    name: "Chad",
    path: "chad",
    id: "+235",
  },
  {
    label: "CL (+56)",
    name: "Chile",
    path: "chile",
    id: "+56",
  },
  {
    label: "CN (+86)",
    name: "China",
    path: "china",
    id: "+86",
  },
  {
    label: "CX (+61)",
    name: "Christmas Island",
    path: "christmasisland",
    id: "+61",
  },
  {
    label: "CC (+61)",
    name: "Cocos Islands",
    path: "cocoskeelingislands",
    id: "+61",
  },
  {
    label: "CO (+57)",
    name: "Colombia",
    path: "colombia",
    id: "+57",
  },
  {
    label: "KM (+269)",
    name: "Comoros",
    path: "comoros",
    ide: "+269",
  },
  {
    label: "CK (+682)",
    name: "Cook Islands",
    path: "cookislands",
    id: "+682",
  },
  {
    label: "CR (+506)",
    name: "Costa Rica",
    path: "costarica",
    id: "+506",
  },
  {
    label: "HR (+385)",
    name: "Croatia",
    path: "croatia",
    id: "+385",
  },
  {
    label: "CU (+53)",
    name: "Cuba",
    path: "cuba",
    id: "+53",
  },
  {
    label: "CW (+599)",
    name: "Curacao",
    path: "curacao",
    ide: "+599",
  },
  {
    label: "CY (+357)",
    name: "Cyprus",
    path: "cyprus",
    ide: "+357",
  },
  {
    label: "CZ (+420)",
    name: "Czech Republic",
    path: "czechrepublic",
    ide: "+420",
  },
  {
    label: "CD (+243))",
    name: "Democratic Republic of the Congo",
    path: "congodemocraticrepublic",
    ide: "+243",
  },
  {
    label: "DK (+45)",
    name: "Denmark",
    path: "denmark",
    ide: "+45",
  },
  {
    label: "DJ (+253)",
    name: "Djibouti",
    path: "djibouti",
    ide: "+253",
  },
  {
    label: "DM (+1-767)",
    name: "Dominica",
    path: "dominica",
    ide: "+1-767",
  },
  {
    label: "DO (+1-809)",
    name: "Dominican Republic",
    path: "dominicanrepublic",
    ide: "+1-809",
  },
  {
    label: "TL (+670)",
    name: "East Timor",
    path: "easttimor",
    id: "+670",
  },
  {
    label: "EC (+593)",
    name: "Ecuador",
    path: "ecuador",
    id: "+593",
  },
  {
    label: "EG (+20)",
    name: "Egypt",
    path: "egypt",
    id: "+20",
  },
  {
    label: "SV (+503)",
    name: "El Salvador",
    path: "elsalvador",
    id: "+503",
  },
  {
    label: "GQ (+240)",
    name: "Equatorial Guinea",
    path: "equatorialguinea",
    id: "+240",
  },
  {
    label: "ER (+291)",
    name: "Eritrea",
    path: "eritrea",
    id: "+291",
  },
  {
    label: "EE (+372)",
    name: "Estonia",
    path: "estonia",
    id: "+372",
  },
  {
    label: "ET (+251)",
    name: "Ethiopia",
    path: "ethiopia",
    id: "+251",
  },
  {
    label: "FK (+500)",
    name: "Falkland Islands",
    path: "falklands",
    id: "+500",
  },
  {
    label: "FO (+298)",
    name: "Faroe Islands",
    path: "faroeislands",
    id: "+298",
  },
  {
    label: "FJ (+679)",
    name: "Fiji",
    path: "fiji",
    id: "+679",
  },
  {
    label: "FI (+358)",
    name: "Finland",
    path: "finland",
    id: "+358",
  },
  {
    label: "FR (+33)",
    name: "France",
    path: "france",
    id: "+33",
  },
  {
    label: "PF (+689)",
    name: "French Polynesia",
    path: "frenchpolynesia",
    id: "+689",
  },
  {
    label: "GA (+241)",
    name: "Gabon",
    path: "gabon",
    id: "+241",
  },
  {
    label: "GM (+220)",
    name: "Gambia",
    path: "gambia",
    id: "+220",
  },
  {
    label: "GE (+995)",
    name: "Georgia",
    path: "georgia",
    id: "+995",
  },
  {
    label: "DE (+49)",
    name: "Germany",
    path: "germany",
    id: "+49",
  },
  {
    label: "GH (+233)",
    name: "Ghana",
    path: "ghana",
    id: "+233",
  },
  {
    label: "GI (+350)",
    name: "Gibraltar",
    path: "gibraltar",
    id: "+350",
  },
  {
    label: "GR (+30)",
    name: "Greece",
    path: "greece",
    id: "+30",
  },
  {
    label: "GL (+299)",
    name: "Greenland",
    path: "greenland",
    id: "+299",
  },
  {
    label: "GD (+1-473)",
    name: "Grenada",
    path: "grenada",
    id: "+1-473",
  },
  {
    label: "GU (+1-671)",
    name: "Guam",
    path: "guam",
    id: "+1-671",
  },
  {
    label: "GT (+502)",
    name: "Guatemala",
    path: "guatemala",
    id: "+502",
  },
  {
    label: "GG (+44-1481)",
    name: "Guernsey",
    path: "guernsey",
    id: "+44-1481",
  },
  {
    label: "GN (+224)",
    name: "Guinea",
    path: "guinea",
    id: "+224",
  },
  {
    label: "GW (+245)",
    name: "Guinea-Bissau",
    path: "guineabissau",
    id: "+245",
  },
  {
    label: "GY (+592)",
    name: "Guyana",
    path: "guyana",
    id: "+592",
  },
  {
    label: "HT (+509)",
    name: "Haiti",
    path: "haiti",
    id: "+509",
  },
  {
    label: "HN (+504)",
    name: "Honduras",
    path: "honduras",
    id: "+504",
  },
  {
    label: "HK (+852)",
    name: "Hong Kong",
    path: "hongkong",
    id: "+852",
  },
  {
    label: "HU (+36)",
    name: "Hungary",
    path: "hungary",
    id: "+36",
  },
  {
    label: "IS (+354)",
    name: "Iceland",
    path: "iceland",
    id: "+354",
  },
  {
    label: "IN (+91)",
    name: "India",
    path: "india",
    id: "+91",
  },
  {
    label: "ID (+62)",
    name: "Indonesia",
    path: "indonesia",
    id: "+62",
  },
  {
    label: "IR (+98)",
    name: "Iran",
    path: "iran",
    id: "+98",
  },
  {
    label: "IQ (+964)",
    name: "Iraq",
    path: "iraq",
    id: "+964",
  },
  {
    label: "IE (+353)",
    name: "Ireland",
    path: "ireland",
    id: "+353",
  },
  {
    label: "IM (+44-1624)",
    name: "Isle of Man",
    path: "isleofman",
    id: "+44-1624",
  },
  {
    label: "IL (+972)",
    name: "Israel",
    path: "israel",
    id: "+972",
  },
  {
    label: "IT (+39)",
    name: "Italy",
    path: "italy",
    id: "+39",
  },
  {
    label: "CI (+225)",
    name: "Ivory Coast",
    path: "ivorycoast",
    id: "+225",
  },
  {
    label: "JM (+1-876)",
    name: "Jamaica",
    path: "jamaica",
    id: "+1-876",
  },
  {
    label: "JP (+81)",
    name: "Japan",
    path: "japan",
    id: "+81",
  },
  {
    label: "JE (+44-1534)",
    name: "Jersey",
    path: "jersey",
    id: "+44-1534",
  },
  {
    label: "JO (+962)",
    name: "Jordan",
    path: "jordan",
    id: "+962",
  },
  {
    label: "KZ (+7)",
    name: "Kazakhstan",
    path: "kazakhstan",
    id: "+7",
  },
  {
    label: "KE (+254)",
    name: "Kenya",
    path: "kenya",
    id: "+254",
  },
  {
    label: "KI (+686)",
    name: "Kiribati",
    path: "kiribati",
    id: "+686",
  },
  {
    label: "XK (+383)",
    name: "Kosovo",
    path: "kosovo",
    id: "+383",
  },
  {
    label: "KW (+965)",
    name: "Kuwait",
    path: "kuwait",
    id: "+965",
  },
  {
    label: "KG (+996)",
    name: "Kyrgyzstan",
    path: "kyrgyzstan",
    id: "+996",
  },
  {
    label: "LA (+856)",
    name: "Laos",
    path: "laos",
    id: "+856",
  },
  {
    label: "LV (+371)",
    name: "Latvia",
    path: "latvia",
    id: "+371",
  },
  {
    label: "LB (+961)",
    name: "Lebanon",
    path: "lebanon",
    id: "+961",
  },
  {
    label: "LS (+266)",
    name: "Lesotho",
    path: "lesotho",
    id: "+266",
  },
  {
    label: "LR (+231)",
    name: "Liberia",
    path: "liberia",
    id: "+231",
  },
  {
    label: "LY (+218)",
    name: "Libya",
    path: "libya",
    id: "+218",
  },
  {
    label: "LI (+423)",
    name: "Liechtenstein",
    path: "liechtenstein",
    id: "+423",
  },
  {
    label: "LT (+370)",
    name: "Lithuania",
    path: "lithuania",
    id: "+370",
  },
  {
    label: "LU (+352)",
    name: "Luxembourg",
    path: "luxembourg",
    id: "+352",
  },
  {
    label: "MO (+853)",
    name: "Macau",
    path: "macau",
    id: "+853",
  },
  {
    label: "MK (+389)",
    name: "Macedonia",
    path: "macedonia",
    id: "+389",
  },
  {
    label: "MG (+261)",
    name: "Madagascar",
    path: "madagascar",
    id: "+261",
  },
  {
    label: "MW (+265)",
    name: "Malawi",
    path: "malawi",
    id: "+265",
  },
  {
    label: "MY (+60)",
    name: "Malaysia",
    path: "malaysia",
    id: "+60",
  },
  {
    label: "MV (+960)",
    name: "Maldives",
    path: "maldives",
    id: "+960",
  },
  {
    label: "ML (+223)",
    name: "Mali",
    path: "mali",
    id: "+223",
  },
  {
    label: "MT (+356)",
    name: "Malta",
    path: "malta",
    id: "+356",
  },
  {
    label: "MH (+692)",
    name: "Marshall Islands",
    path: "marshallislands",
    id: "+692",
  },
  {
    label: "MR (+222)",
    name: "Mauritania",
    path: "mauritania",
    id: "+222",
  },
  {
    label: "MU (+230)",
    name: "Mauritius",
    path: "mauritius",
    id: "+230",
  },
  {
    label: "YT (+262)",
    name: "Mayotte",
    path: "mayotte",
    id: "+262",
  },
  {
    label: "MX (+52)",
    name: "Mexico",
    path: "mexico",
    id: "+52",
  },
  {
    label: "FM (+691)",
    name: "Micronesia",
    path: "micronesia",
    id: "+691",
  },
  {
    label: "MD (+373)",
    name: "Moldova",
    path: "moldova",
    id: "+373",
  },
  {
    label: "MC (+377)",
    name: "Monaco",
    path: "monaco",
    id: "+377",
  },
  {
    label: "MN (+976)",
    name: "Mongolia",
    path: "mongolia",
    id: "+976",
  },
  {
    label: "ME (+382)",
    name: "Montenegro",
    path: "montenegro",
    id: "+382",
  },
  {
    label: "MS (+1-664)",
    name: "Montserrat",
    path: "montserrat",
    id: "+1-664",
  },
  {
    label: "MA (+212)",
    name: "Morocco",
    path: "morocco",
    id: "+212",
  },
  {
    label: "MZ (+258)",
    name: "Mozambique",
    path: "mozambique",
    id: "+258",
  },
  {
    label: "MM (+95)",
    name: "Myanmar",
    path: "burma",
    id: "+95",
  },
  {
    label: "NA (+264)",
    name: "Namibia",
    path: "namibia",
    id: "+264",
  },
  {
    label: "NR (+674)",
    name: "Nauru",
    path: "nauru",
    id: "+674",
  },
  {
    label: "NP (+977)",
    name: "Nepal",
    path: "nepal",
    id: "+977",
  },
  {
    label: "NL (+31)",
    name: "Netherlands",
    path: "netherlands",
    id: "+31",
  },
  {
    label: "AN (+599)",
    name: "Netherlands Antilles",
    path: "netherlandsantilles",
    id: "+599",
  },
  {
    label: "NC (+687)",
    name: "New Caledonia",
    path: "newcaledonia",
    id: "+687",
  },
  {
    label: "NZ (+64)",
    name: "New Zealand",
    path: "newzealand",
    id: "+64",
  },
  {
    label: "NI (+505)",
    name: "Nicaragua",
    path: "nicaragua",
    id: "+505",
  },
  {
    label: "NE (+227)",
    name: "Niger",
    path: "niger",
    id: "+227",
  },
  {
    label: "NG (+234)",
    name: "Nigeria",
    path: "nigeria",
    id: "+234",
  },
  {
    label: "NU (+683)",
    name: "Niue",
    path: "niue",
    id: "+683",
  },
  {
    label: "KP (+850)",
    name: "North Korea",
    path: "northkorea",
    id: "+850",
  },
  {
    label: "MP (+1-670)",
    name: "Northern Mariana Islands",
    path: "northernmarianaislands",
    id: "+1-670",
  },
  {
    label: "NO (+47)",
    name: "Norway",
    path: "norway",
    id: "+47",
  },
  {
    label: "OM (+968)",
    name: "Oman",
    path: "oman",
    id: "+968",
  },
  {
    label: "PK (+92)",
    name: "Pakistan",
    path: "pakistan",
    id: "+92",
  },
  {
    label: "PW (+680)",
    name: "Palau",
    path: "palau",
    id: "+680",
  },
  {
    label: "PS (+970)",
    name: "Palestine",
    path: "palestine",
    id: "+970",
  },
  {
    label: "PA (+507)",
    name: "Panama",
    path: "panama",
    id: "+507",
  },
  {
    label: "PG (+675)",
    name: "Papua New Guinea",
    path: "papuanewguinea",
    id: "+675",
  },
  {
    label: "PY (+595)",
    name: "Paraguay",
    path: "paraguay",
    id: "+595",
  },
  {
    label: "PE (+51)",
    name: "Peru",
    path: "peru",
    id: "+51",
  },
  {
    label: "PH (+63)",
    name: "Philippines",
    path: "philippines",
    id: "+63",
  },
  {
    label: "PN (+64)",
    name: "Pitcairn",
    path: "pitcairnislands",
    id: "+64",
  },
  {
    label: "PL (+48)",
    name: "Poland",
    path: "poland",
    id: "+48",
  },
  {
    label: "PT (+351)",
    name: "Portugal",
    path: "portugal",
    id: "+351",
  },
  {
    label: "PR (+1-787)",
    name: "Puerto Rico",
    path: "puertorico",
    id: "+1-787",
  },
  {
    label: "QA (+974)",
    name: "Qatar",
    path: "qatar",
    id: "+974",
  },
  {
    label: "CG (+242)",
    name: "Republic of the Congo",
    path: "congo",
    id: "+242",
  },
  {
    label: "RE (+262)",
    name: "Reunion",
    path: "reunion",
    id: "+262",
  },
  {
    label: "RO (+40)",
    name: "Romania",
    path: "romania",
    id: "+40",
  },
  {
    label: "RU (+7)",
    name: "Russia",
    path: "russia",
    id: "+7",
  },
  {
    label: "RW (+250)",
    name: "Rwanda",
    path: "rwanda",
    id: "+250",
  },
  {
    label: "BL (+590)",
    name: "Saint Barthelemy",
    path: "saintbarthelemy",
    id: "+590",
  },
  {
    label: "SH (+290)",
    name: "Saint Helena",
    path: "sthelena",
    id: "+290",
  },
  {
    label: "KN (+1-869)",
    name: "Saint Kitts and Nevis",
    path: "stkitts",
    id: "+1-869",
  },
  {
    label: "LC (+1-758)",
    name: "Saint Lucia",
    path: "stlucia",
    id: "+1-758",
  },
  {
    label: "MF (+590)",
    name: "Saint Martin",
    path: "stmartin",
    id: "+590",
  },
  {
    label: "PM (+508)",
    name: "Saint Pierre and Miquelon",
    path: "stpierre",
    id: "+508",
  },
  {
    label: "VC (+1-784)",
    name: "Saint Vincent and the Grenadines",
    path: "stvincent",
    id: "+1-784",
  },
  {
    label: "WS (+685)",
    name: "Samoa",
    path: "samoa",
    id: "+685",
  },
  {
    label: "SM (+378)",
    name: "San Marino",
    path: "sanmarino",
    id: "+378",
  },
  {
    label: "ST (+239)",
    name: "Sao Tome and Principe",
    path: "saotomeandprincipe",
    id: "+239",
  },
  {
    label: "SA (+966)",
    name: "Saudi Arabia",
    path: "saudiarabia",
    id: "+966",
  },
  {
    label: "SN (+221)",
    name: "Senegal",
    path: "senegal",
    id: "+221",
  },
  {
    label: "RS (+381)",
    name: "Serbia",
    path: "serbia",
    id: "+381",
  },
  {
    label: "SC (+248)",
    name: "Seychelles",
    path: "seychelles",
    id: "+248",
  },
  {
    label: "SL (+232)",
    name: "Sierra Leone",
    path: "sierraleone",
    id: "+232",
  },
  {
    label: "SG (+65)",
    name: "Singapore",
    path: "singapore",
    id: "+65",
  },
  {
    label: "SX (+1-721)",
    name: "Sint Maarten",
    path: "sintmaarten",
    id: "+1-721",
  },
  {
    label: "SK (+421)",
    name: "Slovakia",
    path: "slovakia",
    id: "+421",
  },
  {
    label: "SI (+386)",
    name: "Slovenia",
    path: "slovenia",
    id: "+386",
  },
  {
    label: "SB (+677)",
    name: "Solomon Islands",
    path: "solomonislands",
    id: "+677",
  },
  {
    label: "SO (+252)",
    name: "Somalia",
    path: "somalia",
    id: "+252",
  },
  {
    label: "ZA (+27)",
    name: "South Africa",
    path: "southafrica",
    id: "+27",
  },
  {
    label: "KR (+82)",
    name: "South Korea",
    path: "southkorea",
    id: "+82",
  },
  {
    label: "SS (+211)",
    name: "South Sudan",
    path: "southsudan",
    id: "+211",
  },
  {
    label: "ES (+34)",
    name: "Spain",
    path: "spain",
    id: "+34",
  },
  {
    label: "LK (+94)",
    name: "Sri Lanka",
    path: "srilanka",
    id: "+94",
  },
  {
    label: "SD (+249)",
    name: "Sudan",
    path: "sudan",
    id: "+249",
  },
  {
    label: "SR (+597)",
    name: "Suriname",
    path: "suriname",
    id: "+597",
  },
  {
    label: "SJ (+47)",
    name: "Svalbard and Jan Mayen",
    path: "svalbard",
    id: "+47",
  },
  {
    label: "SZ (+268)",
    name: "Swaziland",
    path: "swaziland",
    id: "+268",
  },
  {
    label: "SE (+46)",
    name: "Sweden",
    path: "sweden",
    id: "+46",
  },
  {
    label: "CH (+268)",
    name: "Switzerland",
    path: "switzerland",
    id: "+268",
  },
  {
    label: "SY (+963)",
    name: "Syria",
    path: "syria",
    id: "+963",
  },
  {
    label: "TW (+886)",
    name: "Taiwan",
    path: "taiwan",
    id: "+886",
  },
  {
    label: "TJ (+992)",
    name: "Tajikistan",
    path: "tajikistan",
    id: "+992",
  },
  {
    label: "TZ (+255)",
    name: "Tanzania",
    path: "tanzania",
    id: "+255",
  },
  {
    label: "TH (+66)",
    name: "Thailand",
    path: "thailand",
    id: "+66",
  },
  {
    label: "TG (+228)",
    name: "Togo",
    path: "togo",
    id: "+228",
  },
  {
    label: "TK (+690)",
    name: "Tokelau",
    path: "tokelau",
    id: "+690",
  },
  {
    label: "TO (+676)",
    name: "Tonga",
    path: "tonga",
    id: "+676",
  },
  {
    label: "TT (+1-868)",
    name: "Trinidad and Tobago",
    path: "trinidadandtobago",
    id: "+1-868",
  },
  {
    label: "TN (+216)",
    name: "Tunisia",
    path: "tunisia",
    id: "+216",
  },
  {
    label: "TR (+90)",
    name: "Turkey",
    path: "turkey",
    id: "+90",
  },
  {
    label: "TM (+993)",
    name: "Turkmenistan",
    path: "turkmenistan",
    id: "+993",
  },
  {
    label: "TC (+1-649)",
    name: "Turks and Caicos Islands",
    path: "turksandcaicos",
    id: "+1-649",
  },
  {
    label: "TV (+688)",
    name: "Tuvalu",
    path: "tuvalu",
    id: "+688",
  },
  {
    label: "VI (+1-340)",
    name: "U.S. Virgin Islands",
    path: "virginislands",
    id: "+1-340",
  },
  {
    label: "UG (+256)",
    name: "Uganda",
    path: "uganda",
    id: "+256",
  },
  {
    label: "UA (+380)",
    name: "Ukraine",
    path: "ukraine",
    id: "+380",
  },
  {
    label: "AE (+971)",
    name: "United Arab Emirates",
    path: "uae",
    id: "+971",
  },
  {
    label: "GB (+44)",
    name: "United Kingdom",
    path: "uk",
    id: "+44",
  },
  {
    label: "UY (+598)",
    name: "Uruguay",
    path: "uruguay",
    id: "+598",
  },
  {
    label: "UZ (+998)",
    name: "Uzbekistan",
    path: "uzbekistan",
    id: "+998",
  },
  {
    label: "VU (+678)",
    name: "Vanuatu",
    path: "vanuatu",
    id: "+678",
  },
  {
    label: "VA (+379)",
    name: "Vatican",
    path: "vatican",
    id: "+379",
  },
  {
    label: "VE (+58)",
    name: "Venezuela",
    path: "venezuela",
    id: "+58",
  },
  {
    label: "VN (+84)",
    name: "Vietnam",
    path: "vietnam",
    id: "+84",
  },
  {
    label: "WF (+681)",
    name: "Wallis and Futuna",
    path: "wallisandfutuna",
    id: "+681",
  },
  {
    label: "EH (+212)",
    name: "Western Sahara",
    path: "westernsahara",
    id: "+212",
  },
  {
    label: "YE (+967)",
    name: "Yemen",
    path: "yemen",
    id: "+967",
  },
  {
    label: "ZM (+260)",
    name: "Zambia",
    path: "zambia",
    id: "+260",
  },
  {
    label: "ZE (+263)",
    name: "Zimbabwe",
    path: "zimbabwe",
    id: "+263",
  },
];

export const cardTypeOptions = [
  { value: "AX", label: "American Express" },
  { value: "DS", label: "Discover" },
  { value: "MC", label: "Master Card" },
  { value: "MC", label: "Euro/Mastercard" },
  { value: "VI", label: "VISA" },
];

export const airlineOption = [
  { value: "AM", label: "Aeromexico (AM)" },
  { value: "AC", label: "Air Canada (AC)" },
  { value: "CA", label: "Air China (CA)" },
  { value: "AF", label: "Air France (AF)" },
  { value: "AI", label: "Air India (AI)" },
  { value: "JM", label: "Air Jamaica (JM)" },
  { value: "FJ", label: "Air Pacific Ltd (FJ)" },
  { value: "FL", label: "AirTran Airways (FL)" },
  { value: "AS", label: "Alaska Airlines (AS)" },
  { value: "AA", label: "American Airlines (AA)" },
  { value: "NH", label: "ANA All Nippon Airways (NH)" },
  { value: "OZ", label: "Asiana (OZ)" },
  { value: "UP", label: "Bahamasair (UP)" },
  { value: "BD", label: "bmi (BD)" },
  { value: "BA", label: "British Airways (BA)" },
  { value: "9K", label: "Cape Air (9K)" },
  { value: "BW", label: "Caribbean Airlines (BW)" },
  { value: "CX", label: "Cathay Pacific (CX)" },
  { value: "CI", label: "China Airlines (CI)" },
  { value: "MU", label: "China Eastern Airlines (MU)" },
  { value: "CO", label: "Continental Airlines (CO)" },
  { value: "DL", label: "Delta Airlines (DL)" },
  { value: "7H", label: "Era Alaska (7H)" },
  { value: "F9", label: "Frontier Airlines (F9)" },
  { value: "ZK", label: "Great Lakes (ZK)" },
  { value: "HA", label: "Hawaiian Airlines (HA)" },
  { value: "WP", label: "Islandair (WP)" },
  { value: "JL", label: "Japan Airlines (JL)" },
  { value: "9W", label: "Jet Airways (9W)" },
  { value: "B6", label: "JetBlue Airways (B6)" },
  { value: "KL", label: "KLM-Royal Dutch Airlines (KL)" },
  { value: "KE", label: "Korean Air (KE)" },
  { value: "LA", label: "LAN Airlines (LA)" },
  { value: "LH", label: "Lufthansa (LH)" },
  { value: "YV", label: "Mesa Airlines (YV)" },
  { value: "MX", label: "Mexicana (MX)" },
  { value: "YX", label: "Midwest Airlines (YX)" },
  { value: "NW", label: "Northwest Airlines (NW)" },
  { value: "LW", label: "Pacific Wings (LW)" },
  { value: "SK", label: "Scandinavian Airlines (SK)" },
  { value: "WN", label: "Southwest Airlines (WN)" },
  { value: "NK", label: "Spirit Airlines (NK)" },
  { value: "SY", label: "Sun Country Airlines (SY)" },
  { value: "TA", label: "TACA International (TA)" },
  { value: "JJ", label: "TAM Brazilian Airlines (JJ)" },
  { value: "TP", label: "TAP Air Portugal (TP)" },
  { value: "UA", label: "United Airlines (UA)" },
  { value: "US", label: "US Airways (US)" },
  { value: "VA", label: "V Australia (VA)" },
  { value: "VX", label: "Virgin America (VX)" },
  { value: "VS", label: "Virgin Atlantic (VS)" },
  { value: "WS", label: "WestJet (WS)" },
  { value: "XX", label: "OTHER" },
];
