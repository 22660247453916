import { hotelService } from "../../api/hotelService";
import { hotelConstants } from "../Constants";
import { commonActions } from "./commonActions";
import { openInNewTab } from "../../Util/util";
import http from "../../api/http";

export const hotelActions = {
  searchHotelAction,
  searchHotelMap,
  validateZip,
  getHotelDetails,
  resetHotelDetails,
  getHotelRooms,
  resetHotelRooms,
  getBookingDetails,
  downloadPdf,
  emailBooking,
  cancelledBooking,
  // checkHotelAvability,
  // recheckHotelPriceAction,
  getStripeIntentRequest,
  bookHotelAction,
  getUpcomingBookings,
  getCompletedBookings,
  getCancelledBookings,
  getFailedBookings,
  resetEmailBooking,
  resetCancelledBooking,
  hotelPrebook,
  getAvailableBalance,
  getAvailableBalanceNew,
  paymentIntentHotel,
  cancelRefundDetails,
};

// Node js api started

// node.js api
export const newSearchHotelAction = (payload) => {
  return async (dispatch) => {
    dispatch(commonActions.showLoader());
    dispatch(commonActions.loaderText("loading"));
    dispatch(commonActions.newLoader("newloader"));
    try {
      const response = await http.post(
        `${process.env.REACT_APP_BASE_URL_HOTEL_NODE}/api/v3/search`,
        {
          ...payload,
          params: { use_hotel_search_key: true, use_x_api_key: true },
        }
      );
      if (!response?.data?.pulling) {
        dispatch(commonActions.hideLoader());
        dispatch(commonActions.newLoader());
        dispatch(
          success(hotelConstants.NEW_HOTEL_SEARCH_DATA_SUCCESS, response)
        );
      } else {
        localStorage.setItem(
          "hotel_search_key",
          response?.data?.pre_hotel_search_key
        );
        setTimeout(() => {
          dispatch(newSearchHotelAction(payload));
        }, 4000);
      }

      return response;
    } catch (error) {
      dispatch(commonActions.hideLoader());
      dispatch(commonActions.loaderText());
      return error.response;
    }
  };
};

// node.js api
export const getHotelsForMapAction = (payload) => {
  return async (dispatch) => {
    dispatch(commonActions.showLoader());
    dispatch(commonActions.loaderText("loading"));
    try {
      const response = await http.post(
        `${process.env.REACT_APP_BASE_URL_HOTEL_NODE}/api/v3/map-view/list`,
        {
          ...payload,
          params: { use_hotel_search_key: true, use_x_api_key: true },
        }
      );
      dispatch(commonActions.loaderText());
      dispatch(commonActions.hideLoader());
      dispatch(success(hotelConstants.HOTEL_DATA_FOR_MAP_VIEW, response));
      return response;
    } catch (error) {
      dispatch(commonActions.loaderText());
      dispatch(commonActions.hideLoader());
      return error.response;
    }
  };
};

// node.js api
export const checkHotelAvability = (payload, hotel_id) => {
  return async (dispatch) => {
    dispatch(commonActions.showLoader());
    dispatch(commonActions.loaderText("loading"));
    try {
      const response = await http.post(
        `${process.env.REACT_APP_BASE_URL_HOTEL_NODE}/api/v3/hotel/${hotel_id}/recheck-price`,
        {
          ...payload,
          params: { use_hotel_search_key: true, use_x_api_key: true },
        }
      );
      dispatch(commonActions.loaderText());
      dispatch(commonActions.hideLoader());
      dispatch(success(hotelConstants.HOTEL_GET_AVABILITY_SUCCESS, response));
      return response;
    } catch (error) {
      dispatch(commonActions.loaderText());
      dispatch(commonActions.hideLoader());
      dispatch(
        failure(hotelConstants.HOTEL_GET_AVABILITY_FAILURE, error.response)
      );
      return error.response;
    }
  };
};

// node.js api
export const getCardDataAction = (id) => {
  return async (dispatch) => {
    dispatch(commonActions.showLoader());
    try {
      const response = await http.get(
        `${process.env.REACT_APP_BASE_URL_HOTEL_NODE}/api/v3/map-view/static-content/${id}`,
        {
          params: { use_hotel_search_key: true, use_x_api_key: true },
        }
      );
      dispatch(commonActions.hideLoader());
      return response;
    } catch (error) {
      dispatch(commonActions.hideLoader());
      return error.response;
    }
  };
};

// new API node hotel details
export const newGetHotelDetails = (id) => {
  return async (dispatch) => {
    dispatch(request(hotelConstants.NEW_HOTEL_DETAILS_REQUEST));
    try {
      // http
      //   .get(`${process.env.REACT_APP_BASE_URL_HOTEL_NODE}/api/v3/hotel/${id}/static-content`, {
      //     params: { use_hotel_search_key: true, use_x_api_key: true },
      //   }
      //   )
      //   .then(
      //     (response) => {
      //       dispatch(success(hotelConstants.NEW_HOTEL_DETAILS_SUCCESS, response));
      //     }
      //   )
      const res = await hotelService.newGetHotelDetails(id);
      // dispatch(commonActions.hideLoader());
      dispatch(success(hotelConstants.NEW_HOTEL_DETAILS_SUCCESS, res));
    } catch (error) {
      dispatch(failure(hotelConstants.NEW_HOTEL_DETAILS_FAILURE, error));
      return error.response;
    }
  };
};

export const newGetHotelPackage = (payload, id) => {
  return async (dispatch) => {
    dispatch(commonActions.showLoader());
    dispatch(commonActions.loaderText("loading"));
    dispatch(request(hotelConstants.NEW_HOTEL_PACKAGE_REQUEST));
    try {
      // http
      //   .post(
      //     `${process.env.REACT_APP_BASE_URL_HOTEL_NODE}/api/v3/hotel/${id}/packages`,
      //     {
      //       ...payload,
      //       params: { use_hotel_search_key: true, use_x_api_key: true },
      //     }
      //   )
      //   .then(
      //     (response) => {
      //       dispatch(commonActions.hideLoader());
      //       dispatch(success(hotelConstants.NEW_HOTEL_PACKAGE_SUCCESS, response));
      //     }
      //   )
      const res = await hotelService.newPostHotelPackages(payload, id);
      dispatch(commonActions.loaderText());
      dispatch(commonActions.hideLoader());
      dispatch(success(hotelConstants.NEW_HOTEL_PACKAGE_SUCCESS, res));
    } catch (error) {
      dispatch(failure(hotelConstants.NEW_HOTEL_PACKAGE_FAILURE, error));
      dispatch(commonActions.loaderText());
      dispatch(commonActions.hideLoader());
      return error.response;
    }
  };
};

// Node js api end

// function checkHotelAvability(payload, id) {
//   return async (dispatch) => {
//     try {
//       const res = await hotelService.checkHotelAvailability(payload, id);
//       dispatch(success(hotelConstants.HOTEL_GET_AVABILITY_SUCCESS, res));
//       return res
//     } catch (error) {
//       dispatch(failure(hotelConstants.HOTEL_GET_AVABILITY_FAILURE, error));
//       return error.response
//     }
//   };
// }

// function recheckHotelPriceAction(payload, id, reward_credit_applied) {
//   return (dispatch) => {
//     dispatch(request(hotelConstants.HOTEL_RECHECK_PRICE_REQUEST));
//     dispatch(commonActions.showLoader());
//     return hotelService
//       .recheckHotelPrice(payload, id)
//       .then((res) => {
//         dispatch(success(hotelConstants.HOTEL_RECHECK_PRICE_SUCCESS, res));
//         dispatch(getStripeIntentRequest(reward_credit_applied));
//       })
//       .catch((error) => {
//         dispatch(failure(hotelConstants.HOTEL_RECHECK_PRICE_FAILURE, error));
//         dispatch(commonActions.hideLoader());
//       });
//   };
// }

function getStripeIntentRequest(reward_credit_applied) {
  return async (dispatch) => {
    dispatch(request(hotelConstants.GET_STRIPE_INTENT_REQUEST));
    dispatch(commonActions.showLoader());
    try {
      const res = await hotelService.getStripeIntent(reward_credit_applied);
      dispatch(success(hotelConstants.GET_STRIPE_INTENT_SUCCESS, res));
    } catch (error) {
      dispatch(failure(hotelConstants.GET_STRIPE_INTENT_FAILURE, error));
      dispatch(commonActions.hideLoader());
    }
  };
}

function paymentIntentHotel(payload) {
  return async (dispatch) => {
    dispatch(request(hotelConstants.HOTEL_PAYMENT_INTENT_REQUEST));
    dispatch(commonActions.showLoader());
    try {
      const res = await hotelService.paymentIntentHotel(payload);
      dispatch(success(hotelConstants.HOTEL_PAYMENT_INTENT_SUCCESS, res));
      return res;
    } catch (error) {
      dispatch(failure(hotelConstants.HOTEL_PAYMENT_INTENT_FAILURE, error));
      dispatch(commonActions.hideLoader());
      return error;
    }
  };
}

function bookHotelAction(payload, id) {
  return async (dispatch) => {
    dispatch(request(hotelConstants.BOOK_HOTEL_REQUEST));
    dispatch(commonActions.showLoader());
    try {
      const res = await hotelService.bookHotelService(payload, id);
      dispatch(success(hotelConstants.BOOK_HOTEL_SUCCESS, res));
      dispatch(commonActions.hideLoader());
      return res;
    } catch (error) {
      dispatch(failure(hotelConstants.BOOK_HOTEL_FAILURE, error));
      dispatch(commonActions.hideLoader());
      return error;
    }
  };
}

function searchHotelAction(payload, navigateTo) {
  return (dispatch) => {
    dispatch(request(hotelConstants.SEARCH_HOTEL_REQUEST));
    dispatch(commonActions.loaderText("loading"));
    hotelService.searchHotelService(payload).then(
      (response) => {
        dispatch(commonActions.loaderText());
        if (
          response?.data?.search_key &&
          !window.localStorage.getItem("hotel_search_key")
        ) {
          window.localStorage.setItem(
            "hotel_search_key",
            response?.data?.search_key
          );
        }
        if (localStorage.getItem("preserveNeighbourhood")) {
          response.data.filters_available.filter(
            (ele) => ele.name === "neighbourhoods"
          )[0].options;
        }
        dispatch(success(hotelConstants.SEARCH_HOTEL_SUCCESS, response));
        if (navigateTo) navigateTo("/hotel-search-result");
        dispatch(commonActions.hideLoader());
      },
      (error) => {
        dispatch(commonActions.loaderText());
        dispatch(failure(hotelConstants.SEARCH_HOTEL_FAILURE, error));
        dispatch(commonActions.hideLoader());
      }
    );
  };
}

function searchHotelMap(payload) {
  return (dispatch) => {
    dispatch(request(hotelConstants.SEARCH_HOTEL_CORD_REQUEST));
    dispatch(commonActions.loaderText("loading"));
    hotelService.searchHotelCordService(payload).then(
      (response) => {
        dispatch(commonActions.loaderText());
        dispatch(success(hotelConstants.SEARCH_HOTEL_CORD_SUCCESS, response));
        dispatch(commonActions.hideLoader());
      },
      (error) => {
        dispatch(commonActions.loaderText());
        dispatch(failure(hotelConstants.SEARCH_HOTEL_CORD_FAILURE, error));
        dispatch(commonActions.hideLoader());
      }
    );
  };
}

function getBookingDetails(id) {
  return (dispatch) => {
    dispatch(request(hotelConstants.GET_USER_BOOKING_DETAILS));
    dispatch(commonActions.showLoader());
    return hotelService
      .getBookingDetails(id)
      .then((res) => {
        dispatch(success(hotelConstants.GET_USER_BOOKING_DETAILS_SUCCESS, res));
        dispatch(commonActions.hideLoader());
      })
      .catch((error) => {
        dispatch(
          failure(hotelConstants.GET_USER_BOOKING_DETAILS_FAILURE, error)
        );
        dispatch(commonActions.hideLoader());
      });
  };
}

function downloadPdf(data) {
  return (dispatch) => {
    dispatch(request(hotelConstants.DOWNLOAD_INVOICE));
    dispatch(commonActions.showLoader());
    return hotelService
      .downloadPdf(data)
      .then((res) => {
        dispatch(success(hotelConstants.DOWNLOAD_INVOICE_SUCCESS, res));
        openInNewTab(res.data.url);
        dispatch(commonActions.hideLoader());
      })
      .catch((error) => {
        dispatch(failure(hotelConstants.DOWNLOAD_INVOICE_FAILURE, error));
        dispatch(commonActions.hideLoader());
      });
  };
}

function emailBooking(payload) {
  return (dispatch) => {
    dispatch(request(hotelConstants.EMAIL_BOOKING));
    dispatch(commonActions.showLoader());
    return hotelService
      .emailBooking(payload)
      .then((res) => {
        dispatch(success(hotelConstants.EMAIL_BOOKING_SUCCESS, res));
        dispatch(commonActions.hideLoader());
        return res;
      })
      .catch((error) => {
        dispatch(failure(hotelConstants.EMAIL_BOOKING_FAILURE, error));
        dispatch(commonActions.hideLoader());
        return error;
      });
  };
}

function resetEmailBooking() {
  return (dispatch) => {
    dispatch(request(hotelConstants.EMAIL_BOOKING));
  };
}

function cancelledBooking(payload) {
  return (dispatch) => {
    dispatch(request(hotelConstants.CANCEL_BOOKING));
    dispatch(commonActions.showLoader());
    return hotelService
      .cancelledBooking(payload)
      .then((res) => {
        dispatch(success(hotelConstants.CANCEL_BOOKING_SUCCESS, res));
        dispatch(commonActions.hideLoader());
      })
      .catch((error) => {
        dispatch(failure(hotelConstants.CANCEL_BOOKING_FAILURE, error));
        dispatch(commonActions.hideLoader());
      });
  };
}

function resetCancelledBooking() {
  return (dispatch) => {
    dispatch(request(hotelConstants.CANCEL_BOOKING));
  };
}

function validateZip(zip) {
  return (dispatch) => {
    dispatch(request(hotelConstants.VALIDATE_ZIP));
    dispatch(commonActions.loaderText("fetching..."));
    return hotelService
      .validateZip(zip)
      .then((res) => {
        dispatch(success(hotelConstants.VALIDATE_ZIP_SUCCESS, res));
        dispatch(commonActions.hideLoader());
        return res;
      })
      .catch((error) => {
        dispatch(failure(hotelConstants.VALIDATE_ZIP_FAILURE, error));
        dispatch(commonActions.hideLoader());
      });
  };
}

function getHotelDetails(payload, id) {
  return (dispatch) => {
    dispatch(request(hotelConstants.HOTEL_DETAILS_REQUEST));
    dispatch(commonActions.loaderText("loading"));
    hotelService.getHotelDetails(payload, id).then(
      (response) => {
        dispatch(commonActions.loaderText());
        dispatch(success(hotelConstants.HOTEL_DETAILS_SUCCESS, response));
      },
      (error) => {
        dispatch(commonActions.loaderText());
        dispatch(failure(hotelConstants.HOTEL_DETAILS_FAILURE, error));
        dispatch(commonActions.hideLoader());
      }
    );
  };
}

function resetHotelDetails() {
  return (dispatch) => {
    dispatch(request(hotelConstants.HOTEL_DETAILS_REQUEST));
  };
}

function getHotelRooms(payload, id) {
  return (dispatch) => {
    dispatch(request(hotelConstants.HOTEL_ROOMS_REQUEST));
    dispatch(commonActions.loaderText("loading"));
    hotelService.getHotelRooms(payload, id).then(
      (response) => {
        dispatch(commonActions.loaderText());
        dispatch(success(hotelConstants.HOTEL_ROOMS_SUCCESS, response));
        dispatch(commonActions.hideLoader());
      },
      (error) => {
        dispatch(commonActions.loaderText());
        dispatch(failure(hotelConstants.HOTEL_ROOMS_FAILURE, error));
        dispatch(commonActions.hideLoader());
      }
    );
  };
}

function resetHotelRooms() {
  return (dispatch) => {
    dispatch(request(hotelConstants.HOTEL_ROOMS_REQUEST));
  };
}

function getUpcomingBookings(status) {
  return (dispatch) => {
    dispatch(request(hotelConstants.GET_USER_BOOKINGS));
    dispatch(commonActions.showLoader());
    return hotelService
      .getUpcomingBookings(status)
      .then((res) => {
        dispatch(success(hotelConstants.GET_USER_BOOKINGS_SUCCESS, res));
        dispatch(commonActions.hideLoader());
      })
      .catch((error) => {
        dispatch(failure(hotelConstants.GET_USER_BOOKINGS_FAILURE, error));
        dispatch(commonActions.hideLoader());
      });
  };
}

function getCompletedBookings(status) {
  return (dispatch) => {
    dispatch(request(hotelConstants.GET_COMPLETED_BOOKINGS));
    dispatch(commonActions.showLoader());
    return hotelService
      .getCompletedBookings(status)
      .then((res) => {
        dispatch(success(hotelConstants.GET_COMPLETED_BOOKINGS_SUCCESS, res));
        dispatch(commonActions.hideLoader());
      })
      .catch((error) => {
        dispatch(failure(hotelConstants.GET_COMPLETED_BOOKINGS_FAILURE, error));
        dispatch(commonActions.hideLoader());
      });
  };
}

function getCancelledBookings(status) {
  return (dispatch) => {
    dispatch(request(hotelConstants.GET_CANCELLED_BOOKINGS));
    dispatch(commonActions.showLoader());
    return hotelService
      .getCancelledBookings(status)
      .then((res) => {
        dispatch(success(hotelConstants.GET_CANCELLED_BOOKINGS_SUCCESS, res));
        dispatch(commonActions.hideLoader());
      })
      .catch((error) => {
        dispatch(failure(hotelConstants.GET_CANCELLED_BOOKINGS_FAILURE, error));
        dispatch(commonActions.hideLoader());
      });
  };
}

function getFailedBookings(status) {
  return (dispatch) => {
    dispatch(request(hotelConstants.GET_FAILED_BOOKINGS));
    dispatch(commonActions.showLoader());
    return hotelService
      .getFailedBookings(status)
      .then((res) => {
        dispatch(success(hotelConstants.GET_FAILED_BOOKINGS_SUCCESS, res));
        dispatch(commonActions.hideLoader());
      })
      .catch((error) => {
        dispatch(failure(hotelConstants.GET_FAILED_BOOKINGS_FAILURE, error));
        dispatch(commonActions.hideLoader());
      });
  };
}
export const getMytripBookings = (payload) => {
  return (dispatch, getState) => {
    const myTripCurrentPage =
      getState()?.hotelReducer?.myTripBookingCurrentPage;
    const userId = getState()?.authReducer?.userData?.user_id;
    const updatedPayload = {
      ...payload,
      user_id: userId,
      pagination: {
        page: myTripCurrentPage,
        perPage: 10,
      },
    };
    // dispatch(request(hotelConstants.GET_FAILED_BOOKINGS));
    dispatch(commonActions.showLoader());
    return hotelService
      .getMytripBookingsService(updatedPayload)
      .then((res) => {
        dispatch(success(hotelConstants.GET_MY_TRIP_BOOKING_SUCCESS, res));
        dispatch(commonActions.hideLoader());
      })
      .catch((error) => {
        dispatch(failure(hotelConstants.GET_MY_TRIP_BOOKING_FAILED, error));
        dispatch(commonActions.hideLoader());
      });
  };
};

// hotel pre booking action

function hotelPrebook(payload, id) {
  return (dispatch) => {
    dispatch(request(hotelConstants.PREBOOK_HOTEL_BOOKING_REQUEST));
    dispatch(commonActions.showLoader());
    return hotelService
      .hotelPrebook(payload, id)
      .then((res) => {
        dispatch(success(hotelConstants.PREBOOK_HOTEL_BOOKING_SUCCESS, res));
        dispatch(commonActions.hideLoader());
        return res;
      })
      .catch((error) => {
        dispatch(failure(hotelConstants.PREBOOK_HOTEL_BOOKING_FAILURE, error));
        dispatch(commonActions.hideLoader());
        return error;
      });
  };
}

// Get available balance
function getAvailableBalance() {
  return (dispatch) => {
    hotelService.getAvailableBalance().then(
      (response) => {
        dispatch(success(hotelConstants.GET_AVAILABLE_BALANCE, response));
      },
      () => {}
    );
  };
}

function getAvailableBalanceNew(payload) {
  return (dispatch) => {
    dispatch(commonActions.showLoader());
    return hotelService
      .getAvailableBalanceNew(payload)
      .then((res) => {
        dispatch(success(hotelConstants.GET_AVAILABLE_BALANCE, res));
        // dispatch(commonActions.hideLoader());
        return res;
      })
      .catch((error) => {
        dispatch(failure(hotelConstants.GET_AVAILABLE_BALANCE, error));
        // dispatch(commonActions.hideLoader());
        return error;
      });
  };
}

function cancelRefundDetails(payload) {
  return (dispatch) => {
    return hotelService
      .cancelRefundDetails(payload)
      .then((res) => {
        dispatch(success(hotelConstants.CANCEL_BOOKING_DETAIL_SUCCESS, res));
        dispatch(commonActions.hideLoader());
        return res;
      })
      .catch((error) => {
        dispatch(failure(hotelConstants.CANCEL_BOOKING_DETAIL_FAILURE, error));
        // dispatch(commonActions.hideLoader());
        return error;
      });
  };
}

function request(action, payload) {
  return { type: action, payload };
}

function success(action, response) {
  return { type: action, response };
}

function failure(action, error) {
  return { type: action, error };
}
