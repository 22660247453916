import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import HeaderNavbar from "../Component/HeaderNavbar";
import Footer from "./Footer";
import { Tab, Tabs as TabsComponent, TabList, TabPanel } from "react-tabs";
// import { widgetsTab } from "../Stores/Constants/siteConstants/tabsConstants";
import LogoWhite from "../Component/FormComponent/LogoWhite";
import logo from "../Assets/img/logo.png";
import Scrollbars from "react-custom-scrollbars";
import VideoModal from "../Component/VideoModal";
import FreemiumVideo from "../Assets/video/introductionVideos/FreemiumMember/video.mp4";
import PremiumVideo from "../Assets/video/introductionVideos/PremiumMember/video.mp4";
import PremiumPlusVideo from "../Assets/video/introductionVideos/PremiumPlusMember/video.mp4";
import InfoIcon from "../Assets/img/icons/information-icon-blue.png";
import InfoCloseIcon from "../Assets/img/icons/cancel-close-icon-blue.png";
import FlightTab from "../Views/Widgets/FlightTab";
// import HotelTab from "../Views/Widgets/HotelTab";
import HotelTab from "../Views/Widgets/NewHotelTab.jsx";
import CarsTab from "../Views/Widgets/CarsTab.jsx";
import HomeTab from "../Views/Widgets/HomeTab";
import Condos from "../Views/Widgets/CondosTab";
import { ReactComponent as CondosWhite } from "../Assets/img/icons/condos-icon-white.svg";

// Tab Component above table
export default function TabLayout() {
  const user = JSON.parse(window.localStorage.getItem("user"));
  const [activeTab, setactiveTab] = useState(0);
  const [videoSrc, setVideoSrc] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const widgetsTabInitialVal = [
    {
      id: 0,
      tabKey: "hotels",
      title: "Hotels",
      tabIcon: "icon-apartments",
      tabImage: null,
      isSearchView: true,
      body: HotelTab,
    },
    {
      id: 1,
      tabKey: "flights",
      title: "Flights",
      tabIcon: "icon-flight",
      tabImage: null,
      isSearchView: true,
      body: FlightTab,
    },
    {
      id: 2,
      tabKey: "cars",
      title: "Cars",
      tabIcon: "icon-shape",
      tabImage: null,
      isSearchView: true,
      body: CarsTab,
    },
    {
      id: 3,
      tabKey: "stays",
      title: "Stays",
      tabImage: null,
      tabIcon: "icon-building",
      isSearchView: true,
      body: HomeTab,
    },
    {
      id: 4,
      tabKey: "condos",
      title: "V – Homes",
      tabIcon: null,
      tabImage: <CondosWhite />,
      isSearchView: true,
      body: Condos,
    },
  ];

  const [widgetsTab, setWidgetsTab] = useState(widgetsTabInitialVal);

  const handleInfoAndSearchToggle = (activeTab) => {
    const output = widgetsTab.map((ele) => {
      if (ele.id === activeTab) {
        ele.isSearchView = !ele.isSearchView;
      }
      return ele;
    });
    setWidgetsTab(output);
  };

  const handleActiveTab = (id) => {
    setactiveTab(id);
  };
  useEffect(() => {
    if (user?.first_login) {
      if (user.type_id === process.env.REACT_APP_FREMIUM_USER_TYPE_ID) {
        setVideoSrc(FreemiumVideo);
      } else if (
        user.type_id === process.env.REACT_APP_PREMIUM_USER_TYPE_ID ||
        user.type_id === process.env.REACT_APP_PREMIUM_TYPE_ID_3_MONTH_PROMO ||
        user.type_id === process.env.REACT_APP_PREMIUM_TYPE_ID_6_MONTH_PROMO
      ) {
        setVideoSrc(PremiumVideo);
      } else if (
        user.type_id === process.env.REACT_APP_PREMIUM_PLUS_USER_TYPE_ID
      ) {
        setVideoSrc(PremiumPlusVideo);
      }
      setOpenModal(true);
    }
  }, []);
  // user data
  // const userData = useSelector((state) => state.authReducer.userData);

  // for focusing active tab
  const activeNav = (id) => {
    if (id === activeTab) {
      return "active";
    } else {
      return "";
    }
  };

  const handleOpenCloseVideoModal = () => {
    if (!openModal) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
      setVideoSrc(null);
      localStorage.setItem("videoShown", true);
    }
  };
  const currTabStatus = widgetsTab.filter((ele) => {
    if (ele.id === activeTab) {
      return ele;
    }
  })[0];
  const renderIcon = (activeTab) => {
    return currTabStatus.isSearchView ? (
      <img src={InfoIcon} alt="icon" />
    ) : (
      <img src={InfoCloseIcon} alt="icon" />
    );
  };

  return (
    <div className="landingPage landingPageWrap widget-page-wrap">
      <HeaderNavbar logowhite={logo} />
      <Scrollbars
        autoHeight={true}
        autoHeightMin={120}
        autoHeightMax={`calc(100%)`}
        className="pageInternalScroll mainContent landing-mainContent"
      >
        <div className="container">
          <div
            className={
              currTabStatus?.isSearchView
                ? "centerCnt"
                : "centerCnt centerCntWrap"
            }
          >
            <div className="centerAlign widget-logo">
              <Link to={"/"} className="linkStyle">
                <LogoWhite src={logo} className="logo" />
              </Link>
            </div>
            <h2 className="titleStyle">
              Hi {user?.first_name}, where do You want to go?
            </h2>
            <TabsComponent className="boxStyle widgetBox">
              <TabList className="tabsStyle tabsBtn marginZero">
                {widgetsTab.map((item, index) => {
                  return (
                    <Tab
                      key={index}
                      className={activeNav(item.id)}
                      onClick={() => handleActiveTab(item.id)}
                    >
                      {/* keep the value against index to dynamically update style applied to the particular tab */}
                      <Link to="#" key={index}>
                        {item.tabImage && (
                          <span className="icon-image">{item.tabImage}</span>
                        )}
                        <span className={item.tabIcon}>
                          <span className="path1"></span>
                        </span>

                        {item.title}
                      </Link>
                    </Tab>
                  );
                })}
                {/* Desktop button info widget toggle */}
                <button
                  onClick={() => handleInfoAndSearchToggle(activeTab)}
                  type="button"
                  className="btn-style-removed btn-info-widget btn-info-widget-desktop"
                >
                  {renderIcon(activeTab)}
                </button>
              </TabList>
              {/* Desktop button info widget toggle */}
              <button
                onClick={() => handleInfoAndSearchToggle(activeTab)}
                type="button"
                className="btn-style-removed btn-info-widget btn-info-widget-mobile"
              >
                {renderIcon(activeTab)}
              </button>
              {/* will need to render different table pages based on selected tab */}
              {widgetsTab.map(({ body, isSearchView }, i) => {
                const Component = body;
                return (
                  <React.Fragment key={i}>
                    <div className="tabContentWrap" key={i}>
                      <TabPanel>
                        {" "}
                        <Component isSearchView={isSearchView} />{" "}
                      </TabPanel>
                    </div>
                  </React.Fragment>
                );
              })}
            </TabsComponent>
          </div>
        </div>
      </Scrollbars>
      <Footer />
      {openModal && videoSrc && (
        <VideoModal
          openModal={openModal}
          closeModal={handleOpenCloseVideoModal}
          videoSrc={videoSrc}
          StopAutoPlayVideo={false}
        />
      )}
    </div>
  );
}
