import React from 'react';
import { useSelector } from 'react-redux';
import RoomCard from './RoomCard.jsx';

import { Select } from 'antd';

function Rooms({ setSortKey, sortKey, handelOnCahnge, hotel_id, currencySymbol, currencyCode, Countdown, CountDownForMobile, setShowUpgradeModal }) {

  const hotelPackageDetails = useSelector((state) => state.hotelReducer.hotelPackageDetails);
  return (
    <>
      <div id="rooms" className="selectRoomTabs">
        <div className='sectionTitleWrap'>
          <div className="sectionTitle">
            <h2>Select Your Room</h2>
            <div className='rate-timer-wrap'>
              {CountDownForMobile}
              {Countdown}
            </div>
          </div>
          <div className="sortWrapper">
            <h4 className='sort-by-title'>Sort by</h4>
            <Select
              popupClassName="hotel-detail-sort-by"
              options={[
                {
                  label: "Highest Savings (%)",
                  value: 'savings'
                },
                {
                  label: "Lowest Price",
                  value: 'price'
                },
              ]}
              value={sortKey}
              onChange={(e) => {
                setSortKey(e);
                handelOnCahnge(e);
              }}
              placeholder="Sort by"
            />
          </div>
        </div>
      </div>
      <div className="selectRoomContentMain">
        <div className="selectRoomContent">
          {(
            <div id="refundable-rooms" className="new-card-section activeTab">
              {hotelPackageDetails?.package?.map((ele, index) => {
                return (
                  <RoomCard
                    roomdata={ele}
                    containerIndex={index}
                    index={`${index}_room`}
                    key={`${index}_refun_room`}
                    hotel_id={hotel_id}
                    currencySymbol={currencySymbol}
                    currencyCode={currencyCode}
                    isRefundable={false}
                    setShowUpgradeModal={setShowUpgradeModal}
                  />
                );
              })}
            </div>
          )}
        </div>

      </div>
    </>
  );
}
export default Rooms;
