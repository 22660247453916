import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HeaderNavbar from "../../Component/HeaderNavbar";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import { getMytripBookings, hotelActions } from "../../Stores/Actions";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs as TabsComponent, TabList, TabPanel } from "react-tabs";
import Upcoming from "./Upcoming";
import Cancelled from "./Cancelled";
import Completed from "./Completed";
import Failed from "./Failed";
import { Scrollbars } from "react-custom-scrollbars";
import { keyTabNameMapMyTrip } from "../../Util/util";
import AllBookings from "./AllBookings";

function BookingDetailsNew() {
  const [activeTab, setactiveTab] = useState(0);
  const [isMobile, setisMobile] = useState(false);

  const handleActiveTab = (id) => {
    setactiveTab(id);
  };

  useEffect(() => {
    if (window.innerWidth <= 767) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  }, [window.innerWidth]);

  const dispatch = useDispatch();


  const myTripBookingsData = useSelector(
    (state) => state.hotelReducer.myTripBookings
  );

  // console.log("myTripBookingsData", myTripBookingsData)
  useEffect(() => {
    // dispatch(hotelActions.getUpcomingBookings("upcoming"));
    // dispatch(hotelActions.getCompletedBookings("completed"));
    // dispatch(hotelActions.getCancelledBookings("cancelled"));
    // dispatch(hotelActions.getFailedBookings("failed"));
    const payload = {
      travel_vertical: "all",
      tab: keyTabNameMapMyTrip?.[activeTab] || "upcoming",
    };
    dispatch(getMytripBookings(payload));
  }, [activeTab]);


  const sidebarTabsList2 = [
    {
      id: 0,
      tabKey: "upcoming",
      title: `Upcoming (${myTripBookingsData?.bookingCount?.upcoming || 0})`,
      tabIcon: "icon-work",
      body: (
        <AllBookings status="UPCOMING" bookingData={myTripBookingsData?.bookings} />
      ),
    },
    {
      id: 1,
      tabKey: "completed",
      title: `Completed (${myTripBookingsData?.bookingCount?.completed || 0})`,
      tabIcon: "fa fa-calendar-check-o",
      // body: <Completed />,
      body: (
        <AllBookings
          status="COMPLETED"
          bookingData={myTripBookingsData?.
            bookings
          }
        />
      ),
    },
    {
      id: 2,
      tabKey: "cancelled",
      title: `Cancelled (${myTripBookingsData?.bookingCount?.cancelled || 0})`,
      tabIcon: "fa fa-ban",
      body: (
        <AllBookings
          status="CANCELLED"
          bookingData={myTripBookingsData?.
            bookings
          }
        />
      ),
    },
    {
      id: 3,
      tabKey: "failed",
      title: `Pending (${myTripBookingsData?.bookingCount?.pending || 0})`,
      tabIcon: "fa fa-hourglass-half",
      body: (
        <AllBookings status="PENDING" bookingData={myTripBookingsData?.
          bookings
        } />
      ),
    },
    {
      id: 4,
      tabKey: "failed",
      title: `Failed (${myTripBookingsData?.bookingCount?.failed || 0})`,
      tabIcon: "fa fa-times-circle-o",
      body: (
        <AllBookings status="FAILED" bookingData={myTripBookingsData?.
          bookings
        } />
      ),
    },
  ];

  // for focusing active tab
  const activeNav = (id) => {
    if (id === activeTab) {
      return "active";
    } else {
      return "";
    }
  };

  const [toggle, setToggle] = useState(false);

  const [status, setStatus] = useState("");

  // useEffect(() => {
  //   setStatus(`Upcoming (${upComingBookingData?.length})`);
  // }, [upComingBookingData]);

  return (
    <div className="siteBg listPage bookingPageWrap">
      <div className="pageWrap">
        <div className="fixedHeader">
          <HeaderNavbar useBlueIcon={true} />
        </div>
        <section className="mainContent">
          {/* <div className="pageInternalScroll"> */}
          <Scrollbars
            // autoHeight={`calc(100vh - 110px)`}
            autoHeightMin={120}
            // disableHorizontalScrolling
            autoHeightMax={`calc(100%)`}
            className="pageInternalScroll"
          >
            <div className="container dflex pageInternalScrollTopPadding booking-page-content">
              <div className="leftSideBar">
                <div
                  className={
                    toggle ? "tabsWrapper openDropdown" : "tabsWrapper"
                  }
                >
                  <div className="tabs-heading-title">
                    <h3 className="sideBarTitie">My Bookings</h3>
                    <div
                      className="mobileActiveMenu"
                      onClick={() => setToggle((prev) => !prev)}
                    >
                      <Link className="active toggleBtn2" to="">
                        <i className="icon-work"></i>
                        <span className="tabText">{status}</span>
                        <i className="fa fa-angle-down"></i>
                      </Link>
                    </div>
                  </div>

                  <div className="tabs-content-wrap">
                    <TabsComponent>
                      <div className="leftsidebar-wrap">
                        <h3 className="sideBarTitie">My Bookings</h3>
                        <TabList className="sidebarTabs">
                          {sidebarTabsList2.map((item, index) => {
                            return (
                              <Tab
                                key={`${index}_tablist`}
                                className={activeNav(item.id)}
                                onClick={() => {
                                  handleActiveTab(item.id);
                                  setStatus(item?.title);
                                  if (isMobile) {
                                    setToggle((prev) => !prev);
                                  }
                                }}
                              >
                                <Link to="#" key={index}>
                                  <span
                                    className={`icons-list ${item.tabIcon}`}
                                  ></span>
                                  <span className="tabText">{item.title}</span>
                                </Link>
                              </Tab>
                            );
                          })}
                        </TabList>
                      </div>

                      <div className="custom-tabs-wrap">
                        {sidebarTabsList2.map(({ body }, i) => {
                          return (
                            <TabPanel key={`${i}_tabPanel`}>{body}</TabPanel>
                          );
                        })}
                      </div>
                    </TabsComponent>
                  </div>
                </div>
              </div>
            </div>
          </Scrollbars>
          {/* </div> */}
        </section>
        <FooterWithMediaIcon
          className={"white-icon"}
          iconClassName="icon-white"
        />
      </div>
    </div>
  );
}

export default BookingDetailsNew;
