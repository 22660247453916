import { Link } from "react-router-dom";
import { cabinClassOptions, debounce } from "../../Util/util";
import { isEmpty, cloneDeep } from "lodash";
import { useNavigate } from "react-router-dom";
import { flightActions } from "../../Stores/Actions";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { BsArrowReturnRight } from "react-icons/bs";
import { HiOutlineChevronDown } from "react-icons/hi";
import { useState, useEffect, useRef, useCallback, Fragment } from "react";
import { commonActions } from "../../Stores/Actions/commonActions";
import { Button, DatePicker, Space, AutoComplete, Input } from "antd";
import { MdClose, MdFlight, MdOutlineAdd } from "react-icons/md";

import moment from "moment";
import Dropdown from "./Dropdown";
import doubleArrow from "../../Assets/img/flight-mobile-arrows.png";

import "antd/dist/antd.css";
import "./Temp.scss";

export default function FlightTab({
  isFromLisitng,
  setSelectedFlightTab,
  isSearchView,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mobileAutocompleteRef = useRef(null);
  const currency = useSelector(
    (state) => state.currencyLanguageReducer?.data?.currency || {}
  );

  const [toggleModalForMultiCityOrigin, setToggleModalForMultiCityOrigin] =
    useState(false);
  const [
    toggleModalForMultiCityDestination,
    setToggleModalForMultiCityDestination,
  ] = useState(false);

  const intialPayload = window.localStorage.getItem("flightPayload")
    ? JSON.parse(window.localStorage.getItem("flightPayload"))
    : {};
  const selectedTab = !isFromLisitng
    ? "oneway"
    : window.localStorage.getItem("currentTab");

  const multiCityData = JSON.parse(
    window.localStorage.getItem("multiCityForm")
  );
  const destination =
    selectedTab !== "multicity" || !isFromLisitng
      ? JSON.parse(window.localStorage.getItem("destination"))
      : multiCityData[multiCityData.length - 1].to;
  const origin =
    selectedTab !== "multicity" || !isFromLisitng
      ? JSON.parse(window.localStorage.getItem("origin"))
      : multiCityData[0].from;
  const travelingdata = JSON.parse(
    window.localStorage.getItem("travellingInfo")
  );

  const [departureDate, setDepartureDate] = useState(
    intialPayload?.departureDate && isFromLisitng && selectedTab !== "multicity"
      ? moment(intialPayload?.flightQueries?.[0]?.departureDate).add(1, "days")
      : multiCityData && isFromLisitng
      ? moment(multiCityData[0].date)
      : moment().add(1, "days")
  );
  const [returnDate, setReturnDate] = useState(
    intialPayload?.flightQueries?.[1]?.departureDate && isFromLisitng
      ? moment(intialPayload?.flightQueries?.[1]?.departureDate)
      : multiCityData && isFromLisitng
      ? moment(multiCityData[0].date).add(3, "days")
      : moment().add(3, "days")
  );
  const [currentTab, setCurrentTab] = useState(
    selectedTab && isFromLisitng ? selectedTab : "return"
  );

  useEffect(() => {
    if (isFromLisitng) {
      setSelectedFlightTab(currentTab);
    }
  }, [currentTab]);

  const [flightDropDown, setFlightDropDown] = useState(
    selectedTab && isFromLisitng
      ? selectedTab.charAt(0).toUpperCase() + selectedTab.slice(1)
      : "Return"
  );

  const [openReturnPicker, setOpenReturnPicker] = useState(false);

  const multiCityRef = useRef(null);
  const updateSize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    if (window.innerWidth <= 991) {
      setIsTablet(true);
    } else {
      setIsTablet(false);
    }
  };

  useEffect(() => {
    if (!isFromLisitng) {
      window.localStorage.removeItem("currentTab");
      window.localStorage.removeItem("origin");
      window.localStorage.removeItem("destination");
      window.localStorage.removeItem("multiCityForm");
      window.localStorage.removeItem("travellingInfo");
      window.localStorage.removeItem("flightPayload");
      window.localStorage.removeItem("selectedLegs");
      window.localStorage.removeItem("aircraftOptions");
      window.localStorage.removeItem("validatePayload");
      window.localStorage.removeItem("validateKeyNumber");
    } else if (
      window.localStorage.getItem("currentTab") !== "multicity" &&
      isFromLisitng
    ) {
      let multiCityData = JSON.parse(
        window.localStorage.getItem("multiCityForm")
      );
      multiCityData?.map((ele) => {
        ele.from = { value: origin.value, id: origin.id };
        ele.to = { value: destination.value, id: destination.id };
        ele.date = moment(departureDate);
      });
      window.localStorage.setItem(
        "multiCityForm",
        JSON.stringify(multiCityData)
      );
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("resize", () => {
      setTimeout(() => {
        updateSize();
      }, 50);
    });
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  const [showCalendar, setShowCalendar] = useState(false);
  const [showMultiCityModalCalendar, setShowMultiCityModalCalendar] =
    useState(false);

  const [showReturnCalendarModal, setReturnShowCalendarModal] = useState(false);
  const [searchOptions, setSearchOptions] = useState([]);
  const [searchDestinationOptions, setSearchDestinationOptions] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 991);
  const defaultOpt = [];
  const [showOriginErr, setShowOriginErr] = useState("");
  const [showDestinationErr, setShowDestinationErr] = useState("");
  const [selectedOption, setSelectedOption] = useState(
    origin && isFromLisitng
      ? origin
      : multiCityData && isFromLisitng
      ? { ...multiCityData[0].from }
      : { value: "", id: "" }
  );
  const [selectedDestinationOption, setSelectedDestinationOption] = useState(
    destination && isFromLisitng
      ? destination
      : multiCityData && isFromLisitng
      ? { ...multiCityData[0].to }
      : {
          value: "",
          id: "",
        }
  );

  const [toggleSearch, setToggleSearch] = useState(false);
  const [toggleDestinationSearch, setDestinationToggleSearch] = useState(false);
  const [directFlight, setDirectFlight] = useState(false);
  const [showMultiCity, setShowMultiCity] = useState(false);
  const [multiCityForm, setMultiCityForm] = useState(
    multiCityData && isFromLisitng
      ? multiCityData.map((ele) => ({
          ...ele,
          date: moment(ele.date),
          from: ele.from,
          to: ele.to,
        }))
      : [
          {
            from: { value: "", id: "" },
            to: { value: "", id: "" },
            date: moment(),
          },
        ]
  );
  const [showMultiCityErr, setShowMultiCityErr] = useState(false);
  const [travelingInfo, setTravelingInfo] = useState(
    travelingdata && isFromLisitng
      ? { ...travelingdata }
      : {
          adultCount: 1,
          childCount: 0,
          infantCount: 0,
          classType: 0,
          cabinClass: "Y",
          class: "economy",
        }
  );

  const [showDropdown, setShowDropdown] = useState(
    isFromLisitng ? false : true
  );
  // useEffect(() => {
  //   if (toggleSearch || toggleDestinationSearch) {
  //     window.scrollTo(0, 0);
  //     setTimeout(function () {
  //       mobileAutocompleteRef.current.input.focus()
  //     }, 1000)
  //   }
  // }, [toggleSearch, toggleDestinationSearch])
  const currentGeoLocation =
    window.localStorage.getItem("currentGeoLocation") &&
    JSON.parse(window.localStorage.getItem("currentGeoLocation"));

  const dateFormat = currentGeoLocation === "US" ? "MM/DD/YYYY" : "DD/MM/YYYY";

  const searchLoading = useSelector(
    (state) => state.commonReducer.searchLoading
  );

  const autoCompleteFlightFromData = useSelector(
    (state) => state.commonReducer.autoCompleteFlightFromData
  );

  const autoCompleteFlightToData = useSelector(
    (state) => state.commonReducer.autoCompleteFlightToData
  );

  const returnDateRef = useRef();

  useEffect(() => {
    if (autoCompleteFlightFromData?.data?.length) {
      setSearchOptions([
        ...defaultOpt,
        {
          label: "Searched Options",
          options: autoCompleteFlightFromData?.data?.map((ele) =>
            renderItem(ele)
          ),
          key: "4",
        },
      ]);
    } else {
      setSearchOptions(defaultOpt);
    }
  }, [autoCompleteFlightFromData]);

  useEffect(() => {
    if (autoCompleteFlightToData?.data?.length) {
      setSearchDestinationOptions([
        ...defaultOpt,
        {
          label: "Searched Options",
          options: autoCompleteFlightToData?.data?.map((ele) =>
            renderItem(ele)
          ),
          key: "4",
        },
      ]);
    } else {
      setSearchDestinationOptions(defaultOpt);
    }
  }, [autoCompleteFlightToData]);

  useEffect(() => {
    setShowOriginErr("");
    setShowDestinationErr("");
    setShowMultiCityErr("");
  }, [currentTab]);

  const onDatesChange = (date, dateString, field) => {
    if (field === "departureDate") {
      setMultiCityForm([
        {
          ...multiCityForm[0],
          date: moment(date),
        },
      ]);
      setDepartureDate(date);
      !isMobile && setOpenReturnPicker(true);
      if (returnDateRef.current && !isMobile) returnDateRef?.current?.focus();
      if (moment(date).isAfter(moment(returnDate))) {
        setReturnDate(date);
      }
    } else if (field === "returnDate") {
      setReturnDate(date);
      setOpenReturnPicker(false);
    }
  };

  const renderItem = (ele) => ({
    value: ele.label,
    id: ele.id ? ele.id : "",
    allAirport: ele.allAirport ? true : false,
    label: (
      <div className={`flight-name-title ${ele.is_child ? "child-ele" : ""}`}>
        <div className="flight-icon">
          {ele.is_child ? (
            <BsArrowReturnRight color="#716834" size={20} />
          ) : (
            <MdFlight color="#716834" size={20} />
          )}
        </div>
        <div className="flight-content">
          <h5>{ele.label}</h5>
          <h6>{`${ele.city}${ele.state_code ? ", " + ele.state_code : ""}, ${
            ele.countryName
          }`}</h6>
        </div>
      </div>
    ),
  });

  const handleSearch = (query, option) => {
    if (query !== "") {
      setShowOriginErr("");
      setShowMultiCityErr(false);
    }
    if (query.length > 2) {
      setSelectedOption(query);
      dispatch(commonActions.flightFromSearchAutoComplete(query));
    }
  };

  const handleDestinationSearch = (query) => {
    if (query !== "") {
      setShowOriginErr("");
      setShowMultiCityErr(false);
    }
    if (query.length > 2) {
      setSelectedDestinationOption(query);
      dispatch(commonActions.flightToSearchAutoComplete(query));
    }
  };

  const debounceFnc = useCallback(debounce(handleSearch, 350), []);

  const debounceDestinationFnc = useCallback(
    debounce(handleDestinationSearch, 350),
    []
  );

  const openDropDown = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (isFromLisitng) setShowDropdown(!showDropdown);
  };
  const closeDropdown = (event, field) => {
    window.localStorage.setItem("currentTab", field);
    setCurrentTab(field);
    setFlightDropDown(field.charAt(0).toUpperCase() + field.slice(1));
    if (isFromLisitng) setShowDropdown(false);
  };

  const openMultiCity = (event) => {
    window.localStorage.setItem("currentTab", "multicity");
    setCurrentTab("multicity");
    setFlightDropDown(
      "multicity".charAt(0).toUpperCase() + "multicity".slice(1)
    );
    setShowMultiCity(true);
    closeDropdown(event, "multicity");
  };
  const closeMultiCityModal = () => {
    setSelectedOption(multiCityForm[0].from);
    setSelectedDestinationOption(multiCityForm[0].to);
    setDepartureDate(moment(multiCityForm[0].date));
    setShowMultiCity(false);
    setShowDropdown(false);
  };

  const onSelect = (value, option) => {
    if (
      multiCityForm[0]?.from?.value === "" ||
      multiCityForm[0]?.to?.value === ""
    ) {
      setMultiCityForm([
        {
          from: { value: value, id: option.id },
          to: {
            value: selectedDestinationOption.value,
            id: selectedDestinationOption.id,
          },
          date: moment(departureDate),
        },
      ]);
    }
    setSelectedOption({
      value: value,
      id: option.id,
      allAirport: option.allAirport,
    });
    setShowOriginErr(false);
    if (isMobile) {
      setToggleSearch(false);
      setDestinationToggleSearch(true);
    }
  };

  const onDestinationSelect = (value, option) => {
    if (
      multiCityForm[0]?.from?.value === "" ||
      multiCityForm[0]?.to?.value === ""
    ) {
      setMultiCityForm([
        {
          from: { value: selectedOption.value, id: selectedOption.id },
          to: { value: value, id: option.id },
          date: moment(departureDate),
        },
      ]);
    }
    setSelectedDestinationOption({
      value: value,
      id: option.id,
      allAirport: option.allAirport,
    });
    setShowDestinationErr(false);
    if (isMobile) {
      setDestinationToggleSearch(false);
    }
  };

  const dropDownRef = useRef(null);

  const onClickHandler = () => {
    dispatch({ type: "UPDATE_FLIGHT_FILTERS", payload: {} });

    if (currentTab === "multicity") {
      const emptyField = multiCityForm.find(
        (ele) => !ele.from?.id || !ele.to?.id
      );

      if (!isEmpty(emptyField)) {
        setShowMultiCityErr(true);
        return;
      }
    } else {
      if (!selectedOption.value && !selectedDestinationOption.value) {
        setShowOriginErr("Please Enter Location");
        setShowDestinationErr("Please Enter Location");
        return;
      }
      if (!selectedOption.value) {
        setShowOriginErr("Please Enter Location");
        return;
      }
      if (!selectedDestinationOption.value) {
        setShowDestinationErr("Please Enter Location");
        return;
      }
    }

    let payload = {
      adults: travelingInfo.adultCount,
      children: travelingInfo.childCount,
      infants: travelingInfo.infantCount,
      currency: currency.isoCode,
      cabinClass: travelingInfo.cabinClass,
      paginate: {
        page: 1,
        perPage: 15,
      },
      sort: {
        field: "price",
        order: "asc",
        label: "Price (Low to High)",
      },
      filters: {},
    };
    if (selectedOption.allAirport || selectedDestinationOption.allAirport) {
      payload.allAirport = true;
    }
    if (currentTab === "return") {
      //conditinally updating payload if trip type is Roundtrip
      payload.searchType = "round-trip";
      const flightQueries = [
        {
          origin: selectedOption.id,
          destination: selectedDestinationOption.id,
          departureDate: moment(departureDate).format("YYYY-MM-DD"),
        },
        {
          origin: selectedDestinationOption.id,
          destination: selectedOption.id,
          departureDate: moment(returnDate).format("YYYY-MM-DD"),
        },
      ];
      payload.flightQueries = flightQueries;
      window.localStorage.setItem("origin", JSON.stringify(selectedOption));
      window.localStorage.setItem(
        "destination",
        JSON.stringify(selectedDestinationOption)
      );
    } else if (currentTab === "oneway") {
      //conditionally updating payload if trip type is One-Way
      payload.searchType = "one-way";

      const flightQueries = [
        {
          origin: selectedOption.id,
          originLabel: selectedOption.label,
          destination: selectedDestinationOption.id,
          destinationName: selectedDestinationOption.label,
          departureDate: moment(departureDate).format("YYYY-MM-DD"),
        },
      ];
      payload.flightQueries = flightQueries;
      window.localStorage.setItem("origin", JSON.stringify(selectedOption));
      window.localStorage.setItem(
        "destination",
        JSON.stringify(selectedDestinationOption)
      );
    }
    if (currentTab === "multicity") {
      //conditionally updating payload if trip type is Multi-City
      payload.searchType = "multi-city";
      const flightQueries = multiCityForm.map((ele) => {
        return {
          origin: ele?.from?.id,
          destination: ele?.to?.id,
          departureDate: moment(ele.date).format("YYYY-MM-DD"),
        };
      });
      payload.flightQueries = flightQueries;
    }
    // if (directFlight) payload.filters = { stops: [0] };
    //And finally we are storing payload in local storage we will use this for searching flight action
    window.localStorage.setItem("flightPayload", JSON.stringify(payload));
    window.localStorage.setItem("multiCityForm", JSON.stringify(multiCityForm));
    window.localStorage.setItem(
      "travellingInfo",
      JSON.stringify(travelingInfo)
    );

    dispatch(commonActions.flightAutoCompleteReset());
    if (isFromLisitng) {
      dispatch(flightActions.searchFlightAction(payload));
    } else {
      navigateTo();
    }
  };

  function navigateTo() {
    return navigate("/flight-search-result");
  }
  const handleExchangePlace = () => {
    setSelectedDestinationOption(selectedOption);
    setSelectedOption(selectedDestinationOption);
  };
  //////////   flight search acition click end ///////////

  const handleAddForm = () => {
    const newForm = [
      ...multiCityForm,
      {
        from: multiCityForm[multiCityForm.length - 1].to,
        to: { value: "", id: "" },
        date: moment(multiCityForm[multiCityForm.length - 1].date),
      },
    ];
    setMultiCityForm([...newForm]);
  };

  const handleRemoveForm = (e, index) => {
    e.preventDefault();
    const newForm = multiCityForm;
    newForm.splice(index, 1);
    setMultiCityForm([...newForm]);
  };

  const onMultiCityFromSelect = (value, option, index) => {
    const oldValues = multiCityForm;
    oldValues[index].from = { value: value, id: option.id };
    setMultiCityForm([...oldValues]);
  };

  const onMultiCityToSelect = (value, option, index) => {
    const oldValues = multiCityForm;
    oldValues[index].to = { value: value, id: option.id };
    setMultiCityForm([...oldValues]);
    window.localStorage.setItem("multiCityForm", JSON.stringify(oldValues));
  };

  const onMultiCityDatesChange = (date, index) => {
    intialPayload.departureDate = date;
    window.localStorage.setItem("flightPayload", JSON.stringify(intialPayload));
    const oldValues = multiCityForm;
    oldValues[index].date = date;
    setMultiCityForm([...oldValues]);
    window.localStorage.setItem("multiCityForm", JSON.stringify(oldValues));
  };

  // search api call for multy city
  const searchMultiCity = () => {
    const multiCityForm = JSON.parse(
      window.localStorage.getItem("multiCityForm")
    );
    closeMultiCityModal();
    multiCityForm.map((ele) => {
      ele.origin = ele.from.id;
      ele.destination = ele.to.id;
      ele.departureDate = moment(ele.date).format("YYYY-MM-DD");
      delete ele.from;
      delete ele.to;
      delete ele.date;
    });
    let payload = {
      adults: travelingdata.adultCount,
      children: travelingdata.childCount,
      infants: travelingdata.infantCount,
      currency: currency.isoCode,
      paginate: {
        page: 1,
        perPage: 15,
      },
      sort: {
        field: "price",
        order: "asc",
        label: "Price (Low to High)",
      },
      filters: {},
      flightQueries: multiCityForm,
      searchType: "multi-city",
      cabinClass: intialPayload.cabinClass,
    };
    window.scroll(0, 0);
    dispatch(flightActions.searchFlightAction(payload)); // multicity search
  };

  const travelersCount =
    travelingInfo.adultCount +
    travelingInfo.childCount +
    travelingInfo.infantCount;

  const cabinClass = cabinClassOptions.find(
    (cabin) => cabin.value == travelingInfo.classType
  );

  const handleMultiCityExchangePlace = (index) => {
    const newForm = cloneDeep(multiCityForm);
    newForm[index].from = multiCityForm[index].to;
    newForm[index].to = multiCityForm[index].from;
    setMultiCityForm(newForm);
  };

  const handleOpenMultiCityModal = () => {
    if (isFromLisitng && currentTab === "multicity") {
      multiCityRef.current.click();
    } else {
      return;
    }
  };

  function handleClickOutside(event) {
    if (
      dropDownRef.current &&
      !dropDownRef.current.contains(event.target) &&
      isFromLisitng
    ) {
      setShowDropdown(false);
    }
  }

  const handleMultiCityAutoSearch = (query, index, field) => {
    const oldValues = multiCityForm;
    oldValues[index][field] = { value: query, id: null };
    setMultiCityForm([...oldValues]);

    field === "from" ? debounceFnc(query) : debounceDestinationFnc(query);
  };

  const showSearchFilter = () => {
    setIsTablet(false);
  };

  const closeSearchFilter = () => {
    setIsTablet(true);
  };

  return (
    <>
      <form>
        <Tabs
          className="flight-tab-wrap"
          id="flightsTab"
          defaultIndex={isFromLisitng && currentTab === "multicity" ? 1 : 0}
        >
          {!isSearchView && (
            <div className="landing-info-data-wrap">
              <div className="landing-info-data">
                <h3>
                  <span>Book Flights with All Global Carriers.</span>
                  <span>
                    and over 140 Low Cost Carriers from around the World.
                  </span>
                  <span>
                    From Economy, Premium Economy, Business and First Class.
                  </span>
                </h3>
              </div>
            </div>
          )}

          <div className="flight-tab-content-wrap">
            <div
              className="row no-gutters"
              style={
                !isTablet || isFromLisitng === undefined
                  ? {}
                  : { display: "none" }
              }
            >
              <div className="col flight-types">
                <div className="selectFlightType">
                  <Button id="flightDropdownBtn" onClick={openDropDown}>
                    {flightDropDown}
                    <HiOutlineChevronDown className="dropwDownSVGarrow" />
                  </Button>
                </div>
                <div
                  id="selectFlightOptions"
                  className={`react-tabs__tab-list open`}
                  style={{ display: showDropdown ? "block" : "none" }}
                  ref={dropDownRef}
                >
                  <TabList>
                    <Tab>
                      <button
                        type="button"
                        className={`react-tabs__tab ${
                          currentTab === "return"
                            ? "react-tabs__tab--selected"
                            : ""
                        }`}
                        onClick={(e) => closeDropdown(e, "return")}
                      >
                        Round-Trip
                      </button>
                      <button
                        type="button"
                        className={`react-tabs__tab ${
                          currentTab === "oneway"
                            ? "react-tabs__tab--selected"
                            : ""
                        }`}
                        onClick={(e) => closeDropdown(e, "oneway")}
                      >
                        One-Way
                      </button>
                      <button
                        disabled={true}
                        type="button"
                        className="react-tabs__tab comingSoon"
                        style={{ position: "relative" }}
                      >
                        Multi City <span>Coming Soon</span>
                      </button>
                    </Tab>
                    {/* <Tab>
                    <button
                      type="button"
                      className={`react-tabs__tab ${
                        currentTab === "multicity"
                          ? "react-tabs__tab--selected"
                          : ""
                      }`}
                      ref={multiCityRef}
                      onClick={(e) => openMultiCity(e)}
                    >
                      Multi-City
                    </button>
                  </Tab> */}
                  </TabList>
                </div>
              </div>

              <div className="col travelerNclass">
                <div className="dropdownWrap ">
                  <Dropdown
                    className="btnSml"
                    setTravelingInfo={setTravelingInfo}
                    travelingInfo={travelingInfo}
                    isFromLisitng={isFromLisitng}
                    onClickHandler={onClickHandler}
                  />
                </div>

                {/* <div className="checkbox-content">
                  <label htmlFor="toggle">
                    <input
                      type="checkbox"
                      id="toggle"
                      onChange={(e) => setDirectFlight(e.target.checked)}
                    />
                    <span className="checkmark"> </span>
                    <span className="box">Direct Flights only</span>
                  </label>
                </div> */}
              </div>
            </div>

            <div
              className="tab-content showDropdown"
              id={showMultiCity ? "multiCityOpen" : ""}
            >
              <TabPanel
                className="return-flight-tab"
                onClick={() => handleOpenMultiCityModal()}
              >
                {isTablet && isFromLisitng && (
                  <div>
                    <div
                      className="mobileSearchBlock  "
                      onClick={showSearchFilter}
                    >
                      <span className="fa fa-search"></span>
                      <div className="selectSearchCnt">
                        <h4>
                          {selectedOption?.value}
                          <span className="arrow">
                            {" "}
                            <img src={doubleArrow} />{" "}
                          </span>
                          {selectedDestinationOption?.value}
                        </h4>
                        <p>
                          {moment(departureDate).format(dateFormat)} —{" "}
                          {moment(returnDate).format(dateFormat)}{" "}
                          <i className="fa fa-circle"></i> {travelersCount}{" "}
                          Person
                          <i className="fa fa-circle"></i> {cabinClass?.label}
                        </p>
                      </div>
                      <i className="fa fa-angle-down"></i>
                    </div>
                  </div>
                )}

                {(!isTablet || isFromLisitng === undefined) && (
                  <div
                    id="oneWayFlightTab"
                    className="startEndLocation filterSecondRow"
                  >
                    <div className="startNendWrap">
                      <div className="inputStyle startLocation">
                        <span className="icon-location-on"></span>
                        <div className="flight-input-field">
                          <AutoComplete
                            onSelect={onSelect}
                            filterOption={false}
                            notFoundContent={
                              searchLoading
                                ? "Searching ..."
                                : "Type to search ..."
                            }
                            getPopupContainer={(trigger) =>
                              trigger.parentElement
                            }
                            value={selectedOption?.value || selectedOption}
                            dropdownClassName="flying-from-search-dropdown custom-search-dropdown"
                            options={searchOptions}
                            onSearch={(query, option) => {
                              setSelectedOption(query);
                              debounceFnc(query, option);
                            }}
                            virtual={false}
                            // onFocus={() => isMobile && setToggleSearch(true)}
                            onFocus={() =>
                              isMobile &&
                              setTimeout(function () {
                                setToggleSearch(true);
                              })
                            }
                          >
                            {/* <div className="flight-input-field"> */}
                            <Input
                              size="small"
                              placeholder="Flying from"
                              allowClear={{
                                clearIcon: (
                                  <span className="icon-close flight-input-close-btn"></span>
                                ),
                              }}
                            />
                            {/* </div> */}
                          </AutoComplete>

                          {showOriginErr && (
                            <p className="errorMessage">{showOriginErr}</p>
                          )}

                          {toggleSearch && (
                            <div className="sort-detail-popup mobilePopup">
                              <Link
                                to="#"
                                className="closePopup"
                                onClick={() => setToggleSearch(false)}
                              >
                                <span className="icon-close"></span>
                              </Link>
                              <h4 className="inputPopTitle">
                                Where are you flying from?
                              </h4>
                              <div className="inputInWrap">
                                <span className="icon-location-on"></span>
                                <AutoComplete
                                  getPopupContainer={(trigger) =>
                                    trigger.parentElement
                                  }
                                  onSelect={onSelect}
                                  filterOption={false}
                                  notFoundContent={
                                    searchLoading
                                      ? "Searching ..."
                                      : "Type to search ..."
                                  }
                                  value={
                                    selectedOption?.value || selectedOption
                                  }
                                  dropdownClassName="flying-from-search-dropdown custom-search-dropdown"
                                  options={searchOptions}
                                  onSearch={(query, option) => {
                                    setSelectedOption(query);
                                    debounceFnc(query, option);
                                  }}
                                  open={true}
                                  autoFocus={true}
                                >
                                  <Input
                                    size="large"
                                    className="autoCompleteInput"
                                    placeholder="Search by city or airport"
                                    allowClear={
                                      !searchLoading && {
                                        clearIcon: (
                                          <span className="icon-close flight-input-close-btn"></span>
                                        ),
                                      }
                                    }
                                  />
                                </AutoComplete>
                              </div>

                              {showOriginErr && (
                                <p style={{ color: "red" }}>{showOriginErr}</p>
                              )}
                            </div>
                          )}
                        </div>
                      </div>

                      <Button
                        className="changeBtn"
                        type="button"
                        onClick={() =>
                          currentTab !== "multicity"
                            ? handleExchangePlace()
                            : {}
                        }
                      >
                        <span className="icon-swap-horiz"></span>
                      </Button>

                      <div className="inputStyle">
                        <span className="icon-location-on"></span>
                        <div className="flight-input-field">
                          <AutoComplete
                            onSelect={onDestinationSelect}
                            filterOption={false}
                            notFoundContent={
                              searchLoading
                                ? "Searching ..."
                                : "Type to search ..."
                            }
                            // allowClear
                            getPopupContainer={(trigger) =>
                              trigger.parentElement
                            }
                            value={
                              selectedDestinationOption?.value ||
                              selectedDestinationOption
                            }
                            popupClassName="flying-from-search-dropdown custom-search-dropdown"
                            options={searchDestinationOptions}
                            onSearch={(query, option) => {
                              setSelectedDestinationOption(query);
                              debounceDestinationFnc(query, option);
                            }}
                            virtual={false}
                            onFocus={() =>
                              isMobile &&
                              setTimeout(function () {
                                setDestinationToggleSearch(true);
                              })
                            }
                          >
                            <Input
                              size="small"
                              placeholder="Flying to"
                              allowClear={{
                                clearIcon: (
                                  <span className="icon-close flight-input-close-btn"></span>
                                ),
                              }}
                            />
                          </AutoComplete>

                          {showDestinationErr && (
                            <p className="errorMessage">{showDestinationErr}</p>
                          )}

                          {toggleDestinationSearch && (
                            <div className="sort-detail-popup mobilePopup">
                              <Link
                                to="#"
                                className="closePopup"
                                onClick={() =>
                                  setDestinationToggleSearch(false)
                                }
                              >
                                <span className="icon-close"></span>
                              </Link>

                              <h4 className="inputPopTitle">
                                Where are you flying to?
                              </h4>
                              <div className="inputInWrap">
                                <span className="icon-location-on"></span>
                                <AutoComplete
                                  getPopupContainer={(trigger) =>
                                    trigger.parentElement
                                  }
                                  autoFocus={true}
                                  // allowClear
                                  onSelect={onDestinationSelect}
                                  // filterOption={false}
                                  open={true}
                                  notFoundContent={
                                    searchLoading
                                      ? "Searching ..."
                                      : "Type to search ..."
                                  }
                                  value={
                                    selectedDestinationOption?.value ||
                                    selectedDestinationOption
                                  }
                                  dropdownClassName="flying-from-search-dropdown custom-search-dropdown"
                                  options={searchDestinationOptions}
                                  onSearch={(query, option) => {
                                    setSelectedDestinationOption(query);
                                    debounceDestinationFnc(query, option);
                                  }}
                                >
                                  <Input
                                    className="autoCompleteInput"
                                    size="small"
                                    placeholder="Search by city or airport"
                                    allowClear={
                                      !searchLoading && {
                                        clearIcon: (
                                          <span className="icon-close flight-input-close-btn"></span>
                                        ),
                                      }
                                    }
                                  />
                                </AutoComplete>
                              </div>

                              {showDestinationErr && (
                                <p style={{ color: "red" }}>
                                  {showDestinationErr}
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="startEndDate">
                      <div className="dateInWrap">
                        <span className="icon-invitation"></span>
                        <div className="date-content">
                          <Space
                            direction="vertical"
                            className="custom-single-date-picker"
                          >
                            {!showCalendar && (
                              <DatePicker
                                getPopupContainer={(trigger) =>
                                  trigger.parentElement
                                }
                                inputReadOnly={true}
                                bordered={false}
                                suffixIcon=""
                                value={departureDate}
                                defaultValue={moment()}
                                format={dateFormat}
                                onChange={(date, dateString) =>
                                  onDatesChange(
                                    date,
                                    dateString,
                                    "departureDate"
                                  )
                                }
                                onClick={() => setOpenReturnPicker(false)}
                                showToday={false}
                                onFocus={() =>
                                  isMobile && setShowCalendar(true)
                                }
                                disabledDate={(date) =>
                                  date < moment().endOf("day")
                                }
                              />
                            )}
                          </Space>

                          {showCalendar && isMobile && (
                            <div className="sort-detail-popup mobilePopup">
                              <Link
                                to="#"
                                className="closePopup"
                                onClick={() => setShowCalendar(false)}
                              >
                                <span className="icon-close"></span>
                              </Link>
                              <h4 className="inputPopTitle">Date Range</h4>

                              <DatePicker
                                getPopupContainer={(trigger) =>
                                  trigger.parentElement
                                }
                                inputReadOnly={true}
                                open={showCalendar}
                                bordered={false}
                                suffixIcon=""
                                value={departureDate}
                                defaultValue={moment()}
                                format={dateFormat}
                                onChange={(date, dateString) => {
                                  onDatesChange(
                                    date,
                                    dateString,
                                    "departureDate"
                                  );
                                  setShowCalendar(false);
                                  if (currentTab === "return") {
                                    setReturnShowCalendarModal(true);
                                  }
                                }}
                                showToday={false}
                                disabledDate={(date) =>
                                  date < moment().endOf("day")
                                }
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      {currentTab === "return" && (
                        <div className="returnDateWrap">
                          <div className="icon-arrow"></div>
                          <div className="dateInWrap">
                            <span className="icon-invitation"></span>
                            <div className="date-content">
                              <Space direction="vertical">
                                {!showReturnCalendarModal && (
                                  <DatePicker
                                    getPopupContainer={(trigger) =>
                                      trigger.parentElement
                                    }
                                    inputReadOnly={true}
                                    open={openReturnPicker}
                                    ref={returnDateRef}
                                    bordered={false}
                                    suffixIcon=""
                                    value={returnDate}
                                    defaultValue={moment().add(2, "days")}
                                    format={dateFormat}
                                    onChange={(date, dateString) =>
                                      onDatesChange(
                                        date,
                                        dateString,
                                        "returnDate"
                                      )
                                    }
                                    onClick={() => setOpenReturnPicker(true)}
                                    onBlur={() => setOpenReturnPicker(false)}
                                    showToday={false}
                                    onFocus={() =>
                                      isMobile &&
                                      setReturnShowCalendarModal(true)
                                    }
                                    disabledDate={(date) =>
                                      date < moment().subtract(1, "days") ||
                                      date < departureDate.endOf("day")
                                    }
                                  />
                                )}
                              </Space>

                              {showReturnCalendarModal && (
                                <div className="sort-detail-popup mobilePopup">
                                  <Link
                                    to="#"
                                    className="closePopup"
                                    onClick={() =>
                                      setReturnShowCalendarModal(false)
                                    }
                                  >
                                    <span className="icon-close"></span>
                                  </Link>
                                  <h4 className="inputPopTitle">Date Range</h4>

                                  <DatePicker
                                    getPopupContainer={(trigger) =>
                                      trigger.parentElement
                                    }
                                    inputReadOnly={true}
                                    open={true}
                                    bordered={false}
                                    suffixIcon=""
                                    value={returnDate}
                                    defaultValue={moment().add(2, "days")}
                                    format={dateFormat}
                                    onChange={(date, dateString) => {
                                      onDatesChange(
                                        date,
                                        dateString,
                                        "returnDate"
                                      );
                                      setReturnShowCalendarModal(false);
                                    }}
                                    showToday={false}
                                    disabledDate={(date) =>
                                      date < moment().subtract(1, "days") ||
                                      date < departureDate.endOf("day")
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="searchBtnWrap">
                      {isFromLisitng && (
                        <button
                          onClick={closeSearchFilter}
                          className="closeSearchFilter tabletShow"
                        >
                          Close <i className="fa fa-angle-up"></i>
                        </button>
                      )}
                      <Button
                        type="button"
                        onClick={onClickHandler}
                        className="btn"
                      >
                        Search
                      </Button>
                    </div>
                  </div>
                )}
              </TabPanel>

              <TabPanel className="multiCityTab">
                <div
                  id="multiCityListModal"
                  className={showMultiCity ? "openModal" : ""}
                >
                  <div className="listModallWrap">
                    <div className="listModalOverlay"></div>
                    <div className="modalContent">
                      <Button onClick={closeMultiCityModal} className="close">
                        <MdClose size={28} />
                      </Button>

                      {multiCityForm.length && showMultiCity
                        ? multiCityForm.map((ele, index) => {
                            return (
                              <div
                                className="multiCityFlightTab startEndLocation filterSecondRow"
                                key={`${index}_multicity`}
                              >
                                <div className="startNendWrap">
                                  <div className="inputStyle startLocation">
                                    <div className="icon-location-on"></div>
                                    <div>
                                      <AutoComplete
                                        dropdownClassName="flying-from-search-dropdown custom-search-dropdown"
                                        onSelect={(value, option) => {
                                          onMultiCityFromSelect(
                                            value,
                                            option,
                                            index
                                          );
                                        }}
                                        allowClear
                                        filterOption={false}
                                        notFoundContent={
                                          searchLoading
                                            ? "Searching ..."
                                            : "Type to search ..."
                                        }
                                        getPopupContainer={(trigger) =>
                                          trigger.parentElement
                                        }
                                        value={ele?.from?.value}
                                        options={searchOptions}
                                        onFocus={() => {
                                          isMobile &&
                                            setToggleModalForMultiCityOrigin(
                                              true
                                            );
                                        }}
                                        onClick={() => {
                                          isMobile &&
                                            setToggleModalForMultiCityOrigin(
                                              true
                                            );
                                        }}
                                        onSearch={(query) => {
                                          !isMobile
                                            ? handleMultiCityAutoSearch(
                                                query,
                                                index,
                                                "from"
                                              )
                                            : setToggleModalForMultiCityOrigin(
                                                true
                                              );
                                        }}
                                        virtual={false}
                                      >
                                        <Input
                                          size="large"
                                          placeholder="Flying from"
                                        />
                                      </AutoComplete>

                                      {showMultiCityErr && !ele.from.id && (
                                        <p className="errorMessage">
                                          Please Enter Location
                                        </p>
                                      )}

                                      {toggleModalForMultiCityOrigin && (
                                        <div className="sort-detail-popup mobilePopup">
                                          <Link
                                            to="#"
                                            className="closePopup"
                                            onClick={() =>
                                              setToggleModalForMultiCityOrigin(
                                                false
                                              )
                                            }
                                          >
                                            <span className="icon-close"></span>
                                          </Link>
                                          <h4 className="inputPopTitle">
                                            Where are you flying from?
                                          </h4>
                                          <div className="inputInWrap">
                                            <span className="icon-location-on"></span>
                                            <AutoComplete
                                              getPopupContainer={(trigger) =>
                                                trigger.parentElement
                                              }
                                              autoFocus={true}
                                              allowClear
                                              onSelect={(value, option) =>
                                                onMultiCityFromSelect(
                                                  value,
                                                  option,
                                                  index
                                                )
                                              }
                                              filterOption={false}
                                              open="true"
                                              notFoundContent={
                                                searchLoading
                                                  ? "Searching ..."
                                                  : "Type to search ..."
                                              }
                                              value={ele?.from?.value}
                                              dropdownClassName="flying-from-search-dropdown custom-search-dropdown"
                                              options={searchOptions}
                                              onSearch={(query) => {
                                                handleMultiCityAutoSearch(
                                                  query,
                                                  index,
                                                  "from"
                                                );
                                              }}
                                              virtual={false}
                                            >
                                              <Input
                                                size="large"
                                                className="autoCompleteInput"
                                                placeholder="Search by city or airport"
                                              />
                                            </AutoComplete>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  <Button
                                    type="button"
                                    onClick={() =>
                                      handleMultiCityExchangePlace(index)
                                    }
                                    className="ant-btn ant-btn-button changeBtn"
                                  >
                                    <span className="icon-swap-horiz"></span>
                                  </Button>

                                  <div className="inputStyle">
                                    <div className="icon-location-on"></div>
                                    <div>
                                      <AutoComplete
                                        onSelect={(value, option) =>
                                          onMultiCityToSelect(
                                            value,
                                            option,
                                            index
                                          )
                                        }
                                        allowClear
                                        filterOption={false}
                                        notFoundContent={
                                          searchLoading
                                            ? "Searching ..."
                                            : "Type to search ..."
                                        }
                                        getPopupContainer={(trigger) =>
                                          trigger.parentElement
                                        }
                                        value={ele.to.value}
                                        options={searchDestinationOptions}
                                        onFocus={() => {
                                          isMobile &&
                                            setToggleModalForMultiCityDestination(
                                              true
                                            );
                                        }}
                                        onClick={() => {
                                          isMobile &&
                                            setToggleModalForMultiCityOrigin(
                                              true
                                            );
                                        }}
                                        onSearch={(query) => {
                                          !isMobile &&
                                            handleMultiCityAutoSearch(
                                              query,
                                              index,
                                              "to"
                                            );
                                        }}
                                        virtual={false}
                                        dropdownClassName="flying-from-search-dropdown custom-search-dropdown"
                                      >
                                        <Input
                                          size="small"
                                          placeholder="Flying to"
                                        />
                                      </AutoComplete>

                                      {showMultiCityErr && !ele.to.id && (
                                        <p className="errorMessage">
                                          Please Enter Location
                                        </p>
                                      )}

                                      {toggleModalForMultiCityDestination && (
                                        <div className="sort-detail-popup mobilePopup">
                                          <Link
                                            to="#"
                                            className="closePopup"
                                            onClick={() =>
                                              setToggleModalForMultiCityDestination(
                                                false
                                              )
                                            }
                                          >
                                            <span className="icon-close"></span>
                                          </Link>
                                          <h4 className="inputPopTitle">
                                            Where are you leaving to?
                                          </h4>
                                          <div className="inputInWrap">
                                            <span className="icon-location-on"></span>
                                            <AutoComplete
                                              getPopupContainer={(trigger) =>
                                                trigger.parentElement
                                              }
                                              onSelect={(value, option) =>
                                                onMultiCityToSelect(
                                                  value,
                                                  option,
                                                  index
                                                )
                                              }
                                              allowClear
                                              filterOption={false}
                                              open="true"
                                              notFoundContent={
                                                searchLoading
                                                  ? "Searching ..."
                                                  : "Type to search ..."
                                              }
                                              value={ele?.to?.value}
                                              dropdownClassName="flying-from-search-dropdown custom-search-dropdown"
                                              options={searchDestinationOptions}
                                              onSearch={(query) => {
                                                handleMultiCityAutoSearch(
                                                  query,
                                                  index,
                                                  "to"
                                                );
                                              }}
                                              virtual={false}
                                            >
                                              <Input
                                                size="large"
                                                className="autoCompleteInput"
                                                placeholder="Search by city or airport"
                                              />
                                            </AutoComplete>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="startEndDate">
                                  <div className="dateInWrap">
                                    <div className="icon-invitation"></div>
                                    <div className="date-content">
                                      <Space
                                        direction="vertical"
                                        className="custom-single-date-picker"
                                      >
                                        <DatePicker
                                          bordered={false}
                                          suffixIcon=""
                                          value={ele.date}
                                          defaultValue={ele.date}
                                          format={dateFormat}
                                          onChange={(date) =>
                                            !isMobile &&
                                            onMultiCityDatesChange(date, index)
                                          }
                                          onFocus={() =>
                                            isMobile &&
                                            setShowMultiCityModalCalendar(true)
                                          }
                                          getPopupContainer={(trigger) =>
                                            trigger.parentElement
                                          }
                                          showToday={false}
                                          disabledDate={(date) =>
                                            date < moment(ele.date).endOf("day")
                                          }
                                        />
                                      </Space>

                                      {showMultiCityModalCalendar && (
                                        <div className="sort-detail-popup mobilePopup">
                                          <Link
                                            to="#"
                                            className="closePopup"
                                            onClick={() =>
                                              setShowMultiCityModalCalendar(
                                                false
                                              )
                                            }
                                          >
                                            <span className="icon-close"></span>
                                          </Link>
                                          <h4 className="inputPopTitle">
                                            Date
                                          </h4>

                                          <DatePicker
                                            getPopupContainer={(trigger) =>
                                              trigger.parentElement
                                            }
                                            open={true}
                                            bordered={false}
                                            suffixIcon=""
                                            value={ele.date}
                                            defaultValue={moment()}
                                            format={dateFormat}
                                            onChange={(date) =>
                                              onMultiCityDatesChange(
                                                date,
                                                index
                                              )
                                            }
                                            showToday={false}
                                            disabledDate={(date) =>
                                              date <
                                              moment(ele.date).endOf("day")
                                            }
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {index > 0 && (
                                  <div className="searchBtnWrap removeBtnWrap">
                                    <button
                                      onClick={(e) =>
                                        handleRemoveForm(e, index)
                                      }
                                      className="removeRowBtn"
                                    >
                                      <MdClose size={20} />
                                    </button>{" "}
                                  </div>
                                )}

                                {index === 0 && (
                                  <div className="searchBtnWrap">
                                    <Button
                                      type="button"
                                      onClick={onClickHandler}
                                      className="btn hideInModal"
                                    >
                                      Search
                                    </Button>
                                  </div>
                                )}
                              </div>
                            );
                          })
                        : multiCityForm.map((ele, index) => {
                            if (index === 0) {
                              return (
                                <Fragment key={`${index}_multicity`}>
                                  {isTablet && isFromLisitng && (
                                    <div>
                                      <div
                                        className="mobileSearchBlock"
                                        onClick={showSearchFilter}
                                      >
                                        <span className="fa fa-search"></span>
                                        <div className="selectSearchCnt">
                                          <h4>
                                            {multiCityForm[0].from.value}{" "}
                                            <span className="arrow">
                                              <span></span>
                                              <img src={doubleArrow} />{" "}
                                            </span>
                                            {
                                              multiCityForm[
                                                multiCityForm.length - 1
                                              ].to.value
                                            }
                                          </h4>
                                          <p>
                                            {moment(
                                              multiCityForm[0].date
                                            ).format(dateFormat)}{" "}
                                            —
                                            {moment(
                                              multiCityForm[
                                                multiCityForm.length - 1
                                              ].date
                                            ).format(dateFormat)}{" "}
                                            <i className="fa fa-circle"></i>{" "}
                                            {travelersCount} Person
                                            <i className="fa fa-circle"></i>{" "}
                                            {cabinClass?.label}
                                            Adults
                                          </p>
                                        </div>
                                        <i className="fa fa-angle-down"></i>
                                      </div>
                                    </div>
                                  )}

                                  {(!isTablet ||
                                    isFromLisitng === undefined) && (
                                    <div
                                      className="multiCityFlightTab startEndLocation filterSecondRow"
                                      onClick={() => {
                                        handleOpenMultiCityModal();
                                      }}
                                    >
                                      <div className="startNendWrap">
                                        <div className="inputStyle startLocation">
                                          <span className="icon-location-on"></span>
                                          <div>
                                            <AutoComplete
                                              dropdownClassName="flying-from-search-dropdown custom-search-dropdown"
                                              onSelect={(value, option) => {
                                                onMultiCityFromSelect(
                                                  value,
                                                  option,
                                                  index
                                                );
                                              }}
                                              allowClear
                                              filterOption={false}
                                              notFoundContent={
                                                searchLoading
                                                  ? "Searching ..."
                                                  : "Type to search ..."
                                              }
                                              getPopupContainer={(trigger) =>
                                                trigger.parentElement
                                              }
                                              value={ele?.from?.value}
                                              options={searchOptions}
                                              onSearch={(query) => {
                                                handleMultiCityAutoSearch(
                                                  query,
                                                  index,
                                                  "from"
                                                );
                                              }}
                                              virtual={false}
                                            >
                                              <Input
                                                size="large"
                                                placeholder="Flying from"
                                              />
                                            </AutoComplete>
                                            {showMultiCityErr &&
                                              !ele.from.id && (
                                                <p style={{ color: "red" }}>
                                                  Please Enter Location
                                                </p>
                                              )}
                                          </div>
                                        </div>
                                        <Button
                                          onClick={() =>
                                            handleMultiCityExchangePlace(index)
                                          }
                                          type="button"
                                          className="changeBtn"
                                        >
                                          <span className="icon-swap-horiz"></span>
                                        </Button>

                                        <div className="inputStyle">
                                          <span className="icon-location-on"></span>
                                          <div>
                                            <AutoComplete
                                              onSelect={(value, option) =>
                                                onMultiCityToSelect(
                                                  value,
                                                  option,
                                                  index
                                                )
                                              }
                                              allowClear
                                              filterOption={false}
                                              notFoundContent={
                                                searchLoading
                                                  ? "Searching ..."
                                                  : "Type to search ..."
                                              }
                                              getPopupContainer={(trigger) =>
                                                trigger.parentElement
                                              }
                                              value={
                                                multiCityForm[
                                                  multiCityForm.length - 1
                                                ].to.value
                                              }
                                              options={searchDestinationOptions}
                                              onSearch={(query) => {
                                                handleMultiCityAutoSearch(
                                                  query,
                                                  index,
                                                  "to"
                                                );
                                              }}
                                              virtual={false}
                                              dropdownClassName="flying-from-search-dropdown custom-search-dropdown"
                                            >
                                              <Input
                                                size="small"
                                                placeholder="Flying to"
                                              />
                                            </AutoComplete>
                                            {showMultiCityErr && !ele.to.id && (
                                              <p style={{ color: "red" }}>
                                                Please Enter Location
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </div>

                                      <div className="startEndDate">
                                        <div className="dateInWrap">
                                          <span className="icon-invitation"></span>
                                          <div className="date-content">
                                            <Space
                                              direction="vertical"
                                              className="custom-single-date-picker"
                                            >
                                              <DatePicker
                                                getPopupContainer={(trigger) =>
                                                  trigger.parentElement
                                                }
                                                bordered={false}
                                                suffixIcon=""
                                                value={ele.date}
                                                defaultValue={ele.date}
                                                format={dateFormat}
                                                onChange={(date) =>
                                                  onMultiCityDatesChange(
                                                    date,
                                                    index
                                                  )
                                                }
                                                showToday={false}
                                                disabledDate={(date) =>
                                                  date <
                                                  moment(ele.date).endOf("day")
                                                }
                                              />
                                            </Space>
                                          </div>
                                        </div>
                                      </div>

                                      {index > 0 && (
                                        <div className="searchBtnWrap">
                                          <button
                                            onClick={(e) =>
                                              handleRemoveForm(e, index)
                                            }
                                            className="removeRowBtn"
                                          >
                                            <MdClose size={20} />
                                          </button>{" "}
                                        </div>
                                      )}

                                      {index === 0 && (
                                        <div className="searchBtnWrap">
                                          {isFromLisitng && (
                                            <button
                                              className="closeSearchFilter tabletShow"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                closeSearchFilter();
                                              }}
                                            >
                                              Close{" "}
                                              <i className="fa fa-angle-up"></i>
                                            </button>
                                          )}
                                          <Button type="button" className="btn">
                                            Search
                                          </Button>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </Fragment>
                              );
                            }
                          })}

                      <div className="addFlightBtnWrap">
                        {multiCityForm.length < 4 && (
                          <Button
                            className="addFlightBtn"
                            onClick={handleAddForm}
                          >
                            <MdOutlineAdd className="rightIcnSppace" /> Add
                            Flight
                          </Button>
                        )}

                        {isFromLisitng && (
                          <Button
                            className="searchFlightBtn"
                            onClick={() => searchMultiCity()}
                          >
                            Search
                          </Button>
                        )}
                      </div>

                      {!isFromLisitng && multiCityForm.length < 4 && (
                        <Link
                          to="#"
                          className="addFlight"
                          onClick={handleAddForm}
                        >
                          <i className="icon-add"></i> Add Another Flight
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </TabPanel>
            </div>
          </div>
        </Tabs>
      </form>
    </>
  );
}
