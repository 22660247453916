import React from "react";
import { Link } from "react-router-dom";
import {commafy} from "../../Util/util"

//price details of hotel, car and flight

function PriceDetails({ details, price, text, nextPage ,currency}) {
  return (
    <div className="centerAlign">
      <div className="topBorder">
        <div className="payWrapper tooltip-wrap">
        </div>
        {/* <span className="lightText font14">{details}</span>
        <span className="font17 darkText">{currency}{commafy(price)}</span> */}
      </div>
      <Link to={nextPage} className="btn">
        {text}
      </Link>
    </div>
  );
}

export default PriceDetails;
