
import React from 'react';

const NewExtras = (props) => {

  const {
    allExtrass,
    selectedExtrasType,
    setSelectedExtrasType
  } = props;

  // handles updates in price
  const updatePrice = (type, item) => {
    setSelectedExtrasType({ type, data: item.data });
  };

  return (
    <>
      <h5 className="content-title">Meal Plan</h5>
      {Object.entries(allExtrass)?.map(([key, ele]) => {
        return (
          <label
            key={key}
            htmlFor={key}
            className={`customRadio refundable-box ${ele?.disabled ? 'disabled' : ''} ${selectedExtrasType?.type === key ? 'checked' : ''}`}
            onClick={() => {
              if (!ele?.disabled) {
                updatePrice(key, ele);
              }
            }}
          >
            <input
              type="checkbox"
              readOnly={true}
              checked={selectedExtrasType?.type === key ? true : false}
              name={key}
            />
            <span>
              {ele?.lable}
            </span>

            {ele?.data?.board_price && (
              <label>
                + {ele?.data?.board_price_currency}
                {ele?.data?.board_price}
              </label>
            )}

            {!ele?.data?.board_price && (
              <label>--</label>
            )}
          </label>
        )
      })}

    </>
  );
};

export default NewExtras;
