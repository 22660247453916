import { times } from 'lodash';
import { FieldArray } from 'formik';
import { handleMaskedInput } from '../../Util/util';
import { commonActions } from '../../Stores/Actions';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment, useEffect, useState, useRef } from 'react';

import moment from 'moment';
import InputField from './InputField';
import CustomPhoneInput from '../../Component/PhoneInput';
import Icomoon from '../../Component/FormComponent/Icomoon';
import Checkbox from '../../Component/FormComponent/Checkbox';
import OptionButton from '../../Component/FormComponent/OptionButton';
import SelectFieldOption from '../../Component/FormComponent/SelectFieldOption.jsx';
import DateInput from '../../Component/DatePicker/DateInput';
import SimpleInputField from '../../Component/FormComponent/SimpleInputField';

function Childs(props) {
  const {
    formikProps,
    titleOption,
    ageOptions,
    nationalityOptions,
    passengersCount,
    childOrInfrant,
    travlerType,
    domesticFlight,
    // getGuestData
  } = props;

  const dispatch = useDispatch();
  const fieldArrayPropsRef = useRef(null);

  const flightsWidgetData =
    window.localStorage.getItem('flightPayload') &&
    JSON.parse(window.localStorage.getItem('flightPayload'));

  const { values, errors, touched, handleBlur, handleChange } = formikProps;

  const [dateInputFocused, setDateInputFocused] = useState({
    dob: new Array(passengersCount).fill(false),
    passportExpiryDate: new Array(passengersCount).fill(false),
  });

  const handleFocusChange = (index, focused, dateColl) => {
    setDateInputFocused((pre) => {
      let copy = { ...pre };
      copy[dateColl][index] = focused;
      return copy;
    });
  };

  const getGuestData = useSelector((state) => state.commonReducer.getGuestData);

  const handleSelectChange = (value, fieldName, formikProps) => {
    formikProps.setFieldValue(fieldName, value);
  };

  const handleDateChange = (value, fieldName, formikProps) => {
    // formikProps.setFieldValue(fieldName, moment(value)._d);
     formikProps.setFieldValue(fieldName, moment(value._d).format('YYYY-MM-DD'));
  };

  const flight =  window.localStorage.getItem('selectedLegs') && JSON.parse(window.localStorage.getItem('selectedLegs'));
  const passportMinArrivalDate = moment(flight[flight?.length - 1]?.flightLegs[flight[flight?.length - 1]?.flightLegs?.length - 1]?.arrivalDateTime)?.format('MM/DD/YYYY');


  const [status, setStatus] = useState(true);

  useEffect(() => {
    if (status) {
      setStatus(false);

      // getGuestData?.map((curr, i) => {
      //   fieldArrayPropsRef?.current?.push({
      //     title: curr?.title,
      //     gender: curr?.gender,
      //     frequentFlyerNumber: "",
      //     firstName: curr?.fname,
      //     middleName: curr?.middlename,
      //     lastName: curr?.lname,
      //     nationality: curr?.country,
      //     dob: curr.dob,
      //     passportCountry: curr?.country,
      //     phoneNumber: "",
      //     passportNumber: curr?.passport_number,
      //     passportExpireDate: `01-${curr.passport_exp_month}-${curr?.passport_exp_year}`,
      //     check: false,
      //   });
      // });

      for (let i = 0; i < childOrInfrant; i++) {
        fieldArrayPropsRef?.current?.push({
          title: '',
          gender: '',
          frequentFlyerNumber: '',
          firstName: '',
          middleName: '',
          lastName: '',
          nationality: '',
          dob: '',
          passportCountry: '',
          phoneNumber: '',
          passportNumber: '',
          passportExpireDate: '',
          check: false,
        });
      }
 
    }
  }, [getGuestData]);

  return (
    <FieldArray
      name="childs"
      render={(fieldArrayProps) => {
        const { push, remove, form } = fieldArrayProps;
        const { values, errors, handleChange , setFieldError} = form;

        fieldArrayPropsRef.current = fieldArrayProps;

        const selectedAdults = values?.childs?.filter((curr) => {
          return curr.check == true;
        });

        return (
          <>
            {/* {times(passengersCount, (index) => { */}
            <div className="card2 roomsInfo">
              <div className="sectionTitle justify-space-between">
                <h2> Children Information</h2>
                <p>
                {/* {`${!errors[travlerType] ? flightsWidgetData?.children :flightsWidgetData?.children - (errors[travlerType].filter((item)=>item).length) } / ${flightsWidgetData?.children} added`} */}
                {`${flightsWidgetData?.children} / ${flightsWidgetData?.children} added`}
                </p>
              </div>

              {values?.childs?.map((curr, index) => {
                return (
                  <Fragment key={index}>
                    <div className="d-flex promocodeWrap row margin-zero">
                      {/* <Checkbox
                        name="check"
                        value={values?.childs[index]?.check}
                        disabled={
                          selectedAdults?.length >
                            flightsWidgetData?.children - 1 &&
                          !values?.childs[index]?.check
                        }
                        onChange={() => {
                          handleSelectChange(
                            !values?.childs[index]?.check,
                            `childs[${index}].check`,
                            formikProps
                          );
                        }}
                      /> */}

                      <div className="checkBoxContent">
                        <label className="checkbox-label" htmlFor="checkbox-id">
                          {`Child ${index + 1}`}
                        </label>

                        <p className="add-traveller-button flex-center">
                          {/* <Icomoon className="icon-minus add-traveller-icon" />
                          <span onClick={() => remove(index)}>
                            <i className="icon-close"></i>
                          </span> */}
                        </p>
                      </div>
                    </div>

                    {values?.[travlerType][index]?.check || true && (
                      <>
                        <div className="row new-lead-roomss-row mobile-view-room">
                          <div className="col wrapper-padding profileTitle2 ">
                            <SelectFieldOption
                              label="Title"
                              options={titleOption}
                              name={`childs[${index}].title`}
                              value={values.childs[index]?.title}
                              onChange={(_, name, value) =>
                                handleSelectChange(
                                  value,
                                  `childs[${index}].title`,
                                  formikProps
                                )
                              }
                              formikProps={formikProps}
                              onBlur={handleBlur}
                              touched={
                                touched.childs &&
                                touched.childs[index] &&
                                touched.childs[index]?.title
                              }
                              error={
                                errors.childs &&
                                errors.childs[index] &&
                                errors.childs[index]?.title
                              }
                            />
                          </div>

                          <div className="col wrapper-padding">
                            <OptionButton
                              textOptionOne="M"
                              textOptionTwo="F"
                              type="button"
                              groupClassName="genderBtnGroup"
                              className="genderBtn"
                              activeClassName="active"
                              label="Gender"
                              name={`childs[${index}].gender`}
                              value={values.childs[index]?.gender}
                              onClick={(name, value) => {
                                handleSelectChange(
                                  value,
                                  `childs[${index}].gender`,
                                  formikProps
                                );
                              }}
                              touched={
                                touched.childs &&
                                touched.childs[index] &&
                                touched.childs[index]?.gender
                              }
                              error={
                                errors.childs &&
                                errors.childs[index] &&
                                errors.childs[index]?.gender
                              }
                            />
                          </div>

                          <div className="col wrapper-padding mobile-view-width">
                            
                             <InputField
                            type="text"
                              label="Frequent Flyer Number"
                              placeholder="Frequent Flyer Number"
                              name={
                                `childs[${index}].frequentFlyerNumber` ??
                                undefined
                              }
                              value={values.childs[index]?.frequentFlyerNumber}
                              onChange={handleChange}
                              formikProps={formikProps}
                              touched={
                                touched.childs &&
                                touched.childs[index] &&
                                touched.childs[index]?.frequentFlyerNumber
                              }
                              error={
                                errors.childs &&
                                errors.childs[index] &&
                                errors.childs[index]?.frequentFlyerNumber
                              }
                            />
                          </div>
                        </div>

                        <div className="row new-lead-roomss-row">
                          <div className="col col-room ">
                            <InputField
                              label="First Name"
                              placeholder="First Name"
                              name={`childs[${index}].firstName`}
                              value={
                                values.childs[index]?.firstName ?? undefined
                              }
                              onChange={handleChange}
                              formikProps={formikProps}
                              onBlur={handleBlur}
                              touched={
                                touched.childs &&
                                touched.childs[index] &&
                                touched.childs[index]?.firstName
                              }
                              error={
                                errors.childs &&
                                errors.childs[index] &&
                                errors.childs[index]?.firstName
                              }
                            />
                          </div>

                          <div className="col  col-room ">
                            <InputField
                              label="Middle Name"
                              placeholder="Middle Name"
                              name={`childs[${index}].middleName`}
                              value={
                                values.childs[index]?.middleName ?? undefined
                              }
                              onChange={handleChange}
                              formikProps={formikProps}
                            />{' '}
                          </div>

                          <div className="col  col-room ">
                            <InputField
                              label="Last Name"
                              placeholder="Last Name"
                              name={`childs[${index}].lastName`}
                              value={
                                values.childs[index]?.lastName ?? undefined
                              }
                              onChange={handleChange}
                              formikProps={formikProps}
                              onBlur={handleBlur}
                              touched={
                                touched.childs &&
                                touched.childs[index] &&
                                touched.childs[index]?.lastName
                              }
                              error={
                                errors.childs &&
                                errors.childs[index] &&
                                errors.childs[index]?.lastName
                              }
                            />
                          </div>
                        </div>

                        <div className="row new-lead-roomss-row">
                          <div className="col wrapper-padding profileTitle2">
                            <SelectFieldOption
                              label="Nationality"
                              options={nationalityOptions}
                              name={`childs[${index}].nationality`}
                              value={values.childs[index]?.nationality}
                              onChange={(_, name, value) =>
                                handleSelectChange(
                                  value,
                                  `childs[${index}].nationality`,
                                  formikProps
                                )
                              }
                              formikProps={formikProps}
                              onBlur={handleBlur}
                              touched={
                                touched.childs &&
                                touched.childs[index] &&
                                touched.childs[index]?.nationality
                              }
                              error={
                                errors.childs &&
                                errors.childs[index] &&
                                errors.childs[index]?.nationality
                              }
                            />
                          </div>

                          <div
                            className="col wrapper-padding single-date-picker-wrap"
                            style={{ flexDirection: 'column' }}
                          >
                            <DateInput
                              {...formikProps}
                              placeholder="Date of Birth"
                              label="Date of Birth"
                              // value={
                              //   values.childs[index]?.dob &&
                              //   moment(values.childs[index]?.dob).format(
                              //     "MM/DD/YYYY"
                              //   )
                              // }
                              value={
                                values.childs[index]?.dob &&
                                moment(values.childs[index]?.dob).format(
                                  'YYYY-MM-DD'
                                )
                              }
                              icon="icon-invitation"
                              name={`childs[${index}].dob`}
                              onDateChange={(event) =>
                                handleDateChange(
                                  event,
                                  `childs[${index}].dob`,
                                  formikProps,
                                  index
                                )
                              }
                              focused={dateInputFocused.dob[index]}
                              onFocusChange={({ focused }) =>
                                handleFocusChange(index, focused, 'dob')
                              }
                              isOutsideRange={(day) =>
                                moment().subtract(2, 'years').diff(day) < 0  ||  moment().subtract(12, 'years').diff(day) > 2
                              }
                              renderMonthYearSelector={true}
                              arrayFieldArray={true}
                            />

                            {touched.childs &&
                              touched.childs[index] &&
                              touched.childs[index]?.dob &&
                              errors.childs &&
                              errors.childs[index] &&
                              errors.childs[index]?.dob && (
                                <div className="inputStyle error-messages">
                                  {errors.childs[index]?.dob}
                                </div>
                              )}
                          </div>

                          <div className="col wrapper-padding profileTitle2">
                            <SelectFieldOption
                              label="Country/Territory Code"
                              options={nationalityOptions}
                              name={`childs[${index}].passportCountry`}
                              value={values.childs[index]?.passportCountry}
                              onChange={(_, name, value) =>
                                handleSelectChange(
                                  value,
                                  `childs[${index}].passportCountry`,
                                  formikProps
                                )
                              }
                              formikProps={formikProps}
                              onBlur={handleBlur}
                              touched={
                                touched.childs &&
                                touched.childs[index] &&
                                touched.childs[index]?.passportCountry
                              }
                              error={
                                errors.childs &&
                                errors.childs[index] &&
                                errors.childs[index]?.passportCountry
                              }
                            />
                          </div>
                        </div>

                        <div className="row new-lead-roomss-row">
                          <div className=" wrapper-padding ">
                            <CustomPhoneInput
                              label="Phone Number"
                              placeholder="Phone Number"
                              inputProps={{
                                name: `childs[${index}].phoneNumber`,
                              }}
                              value={values.childs[index]?.phoneNumber}
                              onChange={(phoneNumber) => {
                                formikProps.setFieldValue(
                                  `childs[${index}].phoneNumber`,
                                  phoneNumber
                                );
                              }}
                              formikProps={formikProps}
                              onBlur={handleBlur}
                              touched={
                                touched.childs &&
                                touched.childs[index] &&
                                touched.childs[index]?.phoneNumber
                              }
                              error={
                                errors.childs &&
                                errors.childs[index] &&
                                errors.childs[index]?.phoneNumber
                              }
                            />
                          </div>

                         { !domesticFlight &&<div className="col wrapper-padding">
                            <InputField
                              label="Passport Number"
                              placeholder="Passport Number"
                              name={`childs[${index}].passportNumber`}
                              value={
                                values.childs[index]?.passportNumber ??
                                undefined
                              }
                              onChange={handleChange}
                              formikProps={formikProps}
                              onBlur={handleBlur}
                              touched={
                                touched.childs &&
                                touched.childs[index] &&
                                touched.childs[index]?.passportNumber
                              }
                              error={
                                errors.childs &&
                                errors.childs[index] &&
                                errors.childs[index]?.passportNumber
                              }
                            />
                          </div>
}
                         { !domesticFlight && <div
                            className="col wrapper-padding single-date-picker-wrap"
                            style={{ flexDirection: 'column' }}
                          >
                            <DateInput
                              {...formikProps}
                              placeholder="Passport Expire Date"
                              label="Passport Expire Date"
                              // value={
                              //   values?.childs && values?.childs[index]?.passportExpiryDate &&
                              //   moment(values?.childs && values?.childs[index]?.passportExpiryDate).format(
                              //     "YYYY/MM/DD"
                              //   )
                              // }
                              value={
                                values.childs[index]?.passportExpiryDate &&
                                moment(values.childs[index]?.passportExpiryDate).format(
                                  'YYYY-MM-DD'
                                )
                              }
                              icon="icon-invitation"
                              name={`childs[${index}].passportExpiryDate`}
                              onDateChange={(event) =>
                                handleDateChange(
                                  event,
                                  `childs[${index}].passportExpiryDate`,
                                  formikProps,
                                  index,
                                  setFieldError
                                )
                              }
                              // disabled={index < getGuestData?.length && !isFreemium }

                              focused={dateInputFocused?.passportExpiryDate[index]}
                              onFocusChange={({ focused }) =>
                                handleFocusChange(index, focused, 'passportExpiryDate')
                              }
                              // isOutsideRange={(day) =>
                              //   day.isBefore(moment().subtract(0, "days"))
                              // }
                              isOutsideRange={(day) =>
                                day.isBefore(moment(passportMinArrivalDate).add(10, 'days'))
                              }
                              renderMonthYearSelector={true}
                              arrayFieldArray={true}
                              selectFromFuture={true}
                            />
 

                            {
                              touched?.childs && touched?.childs[index] && touched?.childs[index].passportExpireDate && errors.childs &&
                              errors.childs[index] &&
                              errors.childs[index]?.passportExpiryDate &&
                              <div className="inputStyle error-messages">
                                {errors?.childs && errors?.childs[index]?.passportExpiryDate} 
                              </div>
                            }


                          </div>
}

                        </div>
                        <div />
                      </>
                    )}
                  </Fragment>
                );
              })}

              <div>
                {/* <p
                  className="add-traveller-button add-guest"
                  onClick={() =>
                    push({
                      title: "",
                      gender: "",
                      frequentFlyerNumber: "",
                      firstName: "",
                      middleName: "",
                      lastName: "",
                      nationality: "",
                      dob: null,
                      passportCountry: "",
                      phoneNumber: "",
                      passportNumber: "",
                      passportExpireDate: null,
                      check: false,
                    })
                  }
                >
                  <Icomoon className="icon-add add-traveller-icon" />
                  <span>ADD NEW CHILD</span>
                </p> */}
              </div>
            </div>
          </>
        );
      }}
    />
  );
}

export default Childs;
