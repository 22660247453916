import React, { useState } from 'react';
import HeaderNavbar from '../../Component/HeaderNavbar';
import TabList from './TabList';
import HotelFaqWrapper from './HotelFaqWrapper';
import FooterWithMediaIcon from '../../Component/FooterWithMediaIcon';
import { HotelFaqList } from '../../main/CustomConstants/HotelFaqList';
import PdfIcon from '../../Assets/img/icons/pdf-icon.png';
import Scrollbars from 'react-custom-scrollbars';
import checklistPdf from '../../Assets/pdf/TripelloHotelBookingChecklist2023V2.pdf';

function HotelFaqs() {
  // State for opening faq list
  const [openList, setOpenList] = useState(0);

  // Handle open and close faq list
  const handleOpenCloseList = (id) => {
    setOpenList(openList !== id ? id : null);
  };

  //faq list getting pushed into an array
  const hotelFaq = (getHotelFaq) => {
    const accordianList = [];

    getHotelFaq.forEach((faq, index) => {
      {
        accordianList.push(
          <HotelFaqWrapper
            openList={openList}
            handleOpenCloseList={handleOpenCloseList}
            faq={faq}
            index={index}
          />
        );
      }
    });

    return accordianList;
  };

  return (
    <div className="siteBg FaqPageWrap">
      <div className="pageWrap faq-scroll">
        <div className="fixedHeader">
          <HeaderNavbar useBlueIcon={true}/>
        </div>
      </div>
      <section className="mainContent">
        <Scrollbars
          autoHeight={true}
          autoHeightMin={120}
          autoHeightMax={`calc(100%)`}
          className="pageInternalScroll"
        >
          <div className="container pageInternalScrollTopPadding">
            <div className="row no-gutters rightContent-bottom">
              <TabList />
              <div className="col rightContent">
                <div className="pdf-text-wrap">
                  <div className="row no-gutters">
                    <div className="col pdf-text">
                      <h6>For a better Experience and to optimize your Savings, please download our <a href={checklistPdf} target="_blank" className="whiteLink" rel="noreferrer">Tripello Hotel Booking Checklist</a> </h6>
                    </div>
                    <div className="col pdf-image">
                      <a href={checklistPdf} target="_blank" rel="noreferrer">
                        <img src={PdfIcon} alt="icon" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="faqWrapper card2">{hotelFaq(HotelFaqList)}</div>
              </div>
            </div>
          </div>
        </Scrollbars>
      </section>
      <FooterWithMediaIcon className="white-icon" iconClassName="icon-white" />
    </div>
  );
}

export default HotelFaqs;
