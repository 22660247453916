import { serialize } from "../Util/util";
import http from "./http";

function checkHotelAvailability(payload, id) {
  return http
    .post(
      `${process.env.REACT_APP_BASE_URL_HOTEL_NODE}/v2/rooms/recheck-price/v2/${id}`,
      {
        ...payload,
        params: { use_hotel_search_key: true },
      }
    )
    .then((response) => response);
}

function recheckHotelPrice(payload) {
  return http
    .post(
      `${process.env.REACT_APP_BASE_URL_HOTEL_NODE}/v2/rooms/recheck-price`,
      {
        ...payload,
        params: { use_hotel_search_key: true },
      }
    )
    .then((response) => response);
}

function getStripeIntent(reward_credit_applied) {
  return http.get(
    `${
      reward_credit_applied
        ? process.env.REACT_APP_BASE_URL_HOTEL_NODE +
          "/v2/payment/initiate?reward_credit_applied=" +
          reward_credit_applied
        : process.env.REACT_APP_BASE_URL_HOTEL + "/v2/payment/initiate?"
    }`,
    {
      params: { use_hotel_search_key: true },
    }
  );
}

function paymentIntentHotel(payload) {
  return http.post(
    `${process.env.REACT_APP_BASE_PAYMENT_INTENT_URL}/api/paymentIntent`,
    payload
  );
}

function paymentConfirmService({
  intentId,
  clientSecret,
  cvc,
  expMonth,
  expYear,
  cardNumber,
  cardType,
}) {
  return http
    .post(
      `https://api.stripe.com/v1/payment_intents/${intentId}/confirm?client_secret=${clientSecret}&payment_method_data[type]=${cardType}&payment_method_data[card][cvc]=${cvc}&payment_method_data[card][number]=${cardNumber}&payment_method_data[card][exp_month]=${expMonth}&payment_method_data[card][exp_year]=${expYear}`,
      {
        params: {
          use_stripe_api: true,
          use_form_data: true,
        },
      }
    )
    .then((response) => response);
}

function bookHotelService(payload, id) {
  return (
    http
      // .post(`${process.env.REACT_APP_BASE_URL_HOTEL_NODE}/v2/book-v3`, {
      .post(
        `${process.env.REACT_APP_BASE_URL_HOTEL_NODE}/api/v3/hotel/${id}/book`,
        {
          ...payload,
          params: {
            use_hotel_search_key: true,
          },
        }
      )
      .then((response) => response)
  );
}

function searchHotelService(payload) {
  return http
    .post(`${process.env.REACT_APP_BASE_URL_HOTEL_NODE}/v2/search/v3`, {
      ...payload,
      params: { use_hotel_search_key: true },
    })
    .then((response) => response);
}

function searchHotelCordService(payload) {
  return http
    .post(`${process.env.REACT_APP_BASE_URL_HOTEL_NODE}/v2/search/v3`, {
      ...payload,
      params: { use_hotel_search_key: true },
    })
    .then((response) => response);
}

function getHotelDetails(payload, hotel_id) {
  const qs = serialize(payload);
  return http
    .get(
      `${process.env.REACT_APP_BASE_URL_HOTEL_NODE}/v2/hotel/${hotel_id}/static-content/v2?${qs}`,
      {
        params: {
          use_hotel_search_key: true,
        },
      }
    )
    .then((res) => res);
}

// new node api

function newGetHotelDetails(hotel_id) {
  return http
    .get(
      `${process.env.REACT_APP_BASE_URL_HOTEL_NODE}/api/v3/hotel/${hotel_id}/static-content`,
      {
        params: { use_hotel_search_key: true, use_x_api_key: true },
      }
    )
    .then((res) => res);
}

function newPostHotelPackages(payload, hotel_id) {
  return http
    .post(
      `${process.env.REACT_APP_BASE_URL_HOTEL_NODE}/api/v3/hotel/${hotel_id}/packages`,
      {
        ...payload,
        params: { use_hotel_search_key: true, use_x_api_key: true },
      }
    )
    .then((res) => res);
}

function getHotelRooms(payload, hotel_id) {
  return http
    .post(
      `${process.env.REACT_APP_BASE_URL_HOTEL_NODE}/v2/hotel/${hotel_id}/packages/v4`,
      {
        ...payload,
        params: { use_hotel_search_key: true },
      }
    )
    .then((res) => res);
}

function validateZip(zip) {
  return http.get(
    `${process.env.REACT_APP_BASE_URL_HOTEL_NODE}/zip-details?zip=${zip}`
  );
}

function getBookingDetails(id) {
  return http
    .get(
      `${process.env.REACT_APP_BASE_URL_BACKOFFICE_URL}/api/Hotel/HotelBookingsDetails/${id}`,
      {
        params: { use_base_hotel_url: true },
      }
    )
    .then((res) => res);
}

function downloadPdf(id) {
  return http.get(
    `${process.env.REACT_APP_BASE_URL_HOTEL_NODE}/get-pdf/${id}`,
    {
      params: {
        use_base_hotel_url: true,
      },
    }
  );
}

function getUpcomingBookings(status) {
  return http.get(
    `${process.env.REACT_APP_BASE_URL_HOTEL_NODE}/api/bookings/${status}`
  );
}

function getCancelledBookings(status) {
  return http.get(
    `${process.env.REACT_APP_BASE_URL_HOTEL_NODE}/api/bookings/${status}`
  );
}
function getMytripBookingsService(payload) {
  return http.post(
    `${process.env.REACT_APP_BASE_URL_BACKOFFICE_URL}/api/Mytrip/all`,
    { ...payload, params: { use_hotel_search_key: true, use_x_api_key: true } }
  );
}

function getCompletedBookings(status) {
  return http.get(
    `${process.env.REACT_APP_BASE_URL_HOTEL_NODE}/api/bookings/${status}`
  );
}

function getFailedBookings(status) {
  return http.get(
    `${process.env.REACT_APP_BASE_URL_HOTEL_NODE}/api/bookings/${status}`
  );
}
function emailBooking(payload) {
  return http.post(
    `${process.env.REACT_APP_BASE_URL_BACKOFFICE_URL}/api/Hotel/send-mail`,
    {
      ...payload,
      params: {
        use_base_hotel_url: true,
      },
    }
  );
}

function cancelledBooking(payload) {
  return http.post(
    `${process.env.REACT_APP_BASE_URL_BACKOFFICE_URL}/api/Hotel/cancelBooking/v2`,
    { ...payload, params: { use_base_hotel_url: true } }
  );
}

// hotel pre booking
function hotelPrebook(payload, id) {
  // return http.post(`${process.env.REACT_APP_BASE_URL_HOTEL_NODE}/v2/prebook-v2`, {
  return http.post(
    `${process.env.REACT_APP_BASE_URL_HOTEL_NODE}/api/v3/hotel/${id}/prebook`,
    {
      ...payload,
      params: { use_hotel_search_key: true },
    }
  );
}

function getAvailableBalance() {
  return http.get(
    `${process.env.REACT_APP_BASE_URL_HOTEL_NODE}/v1/balance/?vertical=hotel&tenant_id=${process.env.REACT_APP_TENANT_ID}`,
    {}
  );
}
function getAvailableBalanceNew(payload) {
  return http.post(
    `${process.env.REACT_APP_BASE_URL_HOTEL_NODE}/api/v3/hotel/validate-balance`,
    {
      ...payload,
      params: { use_hotel_search_key: true },
    }
  );
}

function cancelRefundDetails(payload) {
  return http.get(
    `${process.env.REACT_APP_BASE_URL_BACKOFFICE_URL}/api/Hotel/refund-details/${payload?.id}`
  );
}

export const hotelService = {
  searchHotelService,
  searchHotelCordService,
  validateZip,
  getCompletedBookings,
  getHotelDetails,
  newGetHotelDetails,
  getHotelRooms,
  getBookingDetails,
  downloadPdf,
  emailBooking,
  cancelledBooking,
  checkHotelAvailability,
  recheckHotelPrice,
  getStripeIntent,
  paymentConfirmService,
  bookHotelService,
  getUpcomingBookings,
  getCancelledBookings,
  getFailedBookings,
  hotelPrebook,
  getAvailableBalance,
  getAvailableBalanceNew,
  paymentIntentHotel,
  newPostHotelPackages,
  getMytripBookingsService,
  cancelRefundDetails,
};
