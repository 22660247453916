import * as Yup from "yup";

export const supportHotelTabValidation = () => {
  return Yup.object().shape({
    confirmation_id: Yup.string("").required("Required"),
    support_need: Yup.string("").required("Required"),
    description: Yup.string("").required("Required").min(20),
  });
};
export const supportHotelTabValidationMembership = () => {
  return Yup.object().shape({
    support_need: Yup.string("").required("Required"),
    description: Yup.string("").required("Required").min(20),
  });
};
